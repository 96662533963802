import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { BACKGROUND, TEXT_COLOR } from '../../styles';

const MidNavBarCss = styled(Button)({
  backgroundColor: BACKGROUND,
  color: TEXT_COLOR,
  fontSize: '20px',
  fontFamily: 'DM Sans',
  borderRadius: 0,
  width: '200px',
  height: '50px',
  textTransform: 'capitalize',

  //鼠标悬浮时
  '&:hover': {
    backgroundColor: '#6D717D',
    color: '#FFFFFF',
  },
});

export default MidNavBarCss;
