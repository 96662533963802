import {
  GetSearchCompanyParamsModel,
  GetSearchCompanyResponseModel,
} from '../models';

export const getSearchCompanyReq = async (
  reqParam: GetSearchCompanyParamsModel,
): Promise<GetSearchCompanyResponseModel> => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/search/${reqParam.keyword}`,
  );
  if (!response.ok) {
    throw new Error('Failed to get search result');
  }
  const jsonData = await response.json();
  return jsonData;
};
