import { Button } from '@mui/material';
import { RegistrationButtonPropsModel } from '../models';
import { TEXT_COLOR } from '../styles';

const RegistrationButton: React.FC<RegistrationButtonPropsModel> = ({
  children,
  onClick,
}) => {
  const styles = {
    marginRight: '20px',
    textDecoration: 'none',
    borderRadius: '5px',
    height: '38px',
    fontSize: '16px',
    backgroundColor: '#6d717d',
    color: TEXT_COLOR,
  };

  return (
    <Button onClick={onClick} style={styles}>
      {children}
    </Button>
  );
};

export default RegistrationButton;
