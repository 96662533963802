import { rest } from 'msw';
import { companyQuote } from '../data/company-quote'; // 假设这是你数据的位置

// 根据股票代码查找股票报价信息
function findQuoteBySymbol(symbol: string) {
  return companyQuote.find((quote) => quote.symbol === symbol);
}

const handlers = [
  rest.get('/api/quotes/:symbol', (req, res, ctx) => {
    const symbol = req.params.symbol as string; // 获取股票代码
    const quote = findQuoteBySymbol(symbol); // 查找对应的股票报价信息

    // 如果没有找到报价信息，返回404
    if (!quote) {
      return res(
        ctx.status(404),
        ctx.json({ message: `No quote found for symbol ${symbol}` }),
      );
    }

    // 如果找到了报价信息，返回200和报价信息
    return res(ctx.status(200), ctx.json([quote, quote]));
  }),
];

export default handlers;
