import { setupWorker } from 'msw';
import {
  newsHandlers,
  alertsHandlers,
  stocksHandlers,
  recommendationsHandlers,
  notificationsHandlers,
  usersHandlers,
  companyQuotesHandlers,
} from './handlers';

export const worker = setupWorker(
  ...newsHandlers,
  ...alertsHandlers,
  ...stocksHandlers,
  ...recommendationsHandlers,
  ...notificationsHandlers,
  ...usersHandlers,
  ...companyQuotesHandlers,
);
