import {
  GetFinReportReqParamsModel,
  GetFinReportResponseModel,
  GetFinAnalysisResponseModel,
  GetFinAnalysisReqParamsModel,
} from '../models';

export const getFinAnalysisReq = async (
  reqParam: GetFinAnalysisReqParamsModel,
): Promise<GetFinAnalysisResponseModel> => {
  const { company, period, from, to } = reqParam;

  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/api/financial_reports/financial_analysis/${company}?isQuarterly=${
      period === 'quarterly'
    }&from=${from}&to=${to}`,
  );

  if (!response.ok) {
    throw new Error('Failed to get financial report,');
  }

  const jsonData = await response.json();

  // console.log('aaaaaa', jsonData);
  return jsonData;
};

export const getFinReportReq = async (
  reqParam: GetFinReportReqParamsModel,
): Promise<GetFinReportResponseModel> => {
  const { company, period, from, to } = reqParam;

  const response = await fetch(
    `${
      process.env.REACT_APP_BACKEND_URL
    }/api/financial_reports/${company}?isQuarterly=${
      period === 'quarterly'
    }&from=${from}&to=${to}`,
  );

  if (!response.ok) {
    throw new Error('Failed to get financial report,');
  }

  const jsonData = await response.json();
  // console.log('gggggggg', jsonData);
  return jsonData;
};
