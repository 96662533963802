export const recommendedSymbols = [
  'AAPL',
  'ABNB',
  'ADBE',
  'AI',
  'AMD',
  'AMZN',
  'ANET',
  'AVGO',
  'BHP',
  'BIDU',
  'CAT',
  'CLF',
  'COIN',
  'CRM',
  'CRWD',
  'DE',
  'FCX',
  'GOOG',
  'ISRG',
  'MBLY',
  'MDB',
  'META',
  'MRK',
  'MRNA',
  'MSFT',
  'MRVL',
  'NET',
  'NVDA',
  'ON',
  'PFE',
  'PLTR',
  'RBLX',
  'RIO',
  'RIVN',
  'RXRX',
  'SCCO',
  'SHOP',
  'SNOW',
  'SQ',
  'TSLA',
  'U',
  'VALE',
  'MSTR',
  'PAGS',
  'BROS',
];
