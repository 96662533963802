export const newsData = {
  news: [
    {
      title: 'Rivian options buyers may be helping drive stock higher',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Neutral',
      datetime: '2023-07-10T12:00:00Z',
      content:
        "A team of scientists has discovered a new species of orchid in the Amazon rainforest. The orchid, named 'Amazonia Spectabilis', features vibrant pink petals and a unique fragrance. It was found during an expedition to a remote area of the rainforest. The discovery is significant as it adds to the rich biodiversity of the Amazon region. Scientists believe that this new species could provide valuable insights into the evolution and adaptation of orchids in this unique ecosystem. Efforts are now underway to protect the habitat of 'Amazonia Spectabilis' and further study its ecological role.",
    },
    {
      title: "Futures: 'Magnificent Seven' Falter; CPI Inflation Data Looms",
      topics: ['Finance', 'Retail & Wholesale', 'Technology'],
      overallSentimentLabel: 'Bullish',
      datetime: '2023-07-09T08:30:00Z',
      content:
        'The World Health Organization (WHO) has launched a global vaccination campaign to combat the spread of infectious diseases. The campaign aims to ensure that people in all countries have access to safe and effective vaccines. It will focus on increasing vaccine production, improving distribution systems, and addressing vaccine hesitancy. The WHO is partnering with governments, pharmaceutical companies, and other organizations to achieve its vaccination goals. The campaign is expected to save millions of lives and contribute to the control and elimination of preventable diseases worldwide.',
    },
    {
      title:
        'What Does The Second Half Hold? Three MoneyShow Contributors Weigh In',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Neutral',
      datetime: '2023-07-08T15:45:00Z',
      content:
        'A leading tech giant has unveiled its latest smartphone model, equipped with advanced artificial intelligence (AI) features. The new smartphone uses AI algorithms to enhance the user experience and improve performance. It can automatically adjust camera settings for optimal photos, recognize and translate languages in real-time, and offer personalized recommendations based on user preferences. The device also boasts enhanced security features, including facial recognition and encrypted data storage. With its powerful AI capabilities, the smartphone aims to redefine the way users interact with their devices and simplify daily tasks.',
    },
    {
      title:
        'Stock Market Today: Stocks Tick Higher Ahead of June CPI, Q2 Earnings Season',
      topics: [
        'Financial Markets',
        'Manufacturing',
        'Energy & Transportation',
        'Technology',
      ],
      overallSentimentLabel: 'Somewhat-Bullish',
      datetime: '2023-07-07T09:15:00Z',
      content:
        'Environmental activists from around the world are calling for urgent action to address the impacts of climate change. They argue that rising temperatures, extreme weather events, and biodiversity loss pose significant threats to the planet and its inhabitants. Activists are demanding that governments and industries take immediate steps to reduce greenhouse gas emissions, transition to renewable energy sources, and protect natural ecosystems. They are also urging individuals to make sustainable lifestyle choices and advocate for policy changes. The global movement aims to raise awareness and mobilize collective action to combat climate change.',
    },
    {
      title:
        "Narrow Rally: Top 6 Tech Companies Drive Tech Sector's Historic Half-Year Performance, Analyst Says - Microsoft  ( NASDAQ:MSFT ) , Alphabet  ( NASDAQ:GOOGL ) , Meta Platforms  ( NASDAQ:META ) , NVIDIA  ( NASDAQ:NVDA ) , Apple  ( NASDAQ:AAPL ) , Amazon.com  ( NASDAQ:AMZN ) ",
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Bullish',
      datetime: '2023-07-06T14:20:00Z',
      content:
        "A space exploration company has announced its plans to send a manned mission to Mars within the next decade. The company aims to establish a human settlement on the red planet and conduct scientific research. The mission will involve a series of robotic missions to prepare for the arrival of humans, including testing life support systems and identifying potential landing sites. The company is collaborating with international partners and experts in various fields to ensure the success and safety of the mission. This ambitious endeavor represents a major milestone in humanity's quest to explore and colonize other planets.",
    },
    {
      title: 'Rivian options buyers may be helping drive stock higher',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Somewhat-Bullish',
      datetime: '2023-07-05T11:10:00Z',
      content:
        'The global economy is showing promising signs of recovery as countries gradually emerge from the COVID-19 pandemic. Economic indicators, such as GDP growth, trade volumes, and consumer spending, are trending upwards in many regions. Governments and central banks have implemented stimulus measures to support businesses and households during the crisis. Vaccination campaigns have also played a crucial role in restoring confidence and reopening economies. However, challenges remain, including ongoing supply chain disruptions and the unequal impact of the pandemic on different sectors and countries. Continued efforts are needed to ensure a sustainable and inclusive recovery.',
    },
    {
      title: 'Rivian options buyers may be helping drive stock higher',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Bullish',
      datetime: '2023-07-04T16:50:00Z',
      content:
        'A breakthrough in artificial intelligence (AI) has revolutionized medical diagnostics by improving accuracy and efficiency. Researchers have developed an AI system that can analyze medical images, such as X-rays and MRIs, to detect diseases with remarkable precision. The system leverages deep learning algorithms and vast amounts of data to make accurate diagnoses faster than human experts. This technology has the potential to enhance healthcare outcomes by enabling early detection and personalized treatment plans. It can also alleviate the burden on healthcare professionals and improve resource allocation in medical settings.',
    },
    {
      title: 'Rivian options buyers may be helping drive stock higher',
      topics: [
        'Energy & Transportation',
        'Manufacturing',
        'Earnings',
        'Technology',
      ],
      overallSentimentLabel: 'Somewhat-Bullish',
      datetime: '2023-07-03T10:25:00Z',
      content:
        'A popular social media platform has introduced new safety measures to address the issue of online harassment and improve user well-being. The platform has implemented advanced algorithms and automated systems to detect and remove abusive content, hate speech, and cyberbullying. Users can also customize their privacy settings and report abusive behavior more easily. Additionally, the platform is investing in user education and community moderation to foster a safer and more inclusive online environment. These efforts aim to protect users from harmful experiences and promote positive interactions on the platform.',
    },
    {
      title: 'Rivian options buyers may be helping drive stock higher',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Neutral',
      datetime: '2023-07-02T14:40:00Z',
      content:
        'Renewable energy has surpassed coal as the largest source of electricity generation in many countries worldwide. This milestone reflects the increasing adoption of solar, wind, and hydroelectric power as cleaner and more sustainable alternatives. The declining costs of renewable energy technologies, coupled with supportive policies and public demand for greener solutions, have driven this shift. Renewable energy offers numerous environmental benefits, including reduced greenhouse gas emissions and improved air quality. As countries continue to prioritize the transition to renewable energy, it paves the way for a more sustainable and climate-friendly future.',
    },
  ],
};
