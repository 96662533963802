import { useMemo } from 'react';
import { useAppSelector } from '../../redux_features/hooks';
import { Grid, Typography } from '@mui/material';
import {
  LatestQuoteModel,
  MetricNameValuePairsModel,
  StockMetricsPanelPropsModel,
} from '../../models';

const StockMetricsPanel: React.FC<StockMetricsPanelPropsModel> = ({
  stockSymbol,
}: StockMetricsPanelPropsModel) => {
  const selectedCompanyLatestQuote: LatestQuoteModel = useAppSelector(
    (state) =>
      state.ui.stockList.stockListRightSidebar.selectedCompanyLatestQuote,
  );

  const metricNameValuePairs: MetricNameValuePairsModel = useMemo(() => {
    const res = [] as MetricNameValuePairsModel;

    // 根据这个object筛选出所有要显示的数组
    const keyToTextMapping = {
      dayLow: 'Daily Lowest',
      dayHigh: 'Daily Highest',
      price: 'Price',
      yearHigh: 'Year High',
      volume: 'Volume',
      changesPercentage: 'Changes%',
      yearLow: 'Year Low',
      avgVolume: 'Avg Volume',
      change: 'Change',
      priceAvg50: 'Price Avg 50',
      priceAvg200: 'Price Avg 200',
      open: 'Open',
      eps: 'EPS',
      previousClose: 'Previous Close',
      exchange: 'Exchange',
      pe: 'PE',
      // Turnover: 'Turnover',
      marketCap: 'Market Cap',
      sharesOutstanding: 'Shares Outstanding',
      earningsAnnouncement: 'Earnings Announcement',
    };

    for (const key of Object.keys(keyToTextMapping)) {
      if (key in selectedCompanyLatestQuote) {
        if (
          typeof selectedCompanyLatestQuote[key as keyof LatestQuoteModel] !==
            'number' &&
          typeof selectedCompanyLatestQuote[key as keyof LatestQuoteModel] !==
            'string'
        ) {
          throw TypeError(
            'The value to display in metrics panel must be a number or a string!',
          );
        }

        const displayName: string =
          keyToTextMapping[key as keyof typeof keyToTextMapping];
        const displayValue: number = selectedCompanyLatestQuote[
          key as keyof typeof selectedCompanyLatestQuote
        ] as number;

        res.push({
          name: displayName,
          value: displayValue,
        });
      }
    }

    return res || ([] as MetricNameValuePairsModel); // 设置默认值防止空置错误
  }, [selectedCompanyLatestQuote]);

  const specialMetrics = [
    'Shares Outstanding',
    'Earnings Announcement',
    'Market Cap',
  ];
  const specialMetricPairs = metricNameValuePairs.filter((pair) =>
    specialMetrics.includes(pair.name),
  );
  const regularMetricPairs = metricNameValuePairs.filter(
    (pair) => !specialMetrics.includes(pair.name),
  );

  return (
    <>
      <Grid container spacing={2}>
        {regularMetricPairs.map((pair, index) => (
          <Grid item xs={6} key={index}>
            <Typography>
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'left',
                  width: '60%',
                  color: '#9db8cc',
                }}
              >
                {pair.name}:
              </span>
              <span
                style={{
                  display: 'inline-block',
                  textAlign: 'right',
                  width: '40%',
                  color:
                    pair.name === 'Daily Lowest'
                      ? 'red'
                      : pair.name === 'Daily Highest'
                      ? 'green'
                      : pair.name === 'Year Low'
                      ? 'red'
                      : 'white',
                }}
              >
                {typeof pair.value === 'number'
                  ? pair.value.toFixed(2)
                  : pair.value}
              </span>
            </Typography>
          </Grid>
        ))}
        {specialMetricPairs.map((pair, index) => (
          <Grid item xs={12} key={index}>
            <Typography>
              <span style={{ color: '#9db8cc' }}>{pair.name}:</span>{' '}
              {typeof pair.value === 'number'
                ? pair.value.toFixed(2)
                : pair.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default StockMetricsPanel;
