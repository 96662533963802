import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LatestQuoteModel } from '../models';
import { UP_COLOR, DOWN_COLOR } from '../styles';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../redux_features/hooks';
import { BACKGROUND } from '../styles';
interface CompanyCardProps {
  data: LatestQuoteModel;
}

const CompanyPeerCard: React.FC<CompanyCardProps> = ({ data }) => {
  const status = useAppSelector((state) => state.ui.company.status);
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate(`/stock/${data.symbol}`);
  };
  return (
    <div className="Single Card" style={{ width: '70%' }}>
      {status === 'loading' && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            fontSize: '3em', // Increased font size using 'em' units
            color: BACKGROUND,
          }}
        >
          Loading...
        </div>
      )}
      <Card
        onDoubleClick={handleCardClick}
        style={{
          backgroundColor: data.changesPercentage < 0 ? DOWN_COLOR : UP_COLOR,
        }}
      >
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography style={{ fontSize: '12px' }}>
            {data.symbol} {data.price}
          </Typography>
          <Typography style={{ fontSize: '12px' }}>
            ({data.changesPercentage}%)
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompanyPeerCard;
