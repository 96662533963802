import { useAuth0 } from '@auth0/auth0-react';
import { RegistrationButton } from '../../widgets';

function SignUpButton() {
  const { loginWithRedirect } = useAuth0();

  return (
    <RegistrationButton
      onClick={() =>
        loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
          },
        })
      }
    >
      Sign Up
    </RegistrationButton>
  );
}

export default SignUpButton;
