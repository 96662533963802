import { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatNumber } from '../../utils';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux_features/hooks';
import { getStockListTableRowsThunk } from '../../redux_features/thunks';
import { uiSlice } from '../../redux_features/slices';
import { BACKGROUND } from '../../styles';
import { StockListTableRowModel } from '../../models';

const StockListTable: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const symbolList = useAppSelector((state) => state.ui.stockList.symbols);
  const rows = useAppSelector(
    (state) => state.ui.stockList.stockListTable.rows,
  );
  const rowsExpiration = useAppSelector(
    (state) => state.ui.stockList.stockListTable.expiration,
  );
  const displayedSymbols = useAppSelector(
    (state) => state.ui.stockList.stockListTable.displayedSymbols,
  );
  const selectedSymbol = useAppSelector(
    (state) => state.ui.stockList.selectedSymbol,
  );

  const handleRowSingleClick = (symbol: string) => {
    dispatch(uiSlice.actions.setSelectedSymbol(symbol));
  };

  const handleRowDoubleClick = (symbol: string) => {
    navigate(`/stock/${symbol}`);
  };

  useEffect(() => {
    if (rows.length === 0 || Date.now() > rowsExpiration) {
      dispatch(uiSlice.actions.updateStockListExpiration);
      dispatch(getStockListTableRowsThunk(symbolList));
    }
    // 不根据rows和rowsExpiration触发
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, symbolList]);

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: BACKGROUND,
      }}
    >
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, backgroundColor: BACKGROUND }}
          aria-label="simple table"
        >
          <TableHead>
            {/* //背景色 */}
            <TableRow
              sx={{
                backgroundColor: BACKGROUND,
                position: 'sticky',
                top: 0,
                zIndex: 2,
              }}
            >
              {[
                'Symbol',
                'Name',
                'Price',
                'Change',
                '%Change',
                'Volume',
                'Turnover Rate',
                // 'Sentiment',
                'EPS',
                // '%EPS',
                // 'Net Profit Growth Rate',
                "Day's Lowest",
                "Day's Highest",
              ].map((s, index) => {
                const isSticky = index === 0;
                return (
                  <TableCell
                    key={index}
                    sx={{
                      align: 'center',
                      color: '#FAFEFF',
                      fontSize: '20px',
                      // 除了Symbol和Name这两页以外其他居中
                      textAlign: ['Symbol', 'Name'].includes(s)
                        ? 'left'
                        : 'center', // 文字在容器内水平居中
                      verticalAlign: 'middle', // 文字在容器内垂直居中
                      position: isSticky ? 'sticky' : 'static',
                      // left: isSticky ? `${index * 100}px` : 'auto',
                      backgroundColor: BACKGROUND,
                    }}
                  >
                    {s}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows
              .filter((row) => {
                return displayedSymbols.includes(row.symbol);
              })
              .map((row: StockListTableRowModel, index: number) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowSingleClick(row.symbol)}
                  onDoubleClick={() => handleRowDoubleClick(row.symbol)}
                  sx={{
                    backgroundColor:
                      row.symbol === selectedSymbol ? '#1E232E' : BACKGROUND,
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#1E232E',
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      color: '#FFAEA3',
                      position: 'sticky',

                      left: 0,
                      // left: '100px', // 这个数值需要根据第一列的实际宽度来调整
                      zIndex: 1,
                    }}
                    component="th"
                    scope="row"
                  >
                    {row.symbol}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: '#DEE1E2',
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    sx={{ color: row.change > 0 ? '#33E1F6' : '#FF4F5B' }}
                  >
                    {row.price}
                  </TableCell>
                  {/* TODO:！！！数字判断标准需要改 */}
                  <TableCell
                    align="center"
                    sx={{ color: row.change > 0 ? '#33E1F6' : '#FF4F5B' }}
                  >
                    {row.change}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: row.change > 0 ? '#33E1F6' : '#FF4F5B' }}
                  >
                    {row['%Change']}%
                  </TableCell>
                  <TableCell align="center" sx={{ color: '#DEE1E2' }}>
                    {formatNumber(row.volume)}
                  </TableCell>
                  <TableCell align="center" sx={{ color: '#DEE1E2' }}>
                    {row.turnoverRate.toFixed(2) + '%'}
                  </TableCell>
                  {/* <TableCell
                  align="center"
                  sx={{ color: row.sentiment > 2 ? '#33E1F6' : '#FF4F5B' }}
                >
                  {row.sentiment}
                </TableCell> */}
                  <TableCell align="center" sx={{ color: '#DEE1E2' }}>
                    {row.eps.toFixed(2)}
                  </TableCell>
                  {/* <TableCell
                  align="center"
                  sx={{ color: row['%EPS'] > 3 ? '#33E1F6' : '#FF4F5B' }}
                >
                  {row['%EPS']}
                </TableCell> */}
                  {/* <TableCell
                  align="center"
                  sx={{
                    color:
                      row['Net Profit Growth Rate'] > 1 ? '#33E1F6' : '#FF4F5B',
                  }}
                >
                  {row['Net Profit Growth Rate']}
                </TableCell> */}
                  <TableCell align="center" sx={{ color: '#DEE1E2' }}>
                    {row['dayLow'].toFixed(2)}
                  </TableCell>
                  <TableCell align="center" sx={{ color: '#DEE1E2' }}>
                    {row['dayHigh'].toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
export default StockListTable;
