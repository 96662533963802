import { SentimentColorModel } from '../models';
import { AssetsIcons } from '../value';

export const sentimentColorMap: SentimentColorModel[] = [
  {
    sentimentLabel: 'Bearish',
    color: '#FF4F5B',
    img: AssetsIcons.bearish,
  },
  {
    sentimentLabel: 'Somewhat-Bearish',
    color: '#FFAEA3',
    img: AssetsIcons.somewhatBearish,
  },
  {
    sentimentLabel: 'Neutral',
    color: '#F9FDAF',
    img: AssetsIcons.neutral,
  },
  {
    sentimentLabel: 'Somewhat-Bullish',
    color: '#54D094',
    img: AssetsIcons.somewhatBullish,
  },
  {
    sentimentLabel: 'Bullish',
    color: '#28B446',
    img: AssetsIcons.bullish,
  },
];
