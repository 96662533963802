import { FC } from 'react';
import { AiSummary } from '../../common/components';
import { useParams } from 'react-router-dom';
import { BACKGROUND, TEXT_COLOR } from '../../common/styles';

type StockDetailParams = {
  stockSymbol: string;
};

const CompanySummmaryPage: FC = () => {
  const { stockSymbol } = useParams<StockDetailParams>();
  return (
    <div
      style={{
        backgroundColor: BACKGROUND,
        color: TEXT_COLOR,
        padding: '10px',
      }}
    >
      <AiSummary company_symbol={stockSymbol!} />
      <p style={{ width: '90%' }}> </p>
    </div>
  );
};

export default CompanySummmaryPage;
