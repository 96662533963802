import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFinAnalysisReq } from '../../api';
import { GetFinAnalysisReqParamsModel } from '../../models';
export const getFinAnalysisDataThunk = createAsyncThunk(
  'getFinAnalysisDataThunk',
  async ({
    company,
    from,
    to,
  }: {
    company: string;
    from: string;
    to: string;
  }) => {
    const quarterlyResponse = await getFinAnalysisReq({
      company,
      period: 'quarterly',
      from,
      to,
    });

    const annuallyResponse = await getFinAnalysisReq({
      company,
      period: 'annually',
      from,
      to,
    });

    // console.log('aaaaaa', response)
    if (
      Array.isArray(quarterlyResponse.data) &&
      Array.isArray(annuallyResponse.data)
    ) {
      return {
        FinAnalysis: {
          quarterly: quarterlyResponse.data,
          annually: annuallyResponse.data,
        },
      };
    } else {
      throw new Error('Expected the responses to be arrays');
    }
  },
);
