import { Box, CssBaseline, Drawer, Toolbar } from '@mui/material';
import { TopNavBar, LeftNavBar } from '../../components';
import { LayoutPropsModel } from '../../models';
import { BACKGROUND } from '../../styles';

export default function Layout({ children }: LayoutPropsModel) {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        // overflow: 'hidden',
        backgroundColor: 'black',
      }}
    >
      {/* Ensure full height and no overflow */}
      <Box height="100%">
        <CssBaseline />
        <TopNavBar />
        <Drawer
          variant="permanent"
          sx={{
            width: 240,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: 240,
              boxSizing: 'border-box',
              backgroundColor: BACKGROUND,
              display: 'flex',
              alignItems: 'center',
            },
          }}
        >
          <Box sx={{ overflow: 'auto' }}>
            <Toolbar />
            <LeftNavBar />
          </Box>
        </Drawer>
      </Box>
      {/* Fixed main component */}
      <Box
        component="main"
        sx={{ flexGrow: 1, overflow: 'hidden' }}
        height="100%"
      >
        <Toolbar />
        <Box
          sx={{
            overflow: 'hidden',
            height: 'calc(100vh - 64px)',
            marginBottom: '20px',
          }}
        >
          {/* 64px being the height of the toolbar */}
          {children}
        </Box>
      </Box>
    </Box>
  );
}
