import React from 'react';
import { Grid, Typography } from '@mui/material';
import {
  TEXT_COLOR,
  TOP_COLUMN,
  UP_COLOR,
  DOWN_COLOR,
  BACKGROUND,
} from '../styles';
import { NewsButton } from '.';
// import { useAppDispatch, useAppSelector } from '../redux_features/hooks';
import { LatestQuoteModel } from '../models';

const DISPLAY_KEYS = [
  'open',
  'changesPercentage',
  'change',
  'dayLow',
  'dayHigh',
  'volume',
  'avgVolume',
  'previousClose',
];

interface CompanyNavBarProps {
  SummaryButton?: React.ReactNode;
  data: LatestQuoteModel;
}

const CompanyNavBar: React.FC<CompanyNavBarProps> = ({
  SummaryButton,
  data,
}) => {
  const isUp = data.previousClose < data.open; // Determine the condition
  const displayColor = isUp ? UP_COLOR : DOWN_COLOR; // Set the color based on the condition

  return (
    <div
      className="Company Nav Bar"
      style={{
        backgroundColor: BACKGROUND,
        color: TEXT_COLOR,
        overflowY: 'auto',
        width: '100%',
        height: '60px',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '60px' }}
      >
        <Grid item xs={2}>
          <Typography
            variant="h5"
            align="center"
            style={{ color: displayColor }}
          >
            {data.price}
          </Typography>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ color: displayColor }}
          >
            {data.change > 0 ? `+${data.change}` : data.change}{' '}
            <span style={{ display: 'inline-block', width: '12px' }}></span>
            {data.changesPercentage > 0
              ? `+${data.changesPercentage}`
              : data.changesPercentage}
            {'%'}
          </Typography>
        </Grid>

        <Grid item xs={1}>
          {SummaryButton || <NewsButton>Summary</NewsButton>}
        </Grid>

        {/* Display the data in the remaining grids */}
        {data &&
          Object.entries(data)
            .filter(([key]) => DISPLAY_KEYS.includes(key))
            .map(([key, value], index) => {
              key = key === 'changesPercentage' ? 'change%' : key;
              const textColor = [
                'dayLow',
                'dayHigh',
                'volume',
                'avgVolume',
                'previousClose',
                'open',
              ].includes(key)
                ? TEXT_COLOR
                : displayColor;
              return (
                <Grid
                  item
                  xs={1}
                  key={index + 3}
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="subtitle1"
                    align="center"
                    // style={{ color: textColor }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ color: textColor }}
                  >
                    {value}
                  </Typography>
                </Grid>
              );
            })}
      </Grid>
    </div>
  );
};

export default CompanyNavBar;
