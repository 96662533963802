import React, { FC } from 'react';
import { FRFetchPassData, FRDataTable } from '../../common/components';
import { useParams } from 'react-router-dom';
const FinancialReportPage: FC = () => {
  const { stockSymbol } = useParams<{ stockSymbol: string }>();
  return (
    <div style={{ overflowX: 'auto', maxHeight: '95%' }}>
      <h1 style={{ textAlign: 'center' }}>Financial Report & Analysis</h1>
      {stockSymbol && <FRFetchPassData company={stockSymbol} />}
    </div>
  );
};

export default FinancialReportPage;
