const position = { x: 0, y: 0 };

export const initialNodes = [
  {
    id: '1',
    data: { label: 'node 1' },
    position,
  },
  {
    id: '2',
    data: { label: 'node 2' },
    position,
  },
  {
    id: '3',
    data: { label: 'node 3' },
    position,
  },
  {
    id: '4',
    data: { label: 'node 4' },
    position,
  },
  {
    id: '5',
    data: { label: 'node 5' },
    position,
  },
  {
    id: '6',
    data: { label: 'node 6' },
    position,
  },
  {
    id: '7',
    data: { label: 'node 7' },
    position,
  },
  {
    id: '8',
    data: { label: 'node 8' },
    position,
  },
  {
    id: '9',
    data: { label: 'node 9' },
    position,
  },
];

export const initialEdges = [
  { id: 'e12', source: '1', target: '2', type: 'smoothstep' },
  { id: 'e13', source: '1', target: '3', type: 'smoothstep' },
  { id: 'e14', source: '1', target: '4', type: 'smoothstep' },
  { id: 'e45', source: '4', target: '5', type: 'smoothstep' },
  { id: 'e46', source: '4', target: '6', type: 'smoothstep' },
  { id: 'e57', source: '5', target: '7', type: 'smoothstep' },
  { id: 'e58', source: '5', target: '8', type: 'smoothstep' },
  { id: 'e59', source: '5', target: '9', type: 'smoothstep' },
];
