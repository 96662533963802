import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompaniesQuotes, getCompanyDataReq } from '../../api'; // Assuming you have this api function in this location
import {
  CompanyQuoteModel,
  GetCompaniesQuotesResponseModel,
  GetCompanyParamsModel,
  StockListTableRowsModel,
} from '../../models';
import { formatStockListRow } from '../../utils';

export const getCompanyDataThunk = createAsyncThunk(
  'getCompanyDataThunk',
  async (params: GetCompanyParamsModel) => {
    const response = await getCompanyDataReq(params);
    return response;
  },
);

// 从getCompanyInfos和getCompaniesQuotes分别获取信息，合并并格式化之后返回可以直接用的数据行
export const getStockListTableRowsThunk = createAsyncThunk(
  'getStockListTableRowsThunk',
  async (symbolList: string[]) => {
    const quotesResp: GetCompaniesQuotesResponseModel =
      await getCompaniesQuotes({
        symbolList: symbolList,
      });
    const quotes: CompanyQuoteModel[] = quotesResp.data;

    const formattedData: StockListTableRowsModel =
      quotes.map(formatStockListRow);
    return formattedData;
  },
);
