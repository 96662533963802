export const weeklyStockPrices = {
  'Meta Data': {
    'Company Symbol': 'IBM',
    'Last Refreshed': '2023-06-23',
    'Time Zone': 'US/Eastern',
  },
  'Time Series Weekly': {
    '2023-06-23': {
      open: '136.3600',
      high: '137.2300',
      low: '129.1800',
      close: '129.4300',
      'adjusted close': '129.4300',
      volume: '27111509',
      'dividend amount': '0.0000',
    },
    '2023-06-16': {
      open: '136.0000',
      high: '139.4690',
      low: '135.8216',
      close: '137.4800',
      'adjusted close': '137.4800',
      volume: '24228597',
      'dividend amount': '0.0000',
    },
    '2023-06-09': {
      open: '133.1200',
      high: '136.1000',
      low: '131.8800',
      close: '135.3000',
      'adjusted close': '135.3000',
      volume: '21174178',
      'dividend amount': '0.0000',
    },
    '2023-06-02': {
      open: '129.5600',
      high: '133.1200',
      low: '127.4600',
      close: '132.4200',
      'adjusted close': '132.4200',
      volume: '24339245',
      'dividend amount': '0.0000',
    },
    '2023-05-26': {
      open: '127.5000',
      high: '129.6600',
      low: '125.0100',
      close: '128.8900',
      'adjusted close': '128.8900',
      volume: '21029979',
      'dividend amount': '0.0000',
    },
    '2023-05-19': {
      open: '123.0000',
      high: '128.2900',
      low: '122.3400',
      close: '127.2600',
      'adjusted close': '127.2600',
      volume: '18284524',
      'dividend amount': '0.0000',
    },
    '2023-05-12': {
      open: '123.7600',
      high: '123.9200',
      low: '120.5500',
      close: '122.8400',
      'adjusted close': '122.8400',
      volume: '20404364',
      'dividend amount': '1.6600',
    },
    '2023-05-05': {
      open: '126.3500',
      high: '126.7500',
      low: '121.7563',
      close: '123.6500',
      'adjusted close': '121.9789',
      volume: '21164660',
      'dividend amount': '0.0000',
    },
    '2023-04-28': {
      open: '125.5500',
      high: '127.2500',
      low: '124.5600',
      close: '126.4100',
      'adjusted close': '124.7016',
      volume: '20644224',
      'dividend amount': '0.0000',
    },
    '2023-04-21': {
      open: '128.3000',
      high: '130.9800',
      low: '125.2700',
      close: '125.7300',
      'adjusted close': '124.0308',
      volume: '30341128',
      'dividend amount': '0.0000',
    },
    '2023-04-14': {
      open: '129.8300',
      high: '131.1050',
      low: '126.0000',
      close: '128.1400',
      'adjusted close': '126.4082',
      volume: '19506500',
      'dividend amount': '0.0000',
    },
    '2023-04-06': {
      open: '130.9700',
      high: '132.6100',
      low: '130.3150',
      close: '130.5000',
      'adjusted close': '128.7363',
      volume: '13172262',
      'dividend amount': '0.0000',
    },
    '2023-03-31': {
      open: '126.4700',
      high: '131.4800',
      low: '126.4700',
      close: '131.0900',
      'adjusted close': '129.3184',
      volume: '20779522',
      'dividend amount': '0.0000',
    },
    '2023-03-24': {
      open: '124.3100',
      high: '127.2150',
      low: '122.6000',
      close: '125.2900',
      'adjusted close': '123.5968',
      volume: '20458253',
      'dividend amount': '0.0000',
    },
    '2023-03-17': {
      open: '125.1500',
      high: '128.1900',
      low: '121.7100',
      close: '123.6900',
      'adjusted close': '122.0184',
      volume: '66132690',
      'dividend amount': '0.0000',
    },
    '2023-03-10': {
      open: '129.6400',
      high: '130.8600',
      low: '125.1300',
      close: '125.4500',
      'adjusted close': '123.7546',
      volume: '20761401',
      'dividend amount': '0.0000',
    },
    '2023-03-03': {
      open: '131.4200',
      high: '131.8700',
      low: '127.7100',
      close: '129.6400',
      'adjusted close': '127.8880',
      volume: '17865677',
      'dividend amount': '0.0000',
    },
    '2023-02-24': {
      open: '134.0000',
      high: '134.3850',
      low: '128.8600',
      close: '130.5700',
      'adjusted close': '128.8054',
      volume: '14198950',
      'dividend amount': '0.0000',
    },
    '2023-02-17': {
      open: '136.0000',
      high: '137.3900',
      low: '133.8900',
      close: '135.0200',
      'adjusted close': '133.1953',
      volume: '16543870',
      'dividend amount': '0.0000',
    },
    '2023-02-10': {
      open: '135.8300',
      high: '136.7400',
      low: '133.3400',
      close: '135.6000',
      'adjusted close': '133.7674',
      volume: '22140989',
      'dividend amount': '1.6500',
    },
    '2023-02-03': {
      open: '134.3200',
      high: '136.9500',
      low: '132.8000',
      close: '136.9400',
      'adjusted close': '133.4431',
      volume: '27874571',
      'dividend amount': '0.0000',
    },
    '2023-01-27': {
      open: '141.4000',
      high: '142.9850',
      low: '132.9800',
      close: '134.3900',
      'adjusted close': '130.9582',
      volume: '43345140',
      'dividend amount': '0.0000',
    },
    '2023-01-20': {
      open: '146.4200',
      high: '147.1800',
      low: '139.7500',
      close: '141.2000',
      'adjusted close': '137.5943',
      volume: '21419368',
      'dividend amount': '0.0000',
    },
    '2023-01-13': {
      open: '144.0800',
      high: '146.6600',
      low: '142.9000',
      close: '145.8900',
      'adjusted close': '142.1645',
      volume: '14580596',
      'dividend amount': '0.0000',
    },
    '2023-01-06': {
      open: '141.1000',
      high: '144.2500',
      low: '140.0100',
      close: '143.7000',
      'adjusted close': '140.0305',
      volume: '13648755',
      'dividend amount': '0.0000',
    },
    '2022-12-30': {
      open: '141.7300',
      high: '142.8100',
      low: '139.4500',
      close: '140.8900',
      'adjusted close': '137.2922',
      volume: '10477419',
      'dividend amount': '0.0000',
    },
    '2022-12-23': {
      open: '140.1600',
      high: '143.0900',
      low: '137.1950',
      close: '141.6500',
      'adjusted close': '138.0328',
      volume: '19663576',
      'dividend amount': '0.0000',
    },
    '2022-12-16': {
      open: '147.8200',
      high: '153.2100',
      low: '138.9700',
      close: '140.1600',
      'adjusted close': '136.5809',
      volume: '33572382',
      'dividend amount': '0.0000',
    },
    '2022-12-09': {
      open: '147.9400',
      high: '149.1530',
      low: '146.2900',
      close: '147.0500',
      'adjusted close': '143.2949',
      volume: '15316930',
      'dividend amount': '0.0000',
    },
    '2022-12-02': {
      open: '147.9800',
      high: '150.0100',
      low: '145.6700',
      close: '148.6700',
      'adjusted close': '144.8736',
      volume: '20066941',
      'dividend amount': '0.0000',
    },
    '2022-11-25': {
      open: '147.5500',
      high: '150.4600',
      low: '146.4500',
      close: '148.3700',
      'adjusted close': '144.5812',
      volume: '16271883',
      'dividend amount': '0.0000',
    },
    '2022-11-18': {
      open: '142.6300',
      high: '148.3100',
      low: '142.0000',
      close: '147.6400',
      'adjusted close': '143.8699',
      volume: '22034930',
      'dividend amount': '0.0000',
    },
    '2022-11-11': {
      open: '136.6400',
      high: '144.1300',
      low: '136.5100',
      close: '143.1700',
      'adjusted close': '139.5140',
      volume: '25066114',
      'dividend amount': '1.6500',
    },
    '2022-11-04': {
      open: '138.0600',
      high: '140.1700',
      low: '133.9700',
      close: '136.9600',
      'adjusted close': '131.8788',
      volume: '22491556',
      'dividend amount': '0.0000',
    },
    '2022-10-28': {
      open: '130.9000',
      high: '138.8615',
      low: '129.8500',
      close: '138.5100',
      'adjusted close': '133.3713',
      volume: '26667185',
      'dividend amount': '0.0000',
    },
    '2022-10-21': {
      open: '121.8000',
      high: '130.8450',
      low: '121.4300',
      close: '129.9000',
      'adjusted close': '125.0807',
      volume: '37309868',
      'dividend amount': '0.0000',
    },
    '2022-10-14': {
      open: '119.7900',
      high: '122.5400',
      low: '115.5450',
      close: '120.0400',
      'adjusted close': '115.5865',
      volume: '22973512',
      'dividend amount': '0.0000',
    },
    '2022-10-07': {
      open: '120.1600',
      high: '126.4600',
      low: '118.0700',
      close: '118.8200',
      'adjusted close': '114.4118',
      volume: '21614952',
      'dividend amount': '0.0000',
    },
    '2022-09-30': {
      open: '122.3000',
      high: '124.2600',
      low: '118.6100',
      close: '118.8100',
      'adjusted close': '114.4021',
      volume: '22265241',
      'dividend amount': '0.0000',
    },
    '2022-09-23': {
      open: '126.4900',
      high: '128.0600',
      low: '121.7400',
      close: '122.7100',
      'adjusted close': '118.1574',
      volume: '19442664',
      'dividend amount': '0.0000',
    },
    '2022-09-16': {
      open: '130.3300',
      high: '130.9900',
      low: '123.8300',
      close: '127.2700',
      'adjusted close': '122.5483',
      volume: '27107187',
      'dividend amount': '0.0000',
    },
    '2022-09-09': {
      open: '127.8000',
      high: '129.4900',
      low: '126.2800',
      close: '129.1900',
      'adjusted close': '124.3970',
      volume: '12004834',
      'dividend amount': '0.0000',
    },
    '2022-09-02': {
      open: '129.9900',
      high: '131.4200',
      low: '127.2400',
      close: '127.7900',
      'adjusted close': '123.0490',
      volume: '15086608',
      'dividend amount': '0.0000',
    },
    '2022-08-26': {
      open: '137.6500',
      high: '137.8500',
      low: '130.3400',
      close: '130.3800',
      'adjusted close': '125.5429',
      volume: '16982107',
      'dividend amount': '0.0000',
    },
    '2022-08-19': {
      open: '132.9600',
      high: '139.3400',
      low: '132.2400',
      close: '138.3700',
      'adjusted close': '133.2365',
      volume: '17105977',
      'dividend amount': '0.0000',
    },
    '2022-08-12': {
      open: '133.1000',
      high: '134.0900',
      low: '129.1200',
      close: '134.0100',
      'adjusted close': '129.0382',
      volume: '17254110',
      'dividend amount': '1.6500',
    },
    '2022-08-05': {
      open: '130.7500',
      high: '132.8620',
      low: '130.5100',
      close: '132.4800',
      'adjusted close': '125.9597',
      volume: '17400572',
      'dividend amount': '0.0000',
    },
    '2022-07-29': {
      open: '128.4400',
      high: '131.0000',
      low: '127.5800',
      close: '130.7900',
      'adjusted close': '124.3529',
      volume: '22223785',
      'dividend amount': '0.0000',
    },
    '2022-07-22': {
      open: '140.1500',
      high: '140.3100',
      low: '125.1300',
      close: '128.2500',
      'adjusted close': '121.9379',
      volume: '66246811',
      'dividend amount': '0.0000',
    },
    '2022-07-15': {
      open: '140.6200',
      high: '141.8700',
      low: '135.0200',
      close: '139.9200',
      'adjusted close': '133.0335',
      volume: '21089228',
      'dividend amount': '0.0000',
    },
    '2022-07-08': {
      open: '139.9700',
      high: '141.3250',
      low: '135.2700',
      close: '140.4700',
      'adjusted close': '133.5565',
      volume: '16229131',
      'dividend amount': '0.0000',
    },
    '2022-07-01': {
      open: '142.2600',
      high: '144.1550',
      low: '139.2600',
      close: '141.1200',
      'adjusted close': '134.1745',
      volume: '21052787',
      'dividend amount': '0.0000',
    },
    '2022-06-24': {
      open: '135.9000',
      high: '142.3700',
      low: '135.9000',
      close: '142.0600',
      'adjusted close': '135.0682',
      volume: '23921844',
      'dividend amount': '0.0000',
    },
    '2022-06-17': {
      open: '133.9700',
      high: '138.4500',
      low: '132.8500',
      close: '135.0200',
      'adjusted close': '128.3747',
      volume: '34055621',
      'dividend amount': '0.0000',
    },
    '2022-06-10': {
      open: '142.9800',
      high: '144.7300',
      low: '135.2500',
      close: '136.1900',
      'adjusted close': '129.4871',
      volume: '18914084',
      'dividend amount': '0.0000',
    },
    '2022-06-03': {
      open: '138.2000',
      high: '142.5794',
      low: '136.8100',
      close: '141.1800',
      'adjusted close': '134.2315',
      volume: '19861566',
      'dividend amount': '0.0000',
    },
    '2022-05-27': {
      open: '129.5000',
      high: '139.7394',
      low: '129.4200',
      close: '139.2700',
      'adjusted close': '132.4155',
      volume: '19618090',
      'dividend amount': '0.0000',
    },
    '2022-05-20': {
      open: '133.1000',
      high: '138.3700',
      low: '125.8000',
      close: '128.4800',
      'adjusted close': '122.1566',
      volume: '27360441',
      'dividend amount': '0.0000',
    },
    '2022-05-13': {
      open: '134.4100',
      high: '136.3450',
      low: '128.4300',
      close: '133.6000',
      'adjusted close': '127.0246',
      volume: '31171489',
      'dividend amount': '1.6500',
    },
    '2022-05-06': {
      open: '133.0000',
      high: '137.9900',
      low: '130.8900',
      close: '137.6700',
      'adjusted close': '129.3073',
      volume: '27079586',
      'dividend amount': '0.0000',
    },
    '2022-04-29': {
      open: '137.5900',
      high: '139.8700',
      low: '132.0000',
      close: '132.2100',
      'adjusted close': '124.1789',
      volume: '24560464',
      'dividend amount': '0.0000',
    },
    '2022-04-22': {
      open: '126.6000',
      high: '141.8800',
      low: '125.5300',
      close: '138.2500',
      'adjusted close': '129.8520',
      volume: '47056765',
      'dividend amount': '0.0000',
    },
    '2022-04-14': {
      open: '127.9500',
      high: '130.5800',
      low: '124.9100',
      close: '126.5600',
      'adjusted close': '118.8721',
      volume: '15342641',
      'dividend amount': '0.0000',
    },
    '2022-04-08': {
      open: '130.2600',
      high: '131.2300',
      low: '126.7300',
      close: '127.7300',
      'adjusted close': '119.9711',
      volume: '16553021',
      'dividend amount': '0.0000',
    },
    '2022-04-01': {
      open: '130.8200',
      high: '133.0800',
      low: '128.0600',
      close: '130.1500',
      'adjusted close': '122.2441',
      volume: '19183786',
      'dividend amount': '0.0000',
    },
    '2022-03-25': {
      open: '129.0000',
      high: '131.4000',
      low: '127.4000',
      close: '131.3500',
      'adjusted close': '123.3712',
      volume: '15440952',
      'dividend amount': '0.0000',
    },
    '2022-03-18': {
      open: '124.4500',
      high: '128.9300',
      low: '122.6850',
      close: '128.7600',
      'adjusted close': '120.9385',
      volume: '22675632',
      'dividend amount': '0.0000',
    },
    '2022-03-11': {
      open: '126.4700',
      high: '128.3450',
      low: '123.1250',
      close: '123.9600',
      'adjusted close': '116.4301',
      volume: '23784887',
      'dividend amount': '0.0000',
    },
    '2022-03-04': {
      open: '122.2100',
      high: '127.3500',
      low: '120.7000',
      close: '126.6200',
      'adjusted close': '118.9285',
      volume: '26131658',
      'dividend amount': '0.0000',
    },
    '2022-02-25': {
      open: '124.2000',
      high: '125.0000',
      low: '118.8100',
      close: '124.1800',
      'adjusted close': '116.6367',
      volume: '20417161',
      'dividend amount': '0.0000',
    },
    '2022-02-18': {
      open: '132.5900',
      high: '132.6500',
      low: '123.6100',
      close: '124.3500',
      'adjusted close': '116.7964',
      volume: '26022824',
      'dividend amount': '0.0000',
    },
    '2022-02-11': {
      open: '137.4500',
      high: '138.3500',
      low: '132.3800',
      close: '132.6900',
      'adjusted close': '124.6298',
      volume: '23489144',
      'dividend amount': '1.6400',
    },
    '2022-02-04': {
      open: '134.0900',
      high: '138.8200',
      low: '132.3000',
      close: '137.1500',
      'adjusted close': '127.2558',
      volume: '27665550',
      'dividend amount': '0.0000',
    },
    '2022-01-28': {
      open: '127.9900',
      high: '137.3361',
      low: '124.1930',
      close: '134.5000',
      'adjusted close': '124.7970',
      volume: '52800401',
      'dividend amount': '0.0000',
    },
    '2022-01-21': {
      open: '132.9500',
      high: '133.9000',
      low: '129.2700',
      close: '129.3500',
      'adjusted close': '120.0185',
      volume: '20520487',
      'dividend amount': '0.0000',
    },
    '2022-01-14': {
      open: '134.4700',
      high: '136.2000',
      low: '127.9700',
      close: '134.2100',
      'adjusted close': '124.5279',
      volume: '32044685',
      'dividend amount': '0.0000',
    },
    '2022-01-07': {
      open: '134.0700',
      high: '142.2000',
      low: '132.5100',
      close: '134.8300',
      'adjusted close': '125.1032',
      volume: '36013766',
      'dividend amount': '0.0000',
    },
    '2021-12-31': {
      open: '130.6300',
      high: '134.9900',
      low: '129.9500',
      close: '133.6600',
      'adjusted close': '124.0176',
      volume: '18454937',
      'dividend amount': '0.0000',
    },
    '2021-12-23': {
      open: '125.7200',
      high: '130.9600',
      low: '124.7000',
      close: '130.6300',
      'adjusted close': '121.2062',
      volume: '17369625',
      'dividend amount': '0.0000',
    },
    '2021-12-17': {
      open: '123.7600',
      high: '128.6400',
      low: '120.7900',
      close: '127.4000',
      'adjusted close': '118.2092',
      volume: '35216850',
      'dividend amount': '0.0000',
    },
    '2021-12-10': {
      open: '119.4000',
      high: '125.3300',
      low: '119.4000',
      close: '124.0900',
      'adjusted close': '115.1380',
      volume: '25031512',
      'dividend amount': '0.0000',
    },
    '2021-12-03': {
      open: '118.6200',
      high: '119.6100',
      low: '116.4500',
      close: '118.8400',
      'adjusted close': '110.2667',
      volume: '36059651',
      'dividend amount': '0.0000',
    },
    '2021-11-26': {
      open: '116.0000',
      high: '118.8100',
      low: '114.5600',
      close: '115.8100',
      'adjusted close': '107.4553',
      volume: '17875027',
      'dividend amount': '0.0000',
    },
    '2021-11-19': {
      open: '119.5400',
      high: '120.1600',
      low: '115.2700',
      close: '116.0500',
      'adjusted close': '107.6780',
      volume: '24272797',
      'dividend amount': '0.0000',
    },
    '2021-11-12': {
      open: '123.9850',
      high: '124.7800',
      low: '118.7800',
      close: '118.9600',
      'adjusted close': '110.3781',
      volume: '29109912',
      'dividend amount': '1.6400',
    },
    '2021-11-05': {
      open: '125.0500',
      high: '127.2900',
      low: '119.9000',
      close: '123.6100',
      'adjusted close': '113.1570',
      volume: '29791780',
      'dividend amount': '0.0000',
    },
    '2021-10-29': {
      open: '127.5300',
      high: '128.6500',
      low: '124.6200',
      close: '125.1000',
      'adjusted close': '109.4847',
      volume: '34288134',
      'dividend amount': '0.0000',
    },
    '2021-10-22': {
      open: '144.0000',
      high: '144.9400',
      low: '126.6110',
      close: '127.8800',
      'adjusted close': '111.9177',
      volume: '59731582',
      'dividend amount': '0.0000',
    },
    '2021-10-15': {
      open: '143.5000',
      high: '144.8500',
      low: '139.6600',
      close: '144.6100',
      'adjusted close': '126.5594',
      volume: '16262687',
      'dividend amount': '0.0000',
    },
    '2021-10-08': {
      open: '142.7400',
      high: '146.0000',
      low: '140.8900',
      close: '143.2200',
      'adjusted close': '125.3429',
      volume: '27211291',
      'dividend amount': '0.0000',
    },
    '2021-10-01': {
      open: '137.9600',
      high: '143.9700',
      low: '136.4400',
      close: '143.3200',
      'adjusted close': '125.4304',
      volume: '23824191',
      'dividend amount': '0.0000',
    },
    '2021-09-24': {
      open: '133.9000',
      high: '138.4800',
      low: '132.7800',
      close: '137.4900',
      'adjusted close': '120.3281',
      volume: '18425230',
      'dividend amount': '0.0000',
    },
    '2021-09-17': {
      open: '138.4000',
      high: '138.9900',
      low: '135.0500',
      close: '135.2300',
      'adjusted close': '118.3502',
      volume: '20130213',
      'dividend amount': '0.0000',
    },
    '2021-09-10': {
      open: '139.6500',
      high: '139.7900',
      low: '137.0000',
      close: '137.0200',
      'adjusted close': '119.9168',
      volume: '13754250',
      'dividend amount': '0.0000',
    },
    '2021-09-03': {
      open: '139.5000',
      high: '140.9400',
      low: '138.8150',
      close: '139.5800',
      'adjusted close': '122.1573',
      volume: '13345045',
      'dividend amount': '0.0000',
    },
    '2021-08-27': {
      open: '139.6200',
      high: '140.8000',
      low: '138.4000',
      close: '139.4100',
      'adjusted close': '122.0085',
      volume: '12376600',
      'dividend amount': '0.0000',
    },
    '2021-08-20': {
      open: '143.2300',
      high: '143.7400',
      low: '137.2100',
      close: '139.1100',
      'adjusted close': '121.7459',
      volume: '16189007',
      'dividend amount': '0.0000',
    },
    '2021-08-13': {
      open: '142.2000',
      high: '143.5800',
      low: '140.3400',
      close: '143.1800',
      'adjusted close': '125.3079',
      volume: '18462255',
      'dividend amount': '1.6400',
    },
    '2021-08-06': {
      open: '141.4500',
      high: '144.7000',
      low: '141.0300',
      close: '144.0900',
      'adjusted close': '124.6570',
      volume: '16428567',
      'dividend amount': '0.0000',
    },
    '2021-07-30': {
      open: '141.3900',
      high: '143.6400',
      low: '140.7900',
      close: '140.9600',
      'adjusted close': '121.9491',
      volume: '16120616',
      'dividend amount': '0.0000',
    },
    '2021-07-23': {
      open: '136.4500',
      high: '144.9200',
      low: '136.2089',
      close: '141.3400',
      'adjusted close': '122.2779',
      volume: '34786264',
      'dividend amount': '0.0000',
    },
    '2021-07-16': {
      open: '141.4300',
      high: '141.9599',
      low: '138.5900',
      close: '138.9000',
      'adjusted close': '120.1669',
      volume: '18659679',
      'dividend amount': '0.0000',
    },
    '2021-07-09': {
      open: '139.9900',
      high: '141.9800',
      low: '137.1000',
      close: '141.5200',
      'adjusted close': '122.4336',
      volume: '21544898',
      'dividend amount': '0.0000',
    },
    '2021-07-02': {
      open: '147.0100',
      high: '147.5000',
      low: '139.4600',
      close: '140.0200',
      'adjusted close': '121.1359',
      volume: '29077036',
      'dividend amount': '0.0000',
    },
    '2021-06-25': {
      open: '144.1100',
      high: '147.0700',
      low: '144.0600',
      close: '146.8400',
      'adjusted close': '127.0361',
      volume: '17129373',
      'dividend amount': '0.0000',
    },
    '2021-06-18': {
      open: '150.7100',
      high: '151.0300',
      low: '143.0400',
      close: '143.1200',
      'adjusted close': '123.8178',
      volume: '23177438',
      'dividend amount': '0.0000',
    },
    '2021-06-11': {
      open: '147.5500',
      high: '152.8400',
      low: '147.1700',
      close: '151.2800',
      'adjusted close': '130.8773',
      volume: '22042806',
      'dividend amount': '0.0000',
    },
    '2021-06-04': {
      open: '145.0000',
      high: '147.5500',
      low: '143.7500',
      close: '147.4200',
      'adjusted close': '127.5379',
      volume: '12453017',
      'dividend amount': '0.0000',
    },
    '2021-05-28': {
      open: '145.0600',
      high: '145.3900',
      low: '143.0400',
      close: '143.7400',
      'adjusted close': '124.3542',
      volume: '18483838',
      'dividend amount': '0.0000',
    },
    '2021-05-21': {
      open: '144.4400',
      high: '145.8000',
      low: '140.9200',
      close: '144.7400',
      'adjusted close': '125.2193',
      volume: '20546126',
      'dividend amount': '0.0000',
    },
    '2021-05-14': {
      open: '145.8000',
      high: '148.3800',
      low: '141.1400',
      close: '144.6800',
      'adjusted close': '125.1674',
      volume: '27415665',
      'dividend amount': '0.0000',
    },
    '2021-05-07': {
      open: '143.8100',
      high: '148.5150',
      low: '143.5500',
      close: '145.4600',
      'adjusted close': '125.8422',
      volume: '31590796',
      'dividend amount': '1.6400',
    },
    '2021-04-30': {
      open: '142.4200',
      high: '148.7400',
      low: '140.5500',
      close: '141.8800',
      'adjusted close': '121.3766',
      volume: '25588990',
      'dividend amount': '0.0000',
    },
    '2021-04-23': {
      open: '133.6000',
      high: '144.7400',
      low: '132.5800',
      close: '142.4300',
      'adjusted close': '121.8471',
      volume: '47246234',
      'dividend amount': '0.0000',
    },
    '2021-04-16': {
      open: '135.0200',
      high: '135.3700',
      low: '130.3800',
      close: '133.5900',
      'adjusted close': '114.2846',
      volume: '26831249',
      'dividend amount': '0.0000',
    },
    '2021-04-09': {
      open: '133.6400',
      high: '136.6900',
      low: '133.4000',
      close: '135.7300',
      'adjusted close': '116.1153',
      volume: '19179860',
      'dividend amount': '0.0000',
    },
    '2021-04-01': {
      open: '135.9800',
      high: '137.0700',
      low: '132.2700',
      close: '133.2300',
      'adjusted close': '113.9766',
      volume: '18432506',
      'dividend amount': '0.0000',
    },
    '2021-03-26': {
      open: '128.5000',
      high: '136.4800',
      low: '127.8900',
      close: '136.3800',
      'adjusted close': '116.6714',
      volume: '23647858',
      'dividend amount': '0.0000',
    },
    '2021-03-19': {
      open: '127.7700',
      high: '130.9950',
      low: '127.3400',
      close: '128.9000',
      'adjusted close': '110.2723',
      volume: '28046462',
      'dividend amount': '0.0000',
    },
    '2021-03-12': {
      open: '122.9900',
      high: '128.6400',
      low: '122.8800',
      close: '127.6100',
      'adjusted close': '109.1688',
      volume: '29253064',
      'dividend amount': '0.0000',
    },
    '2021-03-05': {
      open: '120.3500',
      high: '123.7500',
      low: '118.7550',
      close: '122.8300',
      'adjusted close': '105.0795',
      volume: '32662092',
      'dividend amount': '0.0000',
    },
    '2021-02-26': {
      open: '118.5000',
      high: '124.3500',
      low: '118.4400',
      close: '118.9300',
      'adjusted close': '101.7431',
      volume: '30731807',
      'dividend amount': '0.0000',
    },
    '2021-02-19': {
      open: '120.1500',
      high: '120.9400',
      low: '118.3800',
      close: '118.9900',
      'adjusted close': '101.7945',
      volume: '22567552',
      'dividend amount': '0.0000',
    },
    '2021-02-12': {
      open: '122.6200',
      high: '123.9767',
      low: '120.0900',
      close: '120.8000',
      'adjusted close': '103.3429',
      volume: '24634263',
      'dividend amount': '1.6300',
    },
    '2021-02-05': {
      open: '119.9000',
      high: '121.8100',
      low: '118.1200',
      close: '121.7900',
      'adjusted close': '102.8172',
      volume: '28405606',
      'dividend amount': '0.0000',
    },
    '2021-01-29': {
      open: '118.7624',
      high: '124.7900',
      low: '117.4500',
      close: '119.1100',
      'adjusted close': '100.5547',
      volume: '56437187',
      'dividend amount': '0.0000',
    },
    '2021-01-22': {
      open: '129.2800',
      high: '132.2400',
      low: '117.3600',
      close: '118.6100',
      'adjusted close': '100.1326',
      volume: '61879427',
      'dividend amount': '0.0000',
    },
    '2021-01-15': {
      open: '127.9500',
      high: '130.1600',
      low: '126.4550',
      close: '128.3900',
      'adjusted close': '108.3891',
      volume: '29417959',
      'dividend amount': '0.0000',
    },
    '2021-01-08': {
      open: '125.8500',
      high: '131.8800',
      low: '123.0400',
      close: '128.5300',
      'adjusted close': '108.5073',
      volume: '28434389',
      'dividend amount': '0.0000',
    },
    '2020-12-31': {
      open: '125.1000',
      high: '126.6000',
      low: '123.2400',
      close: '125.8800',
      'adjusted close': '106.2701',
      volume: '14025419',
      'dividend amount': '0.0000',
    },
    '2020-12-24': {
      open: '123.9700',
      high: '125.2100',
      low: '121.7200',
      close: '124.6900',
      'adjusted close': '105.2655',
      volume: '14908439',
      'dividend amount': '0.0000',
    },
    '2020-12-18': {
      open: '125.3200',
      high: '126.5728',
      low: '123.4400',
      close: '125.8500',
      'adjusted close': '106.2448',
      volume: '25280527',
      'dividend amount': '0.0000',
    },
    '2020-12-11': {
      open: '126.4900',
      high: '127.6900',
      low: '123.6100',
      close: '124.2700',
      'adjusted close': '104.9109',
      volume: '29511629',
      'dividend amount': '0.0000',
    },
    '2020-12-04': {
      open: '124.1000',
      high: '127.3800',
      low: '122.4100',
      close: '127.2000',
      'adjusted close': '107.3845',
      volume: '24848983',
      'dividend amount': '0.0000',
    },
    '2020-11-27': {
      open: '117.4300',
      high: '125.3130',
      low: '117.2700',
      close: '124.3500',
      'adjusted close': '104.9784',
      volume: '19418148',
      'dividend amount': '0.0000',
    },
    '2020-11-20': {
      open: '118.3000',
      high: '118.8800',
      low: '115.8900',
      close: '116.9400',
      'adjusted close': '98.7228',
      volume: '22498909',
      'dividend amount': '0.0000',
    },
    '2020-11-13': {
      open: '117.8800',
      high: '119.7400',
      low: '113.4800',
      close: '116.8500',
      'adjusted close': '98.6468',
      volume: '30088820',
      'dividend amount': '1.6300',
    },
    '2020-11-06': {
      open: '112.6500',
      high: '115.6500',
      low: '111.1600',
      close: '114.0400',
      'adjusted close': '94.9351',
      volume: '25467232',
      'dividend amount': '0.0000',
    },
    '2020-10-30': {
      open: '114.4500',
      high: '114.9000',
      low: '105.9200',
      close: '111.6600',
      'adjusted close': '92.9538',
      volume: '37250916',
      'dividend amount': '0.0000',
    },
    '2020-10-23': {
      open: '126.8000',
      high: '127.3500',
      low: '112.9800',
      close: '116.0000',
      'adjusted close': '96.5668',
      volume: '50486636',
      'dividend amount': '0.0000',
    },
    '2020-10-16': {
      open: '128.0700',
      high: '128.2500',
      low: '123.8500',
      close: '125.9300',
      'adjusted close': '104.8332',
      volume: '21874963',
      'dividend amount': '0.0000',
    },
    '2020-10-09': {
      open: '121.8400',
      high: '135.5000',
      low: '121.0500',
      close: '127.7900',
      'adjusted close': '106.3816',
      volume: '43381507',
      'dividend amount': '0.0000',
    },
    '2020-10-02': {
      open: '120.5700',
      high: '123.3000',
      low: '118.8200',
      close: '120.5700',
      'adjusted close': '100.3712',
      volume: '15038264',
      'dividend amount': '0.0000',
    },
    '2020-09-25': {
      open: '120.4800',
      high: '121.5900',
      low: '116.4800',
      close: '118.9500',
      'adjusted close': '99.0226',
      volume: '18708494',
      'dividend amount': '0.0000',
    },
    '2020-09-18': {
      open: '122.3600',
      high: '125.8200',
      low: '121.7600',
      close: '122.7600',
      'adjusted close': '102.1943',
      volume: '18909398',
      'dividend amount': '0.0000',
    },
    '2020-09-11': {
      open: '122.1600',
      high: '123.7000',
      low: '120.2400',
      close: '121.4600',
      'adjusted close': '101.1121',
      volume: '16507866',
      'dividend amount': '0.0000',
    },
    '2020-09-04': {
      open: '125.2500',
      high: '129.9500',
      low: '121.2400',
      close: '122.3000',
      'adjusted close': '101.8113',
      volume: '26310920',
      'dividend amount': '0.0000',
    },
    '2020-08-28': {
      open: '123.7900',
      high: '126.8200',
      low: '123.3600',
      close: '125.0700',
      'adjusted close': '104.1173',
      volume: '16959794',
      'dividend amount': '0.0000',
    },
    '2020-08-21': {
      open: '125.2500',
      high: '125.5900',
      low: '122.3100',
      close: '123.1600',
      'adjusted close': '102.5273',
      volume: '15908978',
      'dividend amount': '0.0000',
    },
    '2020-08-14': {
      open: '125.4200',
      high: '130.4700',
      low: '123.9100',
      close: '125.2700',
      'adjusted close': '104.2838',
      volume: '18634659',
      'dividend amount': '0.0000',
    },
    '2020-08-07': {
      open: '123.5000',
      high: '126.7500',
      low: '122.1500',
      close: '124.9600',
      'adjusted close': '104.0257',
      volume: '17701697',
      'dividend amount': '1.6300',
    },
    '2020-07-31': {
      open: '124.8600',
      high: '126.3400',
      low: '121.0900',
      close: '122.9400',
      'adjusted close': '101.0263',
      volume: '20010578',
      'dividend amount': '0.0000',
    },
    '2020-07-24': {
      open: '126.0700',
      high: '132.1700',
      low: '125.1400',
      close: '125.7900',
      'adjusted close': '103.3683',
      volume: '41040414',
      'dividend amount': '0.0000',
    },
    '2020-07-17': {
      open: '119.7800',
      high: '125.6300',
      low: '117.9200',
      close: '125.1100',
      'adjusted close': '102.8095',
      volume: '21958911',
      'dividend amount': '0.0000',
    },
    '2020-07-10': {
      open: '121.2500',
      high: '121.8500',
      low: '115.2000',
      close: '118.3500',
      'adjusted close': '97.2545',
      volume: '22560031',
      'dividend amount': '0.0000',
    },
    '2020-07-02': {
      open: '117.9800',
      high: '121.4200',
      low: '117.3700',
      close: '119.7000',
      'adjusted close': '98.3638',
      volume: '16478700',
      'dividend amount': '0.0000',
    },
    '2020-06-26': {
      open: '122.1200',
      high: '122.9000',
      low: '115.8800',
      close: '117.1900',
      'adjusted close': '96.3012',
      volume: '35016186',
      'dividend amount': '0.0000',
    },
    '2020-06-19': {
      open: '119.1900',
      high: '127.5000',
      low: '118.2900',
      close: '122.4700',
      'adjusted close': '100.6401',
      volume: '24867689',
      'dividend amount': '0.0000',
    },
    '2020-06-12': {
      open: '132.2700',
      high: '135.8800',
      low: '117.8400',
      close: '121.9100',
      'adjusted close': '100.1799',
      volume: '33456639',
      'dividend amount': '0.0000',
    },
    '2020-06-05': {
      open: '124.6400',
      high: '134.2300',
      low: '124.0300',
      close: '132.0600',
      'adjusted close': '108.5207',
      volume: '19514867',
      'dividend amount': '0.0000',
    },
    '2020-05-29': {
      open: '121.3000',
      high: '126.9700',
      low: '121.0100',
      close: '124.9000',
      'adjusted close': '102.6370',
      volume: '23037728',
      'dividend amount': '0.0000',
    },
    '2020-05-22': {
      open: '119.8800',
      high: '122.4700',
      low: '117.5900',
      close: '118.3900',
      'adjusted close': '97.2873',
      volume: '19484187',
      'dividend amount': '0.0000',
    },
    '2020-05-15': {
      open: '121.8100',
      high: '124.3200',
      low: '111.8100',
      close: '116.9800',
      'adjusted close': '96.1287',
      volume: '24229073',
      'dividend amount': '0.0000',
    },
    '2020-05-08': {
      open: '120.8200',
      high: '124.3200',
      low: '119.3900',
      close: '122.9900',
      'adjusted close': '101.0674',
      volume: '21189890',
      'dividend amount': '1.6300',
    },
    '2020-05-01': {
      open: '125.5600',
      high: '129.3100',
      low: '121.3900',
      close: '121.8700',
      'adjusted close': '98.8184',
      volume: '26502989',
      'dividend amount': '0.0000',
    },
    '2020-04-24': {
      open: '119.1500',
      high: '125.0000',
      low: '112.0600',
      close: '124.7200',
      'adjusted close': '101.1293',
      volume: '41252209',
      'dividend amount': '0.0000',
    },
    '2020-04-17': {
      open: '121.6300',
      high: '125.1800',
      low: '114.4200',
      close: '120.1200',
      'adjusted close': '97.3994',
      volume: '27240371',
      'dividend amount': '0.0000',
    },
    '2020-04-09': {
      open: '110.3500',
      high: '122.9200',
      low: '110.1300',
      close: '121.5000',
      'adjusted close': '98.5184',
      volume: '23351117',
      'dividend amount': '0.0000',
    },
    '2020-04-03': {
      open: '108.0900',
      high: '113.8100',
      low: '104.5200',
      close: '106.3400',
      'adjusted close': '86.2259',
      volume: '28882211',
      'dividend amount': '0.0000',
    },
    '2020-03-27': {
      open: '94.6000',
      high: '113.1500',
      low: '90.5600',
      close: '108.0300',
      'adjusted close': '87.5962',
      volume: '39907532',
      'dividend amount': '0.0000',
    },
    '2020-03-20': {
      open: '98.0000',
      high: '107.4100',
      low: '94.7100',
      close: '95.3900',
      'adjusted close': '77.3471',
      volume: '47138046',
      'dividend amount': '0.0000',
    },
    '2020-03-13': {
      open: '120.1600',
      high: '124.8800',
      low: '100.8100',
      close: '107.9500',
      'adjusted close': '87.5313',
      volume: '55580046',
      'dividend amount': '0.0000',
    },
    '2020-03-06': {
      open: '130.7500',
      high: '136.1000',
      low: '124.5200',
      close: '127.7300',
      'adjusted close': '103.5700',
      volume: '35054761',
      'dividend amount': '0.0000',
    },
    '2020-02-28': {
      open: '145.5100',
      high: '148.0500',
      low: '126.3600',
      close: '130.1500',
      'adjusted close': '105.5322',
      volume: '38480655',
      'dividend amount': '0.0000',
    },
    '2020-02-21': {
      open: '149.7900',
      high: '151.8900',
      low: '148.8400',
      close: '149.8400',
      'adjusted close': '121.4979',
      volume: '12493166',
      'dividend amount': '0.0000',
    },
    '2020-02-14': {
      open: '152.9700',
      high: '155.6000',
      low: '149.7700',
      close: '150.7000',
      'adjusted close': '122.1952',
      volume: '23423757',
      'dividend amount': '0.0000',
    },
    '2020-02-07': {
      open: '144.2500',
      high: '158.7500',
      low: '143.9000',
      close: '153.4100',
      'adjusted close': '124.3926',
      volume: '43694755',
      'dividend amount': '1.6200',
    },
    '2020-01-31': {
      open: '138.5000',
      high: '144.0500',
      low: '134.9700',
      close: '143.7300',
      'adjusted close': '115.3257',
      volume: '34661511',
      'dividend amount': '0.0000',
    },
    '2020-01-24': {
      open: '137.8100',
      high: '145.7900',
      low: '137.6000',
      close: '140.5600',
      'adjusted close': '112.7822',
      volume: '34829367',
      'dividend amount': '0.0000',
    },
    '2020-01-17': {
      open: '135.4800',
      high: '138.3300',
      low: '135.0700',
      close: '138.3100',
      'adjusted close': '110.9769',
      volume: '20831539',
      'dividend amount': '0.0000',
    },
    '2020-01-10': {
      open: '133.4200',
      high: '137.8700',
      low: '133.2000',
      close: '136.6900',
      'adjusted close': '109.6770',
      volume: '17020619',
      'dividend amount': '0.0000',
    },
    '2020-01-03': {
      open: '135.2000',
      high: '135.9200',
      low: '132.4000',
      close: '134.3400',
      'adjusted close': '107.7914',
      volume: '13418364',
      'dividend amount': '0.0000',
    },
    '2019-12-27': {
      open: '135.7800',
      high: '136.1500',
      low: '134.6100',
      close: '135.2700',
      'adjusted close': '108.5376',
      volume: '8887084',
      'dividend amount': '0.0000',
    },
    '2019-12-20': {
      open: '134.9400',
      high: '136.4200',
      low: '133.4600',
      close: '135.5900',
      'adjusted close': '108.7944',
      volume: '20036502',
      'dividend amount': '0.0000',
    },
    '2019-12-13': {
      open: '133.3500',
      high: '135.6600',
      low: '133.2600',
      close: '134.2100',
      'adjusted close': '107.6871',
      volume: '17504239',
      'dividend amount': '0.0000',
    },
    '2019-12-06': {
      open: '134.4500',
      high: '134.5000',
      low: '130.6900',
      close: '133.2200',
      'adjusted close': '106.8928',
      volume: '17337693',
      'dividend amount': '0.0000',
    },
    '2019-11-29': {
      open: '134.4700',
      high: '136.1500',
      low: '133.0300',
      close: '134.4500',
      'adjusted close': '107.8797',
      volume: '11502959',
      'dividend amount': '0.0000',
    },
    '2019-11-22': {
      open: '134.3000',
      high: '135.3800',
      low: '132.7500',
      close: '134.3400',
      'adjusted close': '107.7914',
      volume: '16963107',
      'dividend amount': '0.0000',
    },
    '2019-11-15': {
      open: '137.2000',
      high: '137.2000',
      low: '133.3300',
      close: '134.4000',
      'adjusted close': '107.8396',
      volume: '16169172',
      'dividend amount': '0.0000',
    },
    '2019-11-08': {
      open: '136.2400',
      high: '139.1400',
      low: '136.1600',
      close: '137.6100',
      'adjusted close': '110.4152',
      volume: '17174941',
      'dividend amount': '1.6200',
    },
    '2019-11-01': {
      open: '136.0000',
      high: '136.6300',
      low: '133.2000',
      close: '135.5300',
      'adjusted close': '107.4817',
      volume: '16136475',
      'dividend amount': '0.0000',
    },
    '2019-10-25': {
      open: '132.6100',
      high: '135.9300',
      low: '130.9000',
      close: '135.4400',
      'adjusted close': '107.4103',
      volume: '19388201',
      'dividend amount': '0.0000',
    },
    '2019-10-18': {
      open: '142.3100',
      high: '143.7200',
      low: '132.2500',
      close: '134.0900',
      'adjusted close': '106.3397',
      volume: '34208011',
      'dividend amount': '0.0000',
    },
    '2019-10-11': {
      open: '142.2600',
      high: '144.5000',
      low: '138.2500',
      close: '142.7600',
      'adjusted close': '113.2154',
      volume: '14096608',
      'dividend amount': '0.0000',
    },
    '2019-10-04': {
      open: '143.7300',
      high: '147.3500',
      low: '139.1800',
      close: '142.9900',
      'adjusted close': '113.3978',
      volume: '15911420',
      'dividend amount': '0.0000',
    },
    '2019-09-27': {
      open: '141.1900',
      high: '145.0900',
      low: '140.5600',
      close: '143.2400',
      'adjusted close': '113.5961',
      volume: '12376163',
      'dividend amount': '0.0000',
    },
    '2019-09-20': {
      open: '142.5600',
      high: '145.0300',
      low: '140.5100',
      close: '141.8800',
      'adjusted close': '112.5175',
      volume: '15262993',
      'dividend amount': '0.0000',
    },
    '2019-09-13': {
      open: '140.5900',
      high: '145.4600',
      low: '140.4600',
      close: '143.6700',
      'adjusted close': '113.9371',
      volume: '17776693',
      'dividend amount': '0.0000',
    },
    '2019-09-06': {
      open: '134.8500',
      high: '141.7000',
      low: '133.3300',
      close: '140.5700',
      'adjusted close': '111.4786',
      volume: '12663912',
      'dividend amount': '0.0000',
    },
    '2019-08-30': {
      open: '131.0500',
      high: '136.0700',
      low: '129.1600',
      close: '135.5300',
      'adjusted close': '107.4817',
      volume: '16211916',
      'dividend amount': '0.0000',
    },
    '2019-08-23': {
      open: '134.8800',
      high: '136.3300',
      low: '128.8300',
      close: '129.5700',
      'adjusted close': '102.7551',
      volume: '16504912',
      'dividend amount': '0.0000',
    },
    '2019-08-16': {
      open: '135.6600',
      high: '136.5700',
      low: '130.2500',
      close: '133.7600',
      'adjusted close': '106.0780',
      volume: '20933133',
      'dividend amount': '0.0000',
    },
    '2019-08-09': {
      open: '144.9800',
      high: '145.1000',
      low: '135.3500',
      close: '136.1300',
      'adjusted close': '107.9575',
      volume: '28623442',
      'dividend amount': '1.6200',
    },
    '2019-08-02': {
      open: '151.1100',
      high: '152.9500',
      low: '145.5900',
      close: '147.2500',
      'adjusted close': '115.4413',
      volume: '22876887',
      'dividend amount': '0.0000',
    },
    '2019-07-26': {
      open: '150.1600',
      high: '151.9400',
      low: '148.3800',
      close: '151.3600',
      'adjusted close': '118.6635',
      volume: '16794485',
      'dividend amount': '0.0000',
    },
    '2019-07-19': {
      open: '142.8900',
      high: '151.5800',
      low: '141.9500',
      close: '149.6800',
      'adjusted close': '117.3464',
      volume: '31893327',
      'dividend amount': '0.0000',
    },
    '2019-07-12': {
      open: '141.0500',
      high: '142.9200',
      low: '139.1300',
      close: '142.7400',
      'adjusted close': '111.9056',
      volume: '13194248',
      'dividend amount': '0.0000',
    },
    '2019-07-05': {
      open: '139.6000',
      high: '141.8200',
      low: '139.2800',
      close: '141.3800',
      'adjusted close': '110.8393',
      volume: '9862722',
      'dividend amount': '0.0000',
    },
    '2019-06-28': {
      open: '139.2000',
      high: '140.1500',
      low: '137.8400',
      close: '137.9000',
      'adjusted close': '108.1111',
      volume: '15575818',
      'dividend amount': '0.0000',
    },
    '2019-06-21': {
      open: '135.3900',
      high: '139.5400',
      low: '134.7000',
      close: '139.2000',
      'adjusted close': '109.1303',
      volume: '15851123',
      'dividend amount': '0.0000',
    },
    '2019-06-14': {
      open: '134.3800',
      high: '136.4600',
      low: '133.9100',
      close: '135.1500',
      'adjusted close': '105.9551',
      volume: '13722199',
      'dividend amount': '0.0000',
    },
    '2019-06-07': {
      open: '127.1000',
      high: '134.7200',
      low: '127.0600',
      close: '133.3100',
      'adjusted close': '104.5126',
      volume: '16193845',
      'dividend amount': '0.0000',
    },
    '2019-05-31': {
      open: '132.1300',
      high: '132.6400',
      low: '126.8500',
      close: '126.9900',
      'adjusted close': '99.5578',
      volume: '14869322',
      'dividend amount': '0.0000',
    },
    '2019-05-24': {
      open: '133.5300',
      high: '137.0600',
      low: '130.4400',
      close: '132.2800',
      'adjusted close': '103.7051',
      volume: '15557532',
      'dividend amount': '0.0000',
    },
    '2019-05-17': {
      open: '133.2800',
      high: '136.1100',
      low: '130.9600',
      close: '134.3200',
      'adjusted close': '105.3044',
      volume: '16426011',
      'dividend amount': '0.0000',
    },
    '2019-05-10': {
      open: '138.3000',
      high: '140.6900',
      low: '132.4200',
      close: '135.3200',
      'adjusted close': '106.0884',
      volume: '19509963',
      'dividend amount': '1.6200',
    },
    '2019-05-03': {
      open: '139.1500',
      high: '141.8100',
      low: '138.6800',
      close: '140.2500',
      'adjusted close': '108.6529',
      volume: '16772230',
      'dividend amount': '0.0000',
    },
    '2019-04-26': {
      open: '139.4200',
      high: '141.3100',
      low: '137.7100',
      close: '139.4400',
      'adjusted close': '108.0254',
      volume: '16961387',
      'dividend amount': '0.0000',
    },
    '2019-04-18': {
      open: '144.4000',
      high: '145.3900',
      low: '136.2600',
      close: '140.3300',
      'adjusted close': '108.7149',
      volume: '27390537',
      'dividend amount': '0.0000',
    },
    '2019-04-12': {
      open: '143.0200',
      high: '144.4400',
      low: '141.8400',
      close: '144.3500',
      'adjusted close': '111.8292',
      volume: '13675882',
      'dividend amount': '0.0000',
    },
    '2019-04-05': {
      open: '141.5100',
      high: '144.2200',
      low: '141.5100',
      close: '143.2800',
      'adjusted close': '111.0002',
      volume: '14574262',
      'dividend amount': '0.0000',
    },
    '2019-03-29': {
      open: '139.0600',
      high: '141.2200',
      low: '138.3500',
      close: '141.1000',
      'adjusted close': '109.3114',
      volume: '14257045',
      'dividend amount': '0.0000',
    },
    '2019-03-22': {
      open: '139.8300',
      high: '142.1200',
      low: '138.7200',
      close: '139.4500',
      'adjusted close': '108.0331',
      volume: '17883337',
      'dividend amount': '0.0000',
    },
    '2019-03-15': {
      open: '136.0300',
      high: '140.3300',
      low: '135.8400',
      close: '139.4300',
      'adjusted close': '108.0176',
      volume: '20959826',
      'dividend amount': '0.0000',
    },
    '2019-03-08': {
      open: '139.9900',
      high: '140.0800',
      low: '133.5800',
      close: '135.0900',
      'adjusted close': '104.6554',
      volume: '18558647',
      'dividend amount': '0.0000',
    },
    '2019-03-01': {
      open: '140.0000',
      high: '140.4900',
      low: '137.7200',
      close: '139.2000',
      'adjusted close': '107.8394',
      volume: '15273342',
      'dividend amount': '0.0000',
    },
    '2019-02-22': {
      open: '137.8100',
      high: '139.3800',
      low: '137.2200',
      close: '139.2500',
      'adjusted close': '107.8782',
      volume: '13238819',
      'dividend amount': '0.0000',
    },
    '2019-02-15': {
      open: '134.2900',
      high: '138.1900',
      low: '133.9100',
      close: '138.0300',
      'adjusted close': '106.9330',
      volume: '17299173',
      'dividend amount': '0.0000',
    },
    '2019-02-08': {
      open: '134.0200',
      high: '136.6500',
      low: '132.1200',
      close: '133.7100',
      'adjusted close': '103.5863',
      volume: '21874687',
      'dividend amount': '1.5700',
    },
    '2019-02-01': {
      open: '133.1000',
      high: '135.4100',
      low: '132.5800',
      close: '134.1000',
      'adjusted close': '102.6781',
      volume: '23585862',
      'dividend amount': '0.0000',
    },
    '2019-01-25': {
      open: '123.3000',
      high: '135.0000',
      low: '121.5400',
      close: '133.9700',
      'adjusted close': '102.5785',
      volume: '44147478',
      'dividend amount': '0.0000',
    },
    '2019-01-18': {
      open: '120.5100',
      high: '124.7200',
      low: '119.7600',
      close: '123.8200',
      'adjusted close': '94.8069',
      volume: '23616108',
      'dividend amount': '0.0000',
    },
    '2019-01-11': {
      open: '117.5000',
      high: '121.8600',
      low: '116.6700',
      close: '121.4600',
      'adjusted close': '92.9999',
      volume: '19780844',
      'dividend amount': '0.0000',
    },
    '2019-01-04': {
      open: '113.3300',
      high: '117.4900',
      low: '111.6900',
      close: '117.3200',
      'adjusted close': '89.8299',
      volume: '18047093',
      'dividend amount': '0.0000',
    },
    '2018-12-28': {
      open: '109.9000',
      high: '114.8000',
      low: '105.9400',
      close: '113.0300',
      'adjusted close': '86.5451',
      volume: '21630293',
      'dividend amount': '0.0000',
    },
    '2018-12-21': {
      open: '119.0700',
      high: '120.2700',
      low: '110.4400',
      close: '110.9400',
      'adjusted close': '84.9449',
      volume: '39485288',
      'dividend amount': '0.0000',
    },
    '2018-12-14': {
      open: '119.7400',
      high: '123.8100',
      low: '118.5200',
      close: '119.9000',
      'adjusted close': '91.8054',
      volume: '23701178',
      'dividend amount': '0.0000',
    },
    '2018-12-07': {
      open: '125.6700',
      high: '126.5900',
      low: '118.8700',
      close: '119.3400',
      'adjusted close': '91.3766',
      volume: '25801474',
      'dividend amount': '0.0000',
    },
    '2018-11-30': {
      open: '118.3700',
      high: '124.6900',
      low: '118.0600',
      close: '124.2700',
      'adjusted close': '95.1514',
      volume: '27471124',
      'dividend amount': '0.0000',
    },
    '2018-11-23': {
      open: '121.6300',
      high: '122.1800',
      low: '116.7000',
      close: '117.1900',
      'adjusted close': '89.7304',
      volume: '18035587',
      'dividend amount': '0.0000',
    },
    '2018-11-16': {
      open: '123.5000',
      high: '123.5700',
      low: '119.5100',
      close: '121.5700',
      'adjusted close': '93.0841',
      volume: '23510061',
      'dividend amount': '0.0000',
    },
    '2018-11-09': {
      open: '116.1000',
      high: '125.0600',
      low: '116.0000',
      close: '123.5400',
      'adjusted close': '94.5925',
      volume: '48482997',
      'dividend amount': '1.5700',
    },
    '2018-11-02': {
      open: '119.3400',
      high: '123.9500',
      low: '114.0900',
      close: '115.6700',
      'adjusted close': '87.4537',
      volume: '87773431',
      'dividend amount': '0.0000',
    },
    '2018-10-26': {
      open: '129.5800',
      high: '131.9000',
      low: '123.7100',
      close: '124.7900',
      'adjusted close': '94.3490',
      volume: '36964774',
      'dividend amount': '0.0000',
    },
    '2018-10-19': {
      open: '140.3800',
      high: '145.4500',
      low: '127.9600',
      close: '129.1000',
      'adjusted close': '97.6076',
      volume: '50928336',
      'dividend amount': '0.0000',
    },
    '2018-10-12': {
      open: '148.5000',
      high: '149.0700',
      low: '138.7800',
      close: '140.8500',
      'adjusted close': '106.4914',
      volume: '27703434',
      'dividend amount': '0.0000',
    },
    '2018-10-05': {
      open: '151.7100',
      high: '154.3600',
      low: '147.8700',
      close: '149.0300',
      'adjusted close': '112.6759',
      volume: '18943237',
      'dividend amount': '0.0000',
    },
    '2018-09-28': {
      open: '151.1000',
      high: '153.3500',
      low: '148.3600',
      close: '151.2100',
      'adjusted close': '114.3242',
      volume: '22847744',
      'dividend amount': '0.0000',
    },
    '2018-09-21': {
      open: '148.4400',
      high: '152.6000',
      low: '147.4700',
      close: '151.3500',
      'adjusted close': '114.4300',
      volume: '37327961',
      'dividend amount': '0.0000',
    },
    '2018-09-14': {
      open: '146.6000',
      high: '149.3000',
      low: '145.2500',
      close: '148.3300',
      'adjusted close': '112.1467',
      volume: '20298346',
      'dividend amount': '0.0000',
    },
    '2018-09-07': {
      open: '145.9800',
      high: '147.6600',
      low: '144.8100',
      close: '145.4500',
      'adjusted close': '109.9692',
      volume: '15394651',
      'dividend amount': '0.0000',
    },
    '2018-08-31': {
      open: '146.9400',
      high: '147.9300',
      low: '145.2500',
      close: '146.4800',
      'adjusted close': '110.7480',
      volume: '15929583',
      'dividend amount': '0.0000',
    },
    '2018-08-24': {
      open: '146.3700',
      high: '147.1600',
      low: '144.7500',
      close: '146.0400',
      'adjusted close': '110.4153',
      volume: '12992907',
      'dividend amount': '0.0000',
    },
    '2018-08-17': {
      open: '144.1500',
      high: '146.3900',
      low: '142.0000',
      close: '146.0600',
      'adjusted close': '110.4304',
      volume: '17890077',
      'dividend amount': '0.0000',
    },
    '2018-08-10': {
      open: '146.9500',
      high: '147.6400',
      low: '143.8800',
      close: '144.4800',
      'adjusted close': '109.2359',
      volume: '17100909',
      'dividend amount': '1.5700',
    },
    '2018-08-03': {
      open: '144.8000',
      high: '147.9200',
      low: '142.3300',
      close: '147.7000',
      'adjusted close': '110.4723',
      volume: '24366161',
      'dividend amount': '0.0000',
    },
    '2018-07-27': {
      open: '146.3500',
      high: '149.2700',
      low: '144.6600',
      close: '145.1500',
      'adjusted close': '108.5651',
      volume: '19940363',
      'dividend amount': '0.0000',
    },
    '2018-07-20': {
      open: '145.6700',
      high: '150.5400',
      low: '142.7400',
      close: '146.3500',
      'adjusted close': '109.4626',
      volume: '36572622',
      'dividend amount': '0.0000',
    },
    '2018-07-13': {
      open: '142.5900',
      high: '146.9800',
      low: '142.4700',
      close: '145.9000',
      'adjusted close': '109.1260',
      volume: '17395351',
      'dividend amount': '0.0000',
    },
    '2018-07-06': {
      open: '138.2800',
      high: '142.9400',
      low: '138.2000',
      close: '142.4800',
      'adjusted close': '106.5680',
      volume: '12024030',
      'dividend amount': '0.0000',
    },
    '2018-06-29': {
      open: '140.4000',
      high: '141.3600',
      low: '137.4500',
      close: '139.7000',
      'adjusted close': '104.4887',
      volume: '25451478',
      'dividend amount': '0.0000',
    },
    '2018-06-22': {
      open: '144.1800',
      high: '144.5200',
      low: '140.7800',
      close: '141.2800',
      'adjusted close': '105.6705',
      volume: '21004666',
      'dividend amount': '0.0000',
    },
    '2018-06-15': {
      open: '146.6200',
      high: '147.5200',
      low: '143.7000',
      close: '145.3900',
      'adjusted close': '108.7446',
      volume: '21311072',
      'dividend amount': '0.0000',
    },
    '2018-06-08': {
      open: '142.3000',
      high: '146.7000',
      low: '142.1800',
      close: '146.1400',
      'adjusted close': '109.3055',
      volume: '18618365',
      'dividend amount': '0.0000',
    },
    '2018-06-01': {
      open: '142.5300',
      high: '143.1000',
      low: '140.2100',
      close: '141.9500',
      'adjusted close': '106.1716',
      volume: '20343954',
      'dividend amount': '0.0000',
    },
    '2018-05-25': {
      open: '144.9500',
      high: '146.2000',
      low: '142.9500',
      close: '143.6400',
      'adjusted close': '107.4356',
      volume: '16951460',
      'dividend amount': '0.0000',
    },
    '2018-05-18': {
      open: '144.4700',
      high: '145.1900',
      low: '142.9200',
      close: '144.0800',
      'adjusted close': '107.7647',
      volume: '16304020',
      'dividend amount': '0.0000',
    },
    '2018-05-11': {
      open: '144.0000',
      high: '144.8500',
      low: '141.2800',
      close: '144.1400',
      'adjusted close': '107.8096',
      volume: '20021856',
      'dividend amount': '1.5700',
    },
    '2018-05-04': {
      open: '146.8600',
      high: '147.3800',
      low: '139.9000',
      close: '143.9100',
      'adjusted close': '106.4655',
      volume: '23464605',
      'dividend amount': '0.0000',
    },
    '2018-04-27': {
      open: '145.0300',
      high: '147.3600',
      low: '144.1100',
      close: '146.4800',
      'adjusted close': '108.3668',
      volume: '25028049',
      'dividend amount': '0.0000',
    },
    '2018-04-20': {
      open: '157.9900',
      high: '162.0000',
      low: '144.5100',
      close: '144.9000',
      'adjusted close': '107.1979',
      volume: '51127683',
      'dividend amount': '0.0000',
    },
    '2018-04-13': {
      open: '151.8000',
      high: '159.2200',
      low: '151.7400',
      close: '156.7100',
      'adjusted close': '115.9350',
      volume: '21372171',
      'dividend amount': '0.0000',
    },
    '2018-04-06': {
      open: '153.3400',
      high: '154.9200',
      low: '147.4500',
      close: '150.5700',
      'adjusted close': '111.3926',
      volume: '20949699',
      'dividend amount': '0.0000',
    },
    '2018-03-29': {
      open: '151.2100',
      high: '154.8700',
      low: '150.2800',
      close: '153.4300',
      'adjusted close': '113.5085',
      volume: '14934365',
      'dividend amount': '0.0000',
    },
    '2018-03-23': {
      open: '159.7100',
      high: '159.8900',
      low: '148.5400',
      close: '148.8900',
      'adjusted close': '110.1497',
      volume: '21959274',
      'dividend amount': '0.0000',
    },
    '2018-03-16': {
      open: '159.6400',
      high: '162.1100',
      low: '157.7400',
      close: '160.2600',
      'adjusted close': '118.5613',
      volume: '25076217',
      'dividend amount': '0.0000',
    },
    '2018-03-09': {
      open: '154.1200',
      high: '159.5800',
      low: '153.7500',
      close: '159.3100',
      'adjusted close': '117.8585',
      volume: '22842811',
      'dividend amount': '0.0000',
    },
    '2018-03-02': {
      open: '155.8100',
      high: '159.7800',
      low: '151.8800',
      close: '154.4900',
      'adjusted close': '114.2927',
      volume: '18831762',
      'dividend amount': '0.0000',
    },
    '2018-02-23': {
      open: '155.7900',
      high: '156.7900',
      low: '152.7900',
      close: '155.5200',
      'adjusted close': '115.0547',
      volume: '14987102',
      'dividend amount': '0.0000',
    },
    '2018-02-16': {
      open: '150.9000',
      high: '157.7900',
      low: '149.6400',
      close: '156.1800',
      'adjusted close': '115.5429',
      volume: '25701914',
      'dividend amount': '0.0000',
    },
    '2018-02-09': {
      open: '157.8900',
      high: '158.5000',
      low: '144.4000',
      close: '149.5100',
      'adjusted close': '110.6084',
      volume: '38856237',
      'dividend amount': '1.5000',
    },
    '2018-02-02': {
      open: '167.4500',
      high: '168.3900',
      low: '158.8700',
      close: '159.0300',
      'adjusted close': '116.4677',
      volume: '21842492',
      'dividend amount': '0.0000',
    },
    '2018-01-26': {
      open: '161.5100',
      high: '168.7200',
      low: '161.1100',
      close: '167.3400',
      'adjusted close': '122.5536',
      volume: '28170112',
      'dividend amount': '0.0000',
    },
    '2018-01-19': {
      open: '165.1000',
      high: '171.1300',
      low: '161.3000',
      close: '162.3700',
      'adjusted close': '118.9138',
      volume: '51246304',
      'dividend amount': '0.0000',
    },
    '2018-01-12': {
      open: '162.6600',
      high: '164.9400',
      low: '161.7000',
      close: '163.1400',
      'adjusted close': '119.4777',
      volume: '22376145',
      'dividend amount': '0.0000',
    },
    '2018-01-05': {
      open: '154.5000',
      high: '162.9000',
      low: '153.5400',
      close: '162.4900',
      'adjusted close': '119.0017',
      volume: '26160206',
      'dividend amount': '0.0000',
    },
    '2017-12-29': {
      open: '152.5100',
      high: '154.7200',
      low: '152.5000',
      close: '153.4200',
      'adjusted close': '112.3591',
      volume: '10573732',
      'dividend amount': '0.0000',
    },
    '2017-12-22': {
      open: '153.4100',
      high: '154.1800',
      low: '151.4900',
      close: '152.5000',
      'adjusted close': '111.6854',
      volume: '20045931',
      'dividend amount': '0.0000',
    },
    '2017-12-15': {
      open: '154.9700',
      high: '157.8500',
      low: '152.0300',
      close: '152.5000',
      'adjusted close': '111.6854',
      volume: '31101402',
      'dividend amount': '0.0000',
    },
    '2017-12-08': {
      open: '155.6700',
      high: '156.8000',
      low: '153.2600',
      close: '154.8100',
      'adjusted close': '113.3771',
      volume: '20236835',
      'dividend amount': '0.0000',
    },
    '2017-12-01': {
      open: '152.0600',
      high: '155.0200',
      low: '151.6200',
      close: '154.7600',
      'adjusted close': '113.3405',
      volume: '24386604',
      'dividend amount': '0.0000',
    },
    '2017-11-24': {
      open: '150.6400',
      high: '152.4500',
      low: '149.9000',
      close: '151.8400',
      'adjusted close': '111.2020',
      volume: '13728716',
      'dividend amount': '0.0000',
    },
    '2017-11-17': {
      open: '148.8800',
      high: '150.1500',
      low: '146.2110',
      close: '148.9700',
      'adjusted close': '109.1001',
      volume: '23731918',
      'dividend amount': '0.0000',
    },
    '2017-11-10': {
      open: '151.7700',
      high: '151.8200',
      low: '149.1400',
      close: '149.1600',
      'adjusted close': '109.2393',
      volume: '21891991',
      'dividend amount': '1.5000',
    },
    '2017-11-03': {
      open: '153.7600',
      high: '154.9300',
      low: '151.4900',
      close: '151.5800',
      'adjusted close': '109.9146',
      volume: '19467703',
      'dividend amount': '0.0000',
    },
    '2017-10-27': {
      open: '162.0500',
      high: '162.5100',
      low: '152.9110',
      close: '153.6800',
      'adjusted close': '111.4374',
      volume: '28529638',
      'dividend amount': '0.0000',
    },
    '2017-10-20': {
      open: '147.2200',
      high: '162.4800',
      low: '146.1800',
      close: '162.0700',
      'adjusted close': '117.5212',
      volume: '56148944',
      'dividend amount': '0.0000',
    },
    '2017-10-13': {
      open: '146.5000',
      high: '148.9500',
      low: '146.3500',
      close: '147.1000',
      'adjusted close': '106.6661',
      volume: '16128636',
      'dividend amount': '0.0000',
    },
    '2017-10-06': {
      open: '145.3500',
      high: '147.5400',
      low: '145.2100',
      close: '146.4800',
      'adjusted close': '106.2165',
      volume: '12252293',
      'dividend amount': '0.0000',
    },
    '2017-09-29': {
      open: '145.1200',
      high: '147.4200',
      low: '144.2100',
      close: '145.0800',
      'adjusted close': '105.2013',
      volume: '18131168',
      'dividend amount': '0.0000',
    },
    '2017-09-22': {
      open: '144.8100',
      high: '145.8911',
      low: '144.2200',
      close: '145.1300',
      'adjusted close': '105.2376',
      volume: '16890140',
      'dividend amount': '0.0000',
    },
    '2017-09-15': {
      open: '143.5400',
      high: '146.3800',
      low: '143.0100',
      close: '144.8200',
      'adjusted close': '105.0128',
      volume: '24882951',
      'dividend amount': '0.0000',
    },
    '2017-09-08': {
      open: '143.5000',
      high: '144.6400',
      low: '141.6400',
      close: '142.4500',
      'adjusted close': '103.2942',
      volume: '13803467',
      'dividend amount': '0.0000',
    },
    '2017-09-01': {
      open: '144.1100',
      high: '144.5600',
      low: '141.9000',
      close: '144.0800',
      'adjusted close': '104.4762',
      volume: '17765494',
      'dividend amount': '0.0000',
    },
    '2017-08-25': {
      open: '139.5900',
      high: '144.1900',
      low: '139.1300',
      close: '143.7400',
      'adjusted close': '104.2296',
      volume: '15927109',
      'dividend amount': '0.0000',
    },
    '2017-08-18': {
      open: '142.0000',
      high: '143.3400',
      low: '139.5800',
      close: '139.7000',
      'adjusted close': '101.3001',
      volume: '18585485',
      'dividend amount': '0.0000',
    },
    '2017-08-11': {
      open: '145.0000',
      high: '145.0900',
      low: '141.1900',
      close: '141.8400',
      'adjusted close': '102.8519',
      volume: '18161288',
      'dividend amount': '1.5000',
    },
    '2017-08-04': {
      open: '144.3800',
      high: '145.6700',
      low: '144.1700',
      close: '145.1600',
      'adjusted close': '104.1599',
      volume: '16503121',
      'dividend amount': '0.0000',
    },
    '2017-07-28': {
      open: '147.0000',
      high: '147.4900',
      low: '143.6400',
      close: '144.2900',
      'adjusted close': '103.5356',
      volume: '21206630',
      'dividend amount': '0.0000',
    },
    '2017-07-21': {
      open: '153.5900',
      high: '154.2900',
      low: '146.5100',
      close: '147.0800',
      'adjusted close': '105.5376',
      volume: '39223438',
      'dividend amount': '0.0000',
    },
    '2017-07-14': {
      open: '152.9100',
      high: '154.6150',
      low: '152.0500',
      close: '154.2400',
      'adjusted close': '110.6753',
      volume: '15015697',
      'dividend amount': '0.0000',
    },
    '2017-07-07': {
      open: '153.5800',
      high: '156.0250',
      low: '152.1400',
      close: '152.9400',
      'adjusted close': '109.7424',
      volume: '11462576',
      'dividend amount': '0.0000',
    },
    '2017-06-30': {
      open: '154.7100',
      high: '155.7500',
      low: '153.1400',
      close: '153.8300',
      'adjusted close': '110.3811',
      volume: '13465670',
      'dividend amount': '0.0000',
    },
    '2017-06-23': {
      open: '155.5100',
      high: '155.8600',
      low: '153.3900',
      close: '154.1100',
      'adjusted close': '110.5820',
      volume: '18294998',
      'dividend amount': '0.0000',
    },
    '2017-06-16': {
      open: '154.1900',
      high: '157.2000',
      low: '152.9400',
      close: '155.3800',
      'adjusted close': '111.4933',
      volume: '23761609',
      'dividend amount': '0.0000',
    },
    '2017-06-09': {
      open: '151.8200',
      high: '154.2650',
      low: '150.8000',
      close: '154.1000',
      'adjusted close': '110.5748',
      volume: '20653101',
      'dividend amount': '0.0000',
    },
    '2017-06-02': {
      open: '151.9500',
      high: '153.2000',
      low: '151.5900',
      close: '152.0500',
      'adjusted close': '109.1038',
      volume: '13583558',
      'dividend amount': '0.0000',
    },
    '2017-05-26': {
      open: '152.1000',
      high: '153.7300',
      low: '151.2300',
      close: '152.4900',
      'adjusted close': '109.4195',
      volume: '14563625',
      'dividend amount': '0.0000',
    },
    '2017-05-19': {
      open: '150.6200',
      high: '154.1400',
      low: '150.1100',
      close: '151.9800',
      'adjusted close': '109.0536',
      volume: '23606431',
      'dividend amount': '0.0000',
    },
    '2017-05-12': {
      open: '152.8000',
      high: '153.4700',
      low: '149.7900',
      close: '150.3700',
      'adjusted close': '107.8983',
      volume: '28432713',
      'dividend amount': '1.5000',
    },
    '2017-05-05': {
      open: '160.0500',
      high: '160.4200',
      low: '153.0000',
      close: '155.0500',
      'adjusted close': '110.1765',
      volume: '28746997',
      'dividend amount': '0.0000',
    },
    '2017-04-28': {
      open: '161.2900',
      high: '162.0400',
      low: '159.6300',
      close: '160.2900',
      'adjusted close': '113.9000',
      volume: '22585454',
      'dividend amount': '0.0000',
    },
    '2017-04-21': {
      open: '169.7500',
      high: '171.6900',
      low: '159.6000',
      close: '160.3800',
      'adjusted close': '113.9640',
      volume: '22353091',
      'dividend amount': '0.0000',
    },
    '2017-04-13': {
      open: '172.5300',
      high: '172.5600',
      low: '168.9800',
      close: '169.5300',
      'adjusted close': '120.4658',
      volume: '15162492',
      'dividend amount': '0.0000',
    },
    '2017-04-07': {
      open: '173.8200',
      high: '176.3300',
      low: '171.2800',
      close: '172.1400',
      'adjusted close': '122.3205',
      volume: '20657952',
      'dividend amount': '0.0000',
    },
    '2017-03-31': {
      open: '172.6900',
      high: '175.0000',
      low: '172.0937',
      close: '174.1400',
      'adjusted close': '123.7416',
      volume: '15949938',
      'dividend amount': '0.0000',
    },
    '2017-03-24': {
      open: '175.6500',
      high: '176.2300',
      low: '172.8000',
      close: '173.8300',
      'adjusted close': '123.5214',
      volume: '16816026',
      'dividend amount': '0.0000',
    },
    '2017-03-17': {
      open: '177.8500',
      high: '179.0000',
      low: '174.7500',
      close: '175.6500',
      'adjusted close': '124.8146',
      volume: '20678038',
      'dividend amount': '0.0000',
    },
    '2017-03-10': {
      open: '179.7200',
      high: '181.2900',
      low: '175.8800',
      close: '177.8300',
      'adjusted close': '126.3637',
      volume: '18283618',
      'dividend amount': '0.0000',
    },
    '2017-03-03': {
      open: '181.1900',
      high: '182.5500',
      low: '179.2800',
      close: '180.0500',
      'adjusted close': '127.9412',
      volume: '14713309',
      'dividend amount': '0.0000',
    },
    '2017-02-24': {
      open: '180.6000',
      high: '182.5000',
      low: '179.1200',
      close: '181.3500',
      'adjusted close': '128.8650',
      volume: '13082284',
      'dividend amount': '0.0000',
    },
    '2017-02-17': {
      open: '179.2400',
      high: '182.7900',
      low: '178.3500',
      close: '180.6700',
      'adjusted close': '128.3818',
      volume: '15587334',
      'dividend amount': '0.0000',
    },
    '2017-02-10': {
      open: '175.3100',
      high: '178.8701',
      low: '174.3800',
      close: '178.6800',
      'adjusted close': '126.9677',
      volume: '15458288',
      'dividend amount': '1.4000',
    },
    '2017-02-03': {
      open: '176.9800',
      high: '177.0700',
      low: '172.8900',
      close: '175.8200',
      'adjusted close': '123.9504',
      volume: '16952754',
      'dividend amount': '0.0000',
    },
    '2017-01-27': {
      open: '170.0800',
      high: '179.2500',
      low: '170.0100',
      close: '177.3000',
      'adjusted close': '124.9938',
      volume: '24898161',
      'dividend amount': '0.0000',
    },
    '2017-01-20': {
      open: '166.6900',
      high: '170.6400',
      low: '165.8000',
      close: '170.5500',
      'adjusted close': '120.2351',
      volume: '26891514',
      'dividend amount': '0.0000',
    },
    '2017-01-13': {
      open: '169.4700',
      high: '169.8000',
      low: '165.3400',
      close: '167.3400',
      'adjusted close': '117.9721',
      volume: '16710843',
      'dividend amount': '0.0000',
    },
    '2017-01-06': {
      open: '167.0000',
      high: '169.9200',
      low: '166.0100',
      close: '169.5300',
      'adjusted close': '119.5161',
      volume: '11943248',
      'dividend amount': '0.0000',
    },
    '2016-12-30': {
      open: '166.9800',
      high: '167.9800',
      low: '165.5000',
      close: '165.9900',
      'adjusted close': '117.0204',
      volume: '7770697',
      'dividend amount': '0.0000',
    },
    '2016-12-23': {
      open: '166.8300',
      high: '168.2500',
      low: '165.2500',
      close: '166.7100',
      'adjusted close': '117.5280',
      volume: '13209502',
      'dividend amount': '0.0000',
    },
    '2016-12-16': {
      open: '166.7200',
      high: '169.9520',
      low: '165.0700',
      close: '166.7300',
      'adjusted close': '117.5421',
      volume: '23953742',
      'dividend amount': '0.0000',
    },
    '2016-12-09': {
      open: '160.8500',
      high: '166.7200',
      low: '158.9300',
      close: '166.5200',
      'adjusted close': '117.3941',
      volume: '17154330',
      'dividend amount': '0.0000',
    },
    '2016-12-02': {
      open: '163.2000',
      high: '164.6600',
      low: '158.3000',
      close: '160.0200',
      'adjusted close': '112.8117',
      volume: '19370850',
      'dividend amount': '0.0000',
    },
    '2016-11-25': {
      open: '160.6900',
      high: '163.1900',
      low: '160.3697',
      close: '163.1400',
      'adjusted close': '115.0112',
      volume: '11269212',
      'dividend amount': '0.0000',
    },
    '2016-11-18': {
      open: '161.2500',
      high: '161.8600',
      low: '157.5500',
      close: '160.3900',
      'adjusted close': '113.0725',
      volume: '16168917',
      'dividend amount': '0.0000',
    },
    '2016-11-11': {
      open: '153.9900',
      high: '161.3400',
      low: '151.0000',
      close: '161.2700',
      'adjusted close': '113.6929',
      volume: '25292112',
      'dividend amount': '1.4000',
    },
    '2016-11-04': {
      open: '152.7600',
      high: '154.3300',
      low: '151.6700',
      close: '152.4300',
      'adjusted close': '106.4999',
      volume: '15168515',
      'dividend amount': '0.0000',
    },
    '2016-10-28': {
      open: '150.4000',
      high: '154.4400',
      low: '149.8300',
      close: '152.6100',
      'adjusted close': '106.6257',
      volume: '16009359',
      'dividend amount': '0.0000',
    },
    '2016-10-21': {
      open: '154.4500',
      high: '155.8900',
      low: '147.7900',
      close: '149.6300',
      'adjusted close': '104.5436',
      volume: '31731091',
      'dividend amount': '0.0000',
    },
    '2016-10-14': {
      open: '156.7100',
      high: '158.4900',
      low: '152.2700',
      close: '154.4500',
      'adjusted close': '107.9113',
      volume: '15614452',
      'dividend amount': '0.0000',
    },
    '2016-10-07': {
      open: '158.0600',
      high: '158.5300',
      low: '154.8600',
      close: '155.6700',
      'adjusted close': '108.7637',
      volume: '11417766',
      'dividend amount': '0.0000',
    },
    '2016-09-30': {
      open: '154.4600',
      high: '165.0000',
      low: '153.4600',
      close: '158.8500',
      'adjusted close': '110.9855',
      volume: '15801644',
      'dividend amount': '0.0000',
    },
    '2016-09-23': {
      open: '154.8700',
      high: '157.2200',
      low: '153.8700',
      close: '154.9800',
      'adjusted close': '108.2816',
      volume: '12476162',
      'dividend amount': '0.0000',
    },
    '2016-09-16': {
      open: '155.2600',
      high: '158.5300',
      low: '153.2100',
      close: '153.8400',
      'adjusted close': '107.4851',
      volume: '21990587',
      'dividend amount': '0.0000',
    },
    '2016-09-09': {
      open: '159.8800',
      high: '161.7600',
      low: '155.6500',
      close: '155.6900',
      'adjusted close': '108.7776',
      volume: '15009419',
      'dividend amount': '0.0000',
    },
    '2016-09-02': {
      open: '158.8300',
      high: '160.5700',
      low: '158.1000',
      close: '159.5500',
      'adjusted close': '111.4745',
      volume: '11286596',
      'dividend amount': '0.0000',
    },
    '2016-08-26': {
      open: '160.0000',
      high: '161.3400',
      low: '157.8500',
      close: '158.3200',
      'adjusted close': '110.6152',
      volume: '13571765',
      'dividend amount': '0.0000',
    },
    '2016-08-19': {
      open: '162.4000',
      high: '162.9700',
      low: '159.5200',
      close: '160.0400',
      'adjusted close': '111.8169',
      volume: '14873739',
      'dividend amount': '0.0000',
    },
    '2016-08-12': {
      open: '162.7300',
      high: '164.9500',
      low: '161.5200',
      close: '161.9500',
      'adjusted close': '113.1514',
      volume: '19554950',
      'dividend amount': '1.4000',
    },
    '2016-08-05': {
      open: '160.6500',
      high: '163.5100',
      low: '159.8000',
      close: '163.5000',
      'adjusted close': '113.2558',
      volume: '15067283',
      'dividend amount': '0.0000',
    },
    '2016-07-29': {
      open: '162.0000',
      high: '163.6000',
      low: '160.0300',
      close: '160.6200',
      'adjusted close': '111.2609',
      volume: '13747991',
      'dividend amount': '0.0000',
    },
    '2016-07-22': {
      open: '159.3000',
      high: '162.1700',
      low: '157.8900',
      close: '162.0700',
      'adjusted close': '112.2653',
      volume: '24775973',
      'dividend amount': '0.0000',
    },
    '2016-07-15': {
      open: '154.6000',
      high: '161.4000',
      low: '154.5000',
      close: '159.7800',
      'adjusted close': '110.6790',
      volume: '18535456',
      'dividend amount': '0.0000',
    },
    '2016-07-08': {
      open: '151.1900',
      high: '154.6000',
      low: '149.9200',
      close: '154.4600',
      'adjusted close': '106.9938',
      volume: '10213227',
      'dividend amount': '0.0000',
    },
    '2016-07-01': {
      open: '146.1800',
      high: '152.9700',
      low: '142.5000',
      close: '152.3500',
      'adjusted close': '105.5323',
      volume: '19155814',
      'dividend amount': '0.0000',
    },
    '2016-06-24': {
      open: '152.6000',
      high: '155.4800',
      low: '146.1800',
      close: '146.5900',
      'adjusted close': '101.5423',
      volume: '22214411',
      'dividend amount': '0.0000',
    },
    '2016-06-17': {
      open: '151.6300',
      high: '152.7200',
      low: '149.0000',
      close: '151.9900',
      'adjusted close': '105.2829',
      volume: '15849580',
      'dividend amount': '0.0000',
    },
    '2016-06-10': {
      open: '153.0900',
      high: '154.0900',
      low: '151.8600',
      close: '152.3700',
      'adjusted close': '105.5461',
      volume: '14920842',
      'dividend amount': '0.0000',
    },
    '2016-06-03': {
      open: '152.5600',
      high: '153.8100',
      low: '151.5400',
      close: '152.8900',
      'adjusted close': '105.9063',
      volume: '13470239',
      'dividend amount': '0.0000',
    },
    '2016-05-27': {
      open: '147.6100',
      high: '152.9300',
      low: '146.6600',
      close: '152.8400',
      'adjusted close': '105.8717',
      volume: '14910875',
      'dividend amount': '0.0000',
    },
    '2016-05-20': {
      open: '147.6500',
      high: '149.9900',
      low: '143.9550',
      close: '147.2500',
      'adjusted close': '101.9995',
      volume: '16406443',
      'dividend amount': '0.0000',
    },
    '2016-05-13': {
      open: '147.7000',
      high: '151.0900',
      low: '147.0100',
      close: '147.7200',
      'adjusted close': '102.3251',
      volume: '17034303',
      'dividend amount': '0.0000',
    },
    '2016-05-06': {
      open: '146.5600',
      high: '147.9700',
      low: '142.9000',
      close: '147.2900',
      'adjusted close': '102.0272',
      volume: '21059912',
      'dividend amount': '1.4000',
    },
    '2016-04-29': {
      open: '148.1600',
      high: '150.7800',
      low: '144.1910',
      close: '145.9400',
      'adjusted close': '100.1402',
      volume: '16956550',
      'dividend amount': '0.0000',
    },
    '2016-04-22': {
      open: '151.7500',
      high: '153.1400',
      low: '142.6100',
      close: '148.5000',
      'adjusted close': '101.8968',
      volume: '37688644',
      'dividend amount': '0.0000',
    },
    '2016-04-15': {
      open: '150.2600',
      high: '152.7600',
      low: '148.5800',
      close: '151.7200',
      'adjusted close': '104.1063',
      volume: '17443203',
      'dividend amount': '0.0000',
    },
    '2016-04-08': {
      open: '152.3400',
      high: '153.5200',
      low: '147.8100',
      close: '149.3500',
      'adjusted close': '102.4801',
      volume: '18417556',
      'dividend amount': '0.0000',
    },
    '2016-04-01': {
      open: '147.7500',
      high: '153.1000',
      low: '147.2300',
      close: '152.5200',
      'adjusted close': '104.6553',
      volume: '20439783',
      'dividend amount': '0.0000',
    },
    '2016-03-24': {
      open: '147.3000',
      high: '149.2800',
      low: '144.4900',
      close: '147.9500',
      'adjusted close': '101.5194',
      volume: '17611709',
      'dividend amount': '0.0000',
    },
    '2016-03-18': {
      open: '142.0100',
      high: '147.5100',
      low: '141.0400',
      close: '147.0900',
      'adjusted close': '100.9293',
      volume: '29886417',
      'dividend amount': '0.0000',
    },
    '2016-03-11': {
      open: '137.2800',
      high: '142.9236',
      low: '136.8718',
      close: '142.3600',
      'adjusted close': '97.6837',
      volume: '23556160',
      'dividend amount': '0.0000',
    },
    '2016-03-04': {
      open: '132.0000',
      high: '139.4200',
      low: '130.9000',
      close: '137.8000',
      'adjusted close': '94.5548',
      volume: '22557187',
      'dividend amount': '0.0000',
    },
    '2016-02-26': {
      open: '133.8400',
      high: '134.9200',
      low: '129.6800',
      close: '132.0300',
      'adjusted close': '90.5956',
      volume: '20763400',
      'dividend amount': '0.0000',
    },
    '2016-02-19': {
      open: '121.8300',
      high: '134.0000',
      low: '121.3400',
      close: '133.0800',
      'adjusted close': '91.3160',
      volume: '23958762',
      'dividend amount': '0.0000',
    },
    '2016-02-12': {
      open: '126.0000',
      high: '127.5100',
      low: '116.9010',
      close: '121.0400',
      'adjusted close': '83.0545',
      volume: '32025579',
      'dividend amount': '1.3000',
    },
    '2016-02-05': {
      open: '124.4000',
      high: '128.6400',
      low: '122.2900',
      close: '128.5700',
      'adjusted close': '87.3274',
      volume: '25527891',
      'dividend amount': '0.0000',
    },
    '2016-01-29': {
      open: '122.1000',
      high: '124.7900',
      low: '120.6500',
      close: '124.7900',
      'adjusted close': '84.7599',
      volume: '27278749',
      'dividend amount': '0.0000',
    },
    '2016-01-22': {
      open: '130.1100',
      high: '132.1000',
      low: '118.0000',
      close: '122.5000',
      'adjusted close': '83.2045',
      volume: '44203492',
      'dividend amount': '0.0000',
    },
    '2016-01-15': {
      open: '131.8100',
      high: '134.2799',
      low: '128.8700',
      close: '130.0300',
      'adjusted close': '88.3190',
      volume: '29470082',
      'dividend amount': '0.0000',
    },
    '2016-01-08': {
      open: '135.6000',
      high: '136.8900',
      low: '131.3200',
      close: '131.6300',
      'adjusted close': '89.4058',
      volume: '25251873',
      'dividend amount': '0.0000',
    },
    '2015-12-31': {
      open: '137.7400',
      high: '140.4400',
      low: '136.5400',
      close: '137.6200',
      'adjusted close': '93.4743',
      volume: '13538507',
      'dividend amount': '0.0000',
    },
    '2015-12-24': {
      open: '135.8300',
      high: '139.3050',
      low: '134.0200',
      close: '138.2500',
      'adjusted close': '93.9022',
      volume: '16540309',
      'dividend amount': '0.0000',
    },
    '2015-12-18': {
      open: '135.3100',
      high: '139.6500',
      low: '134.0200',
      close: '134.9000',
      'adjusted close': '91.6268',
      volume: '27823388',
      'dividend amount': '0.0000',
    },
    '2015-12-11': {
      open: '140.1600',
      high: '140.4100',
      low: '133.9100',
      close: '134.5700',
      'adjusted close': '91.4027',
      volume: '21354563',
      'dividend amount': '0.0000',
    },
    '2015-12-04': {
      open: '138.6100',
      high: '141.4000',
      low: '137.9900',
      close: '140.4300',
      'adjusted close': '95.3829',
      volume: '22935841',
      'dividend amount': '0.0000',
    },
    '2015-11-27': {
      open: '138.5300',
      high: '139.3400',
      low: '137.1200',
      close: '138.4600',
      'adjusted close': '94.0448',
      volume: '13199481',
      'dividend amount': '0.0000',
    },
    '2015-11-20': {
      open: '131.7900',
      high: '138.9150',
      low: '131.7900',
      close: '138.5000',
      'adjusted close': '94.0720',
      volume: '21083793',
      'dividend amount': '0.0000',
    },
    '2015-11-13': {
      open: '137.9200',
      high: '138.0800',
      low: '131.6500',
      close: '131.7500',
      'adjusted close': '89.4873',
      volume: '25069755',
      'dividend amount': '0.0000',
    },
    '2015-11-06': {
      open: '140.5000',
      high: '142.8000',
      low: '137.8700',
      close: '138.2500',
      'adjusted close': '93.9022',
      volume: '21526383',
      'dividend amount': '1.3000',
    },
    '2015-10-30': {
      open: '144.7500',
      high: '145.0000',
      low: '137.3300',
      close: '140.0800',
      'adjusted close': '94.2588',
      volume: '34698762',
      'dividend amount': '0.0000',
    },
    '2015-10-23': {
      open: '149.8500',
      high: '149.9700',
      low: '139.3000',
      close: '144.6800',
      'adjusted close': '97.3541',
      volume: '42068316',
      'dividend amount': '0.0000',
    },
    '2015-10-16': {
      open: '152.4000',
      high: '152.5800',
      low: '148.5800',
      close: '150.3900',
      'adjusted close': '101.1964',
      volume: '17455488',
      'dividend amount': '0.0000',
    },
    '2015-10-09': {
      open: '145.8200',
      high: '153.1470',
      low: '145.8200',
      close: '152.3900',
      'adjusted close': '102.5422',
      volume: '19465122',
      'dividend amount': '0.0000',
    },
    '2015-10-02': {
      open: '144.4200',
      high: '145.7100',
      low: '140.5600',
      close: '144.5800',
      'adjusted close': '97.2869',
      volume: '19914471',
      'dividend amount': '0.0000',
    },
    '2015-09-25': {
      open: '145.3900',
      high: '146.9800',
      low: '141.9500',
      close: '145.4200',
      'adjusted close': '97.8521',
      volume: '16815584',
      'dividend amount': '0.0000',
    },
    '2015-09-18': {
      open: '147.3700',
      high: '149.6800',
      low: '143.9800',
      close: '144.5100',
      'adjusted close': '97.2398',
      volume: '20721826',
      'dividend amount': '0.0000',
    },
    '2015-09-11': {
      open: '145.8600',
      high: '149.0400',
      low: '144.5100',
      close: '147.3700',
      'adjusted close': '99.1642',
      volume: '13916382',
      'dividend amount': '0.0000',
    },
    '2015-09-04': {
      open: '147.2600',
      high: '148.4000',
      low: '141.8500',
      close: '143.7000',
      'adjusted close': '96.6947',
      volume: '21421138',
      'dividend amount': '0.0000',
    },
    '2015-08-28': {
      open: '143.4700',
      high: '148.9700',
      low: '140.6200',
      close: '147.9800',
      'adjusted close': '99.5747',
      volume: '32508092',
      'dividend amount': '0.0000',
    },
    '2015-08-21': {
      open: '155.2000',
      high: '156.6900',
      low: '148.7000',
      close: '148.8500',
      'adjusted close': '100.1601',
      volume: '19846207',
      'dividend amount': '0.0000',
    },
    '2015-08-14': {
      open: '156.7700',
      high: '157.4652',
      low: '153.9500',
      close: '155.7500',
      'adjusted close': '104.8031',
      volume: '17165086',
      'dividend amount': '0.0000',
    },
    '2015-08-07': {
      open: '161.7000',
      high: '161.8500',
      low: '154.3350',
      close: '155.1200',
      'adjusted close': '104.3791',
      volume: '20138504',
      'dividend amount': '1.3000',
    },
    '2015-07-31': {
      open: '159.0000',
      high: '162.0600',
      low: '158.5000',
      close: '161.9900',
      'adjusted close': '108.1029',
      volume: '15380412',
      'dividend amount': '0.0000',
    },
    '2015-07-24': {
      open: '172.7900',
      high: '173.7800',
      low: '159.4100',
      close: '159.7500',
      'adjusted close': '106.6081',
      volume: '37075256',
      'dividend amount': '0.0000',
    },
    '2015-07-17': {
      open: '167.9300',
      high: '172.5200',
      low: '167.5200',
      close: '172.5100',
      'adjusted close': '115.1234',
      volume: '17138495',
      'dividend amount': '0.0000',
    },
    '2015-07-10': {
      open: '163.8300',
      high: '167.4000',
      low: '162.2317',
      close: '166.9500',
      'adjusted close': '111.4129',
      volume: '18143986',
      'dividend amount': '0.0000',
    },
    '2015-07-02': {
      open: '163.9200',
      high: '165.6100',
      low: '162.1200',
      close: '165.0900',
      'adjusted close': '110.1717',
      volume: '12979210',
      'dividend amount': '0.0000',
    },
    '2015-06-26': {
      open: '167.6500',
      high: '169.9700',
      low: '165.2300',
      close: '165.4600',
      'adjusted close': '110.4186',
      volume: '20939777',
      'dividend amount': '0.0000',
    },
    '2015-06-19': {
      open: '165.3300',
      high: '168.7200',
      low: '164.2500',
      close: '166.9900',
      'adjusted close': '111.4396',
      volume: '20761376',
      'dividend amount': '0.0000',
    },
    '2015-06-12': {
      open: '167.1700',
      high: '170.4400',
      low: '163.3700',
      close: '166.9900',
      'adjusted close': '111.4396',
      volume: '18364270',
      'dividend amount': '0.0000',
    },
    '2015-06-05': {
      open: '170.2100',
      high: '171.5601',
      low: '167.2000',
      close: '167.4000',
      'adjusted close': '111.7132',
      volume: '13868111',
      'dividend amount': '0.0000',
    },
    '2015-05-29': {
      open: '172.1100',
      high: '172.4800',
      low: '169.1300',
      close: '169.6500',
      'adjusted close': '113.2148',
      volume: '12435919',
      'dividend amount': '0.0000',
    },
    '2015-05-22': {
      open: '173.4400',
      high: '174.4400',
      low: '171.9300',
      close: '172.2200',
      'adjusted close': '114.9298',
      volume: '11939607',
      'dividend amount': '0.0000',
    },
    '2015-05-15': {
      open: '172.6500',
      high: '174.4100',
      low: '168.8400',
      close: '173.2600',
      'adjusted close': '115.6239',
      volume: '13435074',
      'dividend amount': '0.0000',
    },
    '2015-05-08': {
      open: '174.4700',
      high: '176.3000',
      low: '168.8600',
      close: '172.6800',
      'adjusted close': '115.2368',
      volume: '16793441',
      'dividend amount': '1.3000',
    },
    '2015-05-01': {
      open: '170.8900',
      high: '175.1303',
      low: '170.0200',
      close: '173.6700',
      'adjusted close': '115.0182',
      volume: '21874751',
      'dividend amount': '0.0000',
    },
    '2015-04-24': {
      open: '162.1000',
      high: '171.9100',
      low: '161.9000',
      close: '169.7800',
      'adjusted close': '112.4419',
      volume: '35436301',
      'dividend amount': '0.0000',
    },
    '2015-04-17': {
      open: '162.3700',
      high: '164.9600',
      low: '160.0300',
      close: '160.6700',
      'adjusted close': '106.4085',
      volume: '17532781',
      'dividend amount': '0.0000',
    },
    '2015-04-10': {
      open: '159.6900',
      high: '163.8400',
      low: '158.7020',
      close: '162.8600',
      'adjusted close': '107.8589',
      volume: '13917063',
      'dividend amount': '0.0000',
    },
    '2015-04-02': {
      open: '161.3100',
      high: '163.6000',
      low: '158.3900',
      close: '160.4500',
      'adjusted close': '106.2628',
      volume: '16765545',
      'dividend amount': '0.0000',
    },
    '2015-03-27': {
      open: '162.7700',
      high: '165.3500',
      low: '158.9100',
      close: '160.4000',
      'adjusted close': '106.2297',
      volume: '23569213',
      'dividend amount': '0.0000',
    },
    '2015-03-20': {
      open: '155.0500',
      high: '163.0000',
      low: '154.8000',
      close: '162.8800',
      'adjusted close': '107.8722',
      volume: '25684872',
      'dividend amount': '0.0000',
    },
    '2015-03-13': {
      open: '158.2000',
      high: '161.1500',
      low: '153.4000',
      close: '154.2800',
      'adjusted close': '102.1766',
      volume: '26135448',
      'dividend amount': '0.0000',
    },
    '2015-03-06': {
      open: '161.6800',
      high: '161.8900',
      low: '158.0600',
      close: '158.5000',
      'adjusted close': '104.9714',
      volume: '21117049',
      'dividend amount': '0.0000',
    },
    '2015-02-27': {
      open: '164.2300',
      high: '164.9900',
      low: '159.7700',
      close: '161.9400',
      'adjusted close': '107.2496',
      volume: '22941227',
      'dividend amount': '0.0000',
    },
    '2015-02-20': {
      open: '159.7500',
      high: '164.4900',
      low: '159.7300',
      close: '163.6500',
      'adjusted close': '108.3821',
      volume: '14108892',
      'dividend amount': '0.0000',
    },
    '2015-02-13': {
      open: '156.0000',
      high: '160.8000',
      low: '155.0800',
      close: '160.4000',
      'adjusted close': '106.2297',
      volume: '18164756',
      'dividend amount': '0.0000',
    },
    '2015-02-06': {
      open: '154.0000',
      high: '158.7100',
      low: '151.5100',
      close: '156.7200',
      'adjusted close': '103.7925',
      volume: '22439830',
      'dividend amount': '1.1000',
    },
    '2015-01-30': {
      open: '158.2600',
      high: '159.4600',
      low: '149.5200',
      close: '153.3100',
      'adjusted close': '100.8265',
      volume: '32927307',
      'dividend amount': '0.0000',
    },
    '2015-01-23': {
      open: '156.7000',
      high: '157.6000',
      low: '151.0700',
      close: '155.8700',
      'adjusted close': '102.5101',
      volume: '31230797',
      'dividend amount': '0.0000',
    },
    '2015-01-16': {
      open: '159.0000',
      high: '159.9700',
      low: '153.7400',
      close: '157.1400',
      'adjusted close': '103.3453',
      volume: '23272056',
      'dividend amount': '0.0000',
    },
    '2015-01-09': {
      open: '161.2700',
      high: '161.2700',
      low: '154.0300',
      close: '159.1100',
      'adjusted close': '104.6409',
      volume: '24440360',
      'dividend amount': '0.0000',
    },
    '2015-01-02': {
      open: '162.0000',
      high: '163.3100',
      low: '159.4500',
      close: '162.0600',
      'adjusted close': '106.5810',
      volume: '15698987',
      'dividend amount': '0.0000',
    },
    '2014-12-26': {
      open: '158.3300',
      high: '163.0900',
      low: '158.3300',
      close: '162.3400',
      'adjusted close': '106.7652',
      volume: '12508916',
      'dividend amount': '0.0000',
    },
    '2014-12-19': {
      open: '155.9300',
      high: '160.4100',
      low: '150.5000',
      close: '158.5100',
      'adjusted close': '104.2463',
      volume: '34588770',
      'dividend amount': '0.0000',
    },
    '2014-12-12': {
      open: '163.2900',
      high: '163.3300',
      low: '155.3300',
      close: '155.3800',
      'adjusted close': '102.1878',
      volume: '23397756',
      'dividend amount': '0.0000',
    },
    '2014-12-05': {
      open: '161.6400',
      high: '164.5200',
      low: '161.3500',
      close: '163.2700',
      'adjusted close': '107.3768',
      volume: '20945441',
      'dividend amount': '0.0000',
    },
    '2014-11-28': {
      open: '161.5400',
      high: '163.8600',
      low: '161.0100',
      close: '162.1700',
      'adjusted close': '106.6534',
      volume: '17053892',
      'dividend amount': '0.0000',
    },
    '2014-11-21': {
      open: '164.1600',
      high: '164.9700',
      low: '159.8000',
      close: '160.9200',
      'adjusted close': '105.8313',
      volume: '22273217',
      'dividend amount': '0.0000',
    },
    '2014-11-14': {
      open: '161.9000',
      high: '164.4900',
      low: '161.6100',
      close: '164.1600',
      'adjusted close': '107.9621',
      volume: '20088151',
      'dividend amount': '0.0000',
    },
    '2014-11-07': {
      open: '164.2500',
      high: '164.5400',
      low: '160.0500',
      close: '162.0700',
      'adjusted close': '106.5876',
      volume: '20603321',
      'dividend amount': '1.1000',
    },
    '2014-10-31': {
      open: '162.0000',
      high: '165.5900',
      low: '161.8000',
      close: '164.4000',
      'adjusted close': '107.3883',
      volume: '27342119',
      'dividend amount': '0.0000',
    },
    '2014-10-24': {
      open: '166.8450',
      high: '170.3310',
      low: '161.1000',
      close: '162.0800',
      'adjusted close': '105.8729',
      volume: '69701346',
      'dividend amount': '0.0000',
    },
    '2014-10-17': {
      open: '185.4900',
      high: '186.6500',
      low: '178.6900',
      close: '182.0500',
      'adjusted close': '118.9176',
      volume: '24355568',
      'dividend amount': '0.0000',
    },
    '2014-10-10': {
      open: '189.6900',
      high: '190.8900',
      low: '185.1000',
      close: '185.9300',
      'adjusted close': '121.4520',
      volume: '15795271',
      'dividend amount': '0.0000',
    },
    '2014-10-03': {
      open: '188.5100',
      high: '190.8500',
      low: '186.2400',
      close: '188.6700',
      'adjusted close': '123.2418',
      volume: '14352364',
      'dividend amount': '0.0000',
    },
    '2014-09-26': {
      open: '193.7200',
      high: '194.1400',
      low: '188.6100',
      close: '190.0600',
      'adjusted close': '124.1498',
      volume: '16209901',
      'dividend amount': '0.0000',
    },
    '2014-09-19': {
      open: '191.4200',
      high: '195.0000',
      low: '190.5800',
      close: '194.0000',
      'adjusted close': '126.7235',
      volume: '20044000',
      'dividend amount': '0.0000',
    },
    '2014-09-12': {
      open: '190.7500',
      high: '192.7800',
      low: '189.5100',
      close: '191.2800',
      'adjusted close': '124.9467',
      volume: '12878100',
      'dividend amount': '0.0000',
    },
    '2014-09-05': {
      open: '192.6800',
      high: '192.9700',
      low: '190.0600',
      close: '191.2000',
      'adjusted close': '124.8945',
      volume: '9627700',
      'dividend amount': '0.0000',
    },
    '2014-08-29': {
      open: '191.3900',
      high: '194.1330',
      low: '190.6600',
      close: '192.3000',
      'adjusted close': '125.6130',
      volume: '11487700',
      'dividend amount': '0.0000',
    },
    '2014-08-22': {
      open: '188.1000',
      high: '192.0700',
      low: '187.7600',
      close: '190.4100',
      'adjusted close': '124.3784',
      volume: '11074800',
      'dividend amount': '0.0000',
    },
    '2014-08-15': {
      open: '187.8100',
      high: '188.8800',
      low: '186.2800',
      close: '187.3800',
      'adjusted close': '122.3992',
      volume: '10927100',
      'dividend amount': '0.0000',
    },
    '2014-08-08': {
      open: '189.3500',
      high: '189.9500',
      low: '183.5800',
      close: '186.6300',
      'adjusted close': '121.9093',
      volume: '14770900',
      'dividend amount': '1.1000',
    },
    '2014-08-01': {
      open: '194.3000',
      high: '196.4000',
      low: '188.8600',
      close: '189.1500',
      'adjusted close': '122.8289',
      volume: '19838900',
      'dividend amount': '0.0000',
    },
    '2014-07-25': {
      open: '191.3000',
      high: '195.9000',
      low: '189.2500',
      close: '194.4000',
      'adjusted close': '126.2381',
      volume: '19580700',
      'dividend amount': '0.0000',
    },
    '2014-07-18': {
      open: '188.5500',
      high: '195.9500',
      low: '188.2100',
      close: '192.5000',
      'adjusted close': '125.0043',
      volume: '34217100',
      'dividend amount': '0.0000',
    },
    '2014-07-11': {
      open: '187.6100',
      high: '188.9000',
      low: '186.2100',
      close: '188.0000',
      'adjusted close': '122.0821',
      volume: '14983700',
      'dividend amount': '0.0000',
    },
    '2014-07-03': {
      open: '181.3300',
      high: '188.9900',
      low: '180.2600',
      close: '188.5300',
      'adjusted close': '122.4263',
      volume: '18382300',
      'dividend amount': '0.0000',
    },
    '2014-06-27': {
      open: '181.9200',
      high: '183.0000',
      low: '179.2700',
      close: '181.7100',
      'adjusted close': '117.9975',
      volume: '17703900',
      'dividend amount': '0.0000',
    },
    '2014-06-20': {
      open: '182.4000',
      high: '184.4700',
      low: '181.2350',
      close: '181.5500',
      'adjusted close': '117.8936',
      volume: '24186800',
      'dividend amount': '0.0000',
    },
    '2014-06-13': {
      open: '186.2200',
      high: '187.6400',
      low: '180.9100',
      close: '182.5600',
      'adjusted close': '118.5495',
      volume: '18143900',
      'dividend amount': '0.0000',
    },
    '2014-06-06': {
      open: '184.7600',
      high: '187.6500',
      low: '183.9200',
      close: '186.3700',
      'adjusted close': '121.0236',
      volume: '14243300',
      'dividend amount': '0.0000',
    },
    '2014-05-30': {
      open: '184.8000',
      high: '185.6500',
      low: '182.3300',
      close: '184.3600',
      'adjusted close': '119.7184',
      volume: '15895800',
      'dividend amount': '0.0000',
    },
    '2014-05-23': {
      open: '186.6100',
      high: '187.1600',
      low: '184.7000',
      close: '185.9400',
      'adjusted close': '120.7444',
      volume: '14066100',
      'dividend amount': '0.0000',
    },
    '2014-05-16': {
      open: '191.1200',
      high: '193.0300',
      low: '185.9300',
      close: '187.0600',
      'adjusted close': '121.4717',
      volume: '19407200',
      'dividend amount': '0.0000',
    },
    '2014-05-09': {
      open: '191.0500',
      high: '191.4900',
      low: '186.9300',
      close: '190.0800',
      'adjusted close': '123.4328',
      volume: '13925300',
      'dividend amount': '1.1000',
    },
    '2014-05-02': {
      open: '191.1400',
      high: '196.8600',
      low: '190.5800',
      close: '191.4400',
      'adjusted close': '123.5977',
      volume: '21195300',
      'dividend amount': '0.0000',
    },
    '2014-04-25': {
      open: '189.8000',
      high: '193.0000',
      low: '189.1100',
      close: '189.6300',
      'adjusted close': '122.4291',
      volume: '22312800',
      'dividend amount': '0.0000',
    },
    '2014-04-17': {
      open: '196.2400',
      high: '198.7100',
      low: '187.0100',
      close: '190.0100',
      'adjusted close': '122.6745',
      volume: '30565200',
      'dividend amount': '0.0000',
    },
    '2014-04-11': {
      open: '191.7200',
      high: '199.2100',
      low: '191.5700',
      close: '195.1900',
      'adjusted close': '126.0188',
      volume: '30598500',
      'dividend amount': '0.0000',
    },
    '2014-04-04': {
      open: '191.6400',
      high: '195.1300',
      low: '191.2800',
      close: '191.7700',
      'adjusted close': '123.8108',
      volume: '29036500',
      'dividend amount': '0.0000',
    },
    '2014-03-28': {
      open: '187.4300',
      high: '195.6300',
      low: '187.3700',
      close: '190.4500',
      'adjusted close': '122.9586',
      volume: '35465000',
      'dividend amount': '0.0000',
    },
    '2014-03-21': {
      open: '182.6600',
      high: '188.7000',
      low: '182.6600',
      close: '186.6700',
      'adjusted close': '120.5181',
      volume: '30821100',
      'dividend amount': '0.0000',
    },
    '2014-03-14': {
      open: '187.5500',
      high: '188.4500',
      low: '182.2100',
      close: '182.2100',
      'adjusted close': '117.6386',
      volume: '24057200',
      'dividend amount': '0.0000',
    },
    '2014-03-07': {
      open: '183.3300',
      high: '188.9200',
      low: '182.8200',
      close: '187.6800',
      'adjusted close': '121.1702',
      volume: '20103400',
      'dividend amount': '0.0000',
    },
    '2014-02-28': {
      open: '182.8200',
      high: '186.1200',
      low: '182.8200',
      close: '185.1700',
      'adjusted close': '119.5497',
      volume: '20734000',
      'dividend amount': '0.0000',
    },
    '2014-02-21': {
      open: '183.1800',
      high: '185.7100',
      low: '182.3200',
      close: '182.7900',
      'adjusted close': '118.0131',
      volume: '19774200',
      'dividend amount': '0.0000',
    },
    '2014-02-14': {
      open: '176.9700',
      high: '184.4300',
      low: '176.2500',
      close: '183.6900',
      'adjusted close': '118.5942',
      volume: '21312600',
      'dividend amount': '0.0000',
    },
    '2014-02-07': {
      open: '176.0200',
      high: '177.5600',
      low: '172.1900',
      close: '177.2500',
      'adjusted close': '114.4364',
      volume: '25234000',
      'dividend amount': '0.9500',
    },
    '2014-01-31': {
      open: '179.6050',
      high: '179.6500',
      low: '175.3400',
      close: '176.6800',
      'adjusted close': '113.4513',
      volume: '25559900',
      'dividend amount': '0.0000',
    },
    '2014-01-24': {
      open: '190.2300',
      high: '190.3900',
      low: '179.6400',
      close: '179.6400',
      'adjusted close': '115.3520',
      volume: '38736900',
      'dividend amount': '0.0000',
    },
    '2014-01-17': {
      open: '186.2600',
      high: '190.8100',
      low: '183.8600',
      close: '190.0900',
      'adjusted close': '122.0623',
      volume: '27635300',
      'dividend amount': '0.0000',
    },
    '2014-01-10': {
      open: '187.1500',
      high: '190.3500',
      low: '185.3000',
      close: '187.2600',
      'adjusted close': '120.2450',
      volume: '22947500',
      'dividend amount': '0.0000',
    },
    '2014-01-03': {
      open: '185.3200',
      high: '187.7900',
      low: '184.6700',
      close: '186.6400',
      'adjusted close': '119.8469',
      volume: '15248000',
      'dividend amount': '0.0000',
    },
    '2013-12-27': {
      open: '181.0500',
      high: '186.5000',
      low: '180.6100',
      close: '185.0800',
      'adjusted close': '118.8452',
      volume: '12400800',
      'dividend amount': '0.0000',
    },
    '2013-12-20': {
      open: '173.2200',
      high: '182.0000',
      low: '172.7300',
      close: '180.0200',
      'adjusted close': '115.5960',
      volume: '32267100',
      'dividend amount': '0.0000',
    },
    '2013-12-13': {
      open: '177.9900',
      high: '178.1520',
      low: '172.7300',
      close: '172.8000',
      'adjusted close': '110.9598',
      volume: '21870800',
      'dividend amount': '0.0000',
    },
    '2013-12-06': {
      open: '179.4600',
      high: '179.5900',
      low: '175.1600',
      close: '177.6700',
      'adjusted close': '114.0870',
      volume: '24816100',
      'dividend amount': '0.0000',
    },
    '2013-11-29': {
      open: '180.2500',
      high: '180.7600',
      low: '177.3100',
      close: '179.6800',
      'adjusted close': '115.3777',
      volume: '20384900',
      'dividend amount': '0.0000',
    },
    '2013-11-22': {
      open: '183.5200',
      high: '186.2400',
      low: '179.9200',
      close: '181.3000',
      'adjusted close': '116.4179',
      volume: '25637400',
      'dividend amount': '0.0000',
    },
    '2013-11-15': {
      open: '180.1900',
      high: '184.0487',
      low: '179.6600',
      close: '183.1900',
      'adjusted close': '117.6316',
      volume: '25682800',
      'dividend amount': '0.0000',
    },
    '2013-11-08': {
      open: '179.9000',
      high: '181.3900',
      low: '177.3500',
      close: '179.9900',
      'adjusted close': '115.5768',
      volume: '25635300',
      'dividend amount': '0.9500',
    },
    '2013-11-01': {
      open: '177.0400',
      high: '182.3200',
      low: '176.2000',
      close: '179.2300',
      'adjusted close': '114.4818',
      volume: '25853600',
      'dividend amount': '0.0000',
    },
    '2013-10-25': {
      open: '174.4200',
      high: '179.1000',
      low: '172.6301',
      close: '176.8500',
      'adjusted close': '112.9616',
      volume: '29861500',
      'dividend amount': '0.0000',
    },
    '2013-10-18': {
      open: '185.4100',
      high: '186.9900',
      low: '172.5700',
      close: '173.7800',
      'adjusted close': '111.0006',
      volume: '45663100',
      'dividend amount': '0.0000',
    },
    '2013-10-11': {
      open: '181.8500',
      high: '186.2300',
      low: '178.7100',
      close: '186.1600',
      'adjusted close': '118.9083',
      volume: '20859700',
      'dividend amount': '0.0000',
    },
    '2013-10-04': {
      open: '185.5600',
      high: '186.7400',
      low: '183.0000',
      close: '184.1000',
      'adjusted close': '117.5925',
      volume: '16351900',
      'dividend amount': '0.0000',
    },
    '2013-09-27': {
      open: '190.0600',
      high: '192.4100',
      low: '186.4500',
      close: '186.9200',
      'adjusted close': '119.3937',
      volume: '15079700',
      'dividend amount': '0.0000',
    },
    '2013-09-20': {
      open: '193.7000',
      high: '194.8900',
      low: '190.0200',
      close: '190.0200',
      'adjusted close': '121.3738',
      volume: '22379100',
      'dividend amount': '0.0000',
    },
    '2013-09-13': {
      open: '183.6800',
      high: '193.1000',
      low: '183.3100',
      close: '192.1700',
      'adjusted close': '122.7471',
      volume: '18194900',
      'dividend amount': '0.0000',
    },
    '2013-09-06': {
      open: '183.6300',
      high: '185.0000',
      low: '182.3100',
      close: '183.0300',
      'adjusted close': '116.9090',
      volume: '11856200',
      'dividend amount': '0.0000',
    },
    '2013-08-30': {
      open: '185.2700',
      high: '187.0000',
      low: '181.1000',
      close: '182.2700',
      'adjusted close': '116.4236',
      volume: '15052200',
      'dividend amount': '0.0000',
    },
    '2013-08-23': {
      open: '185.3000',
      high: '186.5700',
      low: '183.1685',
      close: '185.4200',
      'adjusted close': '118.4356',
      volume: '14564700',
      'dividend amount': '0.0000',
    },
    '2013-08-16': {
      open: '186.9700',
      high: '189.9900',
      low: '185.3400',
      close: '185.3400',
      'adjusted close': '118.3845',
      volume: '16580500',
      'dividend amount': '0.0000',
    },
    '2013-08-09': {
      open: '195.1600',
      high: '195.8800',
      low: '186.7900',
      close: '187.8200',
      'adjusted close': '119.9686',
      volume: '20128300',
      'dividend amount': '0.9500',
    },
    '2013-08-02': {
      open: '196.8300',
      high: '197.8300',
      low: '193.2215',
      close: '195.1600',
      'adjusted close': '124.0321',
      volume: '15338600',
      'dividend amount': '0.0000',
    },
    '2013-07-26': {
      open: '193.4000',
      high: '197.8300',
      low: '193.2804',
      close: '197.3500',
      'adjusted close': '125.4239',
      volume: '14719100',
      'dividend amount': '0.0000',
    },
    '2013-07-19': {
      open: '192.4200',
      high: '200.9400',
      low: '191.6800',
      close: '193.5400',
      'adjusted close': '123.0025',
      volume: '31679400',
      'dividend amount': '0.0000',
    },
    '2013-07-12': {
      open: '195.6000',
      high: '195.7800',
      low: '190.7800',
      close: '192.0700',
      'adjusted close': '122.0682',
      volume: '20715900',
      'dividend amount': '0.0000',
    },
    '2013-07-05': {
      open: '192.1500',
      high: '195.1600',
      low: '190.2600',
      close: '194.9300',
      'adjusted close': '123.8859',
      volume: '12378100',
      'dividend amount': '0.0000',
    },
    '2013-06-28': {
      open: '193.9900',
      high: '196.9000',
      low: '188.4100',
      close: '191.1100',
      'adjusted close': '121.4581',
      volume: '25092600',
      'dividend amount': '0.0000',
    },
    '2013-06-21': {
      open: '203.4400',
      high: '206.0900',
      low: '193.5401',
      close: '195.4600',
      'adjusted close': '124.2227',
      volume: '22773400',
      'dividend amount': '0.0000',
    },
    '2013-06-14': {
      open: '206.9700',
      high: '206.9800',
      low: '200.3400',
      close: '202.2000',
      'adjusted close': '128.5063',
      volume: '14580500',
      'dividend amount': '0.0000',
    },
    '2013-06-07': {
      open: '208.2500',
      high: '210.0500',
      low: '201.4700',
      close: '206.3500',
      'adjusted close': '131.1438',
      volume: '17768500',
      'dividend amount': '0.0000',
    },
    '2013-05-31': {
      open: '207.4400',
      high: '211.9800',
      low: '206.0400',
      close: '208.0200',
      'adjusted close': '132.2051',
      volume: '16799600',
      'dividend amount': '0.0000',
    },
    '2013-05-24': {
      open: '208.0200',
      high: '210.1500',
      low: '204.4200',
      close: '205.7200',
      'adjusted close': '130.7434',
      volume: '20259100',
      'dividend amount': '0.0000',
    },
    '2013-05-17': {
      open: '204.1800',
      high: '209.5000',
      low: '202.0400',
      close: '208.4400',
      'adjusted close': '132.4720',
      volume: '21587300',
      'dividend amount': '0.0000',
    },
    '2013-05-10': {
      open: '203.7900',
      high: '205.0000',
      low: '201.5200',
      close: '204.4700',
      'adjusted close': '129.9489',
      volume: '18700700',
      'dividend amount': '0.9500',
    },
    '2013-05-03': {
      open: '194.7800',
      high: '205.3190',
      low: '194.6500',
      close: '204.5100',
      'adjusted close': '129.3743',
      volume: '26809200',
      'dividend amount': '0.0000',
    },
    '2013-04-26': {
      open: '191.1500',
      high: '195.1700',
      low: '187.6800',
      close: '194.3100',
      'adjusted close': '122.9217',
      volume: '27976600',
      'dividend amount': '0.0000',
    },
    '2013-04-19': {
      open: '210.4400',
      high: '212.0000',
      low: '189.7600',
      close: '190.0000',
      'adjusted close': '120.1952',
      volume: '35696400',
      'dividend amount': '0.0000',
    },
    '2013-04-12': {
      open: '209.0700',
      high: '213.0900',
      low: '207.3300',
      close: '211.3800',
      'adjusted close': '133.7203',
      volume: '15273700',
      'dividend amount': '0.0000',
    },
    '2013-04-05': {
      open: '212.8000',
      high: '214.8900',
      low: '206.3400',
      close: '209.4100',
      'adjusted close': '132.4741',
      volume: '16247600',
      'dividend amount': '0.0000',
    },
    '2013-03-28': {
      open: '212.5400',
      high: '213.4400',
      low: '209.7350',
      close: '213.3000',
      'adjusted close': '134.9349',
      volume: '12516100',
      'dividend amount': '0.0000',
    },
    '2013-03-22': {
      open: '212.9000',
      high: '215.8200',
      low: '210.1100',
      close: '212.0800',
      'adjusted close': '134.1631',
      volume: '18085500',
      'dividend amount': '0.0000',
    },
    '2013-03-15': {
      open: '210.0400',
      high: '215.9000',
      low: '209.0400',
      close: '214.9200',
      'adjusted close': '135.9597',
      volume: '23433000',
      'dividend amount': '0.0000',
    },
    '2013-03-08': {
      open: '202.5900',
      high: '210.7400',
      low: '202.5500',
      close: '210.3800',
      'adjusted close': '133.0877',
      volume: '18680900',
      'dividend amount': '0.0000',
    },
    '2013-03-01': {
      open: '201.6700',
      high: '203.1230',
      low: '197.5100',
      close: '202.9100',
      'adjusted close': '128.3621',
      volume: '19419000',
      'dividend amount': '0.0000',
    },
    '2013-02-22': {
      open: '200.6000',
      high: '201.8900',
      low: '198.1100',
      close: '201.0900',
      'adjusted close': '127.2108',
      volume: '13744500',
      'dividend amount': '0.0000',
    },
    '2013-02-15': {
      open: '200.9800',
      high: '201.9500',
      low: '199.0200',
      close: '200.9800',
      'adjusted close': '127.1412',
      volume: '14498300',
      'dividend amount': '0.0000',
    },
    '2013-02-08': {
      open: '204.1900',
      high: '205.0200',
      low: '198.6800',
      close: '201.6800',
      'adjusted close': '127.5840',
      volume: '16419900',
      'dividend amount': '0.8500',
    },
    '2013-02-01': {
      open: '204.8500',
      high: '206.2200',
      low: '202.9600',
      close: '205.1800',
      'adjusted close': '129.2516',
      volume: '15901300',
      'dividend amount': '0.0000',
    },
    '2013-01-25': {
      open: '194.3600',
      high: '208.5800',
      low: '194.0100',
      close: '204.9700',
      'adjusted close': '129.1193',
      volume: '27508900',
      'dividend amount': '0.0000',
    },
    '2013-01-18': {
      open: '192.8200',
      high: '195.0000',
      low: '190.3900',
      close: '194.4700',
      'adjusted close': '122.5049',
      volume: '19748400',
      'dividend amount': '0.0000',
    },
    '2013-01-11': {
      open: '193.4000',
      high: '195.0000',
      low: '191.2800',
      close: '194.4500',
      'adjusted close': '122.4923',
      volume: '16589700',
      'dividend amount': '0.0000',
    },
    '2013-01-04': {
      open: '189.2300',
      high: '196.3500',
      low: '188.8400',
      close: '193.9900',
      'adjusted close': '122.2026',
      volume: '15943900',
      'dividend amount': '0.0000',
    },
    '2012-12-28': {
      open: '193.0500',
      high: '193.8300',
      low: '189.8300',
      close: '189.8300',
      'adjusted close': '119.5820',
      volume: '11911900',
      'dividend amount': '0.0000',
    },
    '2012-12-21': {
      open: '191.7600',
      high: '196.4500',
      low: '191.5700',
      close: '193.4200',
      'adjusted close': '121.8435',
      volume: '24598600',
      'dividend amount': '0.0000',
    },
    '2012-12-14': {
      open: '192.1700',
      high: '194.8000',
      low: '191.2600',
      close: '191.7600',
      'adjusted close': '120.7978',
      volume: '19274500',
      'dividend amount': '0.0000',
    },
    '2012-12-07': {
      open: '190.7600',
      high: '192.2000',
      low: '186.9400',
      close: '191.9500',
      'adjusted close': '120.9175',
      volume: '18850300',
      'dividend amount': '0.0000',
    },
    '2012-11-30': {
      open: '192.4500',
      high: '193.3700',
      low: '189.2700',
      close: '190.0700',
      'adjusted close': '119.7332',
      volume: '21119100',
      'dividend amount': '0.0000',
    },
    '2012-11-23': {
      open: '188.6200',
      high: '193.4900',
      low: '188.2500',
      close: '193.4900',
      'adjusted close': '121.8876',
      volume: '15826300',
      'dividend amount': '0.0000',
    },
    '2012-11-16': {
      open: '190.0300',
      high: '190.8800',
      low: '184.7800',
      close: '186.9400',
      'adjusted close': '117.7615',
      volume: '18951000',
      'dividend amount': '0.0000',
    },
    '2012-11-09': {
      open: '192.3600',
      high: '196.7500',
      low: '188.8800',
      close: '189.6400',
      'adjusted close': '119.4623',
      volume: '17977200',
      'dividend amount': '0.8500',
    },
    '2012-11-02': {
      open: '194.8000',
      high: '198.0000',
      low: '193.2900',
      close: '193.4300',
      'adjusted close': '121.3104',
      volume: '14071200',
      'dividend amount': '0.0000',
    },
    '2012-10-26': {
      open: '193.7100',
      high: '194.6800',
      low: '190.5600',
      close: '193.2700',
      'adjusted close': '121.2100',
      volume: '19824000',
      'dividend amount': '0.0000',
    },
    '2012-10-19': {
      open: '208.8800',
      high: '211.0000',
      low: '193.1800',
      close: '193.3600',
      'adjusted close': '121.2665',
      volume: '37283100',
      'dividend amount': '0.0000',
    },
    '2012-10-12': {
      open: '210.0200',
      high: '210.7400',
      low: '205.0500',
      close: '207.8000',
      'adjusted close': '130.3226',
      volume: '15209500',
      'dividend amount': '0.0000',
    },
    '2012-10-05': {
      open: '208.0100',
      high: '211.7900',
      low: '207.9400',
      close: '210.5900',
      'adjusted close': '132.0723',
      volume: '17786100',
      'dividend amount': '0.0000',
    },
    '2012-09-28': {
      open: '205.0200',
      high: '208.3200',
      low: '203.9000',
      close: '207.4500',
      'adjusted close': '130.1031',
      volume: '17962400',
      'dividend amount': '0.0000',
    },
    '2012-09-21': {
      open: '205.5500',
      high: '207.9900',
      low: '205.3000',
      close: '205.9800',
      'adjusted close': '129.1812',
      volume: '24867400',
      'dividend amount': '0.0000',
    },
    '2012-09-14': {
      open: '199.3900',
      high: '207.6500',
      low: '198.7300',
      close: '206.8100',
      'adjusted close': '129.7017',
      volume: '19339300',
      'dividend amount': '0.0000',
    },
    '2012-09-07': {
      open: '196.6100',
      high: '199.5000',
      low: '193.2500',
      close: '199.5000',
      'adjusted close': '125.1172',
      volume: '15172300',
      'dividend amount': '0.0000',
    },
    '2012-08-31': {
      open: '197.9600',
      high: '198.3000',
      low: '193.1800',
      close: '194.8500',
      'adjusted close': '122.2009',
      volume: '13113600',
      'dividend amount': '0.0000',
    },
    '2012-08-24': {
      open: '200.6900',
      high: '201.1300',
      low: '194.2000',
      close: '197.7700',
      'adjusted close': '124.0322',
      volume: '14140300',
      'dividend amount': '0.0000',
    },
    '2012-08-17': {
      open: '198.8800',
      high: '202.0000',
      low: '197.7200',
      close: '201.2200',
      'adjusted close': '126.1959',
      volume: '12373700',
      'dividend amount': '0.0000',
    },
    '2012-08-10': {
      open: '198.7600',
      high: '200.8800',
      low: '197.2400',
      close: '199.2900',
      'adjusted close': '124.9855',
      volume: '12376100',
      'dividend amount': '0.8500',
    },
    '2012-08-03': {
      open: '196.3200',
      high: '198.9500',
      low: '193.0200',
      close: '198.5200',
      'adjusted close': '123.9731',
      volume: '14944000',
      'dividend amount': '0.0000',
    },
    '2012-07-27': {
      open: '189.7800',
      high: '197.4100',
      low: '188.2000',
      close: '196.3900',
      'adjusted close': '122.6430',
      volume: '18795600',
      'dividend amount': '0.0000',
    },
    '2012-07-20': {
      open: '185.5800',
      high: '196.8500',
      low: '183.2000',
      close: '192.4500',
      'adjusted close': '120.1825',
      volume: '31507600',
      'dividend amount': '0.0000',
    },
    '2012-07-13': {
      open: '190.7600',
      high: '191.1400',
      low: '181.8500',
      close: '186.0100',
      'adjusted close': '116.1608',
      volume: '22998800',
      'dividend amount': '0.0000',
    },
    '2012-07-06': {
      open: '196.3600',
      high: '197.2000',
      low: '189.7400',
      close: '191.4100',
      'adjusted close': '119.5330',
      volume: '11920500',
      'dividend amount': '0.0000',
    },
    '2012-06-29': {
      open: '192.4800',
      high: '195.8100',
      low: '188.9000',
      close: '195.5800',
      'adjusted close': '122.1371',
      volume: '18637100',
      'dividend amount': '0.0000',
    },
    '2012-06-22': {
      open: '197.5700',
      high: '199.9900',
      low: '193.1900',
      close: '193.7000',
      'adjusted close': '120.9631',
      volume: '23194300',
      'dividend amount': '0.0000',
    },
    '2012-06-15': {
      open: '196.6000',
      high: '199.2700',
      low: '192.1400',
      close: '199.1000',
      'adjusted close': '124.3353',
      volume: '19518700',
      'dividend amount': '0.0000',
    },
    '2012-06-08': {
      open: '188.3400',
      high: '195.8299',
      low: '187.0000',
      close: '195.1400',
      'adjusted close': '121.8624',
      volume: '18002600',
      'dividend amount': '0.0000',
    },
    '2012-06-01': {
      open: '195.2200',
      high: '198.0800',
      low: '188.6000',
      close: '189.0800',
      'adjusted close': '118.0780',
      volume: '21714900',
      'dividend amount': '0.0000',
    },
    '2012-05-25': {
      open: '195.9800',
      high: '198.2600',
      low: '193.2000',
      close: '194.3000',
      'adjusted close': '121.3378',
      volume: '17970000',
      'dividend amount': '0.0000',
    },
    '2012-05-18': {
      open: '199.9200',
      high: '201.4679',
      low: '195.1600',
      close: '195.8800',
      'adjusted close': '122.3245',
      volume: '22381000',
      'dividend amount': '0.0000',
    },
    '2012-05-11': {
      open: '203.9600',
      high: '204.7700',
      low: '199.0600',
      close: '201.1700',
      'adjusted close': '125.6280',
      volume: '17219400',
      'dividend amount': '0.8500',
    },
    '2012-05-04': {
      open: '206.7100',
      high: '208.9290',
      low: '204.4000',
      close: '204.9900',
      'adjusted close': '127.4758',
      volume: '14699600',
      'dividend amount': '0.0000',
    },
    '2012-04-27': {
      open: '197.8900',
      high: '208.1700',
      low: '196.7910',
      close: '206.8100',
      'adjusted close': '128.6076',
      volume: '20048100',
      'dividend amount': '0.0000',
    },
    '2012-04-20': {
      open: '204.7400',
      high: '207.6400',
      low: '197.5210',
      close: '199.6000',
      'adjusted close': '124.1239',
      volume: '30566700',
      'dividend amount': '0.0000',
    },
    '2012-04-13': {
      open: '204.4000',
      high: '206.1700',
      low: '202.1700',
      close: '202.8000',
      'adjusted close': '126.1139',
      volume: '17522600',
      'dividend amount': '0.0000',
    },
    '2012-04-05': {
      open: '208.9600',
      high: '210.6900',
      low: '204.4600',
      close: '205.4700',
      'adjusted close': '127.7743',
      volume: '15692400',
      'dividend amount': '0.0000',
    },
    '2012-03-30': {
      open: '206.4500',
      high: '209.1200',
      low: '206.0200',
      close: '208.6500',
      'adjusted close': '129.7518',
      volume: '16007300',
      'dividend amount': '0.0000',
    },
    '2012-03-23': {
      open: '205.7100',
      high: '206.5300',
      low: '203.7020',
      close: '205.4800',
      'adjusted close': '127.7805',
      volume: '17132200',
      'dividend amount': '0.0000',
    },
    '2012-03-16': {
      open: '200.9300',
      high: '207.5200',
      low: '200.2300',
      close: '206.0100',
      'adjusted close': '128.1101',
      volume: '25846100',
      'dividend amount': '0.0000',
    },
    '2012-03-09': {
      open: '198.3600',
      high: '201.1900',
      low: '196.8120',
      close: '200.6200',
      'adjusted close': '124.7582',
      volume: '21823600',
      'dividend amount': '0.0000',
    },
    '2012-03-02': {
      open: '196.7800',
      high: '199.1700',
      low: '196.1000',
      close: '198.8100',
      'adjusted close': '123.6327',
      volume: '19330600',
      'dividend amount': '0.0000',
    },
    '2012-02-24': {
      open: '193.7300',
      high: '199.2300',
      low: '192.4600',
      close: '197.7600',
      'adjusted close': '122.9797',
      volume: '17955300',
      'dividend amount': '0.0000',
    },
    '2012-02-17': {
      open: '193.3100',
      high: '193.8600',
      low: '190.8310',
      close: '193.4200',
      'adjusted close': '120.2808',
      volume: '17149800',
      'dividend amount': '0.0000',
    },
    '2012-02-10': {
      open: '192.4800',
      high: '194.4600',
      low: '191.2800',
      close: '192.4200',
      'adjusted close': '119.6590',
      volume: '18144100',
      'dividend amount': '0.7500',
    },
    '2012-02-03': {
      open: '189.3900',
      high: '194.8100',
      low: '188.2200',
      close: '193.6400',
      'adjusted close': '119.9514',
      volume: '22703600',
      'dividend amount': '0.0000',
    },
    '2012-01-27': {
      open: '187.9100',
      high: '192.7900',
      low: '187.6700',
      close: '190.4600',
      'adjusted close': '117.9815',
      volume: '22822200',
      'dividend amount': '0.0000',
    },
    '2012-01-20': {
      open: '180.3600',
      high: '188.9700',
      low: '179.3200',
      close: '188.5200',
      'adjusted close': '116.7798',
      volume: '32020900',
      'dividend amount': '0.0000',
    },
    '2012-01-13': {
      open: '182.2000',
      high: '183.7200',
      low: '177.3472',
      close: '179.1600',
      'adjusted close': '110.9817',
      volume: '26633200',
      'dividend amount': '0.0000',
    },
    '2012-01-06': {
      open: '186.7300',
      high: '188.7100',
      low: '182.3100',
      close: '182.5400',
      'adjusted close': '113.0754',
      volume: '19352900',
      'dividend amount': '0.0000',
    },
    '2011-12-30': {
      open: '184.9700',
      high: '186.4800',
      low: '183.3400',
      close: '183.8800',
      'adjusted close': '113.9055',
      volume: '12119700',
      'dividend amount': '0.0000',
    },
    '2011-12-23': {
      open: '184.5100',
      high: '187.3300',
      low: '179.0400',
      close: '184.7500',
      'adjusted close': '114.4444',
      volume: '27166800',
      'dividend amount': '0.0000',
    },
    '2011-12-16': {
      open: '193.6400',
      high: '194.3000',
      low: '181.9100',
      close: '183.5700',
      'adjusted close': '113.7135',
      volume: '30186100',
      'dividend amount': '0.0000',
    },
    '2011-12-09': {
      open: '191.1800',
      high: '194.9000',
      low: '190.3200',
      close: '194.5600',
      'adjusted close': '120.5213',
      volume: '24142900',
      'dividend amount': '0.0000',
    },
    '2011-12-02': {
      open: '182.7100',
      high: '191.3300',
      low: '180.6700',
      close: '189.6600',
      'adjusted close': '117.4859',
      volume: '25843600',
      'dividend amount': '0.0000',
    },
    '2011-11-25': {
      open: '183.3700',
      high: '183.9600',
      low: '177.0600',
      close: '177.0600',
      'adjusted close': '109.6808',
      volume: '16357400',
      'dividend amount': '0.0000',
    },
    '2011-11-18': {
      open: '189.1700',
      high: '189.9700',
      low: '183.3900',
      close: '185.2400',
      'adjusted close': '114.7479',
      volume: '25522100',
      'dividend amount': '0.0000',
    },
    '2011-11-11': {
      open: '186.2200',
      high: '187.8800',
      low: '181.1600',
      close: '187.3800',
      'adjusted close': '116.0736',
      volume: '20903100',
      'dividend amount': '0.7500',
    },
    '2011-11-04': {
      open: '185.5900',
      high: '187.7800',
      low: '180.7400',
      close: '186.3800',
      'adjusted close': '114.9935',
      volume: '22918900',
      'dividend amount': '0.0000',
    },
    '2011-10-28': {
      open: '181.5100',
      high: '188.0700',
      low: '179.0300',
      close: '187.4500',
      'adjusted close': '115.6537',
      volume: '28719000',
      'dividend amount': '0.0000',
    },
    '2011-10-21': {
      open: '189.7100',
      high: '190.1600',
      low: '176.1700',
      close: '181.6300',
      'adjusted close': '112.0629',
      volume: '46796000',
      'dividend amount': '0.0000',
    },
    '2011-10-14': {
      open: '183.0000',
      high: '190.5300',
      low: '182.9000',
      close: '190.5300',
      'adjusted close': '117.5540',
      volume: '26220900',
      'dividend amount': '0.0000',
    },
    '2011-10-07': {
      open: '174.3600',
      high: '183.7200',
      low: '168.8800',
      close: '182.3900',
      'adjusted close': '112.5318',
      volume: '38117800',
      'dividend amount': '0.0000',
    },
    '2011-09-30': {
      open: '170.9600',
      high: '180.9100',
      low: '169.8600',
      close: '174.8700',
      'adjusted close': '107.8920',
      volume: '36868400',
      'dividend amount': '0.0000',
    },
    '2011-09-23': {
      open: '169.5000',
      high: '177.6700',
      low: '165.7600',
      close: '169.3400',
      'adjusted close': '104.4801',
      volume: '31790500',
      'dividend amount': '0.0000',
    },
    '2011-09-16': {
      open: '159.6400',
      high: '172.9900',
      low: '158.7600',
      close: '172.9900',
      'adjusted close': '106.7321',
      volume: '33464200',
      'dividend amount': '0.0000',
    },
    '2011-09-09': {
      open: '163.0600',
      high: '169.5800',
      low: '160.8100',
      close: '161.3700',
      'adjusted close': '99.5628',
      volume: '25328900',
      'dividend amount': '0.0000',
    },
    '2011-09-02': {
      open: '170.5800',
      high: '173.7200',
      low: '166.0000',
      close: '166.9800',
      'adjusted close': '103.0240',
      volume: '26288300',
      'dividend amount': '0.0000',
    },
    '2011-08-26': {
      open: '161.3500',
      high: '169.3300',
      low: '157.6200',
      close: '169.1400',
      'adjusted close': '104.3567',
      volume: '33789200',
      'dividend amount': '0.0000',
    },
    '2011-08-19': {
      open: '171.3200',
      high: '174.0000',
      low: '157.1300',
      close: '157.5400',
      'adjusted close': '97.1997',
      volume: '47511700',
      'dividend amount': '0.0000',
    },
    '2011-08-12': {
      open: '168.8300',
      high: '172.6100',
      low: '161.8500',
      close: '168.2000',
      'adjusted close': '103.7768',
      volume: '56678700',
      'dividend amount': '0.7500',
    },
    '2011-08-05': {
      open: '182.6000',
      high: '183.6900',
      low: '166.5200',
      close: '172.9800',
      'adjusted close': '106.2466',
      volume: '41229200',
      'dividend amount': '0.0000',
    },
    '2011-07-29': {
      open: '183.8900',
      high: '184.9600',
      low: '179.7300',
      close: '181.8500',
      'adjusted close': '111.6946',
      volume: '26294400',
      'dividend amount': '0.0000',
    },
    '2011-07-22': {
      open: '174.7300',
      high: '185.6300',
      low: '173.5800',
      close: '185.1800',
      'adjusted close': '113.7400',
      volume: '37500900',
      'dividend amount': '0.0000',
    },
    '2011-07-15': {
      open: '174.9000',
      high: '176.3200',
      low: '173.8400',
      close: '175.5400',
      'adjusted close': '107.8189',
      volume: '23838300',
      'dividend amount': '0.0000',
    },
    '2011-07-08': {
      open: '173.5200',
      high: '177.7700',
      low: '173.5200',
      close: '176.4900',
      'adjusted close': '108.4024',
      volume: '20777900',
      'dividend amount': '0.0000',
    },
    '2011-07-01': {
      open: '165.7400',
      high: '174.6500',
      low: '165.2100',
      close: '174.5400',
      'adjusted close': '107.2047',
      volume: '23400700',
      'dividend amount': '0.0000',
    },
    '2011-06-24': {
      open: '163.7000',
      high: '166.8100',
      low: '163.5900',
      close: '165.0700',
      'adjusted close': '101.3881',
      volume: '22978000',
      'dividend amount': '0.0000',
    },
    '2011-06-17': {
      open: '164.4400',
      high: '165.1000',
      low: '161.5200',
      close: '164.4400',
      'adjusted close': '101.0012',
      volume: '29385600',
      'dividend amount': '0.0000',
    },
    '2011-06-10': {
      open: '164.7600',
      high: '165.9600',
      low: '162.8700',
      close: '163.1800',
      'adjusted close': '100.2273',
      volume: '20640600',
      'dividend amount': '0.0000',
    },
    '2011-06-03': {
      open: '168.4400',
      high: '169.8900',
      low: '164.1300',
      close: '165.0500',
      'adjusted close': '101.3758',
      volume: '23342600',
      'dividend amount': '0.0000',
    },
    '2011-05-27': {
      open: '168.5800',
      high: '168.6900',
      low: '165.9000',
      close: '167.5000',
      'adjusted close': '102.8807',
      volume: '22718600',
      'dividend amount': '0.0000',
    },
    '2011-05-20': {
      open: '169.8100',
      high: '171.4100',
      low: '166.5300',
      close: '170.1600',
      'adjusted close': '104.5145',
      volume: '25997900',
      'dividend amount': '0.0000',
    },
    '2011-05-13': {
      open: '168.3900',
      high: '172.7700',
      low: '167.8200',
      close: '169.9200',
      'adjusted close': '104.3671',
      volume: '23498700',
      'dividend amount': '0.0000',
    },
    '2011-05-06': {
      open: '172.1100',
      high: '173.5400',
      low: '167.5000',
      close: '168.8900',
      'adjusted close': '103.7344',
      volume: '29465600',
      'dividend amount': '0.7500',
    },
    '2011-04-29': {
      open: '167.6500',
      high: '173.0000',
      low: '167.2300',
      close: '170.5800',
      'adjusted close': '104.3092',
      volume: '26858900',
      'dividend amount': '0.0000',
    },
    '2011-04-21': {
      open: '164.6400',
      high: '168.4500',
      low: '162.1900',
      close: '168.2800',
      'adjusted close': '102.9028',
      volume: '29758000',
      'dividend amount': '0.0000',
    },
    '2011-04-15': {
      open: '163.4400',
      high: '166.3400',
      low: '162.3000',
      close: '166.2100',
      'adjusted close': '101.6370',
      volume: '22168000',
      'dividend amount': '0.0000',
    },
    '2011-04-08': {
      open: '164.4200',
      high: '164.7500',
      low: '163.1600',
      close: '164.0500',
      'adjusted close': '100.3162',
      volume: '17602700',
      'dividend amount': '0.0000',
    },
    '2011-04-01': {
      open: '161.5400',
      high: '164.4200',
      low: '161.1200',
      close: '164.2700',
      'adjusted close': '100.4507',
      volume: '19066300',
      'dividend amount': '0.0000',
    },
    '2011-03-25': {
      open: '157.6400',
      high: '162.7400',
      low: '157.0700',
      close: '162.1800',
      'adjusted close': '99.1727',
      volume: '25371800',
      'dividend amount': '0.0000',
    },
    '2011-03-18': {
      open: '161.1600',
      high: '161.9804',
      low: '151.7100',
      close: '155.8900',
      'adjusted close': '95.3263',
      volume: '41798000',
      'dividend amount': '0.0000',
    },
    '2011-03-11': {
      open: '161.6000',
      high: '167.7200',
      low: '158.8500',
      close: '162.4300',
      'adjusted close': '99.3255',
      volume: '31095800',
      'dividend amount': '0.0000',
    },
    '2011-03-04': {
      open: '162.3600',
      high: '164.3100',
      low: '159.4100',
      close: '161.8300',
      'adjusted close': '98.9586',
      volume: '21537300',
      'dividend amount': '0.0000',
    },
    '2011-02-25': {
      open: '163.5700',
      high: '164.2600',
      low: '159.0300',
      close: '162.2800',
      'adjusted close': '99.2338',
      volume: '21433300',
      'dividend amount': '0.0000',
    },
    '2011-02-18': {
      open: '164.1800',
      high: '164.8400',
      low: '162.5200',
      close: '164.8400',
      'adjusted close': '100.7992',
      volume: '18590000',
      'dividend amount': '0.0000',
    },
    '2011-02-11': {
      open: '164.0800',
      high: '166.2500',
      low: '163.1800',
      close: '163.8500',
      'adjusted close': '100.1939',
      volume: '26097300',
      'dividend amount': '0.6500',
    },
    '2011-02-04': {
      open: '159.1800',
      high: '164.2000',
      low: '158.6800',
      close: '164.0000',
      'adjusted close': '99.8945',
      volume: '25371100',
      'dividend amount': '0.0000',
    },
    '2011-01-28': {
      open: '155.4200',
      high: '164.3500',
      low: '155.3300',
      close: '159.2100',
      'adjusted close': '96.9769',
      volume: '32502900',
      'dividend amount': '0.0000',
    },
    '2011-01-21': {
      open: '149.8200',
      high: '156.7800',
      low: '149.3800',
      close: '155.5000',
      'adjusted close': '94.7171',
      volume: '35766800',
      'dividend amount': '0.0000',
    },
    '2011-01-14': {
      open: '147.5800',
      high: '150.0000',
      low: '146.7500',
      close: '150.0000',
      'adjusted close': '91.3670',
      volume: '19878500',
      'dividend amount': '0.0000',
    },
    '2011-01-07': {
      open: '147.2100',
      high: '148.8600',
      low: '146.6400',
      close: '147.9300',
      'adjusted close': '90.1061',
      volume: '23486200',
      'dividend amount': '0.0000',
    },
    '2010-12-31': {
      open: '145.1200',
      high: '147.5000',
      low: '145.0000',
      close: '146.7600',
      'adjusted close': '89.3934',
      volume: '14684300',
      'dividend amount': '0.0000',
    },
    '2010-12-23': {
      open: '145.3900',
      high: '146.4000',
      low: '144.3300',
      close: '145.8900',
      'adjusted close': '88.8635',
      volume: '13585400',
      'dividend amount': '0.0000',
    },
    '2010-12-17': {
      open: '145.1400',
      high: '146.0100',
      low: '144.1500',
      close: '145.0000',
      'adjusted close': '88.3214',
      volume: '28194800',
      'dividend amount': '0.0000',
    },
    '2010-12-10': {
      open: '144.5400',
      high: '146.2999',
      low: '143.5200',
      close: '144.8200',
      'adjusted close': '88.2118',
      volume: '21020900',
      'dividend amount': '0.0000',
    },
    '2010-12-03': {
      open: '143.5300',
      high: '145.8500',
      low: '141.2800',
      close: '145.3800',
      'adjusted close': '88.5529',
      volume: '28622500',
      'dividend amount': '0.0000',
    },
    '2010-11-26': {
      open: '144.0000',
      high: '146.4400',
      low: '142.3300',
      close: '143.9000',
      'adjusted close': '87.6514',
      volume: '15256400',
      'dividend amount': '0.0000',
    },
    '2010-11-19': {
      open: '143.8900',
      high: '145.3500',
      low: '141.1800',
      close: '145.0500',
      'adjusted close': '88.3518',
      volume: '24207800',
      'dividend amount': '0.0000',
    },
    '2010-11-12': {
      open: '145.3500',
      high: '147.5300',
      low: '143.3500',
      close: '143.7400',
      'adjusted close': '87.5539',
      volume: '24816000',
      'dividend amount': '0.6500',
    },
    '2010-11-05': {
      open: '143.6400',
      high: '146.9300',
      low: '142.3200',
      close: '146.9200',
      'adjusted close': '89.0955',
      volume: '27101400',
      'dividend amount': '0.0000',
    },
    '2010-10-29': {
      open: '140.4200',
      high: '144.0000',
      low: '138.5300',
      close: '143.6000',
      'adjusted close': '87.0822',
      volume: '32583300',
      'dividend amount': '0.0000',
    },
    '2010-10-22': {
      open: '140.9000',
      high: '143.0300',
      low: '136.7000',
      close: '139.6700',
      'adjusted close': '84.6989',
      volume: '41753800',
      'dividend amount': '0.0000',
    },
    '2010-10-15': {
      open: '138.7900',
      high: '142.1000',
      low: '138.2700',
      close: '141.0600',
      'adjusted close': '85.5418',
      volume: '31299400',
      'dividend amount': '0.0000',
    },
    '2010-10-08': {
      open: '135.2300',
      high: '139.0900',
      low: '134.3900',
      close: '138.8500',
      'adjusted close': '84.2017',
      volume: '26027000',
      'dividend amount': '0.0000',
    },
    '2010-10-01': {
      open: '133.5100',
      high: '136.2800',
      low: '133.5100',
      close: '135.6400',
      'adjusted close': '82.2550',
      volume: '30834900',
      'dividend amount': '0.0000',
    },
    '2010-09-24': {
      open: '130.2400',
      high: '134.1500',
      low: '130.1100',
      close: '134.1100',
      'adjusted close': '81.3272',
      volume: '28357000',
      'dividend amount': '0.0000',
    },
    '2010-09-17': {
      open: '128.6300',
      high: '130.6000',
      low: '128.4300',
      close: '130.1900',
      'adjusted close': '78.9500',
      volume: '29966700',
      'dividend amount': '0.0000',
    },
    '2010-09-10': {
      open: '126.9900',
      high: '128.2900',
      low: '125.3900',
      close: '127.9900',
      'adjusted close': '77.6159',
      volume: '16686200',
      'dividend amount': '0.0000',
    },
    '2010-09-03': {
      open: '125.0800',
      high: '127.6000',
      low: '122.2800',
      close: '127.5800',
      'adjusted close': '77.3673',
      volume: '25097500',
      'dividend amount': '0.0000',
    },
    '2010-08-27': {
      open: '127.5700',
      high: '128.0300',
      low: '122.4200',
      close: '124.7300',
      'adjusted close': '75.6390',
      volume: '28851700',
      'dividend amount': '0.0000',
    },
    '2010-08-20': {
      open: '127.4700',
      high: '130.1950',
      low: '126.9600',
      close: '127.5000',
      'adjusted close': '77.3188',
      volume: '24839400',
      'dividend amount': '0.0000',
    },
    '2010-08-13': {
      open: '130.7900',
      high: '132.4900',
      low: '127.3300',
      close: '127.8700',
      'adjusted close': '77.5431',
      volume: '27721100',
      'dividend amount': '0.0000',
    },
    '2010-08-06': {
      open: '129.2500',
      high: '131.9800',
      low: '128.7600',
      close: '130.1400',
      'adjusted close': '78.9197',
      volume: '26753600',
      'dividend amount': '0.6500',
    },
    '2010-07-30': {
      open: '128.1800',
      high: '129.5000',
      low: '127.0400',
      close: '128.4000',
      'adjusted close': '77.4776',
      volume: '29087100',
      'dividend amount': '0.0000',
    },
    '2010-07-23': {
      open: '128.6700',
      high: '130.3800',
      low: '122.9300',
      close: '128.3800',
      'adjusted close': '77.4655',
      volume: '45328500',
      'dividend amount': '0.0000',
    },
    '2010-07-16': {
      open: '127.3700',
      high: '131.6000',
      low: '127.1600',
      close: '128.0300',
      'adjusted close': '77.2543',
      volume: '30691500',
      'dividend amount': '0.0000',
    },
    '2010-07-09': {
      open: '123.5800',
      high: '128.2000',
      low: '122.1700',
      close: '127.9600',
      'adjusted close': '77.2121',
      volume: '22776300',
      'dividend amount': '0.0000',
    },
    '2010-07-02': {
      open: '127.6500',
      high: '129.4700',
      low: '120.6100',
      close: '121.8600',
      'adjusted close': '73.5313',
      volume: '39925900',
      'dividend amount': '0.0000',
    },
    '2010-06-25': {
      open: '131.4200',
      high: '131.9400',
      low: '127.1200',
      close: '127.1200',
      'adjusted close': '76.7052',
      volume: '35729700',
      'dividend amount': '0.0000',
    },
    '2010-06-18': {
      open: '128.5000',
      high: '131.2500',
      low: '128.3400',
      close: '130.1500',
      'adjusted close': '78.5335',
      volume: '34958900',
      'dividend amount': '0.0000',
    },
    '2010-06-11': {
      open: '125.5700',
      high: '128.8000',
      low: '122.8200',
      close: '128.4500',
      'adjusted close': '77.5077',
      volume: '36446000',
      'dividend amount': '0.0000',
    },
    '2010-06-04': {
      open: '124.6900',
      high: '128.2200',
      low: '124.2000',
      close: '125.2800',
      'adjusted close': '75.5949',
      volume: '31155200',
      'dividend amount': '0.0000',
    },
    '2010-05-28': {
      open: '125.2600',
      high: '126.3900',
      low: '121.4700',
      close: '125.2600',
      'adjusted close': '75.5829',
      volume: '40594400',
      'dividend amount': '0.0000',
    },
    '2010-05-21': {
      open: '130.6800',
      high: '131.9900',
      low: '121.4000',
      close: '125.4200',
      'adjusted close': '75.6794',
      volume: '52738700',
      'dividend amount': '0.0000',
    },
    '2010-05-14': {
      open: '126.2700',
      high: '133.1000',
      low: '125.0600',
      close: '131.1900',
      'adjusted close': '79.1611',
      volume: '52007600',
      'dividend amount': '0.0000',
    },
    '2010-05-07': {
      open: '129.3900',
      high: '130.1400',
      low: '116.0000',
      close: '122.1000',
      'adjusted close': '73.6761',
      volume: '43103500',
      'dividend amount': '0.6500',
    },
    '2010-04-30': {
      open: '129.7600',
      high: '132.0000',
      low: '128.7100',
      close: '129.0000',
      'adjusted close': '77.4335',
      volume: '35378000',
      'dividend amount': '0.0000',
    },
    '2010-04-23': {
      open: '130.3800',
      high: '132.2800',
      low: '127.7700',
      close: '129.9900',
      'adjusted close': '78.0277',
      volume: '46345200',
      'dividend amount': '0.0000',
    },
    '2010-04-16': {
      open: '128.5700',
      high: '132.1700',
      low: '127.8400',
      close: '130.6300',
      'adjusted close': '78.4119',
      volume: '35328700',
      'dividend amount': '0.0000',
    },
    '2010-04-09': {
      open: '128.3800',
      high: '129.8000',
      low: '127.1200',
      close: '128.7600',
      'adjusted close': '77.2894',
      volume: '24394000',
      'dividend amount': '0.0000',
    },
    '2010-04-01': {
      open: '129.3000',
      high: '129.9500',
      low: '127.5500',
      close: '128.2500',
      'adjusted close': '76.9833',
      volume: '17954900',
      'dividend amount': '0.0000',
    },
    '2010-03-26': {
      open: '127.1100',
      high: '130.7300',
      low: '126.5700',
      close: '129.2600',
      'adjusted close': '77.5895',
      volume: '31453700',
      'dividend amount': '0.0000',
    },
    '2010-03-19': {
      open: '127.4000',
      high: '128.9300',
      low: '126.7800',
      close: '127.7100',
      'adjusted close': '76.6591',
      volume: '32679700',
      'dividend amount': '0.0000',
    },
    '2010-03-12': {
      open: '127.0600',
      high: '128.3700',
      low: '125.2000',
      close: '127.9400',
      'adjusted close': '76.7972',
      volume: '33736000',
      'dividend amount': '0.0000',
    },
    '2010-03-05': {
      open: '127.5000',
      high: '129.0900',
      low: '125.4700',
      close: '127.2500',
      'adjusted close': '76.3830',
      volume: '29153500',
      'dividend amount': '0.0000',
    },
    '2010-02-26': {
      open: '127.3000',
      high: '128.2700',
      low: '125.5700',
      close: '127.1600',
      'adjusted close': '76.3290',
      volume: '23624900',
      'dividend amount': '0.0000',
    },
    '2010-02-19': {
      open: '124.9100',
      high: '128.0600',
      low: '124.1100',
      close: '127.1900',
      'adjusted close': '76.3470',
      volume: '24433700',
      'dividend amount': '0.0000',
    },
    '2010-02-12': {
      open: '123.1500',
      high: '124.2000',
      low: '121.6100',
      close: '124.0000',
      'adjusted close': '74.4322',
      volume: '30088800',
      'dividend amount': '0.5500',
    },
    '2010-02-05': {
      open: '123.2300',
      high: '126.0700',
      low: '121.8300',
      close: '122.9700',
      'adjusted close': '73.4823',
      volume: '35063800',
      'dividend amount': '0.0000',
    },
    '2010-01-29': {
      open: '126.3300',
      high: '127.7500',
      low: '121.9000',
      close: '122.3900',
      'adjusted close': '73.1357',
      volume: '42786400',
      'dividend amount': '0.0000',
    },
    '2010-01-22': {
      open: '131.6300',
      high: '134.2500',
      low: '125.3700',
      close: '125.5000',
      'adjusted close': '74.9941',
      volume: '48810900',
      'dividend amount': '0.0000',
    },
    '2010-01-15': {
      open: '131.0600',
      high: '132.8900',
      low: '128.6700',
      close: '131.7800',
      'adjusted close': '78.7468',
      volume: '35873500',
      'dividend amount': '0.0000',
    },
    '2010-01-08': {
      open: '131.1800',
      high: '132.9700',
      low: '128.9100',
      close: '130.8500',
      'adjusted close': '78.1911',
      volume: '28639800',
      'dividend amount': '0.0000',
    },
    '2009-12-31': {
      open: '130.9900',
      high: '132.8500',
      low: '130.6800',
      close: '130.9000',
      'adjusted close': '78.2210',
      volume: '18075000',
      'dividend amount': '0.0000',
    },
    '2009-12-24': {
      open: '127.8000',
      high: '130.5700',
      low: '127.6800',
      close: '130.5700',
      'adjusted close': '78.0238',
      volume: '18700700',
      'dividend amount': '0.0000',
    },
    '2009-12-18': {
      open: '129.6500',
      high: '129.9800',
      low: '127.0000',
      close: '127.9100',
      'adjusted close': '76.4343',
      volume: '34452500',
      'dividend amount': '0.0000',
    },
    '2009-12-11': {
      open: '126.8800',
      high: '129.7700',
      low: '126.1100',
      close: '129.6800',
      'adjusted close': '77.4919',
      volume: '29242700',
      'dividend amount': '0.0000',
    },
    '2009-12-04': {
      open: '125.1200',
      high: '128.9000',
      low: '124.9200',
      close: '127.2500',
      'adjusted close': '76.0399',
      volume: '30307700',
      'dividend amount': '0.0000',
    },
    '2009-11-27': {
      open: '127.7000',
      high: '128.9400',
      low: '124.2600',
      close: '125.7000',
      'adjusted close': '75.1136',
      volume: '19106900',
      'dividend amount': '0.0000',
    },
    '2009-11-20': {
      open: '127.1700',
      high: '128.6550',
      low: '126.4600',
      close: '126.9600',
      'adjusted close': '75.8666',
      volume: '23482700',
      'dividend amount': '0.0000',
    },
    '2009-11-13': {
      open: '123.9200',
      high: '127.8000',
      low: '123.4900',
      close: '127.0300',
      'adjusted close': '75.9084',
      volume: '31065000',
      'dividend amount': '0.0000',
    },
    '2009-11-06': {
      open: '120.6100',
      high: '123.5000',
      low: '119.5000',
      close: '123.4900',
      'adjusted close': '73.7930',
      volume: '30790900',
      'dividend amount': '0.5500',
    },
    '2009-10-30': {
      open: '120.6100',
      high: '124.3000',
      low: '119.1500',
      close: '120.6100',
      'adjusted close': '71.7525',
      volume: '38785400',
      'dividend amount': '0.0000',
    },
    '2009-10-23': {
      open: '121.7600',
      high: '124.1100',
      low: '120.0000',
      close: '120.3600',
      'adjusted close': '71.6037',
      volume: '36292000',
      'dividend amount': '0.0000',
    },
    '2009-10-16': {
      open: '125.4400',
      high: '128.6100',
      low: '121.2500',
      close: '121.6400',
      'adjusted close': '72.3652',
      volume: '55978800',
      'dividend amount': '0.0000',
    },
    '2009-10-09': {
      open: '118.9000',
      high: '126.0000',
      low: '118.1300',
      close: '125.9300',
      'adjusted close': '74.9174',
      volume: '37825500',
      'dividend amount': '0.0000',
    },
    '2009-10-02': {
      open: '120.5200',
      high: '122.0000',
      low: '117.2600',
      close: '119.0200',
      'adjusted close': '70.8066',
      volume: '37380100',
      'dividend amount': '0.0000',
    },
    '2009-09-25': {
      open: '121.0300',
      high: '122.7400',
      low: '120.1000',
      close: '121.0800',
      'adjusted close': '72.0321',
      volume: '26232600',
      'dividend amount': '0.0000',
    },
    '2009-09-18': {
      open: '117.0000',
      high: '122.8800',
      low: '116.9400',
      close: '122.1100',
      'adjusted close': '72.6448',
      volume: '40859700',
      'dividend amount': '0.0000',
    },
    '2009-09-11': {
      open: '117.9400',
      high: '119.2500',
      low: '116.1600',
      close: '118.0500',
      'adjusted close': '70.2295',
      volume: '21828700',
      'dividend amount': '0.0000',
    },
    '2009-09-04': {
      open: '117.1000',
      high: '118.9300',
      low: '115.1500',
      close: '117.4600',
      'adjusted close': '69.8785',
      volume: '25309900',
      'dividend amount': '0.0000',
    },
    '2009-08-28': {
      open: '119.9200',
      high: '120.8800',
      low: '117.5100',
      close: '118.2200',
      'adjusted close': '70.3306',
      volume: '26123700',
      'dividend amount': '0.0000',
    },
    '2009-08-21': {
      open: '116.6900',
      high: '120.0100',
      low: '116.1200',
      close: '119.9000',
      'adjusted close': '71.3301',
      volume: '27622200',
      'dividend amount': '0.0000',
    },
    '2009-08-14': {
      open: '118.1700',
      high: '119.9600',
      low: '117.3200',
      close: '118.5700',
      'adjusted close': '70.5389',
      volume: '25401100',
      'dividend amount': '0.0000',
    },
    '2009-08-07': {
      open: '118.8800',
      high: '119.9600',
      low: '116.7000',
      close: '119.3300',
      'adjusted close': '70.9910',
      volume: '27893800',
      'dividend amount': '0.5500',
    },
    '2009-07-31': {
      open: '116.9500',
      high: '119.3700',
      low: '116.0500',
      close: '117.9300',
      'adjusted close': '69.8309',
      volume: '28085700',
      'dividend amount': '0.0000',
    },
    '2009-07-24': {
      open: '114.5300',
      high: '118.1500',
      low: '114.3900',
      close: '117.6400',
      'adjusted close': '69.6592',
      volume: '42520000',
      'dividend amount': '0.0000',
    },
    '2009-07-17': {
      open: '101.2800',
      high: '115.5300',
      low: '100.1900',
      close: '115.4200',
      'adjusted close': '68.3446',
      volume: '58793700',
      'dividend amount': '0.0000',
    },
    '2009-07-10': {
      open: '101.5700',
      high: '102.7800',
      low: '99.5000',
      close: '100.8300',
      'adjusted close': '59.7053',
      volume: '34208000',
      'dividend amount': '0.0000',
    },
    '2009-07-02': {
      open: '105.9900',
      high: '106.2700',
      low: '101.7300',
      close: '101.7300',
      'adjusted close': '60.2383',
      volume: '24758400',
      'dividend amount': '0.0000',
    },
    '2009-06-26': {
      open: '105.1800',
      high: '106.7850',
      low: '103.5100',
      close: '105.6800',
      'adjusted close': '62.5772',
      volume: '30871700',
      'dividend amount': '0.0000',
    },
    '2009-06-19': {
      open: '107.6300',
      high: '109.1400',
      low: '105.5000',
      close: '105.8900',
      'adjusted close': '62.7016',
      volume: '42930700',
      'dividend amount': '0.0000',
    },
    '2009-06-12': {
      open: '106.6400',
      high: '110.6400',
      low: '105.5000',
      close: '108.2100',
      'adjusted close': '64.0753',
      volume: '38913000',
      'dividend amount': '0.0000',
    },
    '2009-06-05': {
      open: '106.9400',
      high: '108.8000',
      low: '105.1100',
      close: '107.2400',
      'adjusted close': '63.5009',
      volume: '35592300',
      'dividend amount': '0.0000',
    },
    '2009-05-29': {
      open: '101.3200',
      high: '106.5000',
      low: '101.0200',
      close: '106.2800',
      'adjusted close': '62.9325',
      volume: '27330100',
      'dividend amount': '0.0000',
    },
    '2009-05-22': {
      open: '102.0000',
      high: '106.1200',
      low: '101.7400',
      close: '101.8900',
      'adjusted close': '60.3330',
      volume: '34599500',
      'dividend amount': '0.0000',
    },
    '2009-05-15': {
      open: '101.0900',
      high: '104.3800',
      low: '100.5700',
      close: '101.3700',
      'adjusted close': '60.0251',
      volume: '42229200',
      'dividend amount': '0.0000',
    },
    '2009-05-08': {
      open: '105.2600',
      high: '106.8200',
      low: '99.8300',
      close: '101.4900',
      'adjusted close': '60.0961',
      volume: '42691700',
      'dividend amount': '0.5500',
    },
    '2009-05-01': {
      open: '99.6100',
      high: '106.0500',
      low: '99.2500',
      close: '104.6100',
      'adjusted close': '61.6197',
      volume: '46394300',
      'dividend amount': '0.0000',
    },
    '2009-04-24': {
      open: '100.2900',
      high: '103.8600',
      low: '98.2000',
      close: '100.0800',
      'adjusted close': '58.9513',
      volume: '59469300',
      'dividend amount': '0.0000',
    },
    '2009-04-17': {
      open: '100.2800',
      high: '102.0400',
      low: '96.4400',
      close: '101.2700',
      'adjusted close': '59.6523',
      volume: '41711800',
      'dividend amount': '0.0000',
    },
    '2009-04-09': {
      open: '100.9000',
      high: '102.4500',
      low: '98.5200',
      close: '101.7000',
      'adjusted close': '59.9056',
      volume: '32887100',
      'dividend amount': '0.0000',
    },
    '2009-04-03': {
      open: '92.0000',
      high: '102.6600',
      low: '91.8000',
      close: '102.2200',
      'adjusted close': '60.2119',
      volume: '64912500',
      'dividend amount': '0.0000',
    },
    '2009-03-27': {
      open: '93.7000',
      high: '99.8600',
      low: '92.7500',
      close: '94.1500',
      'adjusted close': '55.4583',
      volume: '60950700',
      'dividend amount': '0.0000',
    },
    '2009-03-20': {
      open: '91.3400',
      high: '95.0000',
      low: '89.4100',
      close: '92.5100',
      'adjusted close': '54.4923',
      volume: '63330900',
      'dividend amount': '0.0000',
    },
    '2009-03-13': {
      open: '84.7000',
      high: '90.6500',
      low: '83.0200',
      close: '90.3600',
      'adjusted close': '53.2258',
      volume: '61831600',
      'dividend amount': '0.0000',
    },
    '2009-03-06': {
      open: '91.1700',
      high: '91.9000',
      low: '83.8100',
      close: '85.8100',
      'adjusted close': '50.5457',
      volume: '74483600',
      'dividend amount': '0.0000',
    },
    '2009-02-27': {
      open: '89.3000',
      high: '93.2800',
      low: '82.8500',
      close: '92.0300',
      'adjusted close': '54.2095',
      volume: '77805700',
      'dividend amount': '0.0000',
    },
    '2009-02-20': {
      open: '91.7300',
      high: '92.4900',
      low: '87.3400',
      close: '88.7900',
      'adjusted close': '52.3010',
      volume: '42419500',
      'dividend amount': '0.0000',
    },
    '2009-02-13': {
      open: '95.9300',
      high: '96.9800',
      low: '92.2000',
      close: '93.8400',
      'adjusted close': '55.2757',
      volume: '49132900',
      'dividend amount': '0.0000',
    },
    '2009-02-06': {
      open: '90.6000',
      high: '97.1000',
      low: '89.6900',
      close: '96.1400',
      'adjusted close': '56.6305',
      volume: '53659500',
      'dividend amount': '0.5000',
    },
    '2009-01-30': {
      open: '89.7700',
      high: '94.9400',
      low: '89.5200',
      close: '91.6500',
      'adjusted close': '53.7064',
      volume: '50819300',
      'dividend amount': '0.0000',
    },
    '2009-01-23': {
      open: '84.7600',
      high: '91.6000',
      low: '81.7600',
      close: '89.4900',
      'adjusted close': '52.4406',
      volume: '61309500',
      'dividend amount': '0.0000',
    },
    '2009-01-16': {
      open: '84.5700',
      high: '86.6000',
      low: '82.1100',
      close: '84.9200',
      'adjusted close': '49.7626',
      volume: '48180000',
      'dividend amount': '0.0000',
    },
    '2009-01-09': {
      open: '86.4200',
      high: '90.4100',
      low: '84.2500',
      close: '84.7000',
      'adjusted close': '49.6337',
      volume: '41092400',
      'dividend amount': '0.0000',
    },
    '2009-01-02': {
      open: '81.7200',
      high: '87.5900',
      low: '79.6800',
      close: '87.3700',
      'adjusted close': '51.1983',
      volume: '26062900',
      'dividend amount': '0.0000',
    },
    '2008-12-26': {
      open: '83.2700',
      high: '83.4500',
      low: '79.9200',
      close: '81.3300',
      'adjusted close': '47.6589',
      volume: '18974200',
      'dividend amount': '0.0000',
    },
    '2008-12-19': {
      open: '82.5100',
      high: '87.2700',
      low: '80.0000',
      close: '83.5200',
      'adjusted close': '48.9422',
      volume: '50128100',
      'dividend amount': '0.0000',
    },
    '2008-12-12': {
      open: '82.5700',
      high: '85.8800',
      low: '78.0600',
      close: '82.2000',
      'adjusted close': '48.1687',
      volume: '49785100',
      'dividend amount': '0.0000',
    },
    '2008-12-05': {
      open: '80.9500',
      high: '81.5000',
      low: '75.3100',
      close: '80.5900',
      'adjusted close': '47.2253',
      volume: '51454000',
      'dividend amount': '0.0000',
    },
    '2008-11-28': {
      open: '75.7400',
      high: '82.4000',
      low: '75.0000',
      close: '81.6000',
      'adjusted close': '47.8171',
      volume: '39691800',
      'dividend amount': '0.0000',
    },
    '2008-11-21': {
      open: '79.4000',
      high: '81.0000',
      low: '69.5000',
      close: '74.8800',
      'adjusted close': '43.8793',
      volume: '73411500',
      'dividend amount': '0.0000',
    },
    '2008-11-14': {
      open: '87.9900',
      high: '88.1000',
      low: '75.4000',
      close: '80.3300',
      'adjusted close': '47.0729',
      volume: '58194400',
      'dividend amount': '0.0000',
    },
    '2008-11-07': {
      open: '92.6400',
      high: '94.7600',
      low: '84.2500',
      close: '86.2700',
      'adjusted close': '50.5537',
      volume: '47015300',
      'dividend amount': '0.5000',
    },
    '2008-10-31': {
      open: '80.2700',
      high: '94.1900',
      low: '79.0100',
      close: '92.9700',
      'adjusted close': '54.1619',
      volume: '60537100',
      'dividend amount': '0.0000',
    },
    '2008-10-24': {
      open: '92.2100',
      high: '93.3100',
      low: '78.8200',
      close: '82.0700',
      'adjusted close': '47.8118',
      volume: '58306900',
      'dividend amount': '0.0000',
    },
    '2008-10-17': {
      open: '90.4400',
      high: '99.0000',
      low: '84.3500',
      close: '90.7800',
      'adjusted close': '52.8860',
      volume: '75048600',
      'dividend amount': '0.0000',
    },
    '2008-10-10': {
      open: '101.2100',
      high: '103.0000',
      low: '83.5100',
      close: '87.7500',
      'adjusted close': '51.1208',
      volume: '86848900',
      'dividend amount': '0.0000',
    },
    '2008-10-03': {
      open: '117.4400',
      high: '118.4300',
      low: '103.0300',
      close: '103.4400',
      'adjusted close': '60.2614',
      volume: '62809100',
      'dividend amount': '0.0000',
    },
    '2008-09-26': {
      open: '118.4500',
      high: '121.9900',
      low: '114.1400',
      close: '119.4200',
      'adjusted close': '69.5709',
      volume: '34730600',
      'dividend amount': '0.0000',
    },
    '2008-09-19': {
      open: '115.3600',
      high: '124.0000',
      low: '110.6100',
      close: '118.8500',
      'adjusted close': '69.2389',
      volume: '66307100',
      'dividend amount': '0.0000',
    },
    '2008-09-12': {
      open: '118.0000',
      high: '119.9500',
      low: '115.0000',
      close: '118.9700',
      'adjusted close': '69.3088',
      volume: '46783800',
      'dividend amount': '0.0000',
    },
    '2008-09-05': {
      open: '122.8700',
      high: '124.0000',
      low: '113.1700',
      close: '114.3300',
      'adjusted close': '66.6056',
      volume: '42011600',
      'dividend amount': '0.0000',
    },
    '2008-08-29': {
      open: '124.4800',
      high: '125.4500',
      low: '121.5000',
      close: '121.7300',
      'adjusted close': '70.9167',
      volume: '30493600',
      'dividend amount': '0.0000',
    },
    '2008-08-22': {
      open: '126.4900',
      high: '127.0000',
      low: '121.5500',
      close: '124.9300',
      'adjusted close': '72.7809',
      volume: '34457400',
      'dividend amount': '0.0000',
    },
    '2008-08-15': {
      open: '128.4300',
      high: '128.4300',
      low: '124.4000',
      close: '126.3600',
      'adjusted close': '73.6140',
      volume: '32724200',
      'dividend amount': '0.0000',
    },
    '2008-08-08': {
      open: '126.7700',
      high: '129.9700',
      low: '126.2500',
      close: '128.8100',
      'adjusted close': '75.0413',
      volume: '34413900',
      'dividend amount': '0.5000',
    },
    '2008-08-01': {
      open: '127.6900',
      high: '129.5000',
      low: '126.1300',
      close: '126.6400',
      'adjusted close': '73.4926',
      volume: '28699300',
      'dividend amount': '0.0000',
    },
    '2008-07-25': {
      open: '129.8300',
      high: '130.9300',
      low: '127.2600',
      close: '128.5300',
      'adjusted close': '74.5894',
      volume: '45950300',
      'dividend amount': '0.0000',
    },
    '2008-07-18': {
      open: '121.8000',
      high: '130.0000',
      low: '119.9000',
      close: '129.8900',
      'adjusted close': '75.3787',
      volume: '59542700',
      'dividend amount': '0.0000',
    },
    '2008-07-11': {
      open: '119.6200',
      high: '124.5000',
      low: '119.6200',
      close: '122.1200',
      'adjusted close': '70.8695',
      volume: '47346100',
      'dividend amount': '0.0000',
    },
    '2008-07-03': {
      open: '119.9900',
      high: '120.7800',
      low: '116.6000',
      close: '119.5400',
      'adjusted close': '69.3723',
      volume: '32716800',
      'dividend amount': '0.0000',
    },
    '2008-06-27': {
      open: '123.0000',
      high: '125.8300',
      low: '118.2600',
      close: '120.0500',
      'adjusted close': '69.6682',
      volume: '41907700',
      'dividend amount': '0.0000',
    },
    '2008-06-20': {
      open: '125.4600',
      high: '127.1400',
      low: '122.3600',
      close: '122.7400',
      'adjusted close': '71.2293',
      volume: '35367900',
      'dividend amount': '0.0000',
    },
    '2008-06-13': {
      open: '125.3800',
      high: '126.7220',
      low: '122.8600',
      close: '126.1500',
      'adjusted close': '73.2082',
      volume: '31303600',
      'dividend amount': '0.0000',
    },
    '2008-06-06': {
      open: '128.6700',
      high: '129.3700',
      low: '124.7400',
      close: '124.9400',
      'adjusted close': '72.5060',
      volume: '35309300',
      'dividend amount': '0.0000',
    },
    '2008-05-30': {
      open: '124.0100',
      high: '129.9900',
      low: '124.0000',
      close: '129.4300',
      'adjusted close': '75.1117',
      volume: '33079200',
      'dividend amount': '0.0000',
    },
    '2008-05-23': {
      open: '127.6800',
      high: '128.2100',
      low: '123.0200',
      close: '124.2000',
      'adjusted close': '72.0766',
      volume: '31469600',
      'dividend amount': '0.0000',
    },
    '2008-05-16': {
      open: '123.8000',
      high: '128.8300',
      low: '123.5600',
      close: '127.8200',
      'adjusted close': '74.1774',
      volume: '40848500',
      'dividend amount': '0.0000',
    },
    '2008-05-09': {
      open: '122.7600',
      high: '125.1700',
      low: '120.7800',
      close: '124.0600',
      'adjusted close': '71.9954',
      volume: '38973400',
      'dividend amount': '0.5000',
    },
    '2008-05-02': {
      open: '122.9800',
      high: '124.4300',
      low: '120.5000',
      close: '123.1800',
      'adjusted close': '71.1979',
      volume: '36106300',
      'dividend amount': '0.0000',
    },
    '2008-04-25': {
      open: '122.0500',
      high: '124.9000',
      low: '122.0500',
      close: '123.0800',
      'adjusted close': '71.1401',
      volume: '32273700',
      'dividend amount': '0.0000',
    },
    '2008-04-18': {
      open: '116.2000',
      high: '125.0000',
      low: '115.5400',
      close: '124.4000',
      'adjusted close': '71.9031',
      volume: '67195200',
      'dividend amount': '0.0000',
    },
    '2008-04-11': {
      open: '116.3700',
      high: '119.2200',
      low: '115.2800',
      close: '116.0000',
      'adjusted close': '67.0479',
      volume: '38989700',
      'dividend amount': '0.0000',
    },
    '2008-04-04': {
      open: '113.9500',
      high: '118.3700',
      low: '113.3400',
      close: '115.7600',
      'adjusted close': '66.9092',
      volume: '42376300',
      'dividend amount': '0.0000',
    },
    '2008-03-28': {
      open: '118.5700',
      high: '119.7900',
      low: '114.2100',
      close: '114.5700',
      'adjusted close': '66.2213',
      volume: '41496800',
      'dividend amount': '0.0000',
    },
    '2008-03-20': {
      open: '113.0000',
      high: '118.5000',
      low: '113.0000',
      close: '118.3300',
      'adjusted close': '68.3946',
      volume: '39979100',
      'dividend amount': '0.0000',
    },
    '2008-03-14': {
      open: '113.1200',
      high: '118.0000',
      low: '112.6900',
      close: '115.2301',
      'adjusted close': '66.6029',
      volume: '48594600',
      'dividend amount': '0.0000',
    },
    '2008-03-07': {
      open: '114.0900',
      high: '116.4100',
      low: '111.8000',
      close: '113.9400',
      'adjusted close': '65.8572',
      volume: '39506500',
      'dividend amount': '0.0000',
    },
    '2008-02-29': {
      open: '107.6800',
      high: '116.6300',
      low: '107.4300',
      close: '113.8600',
      'adjusted close': '65.8110',
      volume: '54366700',
      'dividend amount': '0.0000',
    },
    '2008-02-22': {
      open: '106.7900',
      high: '109.6000',
      low: '104.5300',
      close: '108.0700',
      'adjusted close': '62.4643',
      volume: '31292600',
      'dividend amount': '0.0000',
    },
    '2008-02-15': {
      open: '102.9000',
      high: '108.9300',
      low: '102.8700',
      close: '106.1600',
      'adjusted close': '61.3604',
      volume: '33932900',
      'dividend amount': '0.0000',
    },
    '2008-02-08': {
      open: '108.4000',
      high: '109.0000',
      low: '100.6000',
      close: '103.2700',
      'adjusted close': '59.6899',
      volume: '40641500',
      'dividend amount': '0.4000',
    },
    '2008-02-01': {
      open: '104.4100',
      high: '109.4000',
      low: '103.7000',
      close: '109.0800',
      'adjusted close': '62.8056',
      volume: '39007500',
      'dividend amount': '0.0000',
    },
    '2008-01-25': {
      open: '99.1600',
      high: '107.7900',
      low: '98.5000',
      close: '104.5200',
      'adjusted close': '60.1801',
      volume: '57768700',
      'dividend amount': '0.0000',
    },
    '2008-01-18': {
      open: '105.0000',
      high: '106.7200',
      low: '100.0500',
      close: '103.4000',
      'adjusted close': '59.5352',
      volume: '78126300',
      'dividend amount': '0.0000',
    },
    '2008-01-11': {
      open: '100.3550',
      high: '101.0000',
      low: '97.0400',
      close: '97.6700',
      'adjusted close': '56.2360',
      volume: '52601500',
      'dividend amount': '0.0000',
    },
    '2008-01-04': {
      open: '109.2500',
      high: '110.0000',
      low: '100.4800',
      close: '101.1300',
      'adjusted close': '58.2282',
      volume: '33799300',
      'dividend amount': '0.0000',
    },
    '2007-12-28': {
      open: '111.2000',
      high: '112.1900',
      low: '109.1080',
      close: '110.0900',
      'adjusted close': '63.3871',
      volume: '16539900',
      'dividend amount': '0.0000',
    },
    '2007-12-21': {
      open: '105.3700',
      high: '111.1600',
      low: '104.0000',
      close: '111.0500',
      'adjusted close': '63.9399',
      volume: '43481700',
      'dividend amount': '0.0000',
    },
    '2007-12-14': {
      open: '109.0000',
      high: '110.4000',
      low: '104.1000',
      close: '105.7700',
      'adjusted close': '60.8998',
      volume: '36725700',
      'dividend amount': '0.0000',
    },
    '2007-12-07': {
      open: '105.4500',
      high: '110.0000',
      low: '104.5800',
      close: '108.8600',
      'adjusted close': '62.6789',
      volume: '31672000',
      'dividend amount': '0.0000',
    },
    '2007-11-30': {
      open: '104.4000',
      high: '109.1900',
      low: '101.5000',
      close: '105.1800',
      'adjusted close': '60.5601',
      volume: '46569900',
      'dividend amount': '0.0000',
    },
    '2007-11-23': {
      open: '104.3400',
      high: '105.3900',
      low: '101.3400',
      close: '104.0500',
      'adjusted close': '59.9095',
      volume: '27820500',
      'dividend amount': '0.0000',
    },
    '2007-11-16': {
      open: '101.8900',
      high: '106.4200',
      low: '100.7000',
      close: '104.7900',
      'adjusted close': '60.3355',
      volume: '50102700',
      'dividend amount': '0.0000',
    },
    '2007-11-09': {
      open: '115.1100',
      high: '115.1100',
      low: '99.2700',
      close: '100.2542',
      'adjusted close': '57.7239',
      volume: '62718300',
      'dividend amount': '0.4000',
    },
    '2007-11-02': {
      open: '113.9000',
      high: '116.2500',
      low: '113.2800',
      close: '114.5900',
      'adjusted close': '65.7414',
      volume: '30044700',
      'dividend amount': '0.0000',
    },
    '2007-10-26': {
      open: '110.9700',
      high: '114.8000',
      low: '110.9600',
      close: '113.7300',
      'adjusted close': '65.2480',
      volume: '32762600',
      'dividend amount': '0.0000',
    },
    '2007-10-19': {
      open: '117.9500',
      high: '119.9400',
      low: '111.8000',
      close: '112.2800',
      'adjusted close': '64.4161',
      volume: '53958800',
      'dividend amount': '0.0000',
    },
    '2007-10-12': {
      open: '116.1000',
      high: '121.4600',
      low: '115.8800',
      close: '117.8100',
      'adjusted close': '67.5887',
      volume: '35082300',
      'dividend amount': '0.0000',
    },
    '2007-10-05': {
      open: '117.6100',
      high: '119.6000',
      low: '115.0000',
      close: '116.3000',
      'adjusted close': '66.7224',
      volume: '33529800',
      'dividend amount': '0.0000',
    },
    '2007-09-28': {
      open: '116.9000',
      high: '118.5000',
      low: '115.7900',
      close: '117.8000',
      'adjusted close': '67.5830',
      volume: '33029400',
      'dividend amount': '0.0000',
    },
    '2007-09-21': {
      open: '114.7000',
      high: '118.1000',
      low: '114.3000',
      close: '116.7800',
      'adjusted close': '66.9978',
      volume: '38076800',
      'dividend amount': '0.0000',
    },
    '2007-09-14': {
      open: '116.1500',
      high: '117.7800',
      low: '114.6500',
      close: '115.1300',
      'adjusted close': '66.0512',
      volume: '32211300',
      'dividend amount': '0.0000',
    },
    '2007-09-07': {
      open: '116.3400',
      high: '118.8900',
      low: '115.3300',
      close: '115.5500',
      'adjusted close': '66.2922',
      volume: '28916300',
      'dividend amount': '0.0000',
    },
    '2007-08-31': {
      open: '113.0000',
      high: '117.3500',
      low: '111.6300',
      close: '116.6900',
      'adjusted close': '66.9462',
      volume: '32617900',
      'dividend amount': '0.0000',
    },
    '2007-08-24': {
      open: '110.9000',
      high: '113.2500',
      low: '108.0800',
      close: '113.2400',
      'adjusted close': '64.9669',
      volume: '39957700',
      'dividend amount': '0.0000',
    },
    '2007-08-17': {
      open: '112.9900',
      high: '113.9500',
      low: '103.7000',
      close: '110.9000',
      'adjusted close': '63.6244',
      volume: '55388900',
      'dividend amount': '0.0000',
    },
    '2007-08-10': {
      open: '111.9700',
      high: '114.6000',
      low: '109.7000',
      close: '112.6400',
      'adjusted close': '64.6227',
      volume: '41626500',
      'dividend amount': '0.4000',
    },
    '2007-08-03': {
      open: '115.6200',
      high: '115.7500',
      low: '110.0200',
      close: '111.8900',
      'adjusted close': '63.9659',
      volume: '66192100',
      'dividend amount': '0.0000',
    },
    '2007-07-27': {
      open: '114.8800',
      high: '118.8200',
      low: '114.8500',
      close: '115.6200',
      'adjusted close': '66.0983',
      volume: '64841000',
      'dividend amount': '0.0000',
    },
    '2007-07-20': {
      open: '108.5700',
      high: '116.4800',
      low: '108.4200',
      close: '114.8100',
      'adjusted close': '65.6352',
      volume: '55626100',
      'dividend amount': '0.0000',
    },
    '2007-07-13': {
      open: '108.7300',
      high: '109.6500',
      low: '108.1000',
      close: '108.6000',
      'adjusted close': '62.0851',
      volume: '34811600',
      'dividend amount': '0.0000',
    },
    '2007-07-06': {
      open: '105.3900',
      high: '109.6600',
      low: '104.5800',
      close: '109.0300',
      'adjusted close': '62.3309',
      volume: '24567300',
      'dividend amount': '0.0000',
    },
    '2007-06-29': {
      open: '104.7300',
      high: '106.9200',
      low: '104.1000',
      close: '105.2500',
      'adjusted close': '60.1699',
      volume: '31559100',
      'dividend amount': '0.0000',
    },
    '2007-06-22': {
      open: '105.2700',
      high: '107.0200',
      low: '104.4400',
      close: '104.4400',
      'adjusted close': '59.7069',
      volume: '35051000',
      'dividend amount': '0.0000',
    },
    '2007-06-15': {
      open: '102.8700',
      high: '105.1900',
      low: '102.1000',
      close: '105.0900',
      'adjusted close': '60.0784',
      volume: '34517000',
      'dividend amount': '0.0000',
    },
    '2007-06-08': {
      open: '106.5000',
      high: '106.5900',
      low: '101.5600',
      close: '103.0700',
      'adjusted close': '58.9236',
      volume: '63185700',
      'dividend amount': '0.0000',
    },
    '2007-06-01': {
      open: '105.5700',
      high: '107.6700',
      low: '105.2100',
      close: '106.5400',
      'adjusted close': '60.9074',
      volume: '23208200',
      'dividend amount': '0.0000',
    },
    '2007-05-25': {
      open: '107.1500',
      high: '108.0000',
      low: '103.5700',
      close: '105.1800',
      'adjusted close': '60.1299',
      volume: '30271700',
      'dividend amount': '0.0000',
    },
    '2007-05-18': {
      open: '105.8100',
      high: '108.0500',
      low: '104.5300',
      close: '107.9900',
      'adjusted close': '61.7363',
      volume: '35014200',
      'dividend amount': '0.0000',
    },
    '2007-05-11': {
      open: '102.7600',
      high: '105.9900',
      low: '102.2100',
      close: '105.9800',
      'adjusted close': '60.5872',
      volume: '34979100',
      'dividend amount': '0.4000',
    },
    '2007-05-04': {
      open: '101.1100',
      high: '103.1700',
      low: '100.9700',
      close: '102.9600',
      'adjusted close': '58.6337',
      volume: '38307800',
      'dividend amount': '0.0000',
    },
    '2007-04-27': {
      open: '95.3500',
      high: '101.7000',
      low: '95.0500',
      close: '101.1700',
      'adjusted close': '57.6143',
      volume: '58275300',
      'dividend amount': '0.0000',
    },
    '2007-04-20': {
      open: '94.9900',
      high: '97.6600',
      low: '93.9110',
      close: '94.5800',
      'adjusted close': '53.8614',
      volume: '51786700',
      'dividend amount': '0.0000',
    },
    '2007-04-13': {
      open: '96.4300',
      high: '96.9400',
      low: '94.5300',
      close: '94.9300',
      'adjusted close': '54.0608',
      volume: '27497000',
      'dividend amount': '0.0000',
    },
    '2007-04-05': {
      open: '94.5100',
      high: '96.7500',
      low: '94.0600',
      close: '96.5200',
      'adjusted close': '54.9662',
      volume: '19197800',
      'dividend amount': '0.0000',
    },
    '2007-03-30': {
      open: '94.8400',
      high: '95.2500',
      low: '93.5700',
      close: '94.2600',
      'adjusted close': '53.6792',
      volume: '34042800',
      'dividend amount': '0.0000',
    },
    '2007-03-23': {
      open: '93.9500',
      high: '95.8100',
      low: '93.3900',
      close: '95.0300',
      'adjusted close': '54.1177',
      volume: '26906600',
      'dividend amount': '0.0000',
    },
    '2007-03-16': {
      open: '93.2900',
      high: '94.4700',
      low: '92.1000',
      close: '93.2500',
      'adjusted close': '53.1040',
      volume: '36423000',
      'dividend amount': '0.0000',
    },
    '2007-03-09': {
      open: '90.2500',
      high: '94.8500',
      low: '90.1000',
      close: '93.2800',
      'adjusted close': '53.1211',
      volume: '43505100',
      'dividend amount': '0.0000',
    },
    '2007-03-02': {
      open: '97.8600',
      high: '97.8600',
      low: '88.7700',
      close: '90.9000',
      'adjusted close': '51.7658',
      volume: '52427400',
      'dividend amount': '0.0000',
    },
    '2007-02-23': {
      open: '98.6600',
      high: '99.5000',
      low: '97.4100',
      close: '97.7300',
      'adjusted close': '55.6553',
      volume: '20682500',
      'dividend amount': '0.0000',
    },
    '2007-02-16': {
      open: '98.8000',
      high: '99.5200',
      low: '97.8000',
      close: '98.9900',
      'adjusted close': '56.3729',
      volume: '27047900',
      'dividend amount': '0.0000',
    },
    '2007-02-09': {
      open: '99.1700',
      high: '100.4400',
      low: '97.8100',
      close: '98.5500',
      'adjusted close': '56.1223',
      volume: '34668400',
      'dividend amount': '0.3000',
    },
    '2007-02-02': {
      open: '98.1000',
      high: '99.7300',
      low: '97.4500',
      close: '99.1700',
      'adjusted close': '56.3057',
      volume: '34172300',
      'dividend amount': '0.0000',
    },
    '2007-01-26': {
      open: '96.4200',
      high: '97.9200',
      low: '96.1200',
      close: '97.4500',
      'adjusted close': '55.3291',
      volume: '41549100',
      'dividend amount': '0.0000',
    },
    '2007-01-19': {
      open: '99.4000',
      high: '100.9000',
      low: '94.5500',
      close: '96.1700',
      'adjusted close': '54.6024',
      volume: '58474800',
      'dividend amount': '0.0000',
    },
    '2007-01-12': {
      open: '98.5000',
      high: '100.3300',
      low: '97.9300',
      close: '99.3400',
      'adjusted close': '56.4022',
      volume: '44830200',
      'dividend amount': '0.0000',
    },
    '2007-01-05': {
      open: '97.1700',
      high: '98.7900',
      low: '96.2600',
      close: '97.4200',
      'adjusted close': '55.3121',
      volume: '26942600',
      'dividend amount': '0.0000',
    },
    '2006-12-29': {
      open: '95.0000',
      high: '97.8800',
      low: '94.9200',
      close: '97.1500',
      'adjusted close': '55.1588',
      volume: '15807900',
      'dividend amount': '0.0000',
    },
    '2006-12-22': {
      open: '94.9500',
      high: '96.5000',
      low: '94.8000',
      close: '95.2500',
      'adjusted close': '54.0800',
      volume: '24009800',
      'dividend amount': '0.0000',
    },
    '2006-12-15': {
      open: '93.7200',
      high: '95.8000',
      low: '93.3000',
      close: '95.3000',
      'adjusted close': '54.1084',
      volume: '30414900',
      'dividend amount': '0.0000',
    },
    '2006-12-08': {
      open: '92.5000',
      high: '94.8700',
      low: '92.2500',
      close: '93.8600',
      'adjusted close': '53.2908',
      volume: '28792200',
      'dividend amount': '0.0000',
    },
    '2006-12-01': {
      open: '92.7100',
      high: '93.2400',
      low: '90.4300',
      close: '91.2500',
      'adjusted close': '51.8089',
      volume: '27785200',
      'dividend amount': '0.0000',
    },
    '2006-11-24': {
      open: '93.7700',
      high: '93.8000',
      low: '92.8000',
      close: '93.3500',
      'adjusted close': '53.0012',
      volume: '13559900',
      'dividend amount': '0.0000',
    },
    '2006-11-17': {
      open: '91.7500',
      high: '94.0500',
      low: '91.4100',
      close: '93.8100',
      'adjusted close': '53.2624',
      volume: '23826600',
      'dividend amount': '0.0000',
    },
    '2006-11-10': {
      open: '91.5000',
      high: '93.4000',
      low: '91.5000',
      close: '91.7600',
      'adjusted close': '52.0985',
      volume: '29456500',
      'dividend amount': '0.3000',
    },
    '2006-11-03': {
      open: '90.3000',
      high: '92.6800',
      low: '89.8000',
      close: '91.4100',
      'adjusted close': '51.7322',
      volume: '30108500',
      'dividend amount': '0.0000',
    },
    '2006-10-27': {
      open: '90.0500',
      high: '92.0000',
      low: '90.0100',
      close: '90.7600',
      'adjusted close': '51.3643',
      volume: '35037300',
      'dividend amount': '0.0000',
    },
    '2006-10-20': {
      open: '86.0800',
      high: '92.0400',
      low: '85.7500',
      close: '90.4800',
      'adjusted close': '51.2058',
      volume: '67866100',
      'dividend amount': '0.0000',
    },
    '2006-10-13': {
      open: '83.1400',
      high: '86.2100',
      low: '83.0000',
      close: '86.0800',
      'adjusted close': '48.7157',
      volume: '26864000',
      'dividend amount': '0.0000',
    },
    '2006-10-06': {
      open: '81.7600',
      high: '83.7800',
      low: '81.5600',
      close: '83.1400',
      'adjusted close': '47.0519',
      volume: '25738600',
      'dividend amount': '0.0000',
    },
    '2006-09-29': {
      open: '81.6400',
      high: '82.7900',
      low: '81.2800',
      close: '81.9400',
      'adjusted close': '46.3728',
      volume: '25658300',
      'dividend amount': '0.0000',
    },
    '2006-09-22': {
      open: '82.5600',
      high: '83.7900',
      low: '81.0100',
      close: '81.2100',
      'adjusted close': '45.9596',
      volume: '30290000',
      'dividend amount': '0.0000',
    },
    '2006-09-15': {
      open: '80.6000',
      high: '83.5900',
      low: '80.2500',
      close: '82.9400',
      'adjusted close': '46.9387',
      volume: '27425800',
      'dividend amount': '0.0000',
    },
    '2006-09-08': {
      open: '81.1900',
      high: '81.5400',
      low: '79.3100',
      close: '80.6600',
      'adjusted close': '45.6484',
      volume: '16108000',
      'dividend amount': '0.0000',
    },
    '2006-09-01': {
      open: '79.9100',
      high: '81.6800',
      low: '79.7600',
      close: '81.4100',
      'adjusted close': '46.0728',
      volume: '22236900',
      'dividend amount': '0.0000',
    },
    '2006-08-25': {
      open: '79.5100',
      high: '80.0000',
      low: '78.4200',
      close: '79.8800',
      'adjusted close': '45.2069',
      volume: '18060100',
      'dividend amount': '0.0000',
    },
    '2006-08-18': {
      open: '75.8500',
      high: '79.9700',
      low: '75.6000',
      close: '79.9000',
      'adjusted close': '45.2182',
      volume: '29303900',
      'dividend amount': '0.0000',
    },
    '2006-08-11': {
      open: '75.6300',
      high: '76.2800',
      low: '75.0500',
      close: '75.4800',
      'adjusted close': '42.7168',
      volume: '19054700',
      'dividend amount': '0.3000',
    },
    '2006-08-04': {
      open: '76.4400',
      high: '77.5000',
      low: '74.9000',
      close: '75.9100',
      'adjusted close': '42.7898',
      volume: '20900500',
      'dividend amount': '0.0000',
    },
    '2006-07-28': {
      open: '74.9200',
      high: '77.3000',
      low: '74.9200',
      close: '76.9600',
      'adjusted close': '43.3816',
      volume: '25666400',
      'dividend amount': '0.0000',
    },
    '2006-07-21': {
      open: '73.3400',
      high: '77.0600',
      low: '72.7300',
      close: '74.8600',
      'adjusted close': '42.1979',
      volume: '50614500',
      'dividend amount': '0.0000',
    },
    '2006-07-14': {
      open: '76.1800',
      high: '76.8300',
      low: '73.3700',
      close: '73.5700',
      'adjusted close': '41.4707',
      volume: '37325800',
      'dividend amount': '0.0000',
    },
    '2006-07-07': {
      open: '77.5400',
      high: '78.5300',
      low: '76.1200',
      close: '76.4200',
      'adjusted close': '43.0772',
      volume: '18199800',
      'dividend amount': '0.0000',
    },
    '2006-06-30': {
      open: '77.0400',
      high: '77.9300',
      low: '76.0600',
      close: '76.8200',
      'adjusted close': '43.3027',
      volume: '26797500',
      'dividend amount': '0.0000',
    },
    '2006-06-23': {
      open: '78.0600',
      high: '78.5600',
      low: '76.9400',
      close: '77.1000',
      'adjusted close': '43.4605',
      volume: '27070000',
      'dividend amount': '0.0000',
    },
    '2006-06-16': {
      open: '77.6800',
      high: '78.7800',
      low: '76.8200',
      close: '77.9500',
      'adjusted close': '43.9397',
      volume: '33150500',
      'dividend amount': '0.0000',
    },
    '2006-06-09': {
      open: '79.1000',
      high: '80.7400',
      low: '76.7500',
      close: '78.0100',
      'adjusted close': '43.9735',
      volume: '40341000',
      'dividend amount': '0.0000',
    },
    '2006-06-02': {
      open: '80.3500',
      high: '80.9600',
      low: '79.1500',
      close: '79.5200',
      'adjusted close': '44.8247',
      volume: '23532800',
      'dividend amount': '0.0000',
    },
    '2006-05-26': {
      open: '79.9400',
      high: '81.3000',
      low: '79.0000',
      close: '80.7500',
      'adjusted close': '45.5180',
      volume: '26222700',
      'dividend amount': '0.0000',
    },
    '2006-05-19': {
      open: '81.8000',
      high: '83.2500',
      low: '79.5100',
      close: '80.2800',
      'adjusted close': '45.2531',
      volume: '28629900',
      'dividend amount': '0.0000',
    },
    '2006-05-12': {
      open: '82.6000',
      high: '83.6900',
      low: '82.3000',
      close: '82.3900',
      'adjusted close': '46.4425',
      volume: '22319900',
      'dividend amount': '0.3000',
    },
    '2006-05-05': {
      open: '82.5900',
      high: '83.5500',
      low: '82.0000',
      close: '82.9800',
      'adjusted close': '46.6064',
      volume: '24540000',
      'dividend amount': '0.0000',
    },
    '2006-04-28': {
      open: '81.3000',
      high: '84.4000',
      low: '81.1000',
      close: '82.3400',
      'adjusted close': '46.2469',
      volume: '36485300',
      'dividend amount': '0.0000',
    },
    '2006-04-21': {
      open: '81.7700',
      high: '84.0000',
      low: '81.2600',
      close: '81.6600',
      'adjusted close': '45.8650',
      volume: '35449300',
      'dividend amount': '0.0000',
    },
    '2006-04-13': {
      open: '82.4900',
      high: '82.7400',
      low: '80.6300',
      close: '81.9800',
      'adjusted close': '46.0447',
      volume: '18292300',
      'dividend amount': '0.0000',
    },
    '2006-04-07': {
      open: '82.7200',
      high: '84.4500',
      low: '82.4400',
      close: '82.4800',
      'adjusted close': '46.3255',
      volume: '23780100',
      'dividend amount': '0.0000',
    },
    '2006-03-31': {
      open: '82.9800',
      high: '83.7000',
      low: '82.2000',
      close: '82.4700',
      'adjusted close': '46.3199',
      volume: '23139800',
      'dividend amount': '0.0000',
    },
    '2006-03-24': {
      open: '83.1100',
      high: '84.9900',
      low: '82.9300',
      close: '83.3600',
      'adjusted close': '46.8198',
      volume: '25547800',
      'dividend amount': '0.0000',
    },
    '2006-03-17': {
      open: '81.5600',
      high: '83.7600',
      low: '81.4500',
      close: '83.3000',
      'adjusted close': '46.7861',
      volume: '25173700',
      'dividend amount': '0.0000',
    },
    '2006-03-10': {
      open: '80.0500',
      high: '82.2500',
      low: '79.8600',
      close: '81.5700',
      'adjusted close': '45.8144',
      volume: '23708100',
      'dividend amount': '0.0000',
    },
    '2006-03-03': {
      open: '80.0000',
      high: '80.8900',
      low: '79.5100',
      close: '79.9600',
      'adjusted close': '44.9102',
      volume: '23693200',
      'dividend amount': '0.0000',
    },
    '2006-02-24': {
      open: '80.1000',
      high: '81.6500',
      low: '79.8500',
      close: '80.1000',
      'adjusted close': '44.9888',
      volume: '20014900',
      'dividend amount': '0.0000',
    },
    '2006-02-17': {
      open: '81.3300',
      high: '81.9100',
      low: '80.1300',
      close: '80.7100',
      'adjusted close': '45.3314',
      volume: '26994200',
      'dividend amount': '0.0000',
    },
    '2006-02-10': {
      open: '79.9400',
      high: '81.4600',
      low: '78.9300',
      close: '81.3300',
      'adjusted close': '45.6796',
      volume: '32826100',
      'dividend amount': '0.2000',
    },
    '2006-02-03': {
      open: '81.0200',
      high: '82.2400',
      low: '79.7200',
      close: '79.9700',
      'adjusted close': '44.8049',
      volume: '33566000',
      'dividend amount': '0.0000',
    },
    '2006-01-27': {
      open: '81.3300',
      high: '82.1500',
      low: '80.5900',
      close: '81.0200',
      'adjusted close': '45.3932',
      volume: '32471000',
      'dividend amount': '0.0000',
    },
    '2006-01-20': {
      open: '82.8000',
      high: '84.7000',
      low: '81.2500',
      close: '81.3600',
      'adjusted close': '45.5836',
      volume: '34792500',
      'dividend amount': '0.0000',
    },
    '2006-01-13': {
      open: '83.9000',
      high: '84.8100',
      low: '82.5000',
      close: '83.1700',
      'adjusted close': '46.5977',
      volume: '30173200',
      'dividend amount': '0.0000',
    },
    '2006-01-06': {
      open: '82.4500',
      high: '85.0300',
      low: '80.8100',
      close: '84.9500',
      'adjusted close': '47.5950',
      volume: '36958200',
      'dividend amount': '0.0000',
    },
    '2005-12-30': {
      open: '83.4800',
      high: '84.5000',
      low: '81.5600',
      close: '82.2000',
      'adjusted close': '46.0543',
      volume: '17622000',
      'dividend amount': '0.0000',
    },
    '2005-12-23': {
      open: '83.2300',
      high: '84.2000',
      low: '82.0600',
      close: '83.4800',
      'adjusted close': '46.7714',
      volume: '27298200',
      'dividend amount': '0.0000',
    },
    '2005-12-16': {
      open: '87.0100',
      high: '87.3500',
      low: '82.7500',
      close: '83.3700',
      'adjusted close': '46.7098',
      volume: '39305300',
      'dividend amount': '0.0000',
    },
    '2005-12-09': {
      open: '88.4000',
      high: '89.9200',
      low: '86.3300',
      close: '86.9700',
      'adjusted close': '48.7268',
      volume: '26427900',
      'dividend amount': '0.0000',
    },
    '2005-12-02': {
      open: '88.8000',
      high: '89.9400',
      low: '88.3600',
      close: '88.6500',
      'adjusted close': '49.6680',
      volume: '25765400',
      'dividend amount': '0.0000',
    },
    '2005-11-25': {
      open: '87.6000',
      high: '89.3900',
      low: '86.8800',
      close: '88.8000',
      'adjusted close': '49.7521',
      volume: '18193100',
      'dividend amount': '0.0000',
    },
    '2005-11-18': {
      open: '84.2500',
      high: '88.0000',
      low: '84.0300',
      close: '87.7700',
      'adjusted close': '49.1750',
      volume: '30719600',
      'dividend amount': '0.0000',
    },
    '2005-11-11': {
      open: '83.1000',
      high: '84.8400',
      low: '82.3000',
      close: '84.5500',
      'adjusted close': '47.3709',
      volume: '21762600',
      'dividend amount': '0.2000',
    },
    '2005-11-04': {
      open: '81.5100',
      high: '83.3000',
      low: '80.6400',
      close: '83.0000',
      'adjusted close': '46.3909',
      volume: '31154000',
      'dividend amount': '0.0000',
    },
    '2005-10-28': {
      open: '83.1500',
      high: '83.9500',
      low: '80.5000',
      close: '81.4200',
      'adjusted close': '45.5078',
      volume: '25793400',
      'dividend amount': '0.0000',
    },
    '2005-10-21': {
      open: '82.3600',
      high: '84.6000',
      low: '81.9300',
      close: '83.3300',
      'adjusted close': '46.5754',
      volume: '39286100',
      'dividend amount': '0.0000',
    },
    '2005-10-14': {
      open: '81.2400',
      high: '83.5200',
      low: '81.0800',
      close: '82.3500',
      'adjusted close': '46.0276',
      volume: '36349300',
      'dividend amount': '0.0000',
    },
    '2005-10-07': {
      open: '80.2200',
      high: '81.4700',
      low: '78.7000',
      close: '80.5000',
      'adjusted close': '44.9936',
      volume: '30921800',
      'dividend amount': '0.0000',
    },
    '2005-09-30': {
      open: '78.3100',
      high: '80.5500',
      low: '76.9300',
      close: '80.2200',
      'adjusted close': '44.8371',
      volume: '30116700',
      'dividend amount': '0.0000',
    },
    '2005-09-23': {
      open: '79.7400',
      high: '79.9800',
      low: '77.3000',
      close: '78.0000',
      'adjusted close': '43.5963',
      volume: '31701800',
      'dividend amount': '0.0000',
    },
    '2005-09-16': {
      open: '81.0300',
      high: '82.1100',
      low: '79.6700',
      close: '80.3300',
      'adjusted close': '44.8986',
      volume: '25110400',
      'dividend amount': '0.0000',
    },
    '2005-09-09': {
      open: '79.7000',
      high: '81.5200',
      low: '79.7000',
      close: '81.4400',
      'adjusted close': '45.5190',
      volume: '18681300',
      'dividend amount': '0.0000',
    },
    '2005-09-02': {
      open: '80.0000',
      high: '81.7500',
      low: '79.3400',
      close: '79.4600',
      'adjusted close': '44.4123',
      volume: '25171500',
      'dividend amount': '0.0000',
    },
    '2005-08-26': {
      open: '82.7500',
      high: '83.3500',
      low: '80.2600',
      close: '80.3800',
      'adjusted close': '44.9265',
      volume: '22210500',
      'dividend amount': '0.0000',
    },
    '2005-08-19': {
      open: '81.9000',
      high: '83.3000',
      low: '80.8000',
      close: '82.7600',
      'adjusted close': '46.2568',
      volume: '22885800',
      'dividend amount': '0.0000',
    },
    '2005-08-12': {
      open: '83.4100',
      high: '84.0100',
      low: '81.6200',
      close: '82.1900',
      'adjusted close': '45.9382',
      volume: '22568800',
      'dividend amount': '0.2000',
    },
    '2005-08-05': {
      open: '83.0000',
      high: '84.2000',
      low: '82.7300',
      close: '83.3600',
      'adjusted close': '46.4806',
      volume: '22265700',
      'dividend amount': '0.0000',
    },
    '2005-07-29': {
      open: '83.9800',
      high: '84.5200',
      low: '82.8100',
      close: '83.4600',
      'adjusted close': '46.5364',
      volume: '24382900',
      'dividend amount': '0.0000',
    },
    '2005-07-22': {
      open: '81.9900',
      high: '85.1100',
      low: '81.6800',
      close: '84.4400',
      'adjusted close': '47.0828',
      volume: '45014200',
      'dividend amount': '0.0000',
    },
    '2005-07-15': {
      open: '79.4000',
      high: '82.7500',
      low: '78.5200',
      close: '82.3800',
      'adjusted close': '45.9342',
      volume: '45662500',
      'dividend amount': '0.0000',
    },
    '2005-07-08': {
      open: '74.3800',
      high: '79.5200',
      low: '74.1600',
      close: '79.3000',
      'adjusted close': '44.2168',
      volume: '37388800',
      'dividend amount': '0.0000',
    },
    '2005-07-01': {
      open: '74.0100',
      high: '75.6800',
      low: '73.4500',
      close: '74.6700',
      'adjusted close': '41.6352',
      volume: '30677800',
      'dividend amount': '0.0000',
    },
    '2005-06-24': {
      open: '76.0300',
      high: '77.4900',
      low: '74.0000',
      close: '74.0100',
      'adjusted close': '41.2671',
      volume: '33289800',
      'dividend amount': '0.0000',
    },
    '2005-06-17': {
      open: '74.5000',
      high: '77.7300',
      low: '74.4500',
      close: '76.3900',
      'adjusted close': '42.5942',
      volume: '33568000',
      'dividend amount': '0.0000',
    },
    '2005-06-10': {
      open: '75.8000',
      high: '76.0900',
      low: '74.1000',
      close: '74.7700',
      'adjusted close': '41.6909',
      volume: '24804200',
      'dividend amount': '0.0000',
    },
    '2005-06-03': {
      open: '76.6000',
      high: '77.5000',
      low: '75.5000',
      close: '75.7900',
      'adjusted close': '42.2597',
      volume: '23975100',
      'dividend amount': '0.0000',
    },
    '2005-05-27': {
      open: '76.3000',
      high: '77.4100',
      low: '75.1700',
      close: '77.1000',
      'adjusted close': '42.9901',
      volume: '24899800',
      'dividend amount': '0.0000',
    },
    '2005-05-20': {
      open: '73.0900',
      high: '77.6400',
      low: '73.0900',
      close: '76.4100',
      'adjusted close': '42.6054',
      volume: '34306900',
      'dividend amount': '0.0000',
    },
    '2005-05-13': {
      open: '75.2600',
      high: '75.4600',
      low: '72.5000',
      close: '73.1600',
      'adjusted close': '40.7932',
      volume: '33727100',
      'dividend amount': '0.0000',
    },
    '2005-05-06': {
      open: '76.8800',
      high: '78.1100',
      low: '74.9700',
      close: '75.2600',
      'adjusted close': '41.9641',
      volume: '39063200',
      'dividend amount': '0.2000',
    },
    '2005-04-29': {
      open: '75.2400',
      high: '77.1800',
      low: '74.0500',
      close: '76.3800',
      'adjusted close': '42.4758',
      volume: '51112000',
      'dividend amount': '0.0000',
    },
    '2005-04-22': {
      open: '77.1500',
      high: '77.7500',
      low: '71.8500',
      close: '74.2100',
      'adjusted close': '41.2690',
      volume: '70090000',
      'dividend amount': '0.0000',
    },
    '2005-04-15': {
      open: '87.3500',
      high: '87.5600',
      low: '76.3300',
      close: '76.7000',
      'adjusted close': '42.6537',
      volume: '62144400',
      'dividend amount': '0.0000',
    },
    '2005-04-08': {
      open: '90.0800',
      high: '90.6200',
      low: '87.5000',
      close: '87.6000',
      'adjusted close': '48.7153',
      volume: '27677900',
      'dividend amount': '0.0000',
    },
    '2005-04-01': {
      open: '90.7100',
      high: '91.7600',
      low: '90.0400',
      close: '90.4400',
      'adjusted close': '50.2947',
      volume: '25553000',
      'dividend amount': '0.0000',
    },
    '2005-03-24': {
      open: '89.4200',
      high: '91.5500',
      low: '89.2600',
      close: '90.7000',
      'adjusted close': '50.4393',
      volume: '18904100',
      'dividend amount': '0.0000',
    },
    '2005-03-18': {
      open: '91.5000',
      high: '92.2700',
      low: '89.0900',
      close: '89.2800',
      'adjusted close': '49.6496',
      volume: '25767500',
      'dividend amount': '0.0000',
    },
    '2005-03-11': {
      open: '92.3500',
      high: '93.0000',
      low: '91.2000',
      close: '91.5100',
      'adjusted close': '50.8897',
      volume: '24562700',
      'dividend amount': '0.0000',
    },
    '2005-03-04': {
      open: '92.5300',
      high: '93.7300',
      low: '92.2000',
      close: '92.3700',
      'adjusted close': '51.3680',
      volume: '22028900',
      'dividend amount': '0.0000',
    },
    '2005-02-25': {
      open: '92.6700',
      high: '93.5000',
      low: '91.5500',
      close: '92.8000',
      'adjusted close': '51.6071',
      volume: '18680300',
      'dividend amount': '0.0000',
    },
    '2005-02-18': {
      open: '93.1600',
      high: '94.9700',
      low: '92.5500',
      close: '93.2700',
      'adjusted close': '51.8685',
      volume: '20002800',
      'dividend amount': '0.0000',
    },
    '2005-02-11': {
      open: '94.3500',
      high: '94.9000',
      low: '92.3000',
      close: '93.3000',
      'adjusted close': '51.8852',
      volume: '21103700',
      'dividend amount': '0.1800',
    },
    '2005-02-04': {
      open: '93.6500',
      high: '94.7400',
      low: '93.0000',
      close: '94.5100',
      'adjusted close': '52.4577',
      volume: '19731000',
      'dividend amount': '0.0000',
    },
    '2005-01-28': {
      open: '92.7000',
      high: '93.5900',
      low: '91.4400',
      close: '92.8900',
      'adjusted close': '51.5586',
      volume: '27667500',
      'dividend amount': '0.0000',
    },
    '2005-01-21': {
      open: '93.6500',
      high: '95.3400',
      low: '92.2300',
      close: '92.3800',
      'adjusted close': '51.2755',
      volume: '28556000',
      'dividend amount': '0.0000',
    },
    '2005-01-14': {
      open: '95.7800',
      high: '96.2000',
      low: '93.5500',
      close: '94.1000',
      'adjusted close': '52.2302',
      volume: '26060300',
      'dividend amount': '0.0000',
    },
    '2005-01-07': {
      open: '98.9700',
      high: '99.1000',
      low: '95.4700',
      close: '95.7800',
      'adjusted close': '53.1626',
      volume: '27415300',
      'dividend amount': '0.0000',
    },
    '2004-12-31': {
      open: '97.6900',
      high: '99.0000',
      low: '97.3700',
      close: '98.5800',
      'adjusted close': '54.7168',
      volume: '17501200',
      'dividend amount': '0.0000',
    },
    '2004-12-23': {
      open: '96.3500',
      high: '98.0000',
      low: '96.3500',
      close: '97.7200',
      'adjusted close': '54.2394',
      volume: '18152400',
      'dividend amount': '0.0000',
    },
    '2004-12-17': {
      open: '96.8500',
      high: '98.1500',
      low: '96.0800',
      close: '96.2000',
      'adjusted close': '53.3958',
      volume: '27720400',
      'dividend amount': '0.0000',
    },
    '2004-12-10': {
      open: '96.8800',
      high: '98.2500',
      low: '95.7100',
      close: '96.6700',
      'adjusted close': '53.6566',
      volume: '26952800',
      'dividend amount': '0.0000',
    },
    '2004-12-03': {
      open: '94.9400',
      high: '97.6300',
      low: '94.2400',
      close: '97.0800',
      'adjusted close': '53.8842',
      volume: '29413700',
      'dividend amount': '0.0000',
    },
    '2004-11-26': {
      open: '94.3000',
      high: '95.7900',
      low: '94.1600',
      close: '94.7200',
      'adjusted close': '52.5743',
      volume: '17298900',
      'dividend amount': '0.0000',
    },
    '2004-11-19': {
      open: '95.0800',
      high: '96.6300',
      low: '94.2500',
      close: '94.4500',
      'adjusted close': '52.4244',
      volume: '27259900',
      'dividend amount': '0.0000',
    },
    '2004-11-12': {
      open: '92.5000',
      high: '95.5000',
      low: '92.5000',
      close: '95.3200',
      'adjusted close': '52.9073',
      volume: '28090600',
      'dividend amount': '0.1800',
    },
    '2004-11-05': {
      open: '89.3300',
      high: '93.5200',
      low: '89.2300',
      close: '93.2800',
      'adjusted close': '51.6754',
      volume: '30762600',
      'dividend amount': '0.0000',
    },
    '2004-10-29': {
      open: '87.3600',
      high: '90.2700',
      low: '87.3100',
      close: '89.7500',
      'adjusted close': '49.7198',
      volume: '27890400',
      'dividend amount': '0.0000',
    },
    '2004-10-22': {
      open: '84.3000',
      high: '89.7300',
      low: '84.2900',
      close: '87.3900',
      'adjusted close': '48.4125',
      volume: '39927800',
      'dividend amount': '0.0000',
    },
    '2004-10-15': {
      open: '86.7700',
      high: '87.2000',
      low: '84.3000',
      close: '84.8500',
      'adjusted close': '47.0053',
      volume: '24456500',
      'dividend amount': '0.0000',
    },
    '2004-10-08': {
      open: '87.0000',
      high: '88.1000',
      low: '86.5100',
      close: '86.7100',
      'adjusted close': '48.0357',
      volume: '21303400',
      'dividend amount': '0.0000',
    },
    '2004-10-01': {
      open: '84.1000',
      high: '86.9800',
      low: '83.8800',
      close: '86.7200',
      'adjusted close': '48.0413',
      volume: '22465000',
      'dividend amount': '0.0000',
    },
    '2004-09-24': {
      open: '85.4000',
      high: '86.4300',
      low: '83.2400',
      close: '84.4300',
      'adjusted close': '46.7727',
      volume: '23167900',
      'dividend amount': '0.0000',
    },
    '2004-09-17': {
      open: '87.0000',
      high: '87.2800',
      low: '85.4400',
      close: '85.7400',
      'adjusted close': '47.4984',
      volume: '23207800',
      'dividend amount': '0.0000',
    },
    '2004-09-10': {
      open: '84.7000',
      high: '87.0000',
      low: '84.6000',
      close: '86.7600',
      'adjusted close': '48.0634',
      volume: '17994900',
      'dividend amount': '0.0000',
    },
    '2004-09-03': {
      open: '84.5700',
      high: '85.0900',
      low: '83.6500',
      close: '84.3900',
      'adjusted close': '46.7505',
      volume: '17286100',
      'dividend amount': '0.0000',
    },
    '2004-08-27': {
      open: '85.2300',
      high: '85.4500',
      low: '84.3500',
      close: '84.9400',
      'adjusted close': '47.0552',
      volume: '16955800',
      'dividend amount': '0.0000',
    },
    '2004-08-20': {
      open: '83.7000',
      high: '85.3500',
      low: '83.5100',
      close: '85.2500',
      'adjusted close': '47.2269',
      volume: '21524300',
      'dividend amount': '0.0000',
    },
    '2004-08-13': {
      open: '83.4800',
      high: '84.9900',
      low: '81.9000',
      close: '83.9100',
      'adjusted close': '46.4846',
      volume: '26196900',
      'dividend amount': '0.0000',
    },
    '2004-08-06': {
      open: '86.8700',
      high: '87.3900',
      low: '83.4200',
      close: '83.4800',
      'adjusted close': '46.2464',
      volume: '20815300',
      'dividend amount': '0.1800',
    },
    '2004-07-30': {
      open: '84.8500',
      high: '87.4000',
      low: '84.5100',
      close: '87.0700',
      'adjusted close': '48.1314',
      volume: '22923000',
      'dividend amount': '0.0000',
    },
    '2004-07-23': {
      open: '84.5000',
      high: '87.1100',
      low: '84.4000',
      close: '84.8500',
      'adjusted close': '46.9042',
      volume: '28063300',
      'dividend amount': '0.0000',
    },
    '2004-07-16': {
      open: '84.0000',
      high: '86.4800',
      low: '83.4200',
      close: '84.2800',
      'adjusted close': '46.5891',
      volume: '35836700',
      'dividend amount': '0.0000',
    },
    '2004-07-09': {
      open: '86.5000',
      high: '86.7300',
      low: '83.5100',
      close: '83.8900',
      'adjusted close': '46.3735',
      volume: '26209600',
      'dividend amount': '0.0000',
    },
    '2004-07-02': {
      open: '89.7100',
      high: '89.9000',
      low: '86.5700',
      close: '87.0400',
      'adjusted close': '48.1148',
      volume: '21510400',
      'dividend amount': '0.0000',
    },
    '2004-06-25': {
      open: '90.4000',
      high: '90.9200',
      low: '88.9400',
      close: '89.5500',
      'adjusted close': '49.5023',
      volume: '22021500',
      'dividend amount': '0.0000',
    },
    '2004-06-18': {
      open: '90.0500',
      high: '91.2100',
      low: '89.6200',
      close: '90.0600',
      'adjusted close': '49.7842',
      volume: '22426400',
      'dividend amount': '0.0000',
    },
    '2004-06-10': {
      open: '88.7500',
      high: '90.7500',
      low: '88.0100',
      close: '90.4600',
      'adjusted close': '50.0054',
      volume: '18366900',
      'dividend amount': '0.0000',
    },
    '2004-06-04': {
      open: '88.0900',
      high: '88.6400',
      low: '87.3000',
      close: '87.5600',
      'adjusted close': '48.4023',
      volume: '15611800',
      'dividend amount': '0.0000',
    },
    '2004-05-28': {
      open: '87.7500',
      high: '89.0800',
      low: '86.5500',
      close: '88.5900',
      'adjusted close': '48.9716',
      volume: '20742800',
      'dividend amount': '0.0000',
    },
    '2004-05-21': {
      open: '85.3000',
      high: '88.8800',
      low: '85.1500',
      close: '87.1300',
      'adjusted close': '48.1646',
      volume: '26694400',
      'dividend amount': '0.0000',
    },
    '2004-05-14': {
      open: '87.6000',
      high: '88.1500',
      low: '85.1200',
      close: '86.4100',
      'adjusted close': '47.7666',
      volume: '31619400',
      'dividend amount': '0.0000',
    },
    '2004-05-07': {
      open: '88.1300',
      high: '89.7500',
      low: '87.2012',
      close: '88.1900',
      'adjusted close': '48.7505',
      volume: '25650700',
      'dividend amount': '0.1800',
    },
    '2004-04-30': {
      open: '90.7500',
      high: '91.9900',
      low: '88.0100',
      close: '88.1700',
      'adjusted close': '48.6404',
      volume: '26790300',
      'dividend amount': '0.0000',
    },
    '2004-04-23': {
      open: '91.9000',
      high: '92.4800',
      low: '89.7000',
      close: '91.2800',
      'adjusted close': '50.3561',
      volume: '26094200',
      'dividend amount': '0.0000',
    },
    '2004-04-16': {
      open: '93.5000',
      high: '94.3600',
      low: '91.0400',
      close: '92.2800',
      'adjusted close': '50.9077',
      volume: '31404700',
      'dividend amount': '0.0000',
    },
    '2004-04-08': {
      open: '93.9500',
      high: '94.3900',
      low: '92.5100',
      close: '93.1200',
      'adjusted close': '51.3711',
      volume: '16201400',
      'dividend amount': '0.0000',
    },
    '2004-04-02': {
      open: '92.9900',
      high: '94.5500',
      low: '91.3500',
      close: '94.2000',
      'adjusted close': '51.9669',
      volume: '25046900',
      'dividend amount': '0.0000',
    },
    '2004-03-26': {
      open: '91.2700',
      high: '93.2500',
      low: '90.2800',
      close: '92.7700',
      'adjusted close': '51.1780',
      volume: '27482700',
      'dividend amount': '0.0000',
    },
    '2004-03-19': {
      open: '92.6000',
      high: '93.7900',
      low: '90.8800',
      close: '91.6200',
      'adjusted close': '50.5436',
      volume: '27477500',
      'dividend amount': '0.0000',
    },
    '2004-03-12': {
      open: '96.4900',
      high: '96.8800',
      low: '91.1500',
      close: '93.3000',
      'adjusted close': '51.4704',
      volume: '32862500',
      'dividend amount': '0.0000',
    },
    '2004-03-05': {
      open: '96.5000',
      high: '97.6000',
      low: '95.5600',
      close: '96.4500',
      'adjusted close': '53.2082',
      volume: '21755500',
      'dividend amount': '0.0000',
    },
    '2004-02-27': {
      open: '97.4000',
      high: '97.5100',
      low: '95.2000',
      close: '96.5000',
      'adjusted close': '53.2358',
      volume: '25680600',
      'dividend amount': '0.0000',
    },
    '2004-02-20': {
      open: '99.9900',
      high: '100.0000',
      low: '97.1900',
      close: '97.3100',
      'adjusted close': '53.6826',
      volume: '19898100',
      'dividend amount': '0.0000',
    },
    '2004-02-13': {
      open: '99.3100',
      high: '100.3100',
      low: '98.4100',
      close: '99.7100',
      'adjusted close': '55.0066',
      volume: '20479500',
      'dividend amount': '0.0000',
    },
    '2004-02-06': {
      open: '99.1500',
      high: '100.4300',
      low: '98.2500',
      close: '98.9400',
      'adjusted close': '54.5818',
      volume: '31683700',
      'dividend amount': '0.1600',
    },
    '2004-01-30': {
      open: '97.9000',
      high: '99.8500',
      low: '96.5500',
      close: '99.2300',
      'adjusted close': '54.6534',
      volume: '30864500',
      'dividend amount': '0.0000',
    },
    '2004-01-23': {
      open: '96.0000',
      high: '98.2100',
      low: '95.7300',
      close: '97.9000',
      'adjusted close': '53.9209',
      volume: '25889600',
      'dividend amount': '0.0000',
    },
    '2004-01-16': {
      open: '91.2100',
      high: '95.6500',
      low: '89.0100',
      close: '95.3200',
      'adjusted close': '52.4999',
      volume: '48592000',
      'dividend amount': '0.0000',
    },
    '2004-01-09': {
      open: '92.0000',
      high: '93.3800',
      low: '91.0000',
      close: '91.2100',
      'adjusted close': '50.2362',
      volume: '28694600',
      'dividend amount': '0.0000',
    },
    '2004-01-02': {
      open: '93.1000',
      high: '93.7300',
      low: '91.2000',
      close: '91.5500',
      'adjusted close': '50.4235',
      volume: '18092000',
      'dividend amount': '0.0000',
    },
    '2003-12-26': {
      open: '92.8300',
      high: '93.5000',
      low: '92.1800',
      close: '92.9000',
      'adjusted close': '51.1670',
      volume: '11178100',
      'dividend amount': '0.0000',
    },
    '2003-12-19': {
      open: '93.4300',
      high: '94.1200',
      low: '91.8800',
      close: '93.1400',
      'adjusted close': '51.2992',
      volume: '33482600',
      'dividend amount': '0.0000',
    },
    '2003-12-12': {
      open: '90.5200',
      high: '93.1600',
      low: '90.5200',
      close: '92.7100',
      'adjusted close': '51.0624',
      volume: '30943400',
      'dividend amount': '0.0000',
    },
    '2003-12-05': {
      open: '90.9000',
      high: '91.4800',
      low: '90.0300',
      close: '90.6400',
      'adjusted close': '49.9223',
      volume: '27735300',
      'dividend amount': '0.0000',
    },
    '2003-11-28': {
      open: '89.0300',
      high: '90.6800',
      low: '88.9500',
      close: '90.5400',
      'adjusted close': '49.8672',
      volume: '15370200',
      'dividend amount': '0.0000',
    },
    '2003-11-21': {
      open: '89.9000',
      high: '90.1800',
      low: '88.2300',
      close: '88.6300',
      'adjusted close': '48.8152',
      volume: '25235500',
      'dividend amount': '0.0000',
    },
    '2003-11-14': {
      open: '89.5500',
      high: '91.4800',
      low: '88.8900',
      close: '90.2500',
      'adjusted close': '49.7075',
      volume: '28880700',
      'dividend amount': '0.0000',
    },
    '2003-11-07': {
      open: '89.9000',
      high: '90.8400',
      low: '87.7200',
      close: '88.2600',
      'adjusted close': '48.6114',
      volume: '28479700',
      'dividend amount': '0.1600',
    },
    '2003-10-31': {
      open: '88.8000',
      high: '90.7100',
      low: '88.0500',
      close: '89.4800',
      'adjusted close': '49.1953',
      volume: '28610800',
      'dividend amount': '0.0000',
    },
    '2003-10-24': {
      open: '89.3500',
      high: '89.4000',
      low: '87.5300',
      close: '88.4200',
      'adjusted close': '48.6125',
      volume: '29376600',
      'dividend amount': '0.0000',
    },
    '2003-10-17': {
      open: '93.1000',
      high: '94.5400',
      low: '88.6600',
      close: '89.2300',
      'adjusted close': '49.0578',
      volume: '47597600',
      'dividend amount': '0.0000',
    },
    '2003-10-10': {
      open: '90.7400',
      high: '93.6000',
      low: '90.4000',
      close: '92.6700',
      'adjusted close': '50.9491',
      volume: '27727600',
      'dividend amount': '0.0000',
    },
    '2003-10-03': {
      open: '89.2500',
      high: '91.9500',
      low: '87.9000',
      close: '90.6400',
      'adjusted close': '49.8330',
      volume: '35612700',
      'dividend amount': '0.0000',
    },
    '2003-09-26': {
      open: '92.2400',
      high: '92.2400',
      low: '89.0000',
      close: '89.0500',
      'adjusted close': '48.9588',
      volume: '34494900',
      'dividend amount': '0.0000',
    },
    '2003-09-19': {
      open: '89.7000',
      high: '93.4700',
      low: '88.3900',
      close: '93.2800',
      'adjusted close': '51.2845',
      volume: '42039300',
      'dividend amount': '0.0000',
    },
    '2003-09-12': {
      open: '88.4500',
      high: '89.9700',
      low: '86.4000',
      close: '88.7000',
      'adjusted close': '48.7664',
      volume: '41601200',
      'dividend amount': '0.0000',
    },
    '2003-09-05': {
      open: '82.4000',
      high: '88.2900',
      low: '82.3000',
      close: '86.9500',
      'adjusted close': '47.8043',
      volume: '41027800',
      'dividend amount': '0.0000',
    },
    '2003-08-29': {
      open: '82.8500',
      high: '83.0300',
      low: '81.2700',
      close: '82.0100',
      'adjusted close': '45.0883',
      volume: '24293600',
      'dividend amount': '0.0000',
    },
    '2003-08-22': {
      open: '82.0500',
      high: '84.7200',
      low: '81.9000',
      close: '82.9700',
      'adjusted close': '45.6161',
      volume: '30615500',
      'dividend amount': '0.0000',
    },
    '2003-08-15': {
      open: '80.8700',
      high: '82.1900',
      low: '80.2800',
      close: '81.7900',
      'adjusted close': '44.9674',
      volume: '22220100',
      'dividend amount': '0.0000',
    },
    '2003-08-08': {
      open: '80.7500',
      high: '81.3600',
      low: '78.7300',
      close: '80.8800',
      'adjusted close': '44.4671',
      volume: '30290600',
      'dividend amount': '0.1600',
    },
    '2003-08-01': {
      open: '83.6700',
      high: '83.7000',
      low: '80.0500',
      close: '81.2700',
      'adjusted close': '44.5920',
      volume: '34886500',
      'dividend amount': '0.0000',
    },
    '2003-07-25': {
      open: '83.2000',
      high: '83.7400',
      low: '81.5100',
      close: '83.5500',
      'adjusted close': '45.8430',
      volume: '33171000',
      'dividend amount': '0.0000',
    },
    '2003-07-18': {
      open: '86.0000',
      high: '87.0300',
      low: '82.5000',
      close: '83.7200',
      'adjusted close': '45.9363',
      volume: '45286100',
      'dividend amount': '0.0000',
    },
    '2003-07-11': {
      open: '84.8000',
      high: '86.7500',
      low: '83.4700',
      close: '84.8900',
      'adjusted close': '46.5783',
      volume: '32242400',
      'dividend amount': '0.0000',
    },
    '2003-07-03': {
      open: '83.9000',
      high: '84.8900',
      low: '81.4000',
      close: '83.9500',
      'adjusted close': '46.0625',
      volume: '22755900',
      'dividend amount': '0.0000',
    },
    '2003-06-27': {
      open: '84.7900',
      high: '84.7900',
      low: '82.0900',
      close: '83.4200',
      'adjusted close': '45.7717',
      volume: '27829700',
      'dividend amount': '0.0000',
    },
    '2003-06-20': {
      open: '83.1000',
      high: '85.2900',
      low: '82.5500',
      close: '84.9200',
      'adjusted close': '46.5947',
      volume: '37484300',
      'dividend amount': '0.0000',
    },
    '2003-06-13': {
      open: '80.0000',
      high: '84.9800',
      low: '79.8100',
      close: '82.7500',
      'adjusted close': '45.4041',
      volume: '41268000',
      'dividend amount': '0.0000',
    },
    '2003-06-06': {
      open: '89.0000',
      high: '89.0800',
      low: '79.8400',
      close: '80.0500',
      'adjusted close': '43.9226',
      volume: '69955400',
      'dividend amount': '0.0000',
    },
    '2003-05-30': {
      open: '84.5100',
      high: '88.7000',
      low: '84.5100',
      close: '88.0400',
      'adjusted close': '48.3066',
      volume: '26786400',
      'dividend amount': '0.0000',
    },
    '2003-05-23': {
      open: '88.0500',
      high: '88.4700',
      low: '84.8900',
      close: '85.2600',
      'adjusted close': '46.7813',
      volume: '32835800',
      'dividend amount': '0.0000',
    },
    '2003-05-16': {
      open: '87.5500',
      high: '90.4000',
      low: '87.1000',
      close: '88.9900',
      'adjusted close': '48.8279',
      volume: '38800700',
      'dividend amount': '0.0000',
    },
    '2003-05-09': {
      open: '87.2000',
      high: '88.3400',
      low: '85.7500',
      close: '87.5500',
      'adjusted close': '48.0378',
      volume: '35257500',
      'dividend amount': '0.1600',
    },
    '2003-05-02': {
      open: '84.0500',
      high: '87.5700',
      low: '83.6200',
      close: '87.5700',
      'adjusted close': '47.9602',
      volume: '39359000',
      'dividend amount': '0.0000',
    },
    '2003-04-25': {
      open: '84.4000',
      high: '86.0600',
      low: '82.6000',
      close: '83.8800',
      'adjusted close': '45.9393',
      volume: '36064700',
      'dividend amount': '0.0000',
    },
    '2003-04-17': {
      open: '78.5000',
      high: '84.4000',
      low: '78.1600',
      close: '84.2600',
      'adjusted close': '46.1474',
      volume: '42212300',
      'dividend amount': '0.0000',
    },
    '2003-04-11': {
      open: '82.6000',
      high: '82.9000',
      low: '78.1300',
      close: '78.7500',
      'adjusted close': '43.1297',
      volume: '39935800',
      'dividend amount': '0.0000',
    },
    '2003-04-04': {
      open: '79.2600',
      high: '83.4800',
      low: '78.1200',
      close: '80.7900',
      'adjusted close': '44.2470',
      volume: '46339300',
      'dividend amount': '0.0000',
    },
    '2003-03-28': {
      open: '82.4600',
      high: '84.0000',
      low: '80.5000',
      close: '80.8500',
      'adjusted close': '44.2798',
      volume: '42576300',
      'dividend amount': '0.0000',
    },
    '2003-03-21': {
      open: '78.0000',
      high: '84.9000',
      low: '77.8400',
      close: '84.9000',
      'adjusted close': '46.4979',
      volume: '58133300',
      'dividend amount': '0.0000',
    },
    '2003-03-14': {
      open: '77.4500',
      high: '79.4800',
      low: '73.1700',
      close: '79.0000',
      'adjusted close': '43.2666',
      volume: '48104400',
      'dividend amount': '0.0000',
    },
    '2003-03-07': {
      open: '78.9000',
      high: '79.0000',
      low: '75.7100',
      close: '77.9000',
      'adjusted close': '42.6642',
      volume: '32954000',
      'dividend amount': '0.0000',
    },
    '2003-02-28': {
      open: '79.7000',
      high: '79.9900',
      low: '76.7500',
      close: '77.9500',
      'adjusted close': '42.6916',
      volume: '39649300',
      'dividend amount': '0.0000',
    },
    '2003-02-21': {
      open: '78.0200',
      high: '80.0500',
      low: '77.9100',
      close: '79.9500',
      'adjusted close': '43.7869',
      volume: '26970500',
      'dividend amount': '0.0000',
    },
    '2003-02-14': {
      open: '77.1000',
      high: '78.9700',
      low: '74.3100',
      close: '77.4500',
      'adjusted close': '42.4177',
      volume: '41674400',
      'dividend amount': '0.0000',
    },
    '2003-02-07': {
      open: '78.2000',
      high: '78.9000',
      low: '76.2500',
      close: '77.1000',
      'adjusted close': '42.2260',
      volume: '37378500',
      'dividend amount': '0.1500',
    },
    '2003-01-31': {
      open: '77.9900',
      high: '81.3000',
      low: '77.0200',
      close: '78.2000',
      'adjusted close': '42.7458',
      volume: '42173800',
      'dividend amount': '0.0000',
    },
    '2003-01-24': {
      open: '81.3000',
      high: '81.8500',
      low: '78.8200',
      close: '78.9900',
      'adjusted close': '43.1776',
      volume: '34478700',
      'dividend amount': '0.0000',
    },
    '2003-01-17': {
      open: '88.3100',
      high: '88.9500',
      low: '81.1800',
      close: '81.3000',
      'adjusted close': '44.4403',
      volume: '53450200',
      'dividend amount': '0.0000',
    },
    '2003-01-10': {
      open: '81.9000',
      high: '88.0400',
      low: '81.8100',
      close: '87.6800',
      'adjusted close': '47.9277',
      volume: '50004000',
      'dividend amount': '0.0000',
    },
    '2003-01-03': {
      open: '77.0000',
      high: '81.6500',
      low: '75.6000',
      close: '81.6500',
      'adjusted close': '44.6316',
      volume: '29764800',
      'dividend amount': '0.0000',
    },
    '2002-12-27': {
      open: '79.7500',
      high: '81.5000',
      low: '76.6100',
      close: '77.3600',
      'adjusted close': '42.2866',
      volume: '20460800',
      'dividend amount': '0.0000',
    },
    '2002-12-20': {
      open: '79.6000',
      high: '81.7900',
      low: '78.2400',
      close: '79.7900',
      'adjusted close': '43.6149',
      volume: '36539200',
      'dividend amount': '0.0000',
    },
    '2002-12-13': {
      open: '81.0000',
      high: '82.4800',
      low: '78.2700',
      close: '80.0000',
      'adjusted close': '43.7297',
      volume: '37355200',
      'dividend amount': '0.0000',
    },
    '2002-12-06': {
      open: '88.6000',
      high: '89.4600',
      low: '81.0700',
      close: '82.3200',
      'adjusted close': '44.9978',
      volume: '41270300',
      'dividend amount': '0.0000',
    },
    '2002-11-29': {
      open: '84.6300',
      high: '88.1100',
      low: '83.5200',
      close: '86.9200',
      'adjusted close': '47.5123',
      volume: '28402500',
      'dividend amount': '0.0000',
    },
    '2002-11-22': {
      open: '80.8000',
      high: '85.1700',
      low: '77.8400',
      close: '84.4300',
      'adjusted close': '46.1512',
      volume: '45101100',
      'dividend amount': '0.0000',
    },
    '2002-11-15': {
      open: '77.5900',
      high: '80.9900',
      low: '76.7000',
      close: '80.0100',
      'adjusted close': '43.7351',
      volume: '49429400',
      'dividend amount': '0.0000',
    },
    '2002-11-08': {
      open: '81.9900',
      high: '83.8100',
      low: '77.2000',
      close: '77.5900',
      'adjusted close': '42.4123',
      volume: '52109900',
      'dividend amount': '0.1500',
    },
    '2002-11-01': {
      open: '75.1500',
      high: '80.5000',
      low: '74.2000',
      close: '80.4000',
      'adjusted close': '43.8676',
      volume: '56125400',
      'dividend amount': '0.0000',
    },
    '2002-10-25': {
      open: '73.6500',
      high: '75.9000',
      low: '71.7500',
      close: '74.5600',
      'adjusted close': '40.6812',
      volume: '51727300',
      'dividend amount': '0.0000',
    },
    '2002-10-18': {
      open: '61.5400',
      high: '74.2500',
      low: '61.5400',
      close: '74.2500',
      'adjusted close': '40.5121',
      volume: '72253200',
      'dividend amount': '0.0000',
    },
    '2002-10-11': {
      open: '56.6000',
      high: '63.9200',
      low: '54.0100',
      close: '63.9200',
      'adjusted close': '34.8759',
      volume: '67066500',
      'dividend amount': '0.0000',
    },
    '2002-10-04': {
      open: '59.5500',
      high: '62.0000',
      low: '55.7700',
      close: '56.6000',
      'adjusted close': '30.8819',
      volume: '60869200',
      'dividend amount': '0.0000',
    },
    '2002-09-27': {
      open: '63.7500',
      high: '64.8800',
      low: '59.5500',
      close: '60.3600',
      'adjusted close': '32.9335',
      volume: '59368300',
      'dividend amount': '0.0000',
    },
    '2002-09-20': {
      open: '72.0000',
      high: '73.4500',
      low: '63.9000',
      close: '63.9200',
      'adjusted close': '34.8759',
      volume: '59733100',
      'dividend amount': '0.0000',
    },
    '2002-09-13': {
      open: '72.4500',
      high: '77.5000',
      low: '71.3100',
      close: '72.5000',
      'adjusted close': '39.5572',
      volume: '29374700',
      'dividend amount': '0.0000',
    },
    '2002-09-06': {
      open: '74.2000',
      high: '75.0000',
      low: '71.5000',
      close: '73.2000',
      'adjusted close': '39.9392',
      volume: '24168200',
      'dividend amount': '0.0000',
    },
    '2002-08-30': {
      open: '80.4000',
      high: '80.8900',
      low: '75.1000',
      close: '75.3800',
      'adjusted close': '41.1286',
      volume: '31535800',
      'dividend amount': '0.0000',
    },
    '2002-08-23': {
      open: '79.3500',
      high: '82.8500',
      low: '79.0100',
      close: '80.4000',
      'adjusted close': '43.8676',
      volume: '37599000',
      'dividend amount': '0.0000',
    },
    '2002-08-16': {
      open: '71.0500',
      high: '79.9800',
      low: '70.6100',
      close: '79.3500',
      'adjusted close': '43.2947',
      volume: '42544100',
      'dividend amount': '0.0000',
    },
    '2002-08-09': {
      open: '67.9400',
      high: '74.3000',
      low: '65.8500',
      close: '71.8300',
      'adjusted close': '39.1917',
      volume: '38896400',
      'dividend amount': '0.1500',
    },
    '2002-08-02': {
      open: '70.3700',
      high: '72.7000',
      low: '67.1200',
      close: '67.8800',
      'adjusted close': '36.9564',
      volume: '50523500',
      'dividend amount': '0.0000',
    },
    '2002-07-26': {
      open: '71.2500',
      high: '72.1900',
      low: '65.7000',
      close: '66.4000',
      'adjusted close': '36.1506',
      volume: '64004300',
      'dividend amount': '0.0000',
    },
    '2002-07-19': {
      open: '68.4000',
      high: '74.2000',
      low: '66.6000',
      close: '72.0000',
      'adjusted close': '39.1994',
      volume: '59368700',
      'dividend amount': '0.0000',
    },
    '2002-07-12': {
      open: '73.0000',
      high: '73.4800',
      low: '67.5500',
      close: '69.2100',
      'adjusted close': '37.6805',
      volume: '50325700',
      'dividend amount': '0.0000',
    },
    '2002-07-05': {
      open: '72.0100',
      high: '73.9000',
      low: '67.1600',
      close: '73.5000',
      'adjusted close': '40.0161',
      volume: '37781700',
      'dividend amount': '0.0000',
    },
    '2002-06-28': {
      open: '67.2500',
      high: '73.6200',
      low: '66.1000',
      close: '72.0000',
      'adjusted close': '39.1994',
      volume: '56342200',
      'dividend amount': '0.0000',
    },
    '2002-06-21': {
      open: '76.1800',
      high: '77.7500',
      low: '68.1900',
      close: '68.7500',
      'adjusted close': '37.4300',
      volume: '51132700',
      'dividend amount': '0.0000',
    },
    '2002-06-14': {
      open: '77.7500',
      high: '78.8000',
      low: '73.2500',
      close: '76.1700',
      'adjusted close': '41.4697',
      volume: '45826900',
      'dividend amount': '0.0000',
    },
    '2002-06-07': {
      open: '80.7500',
      high: '80.9500',
      low: '77.0000',
      close: '78.3000',
      'adjusted close': '42.6294',
      volume: '37678900',
      'dividend amount': '0.0000',
    },
    '2002-05-31': {
      open: '82.8000',
      high: '83.1100',
      low: '80.2200',
      close: '80.4500',
      'adjusted close': '43.7999',
      volume: '21571900',
      'dividend amount': '0.0000',
    },
    '2002-05-24': {
      open: '85.4500',
      high: '85.4600',
      low: '82.5200',
      close: '83.1000',
      'adjusted close': '45.2427',
      volume: '25012600',
      'dividend amount': '0.0000',
    },
    '2002-05-17': {
      open: '80.2500',
      high: '86.4900',
      low: '79.5000',
      close: '85.6900',
      'adjusted close': '46.6528',
      volume: '37347700',
      'dividend amount': '0.0000',
    },
    '2002-05-10': {
      open: '81.7000',
      high: '83.0000',
      low: '75.9200',
      close: '79.6800',
      'adjusted close': '43.3807',
      volume: '61363200',
      'dividend amount': '0.1500',
    },
    '2002-05-03': {
      open: '84.7100',
      high: '85.2300',
      low: '81.1100',
      close: '81.7800',
      'adjusted close': '44.4432',
      volume: '42862500',
      'dividend amount': '0.0000',
    },
    '2002-04-26': {
      open: '88.9500',
      high: '89.0800',
      low: '84.7000',
      close: '84.7100',
      'adjusted close': '46.0355',
      volume: '31976500',
      'dividend amount': '0.0000',
    },
    '2002-04-19': {
      open: '86.5000',
      high: '90.0300',
      low: '84.0000',
      close: '89.0000',
      'adjusted close': '48.3669',
      volume: '51860900',
      'dividend amount': '0.0000',
    },
    '2002-04-12': {
      open: '87.5000',
      high: '89.9500',
      low: '83.3400',
      close: '85.6000',
      'adjusted close': '46.5191',
      volume: '110897800',
      'dividend amount': '0.0000',
    },
    '2002-04-05': {
      open: '104.0000',
      high: '104.0000',
      low: '97.2500',
      close: '97.2500',
      'adjusted close': '52.8503',
      volume: '36348600',
      'dividend amount': '0.0000',
    },
    '2002-03-28': {
      open: '105.8000',
      high: '106.6600',
      low: '102.3000',
      close: '104.0000',
      'adjusted close': '56.5186',
      volume: '25371800',
      'dividend amount': '0.0000',
    },
    '2002-03-22': {
      open: '107.1000',
      high: '108.6400',
      low: '104.7000',
      close: '105.6000',
      'adjusted close': '57.3881',
      volume: '25381100',
      'dividend amount': '0.0000',
    },
    '2002-03-15': {
      open: '104.3500',
      high: '108.8500',
      low: '103.6000',
      close: '106.7900',
      'adjusted close': '58.0348',
      volume: '42307200',
      'dividend amount': '0.0000',
    },
    '2002-03-08': {
      open: '103.3500',
      high: '107.5000',
      low: '103.1300',
      close: '105.0900',
      'adjusted close': '57.1109',
      volume: '47715500',
      'dividend amount': '0.0000',
    },
    '2002-03-01': {
      open: '98.4500',
      high: '103.1000',
      low: '95.9900',
      close: '103.0200',
      'adjusted close': '55.9860',
      volume: '51947900',
      'dividend amount': '0.0000',
    },
    '2002-02-22': {
      open: '101.5000',
      high: '101.8000',
      low: '95.7600',
      close: '98.4500',
      'adjusted close': '53.5024',
      volume: '48524400',
      'dividend amount': '0.0000',
    },
    '2002-02-15': {
      open: '105.0000',
      high: '109.1500',
      low: '101.7000',
      close: '102.8900',
      'adjusted close': '55.9154',
      volume: '39939500',
      'dividend amount': '0.0000',
    },
    '2002-02-08': {
      open: '108.0000',
      high: '108.5000',
      low: '102.6100',
      close: '104.9900',
      'adjusted close': '57.0566',
      volume: '35602600',
      'dividend amount': '0.1400',
    },
    '2002-02-01': {
      open: '109.5000',
      high: '110.2100',
      low: '101.0000',
      close: '108.0000',
      'adjusted close': '58.6154',
      volume: '55992100',
      'dividend amount': '0.0000',
    },
    '2002-01-25': {
      open: '114.2500',
      high: '114.2600',
      low: '107.0000',
      close: '109.2800',
      'adjusted close': '59.3101',
      volume: '38030400',
      'dividend amount': '0.0000',
    },
    '2002-01-18': {
      open: '120.0000',
      high: '120.5500',
      low: '112.8100',
      close: '114.2500',
      'adjusted close': '62.0075',
      volume: '50634800',
      'dividend amount': '0.0000',
    },
    '2002-01-11': {
      open: '125.0000',
      high: '126.3900',
      low: '120.2800',
      close: '120.3100',
      'adjusted close': '65.2965',
      volume: '31815400',
      'dividend amount': '0.0000',
    },
    '2002-01-04': {
      open: '122.7500',
      high: '125.6000',
      low: '119.8000',
      close: '125.6000',
      'adjusted close': '68.1676',
      volume: '27402400',
      'dividend amount': '0.0000',
    },
    '2001-12-28': {
      open: '122.0500',
      high: '123.9300',
      low: '121.1000',
      close: '122.9000',
      'adjusted close': '66.7022',
      volume: '13450200',
      'dividend amount': '0.0000',
    },
    '2001-12-21': {
      open: '120.1500',
      high: '124.7000',
      low: '120.1500',
      close: '122.0000',
      'adjusted close': '66.2137',
      volume: '34859100',
      'dividend amount': '0.0000',
    },
    '2001-12-14': {
      open: '120.0000',
      high: '123.2100',
      low: '119.4100',
      close: '121.1000',
      'adjusted close': '65.7252',
      volume: '36439000',
      'dividend amount': '0.0000',
    },
    '2001-12-07': {
      open: '114.8000',
      high: '121.9800',
      low: '113.2100',
      close: '120.4000',
      'adjusted close': '65.3453',
      volume: '45132800',
      'dividend amount': '0.0000',
    },
    '2001-11-30': {
      open: '115.9000',
      high: '116.4000',
      low: '111.8100',
      close: '115.5900',
      'adjusted close': '62.7348',
      volume: '29636100',
      'dividend amount': '0.0000',
    },
    '2001-11-23': {
      open: '114.7000',
      high: '116.8000',
      low: '113.0500',
      close: '115.3500',
      'adjusted close': '62.6045',
      volume: '17282200',
      'dividend amount': '0.0000',
    },
    '2001-11-16': {
      open: '113.0000',
      high: '117.0000',
      low: '112.0500',
      close: '114.5000',
      'adjusted close': '62.1432',
      volume: '36592100',
      'dividend amount': '0.0000',
    },
    '2001-11-09': {
      open: '109.9500',
      high: '115.5600',
      low: '109.0000',
      close: '114.0800',
      'adjusted close': '61.9152',
      volume: '37385000',
      'dividend amount': '0.1400',
    },
    '2001-11-02': {
      open: '110.5000',
      high: '111.1200',
      low: '106.9000',
      close: '109.5000',
      'adjusted close': '59.3565',
      volume: '37116500',
      'dividend amount': '0.0000',
    },
    '2001-10-26': {
      open: '102.6500',
      high: '112.1000',
      low: '101.9000',
      close: '111.1600',
      'adjusted close': '60.2564',
      volume: '43172300',
      'dividend amount': '0.0000',
    },
    '2001-10-19': {
      open: '99.8500',
      high: '106.7000',
      low: '99.6600',
      close: '102.6500',
      'adjusted close': '55.6434',
      volume: '63537500',
      'dividend amount': '0.0000',
    },
    '2001-10-12': {
      open: '98.0300',
      high: '101.0000',
      low: '94.9000',
      close: '100.8400',
      'adjusted close': '54.6622',
      volume: '48258400',
      'dividend amount': '0.0000',
    },
    '2001-10-05': {
      open: '91.7200',
      high: '98.8800',
      low: '91.3400',
      close: '98.0200',
      'adjusted close': '53.1336',
      volume: '46507700',
      'dividend amount': '0.0000',
    },
    '2001-09-28': {
      open: '91.8000',
      high: '96.0000',
      low: '87.4900',
      close: '91.7200',
      'adjusted close': '49.7185',
      volume: '61809500',
      'dividend amount': '0.0000',
    },
    '2001-09-21': {
      open: '93.9000',
      high: '96.8500',
      low: '89.5000',
      close: '90.5000',
      'adjusted close': '49.0572',
      volume: '75868900',
      'dividend amount': '0.0000',
    },
    '2001-09-10': {
      open: '96.0000',
      high: '97.4000',
      low: '95.5900',
      close: '96.4700',
      'adjusted close': '52.2934',
      volume: '10311500',
      'dividend amount': '0.0000',
    },
    '2001-09-07': {
      open: '100.1500',
      high: '103.1300',
      low: '96.0000',
      close: '96.5900',
      'adjusted close': '52.3584',
      volume: '41322600',
      'dividend amount': '0.0000',
    },
    '2001-08-31': {
      open: '106.9900',
      high: '108.2000',
      low: '98.8600',
      close: '99.9500',
      'adjusted close': '54.1798',
      volume: '38331400',
      'dividend amount': '0.0000',
    },
    '2001-08-24': {
      open: '104.6800',
      high: '107.8000',
      low: '101.4000',
      close: '106.9900',
      'adjusted close': '57.9959',
      volume: '33343800',
      'dividend amount': '0.0000',
    },
    '2001-08-17': {
      open: '105.0000',
      high: '106.8500',
      low: '103.6600',
      close: '104.5900',
      'adjusted close': '56.6950',
      volume: '29688900',
      'dividend amount': '0.0000',
    },
    '2001-08-10': {
      open: '107.2500',
      high: '107.3700',
      low: '102.7000',
      close: '104.9500',
      'adjusted close': '56.8901',
      volume: '26622100',
      'dividend amount': '0.1400',
    },
    '2001-08-03': {
      open: '104.9000',
      high: '110.0900',
      low: '104.7600',
      close: '108.1800',
      'adjusted close': '58.5623',
      volume: '25049100',
      'dividend amount': '0.0000',
    },
    '2001-07-27': {
      open: '105.7000',
      high: '106.9500',
      low: '102.7300',
      close: '104.7000',
      'adjusted close': '56.6784',
      volume: '32638300',
      'dividend amount': '0.0000',
    },
    '2001-07-20': {
      open: '108.5300',
      high: '109.3800',
      low: '103.0300',
      close: '105.7000',
      'adjusted close': '57.2198',
      volume: '48299000',
      'dividend amount': '0.0000',
    },
    '2001-07-13': {
      open: '106.5000',
      high: '109.3000',
      low: '101.5600',
      close: '108.5300',
      'adjusted close': '58.7518',
      volume: '45082800',
      'dividend amount': '0.0000',
    },
    '2001-07-06': {
      open: '113.5000',
      high: '115.4000',
      low: '105.8100',
      close: '106.5000',
      'adjusted close': '57.6529',
      volume: '25362500',
      'dividend amount': '0.0000',
    },
    '2001-06-29': {
      open: '113.7500',
      high: '116.9500',
      low: '111.1000',
      close: '113.5000',
      'adjusted close': '61.4422',
      volume: '35230300',
      'dividend amount': '0.0000',
    },
    '2001-06-22': {
      open: '113.9800',
      high: '117.2500',
      low: '111.3600',
      close: '112.8700',
      'adjusted close': '61.1012',
      volume: '33926900',
      'dividend amount': '0.0000',
    },
    '2001-06-15': {
      open: '116.3000',
      high: '118.4700',
      low: '113.3800',
      close: '113.6000',
      'adjusted close': '61.4964',
      volume: '39520000',
      'dividend amount': '0.0000',
    },
    '2001-06-08': {
      open: '113.2500',
      high: '119.0000',
      low: '112.8500',
      close: '116.1000',
      'adjusted close': '62.8497',
      volume: '29113600',
      'dividend amount': '0.0000',
    },
    '2001-06-01': {
      open: '117.3500',
      high: '117.5900',
      low: '111.6400',
      close: '112.8900',
      'adjusted close': '61.1120',
      volume: '27279700',
      'dividend amount': '0.0000',
    },
    '2001-05-25': {
      open: '117.9500',
      high: '119.9000',
      low: '117.1000',
      close: '117.8000',
      'adjusted close': '63.7700',
      volume: '34172800',
      'dividend amount': '0.0000',
    },
    '2001-05-18': {
      open: '111.2000',
      high: '117.6800',
      low: '111.0000',
      close: '117.4400',
      'adjusted close': '63.5751',
      volume: '32527500',
      'dividend amount': '0.0000',
    },
    '2001-05-11': {
      open: '116.2000',
      high: '118.9000',
      low: '110.9600',
      close: '111.8100',
      'adjusted close': '60.5274',
      volume: '37758700',
      'dividend amount': '0.1400',
    },
    '2001-05-04': {
      open: '116.4000',
      high: '118.9500',
      low: '111.2000',
      close: '115.8600',
      'adjusted close': '62.6453',
      volume: '40113600',
      'dividend amount': '0.0000',
    },
    '2001-04-27': {
      open: '114.0100',
      high: '116.9000',
      low: '111.6800',
      close: '116.2000',
      'adjusted close': '62.8291',
      volume: '38874700',
      'dividend amount': '0.0000',
    },
    '2001-04-20': {
      open: '96.2000',
      high: '116.4000',
      low: '95.2000',
      close: '114.8300',
      'adjusted close': '62.0884',
      volume: '77541600',
      'dividend amount': '0.0000',
    },
    '2001-04-12': {
      open: '98.5000',
      high: '101.9400',
      low: '93.8000',
      close: '96.2000',
      'adjusted close': '52.0152',
      volume: '40874500',
      'dividend amount': '0.0000',
    },
    '2001-04-06': {
      open: '97.4500',
      high: '100.0000',
      low: '90.0500',
      close: '97.9500',
      'adjusted close': '52.9614',
      volume: '61562900',
      'dividend amount': '0.0000',
    },
    '2001-03-30': {
      open: '95.2500',
      high: '100.0000',
      low: '93.7500',
      close: '96.1800',
      'adjusted close': '52.0044',
      volume: '52337900',
      'dividend amount': '0.0000',
    },
    '2001-03-23': {
      open: '91.0000',
      high: '94.9900',
      low: '87.6500',
      close: '93.5100',
      'adjusted close': '50.5607',
      volume: '56911400',
      'dividend amount': '0.0000',
    },
    '2001-03-16': {
      open: '97.9000',
      high: '98.9000',
      low: '88.2000',
      close: '90.1000',
      'adjusted close': '48.7169',
      volume: '69318700',
      'dividend amount': '0.0000',
    },
    '2001-03-09': {
      open: '102.8100',
      high: '108.4000',
      low: '97.9000',
      close: '99.2900',
      'adjusted close': '53.6859',
      volume: '46134500',
      'dividend amount': '0.0000',
    },
    '2001-03-02': {
      open: '105.8000',
      high: '107.6500',
      low: '97.5000',
      close: '102.3000',
      'adjusted close': '55.3134',
      volume: '59912700',
      'dividend amount': '0.0000',
    },
    '2001-02-23': {
      open: '114.5500',
      high: '115.6000',
      low: '100.2500',
      close: '104.0000',
      'adjusted close': '56.2326',
      volume: '42890800',
      'dividend amount': '0.0000',
    },
    '2001-02-16': {
      open: '110.5500',
      high: '118.6400',
      low: '110.3000',
      close: '115.0000',
      'adjusted close': '62.1803',
      volume: '36127100',
      'dividend amount': '0.0000',
    },
    '2001-02-09': {
      open: '109.4000',
      high: '118.2000',
      low: '109.3000',
      close: '112.0000',
      'adjusted close': '60.5582',
      volume: '36346600',
      'dividend amount': '0.1300',
    },
    '2001-02-02': {
      open: '112.5000',
      high: '116.7500',
      low: '109.6000',
      close: '110.2700',
      'adjusted close': '59.5566',
      volume: '32690400',
      'dividend amount': '0.0000',
    },
    '2001-01-26': {
      open: '109.5000',
      high: '115.2500',
      low: '107.6200',
      close: '114.1900',
      'adjusted close': '61.6737',
      volume: '40283600',
      'dividend amount': '0.0000',
    },
    '2001-01-19': {
      open: '93.7500',
      high: '113.9400',
      low: '91.8100',
      close: '111.2500',
      'adjusted close': '60.0859',
      volume: '55406000',
      'dividend amount': '0.0000',
    },
    '2001-01-12': {
      open: '93.5000',
      high: '96.4400',
      low: '91.2500',
      close: '93.8100',
      'adjusted close': '50.6666',
      volume: '37351000',
      'dividend amount': '0.0000',
    },
    '2001-01-05': {
      open: '84.5000',
      high: '99.7500',
      low: '83.7500',
      close: '94.0000',
      'adjusted close': '50.7692',
      volume: '44240800',
      'dividend amount': '0.0000',
    },
    '2000-12-29': {
      open: '89.0000',
      high: '89.5600',
      low: '82.1200',
      close: '85.0000',
      'adjusted close': '45.9083',
      volume: '31039700',
      'dividend amount': '0.0000',
    },
    '2000-12-22': {
      open: '88.3700',
      high: '94.4400',
      low: '80.0600',
      close: '89.0000',
      'adjusted close': '48.0687',
      volume: '50218000',
      'dividend amount': '0.0000',
    },
    '2000-12-15': {
      open: '96.5000',
      high: '98.2500',
      low: '87.3100',
      close: '87.8100',
      'adjusted close': '47.4260',
      volume: '41477400',
      'dividend amount': '0.0000',
    },
    '2000-12-08': {
      open: '96.0000',
      high: '104.7500',
      low: '93.1200',
      close: '97.0000',
      'adjusted close': '52.3895',
      volume: '37530500',
      'dividend amount': '0.0000',
    },
    '2000-12-01': {
      open: '100.4400',
      high: '102.0000',
      low: '92.2500',
      close: '95.6200',
      'adjusted close': '51.6441',
      volume: '32366400',
      'dividend amount': '0.0000',
    },
    '2000-11-24': {
      open: '101.5000',
      high: '104.3700',
      low: '98.3100',
      close: '99.9400',
      'adjusted close': '53.9774',
      volume: '22641900',
      'dividend amount': '0.0000',
    },
    '2000-11-17': {
      open: '91.8100',
      high: '102.5000',
      low: '91.6200',
      close: '101.9400',
      'adjusted close': '55.0576',
      volume: '29453200',
      'dividend amount': '0.0000',
    },
    '2000-11-10': {
      open: '99.8700',
      high: '103.7500',
      low: '93.0000',
      close: '93.0000',
      'adjusted close': '50.2291',
      volume: '32715700',
      'dividend amount': '0.1300',
    },
    '2000-11-03': {
      open: '93.5000',
      high: '102.6200',
      low: '90.3100',
      close: '100.1200',
      'adjusted close': '54.0044',
      volume: '39746400',
      'dividend amount': '0.0000',
    },
    '2000-10-27': {
      open: '94.7500',
      high: '95.0000',
      low: '86.9400',
      close: '93.6900',
      'adjusted close': '50.5360',
      volume: '48790400',
      'dividend amount': '0.0000',
    },
    '2000-10-20': {
      open: '111.5000',
      high: '113.8700',
      low: '90.2500',
      close: '94.7500',
      'adjusted close': '51.1078',
      volume: '73706800',
      'dividend amount': '0.0000',
    },
    '2000-10-13': {
      open: '115.9400',
      high: '119.3700',
      low: '103.1200',
      close: '109.0600',
      'adjusted close': '58.8266',
      volume: '38085500',
      'dividend amount': '0.0000',
    },
    '2000-10-06': {
      open: '112.6200',
      high: '119.6200',
      low: '108.5600',
      close: '116.0000',
      'adjusted close': '62.5700',
      volume: '43940700',
      'dividend amount': '0.0000',
    },
    '2000-09-29': {
      open: '124.5000',
      high: '126.6200',
      low: '110.2500',
      close: '112.6200',
      'adjusted close': '60.7468',
      volume: '31360800',
      'dividend amount': '0.0000',
    },
    '2000-09-22': {
      open: '125.2500',
      high: '126.6900',
      low: '120.0000',
      close: '123.8700',
      'adjusted close': '66.8150',
      volume: '22129500',
      'dividend amount': '0.0000',
    },
    '2000-09-15': {
      open: '127.8700',
      high: '129.1900',
      low: '122.7500',
      close: '125.0000',
      'adjusted close': '67.4246',
      volume: '28000200',
      'dividend amount': '0.0000',
    },
    '2000-09-08': {
      open: '132.8100',
      high: '134.8800',
      low: '129.3800',
      close: '129.5000',
      'adjusted close': '69.8518',
      volume: '20201600',
      'dividend amount': '0.0000',
    },
    '2000-09-01': {
      open: '129.8800',
      high: '134.9400',
      low: '129.5600',
      close: '133.6300',
      'adjusted close': '72.0795',
      volume: '30751200',
      'dividend amount': '0.0000',
    },
    '2000-08-25': {
      open: '121.0600',
      high: '129.8800',
      low: '119.8700',
      close: '129.0000',
      'adjusted close': '69.5821',
      volume: '24522100',
      'dividend amount': '0.0000',
    },
    '2000-08-18': {
      open: '120.0000',
      high: '123.6900',
      low: '119.3100',
      close: '120.4400',
      'adjusted close': '64.9649',
      volume: '23739400',
      'dividend amount': '0.0000',
    },
    '2000-08-11': {
      open: '116.6200',
      high: '121.8100',
      low: '115.5600',
      close: '120.6200',
      'adjusted close': '65.0620',
      volume: '29859300',
      'dividend amount': '0.1300',
    },
    '2000-08-04': {
      open: '110.5000',
      high: '116.5000',
      low: '110.0600',
      close: '115.8700',
      'adjusted close': '62.4316',
      volume: '24015800',
      'dividend amount': '0.0000',
    },
    '2000-07-28': {
      open: '114.1200',
      high: '115.6200',
      low: '108.8700',
      close: '111.8100',
      'adjusted close': '60.2440',
      volume: '28454700',
      'dividend amount': '0.0000',
    },
    '2000-07-21': {
      open: '104.4400',
      high: '117.8100',
      low: '101.0000',
      close: '114.7500',
      'adjusted close': '61.8281',
      volume: '52881600',
      'dividend amount': '0.0000',
    },
    '2000-07-14': {
      open: '104.6900',
      high: '105.9400',
      low: '101.0000',
      close: '103.9400',
      'adjusted close': '56.0036',
      volume: '25072200',
      'dividend amount': '0.0000',
    },
    '2000-07-07': {
      open: '108.7500',
      high: '109.8700',
      low: '100.0000',
      close: '105.0600',
      'adjusted close': '56.6071',
      volume: '38608400',
      'dividend amount': '0.0000',
    },
    '2000-06-30': {
      open: '113.0000',
      high: '115.7500',
      low: '108.6200',
      close: '109.5600',
      'adjusted close': '59.0317',
      volume: '32971500',
      'dividend amount': '0.0000',
    },
    '2000-06-23': {
      open: '113.5000',
      high: '120.6900',
      low: '110.8100',
      close: '111.8700',
      'adjusted close': '60.2764',
      volume: '27864000',
      'dividend amount': '0.0000',
    },
    '2000-06-16': {
      open: '119.1200',
      high: '120.4400',
      low: '113.2500',
      close: '113.2500',
      'adjusted close': '61.0199',
      volume: '26364000',
      'dividend amount': '0.0000',
    },
    '2000-06-09': {
      open: '108.4400',
      high: '122.3100',
      low: '108.4400',
      close: '119.6900',
      'adjusted close': '64.4898',
      volume: '37831000',
      'dividend amount': '0.0000',
    },
    '2000-06-02': {
      open: '107.7500',
      high: '111.0000',
      low: '105.5000',
      close: '108.8100',
      'adjusted close': '58.6276',
      volume: '18535800',
      'dividend amount': '0.0000',
    },
    '2000-05-26': {
      open: '106.0000',
      high: '112.2500',
      low: '103.4400',
      close: '106.9400',
      'adjusted close': '57.6200',
      volume: '31197000',
      'dividend amount': '0.0000',
    },
    '2000-05-19': {
      open: '104.0000',
      high: '109.2500',
      low: '102.0000',
      close: '106.4400',
      'adjusted close': '57.3506',
      volume: '29694500',
      'dividend amount': '0.0000',
    },
    '2000-05-12': {
      open: '108.0600',
      high: '110.6200',
      low: '102.0600',
      close: '104.4400',
      'adjusted close': '56.2730',
      volume: '33410400',
      'dividend amount': '0.1300',
    },
    '2000-05-05': {
      open: '112.5000',
      high: '113.6200',
      low: '106.5000',
      close: '107.8700',
      'adjusted close': '58.0524',
      volume: '22151300',
      'dividend amount': '0.0000',
    },
    '2000-04-28': {
      open: '102.0000',
      high: '113.9400',
      low: '101.2500',
      close: '111.5000',
      'adjusted close': '60.0059',
      volume: '35488400',
      'dividend amount': '0.0000',
    },
    '2000-04-20': {
      open: '103.8700',
      high: '115.1200',
      low: '102.0600',
      close: '104.0000',
      'adjusted close': '55.9696',
      volume: '43482800',
      'dividend amount': '0.0000',
    },
    '2000-04-14': {
      open: '124.7500',
      high: '125.3700',
      low: '102.8700',
      close: '105.0000',
      'adjusted close': '56.5078',
      volume: '42149600',
      'dividend amount': '0.0000',
    },
    '2000-04-07': {
      open: '120.0000',
      high: '128.0000',
      low: '115.0600',
      close: '123.1200',
      'adjusted close': '66.2595',
      volume: '47344000',
      'dividend amount': '0.0000',
    },
    '2000-03-31': {
      open: '125.0000',
      high: '128.2500',
      low: '117.0600',
      close: '118.3700',
      'adjusted close': '63.7031',
      volume: '44498000',
      'dividend amount': '0.0000',
    },
    '2000-03-24': {
      open: '110.0000',
      high: '122.2500',
      low: '109.9400',
      close: '120.6200',
      'adjusted close': '64.9140',
      volume: '38090900',
      'dividend amount': '0.0000',
    },
    '2000-03-17': {
      open: '104.0000',
      high: '111.6900',
      low: '102.5000',
      close: '110.0000',
      'adjusted close': '59.1987',
      volume: '36990400',
      'dividend amount': '0.0000',
    },
    '2000-03-10': {
      open: '109.9400',
      high: '111.0000',
      low: '101.0000',
      close: '105.2500',
      'adjusted close': '56.6424',
      volume: '42586200',
      'dividend amount': '0.0000',
    },
    '2000-03-03': {
      open: '104.6200',
      high: '110.0000',
      low: '99.5000',
      close: '108.0000',
      'adjusted close': '58.1223',
      volume: '51128300',
      'dividend amount': '0.0000',
    },
    '2000-02-25': {
      open: '112.0000',
      high: '113.4400',
      low: '104.9400',
      close: '108.0000',
      'adjusted close': '58.1223',
      volume: '34514300',
      'dividend amount': '0.0000',
    },
    '2000-02-18': {
      open: '116.0000',
      high: '118.8700',
      low: '111.5000',
      close: '112.5000',
      'adjusted close': '60.5441',
      volume: '26599300',
      'dividend amount': '0.0000',
    },
    '2000-02-11': {
      open: '116.0000',
      high: '119.7500',
      low: '113.1200',
      close: '115.3700',
      'adjusted close': '62.0886',
      volume: '26814500',
      'dividend amount': '0.1200',
    },
    '2000-02-04': {
      open: '111.3700',
      high: '118.4400',
      low: '109.1200',
      close: '115.6200',
      'adjusted close': '62.1604',
      volume: '32834200',
      'dividend amount': '0.0000',
    },
    '2000-01-28': {
      open: '121.8700',
      high: '122.8700',
      low: '110.0600',
      close: '111.5600',
      'adjusted close': '59.9776',
      volume: '33325200',
      'dividend amount': '0.0000',
    },
    '2000-01-21': {
      open: '119.6900',
      high: '124.7500',
      low: '112.6900',
      close: '121.5000',
      'adjusted close': '65.3216',
      volume: '41930500',
      'dividend amount': '0.0000',
    },
    '2000-01-14': {
      open: '117.2500',
      high: '123.3100',
      low: '115.3700',
      close: '119.6200',
      'adjusted close': '64.3109',
      volume: '42663900',
      'dividend amount': '0.0000',
    },
    '2000-01-07': {
      open: '112.4400',
      high: '119.7500',
      low: '110.6200',
      close: '113.5000',
      'adjusted close': '61.0206',
      volume: '51137300',
      'dividend amount': '0.0000',
    },
    '1999-12-31': {
      open: '109.6900',
      high: '110.7500',
      low: '106.6200',
      close: '107.8700',
      'adjusted close': '57.9938',
      volume: '16812500',
      'dividend amount': '0.0000',
    },
    '1999-12-23': {
      open: '109.0600',
      high: '110.4400',
      low: '107.7500',
      close: '108.6200',
      'adjusted close': '58.3970',
      volume: '18144100',
      'dividend amount': '0.0000',
    },
    '1999-12-17': {
      open: '108.1200',
      high: '112.7500',
      low: '104.5000',
      close: '110.0000',
      'adjusted close': '59.1389',
      volume: '38810100',
      'dividend amount': '0.0000',
    },
    '1999-12-10': {
      open: '113.0000',
      high: '122.1200',
      low: '107.5600',
      close: '109.0000',
      'adjusted close': '58.6013',
      volume: '58626000',
      'dividend amount': '0.0000',
    },
    '1999-12-03': {
      open: '104.9400',
      high: '112.8700',
      low: '102.1200',
      close: '111.8700',
      'adjusted close': '60.1443',
      volume: '37670000',
      'dividend amount': '0.0000',
    },
    '1999-11-26': {
      open: '105.5000',
      high: '109.8700',
      low: '101.8100',
      close: '105.0000',
      'adjusted close': '56.4508',
      volume: '37165600',
      'dividend amount': '0.0000',
    },
    '1999-11-19': {
      open: '96.0000',
      high: '105.1200',
      low: '92.6200',
      close: '103.9400',
      'adjusted close': '55.8809',
      volume: '61550800',
      'dividend amount': '0.0000',
    },
    '1999-11-12': {
      open: '90.5000',
      high: '97.5000',
      low: '90.5000',
      close: '95.8700',
      'adjusted close': '51.5423',
      volume: '43569700',
      'dividend amount': '0.1200',
    },
  },
};
