import { useState, useEffect } from 'react';
import {
  GetNewsReqParamsModel,
  GetNewsResponseModel,
  NewsItemModel,
} from '../models';
import { getNewsReq } from '../api';

export function useNews() {
  const [news, setNews] = useState<NewsItemModel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [newsTimeScope, setNewsTimeScope] = useState('hourly');

  useEffect(() => {
    if (searchTerm || newsTimeScope) {
      const fetchNews = async () => {
        const reqParams: GetNewsReqParamsModel = {
          pageNum: 0,
          pageSize: 5,
          sortBy: 'datetime',
          sortOrder: 'ascending',
          newsTimeScope: newsTimeScope ? newsTimeScope : undefined,
          searchTerm: searchTerm ? searchTerm : undefined,
        };
        try {
          const response: GetNewsResponseModel = await getNewsReq(reqParams);
          setNews(response.news);
        } catch (err) {
          console.error('Failed to fetch news');
        }
      };
      fetchNews();
    }
  }, [searchTerm, newsTimeScope]);

  return { news, setSearchTerm, setNewsTimeScope };
}
