export const earningsNewsData = {
  news: [
    {
      title: 'Rivian options buyers may be helping drive stock higher',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Neutral',
      datetime: '2023-07-10T12:00:00Z',
      content:
        "A team of scientists has discovered a new species of orchid in the Amazon rainforest. The orchid, named 'Amazonia Spectabilis', features vibrant pink petals and a unique fragrance. It was found during an expedition to a remote area of the rainforest. The discovery is significant as it adds to the rich biodiversity of the Amazon region. Scientists believe that this new species could provide valuable insights into the evolution and adaptation of orchids in this unique ecosystem. Efforts are now underway to protect the habitat of 'Amazonia Spectabilis' and further study its ecological role.",
    },
  ],
};
