import { NewsItemModel } from '../../common/models';
import { SingleNews } from '../../common/widgets';
interface SearchResultsPagePropsModel {
  results: NewsItemModel[];
}

const NewsSearchPage: React.FC<SearchResultsPagePropsModel> = ({ results }) => {
  return (
    <div style={{ backgroundColor: '#23232b' }}>
      {results.map((news, index) => (
        <SingleNews key={index} news={news} />
      ))}
    </div>
  );
};

export default NewsSearchPage;
