import { NewsItemModelVersion1 } from '../models';
import { NewsTags, NewsSentiment } from '.';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import { TEXT_COLOR, LEFT_FONT_BG, BACKGROUND } from '../styles';
export interface SingleNewsPropsModelVersion1 {
  news: NewsItemModelVersion1;
}

const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: TEXT_COLOR,
  '&:hover': {
    color: LEFT_FONT_BG,
  },
});

const SingleNewsUrl: React.FC<SingleNewsPropsModelVersion1> = ({ news }) => {
  return (
    <div className="article" style={{ width: '100%', color: TEXT_COLOR }}>
      <StyledLink href={news.url} target="_blank" rel="noopener noreferrer">
        <h2>
          {news.title.length > 150
            ? news.title.slice(0, 150) + '...'
            : news.title}
        </h2>

        {/* <h2>{news.title.slice(0, 150)}</h2> */}
      </StyledLink>
      {/* <h2>{news.title}</h2> */}
      {news.ai_summary_en !== 'n/a' && (
        <p style={{ fontSize: '18px' }}>{news.ai_summary_en.slice(0, 400)}</p>
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <NewsSentiment label={news.overall_sentiment_label} />
        </Grid>
        <Grid item xs>
          <NewsTags tags={news.topics.map((topic) => topic.name)} />
        </Grid>
      </Grid>
    </div>
  );
};

// Migrate this AI Summary News React.FC to a new file because it's a different widget
const RelatedNewsItem: React.FC<SingleNewsPropsModelVersion1> = ({ news }) => {
  const formatDate = (dateString: string): string => {
    return dateString.split('T')[0];
  };
  // const linkStyle = {
  //   textDecoration: 'none', // 设置文本装饰为"none"以去除下划线
  //   color: 'inherit', // 继承默认文本颜色
  //   '&:hover': {
  //     color: HOVER_NAV_BUTTON,
  //   },
  // };
  return (
    <div
      className="article"
      style={{
        marginLeft: '25px',
        width: '90%',
        color: TEXT_COLOR,
        // marginBottom: '2px',
      }}
    >
      <StyledLink
        // component="a"
        href={news.url}
        target="_blank"
        rel="noopener"
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={2}>
            <p>{formatDate(news.published_time)}</p>
          </Grid>
          <Grid item xs={10}>
            <p>{news.ai_summary_en.slice(0, 400)}</p>
          </Grid>
        </Grid>
      </StyledLink>
    </div>
  );
};

export { RelatedNewsItem, SingleNewsUrl };
