import React from 'react';
import { AppBar, Toolbar, Typography, Grid } from '@mui/material';
import { NewsTags } from '../../widgets';
import { NewsNavBarPropsModel } from '../../models';
import { TOP_COLUMN, BACKGROUND } from '../../styles';
const NewsNavBar: React.FC<NewsNavBarPropsModel> = ({
  navButtons,
  searchBar,
  newsButton,
  tags,
  onTagClick,
  header,
  position = 'static',
  height,
  colorNavBar,
}) => {
  const hasButtons = !!(navButtons || newsButton || (tags && tags.length));

  return (
    <div className="News Nav Bar">
      {' '}
      <AppBar
        position={position}
        sx={{
          backgroundColor: colorNavBar || TOP_COLUMN,
          color: '#fafeff',
          padding: 0,
          justifyContent: 'center',
        }}
      >
        <Toolbar style={{ minHeight: height || '50px' }}>
          <Grid container alignItems="center">
            <Grid item xs>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: hasButtons ? 'flex-start' : 'center',
                }}
              >
                {header && (
                  <Typography
                    variant="h6"
                    marginRight={hasButtons ? 6 : 0}
                    marginLeft={hasButtons ? 2 : 0}
                    alignItems="center"
                    display={'inline'}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {header}
                  </Typography>
                )}

                {hasButtons && (
                  <>
                    {navButtons}
                    {newsButton}
                    {tags && <NewsTags tags={tags} onTagClick={onTagClick} />}
                  </>
                )}
              </div>
            </Grid>
            {searchBar && <Grid item>{searchBar}</Grid>}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NewsNavBar;
