import Stack from '@mui/material/Stack';
import { LeftNavBarButton } from '../../widgets';
import { useTranslation } from 'react-i18next';

const LeftNavBar = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={0} direction="column" data-testid="left-navbar">
      <LeftNavBarButton to="/">{t('left_navbar.home')}</LeftNavBarButton>
      {/* <LeftNavBarButton to="/stock_list">
        {t('left_navbar.stock_list')}
      </LeftNavBarButton> */}
      <LeftNavBarButton to="/news">{t('left_navbar.news')}</LeftNavBarButton>
      {/* <LeftNavBarButton to="/">
        {t('left_navbar.industry_sectors')}
      </LeftNavBarButton> */}
      <LeftNavBarButton to="/my_account">
        {t('left_navbar.my_account')}
      </LeftNavBarButton>
    </Stack>
  );
};
export default LeftNavBar;
