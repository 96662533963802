export const treeMapData = {
  data: [
    { company: 'Company A', priceChange: -6.12, turnover: 5.42 },
    { company: 'Company B', priceChange: 9.47, turnover: 9.25 },
    { company: 'Company C', priceChange: -8.76, turnover: 12.1 },
    { company: 'Company D', priceChange: 2.81, turnover: 3.15 },
    { company: 'Company E', priceChange: -0.57, turnover: 0.05 },
    { company: 'Company F', priceChange: 6.92, turnover: 7.5 },
    { company: 'Company G', priceChange: -3.25, turnover: 1.2 },
    { company: 'Company H', priceChange: -4.63, turnover: 2.9 },
    { company: 'Company I', priceChange: 7.88, turnover: 8.7 },
    { company: 'Company J', priceChange: 1.03, turnover: 4.6 },
    { company: 'Company K', priceChange: -9.91, turnover: 0.05 },
    { company: 'Company L', priceChange: 0.85, turnover: 5.8 },
    { company: 'Company M', priceChange: -1.24, turnover: 0.05 },
    { company: 'Company N', priceChange: 5.37, turnover: 9.9 },
    { company: 'Company O', priceChange: -5.79, turnover: 0.05 },
    { company: 'Company P', priceChange: 3.97, turnover: 6.2 },
    { company: 'Company Q', priceChange: -7.55, turnover: 0.05 },
    { company: 'Company R', priceChange: 9.22, turnover: 10.4 },
    { company: 'Company S', priceChange: -4.19, turnover: 2.5 },
    { company: 'Company T', priceChange: -1.67, turnover: 2.0 },
    { company: 'Company U', priceChange: 2.51, turnover: 3.7 },
    { company: 'Company V', priceChange: -0.36, turnover: 0.36 },
    { company: 'Company W', priceChange: -7.89, turnover: 0.05 },
    { company: 'Company X', priceChange: 8.11, turnover: 10.8 },
    { company: 'Company Y', priceChange: -3.75, turnover: 1.8 },
    { company: 'Company Z', priceChange: 0.94, turnover: 0.94 },
  ],
};
