import { FC } from 'react';
import {
  TimedNews,
  NewsDetailedList,
  RankedNews,
} from '../../common/components';
import { Grid } from '@mui/material';

const NewsFinPage: FC = () => {
  return (
    <div
      className="news-fin-page"
      style={{
        backgroundColor: '#23232b',
        height: '100vh',
        overflow: 'hidden',
        margin: 0,
      }}
    >
      <p style={{ height: '1px' }}></p>
      <Grid
        container
        direction="row"
        // justifyContent="center"
        // alignItems="center"

        style={{ height: '100%', overflow: 'hidden', margin: 0 }}
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={7}
          sx={{
            margin: 0,
            overflowY: 'auto',
            maxHeight: '100%',
            '&::-webkit-scrollbar': {
              width: '5px',
              backgroundColor: 'transparent', // Default track to transparent
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 5px transparent', // Default track shadow to transparent
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'transparent', // Default thumb to transparent
            },
            '&:hover::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 5px grey', // Visible track shadow on hover
            },
            '&:hover::-webkit-scrollbar-thumb': {
              background: '#888', // Visible thumb on hover
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555',
            },
          }}
        >
          <NewsDetailedList />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          style={{ overflowY: 'auto', maxHeight: '100%' }}
        >
          <div style={{ maxHeight: '50%', overflowY: 'auto' }}>
            <TimedNews height={400} />
          </div>
          <div style={{ maxHeight: '50%', overflowY: 'auto' }}>
            <RankedNews />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewsFinPage;
