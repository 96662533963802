import ReactFlow, { useNodesState, useEdgesState } from 'reactflow';
import dagre from 'dagre';
import { initialNodes, initialEdges } from './nodes-edges';
import 'reactflow/dist/style.css';
import './style-override.css';

const NODE_CONTAINER_WIDTH = 200;
const NODE_CONTAINER_HEIGHT = 100;

const getLayoutedElements = (nodes: any, edges: any) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const direction = 'TB'; // "LR" for horizontal
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, {
      width: NODE_CONTAINER_WIDTH,
      height: NODE_CONTAINER_HEIGHT,
    });
  });
  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - NODE_CONTAINER_WIDTH / 2,
      y: nodeWithPosition.y - NODE_CONTAINER_HEIGHT / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges,
);

const TreeDiagram = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodesDraggable={false}
      edgesUpdatable={false}
      nodesConnectable={false}
      nodesFocusable={false}
      edgesFocusable={false}
      elementsSelectable={false}
      panOnDrag={false}
      zoomOnScroll={false}
      fitView
    />
  );
};

export default TreeDiagram;
