import { NewsItemModelVersion1 } from '../models';
import Link from '@mui/material/Link';
import { styled } from '@mui/system';
import { TEXT_COLOR, LEFT_FONT_BG, BACKGROUND, BLACK } from '../styles';

export interface SingleNewsPropsModelVersion1 {
  news: NewsItemModelVersion1;
}

const StyledLink = styled(Link)({
  textDecoration: 'none',
  cursor: 'pointer',
  color: TEXT_COLOR,
  '&:hover': {
    backgroundColor: LEFT_FONT_BG,
  },
});

const CompanyNewsHeadline: React.FC<SingleNewsPropsModelVersion1> = ({
  news,
}) => {
  return (
    <li>
      <StyledLink href={news.url} style={{ color: TEXT_COLOR }}>
        {news.title}
      </StyledLink>
    </li>
  );
};

export default CompanyNewsHeadline;
