import {
  GetNewsReqParamsModelVersion1,
  GetNewsResponseModelVersion1,
} from '../models';

export const getNewsReqVersion1 = async (
  reqParam: GetNewsReqParamsModelVersion1,
): Promise<GetNewsResponseModelVersion1> => {
  const { newsTag, page, pageSize } = reqParam;

  let url;
  if (newsTag === 'all') {
    url = `${process.env.REACT_APP_BACKEND_URL}/api/news/${newsTag}?&page=${page}&pageSize=${pageSize}`;
  } else {
    url = `${process.env.REACT_APP_BACKEND_URL}/api/news/topic/${newsTag}?&page=${page}&pageSize=${pageSize}`;
  }
  const response = await fetch(
    url,
    // `${process.env.REACT_APP_BACKEND_URL}/api/news/topic/${newsTag}?&page=${page}&pageSize=${pageSize}`,
  );

  if (!response.ok) {
    throw new Error('Failed to get news');
  }
  const jsonData = await response.json();

  return jsonData;
};

export const getNewsByCompanyReq = async (
  reqParam: GetNewsReqParamsModelVersion1,
): Promise<GetNewsResponseModelVersion1> => {
  const { company, page, pageSize } = reqParam;

  let url;
  if (company === 'all') {
    url = `${process.env.REACT_APP_BACKEND_URL}/api/news/${company}?&page=${page}&pageSize=${pageSize}`;
  } else {
    url = `${process.env.REACT_APP_BACKEND_URL}/api/news/company/${company}?&page=${page}&pageSize=${pageSize}`;
  }
  const response = await fetch(
    url,
    // `${process.env.REACT_APP_BACKEND_URL}/api/news/topic/${newsTag}?&page=${page}&pageSize=${pageSize}`,
  );

  if (!response.ok) {
    throw new Error('Failed to get news');
  }
  const jsonData = await response.json();

  return jsonData;
};
