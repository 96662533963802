import { NewsItemModel } from '../models';
import { RelatedNewsItem } from '.';
import { useEffect } from 'react';
import { getNewsDataByCompanyThunk } from '../redux_features/thunks';
import { useAppDispatch, useAppSelector } from '../redux_features/hooks';
import { BACKGROUND } from '../styles';

const RelatedNewsList: React.FC<{ company_symbol: string }> = ({
  company_symbol,
}) => {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => state.ui.news);
  const status = useAppSelector((state) => state.ui.news.status);

  useEffect(() => {
    dispatch(
      getNewsDataByCompanyThunk({
        companySymbol: company_symbol,
        page: '1',
        pageSize: '8',
      }),
    );
  }, [company_symbol, dispatch]);
  console.log(news);
  return (
    <div style={{ backgroundColor: BACKGROUND }}>
      {news.newsData.map((newsItem, index) => (
        <RelatedNewsItem key={index} news={newsItem} />
      ))}
    </div>
  );
};

export default RelatedNewsList;
