// Refer to: https://redux.js.org/tutorials/typescript-quick-start/
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { uiSlice } from './slices';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
};
const combinedReducers = combineReducers({ ui: uiSlice.reducer });
type RootReducer = ReturnType<typeof combinedReducers>;
const persistedReducer = persistReducer<RootReducer>(
  persistConfig,
  combinedReducers,
);

// Initialize Redux Store
export const store = configureStore({
  reducer: persistedReducer,
  // 默认自带了几个中间件，但如果要添加额外的中间件则必须手动处理getDefaultMiddleware
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export const persistor = persistStore(store);

// 从 store 本身推断出 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>;
// 推断出类型: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
