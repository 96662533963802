import { NewsItemModel } from '../models';
import { CompanyNewsHeadline } from '.';
import { useEffect } from 'react';
import { getNewsDataByCompanyThunk } from '../redux_features/thunks';
import { useAppDispatch, useAppSelector } from '../redux_features/hooks';
import { BACKGROUND } from '../styles';

const CompanyNewsHeadlineList: React.FC<{ company_symbol: string }> = ({
  company_symbol,
}) => {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => state.ui.news);

  useEffect(() => {
    dispatch(
      getNewsDataByCompanyThunk({
        companySymbol: company_symbol,
        page: '1',
        pageSize: '5',
      }),
    );
  }, [company_symbol, dispatch]);

  return (
    <ul>
      {news.newsData.map((newsItem, index) => (
        <CompanyNewsHeadline key={index} news={newsItem} />
      ))}
    </ul>
  );
};

export default CompanyNewsHeadlineList;
