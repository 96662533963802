//中性颜色
export const WHITE = '#fff';
export const GRAY_100 = '#f8f9fa';
export const GRAY_200 = '#e9ecef';
export const GRAY_300 = '#dee2e6';
export const GRAY_400 = '#ced4da';
export const GRAY_500 = '#adb5bd';
export const GRAY_600 = '#6c757d';
export const GRAY_700 = '#495057';
export const GRAY_800 = '#343a40';
export const GRAY_900 = '#212529';
export const BLACK = '#000';

export const BLUE = '#0d6efd';
export const INDIGO = '#6610f2';
export const PURPLE = '#6f42c1';
export const PINK = '#d63384';
export const RED = '#dc3545';
export const ORANGE = '#fd7e14';
export const YELLOW = '#fadb14';
export const GREEN = '#52c41a';
export const TEAL = '#20c997';
export const CYAN = '#17a2b8';

export const PRIMARY = BLUE;
export const SECONDARY = GRAY_600;
export const SUCCESS = GREEN;
export const INFO = CYAN;
export const WARNING = YELLOW;
export const DANGER = RED;
export const LIGHT = GRAY_100;
export const DARK = GRAY_800;

//字体颜色
export const TEXT_COLOR = '#FEFEFB';

//大背景颜色
export const BACKGROUND = '#0b0d1a';
//左边的状态栏
export const LEFT_FONT_NAME = GRAY_700;
//正在被选择的按钮颜
export const LEFT_FONT_BG = '#3c5086';
//正在被选择的按钮字体颜色
export const LEFT_SELECT_FONT_BG = '#ffffff';

//最顶上的栏
export const TOP_COLUMN = '#23232b';
//中间选择栏的字体名
export const MID_FONT_NAME = '#fafeff';
//中间框stroke颜色
export const MID_STROKE = '#616470';
//中间框背景色
export const MID_BACKGROUND = '#606470';

export const HOVER_NAV_BUTTON = '#61717D';
//MA系列
export const MA5 = '#f6f8fb';
export const MA10 = '#faf85a';
export const MA20 = '#df0fd9';
export const MA30 = '#6ee439';
export const MA60 = '#c0c2c7';
export const MA150 = '#57d3e5';
export const MA200 = '#f9fdaf';

//股票颜色
export const UP_COLOR = '#33E1F6';
export const DOWN_COLOR = '#FF4F5B';
