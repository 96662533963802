import { useParams } from 'react-router-dom';
import { NavButton, SearchBar } from '../../common/widgets';
import { NewsNavBar } from '../../common/components';
import { NewsFinPage, NewsSearchPage } from '.';
import { useNews } from '../../common/hooks/useNews';
// import { Box } from '@mui/material';

const NewsMain: React.FC = () => {
  const { subpage } = useParams();
  const { news, setSearchTerm } = useNews();

  return (
    <div>
      <NewsNavBar
        height={60}
        navButtons={
          <>
            <NavButton to="/news/financial_news">Financial News</NavButton>
            {/* <NavButton to="/news/event_drive"> Event Drive</NavButton> */}
          </>
        }
        // searchBar={<SearchBar />}
        searchBar={<SearchBar onSearch={setSearchTerm} />}
      />

      {subpage === undefined && <NewsFinPage />}
      {subpage === 'financial_news' && <NewsFinPage />}
      {/* {subpage === 'event_drive' && <NewsEventPage />} */}
      {subpage === 'search_results' && <NewsSearchPage results={news} />}
    </div>
  );
};

export default NewsMain;
