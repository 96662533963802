import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { StockListTabsAndRightSidebar } from '../../common/components';

const WatchlistPage: React.FC = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Grid container height="100%" style={{ overflow: 'auto' }}>
        <Grid item xs={6}>
          <Box sx={{ width: '200%' }}>
            {/* 横向的长内容 */}
            <StockListTabsAndRightSidebar />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WatchlistPage;
