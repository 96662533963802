import React from 'react';
import ReactECharts from 'echarts-for-react';

import { TEXT_COLOR, UP_COLOR, DOWN_COLOR } from '../../styles';

interface EPSBarChartProps {
  data: {
    publish_time: string;
    eps: number;
  }[];
}

const EPSBarChart: React.FC<EPSBarChartProps> = ({ data }) => {
  // Calculate growth rate
  const growthRates = data.map((item, index) => {
    if (index === 0) return null;

    const prev = data[index - 1].eps;
    if (prev === 0) return null;

    const current = item.eps;
    const growthRate = ((current - prev) / Math.abs(prev)) * 100;

    return parseFloat(growthRate.toFixed(2));
  });

  // Externalizing series config
  const seriesConfig = [
    {
      name: '• EPS',
      type: 'bar',
      data: data.map((item) => item.eps),
      itemStyle: {
        color: (params: any) => {
          return params.value >= 0 ? UP_COLOR : DOWN_COLOR;
        },
      },
    },
    {
      name: '• Growth Rate',
      type: 'line',
      yAxisIndex: 1,
      data: growthRates,
      lineStyle: {
        color: TEXT_COLOR,
      },
      symbol: 'circle',
      symbolSize: 5,
      itemStyle: {
        color: TEXT_COLOR,
      },
    },
  ];

  const option = {
    title: {
      text: 'EPS & Growth Rate',
      left: 'center',
      top: 0,
      textStyle: {
        color: TEXT_COLOR,
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any[]) {
        let tooltipContent = '';

        params.forEach((param) => {
          tooltipContent += `${param.seriesName}: ${param.value}`;
          tooltipContent += '<br/>';
        });

        return tooltipContent;
      },
    },
    xAxis: {
      data: data.map((item) => item.publish_time),
      axisLabel: {
        interval: 0,
        rotate: 45,
      },
    },
    yAxis: [
      {
        type: 'value',
        name: 'EPS', // Title for the first y-axis
        nameTextStyle: {
          color: '',
        },
        axisLine: {
          lineStyle: {
            color: TEXT_COLOR,
          },
        },
      },
      {
        type: 'value',
        position: 'right',
        name: '%', // Title for the second y-axis
        nameTextStyle: {
          color: TEXT_COLOR,
        },
        axisLine: {
          lineStyle: {
            color: TEXT_COLOR,
          },
        },
      },
    ],

    grid: {
      top: '10%',
      bottom: '25%',
      left: '10%',
      right: '10%',
    },
    series: seriesConfig,
  };

  return <ReactECharts option={option} style={{ height: '100%' }} />;
};

export default EPSBarChart;
