import { FC, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import RelatedNewsList from '../../common/widgets/RelatedNewsPage';
import InsiderTrading from '../../common/widgets/Insidertrading';
import { Background } from 'reactflow';
import { BACKGROUND } from '../../common/styles';

type StockDetailParams = {
  stockSymbol: string;
};

const CompanyDetailedPage: FC = () => {
  const params = useParams() as unknown as StockDetailParams;
  const { stockSymbol } = params;

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    // 父容器
    <Box style={{ position: 'relative', height: '100%' /*或其他适当高度*/ }}>
      {/* 固定的Tabs */}
      <Box
        style={{
          position: 'absolute',
          top: 0,
          width: '100%',
          backgroundColor: BACKGROUND,
        }}
      >
        <Tabs value={selectedTab} onChange={handleChange} textColor="inherit">
          <Tab label="AI News" />
          <Tab label="AI Announcement" />
        </Tabs>
      </Box>
      {/* 可滚动的内容 */}
      <Box
        style={{
          paddingTop: '48px' /*或Tabs的实际高度*/,
          overflowY: 'auto',
          height: '100%',
        }}
      >
        {selectedTab === 0 && <RelatedNewsList company_symbol={stockSymbol} />}
        {selectedTab === 1 && <InsiderTrading company_symbol={stockSymbol} />}
      </Box>
    </Box>
  );
};

export default CompanyDetailedPage;
