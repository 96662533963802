import {
  CompanyInfoModel,
  CompanyInfosResponseModel,
  GetCompanyInfosReqParamsModel,
  GetCompanyParamsModel,
  GetCompanyResponseModel,
} from '../models';

export const getCompanyDataReq = async (
  reqParam: GetCompanyParamsModel,
): Promise<GetCompanyResponseModel> => {
  const { company_symbol } = reqParam;
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/company_and_peer_quote/${company_symbol}`,
  );

  if (!response.ok) {
    throw new Error('Failed to get company data,');
  }
  const jsonData = await response.json();
  return jsonData;
};

export const getCompanyInfos = async (
  reqParam: GetCompanyInfosReqParamsModel,
): Promise<CompanyInfosResponseModel> => {
  const symbolList = reqParam;

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/company_infos`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ companySymbols: symbolList }),
    },
  );

  if (!response.ok) {
    throw new Error(
      `Failed to fetch from ${process.env.REACT_APP_BACKEND_URL}/api/companies/company_infos`,
    );
  }
  const jsonData: CompanyInfosResponseModel = await response.json();
  return jsonData;
};

// export const getSymbolList =
//   async (): Promise<GetCompanyInfosReqParamsModel> => {
//     const symbolList = await fetch(
//       `${process.env.REACT_APP_BACKEND_URL}/api/companies/all_symbol`,
//       {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       },
//     );
//     // TODO: Pagination not done for symbolList
//     const listData = ['GOOG', 'TSLA', 'MSFT'];
//     // const listData: GetCompanyInfosReqParamsModel = await symbolList.json();
//     return listData;
//   };

export const getSymbolList = async (
  page: number = 0,
  pageSize: number = 50,
  getAll: string | null = null,
): Promise<GetCompanyInfosReqParamsModel> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/companies/all_symbol?page=${page}&pageSize=${pageSize}&getAll=${getAll}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error('Failed to fetch symbol list');
    }

    const responseData = await response.json();
    const listData: GetCompanyInfosReqParamsModel = responseData.data;

    return listData;
  } catch (error) {
    console.error(`Error fetching symbol list: ${error}`);
    return [];
  }
};

export const getCompanyDetail = async (
  reqParam: string,
): Promise<CompanyInfoModel> => {
  const symbolList = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/company_infos/${reqParam}`,

    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const companyInformationData: CompanyInfoModel = await symbolList.json();

  return companyInformationData;
};

export const getCompanyTitle = async (
  reqParam: string,
): Promise<CompanyInfoModel> => {
  const symbolList = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/companies/company_infos/${reqParam}`,

    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  const companyTitleData: CompanyInfoModel = await symbolList.json();

  return companyTitleData;
};
