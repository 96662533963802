import { TextField } from '@mui/material';
import { SearchButton } from '.';
import { useNavigate } from 'react-router-dom';
import { SearchBarPropsModel } from '../models/widgets/search-bar';
import { useAppDispatch, useAppSelector } from '../redux_features/hooks';
import { uiSlice } from '../redux_features/slices';

const SearchBar: React.FC<SearchBarPropsModel> = ({ onSearch }) => {
  const dispatch = useAppDispatch();
  const searchInput = useAppSelector((state) => state.ui.searchInput);
  const navigate = useNavigate();

  const handleSearch = () => {
    onSearch(searchInput);
    navigate('/news/search_results');
  };

  const handleClear = () => {
    dispatch(uiSlice.actions.clearSearchInput());
  };

  return (
    <div style={{ display: 'flex' }}>
      <TextField
        value={searchInput}
        onChange={(e) =>
          dispatch(uiSlice.actions.setSearchInput(e.target.value))
        }
        variant="outlined"
        sx={{
          '& .MuiInputBase-input': {
            width: '200px',
            height: '1px',
            backgroundColor: 'rgba(109, 113, 125, 0.5)',
            color: 'white',
          },
        }}
      />
      <SearchButton
        variant="contained"
        onClick={handleSearch}
        style={{ marginLeft: '10px', marginRight: '10px' }}
      >
        Search
      </SearchButton>
      <SearchButton variant="contained" onClick={handleClear}>
        Clear
      </SearchButton>
    </div>
  );
};

export default SearchBar;
