import { TagColorModel } from '../models';

export const tagColorMap: TagColorModel[] = [
  {
    tagName: 'Blockchain',
    color: '#DF0FD9',
  },
  {
    tagName: 'Earnings',
    color: '#6EE439',
  },
  {
    tagName: 'IPO',
    color: '#81C2CB',
  },
  {
    tagName: 'Mergers & Acquisitions',
    color: '#8C8080',
  },
  {
    tagName: 'Financial Markets',
    color: '#0CA5AF',
  },
  {
    tagName: 'Energy & Transportation',
    color: '#FF5262',
  },
  {
    tagName: 'Finance',
    color: '#6EE439',
  },
  {
    tagName: 'Life Sciences',
    color: '#2C2F36',
  },
  {
    tagName: 'Manufacturing',
    color: '#466D8C',
  },
  {
    tagName: 'Real Estate & Construction',
    color: '#939746',
  },
  {
    tagName: 'Retail & Wholesale',
    color: '#FAF85A',
  },
  {
    tagName: 'Technology',
    color: '#00E3F2',
  },
  {
    tagName: 'Economy - Fiscal',
    color: '#4A8CD8',
  },
  {
    tagName: 'Economy - Monetary',
    color: '#678ABF',
  },
  {
    tagName: 'Economy - Macro',
    color: '#3B70C4',
  },
];

export const tags = [
  'blockchain',
  'earnings',
  'ipo',
  'mergers_and_acquisitions',
  'financial_markets',
  'economy_fiscal',
  'economy_monetary',
  'economy_macro',
  'energy_transportation',
  'finance',
  'life_sciences',
  'manufacturing',
  'real_estate',
  'retail_wholesale',
  'technology',
];
