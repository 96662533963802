import {
  GetStockPricesReqParamsModel,
  GetStockDailyPricesResponseModel,
  GetTreeMapDataResponseModel,
  InsideTradingModel,
} from '../models';

export const getStockPricesReq = async (
  reqParam: GetStockPricesReqParamsModel,
): Promise<GetStockDailyPricesResponseModel> => {
  const {
    company = 'IBM',
    scope = 'daily',
    from = 'all',
    to = 'today',
  } = reqParam;

  const response = await fetch(
    `/api/stocks/${company}/stock_prices?scope=${scope}`,
  );
  if (!response.ok) {
    throw new Error('Failed to get stock prices');
  }
  const jsonData = await response.json();
  return jsonData;
};

export const getTreeMapDataReq =
  async (): Promise<GetTreeMapDataResponseModel> => {
    const response = await fetch(`/api/stocks/tree_map_data`);
    if (!response.ok) {
      throw new Error('Failed to get tree map data');
    }
    const jsonData = await response.json();
    return jsonData;
  };
