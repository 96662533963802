import React from 'react';
import { TagColorModel } from '../models';
import { tagColorMap } from '../styles';
import Button from '@mui/material/Button';
import { NewsTagsPropsModel } from '../models';

const NewsTags: React.FC<NewsTagsPropsModel> = ({ tags, onTagClick }) => {
  const getTagCategory = (tag: string): TagColorModel | undefined => {
    // Find the tag category by its name
    return tagColorMap.find((category) => category.tagName === tag);
  };

  return (
    <div className="tags">
      {tags.map((tag, index) => {
        const category = getTagCategory(tag);
        const tagStyle = category
          ? {
              backgroundColor: category.color,
              borderRadius: 0,
              height: '15px',
              margin: '5px',
              fontSize: '12px',
            }
          : {};

        return (
          <Button
            key={index}
            className="tag"
            variant="contained"
            style={tagStyle}
            onClick={() => onTagClick && onTagClick(tag)} // Check that onTagClick is defined before calling it
          >
            {tag}
          </Button>
        );
      })}
    </div>
  );
};

export default NewsTags;
