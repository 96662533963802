import { Button } from '@mui/material';
import { NavButtonPropsModel } from '../models';
import { TEXT_COLOR } from '../styles';

const NewsButton: React.FC<NavButtonPropsModel> = ({
  children,
  onClick,
  isSelected,
}) => {
  const handleClick = () => {
    if (onClick) onClick();
  };

  const styles = {
    marginRight: '20px',
    textDecoration: 'none',
    borderRadius: 0,
    height: '38px',
    fontSize: '14px',
    backgroundColor: isSelected ? '#6d717d' : 'transparent',
    color: TEXT_COLOR,
    display: 'inline-flex',
  };

  return (
    <Button onClick={handleClick} style={styles}>
      {children}
    </Button>
  );
};

export default NewsButton;
