export const companyQuote = [
  {
    symbol: 'AAPL',
    name: 'Apple Inc.',
    price: 193.73,
    changesPercentage: -0.134,
    change: -0.26,
    dayLow: 192.415,
    dayHigh: 194.33,
    yearHigh: 194.48,
    yearLow: 124.17,
    marketCap: 3047121131592,
    priceAvg50: 181.4006,
    priceAvg200: 156.46785,
    exchange: 'NASDAQ',
    volume: 46631931,
    avgVolume: 56687857,
    open: 193.35,
    previousClose: 193.99,
    eps: 5.89,
    pe: 32.89,
    earningsAnnouncement: '2023-08-03T00:00:00.000+0000',
    sharesOutstanding: 15728700416,
    timestamp: 1689710404,
  },
  {
    symbol: 'GOOG',
    name: 'Alphabet Inc.',
    price: 124.08,
    changesPercentage: -0.7836,
    change: -0.98,
    dayLow: 123.3,
    dayHigh: 124.99,
    yearHigh: 129.55,
    yearLow: 83.45,
    marketCap: 1573557685062,
    priceAvg50: 121.0087,
    priceAvg200: 103.42448,
    exchange: 'NASDAQ',
    volume: 19787923,
    avgVolume: 25767603,
    open: 124.905,
    previousClose: 125.06,
    eps: 4.73,
    pe: 26.23,
    earningsAnnouncement: '2023-07-24T20:00:00.000+0000',
    sharesOutstanding: 12681799525,
    timestamp: 1689710404,
  },
];
