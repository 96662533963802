import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { TEXT_COLOR } from '../styles';

const SearchButton = styled(Button)({
  backgroundColor: '#6d717d',
  color: TEXT_COLOR,
  fontSize: '16px',
  borderRadius: 0,
  height: '38px',
  marginRight: '20px',
  // textTransform: 'lowercase',
  '&:hover': {
    backgroundColor: '#3c5086',
  },
});

export default SearchButton;
