import React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import { NewsSentimentPropsModel, SentimentColorModel } from '../models';
import { sentimentColorMap } from '../styles';

const getSentiment = (label: string): SentimentColorModel | undefined => {
  return sentimentColorMap.find(
    (sentimentColorMap) => sentimentColorMap.sentimentLabel === label,
  );
};

const NewsSentiment: React.FC<NewsSentimentPropsModel> = ({ label }) => {
  const sentiment = getSentiment(label);

  if (!sentiment) {
    return null;
  }

  const StyledAvatar = styled(Avatar)({
    backgroundColor: sentiment.color,
    width: 18,
    height: 18,
  });

  return (
    <Tooltip title={sentiment.sentimentLabel} arrow>
      <StyledAvatar src={process.env.PUBLIC_URL + sentiment.img} />
    </Tooltip>
  );
};

export default NewsSentiment;
