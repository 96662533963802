import { NewsItemModelVersion1, SingleNewsPropsModel } from '../models';
import { NewsTags, NewsSentiment } from '.';
import Grid from '@mui/material/Grid';

const SingleNews: React.FC<SingleNewsPropsModel> = ({ news }) => {
  return (
    <div
      className="article"
      style={{ marginLeft: '25px', width: '100%', color: 'white' }}
    >
      <h2>{news.title}</h2>
      <p>{news.content.slice(0, 1000)}</p>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <NewsSentiment label={news.overallSentimentLabel} />
        </Grid>
        <Grid item xs>
          <NewsTags tags={news.topics} />
        </Grid>
      </Grid>
    </div>
  );
};

export default SingleNews;
