import { rest } from 'msw';
import { dailyStockPrices } from '../data/daily-stock-prices-example';
import { weeklyStockPrices } from '../data/weekly-stock-prices-example';
import { monthlyStockPrices } from '../data/monthly-stock-prices-example';
import { quarterlyStockPrices } from '../data/quarterly-stock-prices-example';
import { yearlyStockPrices } from '../data/yearly-stock-prices-example';
import { treeMapData } from '../data/tree-map-data';

const handlers = [
  rest.get('/api/stocks/:company/stock_prices', (req, res, ctx) => {
    // param不包含from和to则获取最早时间开始的所有数据，模拟后端暂不处理from和to，交给真的后端去处理
    const { company } = req.params;
    const scope = req.url.searchParams.get('scope');
    const from = req.url.searchParams.get('from');
    const to = req.url.searchParams.get('to'); // 设置默认值最近的一天

    switch (scope) {
      case 'daily':
        return res(ctx.status(200), ctx.json(dailyStockPrices));
      case 'weekly':
        return res(ctx.status(200), ctx.json(weeklyStockPrices));
      case 'monthly':
        return res(ctx.status(200), ctx.json(monthlyStockPrices));
      case 'quarterly':
        return res(ctx.status(200), ctx.json(quarterlyStockPrices));
      case 'yearly':
        return res(ctx.status(200), ctx.json(yearlyStockPrices));
      default:
        return res(ctx.status(400), ctx.json({ error: 'Invalid scope value' }));
    }
  }),

  rest.get('/api/stocks/tree_map_data', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(treeMapData));
  }),
];

export default handlers;
