import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Layout } from '../components';
import {
  HomePage,
  MyAccountPage,
  NewsMain,
  CompanyHomePage,
  WatchlistPage,
} from '../../pages';

function AppRouter() {
  const pathMappings = [
    // { path: '/', page: <HomePage /> },
    { path: '/', page: <WatchlistPage /> },
    { path: '/stock_list', page: <WatchlistPage /> },
    { path: '/my_account', page: <MyAccountPage /> },
    { path: '/news/:subpage?', page: <NewsMain /> },
    { path: '/stock/:stockSymbol', page: <CompanyHomePage /> },
    { path: '/stock/:stockSymbol/:subpage', page: <CompanyHomePage /> },
  ];

  return (
    <BrowserRouter>
      <Routes>
        {pathMappings.map((pathMapping, index) => (
          <Route
            key={index}
            path={pathMapping.path}
            element={<Layout>{pathMapping.page}</Layout>}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
