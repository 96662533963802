import { useAuth0 } from '@auth0/auth0-react';
import { UserProfile } from '../../common/components';
import { BACKGROUND } from '../../common/styles';

const MyAccountPage: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div
      style={{
        overflowX: 'auto',
        maxHeight: '100%',
        backgroundColor: BACKGROUND,
        width: '100%',
        height: '100%',
      }}
    >
      <UserProfile />
    </div>
  );
};

export default MyAccountPage;
