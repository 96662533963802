import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { LeftNavBarButtonPropsModel } from '../models';
import { BACKGROUND, LEFT_FONT_BG, LEFT_SELECT_FONT_BG } from '../styles';

const StyledButton = styled(Button)({
  textTransform: 'none', // Cancel automatic uppercase transformation
  backgroundColor: BACKGROUND,
  color: '#6D717D',
  fontSize: '20px',
  fontFamily: 'Lato',
  borderRadius: 15,
  width: '220px',
  height: '50px',

  //鼠标悬浮时
  '&:hover': {
    backgroundColor: LEFT_FONT_BG,
    color: LEFT_SELECT_FONT_BG,
  },
});

const LeftNavBarButton = ({ to, children }: LeftNavBarButtonPropsModel) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledButton>{children}</StyledButton>
    </Link>
  );
};

export default LeftNavBarButton;
