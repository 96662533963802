import { rest } from 'msw';
import {
  newsDataByType,
  newsDataByTime,
  filteredNewsDataBySearch,
} from '../data';
import { newsData } from '../data/news-data/news-data';

const handlers = [
  rest.get('/api/news', (req, res, ctx) => {
    const pageNum = req.url.searchParams.get('page');
    const pageSize = req.url.searchParams.get('page_size');
    const sortBy = req.url.searchParams.get('sort_by');
    const sortOrder = req.url.searchParams.get('sort_order');
    const newsTag = req.url.searchParams.get('tag');
    const newsTimeScope = req.url.searchParams.get('time_scope');
    const searchTerm = req.url.searchParams.get('search_term');
    // const newsSentiment = req.url.searchParams.get('sentiment');

    const limitedNews = newsData['news'].slice(0, Number(pageSize));
    const originalData = { news: limitedNews };

    if (newsTag && newsDataByType[newsTag]) {
      const limitedNews = newsDataByType[newsTag].news.slice(
        0,
        Number(pageSize),
      );
      const data = { news: limitedNews };
      return res(ctx.status(200), ctx.json(data));
    } else if (newsTimeScope && newsDataByTime[newsTimeScope]) {
      const limitedNews = newsDataByTime[newsTimeScope].news.slice(
        0,
        Number(pageSize),
      );
      const data = { news: limitedNews };
      return res(ctx.status(200), ctx.json(data));
    } else if (searchTerm && filteredNewsDataBySearch[searchTerm]) {
      const limitedNews = filteredNewsDataBySearch[searchTerm].news.slice(
        0,
        Number(pageSize),
      );
      const data = { news: limitedNews };
      return res(ctx.status(200), ctx.json(data));
    } else {
      return res(ctx.status(200), ctx.json(originalData));
    }
  }),
];

export default handlers;
