import { InsideTradingModel } from '../models';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux_features/hooks';
import { getInsiderTradingByCompanyThunk } from '../redux_features/thunks/Market-data-thunk';
import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import { BACKGROUND } from '../styles';

export interface SingleInsiderTradingPropsModel {
  insideTrading: InsideTradingModel;
}

function summarizeTransaction(data: any): string {
  let action = '';

  switch (data.transactionType) {
    case 'A-Award':
      action = 'was awarded';
      break;
    case 'C-Conversion':
      action = 'converted';
      break;
    case 'D-Return':
      action = 'returned';
      break;
    case 'E-ExpireShort':
      action = 'had short-term options expire';
      break;
    case 'F-InKind':
      action = 'received in-kind';
      break;
    case 'G-Gift':
      action = 'gifted';
      break;
    case 'H-ExpireLong':
      action = 'had long-term options expire';
      break;
    case 'I-Discretionary':
      action = 'made a discretionary transaction';
      break;
    case 'J-Other':
      action = 'made an other type of transaction';
      break;
    case 'L-Small':
      action = 'made a small transaction';
      break;
    case 'M-Exempt':
      action = 'made an exempt transaction';
      break;
    case 'O-OutOfTheMoney':
      action = 'transacted out-of-the-money options';
      break;
    case 'P-Purchase':
      action = 'purchased';
      break;
    case 'S-Sale':
      action = 'sold';
      break;
    case 'U-Tender':
      action = 'tendered';
      break;
    case 'W-Will':
      action = 'willed';
      break;
    case 'X-InTheMoney':
      action = 'transacted in-the-money options';
      break;
    case 'Z-Trust':
      action = 'transferred to a trust';
      break;
    default:
      action = 'made an unknown transaction';
  }

  return `On ${data.transactionDate}, ${data.reportingName} (Position: ${
    data.typeOfOwner
  }, reportingCik: ${
    data.reportingCik
  }) ${action} approximately ${data.securitiesTransacted.toFixed(
    2,
  )} shares of ${data.symbol} (companyCik: ${
    data.companyCik
  }) at a price of $${data.price.toFixed(2)} per share, and currently holds ${
    data.securitiesOwned
  } shares. Transaction details can be found at this link: [SEC filing](${
    data.link
  }).`;
}

const InsiderTrading: React.FC<{ company_symbol: string }> = ({
  company_symbol,
}) => {
  const dispatch = useAppDispatch();
  const tradings = useAppSelector((state) => state.ui.insideTradings);
  const status = useAppSelector((state) => state.ui.insideTradings.status);

  useEffect(() => {
    dispatch(
      getInsiderTradingByCompanyThunk({
        companySymbol: company_symbol,
        page: '1',
        pageSize: '10',
      }),
    );
  }, [company_symbol, dispatch]);
  console.log(tradings);
  return (
    <div style={{ backgroundColor: BACKGROUND }}>
      {tradings.insideTradingDatas.map((tradingItem, index) => (
        <InsiderTradingItem key={index} insideTrading={tradingItem} />
      ))}
    </div>
  );
};

const InsiderTradingItem: React.FC<SingleInsiderTradingPropsModel> = ({
  insideTrading,
}) => {
  const dateStr = insideTrading.transactionDate; // 假设这是一个表示日期的字符串
  const transactionDate = new Date(dateStr);
  const formattedDate = `${transactionDate.toLocaleDateString()} ${transactionDate.toLocaleTimeString(
    [],
    { hour: '2-digit', minute: '2-digit' },
  )}`;
  const linkStyle = {
    textDecoration: 'none', // 设置文本装饰为"none"以去除下划线
    color: 'inherit', // 继承默认文本颜色
  };
  return (
    <div
      className="article"
      style={{ marginLeft: '25px', width: '90%', color: 'white' }}
    >
      <Link
        component="a"
        href={insideTrading.link}
        target="_blank"
        rel="noopener"
        style={linkStyle}
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={2}>
            <p>{formattedDate}</p>
          </Grid>
          <Grid item xs={10}>
            <p>{summarizeTransaction(insideTrading)};</p>
          </Grid>
        </Grid>
      </Link>
    </div>
  );
};

export default InsiderTrading;
