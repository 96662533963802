import React, { useState } from 'react';
import FRGraphTable from '../../widgets/FRGraphTable';
import FRBarChart from '../../widgets/FRBarChart';
// import { useAppDispatch, useAppSelector } from '../../redux_features/hooks';
// import { getFinAnalysisDataThunk } from '../../redux_features/thunks';
import { FinAnalysisDataItem, FinAnalysisItem } from '../../models';
import { TEXT_COLOR } from '../../styles';
interface FRDynamicGraphTableProps {
  company: string;
  analysisData: FinAnalysisItem[];
}

const FRDynamicGraphTable: React.FC<FRDynamicGraphTableProps> = ({
  company,
  analysisData,
}) => {
  const [selectedAttribute, setSelectedAttribute] =
    useState<keyof FinAnalysisDataItem>('eps');

  const handleRowClick = (attribute: keyof FinAnalysisDataItem) => {
    setSelectedAttribute(attribute);
  };

  const maxDataLength = 7;
  const dataToPass =
    analysisData.length > maxDataLength
      ? analysisData.slice(0, maxDataLength)
      : analysisData;

  if (analysisData.length === 0) {
    return <div>No AnalysisData</div>;
  }

  return (
    <div className="Dynamic Graph Table" style={{ justifyContent: 'center' }}>
      <p style={{ width: '90%' }}></p>
      <FRBarChart
        attribute={selectedAttribute}
        data={analysisData.slice(0, maxDataLength)}
      />
      <p style={{ fontSize: '12px', textAlign: 'center', color: TEXT_COLOR }}>
        Click A Row to View Graph.
      </p>
      <FRGraphTable data={dataToPass} onRowClick={handleRowClick} />
      <p style={{ width: '90%' }}></p>
    </div>
  );
};

export default FRDynamicGraphTable;
