import React, { useState } from 'react';
import { Table, TableBody, TableHead, TableContainer } from '@mui/material';
import { FinAnalysisDataItem, FinAnalysisItem } from '../models';
import { TEXT_COLOR, BACKGROUND, HOVER_NAV_BUTTON } from '../styles';
import { styled } from '@mui/system';

interface FRGraphTableProps {
  data: FinAnalysisItem[];
  onRowClick: (attribute: keyof FinAnalysisDataItem) => void;
}

const StyledTableContainer = styled(TableContainer)({
  // maxHeight: 300,
  maxWidth: 1150,
  margin: 'auto',
  backgroundColor: BACKGROUND,
});

const StyledTable = styled(Table)({
  backgroundColor: BACKGROUND,
  color: TEXT_COLOR,
});

const TableCell = styled('td')({
  textAlign: 'center',
  fontSize: '14px',
});

const TableFirstRow = styled('tr')({
  cursor: 'default',
  borderBottom: `3px solid`,
});

const TableRow = styled('tr')({
  cursor: 'pointer',
  borderBottom: `0.5px solid ${HOVER_NAV_BUTTON}`,
  ':hover': {
    backgroundColor: HOVER_NAV_BUTTON,
  },
  '&:last-child': {
    borderBottom: 'none',
  },
});

const StickyCell = styled(TableCell)({
  position: 'sticky',
  left: 0,
  zIndex: 2,
  fontSize: '12px',
});

const FRGraphTable: React.FC<FRGraphTableProps> = ({ data, onRowClick }) => {
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const headers = Object.keys(data[0].analyses_data || {}).filter(
    (key) =>
      typeof data[0].analyses_data[key as keyof FinAnalysisDataItem] ===
      'number',
  );

  function formatKey(key: string): string {
    let words = key.split(/(?=[A-Z])/);
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

    return words.join(' ');
  }

  function formatNumber(num: number): string {
    if (Math.abs(num) > 1_000_000_000) {
      return `${(num / 1_000_000_000).toFixed(2)} B`;
    }
    if (Math.abs(num) > 1_000_000) {
      return `${(num / 1_000_000).toFixed(2)} M`;
    }

    return num.toFixed(2);
  }

  const formatDate = (dateString: string): string => {
    return dateString.split('T')[0];
  };

  const formattedHeaders = headers.map((header) => formatKey(header));

  return (
    <div style={{ width: '100%' }}>
      <StyledTableContainer>
        <StyledTable>
          <TableHead>
            <TableFirstRow>
              <StickyCell>Attributes</StickyCell>
              {data.map((record, rowIndex) => (
                <TableCell key={rowIndex}>
                  {formatDate(record.publish_time)}
                </TableCell>
              ))}
            </TableFirstRow>
          </TableHead>

          <TableBody>
            {headers.map((header) => (
              <TableRow
                key={header}
                onClick={() => {
                  setSelectedRow(header);
                  onRowClick(header as keyof FinAnalysisDataItem);
                }}
                style={{
                  backgroundColor:
                    header === selectedRow ? HOVER_NAV_BUTTON : BACKGROUND,
                }}
              >
                <StickyCell>
                  {formattedHeaders[headers.indexOf(header)]}
                </StickyCell>
                {data.map((row, rowIndex) => (
                  <TableCell key={rowIndex}>
                    {typeof row.analyses_data[
                      header as keyof FinAnalysisDataItem
                    ] === 'number'
                      ? formatNumber(
                          row.analyses_data[
                            header as keyof FinAnalysisDataItem
                          ] as number,
                        )
                      : row.analyses_data[header as keyof FinAnalysisDataItem]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledTableContainer>
    </div>
  );
};

export default FRGraphTable;
