import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StockListTable from './StockListTable';

import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
// import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { getSymbolList } from '../../api';
import AiSummary from '../ai_company_summary/AiSummary';
import { StockTitleDetail } from '../stocktitle_detail';
import { StockMetricsPanel } from '..';
import { useAppDispatch, useAppSelector } from '../../redux_features/hooks';
import { getCompanyDataThunk } from '../../redux_features/thunks';
import { uiSlice } from '../../redux_features/slices';
import { GetCompanyResponseModel, LatestQuoteModel } from '../../models';
import { BLACK, TOP_COLUMN } from '../../styles/main-colors';
import { recommendedSymbols } from './recommended-stocks';
import CompanyNewsHeadlineList from '../../widgets/CompanyNewsHeadlineList';

// 股票列表================================================================================
export default function StockListTabsAndRightSidebar() {
  const dispatch = useAppDispatch();

  // For tabs selector, will be extracted into independent component later
  const [tabValue, setTabValue] = React.useState(0);

  const isOpen = useAppSelector(
    (state) => state.ui.stockList.stockListRightSidebar.isOpen,
  );
  const selectedSymbol = useAppSelector(
    (state) => state.ui.stockList.selectedSymbol,
  );
  const companyData: GetCompanyResponseModel = useAppSelector(
    (state) => state.ui.company.companyData,
  );
  const allSymbols = useAppSelector((state) => state.ui.stockList.symbols);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    switch (newValue) {
      // Recommended Stocks Tab
      case 0:
        dispatch(uiSlice.actions.setDisplayedSymbols(recommendedSymbols));
        break;
      // Total Tab
      case 1:
        dispatch(uiSlice.actions.setDisplayedSymbols(allSymbols));
        break;
      // Total as default
      default:
        dispatch(uiSlice.actions.setDisplayedSymbols(allSymbols));
        break;
    }
  };

  const handleDrawerOpen = () => {
    if (isOpen) {
      dispatch(uiSlice.actions.closeStockListRightSidebar());
    } else {
      dispatch(uiSlice.actions.openStockListRightSidebar());
    }
  };

  // 页面初始化的时候获取symbol列表
  useEffect(() => {
    const fetchSymbolList = async () => {
      const fetchedList = await getSymbolList(undefined, undefined, 'all'); // 从你的异步函数获取symbolList
      dispatch(uiSlice.actions.setSymbols([...fetchedList]));
      dispatch(uiSlice.actions.setDisplayedSymbols([...recommendedSymbols]));
    };

    if (allSymbols.length === 0) {
      fetchSymbolList();
    }
  }, [dispatch, allSymbols]);

  // 设置被选中的行的初始值为symbol list的第一家公司
  useEffect(() => {
    if (selectedSymbol === '' && allSymbols.length > 0) {
      dispatch(uiSlice.actions.setSelectedSymbol(allSymbols[0]));
    }
  }, [dispatch, allSymbols, selectedSymbol]);

  // 取当前公司及同行业公司的所有信息
  useEffect(() => {
    if (selectedSymbol !== '') {
      dispatch(getCompanyDataThunk({ company_symbol: selectedSymbol }));
    }
  }, [dispatch, selectedSymbol]);

  // 将当前公司的最新价格数据单独存储
  useEffect(() => {
    if (Object.keys(companyData).length !== 0 && selectedSymbol !== '') {
      const selectedCompanyLatestQuote = companyData.latestQuotes.find(
        (quoteInfo: LatestQuoteModel) => quoteInfo.symbol === selectedSymbol,
      );

      if (selectedCompanyLatestQuote) {
        dispatch(
          uiSlice.actions.setSelectedCompanyLatestQuote(
            selectedCompanyLatestQuote,
          ),
        );
      }
    }
  }, [dispatch, companyData, selectedSymbol]);

  return (
    <Box sx={{ width: '100%', backgroundColor: 'black' }}>
      <AppBarShift open={isOpen}>
        <Box sx={{ borderColor: 'divider' }}>
          <Toolbar>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ color: 'white', flexGrow: 1 }}
            >
              <Tab
                label="Recommended"
                {...a11yProps(0)}
                sx={{ color: 'white' }}
              />
              {/* TODO: 为了部署先注释掉性能不好的Total */}
              {/* <Tab label="Total" {...a11yProps(1)} sx={{ color: 'white' }} /> */}
            </Tabs>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
            >
              <MenuIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Toolbar>
        </Box>
      </AppBarShift>
      {/* ==============================右边的BAR======================================*/}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={isOpen}
      >
        <Toolbar />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // paddingLeft: '20px',
            background: TOP_COLUMN,
          }}
        >
          <List>
            <Box sx={{ width: innerWidth }} style={{ color: 'WHITE' }}>
              <StockTitleDetail company_symbol={selectedSymbol} />
            </Box>
          </List>
          {/* 为了初次部署先注释掉 */}
          {/*  */}
          {/* <List>
            <Box sx={{ width: innerWidth }} style={{ color: 'WHITE' }}> */}
          {/* TODO */}
          {/* K线图 */}
          {/* <StockTitleDetail company_symbol={selectedSymbol} /> */}
          {/* </Box>
          </List> */}
          {/*  */}
          <List>
            <Box
              sx={{
                width: innerWidth,
                padding: '20px',
                borderRadius: '25px',
              }}
              style={{
                border: '5px solid BLACK',
                color: 'WHITE',
              }}
            >
              Quotation Info
              <StockMetricsPanel stockSymbol={selectedSymbol!} />
            </Box>
          </List>
          <List>
            <Box
              sx={{
                width: innerWidth,
                overflow: 'auto',
                padding: '20px',
                borderRadius: '25px',
              }}
              style={{ border: '5px solid BLACK', color: 'WHITE' }}
            >
              AI Summary
              <AiSummary company_symbol={selectedSymbol!} />
            </Box>
          </List>
          <List>
            <Box
              sx={{
                width: innerWidth,
                padding: '20px',
                borderRadius: '25px',
              }}
              style={{ border: '5px solid BLACK', color: 'WHITE' }}
            >
              AI News
              <CompanyNewsHeadlineList company_symbol={selectedSymbol!} />
            </Box>
          </List>
        </Box>
      </Drawer>

      {tabIndices.map((tabIndex) => (
        <CustomTabPanel key={tabIndex} value={tabValue} index={tabIndex}>
          <StockListTable />
        </CustomTabPanel>
      ))}
    </Box>
  );
}

// Drawer参数============================================================================

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabIndices = [0, 1];

const drawerWidth = '30vw';
const innerWidth = '29vw';

const AppBarShift = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open?: boolean }>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
