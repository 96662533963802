import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { worker } from './tests/mocks/worker';
import './common/i18n/init-i18n';
import { Provider } from 'react-redux';
import { store, persistor } from './common/redux_features/store';
import { Auth0Provider } from '@auth0/auth0-react';
import { PersistGate } from 'redux-persist/integration/react';

console.log(`NODE_ENV: ${process.env.NODE_ENV}`);

const mswFlag: string = process.env.REACT_APP_MSW_FLAG as string;
if (mswFlag === 'true') {
  worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <Auth0Provider
    domain={`${process.env.REACT_APP_AUTH0_DOMAIN_URL}`}
    clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID}`}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Auth0Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
