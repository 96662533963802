export const hourlyNews = {
  news: [
    {
      title: 'Hourly News 1',
      topics: ['Finance', 'Retail & Wholesale', 'Technology'],
      overallSentimentLabel: 'Bullish',
      datetime: '2023-07-09T08:30:00Z',
      content:
        'Hourly News The World Health Organization (WHO) has launched a global vaccination campaign to combat the spread of infectious diseases. The campaign aims to ensure that people in all countries have access to safe and effective vaccines. It will focus on increasing vaccine production, improving distribution systems, and addressing vaccine hesitancy. The WHO is partnering with governments, pharmaceutical companies, and other organizations to achieve its vaccination goals. The campaign is expected to save millions of lives and contribute to the control and elimination of preventable diseases worldwide.',
    },
    {
      title: 'Hourly News 2',
      topics: ['Finance', 'Retail & Wholesale', 'Technology'],
      overallSentimentLabel: 'Bullish',
      datetime: '2023-07-09T08:30:00Z',
      content:
        'Hourly News The World Health Organization (WHO) has launched a global vaccination campaign to combat the spread of infectious diseases. The campaign aims to ensure that people in all countries have access to safe and effective vaccines. It will focus on increasing vaccine production, improving distribution systems, and addressing vaccine hesitancy. The WHO is partnering with governments, pharmaceutical companies, and other organizations to achieve its vaccination goals. The campaign is expected to save millions of lives and contribute to the control and elimination of preventable diseases worldwide.',
    },
  ],
};
