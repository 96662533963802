import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { BACKGROUND, TEXT_COLOR } from '../../common/styles';
import { NewsNavBar } from '../../common/components';
import { FinancialReportPage, CompanyGraphingPage } from '..';
import { NavButton } from '../../common/widgets';
import { Box } from '@mui/material';

interface RouteParams {
  stockSymbol: string;
  subpage: string;
}

const CompanyHomePage: React.FC = () => {
  const params = useParams() as unknown as RouteParams;
  const { stockSymbol, subpage } = params;
  console.log(params);
  return (
    <Box
      height="100%"
      style={{
        overflowY: 'auto',
        backgroundColor: BACKGROUND,
        color: TEXT_COLOR,
        // border: '1px solid blue',
      }}
    >
      <Box height="6%" style={{ alignItems: 'center' }}>
        <NewsNavBar
          height={38}
          header={
            <Link
              to={`/stock/${stockSymbol}`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {stockSymbol}
            </Link>
          }
          // header={stockSymbol}
          navButtons={
            <>
              <NavButton
                to={`/stock/${stockSymbol}/financial_report`}
                height={20}
              >
                Financial Report
              </NavButton>
            </>
          }
        />
      </Box>

      <Box height="95%">
        {!subpage && <CompanyGraphingPage />}
        {subpage === 'financial_report' && <FinancialReportPage />}
      </Box>
    </Box>
  );
};

export default CompanyHomePage;
