import React from 'react';
import ReactECharts from 'echarts-for-react';
import { FinAnalysisDataItem, FinAnalysisItem } from '../models';
import { TEXT_COLOR, BACKGROUND, UP_COLOR, DOWN_COLOR } from '../styles';

interface BarChartProps {
  attribute: keyof FinAnalysisDataItem;
  data: FinAnalysisItem[];
}

interface TooltipParams {
  name: string;
  value: number | string;
  seriesType: string;
}

const FRBarChart: React.FC<BarChartProps> = ({ attribute, data }) => {
  const formatDate = (dateString: string): string => {
    return dateString.split('T')[0];
  };

  const formatNumber = (num: number): string => {
    if (Math.abs(num) > 1_000_000_000) {
      return `${(num / 1_000_000_000).toFixed(2)} B`;
    }
    if (Math.abs(num) > 1_000_000) {
      return `${(num / 1_000_000).toFixed(2)} M`;
    }

    return num.toFixed(2);
  };

  const getChartTitle = (): string => {
    // Customize this function as you see fit
    return (
      attribute.charAt(0).toUpperCase() +
      attribute
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    );
  };

  const getOption = () => ({
    backgroundColor: BACKGROUND,
    title: {
      text: getChartTitle(),
      textStyle: {
        color: TEXT_COLOR,
        fontSize: '20px',
      },
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params: TooltipParams[]) {
        return params
          .map(
            (param) =>
              `${
                param.seriesType === 'bar'
                  ? `${
                      attribute.charAt(0).toUpperCase() +
                      attribute
                        .slice(1)
                        .replace(/([A-Z])/g, ' $1')
                        .trim()
                    } Bar Chart`
                  : `${
                      attribute.charAt(0).toUpperCase() +
                      attribute
                        .slice(1)
                        .replace(/([A-Z])/g, ' $1')
                        .trim()
                    } Line Graph`
              } ${param.name}: ${formatNumber(Number(param.value))}`,
          )
          .join('<br/>');
      },
      textStyle: {
        color: BACKGROUND,
      },
    },
    xAxis: {
      type: 'category',
      data: data.map((item) => formatDate(item.publish_time)),
      axisLabel: {
        color: TEXT_COLOR,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: formatNumber,
        color: TEXT_COLOR,
      },
    },
    series: [
      {
        data: data.map((item) => item.analyses_data[attribute]),
        type: 'bar',
        name: attribute.charAt(0).toUpperCase() + attribute.slice(1),
        itemStyle: {
          color: (params: any) => {
            return params.value >= 0 ? UP_COLOR : DOWN_COLOR;
          },
        },
      },
      {
        data: data.map((item) => item.analyses_data[attribute]),
        type: 'line',
        name: `${attribute.charAt(0).toUpperCase() + attribute.slice(1)} Line`,
        itemStyle: {
          color: TEXT_COLOR,
        },
        lineStyle: {
          color: TEXT_COLOR,
        },
      },
    ],
  });

  return (
    <div
      className="bar chart"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ReactECharts
        option={getOption()}
        style={{
          height: '300px',
          width: '1150px',
        }}
      />
    </div>
  );
};

export default FRBarChart;
