export const searchResultsData = {
  news: [
    {
      title: 'Search Result 1',
      topics: ['Financial Markets', 'Manufacturing', 'Earnings', 'Technology'],
      overallSentimentLabel: 'Neutral',
      datetime: '2023-07-10T12:00:00Z',
      content:
        "A team of scientists has discovered a new species of orchid in the Amazon rainforest. The orchid, named 'Amazonia Spectabilis', features vibrant pink petals and a unique fragrance. It was found during an expedition to a remote area of the rainforest. The discovery is significant as it adds to the rich biodiversity of the Amazon region. Scientists believe that this new species could provide valuable insights into the evolution and adaptation of orchids in this unique ecosystem. Efforts are now underway to protect the habitat of 'Amazonia Spectabilis' and further study its ecological role.",
    },
    {
      title: 'Search Result 2',
      topics: ['Finance', 'Retail & Wholesale', 'Technology'],
      overallSentimentLabel: 'Bullish',
      datetime: '2023-07-09T08:30:00Z',
      content:
        'The World Health Organization (WHO) has launched a global vaccination campaign to combat the spread of infectious diseases. The campaign aims to ensure that people in all countries have access to safe and effective vaccines. It will focus on increasing vaccine production, improving distribution systems, and addressing vaccine hesitancy. The WHO is partnering with governments, pharmaceutical companies, and other organizations to achieve its vaccination goals. The campaign is expected to save millions of lives and contribute to the control and elimination of preventable diseases worldwide.',
    },
  ],
};
