import { useState } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { NewsNavBar } from '../../components';
import { NewsButton } from '../../widgets';
import { useNews } from '../../hooks';
import { TimedNewsPropsModel } from '../../models';

const TimedNews: React.FC<TimedNewsPropsModel> = ({ width, height }) => {
  const { news, setNewsTimeScope } = useNews();
  const [selectedButton, setSelectedButton] = useState('hourly');

  const handleButtonPress = (timeScope: string) => {
    setNewsTimeScope(timeScope);
    setSelectedButton(timeScope);
  };

  return (
    <Box
      sx={{
        width: width,
        height: height,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 5px grey',
          borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      <NewsNavBar
        height={50}
        header="Newest"
        position="sticky"
        navButtons={
          <>
            <NewsButton
              onClick={() => handleButtonPress('hourly')}
              isSelected={selectedButton === 'hourly'}
            >
              {' '}
              Hourly
            </NewsButton>
            <NewsButton
              onClick={() => handleButtonPress('daily')}
              isSelected={selectedButton === 'daily'}
            >
              {' '}
              Daily
            </NewsButton>
          </>
        }
      />

      <List>
        {news.map((news, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${index + 1}. ${news.content}`}
              primaryTypographyProps={{ color: 'white' }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TimedNews;
