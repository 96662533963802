import { useAuth0 } from '@auth0/auth0-react';
import { RegistrationButton } from '../../widgets';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <RegistrationButton
      onClick={() => {
        loginWithRedirect();
      }}
    >
      Log In
    </RegistrationButton>
  );
};

export default LoginButton;
