import { Box, List, ListItem, ListItemText } from '@mui/material';
import { NewsNavBar } from '..';
import { useNews } from '../../hooks';
import { RankedNewsPropsModel } from '../../models';

const RankedNews: React.FC<RankedNewsPropsModel> = ({ width, height }) => {
  const { news } = useNews();

  return (
    <Box
      sx={{
        width: width,
        height: height,
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 5px grey',
          borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}
    >
      <NewsNavBar header="Hot" position="sticky" height={50} />
      <List>
        {news.map((news, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${index + 1}. ${news.content}`}
              primaryTypographyProps={{ color: 'white' }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default RankedNews;
