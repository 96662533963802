import {
  GetLastDayDataByCompanyReqParamsModel,
  GetLastDayDataByCompanyResponseModel,
  GetCompaniesQuotesReqBodyModel,
  GetCompaniesQuotesResponseModel,
  GetInsideTradingParamModel,
  GetInsideTradingModel,
  GetOneCompanyQuotesReqParamsModel,
  GetOneCompanyQuotesResponseModel,
} from '../models';

export const getLastDayDataByCompany = async (
  reqParams: GetLastDayDataByCompanyReqParamsModel,
): Promise<GetLastDayDataByCompanyResponseModel> => {
  const { companySymbol } = reqParams;

  const resp = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/market_data/latest_day_data/${companySymbol}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const ret: GetLastDayDataByCompanyResponseModel = await resp.json();
  return ret;
};

export const getCompaniesQuotes = async (
  reqBody: GetCompaniesQuotesReqBodyModel,
): Promise<GetCompaniesQuotesResponseModel> => {
  const resp = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/market_data/company_quote_last`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    },
  );

  const ret: GetCompaniesQuotesResponseModel = await resp.json();
  return ret;
};

export const getOneCompanyQuotes = async (
  reqParams: GetOneCompanyQuotesReqParamsModel,
): Promise<GetOneCompanyQuotesResponseModel> => {
  const { symbol } = reqParams;

  const resp = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/market_data/${symbol}`,
  );
  const ret: GetOneCompanyQuotesResponseModel = await resp.json();
  return ret;
};

export const getInsiderTradingByCompanyReq = async (
  reqParam: GetInsideTradingParamModel,
): Promise<GetInsideTradingModel> => {
  const { company, page, pageSize } = reqParam;

  const url = `${process.env.REACT_APP_BACKEND_URL}/api/insider_transactions/get_transaction_by_symbol/${company}?&page=${page}&pageSize=${pageSize}`;

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error('Failed to get news');
  }
  const jsonData = await response.json();

  return jsonData;
};
