import { CompanyQuoteModel, StockListTableRowModel } from '../models';

// Format raw data from APIs into formatted data rows for stock list
export const formatStockListRow = (
  rawData: CompanyQuoteModel,
): StockListTableRowModel => {
  let symbol: string = rawData.symbol;
  let name: string = rawData.name;
  let change: number = rawData.change;
  let percentChange: number = rawData.changesPercentage;
  let volume: number = parseInt(rawData.volume);
  let turnoverRate: number =
    parseInt(rawData.sharesOutstanding) !== 0
      ? (100 * parseInt(rawData.volume)) / parseInt(rawData.sharesOutstanding)
      : 0;
  let eps: number = rawData.eps;
  let dayLow: number = rawData.dayLow;
  let dayHigh: number = rawData.dayHigh;
  let price: number = rawData.price;

  return {
    symbol,
    name,
    change: Number(change.toFixed(2)),
    '%Change': Number(percentChange.toFixed(2)),
    volume,
    turnoverRate: Number(turnoverRate.toFixed(2)),
    eps,
    dayLow,
    dayHigh,
    price,
  };
};
