// This file is in progress, uploaded for now

// The data required to make a dupont chart
interface DuPontData {
  roe: number; // Return on Equity 净资产收益率
  roa: number; // Return on Assets 总资产净利率
  equityMultiplier: number; // 权益乘数
  netProfitMargin: number; // 净利润率
  assetTurnover: number; // 资产周转率
}

// The tree structure between nodes
const duPontDataTree = {
  roe: { roa: {}, equityMultiplier: {} },
};

export const generateDuPont = (): DuPontData => {
  return {} as DuPontData;
};
