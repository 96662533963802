import React, { useEffect, useState } from 'react';
import { getCompanyDetail } from '../../api';
import { CompanyInfoModel } from '../../models';
import { Box } from '@mui/material';

const formatCompanyTitleInfo = (
  rawData: CompanyInfoModel,
): CompanyInfoModel => {
  return {
    ...rawData,
    company_name: rawData.company_name,
    company_symbol: rawData.company_symbol,
    company_information: rawData.company_information,
    industry_position: rawData.industry_position,
    // 包含 CompanyInfoModel 期望的其他属性
    tags: rawData.tags,
  };
};

interface StockTitleDetailProps {
  company_symbol: string;
}

const StockTitleDetail: React.FC<StockTitleDetailProps> = ({
  company_symbol,
}) => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoModel | null>(null);

  useEffect(() => {
    const GetCompanyInfoData = async () => {
      const companyInformationData: CompanyInfoModel = await getCompanyDetail(
        company_symbol,
      );
      setCompanyInfo(formatCompanyTitleInfo(companyInformationData));
    };

    GetCompanyInfoData();
  }, [company_symbol]);

  if (!companyInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Box height="20px"></Box>
      <p
        style={{
          display: 'inline',
          marginRight: '20px',
          fontWeight: 'bold',
          fontSize: '30px',
          color: 'red',
        }}
      >
        {companyInfo.company_symbol}
      </p>
      <p style={{ display: 'inline' }}>{companyInfo.company_name}</p>
    </div>
  );
};

export default StockTitleDetail;
