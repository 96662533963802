import { rest } from 'msw';

const handlers = [
  rest.get('/api/notifications', (req, res, ctx) => {
    const data = { firstName: 'John', lastName: 'Maverick' };
    return res(ctx.status(200), ctx.json(data));
  }),
];

export default handlers;
