import { useAppDispatch, useAppSelector } from '../../redux_features/hooks';
import { getFinAnalysisDataThunk } from '../../redux_features/thunks';
import React, { useEffect } from 'react';
import { FinAnalysisItem } from '../../models';
import EPSBarChart from './EPSChart';

interface EPSChartProps {
  company: string;
}

const EPSDataFetch: React.FC<EPSChartProps> = ({ company }) => {
  const dispatch = useAppDispatch();

  //analysisData is of type FinAnalysisItem[]
  const analysisData: FinAnalysisItem[] = useAppSelector(
    (state) => state.ui.finAnalysis.FinAnalysis.annuallyAnalysis,
  );

  const today = new Date();

  // Format today's date
  const to = `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(
    2,
    '0',
  )}/${String(today.getDate()).padStart(2, '0')}`;

  // Get the year from 10 years ago
  const tenYearsAgoYear = today.getFullYear() - 12;

  // Set the date from 10 years ago and format it
  const from = `${tenYearsAgoYear}/01/01`;

  useEffect(() => {
    dispatch(
      getFinAnalysisDataThunk({
        company,
        from: `${from}`,
        to: `${to}`,
      }),
    );
  }, [company, dispatch]);

  const maxDataLength = 15;
  const dataToPass =
    analysisData.length > maxDataLength
      ? analysisData.slice(0, maxDataLength)
      : analysisData;

  const formatDate = (dateString: string): string => {
    return dateString.split('T')[0].slice(0, 4);
  };

  const epsData = dataToPass.map((item) => ({
    publish_time: formatDate(item.publish_time),
    eps: item.analyses_data.eps,
  }));

  return (
    <div className="EPSChart" style={{ height: '100%' }}>
      <EPSBarChart data={epsData} />
    </div>
  );
};

export default EPSDataFetch;
