import { createAsyncThunk } from '@reduxjs/toolkit';
import { getInsiderTradingByCompanyReq } from '../../api';

export const getInsiderTradingByCompanyThunk = createAsyncThunk(
  'getInsiderTradingByCompanyThunk',
  async ({
    companySymbol,
    page,
    pageSize,
  }: {
    companySymbol: string;
    page: string;
    pageSize: string;
  }) => {
    const response = await getInsiderTradingByCompanyReq({
      company: companySymbol,
      page: page,
      pageSize: pageSize,
    });

    if (response && Array.isArray(response)) {
      return { insideTradings: response };
    } else {
      throw new Error('Expected the response to be an array');
    }
  },
);
