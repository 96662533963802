import {
  Datafeed,
  DatafeedSubscribeCallback,
  Period,
  SymbolInfo,
} from '@klinecharts/pro';
import { KLineData } from 'klinecharts';
import { getOneCompanyQuotes, getSearchCompanyReq } from '../../api';
import { GetSearchCompanyResponseModel } from '../../models';

export default class CustomDatafeed implements Datafeed {
  private params: any;
  private navigate: any;

  constructor(params: any, navigate: any) {
    this.params = params;
    this.navigate = navigate;
  }

  async searchSymbols(search?: string): Promise<SymbolInfo[]> {
    // 实现标的搜索逻辑
    // 返回一个Array, 其每个元素格式应为{exchange: 'US', ticker: 'MSFT',}

    const matchedCompanies: GetSearchCompanyResponseModel =
      await getSearchCompanyReq({ keyword: search || '' });

    return matchedCompanies.map((matchedCompanyInfo) => {
      return {
        exchange: 'US',
        ticker: matchedCompanyInfo.company_symbol,
      };
    });
  }

  /**
   * 获取历史k线数据
   * 当标的和周期发生变化的时候触发
   *
   * 返回标的k线数据数组
   */
  async getHistoryKLineData(
    symbolInfo: SymbolInfo,
    period: Period,
    from: number,
    to: number,
  ): Promise<KLineData[]> {
    const { stockSymbol: currentPageSymbol } = this.params;
    if (symbolInfo.ticker !== currentPageSymbol) {
      this.navigate(`/stock/${symbolInfo.ticker}`);
      return [] as KLineData[];
    }

    const data = await getOneCompanyQuotes({ symbol: symbolInfo.ticker });

    return data.map((item: any) => ({
      close: item.close,
      high: item.high,
      low: item.low,
      open: item.open,
      timestamp: new Date(item.record_time).getTime(),
      volume: parseInt(item.volume, 10),
    }));
  }

  /**
   * 订阅标的在某个周期的实时数据
   * 当标的和周期发生变化的时候触发
   *
   * 通过callback告知图表接收数据
   */
  subscribe(
    symbol: SymbolInfo,
    period: Period,
    callback: DatafeedSubscribeCallback,
  ): void {
    // 完成ws订阅或者http轮询
  }

  /**
   * 取消订阅标的在某个周期的实时数据
   * 当标的和周期发生变化的时候触发
   *
   */
  unsubscribe(symbol: SymbolInfo, period: Period): void {
    // 完成ws订阅取消或者http轮询取消
  }
}
