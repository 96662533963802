import React from 'react';
import CompanyPeerCard from './CompanyPeerCard';
import { CompanyAndPeersLatestQuotesModel } from '../models';

interface CompanyCardsContainerProps {
  companies: CompanyAndPeersLatestQuotesModel;
}

const CompanyCardsContainer: React.FC<CompanyCardsContainerProps> = ({
  companies,
}) => {
  return (
    <div
      className="card container"
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '1rem',
        marginTop: '30px',
      }}
    >
      {companies.map((company, index) => (
        <div
          key={index}
          style={{
            flex: '1 0 calc(50% - 1rem)',
            maxWidth: 'calc(50% - 1rem)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CompanyPeerCard data={company} />
        </div>
      ))}
    </div>
  );
};

export default CompanyCardsContainer;
