import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFinReportReq } from '../../api';

export const getFinReportDataThunk = createAsyncThunk(
  'getFinReportDataThunk',
  async ({
    company,
    from,
    to,
  }: {
    company: string;
    from: string;
    to: string;
  }) => {
    const quarterlyResponse = await getFinReportReq({
      company,
      period: 'quarterly',
      from,
      to,
    });

    const annuallyResponse = await getFinReportReq({
      company,
      period: 'annually',
      from,
      to,
    });

    // Make sure both responses are arrays
    if (Array.isArray(quarterlyResponse) && Array.isArray(annuallyResponse)) {
      return {
        FinReport: {
          quarterlyReport: quarterlyResponse,
          annuallyReport: annuallyResponse,
        },
      };
    } else {
      throw new Error('Expected the responses to be arrays');
    }
  },
);
