import { GetNewsReqParamsModel, GetNewsResponseModel } from '../models';

export const getNewsReq = async (
  reqParam: GetNewsReqParamsModel,
): Promise<GetNewsResponseModel> => {
  const {
    pageNum = 0,
    pageSize = 5,
    sortBy = 'datetime',
    sortOrder = 'ascending',
    newsTag,
    newsTimeScope,
    searchTerm,
  } = reqParam;

  let url = `/api/news?page=${pageNum}&page_size=${pageSize}&sort_by=${sortBy}&sort_order=${sortOrder}`;

  if (newsTag) {
    url += `&tag=${newsTag}`;
  }
  if (newsTimeScope) {
    url += `&time_scope=${newsTimeScope}`;
  }
  if (searchTerm) {
    url += `&search_term=${searchTerm}`;
  }

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error('Failed to get news');
  }
  const jsonData = await response.json();
  return jsonData;
};
