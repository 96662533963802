import { useEffect } from 'react';
import { tagColorMap } from '../../styles';
import { NewsButton, SingleNewsUrl } from '../../widgets';
import { NewsNavBar } from '..';
import { uiSlice } from '../../redux_features/slices/ui-slice';
import { useAppDispatch, useAppSelector } from '../../redux_features/hooks';
import { getNewsDataThunk } from '../../redux_features/thunks';
const tagNames = tagColorMap.map((entry) => entry.tagName, 'all');

const NewsDetailedList: React.FC = () => {
  const dispatch = useAppDispatch();
  const news = useAppSelector((state) => state.ui.news);
  const status = useAppSelector((state) => state.ui.news.status);
  const selectedTag = useAppSelector((state) => state.ui.selectedTag);

  useEffect(() => {
    dispatch(getNewsDataThunk({ tag: selectedTag, page: '1', pageSize: '8' }));
  }, [selectedTag, dispatch]);

  const handleTagClick = (tag: string) => {
    dispatch(uiSlice.actions.setSelectedTag(tag));
  };

  if (status === 'loading') {
    return <div>Loading...n</div>;
  }

  return (
    <div className="news-fin-page" style={{ backgroundColor: '#23232b' }}>
      <NewsNavBar
        tags={tagNames}
        onTagClick={handleTagClick}
        navButtons={
          <>
            <NewsButton
              onClick={() => handleTagClick('all')}
              isSelected={selectedTag === 'all'}
            >
              {' '}
              All{' '}
            </NewsButton>
          </>
        }
      />

      {news.newsData.map((newsItem, index) => (
        <SingleNewsUrl key={index} news={newsItem} />
      ))}
    </div>
  );
};

export default NewsDetailedList;
