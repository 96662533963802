import React, { useEffect, useRef } from 'react';
import '@klinecharts/pro/dist/klinecharts-pro.css';
import CustomDatafeed from './CustomDatafeed'; // 确保路径是正确的
import { KLineChartPro } from '@klinecharts/pro';
import { useNavigate, useParams } from 'react-router-dom';

interface ProKLineChartPropsModel {
  companySymbol: string;
}

const ProKLineChart: React.FC<ProKLineChartPropsModel> = ({
  companySymbol,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const datafeed = new CustomDatafeed(params, navigate);
    if (containerRef.current) {
      const symbolInfo = {
        exchange: 'US',
        ticker: companySymbol,
      };
      const periodInfo = { multiplier: 1, timespan: 'Day', text: 'D' };
      new KLineChartPro({
        container: containerRef.current,
        symbol: symbolInfo,
        period: periodInfo,
        locale: 'en-US',
        theme: 'dark',
        watermark: 'YMYC AI',
        datafeed: datafeed,
      });

      return () => {
        if (containerRef.current) {
          containerRef.current.innerHTML = '';
        }
      };
    }
  }, [companySymbol, params]);

  return (
    <div
      ref={containerRef}
      id="container"
      style={{ width: '100%', height: '100%' }}
    ></div>
  );
};

export default ProKLineChart;
