import { FC, useEffect, useState } from 'react';
import { CompanySummmaryPage } from '.';
import Grid from '@mui/material/Grid';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
} from '../../common/redux_features/hooks';
import {
  CompanyNavBar,
  CompanyCardsContainer,
  NewsButton,
} from '../../common/widgets';
import { useParams } from 'react-router-dom';
import {
  NewsNavBar,
  EPSDataFetch,
  ProKLineChart,
} from '../../common/components';
import { GetStockPricesReqParamsModel } from '../../common/models';
import { BACKGROUND, TEXT_COLOR, TOP_COLUMN } from '../../common/styles';
import CompanyDetailedPage from './CompanyDetailedSubPage';
import { getCompanyDataThunk } from '../../common/redux_features/thunks';
import { uiSlice } from '../../common/redux_features/slices';

type StockDetailParams = {
  stockSymbol: string;
};

const CompanyGraphingPage: FC = () => {
  const dispatch = useAppDispatch();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const selectedSymbol = useAppSelector(
    (state) => state.ui.stockList.selectedSymbol,
  );

  const { stockSymbol } = useParams<StockDetailParams>();
  const latestQuotes = useAppSelector(
    (state) => state.ui.company.companyData.latestQuotes,
  );
  const status = useAppSelector((state) => state.ui.finReport.status);

  console.log('aaaaa', latestQuotes);
  const SummaryButton = (
    <NewsButton onClick={() => setDialogOpen(true)}>AI Summary</NewsButton>
  );

  console.log('bbbb', status);
  const matchedCompany = latestQuotes.find(
    (company) => company.symbol === stockSymbol,
  );
  const otherCompanies = latestQuotes.filter(
    (company) => company.symbol !== stockSymbol,
  );

  // 取当前公司及同行业公司的所有信息
  useEffect(() => {
    if (stockSymbol && stockSymbol !== selectedSymbol) {
      dispatch(uiSlice.actions.setSelectedSymbol(stockSymbol));
    }
    if (stockSymbol && stockSymbol !== '') {
      dispatch(getCompanyDataThunk({ company_symbol: stockSymbol }));
    }
  }, [dispatch, selectedSymbol, stockSymbol]);

  return (
    <Box height="100%">
      {matchedCompany && (
        <CompanyNavBar data={matchedCompany} SummaryButton={SummaryButton} />
      )}
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: BACKGROUND,
            maxWidth: '800px',
          },
        }}
      >
        <DialogContent>
          <CompanySummmaryPage />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            style={{ color: TEXT_COLOR, backgroundColor: TOP_COLUMN }}
            onClick={() => setDialogOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container style={{ height: 'calc(100% - 65px)' }}>
        <Grid item xs={12} md={9} height="100%">
          <Box height="65%">
            <ProKLineChart companySymbol={stockSymbol || ''} />
          </Box>
          <Box
            style={{
              overflow: 'auto',
              position: 'relative',
            }}
            height="35%"
          >
            <CompanyDetailedPage />
          </Box>
        </Grid>

        <Grid height="100%" item xs={12} md={3}>
          <Box className="right side " style={{ height: '100%' }}>
            <Grid container direction="column" sx={{ height: '100%' }}>
              <Grid item style={{ height: '40%' }}>
                {' '}
                {stockSymbol && <EPSDataFetch company={stockSymbol} />}
              </Grid>
              <Grid
                item
                style={{
                  overflow: 'auto',
                  height: '60%',
                }}
              >
                <NewsNavBar
                  header={'Peer Group'}
                  height={28}
                  colorNavBar={BACKGROUND}
                />
                <CompanyCardsContainer companies={otherCompanies} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompanyGraphingPage;
