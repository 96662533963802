export const dailyStockPrices = {
  'Meta Data': {
    'Company Symbol': 'IBM',
    'Last Refreshed': '2023-06-23',
    'Time Zone': 'US/Eastern',
  },
  'Time Series Daily': {
    // Insert the time series data here
    '2023-06-23': {
      open: '130.4',
      high: '130.62',
      low: '129.18',
      close: '129.43',
      'adjusted close': '129.43',
      volume: '11324705',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-22': {
      open: '131.68',
      high: '132.96',
      low: '130.68',
      close: '131.17',
      'adjusted close': '131.17',
      volume: '6013021',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-21': {
      open: '135.11',
      high: '135.39',
      low: '133.29',
      close: '133.69',
      'adjusted close': '133.69',
      volume: '5501272',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-20': {
      open: '136.36',
      high: '137.23',
      low: '135.89',
      close: '135.96',
      'adjusted close': '135.96',
      volume: '4272511',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-16': {
      open: '139.23',
      high: '139.469',
      low: '137.47',
      close: '137.48',
      'adjusted close': '137.48',
      volume: '7473676',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-15': {
      open: '137.27',
      high: '138.8',
      low: '137.175',
      close: '138.4',
      'adjusted close': '138.4',
      volume: '3812582',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-14': {
      open: '137.8',
      high: '138.93',
      low: '136.94',
      close: '137.2',
      'adjusted close': '137.2',
      volume: '4514888',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-13': {
      open: '136.51',
      high: '138.17',
      low: '136.0',
      close: '137.6',
      'adjusted close': '137.6',
      volume: '3927331',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-12': {
      open: '136.0',
      high: '136.62',
      low: '135.8216',
      close: '136.42',
      'adjusted close': '136.42',
      volume: '4500120',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-09': {
      open: '134.36',
      high: '136.1',
      low: '134.17',
      close: '135.3',
      'adjusted close': '135.3',
      volume: '3981748',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-08': {
      open: '134.69',
      high: '135.98',
      low: '134.01',
      close: '134.41',
      'adjusted close': '134.41',
      volume: '4128939',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-07': {
      open: '132.5',
      high: '134.44',
      low: '132.19',
      close: '134.38',
      'adjusted close': '134.38',
      volume: '5772024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-06': {
      open: '132.43',
      high: '132.94',
      low: '131.88',
      close: '132.69',
      'adjusted close': '132.69',
      volume: '3297951',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-05': {
      open: '133.12',
      high: '133.58',
      low: '132.27',
      close: '132.64',
      'adjusted close': '132.64',
      volume: '3993516',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-02': {
      open: '130.38',
      high: '133.12',
      low: '130.15',
      close: '132.42',
      'adjusted close': '132.42',
      volume: '5375796',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-06-01': {
      open: '128.44',
      high: '130.145',
      low: '127.78',
      close: '129.82',
      'adjusted close': '129.82',
      volume: '4136086',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-31': {
      open: '128.51',
      high: '129.44',
      low: '127.46',
      close: '128.59',
      'adjusted close': '128.59',
      volume: '11086313',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-30': {
      open: '129.56',
      high: '130.0699',
      low: '128.26',
      close: '129.48',
      'adjusted close': '129.48',
      volume: '3741050',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-26': {
      open: '127.06',
      high: '129.66',
      low: '126.81',
      close: '128.89',
      'adjusted close': '128.89',
      volume: '5612570',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-25': {
      open: '125.61',
      high: '127.23',
      low: '125.01',
      close: '126.76',
      'adjusted close': '126.76',
      volume: '4102854',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-24': {
      open: '127.82',
      high: '127.9',
      low: '125.47',
      close: '125.68',
      'adjusted close': '125.68',
      volume: '3915505',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-23': {
      open: '127.24',
      high: '129.09',
      low: '127.13',
      close: '128.18',
      'adjusted close': '128.18',
      volume: '4592280',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-22': {
      open: '127.5',
      high: '128.19',
      low: '127.15',
      close: '127.5',
      'adjusted close': '127.5',
      volume: '2806770',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-19': {
      open: '126.79',
      high: '128.29',
      low: '126.55',
      close: '127.26',
      'adjusted close': '127.26',
      volume: '4306657',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-18': {
      open: '125.3',
      high: '126.51',
      low: '125.1894',
      close: '126.15',
      'adjusted close': '126.15',
      volume: '3797883',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-17': {
      open: '123.94',
      high: '125.85',
      low: '123.47',
      close: '125.71',
      'adjusted close': '125.71',
      volume: '4515134',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-16': {
      open: '123.35',
      high: '123.86',
      low: '122.45',
      close: '123.46',
      'adjusted close': '123.46',
      volume: '2749125',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-15': {
      open: '123.0',
      high: '123.6881',
      low: '122.34',
      close: '123.36',
      'adjusted close': '123.36',
      volume: '2915725',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-12': {
      open: '121.41',
      high: '122.86',
      low: '121.11',
      close: '122.84',
      'adjusted close': '122.84',
      volume: '4564825',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-11': {
      open: '122.02',
      high: '122.24',
      low: '120.55',
      close: '120.9',
      'adjusted close': '120.9',
      volume: '3446452',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-10': {
      open: '121.99',
      high: '122.49',
      low: '121.1',
      close: '122.02',
      'adjusted close': '122.02',
      volume: '4189222',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-09': {
      open: '121.9',
      high: '121.97',
      low: '120.66',
      close: '121.17',
      'adjusted close': '121.17',
      volume: '4540047',
      'dividend amount': '1.6600',
      'split coefficient': '1.0',
    },
    '2023-05-08': {
      open: '123.76',
      high: '123.92',
      low: '122.55',
      close: '123.4',
      'adjusted close': '121.732296670195',
      volume: '3663818',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-05': {
      open: '123.11',
      high: '124.1',
      low: '122.805',
      close: '123.65',
      'adjusted close': '121.978918016771',
      volume: '4971936',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-04': {
      open: '123.03',
      high: '123.52',
      low: '121.7563',
      close: '122.57',
      'adjusted close': '120.91351379956',
      volume: '4468237',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-03': {
      open: '125.46',
      high: '125.57',
      low: '123.26',
      close: '123.45',
      'adjusted close': '121.78162093951',
      volume: '4554212',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-02': {
      open: '126.3',
      high: '126.45',
      low: '123.27',
      close: '125.16',
      'adjusted close': '123.468510950094',
      volume: '4445283',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-05-01': {
      open: '126.35',
      high: '126.75',
      low: '126.06',
      close: '126.09',
      'adjusted close': '124.385942359358',
      volume: '2724992',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-28': {
      open: '126.58',
      high: '127.25',
      low: '125.64',
      close: '126.41',
      'adjusted close': '124.701617682976',
      volume: '5061247',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-27': {
      open: '126.37',
      high: '127.02',
      low: '125.455',
      close: '126.97',
      'adjusted close': '125.254049499308',
      volume: '3204889',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-26': {
      open: '125.81',
      high: '126.545',
      low: '125.12',
      close: '125.85',
      'adjusted close': '124.149185866645',
      volume: '4058800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-25': {
      open: '124.9',
      high: '126.19',
      low: '124.76',
      close: '125.89',
      'adjusted close': '124.188645282097',
      volume: '4275396',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-24': {
      open: '125.55',
      high: '126.05',
      low: '124.56',
      close: '125.4',
      'adjusted close': '123.705267442807',
      volume: '4043892',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-21': {
      open: '126.0',
      high: '126.7',
      low: '125.27',
      close: '125.73',
      'adjusted close': '124.030807620288',
      volume: '6725426',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-20': {
      open: '130.15',
      high: '130.98',
      low: '125.84',
      close: '126.36',
      'adjusted close': '124.652293413661',
      volume: '9749618',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-19': {
      open: '126.5',
      high: '126.98',
      low: '125.3',
      close: '126.32',
      'adjusted close': '124.612833998209',
      volume: '7014368',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-18': {
      open: '128.14',
      high: '128.68',
      low: '127.35',
      close: '127.78',
      'adjusted close': '126.053102662216',
      volume: '3193787',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-17': {
      open: '128.3',
      high: '128.72',
      low: '126.8',
      close: '127.82',
      'adjusted close': '126.092562077668',
      volume: '3657929',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-14': {
      open: '128.46',
      high: '129.84',
      low: '127.31',
      close: '128.14',
      'adjusted close': '126.408237401286',
      volume: '4180614',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-13': {
      open: '128.01',
      high: '128.39',
      low: '126.0',
      close: '127.9',
      'adjusted close': '126.171480908573',
      volume: '5621512',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-12': {
      open: '130.4',
      high: '130.8857',
      low: '128.17',
      close: '128.54',
      'adjusted close': '126.802831555809',
      volume: '3957542',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-11': {
      open: '130.58',
      high: '131.105',
      low: '130.18',
      close: '130.42',
      'adjusted close': '128.657424082065',
      volume: '3132430',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-10': {
      open: '129.83',
      high: '131.08',
      low: '129.24',
      close: '131.03',
      'adjusted close': '129.259180167711',
      volume: '2614402',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-06': {
      open: '132.16',
      high: '132.6',
      low: '130.315',
      close: '130.5',
      'adjusted close': '128.736342912969',
      volume: '3050581',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-05': {
      open: '131.37',
      high: '132.61',
      low: '131.37',
      close: '132.14',
      'adjusted close': '130.354178946511',
      volume: '2898759',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-04': {
      open: '131.99',
      high: '132.1499',
      low: '130.89',
      close: '131.6',
      'adjusted close': '129.821476837906',
      volume: '3382783',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-04-03': {
      open: '130.97',
      high: '132.61',
      low: '130.77',
      close: '132.06',
      'adjusted close': '130.275260115607',
      volume: '3840139',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-31': {
      open: '129.47',
      high: '131.23',
      low: '129.42',
      close: '131.09',
      'adjusted close': '129.31836929089',
      volume: '4524686',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-30': {
      open: '130.16',
      high: '131.48',
      low: '129.1',
      close: '129.22',
      'adjusted close': '127.473641618497',
      volume: '3561762',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-29': {
      open: '130.12',
      high: '130.35',
      low: '129.18',
      close: '129.71',
      'adjusted close': '127.957019457787',
      volume: '3279846',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-28': {
      open: '129.18',
      high: '129.66',
      low: '128.8',
      close: '129.34',
      'adjusted close': '127.592019864854',
      volume: '2889115',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-27': {
      open: '126.47',
      high: '130.255',
      low: '126.47',
      close: '129.31',
      'adjusted close': '127.562425303265',
      volume: '6524113',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-24': {
      open: '123.36',
      high: '125.4',
      low: '122.88',
      close: '125.29',
      'adjusted close': '123.596754050313',
      volume: '3812644',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-23': {
      open: '123.81',
      high: '124.93',
      low: '122.6',
      close: '123.37',
      'adjusted close': '121.702702108605',
      volume: '4651936',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-22': {
      open: '127.0',
      high: '127.215',
      low: '124.01',
      close: '124.05',
      'adjusted close': '122.373512171294',
      volume: '3549024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-21': {
      open: '126.9',
      high: '127.15',
      low: '125.66',
      close: '126.57',
      'adjusted close': '124.859455344785',
      volume: '3856345',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-20': {
      open: '124.31',
      high: '126.16',
      low: '124.19',
      close: '125.94',
      'adjusted close': '124.237969551413',
      volume: '4588304',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-17': {
      open: '124.08',
      high: '124.52',
      low: '122.93',
      close: '123.69',
      'adjusted close': '122.018377432223',
      volume: '37400167',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-16': {
      open: '122.96',
      high: '124.82',
      low: '121.92',
      close: '124.7',
      'adjusted close': '123.014727672393',
      volume: '6440023',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-15': {
      open: '122.99',
      high: '123.35',
      low: '121.71',
      close: '123.28',
      'adjusted close': '121.613918423838',
      volume: '5989339',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-14': {
      open: '126.49',
      high: '126.64',
      low: '123.2',
      close: '124.65',
      'adjusted close': '122.965403403077',
      volume: '8114792',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-13': {
      open: '125.15',
      high: '128.19',
      low: '124.85',
      close: '125.58',
      'adjusted close': '123.882834812342',
      volume: '8188369',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-10': {
      open: '126.12',
      high: '127.29',
      low: '125.13',
      close: '125.45',
      'adjusted close': '123.754591712122',
      volume: '5990867',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-09': {
      open: '128.3',
      high: '128.53',
      low: '125.98',
      close: '126.16',
      'adjusted close': '124.4549963364',
      volume: '5478317',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-08': {
      open: '128.48',
      high: '128.74',
      low: '127.545',
      close: '128.05',
      'adjusted close': '126.319453716519',
      volume: '2778798',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-07': {
      open: '130.28',
      high: '130.42',
      low: '128.19',
      close: '128.25',
      'adjusted close': '126.51675079378',
      volume: '3530439',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-06': {
      open: '129.64',
      high: '130.86',
      low: '129.59',
      close: '130.19',
      'adjusted close': '128.430532443214',
      volume: '2982980',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-03': {
      open: '129.35',
      high: '129.905',
      low: '128.77',
      close: '129.64',
      'adjusted close': '127.887965480746',
      volume: '2860286',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-02': {
      open: '128.39',
      high: '129.22',
      low: '127.71',
      close: '128.93',
      'adjusted close': '127.187560856468',
      volume: '3340254',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-03-01': {
      open: '128.9',
      high: '129.4726',
      low: '127.74',
      close: '128.19',
      'adjusted close': '126.457561670602',
      volume: '3760678',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-28': {
      open: '130.55',
      high: '130.61',
      low: '129.14',
      close: '129.3',
      'adjusted close': '127.552560449402',
      volume: '5143133',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-27': {
      open: '131.42',
      high: '131.87',
      low: '130.13',
      close: '130.49',
      'adjusted close': '128.726478059106',
      volume: '2761326',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-24': {
      open: '129.62',
      high: '130.67',
      low: '129.22',
      close: '130.57',
      'adjusted close': '128.805396890011',
      volume: '3015907',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-23': {
      open: '131.5',
      high: '131.7',
      low: '128.86',
      close: '130.79',
      'adjusted close': '129.022423674998',
      volume: '3725648',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-22': {
      open: '131.9',
      high: '131.99',
      low: '130.29',
      close: '130.97',
      'adjusted close': '129.199991044533',
      volume: '3200185',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-21': {
      open: '134.0',
      high: '134.385',
      low: '131.66',
      close: '131.71',
      'adjusted close': '129.9299902304',
      volume: '4257210',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-17': {
      open: '134.5',
      high: '135.58',
      low: '133.89',
      close: '135.02',
      'adjusted close': '133.195256859074',
      volume: '3466184',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-16': {
      open: '135.57',
      high: '135.9672',
      low: '134.59',
      close: '135.0',
      'adjusted close': '133.175527151347',
      volume: '2965495',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-15': {
      open: '135.2',
      high: '136.445',
      low: '135.07',
      close: '136.4',
      'adjusted close': '134.556606692176',
      volume: '2507004',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-14': {
      open: '137.05',
      high: '137.24',
      low: '135.05',
      close: '136.01',
      'adjusted close': '134.171877391517',
      volume: '3202172',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-13': {
      open: '136.0',
      high: '137.39',
      low: '135.85',
      close: '137.35',
      'adjusted close': '135.493767809167',
      volume: '4403015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-10': {
      open: '133.78',
      high: '135.77',
      low: '133.5',
      close: '135.6',
      'adjusted close': '133.767418383131',
      volume: '5049571',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-09': {
      open: '134.99',
      high: '135.73',
      low: '133.34',
      close: '133.75',
      'adjusted close': '131.942420418465',
      volume: '3918817',
      'dividend amount': '1.6500',
      'split coefficient': '1.0',
    },
    '2023-02-08': {
      open: '135.71',
      high: '136.74',
      low: '135.16',
      close: '135.98',
      'adjusted close': '132.507609516269',
      volume: '4593748',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-07': {
      open: '135.67',
      high: '136.4',
      low: '134.45',
      close: '135.84',
      'adjusted close': '132.371184561626',
      volume: '3737553',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-06': {
      open: '135.83',
      high: '136.32',
      low: '134.95',
      close: '136.18',
      'adjusted close': '132.702502308615',
      volume: '4841300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-03': {
      open: '136.35',
      high: '136.95',
      low: '135.53',
      close: '136.94',
      'adjusted close': '133.443094919531',
      volume: '3755720',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-02': {
      open: '135.96',
      high: '136.72',
      low: '134.85',
      close: '136.39',
      'adjusted close': '132.907139740579',
      volume: '6107793',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-02-01': {
      open: '134.49',
      high: '135.79',
      low: '132.8',
      close: '135.09',
      'adjusted close': '131.640336590328',
      volume: '5428898',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-31': {
      open: '135.5',
      high: '135.65',
      low: '133.76',
      close: '134.73',
      'adjusted close': '131.289529564104',
      volume: '7206448',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-30': {
      open: '134.32',
      high: '136.11',
      low: '133.98',
      close: '135.3',
      'adjusted close': '131.844974022291',
      volume: '5375712',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-27': {
      open: '134.44',
      high: '135.488',
      low: '133.7701',
      close: '134.39',
      'adjusted close': '130.958211817116',
      volume: '8143146',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-26': {
      open: '137.53',
      high: '138.27',
      low: '132.98',
      close: '134.45',
      'adjusted close': '131.016679654819',
      volume: '17548483',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-25': {
      open: '140.47',
      high: '141.03',
      low: '139.36',
      close: '140.76',
      'adjusted close': '137.165547253346',
      volume: '7347453',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-24': {
      open: '141.25',
      high: '142.75',
      low: '140.0',
      close: '141.49',
      'adjusted close': '137.87690594541',
      volume: '4407622',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-23': {
      open: '141.4',
      high: '142.985',
      low: '141.06',
      close: '141.86',
      'adjusted close': '138.237457611251',
      volume: '5898436',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-20': {
      open: '141.67',
      high: '141.86',
      low: '140.51',
      close: '141.2',
      'adjusted close': '137.594311396508',
      volume: '7153341',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-19': {
      open: '140.0',
      high: '142.23',
      low: '139.75',
      close: '140.62',
      'adjusted close': '137.029122298704',
      volume: '4833924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-18': {
      open: '144.4',
      high: '144.678',
      low: '140.225',
      close: '140.41',
      'adjusted close': '136.82448486674',
      volume: '6445642',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-17': {
      open: '146.42',
      high: '147.18',
      low: '145.01',
      close: '145.19',
      'adjusted close': '141.482422603817',
      volume: '2986461',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-13': {
      open: '144.06',
      high: '146.1',
      low: '144.01',
      close: '145.89',
      'adjusted close': '142.164547377029',
      volume: '2455786',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-12': {
      open: '144.88',
      high: '146.66',
      low: '144.52',
      close: '145.55',
      'adjusted close': '141.833229630041',
      volume: '2716118',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-11': {
      open: '145.0',
      high: '145.53',
      low: '143.45',
      close: '145.26',
      'adjusted close': '141.550635081139',
      volume: '3268738',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-10': {
      open: '143.61',
      high: '144.85',
      low: '142.9',
      close: '144.8',
      'adjusted close': '141.102381658742',
      volume: '2152172',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-09': {
      open: '144.08',
      high: '145.47',
      low: '143.4',
      close: '143.55',
      'adjusted close': '139.884301706577',
      volume: '3987782',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-06': {
      open: '142.38',
      high: '144.25',
      low: '141.58',
      close: '143.7',
      'adjusted close': '140.030471300837',
      volume: '3574042',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-05': {
      open: '142.44',
      high: '142.498',
      low: '140.01',
      close: '141.11',
      'adjusted close': '137.506609639952',
      volume: '2866648',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-04': {
      open: '142.07',
      high: '143.615',
      low: '141.3675',
      close: '142.6',
      'adjusted close': '138.958560942932',
      volume: '3869236',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2023-01-03': {
      open: '141.1',
      high: '141.9',
      low: '140.48',
      close: '141.55',
      'adjusted close': '137.935373783114',
      volume: '3338829',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-30': {
      open: '140.54',
      high: '140.9',
      low: '139.45',
      close: '140.89',
      'adjusted close': '137.292227568371',
      volume: '2858110',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-29': {
      open: '140.58',
      high: '142.26',
      low: '140.45',
      close: '141.06',
      'adjusted close': '137.457886441866',
      volume: '2337207',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-28': {
      open: '142.4',
      high: '142.81',
      low: '139.95',
      close: '140.02',
      'adjusted close': '136.444443921665',
      volume: '2539577',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-27': {
      open: '141.73',
      high: '142.72',
      low: '141.23',
      close: '142.42',
      'adjusted close': '138.783157429821',
      volume: '2742525',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-23': {
      open: '140.59',
      high: '141.8565',
      low: '139.6',
      close: '141.65',
      'adjusted close': '138.032820179287',
      volume: '2092715',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-22': {
      open: '140.95',
      high: '141.44',
      low: '138.62',
      close: '140.88',
      'adjusted close': '137.282482928754',
      volume: '3337851',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-21': {
      open: '141.84',
      high: '143.09',
      low: '140.975',
      close: '142.14',
      'adjusted close': '138.510307520536',
      volume: '3793700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-20': {
      open: '138.84',
      high: '141.425',
      low: '138.34',
      close: '141.28',
      'adjusted close': '137.672268513447',
      volume: '5156450',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-19': {
      open: '140.16',
      high: '140.44',
      low: '137.195',
      close: '138.87',
      'adjusted close': '135.323810365673',
      volume: '5282860',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-16': {
      open: '141.25',
      high: '141.915',
      low: '138.97',
      close: '140.16',
      'adjusted close': '136.580868876307',
      volume: '9834427',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-15': {
      open: '148.52',
      high: '148.98',
      low: '141.58',
      close: '142.36',
      'adjusted close': '138.724689592117',
      volume: '6687744',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-14': {
      open: '150.47',
      high: '151.91',
      low: '148.45',
      close: '149.86',
      'adjusted close': '146.033169305104',
      volume: '4205878',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-13': {
      open: '150.37',
      high: '153.21',
      low: '149.95',
      close: '150.57',
      'adjusted close': '146.725038717934',
      volume: '8811553',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-12': {
      open: '147.82',
      high: '149.21',
      low: '146.9426',
      close: '149.21',
      'adjusted close': '145.399767729979',
      volume: '4032780',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-09': {
      open: '147.4',
      high: '148.34',
      low: '146.97',
      close: '147.05',
      'adjusted close': '143.294925572638',
      volume: '3047581',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-08': {
      open: '147.9',
      high: '149.153',
      low: '147.365',
      close: '147.78',
      'adjusted close': '144.006284264702',
      volume: '2665687',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-07': {
      open: '147.33',
      high: '148.105',
      low: '146.29',
      close: '147.27',
      'adjusted close': '143.509307644219',
      volume: '3971285',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-06': {
      open: '147.3',
      high: '147.8',
      low: '146.7',
      close: '147.5',
      'adjusted close': '143.733434355417',
      volume: '2847610',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-05': {
      open: '147.94',
      high: '148.93',
      low: '146.8',
      close: '147.41',
      'adjusted close': '143.645732598862',
      volume: '2784767',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-02': {
      open: '148.13',
      high: '149.16',
      low: '147.73',
      close: '148.67',
      'adjusted close': '144.873557190643',
      volume: '2899995',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-12-01': {
      open: '149.98',
      high: '150.01',
      low: '147.34',
      close: '149.16',
      'adjusted close': '145.351044531892',
      volume: '4495924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-30': {
      open: '146.19',
      high: '149.64',
      low: '145.67',
      close: '148.9',
      'adjusted close': '145.097683901842',
      volume: '6377582',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-29': {
      open: '145.91',
      high: '147.1668',
      low: '145.7',
      close: '146.49',
      'adjusted close': '142.749225754068',
      volume: '2754744',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-28': {
      open: '147.98',
      high: '148.24',
      low: '145.935',
      close: '146.18',
      'adjusted close': '142.447141925932',
      volume: '3538696',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-25': {
      open: '148.27',
      high: '149.49',
      low: '148.101',
      close: '148.37',
      'adjusted close': '144.581218002124',
      volume: '2075156',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-23': {
      open: '149.1',
      high: '150.46',
      low: '148.3',
      close: '148.75',
      'adjusted close': '144.951514307582',
      volume: '3658459',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-22': {
      open: '147.6',
      high: '149.35',
      low: '147.02',
      close: '149.1',
      'adjusted close': '145.292576694188',
      volume: '7062060',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-21': {
      open: '147.55',
      high: '147.928',
      low: '146.45',
      close: '146.68',
      'adjusted close': '142.934373906797',
      volume: '3476208',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-18': {
      open: '146.56',
      high: '148.31',
      low: '145.94',
      close: '147.64',
      'adjusted close': '143.86985931006',
      volume: '4661735',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-17': {
      open: '143.41',
      high: '146.18',
      low: '143.25',
      close: '146.09',
      'adjusted close': '142.359440169376',
      volume: '3955162',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-16': {
      open: '144.13',
      high: '144.95',
      low: '144.0081',
      close: '144.52',
      'adjusted close': '140.829531749457',
      volume: '3445302',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-15': {
      open: '144.08',
      high: '146.1609',
      low: '142.0',
      close: '144.34',
      'adjusted close': '140.654128236345',
      volume: '4727107',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-14': {
      open: '142.63',
      high: '146.08',
      low: '142.18',
      close: '144.2',
      'adjusted close': '140.517703281703',
      volume: '5245624',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-11': {
      open: '141.5',
      high: '144.13',
      low: '140.96',
      close: '143.17',
      'adjusted close': '139.514005401119',
      volume: '5871264',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-10': {
      open: '140.26',
      high: '141.37',
      low: '138.29',
      close: '141.23',
      'adjusted close': '137.62354531536',
      volume: '5389025',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-09': {
      open: '137.95',
      high: '138.9',
      low: '136.94',
      close: '137.39',
      'adjusted close': '133.88160370231',
      volume: '4720007',
      'dividend amount': '1.6500',
      'split coefficient': '1.0',
    },
    '2022-11-08': {
      open: '139.0',
      high: '140.93',
      low: '138.72',
      close: '140.04',
      'adjusted close': '134.844503613863',
      volume: '5042756',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-07': {
      open: '136.64',
      high: '138.7',
      low: '136.51',
      close: '138.34',
      'adjusted close': '133.207573764224',
      volume: '4043062',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-04': {
      open: '135.65',
      high: '137.73',
      low: '134.94',
      close: '136.96',
      'adjusted close': '131.878771886281',
      volume: '4177972',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-03': {
      open: '136.42',
      high: '136.48',
      low: '133.97',
      close: '134.47',
      'adjusted close': '129.481151106514',
      volume: '4442443',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-02': {
      open: '137.75',
      high: '140.17',
      low: '136.8',
      close: '136.83',
      'adjusted close': '131.753594897779',
      volume: '5365264',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-11-01': {
      open: '138.25',
      high: '138.65',
      low: '136.7',
      close: '138.2',
      'adjusted close': '133.072767776606',
      volume: '3590607',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-31': {
      open: '138.06',
      high: '138.7699',
      low: '136.595',
      close: '138.29',
      'adjusted close': '133.159428768646',
      volume: '4915270',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-28': {
      open: '135.56',
      high: '138.8615',
      low: '135.22',
      close: '138.51',
      'adjusted close': '133.371266749187',
      volume: '5965457',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-27': {
      open: '135.55',
      high: '136.4',
      low: '134.445',
      close: '134.77',
      'adjusted close': '129.77002107998',
      volume: '3993168',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-26': {
      open: '133.72',
      high: '135.863',
      low: '132.81',
      close: '135.01',
      'adjusted close': '130.001117058752',
      volume: '5140023',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-25': {
      open: '132.0',
      high: '133.3',
      low: '131.3',
      close: '132.93',
      'adjusted close': '127.998285242723',
      volume: '5957623',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-24': {
      open: '130.9',
      high: '133.11',
      low: '129.85',
      close: '132.69',
      'adjusted close': '127.76718926395',
      volume: '5610914',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-21': {
      open: '128.39',
      high: '130.845',
      low: '127.59',
      close: '129.9',
      'adjusted close': '125.080698510717',
      volume: '7201256',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-20': {
      open: '126.25',
      high: '128.96',
      low: '125.15',
      close: '128.3',
      'adjusted close': '123.540058652233',
      volume: '13623115',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-19': {
      open: '122.36',
      high: '123.94',
      low: '121.9875',
      close: '122.51',
      'adjusted close': '117.964868164342',
      volume: '5906576',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-18': {
      open: '123.0',
      high: '123.94',
      low: '121.82',
      close: '122.94',
      'adjusted close': '118.378915126309',
      volume: '5120336',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-17': {
      open: '121.8',
      high: '122.875',
      low: '121.43',
      close: '121.52',
      'adjusted close': '117.011597251904',
      volume: '5458585',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-14': {
      open: '121.8',
      high: '122.54',
      low: '119.84',
      close: '120.04',
      'adjusted close': '115.586505382806',
      volume: '3763840',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-13': {
      open: '116.1',
      high: '122.15',
      low: '115.545',
      close: '121.79',
      'adjusted close': '117.271580228024',
      volume: '5837645',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-12': {
      open: '118.0',
      high: '118.81',
      low: '117.2',
      close: '117.57',
      'adjusted close': '113.20814260127',
      volume: '3338754',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-11': {
      open: '117.46',
      high: '119.23',
      low: '116.94',
      close: '117.8',
      'adjusted close': '113.429609580928',
      volume: '4043265',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-10': {
      open: '119.79',
      high: '119.96',
      low: '117.04',
      close: '117.75',
      'adjusted close': '113.38146458535',
      volume: '5990008',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-07': {
      open: '121.5',
      high: '121.8016',
      low: '118.07',
      close: '118.82',
      'adjusted close': '114.411767490712',
      volume: '4499672',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-06': {
      open: '124.88',
      high: '125.3',
      low: '121.77',
      close: '122.23',
      'adjusted close': '117.695256189107',
      volume: '5074624',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-05': {
      open: '124.71',
      high: '126.46',
      low: '124.23',
      close: '125.74',
      'adjusted close': '121.075034878657',
      volume: '3212872',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-04': {
      open: '122.8',
      high: '125.65',
      low: '122.52',
      close: '125.5',
      'adjusted close': '120.843938899885',
      volume: '4566055',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-10-03': {
      open: '120.16',
      high: '122.21',
      low: '119.6',
      close: '121.51',
      'adjusted close': '117.001968252789',
      volume: '4261729',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-30': {
      open: '121.66',
      high: '122.43',
      low: '118.61',
      close: '118.81',
      'adjusted close': '114.402138491596',
      volume: '5346108',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-29': {
      open: '121.85',
      high: '122.62',
      low: '120.57',
      close: '121.63',
      'adjusted close': '117.117516242175',
      volume: '3817723',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-28': {
      open: '121.65',
      high: '123.23',
      low: '119.81',
      close: '122.76',
      'adjusted close': '118.20559314223',
      volume: '4996378',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-27': {
      open: '122.6',
      high: '123.95',
      low: '121.08',
      close: '121.74',
      'adjusted close': '117.223435232446',
      volume: '4117215',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-26': {
      open: '122.3',
      high: '124.26',
      low: '121.75',
      close: '122.01',
      'adjusted close': '117.483418208565',
      volume: '3987817',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-23': {
      open: '124.53',
      high: '125.0',
      low: '121.74',
      close: '122.71',
      'adjusted close': '118.157448146652',
      volume: '5195693',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-22': {
      open: '124.76',
      high: '126.51',
      low: '124.41',
      close: '125.31',
      'adjusted close': '120.66098791669',
      volume: '4038335',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-21': {
      open: '126.89',
      high: '127.84',
      low: '124.92',
      close: '124.93',
      'adjusted close': '120.2950859503',
      volume: '3389545',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-20': {
      open: '126.9',
      high: '126.99',
      low: '125.52',
      close: '126.3',
      'adjusted close': '121.614258829127',
      volume: '2837485',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-19': {
      open: '126.49',
      high: '128.06',
      low: '126.28',
      close: '127.73',
      'adjusted close': '122.991205702648',
      volume: '3981606',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-16': {
      open: '124.36',
      high: '127.53',
      low: '123.83',
      close: '127.27',
      'adjusted close': '122.548271743333',
      volume: '9839696',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-15': {
      open: '127.39',
      high: '127.465',
      low: '124.9',
      close: '125.49',
      'adjusted close': '120.834309900769',
      volume: '5141671',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-14': {
      open: '127.5',
      high: '129.0',
      low: '126.845',
      close: '127.69',
      'adjusted close': '122.952689706185',
      volume: '3819067',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-13': {
      open: '129.14',
      high: '129.909',
      low: '126.7709',
      close: '127.25',
      'adjusted close': '122.529013745102',
      volume: '4565585',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-12': {
      open: '130.33',
      high: '130.99',
      low: '129.89',
      close: '130.66',
      'adjusted close': '125.812502443497',
      volume: '3741168',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-09': {
      open: '128.9',
      high: '129.49',
      low: '128.06',
      close: '129.19',
      'adjusted close': '124.397039573515',
      volume: '3293365',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-08': {
      open: '127.2',
      high: '128.54',
      low: '126.59',
      close: '128.47',
      'adjusted close': '123.703751637197',
      volume: '2964613',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-07': {
      open: '126.69',
      high: '127.8667',
      low: '126.28',
      close: '127.71',
      'adjusted close': '122.971947704417',
      volume: '2401513',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-06': {
      open: '127.8',
      high: '128.06',
      low: '126.3',
      close: '126.72',
      'adjusted close': '122.018676791979',
      volume: '3345343',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-02': {
      open: '130.3',
      high: '130.56',
      low: '127.24',
      close: '127.79',
      'adjusted close': '123.048979697341',
      volume: '3040813',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-09-01': {
      open: '128.4',
      high: '129.8173',
      low: '127.74',
      close: '129.66',
      'adjusted close': '124.849602531945',
      volume: '3396219',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-31': {
      open: '129.92',
      high: '130.0',
      low: '128.4',
      close: '128.45',
      'adjusted close': '123.684493638966',
      volume: '3490380',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-30': {
      open: '130.56',
      high: '130.77',
      low: '129.29',
      close: '129.58',
      'adjusted close': '124.77257053902',
      volume: '2407888',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-29': {
      open: '129.99',
      high: '131.42',
      low: '129.57',
      close: '130.31',
      'adjusted close': '125.475487474454',
      volume: '2751308',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-26': {
      open: '134.1',
      high: '134.18',
      low: '130.34',
      close: '130.38',
      'adjusted close': '125.542890468263',
      volume: '4185254',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-25': {
      open: '133.65',
      high: '134.425',
      low: '133.07',
      close: '133.98',
      'adjusted close': '129.009330149853',
      volume: '2706005',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-24': {
      open: '134.89',
      high: '135.11',
      low: '133.11',
      close: '133.23',
      'adjusted close': '128.287155216188',
      volume: '2855421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-23': {
      open: '135.37',
      high: '136.1',
      low: '134.72',
      close: '134.74',
      'adjusted close': '129.741134082633',
      volume: '4141798',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-22': {
      open: '137.65',
      high: '137.85',
      low: '135.47',
      close: '135.55',
      'adjusted close': '130.521083010991',
      volume: '3093629',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-19': {
      open: '138.75',
      high: '139.34',
      low: '137.66',
      close: '138.37',
      'adjusted close': '133.23646076157',
      volume: '3155842',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-18': {
      open: '137.79',
      high: '139.12',
      low: '137.75',
      close: '139.07',
      'adjusted close': '133.910490699657',
      volume: '3177726',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-17': {
      open: '136.46',
      high: '138.415',
      low: '136.3',
      close: '137.79',
      'adjusted close': '132.677978812869',
      volume: '3667568',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-16': {
      open: '134.6',
      high: '137.37',
      low: '134.4517',
      close: '136.56',
      'adjusted close': '131.493611921659',
      volume: '4313600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-15': {
      open: '132.96',
      high: '135.19',
      low: '132.24',
      close: '134.93',
      'adjusted close': '129.924085065828',
      volume: '2791241',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-12': {
      open: '132.62',
      high: '134.09',
      low: '131.98',
      close: '134.01',
      'adjusted close': '129.0382171472',
      volume: '2767054',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-11': {
      open: '132.36',
      high: '133.225',
      low: '132.0',
      close: '132.54',
      'adjusted close': '127.622754277217',
      volume: '3501925',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-10': {
      open: '130.75',
      high: '131.78',
      low: '130.34',
      close: '131.5',
      'adjusted close': '126.621338369202',
      volume: '3663995',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-09': {
      open: '129.92',
      high: '130.79',
      low: '129.12',
      close: '129.47',
      'adjusted close': '124.66665154875',
      volume: '3495991',
      'dividend amount': '1.6500',
      'split coefficient': '1.0',
    },
    '2022-08-08': {
      open: '133.1',
      high: '133.35',
      low: '132.02',
      close: '132.61',
      'adjusted close': '126.083318043622',
      volume: '3825145',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-05': {
      open: '131.25',
      high: '132.67',
      low: '131.07',
      close: '132.48',
      'adjusted close': '125.959716268901',
      volume: '2586519',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-04': {
      open: '132.15',
      high: '132.2866',
      low: '131.02',
      close: '131.64',
      'adjusted close': '125.161058647631',
      volume: '3179789',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-03': {
      open: '131.82',
      high: '132.862',
      low: '131.32',
      close: '132.34',
      'adjusted close': '125.826606665356',
      volume: '3088532',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-02': {
      open: '132.2',
      high: '132.78',
      low: '130.51',
      close: '131.81',
      'adjusted close': '125.32269173765',
      volume: '4403633',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-08-01': {
      open: '130.75',
      high: '132.7',
      low: '130.7',
      close: '132.04',
      'adjusted close': '125.541371800617',
      volume: '4142099',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-29': {
      open: '129.52',
      high: '131.0',
      low: '129.31',
      close: '130.79',
      'adjusted close': '124.352893197536',
      volume: '5786815',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-28': {
      open: '128.75',
      high: '129.81',
      low: '128.606',
      close: '129.22',
      'adjusted close': '122.860164072067',
      volume: '3913680',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-27': {
      open: '127.97',
      high: '129.43',
      low: '127.58',
      close: '129.12',
      'adjusted close': '122.765085783821',
      volume: '4175625',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-26': {
      open: '128.26',
      high: '129.3',
      low: '127.63',
      close: '128.08',
      'adjusted close': '121.776271586057',
      volume: '3645313',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-25': {
      open: '128.44',
      high: '129.1257',
      low: '127.9',
      close: '128.54',
      'adjusted close': '122.213631711991',
      volume: '4702352',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-22': {
      open: '127.03',
      high: '128.32',
      low: '125.71',
      close: '128.25',
      'adjusted close': '121.937904676076',
      volume: '6467205',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-21': {
      open: '128.75',
      high: '128.81',
      low: '125.13',
      close: '127.15',
      'adjusted close': '120.892043505365',
      volume: '11975361',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-20': {
      open: '130.7',
      high: '130.72',
      low: '128.06',
      close: '129.18',
      'adjusted close': '122.822132756768',
      volume: '9882016',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-19': {
      open: '131.58',
      high: '132.56',
      low: '127.72',
      close: '130.88',
      'adjusted close': '124.438463656958',
      volume: '29690535',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-18': {
      open: '140.15',
      high: '140.31',
      low: '137.7844',
      close: '138.13',
      'adjusted close': '131.331639554826',
      volume: '8231694',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-15': {
      open: '140.68',
      high: '140.68',
      low: '138.61',
      close: '139.92',
      'adjusted close': '133.033540914437',
      volume: '4519320',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-14': {
      open: '135.6',
      high: '139.43',
      low: '135.02',
      close: '139.06',
      'adjusted close': '132.215867635518',
      volume: '5298351',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-13': {
      open: '137.18',
      high: '138.9',
      low: '136.8',
      close: '137.18',
      'adjusted close': '130.428395816485',
      volume: '4123213',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-12': {
      open: '140.84',
      high: '141.55',
      low: '138.565',
      close: '139.18',
      'adjusted close': '132.329961581414',
      volume: '3235571',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-11': {
      open: '140.62',
      high: '141.87',
      low: '140.13',
      close: '141.0',
      'adjusted close': '134.060386427499',
      volume: '3912773',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-08': {
      open: '140.76',
      high: '141.3203',
      low: '139.82',
      close: '140.47',
      'adjusted close': '133.556471499793',
      volume: '2820928',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-07': {
      open: '138.91',
      high: '141.325',
      low: '138.83',
      close: '140.83',
      'adjusted close': '133.89875333748',
      volume: '3897077',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-06': {
      open: '138.0',
      high: '139.14',
      low: '137.13',
      close: '138.08',
      'adjusted close': '131.284100410703',
      volume: '3237059',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-05': {
      open: '139.97',
      high: '139.97',
      low: '135.27',
      close: '137.62',
      'adjusted close': '130.846740284769',
      volume: '6274067',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-07-01': {
      open: '141.0',
      high: '141.67',
      low: '139.26',
      close: '141.12',
      'adjusted close': '134.174480373395',
      volume: '4012106',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-30': {
      open: '139.58',
      high: '142.46',
      low: '139.28',
      close: '141.19',
      'adjusted close': '134.241035175167',
      volume: '4878020',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-29': {
      open: '142.74',
      high: '143.5213',
      low: '139.5',
      close: '140.71',
      'adjusted close': '133.784659391584',
      volume: '4161491',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-28': {
      open: '142.92',
      high: '144.155',
      low: '141.32',
      close: '141.86',
      'adjusted close': '134.878059706419',
      volume: '4065202',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-27': {
      open: '142.26',
      high: '143.83',
      low: '141.95',
      close: '142.8',
      'adjusted close': '135.771795615935',
      volume: '3935968',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-24': {
      open: '139.2',
      high: '142.37',
      low: '139.13',
      close: '142.06',
      'adjusted close': '135.068216282912',
      volume: '11493012',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-23': {
      open: '137.14',
      high: '138.62',
      low: '136.5',
      close: '138.44',
      'adjusted close': '131.62638224839',
      volume: '4547925',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-22': {
      open: '136.1',
      high: '138.18',
      low: '136.03',
      close: '137.08',
      'adjusted close': '130.333317528238',
      volume: '3791635',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-21': {
      open: '135.9',
      high: '138.5',
      low: '135.9',
      close: '137.85',
      'adjusted close': '131.065420347736',
      volume: '4089272',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-17': {
      open: '135.99',
      high: '136.98',
      low: '132.85',
      close: '135.02',
      'adjusted close': '128.374704790361',
      volume: '12968509',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-16': {
      open: '135.3',
      high: '136.24',
      low: '134.39',
      close: '135.67',
      'adjusted close': '128.992713663963',
      volume: '5386396',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-15': {
      open: '136.01',
      high: '138.45',
      low: '135.58',
      close: '137.06',
      'adjusted close': '130.314301870589',
      volume: '4606887',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-14': {
      open: '135.0',
      high: '136.9501',
      low: '134.86',
      close: '135.72',
      'adjusted close': '129.040252808086',
      volume: '4607785',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-13': {
      open: '133.97',
      high: '137.57',
      low: '133.76',
      close: '135.11',
      'adjusted close': '128.460275249783',
      volume: '6486044',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-10': {
      open: '137.8',
      high: '137.8',
      low: '135.25',
      close: '136.19',
      'adjusted close': '129.487120762845',
      volume: '4661716',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-09': {
      open: '140.15',
      high: '141.04',
      low: '137.93',
      close: '137.96',
      'adjusted close': '131.170006464807',
      volume: '3592551',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-08': {
      open: '142.07',
      high: '142.8',
      low: '140.15',
      close: '140.83',
      'adjusted close': '133.89875333748',
      volume: '3005666',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-07': {
      open: '142.6',
      high: '142.98',
      low: '140.96',
      close: '142.78',
      'adjusted close': '135.752779958286',
      volume: '2887051',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-06': {
      open: '142.98',
      high: '144.73',
      low: '142.0',
      close: '142.88',
      'adjusted close': '135.847858246532',
      volume: '4767100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-03': {
      open: '140.26',
      high: '142.5794',
      low: '139.74',
      close: '141.18',
      'adjusted close': '134.231527346343',
      volume: '4352213',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-02': {
      open: '139.45',
      high: '140.29',
      low: '136.85',
      close: '140.15',
      'adjusted close': '133.252220977404',
      volume: '3734812',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-06-01': {
      open: '139.67',
      high: '140.4699',
      low: '138.52',
      close: '139.43',
      'adjusted close': '132.56765730203',
      volume: '3796488',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-31': {
      open: '138.2',
      high: '139.83',
      low: '136.81',
      close: '138.84',
      'adjusted close': '132.006695401376',
      volume: '7978053',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-27': {
      open: '137.39',
      high: '139.7394',
      low: '137.24',
      close: '139.27',
      'adjusted close': '132.415532040836',
      volume: '4608215',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-26': {
      open: '135.0',
      high: '137.55',
      low: '134.52',
      close: '136.89',
      'adjusted close': '130.15266878057',
      volume: '3929338',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-25': {
      open: '132.86',
      high: '134.82',
      low: '132.86',
      close: '134.39',
      'adjusted close': '127.775711574409',
      volume: '3299303',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-24': {
      open: '130.57',
      high: '134.37',
      low: '130.42',
      close: '133.8',
      'adjusted close': '127.214749673755',
      volume: '4189723',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-23': {
      open: '129.5',
      high: '131.95',
      low: '129.42',
      close: '131.17',
      'adjusted close': '124.714190692873',
      volume: '3591511',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-20': {
      open: '130.18',
      high: '130.37',
      low: '125.8',
      close: '128.48',
      'adjusted close': '122.156584739043',
      volume: '6914458',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-19': {
      open: '132.0',
      high: '132.32',
      low: '128.71',
      close: '129.66',
      'adjusted close': '123.278508540351',
      volume: '5819664',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-18': {
      open: '137.1',
      high: '137.615',
      low: '132.17',
      close: '132.94',
      'adjusted close': '126.397076394835',
      volume: '6003015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-17': {
      open: '137.19',
      high: '138.37',
      low: '135.64',
      close: '138.37',
      'adjusted close': '131.559827446617',
      volume: '4372909',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-16': {
      open: '133.1',
      high: '136.51',
      low: '132.41',
      close: '135.03',
      'adjusted close': '128.384212619186',
      volume: '4250395',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-13': {
      open: '133.0',
      high: '133.8',
      low: '131.05',
      close: '133.6',
      'adjusted close': '127.024593097262',
      volume: '4195218',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-12': {
      open: '131.27',
      high: '133.62',
      low: '130.41',
      close: '132.9',
      'adjusted close': '126.359045079536',
      volume: '5384809',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-11': {
      open: '129.86',
      high: '132.96',
      low: '129.86',
      close: '130.75',
      'adjusted close': '124.314861882238',
      volume: '5301131',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-10': {
      open: '135.0',
      high: '135.41',
      low: '128.43',
      close: '129.13',
      'adjusted close': '122.774593612645',
      volume: '8642398',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-09': {
      open: '134.41',
      high: '136.345',
      low: '133.315',
      close: '134.44',
      'adjusted close': '127.823250718532',
      volume: '7647933',
      'dividend amount': '1.6500',
      'split coefficient': '1.0',
    },
    '2022-05-06': {
      open: '135.47',
      high: '137.99',
      low: '135.47',
      close: '137.67',
      'adjusted close': '129.307274057023',
      volume: '7306396',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-05': {
      open: '136.46',
      high: '137.26',
      low: '134.76',
      close: '135.92',
      'adjusted close': '127.663577321352',
      volume: '5957434',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-04': {
      open: '132.87',
      high: '137.87',
      low: '132.14',
      close: '137.4',
      'adjusted close': '129.053675132091',
      volume: '5913705',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-03': {
      open: '133.0',
      high: '133.6',
      low: '131.99',
      close: '132.52',
      'adjusted close': '124.470109377764',
      volume: '3688574',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-05-02': {
      open: '133.0',
      high: '133.77',
      low: '130.89',
      close: '133.04',
      'adjusted close': '124.958522122077',
      volume: '4213477',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-29': {
      open: '135.13',
      high: '135.5545',
      low: '132.0',
      close: '132.21',
      'adjusted close': '124.17894024173',
      volume: '5078660',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-28': {
      open: '136.85',
      high: '136.99',
      low: '134.81',
      close: '135.74',
      'adjusted close': '127.494511371398',
      volume: '4477068',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-27': {
      open: '135.78',
      high: '137.12',
      low: '134.63',
      close: '135.06',
      'adjusted close': '126.85581778268',
      volume: '4369052',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-26': {
      open: '139.0',
      high: '139.87',
      low: '136.17',
      close: '136.19',
      'adjusted close': '127.917176246284',
      volume: '4887395',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-25': {
      open: '137.59',
      high: '139.65',
      low: '135.19',
      close: '139.1',
      'adjusted close': '130.650409103885',
      volume: '5748289',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-22': {
      open: '139.7',
      high: '140.4399',
      low: '137.35',
      close: '138.25',
      'adjusted close': '129.852042117988',
      volume: '6508599',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-21': {
      open: '138.23',
      high: '141.88',
      low: '137.35',
      close: '139.85',
      'adjusted close': '131.35485056203',
      volume: '9922349',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-20': {
      open: '135.0',
      high: '139.56',
      low: '133.38',
      close: '138.32',
      'adjusted close': '129.917789987415',
      volume: '17770306',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-19': {
      open: '126.08',
      high: '129.4',
      low: '126.0',
      close: '129.15',
      'adjusted close': '121.3048190925',
      volume: '7971361',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-18': {
      open: '126.6',
      high: '127.3899',
      low: '125.53',
      close: '126.17',
      'adjusted close': '118.505838365473',
      volume: '4884150',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-14': {
      open: '128.93',
      high: '130.58',
      low: '126.38',
      close: '126.56',
      'adjusted close': '118.872147923708',
      volume: '6384180',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-13': {
      open: '125.64',
      high: '126.67',
      low: '124.91',
      close: '126.14',
      'adjusted close': '118.477660707147',
      volume: '3064918',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-12': {
      open: '126.42',
      high: '127.34',
      low: '125.5835',
      close: '125.98',
      'adjusted close': '118.327379862743',
      volume: '2690998',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-11': {
      open: '127.95',
      high: '128.175',
      low: '126.18',
      close: '126.37',
      'adjusted close': '118.693689420978',
      volume: '3202545',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-08': {
      open: '128.01',
      high: '128.78',
      low: '127.27',
      close: '127.73',
      'adjusted close': '119.971076598413',
      volume: '3143309',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-07': {
      open: '128.87',
      high: '129.2499',
      low: '126.73',
      close: '128.55',
      'adjusted close': '120.741265925985',
      volume: '3538317',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-06': {
      open: '127.78',
      high: '129.515',
      low: '127.25',
      close: '129.39',
      'adjusted close': '121.530240359107',
      volume: '3958057',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-05': {
      open: '129.85',
      high: '131.23',
      low: '128.66',
      close: '128.89',
      'adjusted close': '121.060612720344',
      volume: '3102238',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-04': {
      open: '130.26',
      high: '130.45',
      low: '128.93',
      close: '130.27',
      'adjusted close': '122.35678500333',
      volume: '2811100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-04-01': {
      open: '129.66',
      high: '130.27',
      low: '128.06',
      close: '130.15',
      'adjusted close': '122.244074370027',
      volume: '4012373',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-31': {
      open: '130.72',
      high: '131.88',
      low: '130.0',
      close: '130.02',
      'adjusted close': '122.121971183948',
      volume: '4274029',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-30': {
      open: '132.01',
      high: '133.08',
      low: '131.39',
      close: '132.13',
      'adjusted close': '124.103799819528',
      volume: '2622860',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-29': {
      open: '132.04',
      high: '132.84',
      low: '130.43',
      close: '131.94',
      'adjusted close': '123.925341316798',
      volume: '5791032',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-28': {
      open: '130.82',
      high: '131.5',
      low: '129.6',
      close: '131.47',
      'adjusted close': '123.483891336361',
      volume: '2483492',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-25': {
      open: '129.5',
      high: '131.4',
      low: '129.31',
      close: '131.35',
      'adjusted close': '123.371180703058',
      volume: '3516923',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-24': {
      open: '128.33',
      high: '129.37',
      low: '127.8',
      close: '129.25',
      'adjusted close': '121.398744620253',
      volume: '2971075',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-23': {
      open: '129.08',
      high: '129.32',
      low: '128.25',
      close: '128.3',
      'adjusted close': '120.506452106603',
      volume: '2924535',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-22': {
      open: '128.5',
      high: '129.3',
      low: '127.85',
      close: '129.06',
      'adjusted close': '121.220286117523',
      volume: '2649026',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-21': {
      open: '129.0',
      high: '129.74',
      low: '127.4',
      close: '128.1',
      'adjusted close': '120.318601051098',
      volume: '3379393',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-18': {
      open: '127.38',
      high: '128.93',
      low: '126.37',
      close: '128.76',
      'adjusted close': '120.938509534265',
      volume: '7400216',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-17': {
      open: '127.1',
      high: '128.29',
      low: '126.53',
      close: '127.96',
      'adjusted close': '120.187105312244',
      volume: '3671903',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-16': {
      open: '126.5',
      high: '127.2842',
      low: '124.71',
      close: '127.04',
      'adjusted close': '119.32299045692',
      volume: '4292845',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-15': {
      open: '124.0',
      high: '125.9398',
      low: '123.22',
      close: '125.64',
      'adjusted close': '118.008033068384',
      volume: '4021228',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-14': {
      open: '124.45',
      high: '125.16',
      low: '122.685',
      close: '123.94',
      'adjusted close': '116.411299096589',
      volume: '3289440',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-11': {
      open: '125.0',
      high: '126.77',
      low: '123.91',
      close: '123.96',
      'adjusted close': '116.43008420214',
      volume: '4119630',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-10': {
      open: '125.75',
      high: '126.03',
      low: '123.125',
      close: '124.35',
      'adjusted close': '116.796393760375',
      volume: '5326835',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-09': {
      open: '127.41',
      high: '128.1',
      low: '125.91',
      close: '126.22',
      'adjusted close': '118.552801129349',
      volume: '4403499',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-08': {
      open: '126.62',
      high: '128.345',
      low: '124.82',
      close: '125.68',
      'adjusted close': '118.045603279485',
      volume: '4887565',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-07': {
      open: '126.47',
      high: '127.905',
      low: '125.62',
      close: '126.07',
      'adjusted close': '118.41191283772',
      volume: '5047358',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-04': {
      open: '124.4',
      high: '127.35',
      low: '124.2103',
      close: '126.62',
      'adjusted close': '118.928503240359',
      volume: '4402911',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-03': {
      open: '124.96',
      high: '126.665',
      low: '124.2351',
      close: '125.93',
      'adjusted close': '118.280417098866',
      volume: '5206649',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-02': {
      open: '122.59',
      high: '124.64',
      low: '121.8',
      close: '123.86',
      'adjusted close': '116.336158674387',
      volume: '4891435',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-03-01': {
      open: '122.67',
      high: '123.39',
      low: '120.7',
      close: '121.35',
      'adjusted close': '113.978627927797',
      volume: '4873331',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-28': {
      open: '122.21',
      high: '123.3851',
      low: '121.0388',
      close: '122.51',
      'adjusted close': '115.068164049727',
      volume: '6757332',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-25': {
      open: '122.05',
      high: '124.2625',
      low: '121.45',
      close: '124.18',
      'adjusted close': '116.636720363196',
      volume: '4460904',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-24': {
      open: '120.0',
      high: '122.1',
      low: '118.81',
      close: '121.97',
      'adjusted close': '114.560966199863',
      volume: '6563202',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-23': {
      open: '124.38',
      high: '124.7',
      low: '121.87',
      close: '122.07',
      'adjusted close': '114.654891727615',
      volume: '4086367',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-22': {
      open: '124.2',
      high: '125.0',
      low: '122.68',
      close: '123.92',
      'adjusted close': '116.392513991039',
      volume: '5306688',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-18': {
      open: '124.94',
      high: '125.44',
      low: '123.61',
      close: '124.35',
      'adjusted close': '116.796393760375',
      volume: '4609212',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-17': {
      open: '128.05',
      high: '128.5',
      low: '124.85',
      close: '124.97',
      'adjusted close': '117.378732032441',
      volume: '6796997',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-16': {
      open: '129.45',
      high: '130.44',
      low: '128.26',
      close: '129.18',
      'adjusted close': '121.332996750826',
      volume: '4875612',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-15': {
      open: '130.64',
      high: '131.68',
      low: '129.61',
      close: '129.94',
      'adjusted close': '122.046830761746',
      volume: '4395714',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-14': {
      open: '132.59',
      high: '132.65',
      low: '129.07',
      close: '130.15',
      'adjusted close': '122.244074370027',
      volume: '5345289',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-11': {
      open: '133.9',
      high: '134.7099',
      low: '132.38',
      close: '132.69',
      'adjusted close': '124.629782774943',
      volume: '4176155',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-10': {
      open: '135.47',
      high: '136.56',
      low: '133.17',
      close: '133.52',
      'adjusted close': '125.40936465529',
      volume: '5978640',
      'dividend amount': '1.6400',
      'split coefficient': '1.0',
    },
    '2022-02-09': {
      open: '137.84',
      high: '138.35',
      low: '136.83',
      close: '137.79',
      'adjusted close': '127.849632700891',
      volume: '5393478',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-08': {
      open: '137.23',
      high: '137.52',
      low: '135.78',
      close: '137.02',
      'adjusted close': '127.135181600087',
      volume: '4181825',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-07': {
      open: '137.45',
      high: '137.8223',
      low: '136.27',
      close: '137.24',
      'adjusted close': '127.339310486031',
      volume: '3759046',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-04': {
      open: '137.86',
      high: '138.82',
      low: '136.215',
      close: '137.15',
      'adjusted close': '127.255803214509',
      volume: '4142045',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-03': {
      open: '137.0',
      high: '138.76',
      low: '135.831',
      close: '137.78',
      'adjusted close': '127.840354115166',
      volume: '6100777',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-02': {
      open: '135.7',
      high: '137.555',
      low: '135.26',
      close: '137.25',
      'adjusted close': '127.348589071756',
      volume: '5357237',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-02-01': {
      open: '133.76',
      high: '135.96',
      low: '132.5',
      close: '135.53',
      'adjusted close': '125.752672327104',
      volume: '6206448',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-31': {
      open: '134.09',
      high: '134.09',
      low: '132.3',
      close: '133.57',
      'adjusted close': '123.934069525059',
      volume: '5859043',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-28': {
      open: '133.19',
      high: '134.53',
      low: '131.79',
      close: '134.5',
      'adjusted close': '124.796977997458',
      volume: '5471497',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-27': {
      open: '133.66',
      high: '134.75',
      low: '132.08',
      close: '132.52',
      'adjusted close': '122.959818023964',
      volume: '5499566',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-26': {
      open: '136.47',
      high: '137.07',
      low: '133.13',
      close: '134.26',
      'adjusted close': '124.574291940065',
      volume: '8335992',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-25': {
      open: '129.14',
      high: '137.3361',
      low: '128.3',
      close: '136.1',
      'adjusted close': '126.281551713413',
      volume: '19715698',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-24': {
      open: '127.99',
      high: '129.15',
      low: '124.193',
      close: '128.82',
      'adjusted close': '119.526741305818',
      volume: '13777648',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-21': {
      open: '131.65',
      high: '131.87',
      low: '129.27',
      close: '129.35',
      'adjusted close': '120.018506349228',
      volume: '5906950',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-20': {
      open: '131.26',
      high: '132.88',
      low: '130.5705',
      close: '130.82',
      'adjusted close': '121.382458450762',
      volume: '5278244',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-19': {
      open: '132.9',
      high: '133.9',
      low: '131.5',
      close: '131.58',
      'adjusted close': '122.087630965841',
      volume: '4099035',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-18': {
      open: '132.95',
      high: '133.89',
      low: '131.78',
      close: '132.94',
      'adjusted close': '123.349518624402',
      volume: '5236258',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-14': {
      open: '134.55',
      high: '135.14',
      low: '133.3',
      close: '134.21',
      'adjusted close': '124.527899011441',
      volume: '5310305',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-13': {
      open: '133.9',
      high: '136.05',
      low: '133.56',
      close: '134.76',
      'adjusted close': '125.038221226301',
      volume: '4868349',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-12': {
      open: '133.25',
      high: '134.47',
      low: '131.37',
      close: '133.59',
      'adjusted close': '123.952626696509',
      volume: '5352014',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-11': {
      open: '130.52',
      high: '133.25',
      low: '127.97',
      close: '132.87',
      'adjusted close': '123.284568524329',
      volume: '11081210',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-10': {
      open: '134.47',
      high: '136.2',
      low: '133.38',
      close: '135.03',
      'adjusted close': '125.288743040868',
      volume: '5432807',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-07': {
      open: '134.9',
      high: '135.6618',
      low: '133.5111',
      close: '134.83',
      'adjusted close': '125.103171326374',
      volume: '5238099',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-06': {
      open: '138.2',
      high: '138.4099',
      low: '132.51',
      close: '135.34',
      'adjusted close': '125.576379198335',
      volume: '9908081',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-05': {
      open: '138.31',
      high: '142.2',
      low: '137.88',
      close: '138.22',
      'adjusted close': '128.248611887053',
      volume: '8956910',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-04': {
      open: '136.1',
      high: '139.95',
      low: '135.9',
      close: '138.02',
      'adjusted close': '128.063040172559',
      volume: '7300011',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2022-01-03': {
      open: '134.07',
      high: '136.295',
      low: '133.63',
      close: '136.04',
      'adjusted close': '126.225880199065',
      volume: '4610665',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-31': {
      open: '134.0',
      high: '134.99',
      low: '133.61',
      close: '133.66',
      'adjusted close': '124.017576796582',
      volume: '3315313',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-30': {
      open: '133.75',
      high: '134.37',
      low: '133.33',
      close: '133.91',
      'adjusted close': '124.2495414397',
      volume: '3158111',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-29': {
      open: '132.4',
      high: '134.21',
      low: '132.3',
      close: '133.35',
      'adjusted close': '123.729940639116',
      volume: '4239935',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-28': {
      open: '131.6',
      high: '133.22',
      low: '131.59',
      close: '132.63',
      'adjusted close': '123.061882466936',
      volume: '3447079',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-27': {
      open: '130.63',
      high: '131.65',
      low: '129.95',
      close: '131.62',
      'adjusted close': '122.124745308739',
      volume: '4294499',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-23': {
      open: '130.0',
      high: '130.96',
      low: '129.52',
      close: '130.63',
      'adjusted close': '121.206165321992',
      volume: '3649044',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-22': {
      open: '129.06',
      high: '129.79',
      low: '127.6',
      close: '129.75',
      'adjusted close': '120.389649778217',
      volume: '3923198',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-21': {
      open: '127.66',
      high: '129.3356',
      low: '127.66',
      close: '128.97',
      'adjusted close': '119.665920091689',
      volume: '4855972',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-20': {
      open: '125.72',
      high: '127.2',
      low: '124.7',
      close: '127.06',
      'adjusted close': '117.893710218268',
      volume: '4941411',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-17': {
      open: '125.87',
      high: '128.64',
      low: '125.2093',
      close: '127.4',
      'adjusted close': '118.209182132908',
      volume: '10382693',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-16': {
      open: '123.51',
      high: '126.6399',
      low: '123.475',
      close: '125.93',
      'adjusted close': '116.845230031375',
      volume: '7280537',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-15': {
      open: '123.8',
      high: '124.815',
      low: '122.18',
      close: '123.11',
      'adjusted close': '114.228668857004',
      volume: '4990028',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-14': {
      open: '122.35',
      high: '125.03',
      low: '122.3',
      close: '123.76',
      'adjusted close': '114.831776929111',
      volume: '5716124',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-13': {
      open: '123.76',
      high: '124.3554',
      low: '120.79',
      close: '122.58',
      'adjusted close': '113.736903813594',
      volume: '6847468',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-10': {
      open: '124.3',
      high: '125.33',
      low: '123.36',
      close: '124.09',
      'adjusted close': '115.137970258027',
      volume: '4966921',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-09': {
      open: '122.15',
      high: '123.95',
      low: '121.79',
      close: '123.57',
      'adjusted close': '114.655483800341',
      volume: '4601092',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-08': {
      open: '122.0',
      high: '123.38',
      low: '121.52',
      close: '123.02',
      'adjusted close': '114.145161585482',
      volume: '5483948',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-07': {
      open: '120.475',
      high: '122.08',
      low: '120.07',
      close: '121.58',
      'adjusted close': '112.809045241123',
      volume: '5193991',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-06': {
      open: '119.4',
      high: '121.15',
      low: '119.4',
      close: '119.91',
      'adjusted close': '111.259521425095',
      volume: '4785560',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-03': {
      open: '117.36',
      high: '119.36',
      low: '117.36',
      close: '118.84',
      'adjusted close': '110.26671275255',
      volume: '6630139',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-02': {
      open: '117.37',
      high: '117.98',
      low: '116.56',
      close: '116.9',
      'adjusted close': '108.466667121954',
      volume: '5267149',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-12-01': {
      open: '118.25',
      high: '118.93',
      low: '116.85',
      close: '116.92',
      'adjusted close': '108.485224293404',
      volume: '5959867',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-30': {
      open: '117.5',
      high: '119.2399',
      low: '116.45',
      close: '117.1',
      'adjusted close': '108.652238836449',
      volume: '9252701',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-29': {
      open: '118.62',
      high: '119.61',
      low: '117.53',
      close: '118.5',
      'adjusted close': '109.951240837909',
      volume: '8949795',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-26': {
      open: '115.0',
      high: '116.335',
      low: '114.56',
      close: '115.81',
      'adjusted close': '107.45530127796',
      volume: '3322012',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-24': {
      open: '116.16',
      high: '117.27',
      low: '116.08',
      close: '116.73',
      'adjusted close': '108.308931164634',
      volume: '3220802',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-23': {
      open: '116.79',
      high: '117.94',
      low: '116.04',
      close: '116.79',
      'adjusted close': '108.364602678983',
      volume: '4914995',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-22': {
      open: '116.0',
      high: '118.81',
      low: '115.19',
      close: '116.47',
      'adjusted close': '108.067687935792',
      volume: '6417218',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-19': {
      open: '116.49',
      high: '116.56',
      low: '115.27',
      close: '116.05',
      'adjusted close': '107.677987335353',
      volume: '5384548',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-18': {
      open: '118.36',
      high: '118.36',
      low: '116.31',
      close: '116.66',
      'adjusted close': '108.243981064561',
      volume: '5047879',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-17': {
      open: '118.38',
      high: '119.33',
      low: '117.78',
      close: '118.06',
      'adjusted close': '109.542983066022',
      volume: '4043289',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-16': {
      open: '118.92',
      high: '119.9',
      low: '118.42',
      close: '118.46',
      'adjusted close': '109.91412649501',
      volume: '4750760',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-15': {
      open: '119.54',
      high: '120.16',
      low: '118.31',
      close: '118.87',
      'adjusted close': '110.294548509724',
      volume: '5046321',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-12': {
      open: '120.0',
      high: '120.64',
      low: '118.78',
      close: '118.96',
      'adjusted close': '110.378055781246',
      volume: '5376966',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-11': {
      open: '120.9',
      high: '121.7894',
      low: '120.08',
      close: '120.27',
      'adjusted close': '111.593550511184',
      volume: '4643257',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-10': {
      open: '121.0',
      high: '122.43',
      low: '119.932',
      close: '120.22',
      'adjusted close': '111.547157582561',
      volume: '6268961',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-09': {
      open: '122.56',
      high: '122.9',
      low: '120.26',
      close: '120.85',
      'adjusted close': '112.131708483218',
      volume: '7195453',
      'dividend amount': '1.6400',
      'split coefficient': '1.0',
    },
    '2021-11-08': {
      open: '123.985',
      high: '124.78',
      low: '123.53',
      close: '124.54',
      'adjusted close': '114.008351493999',
      volume: '5625275',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-05': {
      open: '121.43',
      high: '123.77',
      low: '121.43',
      close: '123.61',
      'adjusted close': '113.156996372035',
      volume: '6790478',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-04': {
      open: '123.05',
      high: '123.34',
      low: '119.9',
      close: '120.85',
      'adjusted close': '110.630394074593',
      volume: '7208736',
      'dividend amount': '0.0000',
      'split coefficient': '1.046',
    },
    '2021-11-03': {
      open: '126.23',
      high: '127.29',
      low: '125.68',
      close: '127.13',
      'adjusted close': '111.261309499894',
      volume: '5421406',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-02': {
      open: '126.3',
      high: '127.17',
      low: '124.91',
      close: '126.18',
      'adjusted close': '110.429890920291',
      volume: '4496393',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-11-01': {
      open: '125.05',
      high: '126.31',
      low: '123.8375',
      close: '126.28',
      'adjusted close': '110.517408665512',
      volume: '5874767',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-29': {
      open: '125.43',
      high: '126.32',
      low: '124.91',
      close: '125.1',
      'adjusted close': '109.4846992719',
      volume: '5916789',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-28': {
      open: '125.17',
      high: '126.31',
      low: '124.62',
      close: '125.84',
      'adjusted close': '110.132330586538',
      volume: '6503003',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-27': {
      open: '127.44',
      high: '127.88',
      low: '125.01',
      close: '125.17',
      'adjusted close': '109.545961693555',
      volume: '6973432',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-26': {
      open: '127.52',
      high: '128.3',
      low: '126.755',
      close: '127.13',
      'adjusted close': '111.261309499894',
      volume: '8520872',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-25': {
      open: '127.53',
      high: '128.65',
      low: '126.94',
      close: '127.64',
      'adjusted close': '111.707650000523',
      volume: '6374038',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-22': {
      open: '128.05',
      high: '130.25',
      low: '126.611',
      close: '127.88',
      'adjusted close': '111.917692589054',
      volume: '11582195',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-21': {
      open: '133.51',
      high: '133.72',
      low: '128.1',
      close: '128.33',
      'adjusted close': '112.31152244255',
      volume: '31466529',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-20': {
      open: '141.68',
      high: '142.2',
      low: '140.7',
      close: '141.9',
      'adjusted close': '124.187680469086',
      volume: '6189255',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-19': {
      open: '141.08',
      high: '142.94',
      low: '140.5201',
      close: '141.98',
      'adjusted close': '124.257694665263',
      volume: '4339548',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-18': {
      open: '144.0',
      high: '144.94',
      low: '141.759',
      close: '142.32',
      'adjusted close': '124.555254999016',
      volume: '6154055',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-15': {
      open: '143.39',
      high: '144.85',
      low: '142.79',
      close: '144.61',
      'adjusted close': '126.559411364585',
      volume: '3222778',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-14': {
      open: '141.04',
      high: '143.92',
      low: '141.01',
      close: '143.39',
      'adjusted close': '125.491694872884',
      volume: '4217305',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-13': {
      open: '140.52',
      high: '141.41',
      low: '139.66',
      close: '140.76',
      'adjusted close': '123.189978173563',
      volume: '2880747',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-12': {
      open: '142.21',
      high: '142.3',
      low: '140.3',
      close: '140.47',
      'adjusted close': '122.936176712421',
      volume: '3148559',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-11': {
      open: '143.5',
      high: '144.08',
      low: '142.4',
      close: '142.43',
      'adjusted close': '124.651524518759',
      volume: '2793298',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-08': {
      open: '141.81',
      high: '143.65',
      low: '141.05',
      close: '143.22',
      'adjusted close': '125.342914706008',
      volume: '3731279',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-07': {
      open: '142.73',
      high: '143.395',
      low: '141.53',
      close: '141.81',
      'adjusted close': '124.108914498387',
      volume: '3823803',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-06': {
      open: '142.48',
      high: '143.37',
      low: '140.89',
      close: '142.36',
      'adjusted close': '124.590262097104',
      volume: '5328433',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-05': {
      open: '144.75',
      high: '145.0',
      low: '142.64',
      close: '143.15',
      'adjusted close': '125.281652284353',
      volume: '6976648',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-04': {
      open: '142.74',
      high: '146.0',
      low: '142.3501',
      close: '144.11',
      'adjusted close': '126.121822638478',
      volume: '7351128',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-10-01': {
      open: '141.0',
      high: '143.97',
      low: '140.37',
      close: '143.32',
      'adjusted close': '125.430432451229',
      volume: '6604064',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-30': {
      open: '140.0',
      high: '140.57',
      low: '138.5',
      close: '138.93',
      'adjusted close': '121.588403436012',
      volume: '5824431',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-29': {
      open: '137.73',
      high: '139.93',
      low: '136.44',
      close: '139.18',
      'adjusted close': '121.807197799066',
      volume: '3774236',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-28': {
      open: '139.17',
      high: '139.688',
      low: '137.21',
      close: '137.47',
      'adjusted close': '120.310644355781',
      volume: '4314595',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-27': {
      open: '137.96',
      high: '139.065',
      low: '137.48',
      close: '138.56',
      'adjusted close': '121.264587778693',
      volume: '3306865',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-24': {
      open: '137.03',
      high: '138.48',
      low: '136.75',
      close: '137.49',
      'adjusted close': '120.328147904825',
      volume: '2964397',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-23': {
      open: '135.25',
      high: '137.42',
      low: '135.03',
      close: '136.73',
      'adjusted close': '119.663013041143',
      volume: '3013238',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-22': {
      open: '133.72',
      high: '135.37',
      low: '133.47',
      close: '134.63',
      'adjusted close': '117.825140391495',
      volume: '3602416',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-21': {
      open: '135.11',
      high: '135.65',
      low: '132.94',
      close: '132.97',
      'adjusted close': '116.37234582082',
      volume: '4074528',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-20': {
      open: '133.9',
      high: '135.18',
      low: '132.78',
      close: '134.31',
      'adjusted close': '117.545083606786',
      volume: '4770651',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-17': {
      open: '135.75',
      high: '135.9199',
      low: '135.05',
      close: '135.23',
      'adjusted close': '118.350246862823',
      volume: '5633480',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-16': {
      open: '137.28',
      high: '137.95',
      low: '135.71',
      close: '136.43',
      'adjusted close': '119.400459805479',
      volume: '2643975',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-15': {
      open: '136.22',
      high: '137.8',
      low: '135.67',
      close: '137.2',
      'adjusted close': '120.074346443683',
      volume: '3254122',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-14': {
      open: '138.4',
      high: '138.57',
      low: '135.34',
      close: '136.22',
      'adjusted close': '119.216672540514',
      volume: '4454291',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-13': {
      open: '138.4',
      high: '138.99',
      low: '137.51',
      close: '138.15',
      'adjusted close': '120.905765023286',
      volume: '4144345',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-10': {
      open: '138.82',
      high: '139.3699',
      low: '137.0',
      close: '137.02',
      'adjusted close': '119.916814502285',
      volume: '3975115',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-09': {
      open: '137.85',
      high: '138.96',
      low: '137.555',
      close: '137.74',
      'adjusted close': '120.546942267878',
      volume: '3508363',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-08': {
      open: '138.14',
      high: '139.09',
      low: '137.6',
      close: '138.67',
      'adjusted close': '121.360857298437',
      volume: '2985409',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-07': {
      open: '139.65',
      high: '139.79',
      low: '137.7614',
      close: '138.06',
      'adjusted close': '120.826999052587',
      volume: '3285363',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-03': {
      open: '139.68',
      high: '140.47',
      low: '139.3',
      close: '139.58',
      'adjusted close': '122.157268779951',
      volume: '1924215',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-02': {
      open: '139.72',
      high: '140.05',
      low: '139.03',
      close: '140.01',
      'adjusted close': '122.533595084403',
      volume: '2715659',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-09-01': {
      open: '139.98',
      high: '140.0699',
      low: '139.19',
      close: '139.3',
      'adjusted close': '121.912219093331',
      volume: '2474544',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-31': {
      open: '139.54',
      high: '140.94',
      low: '138.95',
      close: '140.34',
      'adjusted close': '122.822403643633',
      volume: '4235101',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-30': {
      open: '139.5',
      high: '139.88',
      low: '138.815',
      close: '138.97',
      'adjusted close': '121.623410534101',
      volume: '1995526',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-27': {
      open: '138.71',
      high: '139.585',
      low: '138.4',
      close: '139.41',
      'adjusted close': '122.008488613075',
      volume: '2459643',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-26': {
      open: '139.97',
      high: '140.8',
      low: '138.71',
      close: '138.78',
      'adjusted close': '121.45712681818',
      volume: '2498915',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-25': {
      open: '139.92',
      high: '140.8',
      low: '139.46',
      close: '139.86',
      'adjusted close': '122.402318466571',
      volume: '2012817',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-24': {
      open: '139.78',
      high: '140.23',
      low: '139.32',
      close: '139.84',
      'adjusted close': '122.384814917527',
      volume: '2365638',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-23': {
      open: '139.62',
      high: '140.15',
      low: '138.8',
      close: '139.62',
      'adjusted close': '122.19227587804',
      volume: '3039587',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-20': {
      open: '137.74',
      high: '139.38',
      low: '137.27',
      close: '139.11',
      'adjusted close': '121.745935377411',
      volume: '2657763',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-19': {
      open: '138.69',
      high: '139.45',
      low: '137.21',
      close: '138.02',
      'adjusted close': '120.791991954498',
      volume: '4160129',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-18': {
      open: '141.67',
      high: '141.915',
      low: '139.39',
      close: '139.47',
      'adjusted close': '122.060999260208',
      volume: '3510694',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-17': {
      open: '143.0',
      high: '143.16',
      low: '141.09',
      close: '142.42',
      'adjusted close': '124.642772744237',
      volume: '3074078',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-16': {
      open: '143.23',
      high: '143.74',
      low: '142.23',
      close: '143.59',
      'adjusted close': '125.666730363327',
      volume: '2786343',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-13': {
      open: '142.64',
      high: '143.58',
      low: '142.44',
      close: '143.18',
      'adjusted close': '125.307907607919',
      volume: '1908951',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-12': {
      open: '142.26',
      high: '143.15',
      low: '142.0766',
      close: '143.07',
      'adjusted close': '125.211638088176',
      volume: '2089418',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-11': {
      open: '141.78',
      high: '142.7685',
      low: '141.5',
      close: '142.13',
      'adjusted close': '124.388971283095',
      volume: '4259952',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-10': {
      open: '141.21',
      high: '141.811',
      low: '140.34',
      close: '141.38',
      'adjusted close': '123.732588193935',
      volume: '5299869',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-09': {
      open: '142.2',
      high: '142.495',
      low: '140.97',
      close: '141.25',
      'adjusted close': '123.618815125147',
      volume: '4904065',
      'dividend amount': '1.6400',
      'split coefficient': '1.0',
    },
    '2021-08-06': {
      open: '143.0',
      high: '144.39',
      low: '142.89',
      close: '144.09',
      'adjusted close': '124.656974395567',
      volume: '3826835',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-05': {
      open: '143.03',
      high: '143.41',
      low: '142.22',
      close: '142.77',
      'adjusted close': '123.514999198106',
      volume: '2757389',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-04': {
      open: '143.8',
      high: '144.18',
      low: '142.47',
      close: '142.76',
      'adjusted close': '123.506347870852',
      volume: '2830079',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-03': {
      open: '141.9',
      high: '144.7',
      low: '141.65',
      close: '144.07',
      'adjusted close': '124.63967174106',
      volume: '4084724',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-08-02': {
      open: '141.45',
      high: '143.06',
      low: '141.03',
      close: '141.42',
      'adjusted close': '122.347070018884',
      volume: '2929540',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-30': {
      open: '141.52',
      high: '141.85',
      low: '140.79',
      close: '140.96',
      'adjusted close': '121.949108965224',
      volume: '3535555',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-29': {
      open: '142.33',
      high: '142.96',
      low: '141.6',
      close: '141.93',
      'adjusted close': '122.788287708812',
      volume: '2657669',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-28': {
      open: '143.01',
      high: '143.1',
      low: '141.64',
      close: '141.77',
      'adjusted close': '122.649866472756',
      volume: '2544099',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-27': {
      open: '142.53',
      high: '143.64',
      low: '141.6',
      close: '142.75',
      'adjusted close': '123.497696543599',
      volume: '3137027',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-26': {
      open: '141.39',
      high: '143.0',
      low: '141.13',
      close: '142.77',
      'adjusted close': '123.514999198106',
      volume: '4246266',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-23': {
      open: '140.96',
      high: '141.7',
      low: '140.33',
      close: '141.34',
      'adjusted close': '122.277859400856',
      volume: '4474157',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-22': {
      open: '141.66',
      high: '141.81',
      low: '140.41',
      close: '140.71',
      'adjusted close': '121.732825783886',
      volume: '3314153',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-21': {
      open: '139.97',
      high: '141.39',
      low: '139.65',
      close: '141.3',
      'adjusted close': '122.243254091842',
      volume: '4803977',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-20': {
      open: '143.0',
      high: '144.92',
      low: '138.7',
      close: '139.97',
      'adjusted close': '121.092627567128',
      volume: '13611675',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-19': {
      open: '136.45',
      high: '138.49',
      low: '136.2089',
      close: '137.92',
      'adjusted close': '119.319105480162',
      volume: '8582302',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-16': {
      open: '141.0',
      high: '141.0',
      low: '138.59',
      close: '138.9',
      'adjusted close': '120.166935551004',
      volume: '4109308',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-15': {
      open: '139.32',
      high: '140.46',
      low: '138.8005',
      close: '140.45',
      'adjusted close': '121.507891275295',
      volume: '3639698',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-14': {
      open: '140.72',
      high: '140.75',
      low: '138.9273',
      close: '139.82',
      'adjusted close': '120.962857658325',
      volume: '4403752',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-13': {
      open: '140.92',
      high: '140.92',
      low: '139.63',
      close: '140.28',
      'adjusted close': '121.360818711986',
      volume: '3164294',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-12': {
      open: '141.43',
      high: '141.9599',
      low: '140.115',
      close: '140.92',
      'adjusted close': '121.91450365621',
      volume: '3342627',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-09': {
      open: '141.45',
      high: '141.98',
      low: '140.841',
      close: '141.52',
      'adjusted close': '122.433583291419',
      volume: '3904059',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-08': {
      open: '137.78',
      high: '141.31',
      low: '137.66',
      close: '140.74',
      'adjusted close': '121.758779765647',
      volume: '5487425',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-07': {
      open: '138.76',
      high: '140.33',
      low: '138.76',
      close: '139.82',
      'adjusted close': '120.962857658325',
      volume: '4059667',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-06': {
      open: '139.99',
      high: '140.42',
      low: '137.1',
      close: '138.78',
      'adjusted close': '120.063119623962',
      volume: '8093747',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-02': {
      open: '146.91',
      high: '146.95',
      low: '139.46',
      close: '140.02',
      'adjusted close': '121.135884203395',
      volume: '16828161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-07-01': {
      open: '146.96',
      high: '147.5',
      low: '146.57',
      close: '146.84',
      'adjusted close': '127.036089390277',
      volume: '2686289',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-30': {
      open: '145.13',
      high: '146.93',
      low: '144.71',
      close: '146.59',
      'adjusted close': '126.81980620894',
      volume: '3245091',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-29': {
      open: '145.26',
      high: '146.74',
      low: '145.1',
      close: '145.55',
      'adjusted close': '125.920068174576',
      volume: '2428626',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-28': {
      open: '147.01',
      high: '147.3',
      low: '144.9121',
      close: '145.29',
      'adjusted close': '125.695133665986',
      volume: '3888869',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-25': {
      open: '145.38',
      high: '146.93',
      low: '144.945',
      close: '146.84',
      'adjusted close': '127.036089390277',
      volume: '3594218',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-24': {
      open: '145.8',
      high: '146.19',
      low: '144.69',
      close: '145.44',
      'adjusted close': '125.824903574788',
      volume: '3609679',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-23': {
      open: '146.43',
      high: '146.5',
      low: '144.57',
      close: '144.61',
      'adjusted close': '125.106843412748',
      volume: '3202644',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-22': {
      open: '146.52',
      high: '146.81',
      low: '145.35',
      close: '146.36',
      'adjusted close': '126.620825682109',
      volume: '2437121',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-21': {
      open: '144.11',
      high: '147.07',
      low: '144.06',
      close: '146.65',
      'adjusted close': '126.87171417246',
      volume: '4285711',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-18': {
      open: '144.48',
      high: '144.68',
      low: '143.04',
      close: '143.12',
      'adjusted close': '123.817795651978',
      volume: '9156505',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-17': {
      open: '147.55',
      high: '148.06',
      low: '145.28',
      close: '145.6',
      'adjusted close': '125.963324810844',
      volume: '4367387',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-16': {
      open: '149.76',
      high: '149.76',
      low: '147.22',
      close: '147.83',
      'adjusted close': '127.892570788373',
      volume: '3795420',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-15': {
      open: '149.85',
      high: '149.91',
      low: '148.6',
      close: '149.36',
      'adjusted close': '129.216223858157',
      volume: '2513281',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-14': {
      open: '150.71',
      high: '151.03',
      low: '148.655',
      close: '150.03',
      'adjusted close': '129.795862784141',
      volume: '3344845',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-11': {
      open: '150.43',
      high: '151.845',
      low: '150.37',
      close: '151.28',
      'adjusted close': '130.877278690827',
      volume: '3438255',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-10': {
      open: '151.47',
      high: '152.84',
      low: '149.76',
      close: '150.54',
      'adjusted close': '130.237080474069',
      volume: '4758488',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-09': {
      open: '149.03',
      high: '151.07',
      low: '148.82',
      close: '150.67',
      'adjusted close': '130.349547728364',
      volume: '5303252',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-08': {
      open: '148.12',
      high: '150.2',
      low: '148.12',
      close: '149.07',
      'adjusted close': '128.965335367806',
      volume: '5080099',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-07': {
      open: '147.55',
      high: '148.74',
      low: '147.17',
      close: '148.02',
      'adjusted close': '128.056946006189',
      volume: '3462712',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-04': {
      open: '146.0',
      high: '147.55',
      low: '145.76',
      close: '147.42',
      'adjusted close': '127.537866370979',
      volume: '3117905',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-03': {
      open: '144.91',
      high: '145.88',
      low: '144.04',
      close: '145.55',
      'adjusted close': '125.920068174576',
      volume: '4130741',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-02': {
      open: '144.62',
      high: '145.75',
      low: '144.11',
      close: '145.72',
      'adjusted close': '126.067140737886',
      volume: '2786916',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-06-01': {
      open: '145.0',
      high: '145.83',
      low: '143.75',
      close: '144.19',
      'adjusted close': '124.743487668101',
      volume: '2417455',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-28': {
      open: '144.21',
      high: '144.33',
      low: '143.485',
      close: '143.74',
      'adjusted close': '124.354177941694',
      volume: '2534811',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-27': {
      open: '143.82',
      high: '144.77',
      low: '143.63',
      close: '143.82',
      'adjusted close': '124.423388559722',
      volume: '5630345',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-26': {
      open: '143.5',
      high: '143.9894',
      low: '143.04',
      close: '143.38',
      'adjusted close': '124.042730160569',
      volume: '3083789',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-25': {
      open: '144.92',
      high: '145.0',
      low: '143.2',
      close: '143.79',
      'adjusted close': '124.397434577962',
      volume: '3937323',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-24': {
      open: '145.06',
      high: '145.39',
      low: '144.18',
      close: '144.72',
      'adjusted close': '125.202008012537',
      volume: '3297570',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-21': {
      open: '144.28',
      high: '145.699',
      low: '144.28',
      close: '144.74',
      'adjusted close': '125.219310667044',
      volume: '4033482',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-20': {
      open: '143.12',
      high: '144.91',
      low: '142.16',
      close: '143.88',
      'adjusted close': '124.475296523243',
      volume: '4112690',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-19': {
      open: '142.32',
      high: '143.2',
      low: '140.92',
      close: '143.19',
      'adjusted close': '123.878354942752',
      volume: '4300732',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-18': {
      open: '144.78',
      high: '145.3',
      low: '143.23',
      close: '143.91',
      'adjusted close': '124.501250505004',
      volume: '3824050',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-17': {
      open: '144.44',
      high: '145.8',
      low: '144.33',
      close: '145.11',
      'adjusted close': '125.539409775423',
      volume: '4275172',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-14': {
      open: '145.11',
      high: '145.69',
      low: '143.96',
      close: '144.68',
      'adjusted close': '125.167402703523',
      volume: '2747385',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-13': {
      open: '141.45',
      high: '144.9',
      low: '141.28',
      close: '144.17',
      'adjusted close': '124.726185013594',
      volume: '4598920',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-12': {
      open: '143.84',
      high: '144.15',
      low: '141.14',
      close: '141.3',
      'adjusted close': '122.243254091842',
      volume: '5959579',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-11': {
      open: '144.99',
      high: '145.19',
      low: '142.9',
      close: '144.22',
      'adjusted close': '124.769441649862',
      volume: '7126404',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-10': {
      open: '145.8',
      high: '148.38',
      low: '145.8',
      close: '146.17',
      'adjusted close': '126.456450464293',
      volume: '6983377',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-07': {
      open: '145.92',
      high: '146.14',
      low: '144.57',
      close: '145.46',
      'adjusted close': '125.842206229295',
      volume: '7003467',
      'dividend amount': '1.6400',
      'split coefficient': '1.0',
    },
    '2021-05-06': {
      open: '145.26',
      high: '148.515',
      low: '145.18',
      close: '148.42',
      'adjusted close': '126.971449684242',
      volume: '7503487',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-05': {
      open: '145.94',
      high: '145.94',
      low: '144.97',
      close: '145.22',
      'adjusted close': '124.233889793462',
      volume: '4999900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-04': {
      open: '144.41',
      high: '146.59',
      low: '144.33',
      close: '145.75',
      'adjusted close': '124.687298150372',
      volume: '6350479',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-05-03': {
      open: '143.81',
      high: '145.75',
      low: '143.55',
      close: '144.75',
      'adjusted close': '123.831810684503',
      volume: '5733463',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-30': {
      open: '143.7',
      high: '143.83',
      low: '140.55',
      close: '141.88',
      'adjusted close': '121.37656165746',
      volume: '8872181',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-29': {
      open: '144.13',
      high: '148.74',
      low: '142.98',
      close: '144.24',
      'adjusted close': '123.39551207691',
      volume: '4353880',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-28': {
      open: '142.92',
      high: '143.4',
      low: '142.1',
      close: '143.0',
      'adjusted close': '122.334707619233',
      volume: '3768129',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-27': {
      open: '141.69',
      high: '142.56',
      low: '140.75',
      close: '142.01',
      'adjusted close': '121.487775028023',
      volume: '3884037',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-26': {
      open: '142.42',
      high: '143.63',
      low: '141.48',
      close: '141.57',
      'adjusted close': '121.111360543041',
      volume: '4710763',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-23': {
      open: '141.31',
      high: '143.61',
      low: '140.95',
      close: '142.43',
      'adjusted close': '121.847079763688',
      volume: '4556700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-22': {
      open: '143.7',
      high: '144.74',
      low: '141.0',
      close: '141.28',
      'adjusted close': '120.863269177939',
      volume: '7101368',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-21': {
      open: '138.06',
      high: '143.73',
      low: '137.71',
      close: '143.55',
      'adjusted close': '122.805225725461',
      volume: '11909005',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-20': {
      open: '137.07',
      high: '139.77',
      low: '136.7',
      close: '138.16',
      'adjusted close': '118.194148284428',
      volume: '15480579',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-19': {
      open: '133.6',
      high: '133.815',
      low: '132.58',
      close: '133.12',
      'adjusted close': '113.88249145645',
      volume: '8198582',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-16': {
      open: '133.0',
      high: '134.1',
      low: '132.95',
      close: '133.59',
      'adjusted close': '114.284570565408',
      volume: '5291756',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-15': {
      open: '133.28',
      high: '133.87',
      low: '132.22',
      close: '132.58',
      'adjusted close': '113.420528224881',
      volume: '3883955',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-14': {
      open: '131.305',
      high: '132.78',
      low: '130.52',
      close: '132.63',
      'adjusted close': '113.463302598174',
      volume: '5868049',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-13': {
      open: '133.0',
      high: '133.62',
      low: '130.38',
      close: '131.18',
      'adjusted close': '112.222845772664',
      volume: '8033530',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-12': {
      open: '135.02',
      high: '135.37',
      low: '133.85',
      close: '134.59',
      'adjusted close': '115.140058031277',
      volume: '3753959',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-09': {
      open: '134.87',
      high: '135.74',
      low: '134.71',
      close: '135.73',
      'adjusted close': '116.115313742367',
      volume: '3023916',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-08': {
      open: '134.57',
      high: '135.6299',
      low: '134.16',
      close: '135.12',
      'adjusted close': '115.593466388187',
      volume: '4087228',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-07': {
      open: '133.84',
      high: '134.94',
      low: '133.78',
      close: '134.93',
      'adjusted close': '115.430923769672',
      volume: '2976136',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-06': {
      open: '135.58',
      high: '135.64',
      low: '134.09',
      close: '134.22',
      'adjusted close': '114.823527668905',
      volume: '3620964',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-05': {
      open: '133.64',
      high: '136.69',
      low: '133.4',
      close: '135.93',
      'adjusted close': '116.286411235541',
      volume: '5471616',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-04-01': {
      open: '133.76',
      high: '133.93',
      low: '132.27',
      close: '133.23',
      'adjusted close': '113.976595077695',
      volume: '4074161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-31': {
      open: '134.54',
      high: '134.71',
      low: '132.71',
      close: '133.26',
      'adjusted close': '114.002259701671',
      volume: '4945315',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-30': {
      open: '135.86',
      high: '136.27',
      low: '134.02',
      close: '134.72',
      'adjusted close': '115.25127140184',
      volume: '4790366',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-29': {
      open: '135.98',
      high: '137.07',
      low: '135.51',
      close: '135.86',
      'adjusted close': '116.22652711293',
      volume: '4622664',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-26': {
      open: '133.29',
      high: '136.48',
      low: '133.12',
      close: '136.38',
      'adjusted close': '116.671380595182',
      volume: '5567592',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-25': {
      open: '130.33',
      high: '133.24',
      low: '129.77',
      close: '133.07',
      'adjusted close': '113.839717083156',
      volume: '5553966',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-24': {
      open: '130.95',
      high: '132.11',
      low: '130.57',
      close: '130.62',
      'adjusted close': '111.743772791778',
      volume: '4005027',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-23': {
      open: '130.44',
      high: '131.56',
      low: '129.8',
      close: '130.46',
      'adjusted close': '111.606894797239',
      volume: '4356359',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-22': {
      open: '128.5',
      high: '130.72',
      low: '127.89',
      close: '130.55',
      'adjusted close': '111.683888669167',
      volume: '4164914',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-19': {
      open: '130.02',
      high: '130.44',
      low: '128.53',
      close: '128.9',
      'adjusted close': '110.272334350483',
      volume: '9844983',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-18': {
      open: '128.94',
      high: '130.995',
      low: '127.79',
      close: '130.06',
      'adjusted close': '111.264699810891',
      volume: '5835669',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-17': {
      open: '128.46',
      high: '129.49',
      low: '127.49',
      close: '129.03',
      'adjusted close': '110.383547721046',
      volume: '4291351',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-16': {
      open: '128.28',
      high: '128.52',
      low: '127.34',
      close: '128.24',
      'adjusted close': '109.70771262301',
      volume: '4653178',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-15': {
      open: '127.77',
      high: '128.75',
      low: '127.5401',
      close: '128.58',
      'adjusted close': '109.998578361405',
      volume: '3421281',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-12': {
      open: '127.19',
      high: '127.68',
      low: '126.61',
      close: '127.61',
      'adjusted close': '109.168755519513',
      volume: '4010100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-11': {
      open: '128.09',
      high: '128.64',
      low: '126.78',
      close: '127.14',
      'adjusted close': '108.766676410554',
      volume: '5146937',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-10': {
      open: '125.05',
      high: '128.24',
      low: '124.61',
      close: '127.87',
      'adjusted close': '109.391182260639',
      volume: '7247807',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-09': {
      open: '125.4',
      high: '126.43',
      low: '124.16',
      close: '124.18',
      'adjusted close': '106.234433511583',
      volume: '5609029',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-08': {
      open: '122.99',
      high: '126.85',
      low: '122.88',
      close: '124.81',
      'adjusted close': '106.77339061508',
      volume: '7239191',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-05': {
      open: '120.64',
      high: '123.75',
      low: '120.25',
      close: '122.83',
      'adjusted close': '105.07952543266',
      volume: '6949265',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-04': {
      open: '122.0',
      high: '123.22',
      low: '118.755',
      close: '120.11',
      'adjusted close': '102.752599525497',
      volume: '8068492',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-03': {
      open: '120.5',
      high: '122.634',
      low: '119.98',
      close: '122.36',
      'adjusted close': '104.677446323702',
      volume: '7404269',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-02': {
      open: '120.74',
      high: '121.9',
      low: '120.26',
      close: '120.33',
      'adjusted close': '102.940806767988',
      volume: '4524299',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-03-01': {
      open: '120.35',
      high: '122.32',
      low: '119.8649',
      close: '120.74',
      'adjusted close': '103.291556628994',
      volume: '5715767',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-26': {
      open: '122.25',
      high: '122.25',
      low: '118.88',
      close: '118.93',
      'adjusted close': '101.743124315772',
      volume: '8868848',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-25': {
      open: '123.37',
      high: '124.35',
      low: '122.335',
      close: '122.47',
      'adjusted close': '104.771549944947',
      volume: '5672671',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-24': {
      open: '120.8',
      high: '123.49',
      low: '120.52',
      close: '123.21',
      'adjusted close': '105.40461066969',
      volume: '5819983',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-23': {
      open: '120.92',
      high: '121.35',
      low: '119.8',
      close: '120.71',
      'adjusted close': '103.265892005018',
      volume: '4531464',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-22': {
      open: '118.5',
      high: '121.125',
      low: '118.44',
      close: '120.86',
      'adjusted close': '103.394215124899',
      volume: '5838841',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-19': {
      open: '120.75',
      high: '120.76',
      low: '118.38',
      close: '118.99',
      'adjusted close': '101.794453563724',
      volume: '6578741',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-18': {
      open: '120.5',
      high: '120.94',
      low: '119.7',
      close: '120.73',
      'adjusted close': '103.283001754336',
      volume: '5399145',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-17': {
      open: '119.27',
      high: '120.56',
      low: '119.02',
      close: '119.97',
      'adjusted close': '102.632831280275',
      volume: '3949876',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-16': {
      open: '120.15',
      high: '120.6',
      low: '119.36',
      close: '120.07',
      'adjusted close': '102.718380026862',
      volume: '6639790',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-12': {
      open: '121.0',
      high: '121.36',
      low: '120.09',
      close: '120.8',
      'adjusted close': '103.342885876946',
      volume: '3871195',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-11': {
      open: '122.0',
      high: '122.205',
      low: '120.63',
      close: '120.91',
      'adjusted close': '103.436989498192',
      volume: '5381556',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-10': {
      open: '123.03',
      high: '123.41',
      low: '121.2138',
      close: '122.24',
      'adjusted close': '104.574787827798',
      volume: '4831858',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-09': {
      open: '121.9',
      high: '122.5953',
      low: '121.05',
      close: '122.1',
      'adjusted close': '104.455019582576',
      volume: '4661655',
      'dividend amount': '1.6300',
      'split coefficient': '1.0',
    },
    '2021-02-08': {
      open: '122.62',
      high: '123.9767',
      low: '122.4',
      close: '123.61',
      'adjusted close': '104.353713493916',
      volume: '5887999',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-05': {
      open: '121.0',
      high: '121.81',
      low: '120.52',
      close: '121.79',
      'adjusted close': '102.817237815905',
      volume: '4565727',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-04': {
      open: '119.91',
      high: '121.1',
      low: '118.87',
      close: '121.02',
      'adjusted close': '102.16719041367',
      volume: '4562124',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-03': {
      open: '119.04',
      high: '119.8',
      low: '118.12',
      close: '119.12',
      'adjusted close': '100.563177343219',
      volume: '6715366',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-02': {
      open: '119.36',
      high: '121.0',
      low: '119.28',
      close: '119.44',
      'adjusted close': '100.833326912979',
      volume: '6311881',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-02-01': {
      open: '119.9',
      high: '120.95',
      low: '118.73',
      close: '120.54',
      'adjusted close': '101.761966059029',
      volume: '6250508',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-29': {
      open: '120.22',
      high: '121.3',
      low: '118.9',
      close: '119.11',
      'adjusted close': '100.554735169164',
      volume: '11825496',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-28': {
      open: '122.8',
      high: '123.2',
      low: '120.07',
      close: '120.08',
      'adjusted close': '101.373626052499',
      volume: '8084051',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-27': {
      open: '122.08',
      high: '124.79',
      low: '121.6214',
      close: '122.47',
      'adjusted close': '103.391305651645',
      volume: '12146541',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-26': {
      open: '119.18',
      high: '122.59',
      low: '118.42',
      close: '122.49',
      'adjusted close': '103.408189999755',
      volume: '10694708',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-25': {
      open: '118.7624',
      high: '119.538',
      low: '117.45',
      close: '118.58',
      'adjusted close': '100.107299944248',
      volume: '13686391',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-22': {
      open: '120.7',
      high: '120.7',
      low: '117.36',
      close: '118.61',
      'adjusted close': '100.132626466413',
      volume: '38063533',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-21': {
      open: '130.12',
      high: '132.24',
      low: '130.05',
      close: '131.65',
      'adjusted close': '111.14122143414',
      volume: '12819233',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-20': {
      open: '129.7',
      high: '131.06',
      low: '128.56',
      close: '130.08',
      'adjusted close': '109.815800107504',
      volume: '5598705',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-19': {
      open: '129.28',
      high: '129.59',
      low: '128.0885',
      close: '129.02',
      'adjusted close': '108.920929657674',
      volume: '5397956',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-15': {
      open: '128.28',
      high: '129.24',
      low: '127.67',
      close: '128.39',
      'adjusted close': '108.389072692208',
      volume: '4905506',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-14': {
      open: '128.02',
      high: '130.16',
      low: '127.55',
      close: '128.97',
      'adjusted close': '108.878718787398',
      volume: '7503180',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-13': {
      open: '129.15',
      high: '129.75',
      low: '126.455',
      close: '126.92',
      'adjusted close': '107.148073106122',
      volume: '7677739',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-12': {
      open: '129.09',
      high: '129.85',
      low: '127.94',
      close: '129.21',
      'adjusted close': '109.081330964719',
      volume: '3729068',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-11': {
      open: '127.95',
      high: '129.675',
      low: '127.66',
      close: '128.58',
      'adjusted close': '108.549473999253',
      volume: '5602466',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-08': {
      open: '128.57',
      high: '129.32',
      low: '126.98',
      close: '128.53',
      'adjusted close': '108.507263128978',
      volume: '4676487',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-07': {
      open: '130.04',
      high: '130.46',
      low: '128.26',
      close: '128.99',
      'adjusted close': '108.895603135508',
      volume: '4507382',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-06': {
      open: '126.9',
      high: '131.88',
      low: '126.72',
      close: '129.29',
      'adjusted close': '109.148868357159',
      volume: '7956740',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-05': {
      open: '125.01',
      high: '126.68',
      low: '124.61',
      close: '126.14',
      'adjusted close': '106.489583529832',
      volume: '6114619',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2021-01-04': {
      open: '125.85',
      high: '125.9174',
      low: '123.04',
      close: '123.94',
      'adjusted close': '104.632305237731',
      volume: '5179161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-31': {
      open: '124.22',
      high: '126.03',
      low: '123.99',
      close: '125.88',
      'adjusted close': '106.270087004402',
      volume: '3574696',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-30': {
      open: '123.8',
      high: '124.85',
      low: '123.63',
      close: '124.34',
      'adjusted close': '104.969992199931',
      volume: '3380494',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-29': {
      open: '125.35',
      high: '125.48',
      low: '123.24',
      close: '123.8',
      'adjusted close': '104.514114800961',
      volume: '3487007',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-28': {
      open: '125.1',
      high: '126.6',
      low: '124.46',
      close: '124.82',
      'adjusted close': '105.375216554571',
      volume: '3583222',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-24': {
      open: '125.0',
      high: '125.1',
      low: '124.21',
      close: '124.69',
      'adjusted close': '105.265468291856',
      volume: '1761122',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-23': {
      open: '123.88',
      high: '125.21',
      low: '123.74',
      close: '123.9',
      'adjusted close': '104.598536541511',
      volume: '2693889',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-22': {
      open: '123.31',
      high: '124.22',
      low: '122.41',
      close: '123.61',
      'adjusted close': '104.353713493916',
      volume: '4337757',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-21': {
      open: '123.97',
      high: '124.18',
      low: '121.72',
      close: '123.39',
      'adjusted close': '104.167985664706',
      volume: '6115671',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-18': {
      open: '125.59',
      high: '126.4',
      low: '124.97',
      close: '125.85',
      'adjusted close': '106.244760482237',
      volume: '7552845',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-17': {
      open: '126.08',
      high: '126.09',
      low: '124.91',
      close: '125.55',
      'adjusted close': '105.991495260587',
      volume: '3787962',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-16': {
      open: '125.93',
      high: '126.5728',
      low: '125.286',
      close: '125.55',
      'adjusted close': '105.991495260587',
      volume: '4530096',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-15': {
      open: '124.39',
      high: '125.93',
      low: '123.44',
      close: '125.93',
      'adjusted close': '106.312297874677',
      volume: '4359601',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-14': {
      open: '125.32',
      high: '126.2435',
      low: '123.47',
      close: '123.53',
      'adjusted close': '104.286176101476',
      volume: '5050023',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-11': {
      open: '124.08',
      high: '125.51',
      low: '123.61',
      close: '124.27',
      'adjusted close': '104.910896981546',
      volume: '4481416',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-10': {
      open: '126.35',
      high: '126.93',
      low: '124.94',
      close: '124.96',
      'adjusted close': '105.493406991341',
      volume: '4803172',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-09': {
      open: '125.8',
      high: '127.69',
      low: '125.7',
      close: '126.79',
      'adjusted close': '107.038324843407',
      volume: '6513517',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-08': {
      open: '125.32',
      high: '126.33',
      low: '124.64',
      close: '125.71',
      'adjusted close': '106.126570045467',
      volume: '5395024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-07': {
      open: '126.49',
      high: '126.97',
      low: '124.57',
      close: '124.7',
      'adjusted close': '105.273910465911',
      volume: '8318500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-04': {
      open: '123.97',
      high: '127.38',
      low: '123.64',
      close: '127.2',
      'adjusted close': '107.384453979663',
      volume: '5522760',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-03': {
      open: '124.16',
      high: '124.86',
      low: '123.29',
      close: '123.61',
      'adjusted close': '104.353713493916',
      volume: '4548161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-02': {
      open: '122.85',
      high: '124.64',
      low: '122.41',
      close: '124.62',
      'adjusted close': '105.206373073471',
      volume: '3690737',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-12-01': {
      open: '123.9',
      high: '125.83',
      low: '123.08',
      close: '123.16',
      'adjusted close': '103.973815661441',
      volume: '5099334',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-30': {
      open: '124.1',
      high: '125.0',
      low: '123.09',
      close: '123.52',
      'adjusted close': '104.277733927421',
      volume: '5987991',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-27': {
      open: '124.2',
      high: '125.313',
      low: '123.91',
      close: '124.35',
      'adjusted close': '104.978434373986',
      volume: '2091186',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-25': {
      open: '122.93',
      high: '124.33',
      low: '122.11',
      close: '124.2',
      'adjusted close': '104.851801763161',
      volume: '4135894',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-24': {
      open: '120.86',
      high: '124.73',
      low: '120.805',
      close: '124.42',
      'adjusted close': '105.037529592371',
      volume: '7535949',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-23': {
      open: '117.43',
      high: '120.515',
      low: '117.27',
      close: '120.09',
      'adjusted close': '101.382068226554',
      volume: '5655119',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-20': {
      open: '117.6',
      high: '118.04',
      low: '116.69',
      close: '116.94',
      'adjusted close': '98.7227833992276',
      volume: '5024593',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-19': {
      open: '116.54',
      high: '117.45',
      low: '115.89',
      close: '117.18',
      'adjusted close': '98.9253955765477',
      volume: '3439648',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-18': {
      open: '117.72',
      high: '118.88',
      low: '116.75',
      close: '116.77',
      'adjusted close': '98.5792664402925',
      volume: '4606828',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-17': {
      open: '117.6',
      high: '118.54',
      low: '117.07',
      close: '117.7',
      'adjusted close': '99.3643886274079',
      volume: '4134455',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-16': {
      open: '118.3',
      high: '118.55',
      low: '117.12',
      close: '118.36',
      'adjusted close': '99.9215721150382',
      volume: '5293385',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-13': {
      open: '115.19',
      high: '117.37',
      low: '115.01',
      close: '116.85',
      'adjusted close': '98.6468038327325',
      volume: '4683512',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-12': {
      open: '115.63',
      high: '116.37',
      low: '113.48',
      close: '114.5',
      'adjusted close': '96.6628929298063',
      volume: '6500799',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-11': {
      open: '118.12',
      high: '118.35',
      low: '116.22',
      close: '117.2',
      'adjusted close': '98.9422799246577',
      volume: '4289601',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-10': {
      open: '116.69',
      high: '118.17',
      low: '116.25',
      close: '117.91',
      'adjusted close': '99.541674282563',
      volume: '5622756',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-09': {
      open: '117.88',
      high: '119.74',
      low: '115.27',
      close: '115.53',
      'adjusted close': '97.5324368574719',
      volume: '8992152',
      'dividend amount': '1.6300',
      'split coefficient': '1.0',
    },
    '2020-11-06': {
      open: '115.08',
      high: '115.1',
      low: '113.39',
      close: '114.04',
      'adjusted close': '94.9351237557707',
      volume: '5249171',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-05': {
      open: '113.3',
      high: '115.29',
      low: '113.01',
      close: '114.77',
      'adjusted close': '95.5428284237969',
      volume: '4902206',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-04': {
      open: '112.33',
      high: '113.91',
      low: '111.16',
      close: '111.9',
      'adjusted close': '93.153633359091',
      volume: '5800071',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-03': {
      open: '114.0',
      high: '115.65',
      low: '113.63',
      close: '114.16',
      'adjusted close': '95.0350204135284',
      volume: '4204287',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-11-02': {
      open: '112.65',
      high: '113.8265',
      low: '112.25',
      close: '112.91',
      'adjusted close': '93.994430228552',
      volume: '5311497',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-30': {
      open: '107.9',
      high: '111.8',
      low: '107.75',
      close: '111.66',
      'adjusted close': '92.9538400435755',
      volume: '7923882',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-29': {
      open: '107.25',
      high: '109.64',
      low: '106.55',
      close: '108.91',
      'adjusted close': '90.6645416366273',
      volume: '6760241',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-28': {
      open: '108.66',
      high: '109.73',
      low: '105.92',
      close: '106.65',
      'adjusted close': '88.78315458219',
      volume: '9427321',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-27': {
      open: '112.15',
      high: '112.22',
      low: '110.03',
      close: '110.56',
      'adjusted close': '92.0381206807963',
      volume: '5936106',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-26': {
      open: '114.45',
      high: '114.9',
      low: '111.84',
      close: '112.22',
      'adjusted close': '93.420024446445',
      volume: '7203366',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-23': {
      open: '116.5',
      high: '116.62',
      low: '115.53',
      close: '116.0',
      'adjusted close': '96.5667691658137',
      volume: '3893362',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-22': {
      open: '115.0',
      high: '116.06',
      low: '112.98',
      close: '115.76',
      'adjusted close': '96.3669758502983',
      volume: '7858158',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-21': {
      open: '116.66',
      high: '117.6899',
      low: '114.79',
      close: '115.06',
      'adjusted close': '95.7842453467114',
      volume: '9755308',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-20': {
      open: '119.8',
      high: '120.15',
      low: '116.84',
      close: '117.37',
      'adjusted close': '97.7072560085479',
      volume: '21501073',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-19': {
      open: '126.8',
      high: '127.35',
      low: '125.08',
      close: '125.52',
      'adjusted close': '104.491904014594',
      volume: '7478735',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-16': {
      open: '125.17',
      high: '126.43',
      low: '124.655',
      close: '125.93',
      'adjusted close': '104.833217595267',
      volume: '4714320',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-15': {
      open: '124.08',
      high: '125.215',
      low: '123.85',
      close: '124.89',
      'adjusted close': '103.967446561366',
      volume: '3389301',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-14': {
      open: '125.13',
      high: '126.94',
      low: '125.13',
      close: '125.94',
      'adjusted close': '104.841542316746',
      volume: '3730139',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-13': {
      open: '126.57',
      high: '127.15',
      low: '124.46',
      close: '125.1',
      'adjusted close': '104.142265712442',
      volume: '5406088',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-12': {
      open: '128.07',
      high: '128.25',
      low: '126.44',
      close: '127.21',
      'adjusted close': '105.898781944682',
      volume: '4635115',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-09': {
      open: '132.0',
      high: '132.0',
      low: '127.6',
      close: '127.79',
      'adjusted close': '106.381615790512',
      volume: '8353704',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-08': {
      open: '130.86',
      high: '135.5',
      low: '129.77',
      close: '131.49',
      'adjusted close': '109.461762738042',
      volume: '25288926',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-07': {
      open: '122.67',
      high: '124.39',
      low: '122.32',
      close: '124.07',
      'adjusted close': '103.284819400022',
      volume: '2815663',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-06': {
      open: '122.58',
      high: '124.83',
      low: '121.59',
      close: '121.97',
      'adjusted close': '101.536627889261',
      volume: '3872265',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-05': {
      open: '121.84',
      high: '122.75',
      low: '121.05',
      close: '122.01',
      'adjusted close': '101.56992677518',
      volume: '3050949',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-02': {
      open: '119.04',
      high: '121.75',
      low: '118.82',
      close: '120.57',
      'adjusted close': '100.371166882088',
      volume: '2925228',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-10-01': {
      open: '122.36',
      high: '123.3',
      low: '120.355',
      close: '121.09',
      'adjusted close': '100.804052399038',
      volume: '3211661',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-30': {
      open: '121.38',
      high: '122.91',
      low: '120.8',
      close: '121.67',
      'adjusted close': '101.286886244867',
      volume: '3261235',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-29': {
      open: '121.41',
      high: '122.1858',
      low: '120.21',
      close: '120.94',
      'adjusted close': '100.679181576841',
      volume: '2130564',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-28': {
      open: '120.57',
      high: '122.33',
      low: '120.41',
      close: '121.73',
      'adjusted close': '101.336834573746',
      volume: '3509576',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-25': {
      open: '117.6',
      high: '119.41',
      low: '116.94',
      close: '118.95',
      'adjusted close': '99.0225620023581',
      volume: '2953686',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-24': {
      open: '118.1',
      high: '119.515',
      low: '116.48',
      close: '118.09',
      'adjusted close': '98.3066359550943',
      volume: '3546160',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-23': {
      open: '120.8',
      high: '121.59',
      low: '118.43',
      close: '118.83',
      'adjusted close': '98.9226653446004',
      volume: '3939398',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-22': {
      open: '120.33',
      high: '121.45',
      low: '119.53',
      close: '120.51',
      'adjusted close': '100.321218553209',
      volume: '2957809',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-21': {
      open: '120.48',
      high: '120.7',
      low: '118.58',
      close: '120.25',
      'adjusted close': '100.104775794734',
      volume: '5311441',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-18': {
      open: '124.26',
      high: '124.92',
      low: '122.65',
      close: '122.76',
      'adjusted close': '102.194280886166',
      volume: '5391570',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-17': {
      open: '122.62',
      high: '125.55',
      low: '121.97',
      close: '124.92',
      'adjusted close': '103.992420725806',
      volume: '3171262',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-16': {
      open: '122.71',
      high: '125.82',
      low: '122.71',
      close: '124.22',
      'adjusted close': '103.409690222219',
      volume: '3789458',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-15': {
      open: '122.82',
      high: '123.4',
      low: '122.24',
      close: '122.44',
      'adjusted close': '101.927889798812',
      volume: '2915221',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-14': {
      open: '122.36',
      high: '123.38',
      low: '121.76',
      close: '122.09',
      'adjusted close': '101.636524547019',
      volume: '3641887',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-11': {
      open: '121.39',
      high: '122.7',
      low: '120.73',
      close: '121.46',
      'adjusted close': '101.112067093791',
      volume: '3548091',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-10': {
      open: '122.53',
      high: '123.5764',
      low: '120.24',
      close: '120.56',
      'adjusted close': '100.362842160608',
      volume: '3978805',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-09': {
      open: '122.13',
      high: '123.7',
      low: '121.29',
      close: '122.26',
      'adjusted close': '101.778044812176',
      volume: '3770678',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-08': {
      open: '122.16',
      high: '122.87',
      low: '120.71',
      close: '121.21',
      'adjusted close': '100.903949056796',
      volume: '5210292',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-04': {
      open: '124.35',
      high: '125.35',
      low: '121.24',
      close: '122.3',
      'adjusted close': '101.811343698095',
      volume: '6018238',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-03': {
      open: '128.19',
      high: '129.95',
      low: '123.65',
      close: '124.45',
      'adjusted close': '103.601158816254',
      volume: '5716750',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-02': {
      open: '123.72',
      high: '128.7',
      low: '123.575',
      close: '128.18',
      'adjusted close': '106.706279928224',
      volume: '6592430',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-09-01': {
      open: '122.85',
      high: '123.95',
      low: '122.15',
      close: '123.4',
      'adjusted close': '102.727063060874',
      volume: '3155623',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-31': {
      open: '125.25',
      high: '125.25',
      low: '123.03',
      close: '123.31',
      'adjusted close': '102.652140567556',
      volume: '4827879',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-28': {
      open: '124.96',
      high: '125.3',
      low: '124.2187',
      close: '125.07',
      'adjusted close': '104.117291548003',
      volume: '3099940',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-27': {
      open: '124.6',
      high: '125.1',
      low: '123.98',
      close: '124.65',
      'adjusted close': '103.767653245851',
      volume: '3422654',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-26': {
      open: '124.95',
      high: '125.12',
      low: '123.95',
      close: '124.17',
      'adjusted close': '103.36806661482',
      volume: '3388687',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-25': {
      open: '126.0',
      high: '126.82',
      low: '124.49',
      close: '124.64',
      'adjusted close': '103.759328524371',
      volume: '2977697',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-24': {
      open: '123.79',
      high: '126.06',
      low: '123.36',
      close: '125.68',
      'adjusted close': '104.625099558271',
      volume: '4070816',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-21': {
      open: '123.01',
      high: '123.4842',
      low: '122.31',
      close: '123.16',
      'adjusted close': '102.527269745359',
      volume: '3385051',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-20': {
      open: '123.2',
      high: '124.04',
      low: '122.81',
      close: '123.15',
      'adjusted close': '102.518945023879',
      volume: '2561164',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-19': {
      open: '124.83',
      high: '125.5',
      low: '123.5',
      close: '123.84',
      'adjusted close': '103.093350805986',
      volume: '3743949',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-18': {
      open: '125.0',
      high: '125.5',
      low: '124.24',
      close: '124.92',
      'adjusted close': '103.992420725806',
      volume: '2882355',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-17': {
      open: '125.25',
      high: '125.59',
      low: '124.41',
      close: '124.44',
      'adjusted close': '103.592834094775',
      volume: '3336459',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-14': {
      open: '124.2',
      high: '125.56',
      low: '123.91',
      close: '125.27',
      'adjusted close': '104.283785977599',
      volume: '2963753',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-13': {
      open: '125.96',
      high: '126.39',
      low: '124.77',
      close: '125.03',
      'adjusted close': '104.083992662084',
      volume: '3171258',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-12': {
      open: '127.61',
      high: '127.79',
      low: '125.876',
      close: '126.7',
      'adjusted close': '105.474221149212',
      volume: '3530177',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-11': {
      open: '128.76',
      high: '130.47',
      low: '126.61',
      close: '126.75',
      'adjusted close': '105.515844756611',
      volume: '5001176',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-10': {
      open: '125.42',
      high: '127.24',
      low: '125.1849',
      close: '127.11',
      'adjusted close': '105.815534729884',
      volume: '3968295',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-07': {
      open: '123.5',
      high: '125.07',
      low: '123.21',
      close: '124.96',
      'adjusted close': '104.025719611725',
      volume: '3651226',
      'dividend amount': '1.6300',
      'split coefficient': '1.0',
    },
    '2020-08-06': {
      open: '125.0',
      high: '126.26',
      low: '125.0',
      close: '126.12',
      'adjusted close': '103.639495674467',
      volume: '3417131',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-05': {
      open: '126.73',
      high: '126.75',
      low: '124.61',
      close: '125.45',
      'adjusted close': '103.088921125609',
      volume: '3675400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-04': {
      open: '123.82',
      high: '125.86',
      low: '123.82',
      close: '125.84',
      'adjusted close': '103.409404818228',
      volume: '3431366',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-08-03': {
      open: '123.5',
      high: '124.41',
      low: '122.15',
      close: '124.31',
      'adjusted close': '102.152122639494',
      volume: '3526574',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-31': {
      open: '122.71',
      high: '122.99',
      low: '121.09',
      close: '122.94',
      'adjusted close': '101.026320950039',
      volume: '5277652',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-30': {
      open: '123.71',
      high: '123.9046',
      low: '121.64',
      close: '122.9',
      'adjusted close': '100.993450827719',
      volume: '3988062',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-29': {
      open: '124.56',
      high: '125.99',
      low: '124.07',
      close: '125.32',
      'adjusted close': '102.98209322807',
      volume: '2833141',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-28': {
      open: '125.82',
      high: '126.34',
      low: '124.15',
      close: '124.47',
      'adjusted close': '102.283603128773',
      volume: '4178176',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-27': {
      open: '124.86',
      high: '126.32',
      low: '124.71',
      close: '126.21',
      'adjusted close': '103.713453449686',
      volume: '3733547',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-24': {
      open: '126.48',
      high: '127.6459',
      low: '125.5',
      close: '125.79',
      'adjusted close': '103.368317165328',
      volume: '3531076',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-23': {
      open: '129.1',
      high: '129.37',
      low: '127.15',
      close: '127.33',
      'adjusted close': '104.633816874642',
      volume: '4220136',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-22': {
      open: '125.9',
      high: '129.47',
      low: '125.8',
      close: '128.67',
      'adjusted close': '105.734965972357',
      volume: '8195366',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-21': {
      open: '131.16',
      high: '132.17',
      low: '125.8',
      close: '126.06',
      'adjusted close': '103.590190490987',
      volume: '15356679',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-20': {
      open: '126.07',
      high: '127.07',
      low: '125.14',
      close: '126.37',
      'adjusted close': '103.844933938966',
      volume: '9737157',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-17': {
      open: '124.39',
      high: '125.63',
      low: '123.2',
      close: '125.11',
      'adjusted close': '102.809525085891',
      volume: '3983972',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-16': {
      open: '122.68',
      high: '124.48',
      low: '122.16',
      close: '124.01',
      'adjusted close': '101.905596722095',
      volume: '4328856',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-15': {
      open: '122.4',
      high: '123.96',
      low: '122.15',
      close: '123.0',
      'adjusted close': '101.075626133519',
      volume: '4470095',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-14': {
      open: '118.62',
      high: '120.89',
      low: '117.92',
      close: '120.6',
      'adjusted close': '99.1034187943283',
      volume: '4531393',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-13': {
      open: '119.78',
      high: '120.99',
      low: '118.66',
      close: '119.2',
      'adjusted close': '97.9529645131338',
      volume: '4644595',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-10': {
      open: '115.5',
      high: '118.57',
      low: '115.29',
      close: '118.35',
      'adjusted close': '97.2544744138371',
      volume: '4285088',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-09': {
      open: '118.0',
      high: '118.0',
      low: '115.2',
      close: '115.71',
      'adjusted close': '95.0850463407274',
      volume: '4760875',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-08': {
      open: '118.06',
      high: '118.64',
      low: '116.48',
      close: '117.71',
      'adjusted close': '96.7285524567196',
      volume: '5191792',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-07': {
      open: '119.0',
      high: '119.51',
      low: '117.33',
      close: '117.58',
      'adjusted close': '96.6217245591801',
      volume: '4278088',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-06': {
      open: '121.25',
      high: '121.85',
      low: '119.46',
      close: '120.19',
      'adjusted close': '98.7665000405499',
      volume: '4044188',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-02': {
      open: '119.69',
      high: '121.42',
      low: '119.26',
      close: '119.7',
      'adjusted close': '98.3638410421318',
      volume: '3745663',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-07-01': {
      open: '120.27',
      high: '121.23',
      low: '118.37',
      close: '118.54',
      'adjusted close': '97.4106074948564',
      volume: '4657168',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-30': {
      open: '119.19',
      high: '121.2',
      low: '119.0',
      close: '120.77',
      'adjusted close': '99.2431168141876',
      volume: '3916381',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-29': {
      open: '117.98',
      high: '119.78',
      low: '117.37',
      close: '119.75',
      'adjusted close': '98.4049286950316',
      volume: '4159488',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-26': {
      open: '118.26',
      high: '118.99',
      low: '116.26',
      close: '117.19',
      'adjusted close': '96.3012408665616',
      volume: '10749166',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-25': {
      open: '116.76',
      high: '119.21',
      low: '116.1',
      close: '119.03',
      'adjusted close': '97.8132664932744',
      volume: '6128720',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-24': {
      open: '118.32',
      high: '118.32',
      low: '115.88',
      close: '116.42',
      'adjusted close': '95.6684910119046',
      volume: '6747421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-23': {
      open: '122.01',
      high: '122.9',
      low: '119.23',
      close: '119.41',
      'adjusted close': '98.1255326553129',
      volume: '6628278',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-22': {
      open: '122.12',
      high: '122.5',
      low: '120.53',
      close: '121.07',
      'adjusted close': '99.4896427315864',
      volume: '4762601',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-19': {
      open: '126.17',
      high: '126.82',
      low: '122.25',
      close: '122.47',
      'adjusted close': '100.640097012781',
      volume: '8098994',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-18': {
      open: '123.0',
      high: '124.4',
      low: '122.33',
      close: '124.16',
      'adjusted close': '102.028859680794',
      volume: '2860286',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-17': {
      open: '125.83',
      high: '126.25',
      low: '123.4',
      close: '124.15',
      'adjusted close': '102.020642150214',
      volume: '3153862',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-16': {
      open: '125.0',
      high: '127.5',
      low: '123.16',
      close: '125.15',
      'adjusted close': '102.84239520821',
      volume: '5605791',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-15': {
      open: '119.19',
      high: '122.37',
      low: '118.29',
      close: '121.65',
      'adjusted close': '99.9662595052242',
      volume: '5148756',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-12': {
      open: '121.25',
      high: '123.12',
      low: '119.28',
      close: '121.91',
      'adjusted close': '100.179915300303',
      volume: '6218003',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-11': {
      open: '126.03',
      high: '126.1',
      low: '117.84',
      close: '118.01',
      'adjusted close': '96.9750783741184',
      volume: '11753674',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-10': {
      open: '132.15',
      high: '132.23',
      low: '129.8',
      close: '129.87',
      'adjusted close': '106.721069641952',
      volume: '4725055',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-09': {
      open: '133.52',
      high: '133.6',
      low: '131.02',
      close: '131.87',
      'adjusted close': '108.364575757944',
      volume: '5391520',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-08': {
      open: '132.27',
      high: '135.88',
      low: '132.27',
      close: '135.75',
      'adjusted close': '111.552977622969',
      volume: '5368387',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-05': {
      open: '133.89',
      high: '134.23',
      low: '131.75',
      close: '132.06',
      'adjusted close': '108.520708838963',
      volume: '5892718',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-04': {
      open: '127.78',
      high: '128.95',
      low: '127.02',
      close: '128.89',
      'adjusted close': '105.915751645116',
      volume: '3864131',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-03': {
      open: '127.15',
      high: '129.6',
      low: '127.0',
      close: '129.05',
      'adjusted close': '106.047232134395',
      volume: '3913804',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-02': {
      open: '125.67',
      high: '126.0',
      low: '124.66',
      close: '126.0',
      'adjusted close': '103.540885307507',
      volume: '2976908',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-06-01': {
      open: '124.64',
      high: '125.75',
      low: '124.03',
      close: '124.89',
      'adjusted close': '102.628739413131',
      volume: '2867306',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-29': {
      open: '123.67',
      high: '125.13',
      low: '122.24',
      close: '124.9',
      'adjusted close': '102.636956943711',
      volume: '7929905',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-28': {
      open: '126.87',
      high: '126.97',
      low: '124.22',
      close: '124.53',
      'adjusted close': '102.332908312253',
      volume: '3915224',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-27': {
      open: '123.94',
      high: '125.83',
      low: '123.47',
      close: '125.54',
      'adjusted close': '103.162878900829',
      volume: '5725163',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-26': {
      open: '121.3',
      high: '122.62',
      low: '121.01',
      close: '121.76',
      'adjusted close': '100.056652341604',
      volume: '5467436',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-22': {
      open: '119.37',
      high: '119.47',
      low: '117.59',
      close: '118.39',
      'adjusted close': '97.2873445361569',
      volume: '4179906',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-21': {
      open: '120.99',
      high: '121.72',
      low: '118.97',
      close: '119.12',
      'adjusted close': '97.8872242684941',
      volume: '4018329',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-20': {
      open: '120.93',
      high: '122.47',
      low: '120.6',
      close: '121.38',
      'adjusted close': '99.7443861795652',
      volume: '3840563',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-19': {
      open: '121.19',
      high: '122.17',
      low: '120.14',
      close: '120.29',
      'adjusted close': '98.8486753463495',
      volume: '3239964',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-18': {
      open: '119.88',
      high: '122.36',
      low: '119.75',
      close: '121.56',
      'adjusted close': '99.8923017300045',
      volume: '4205425',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-15': {
      open: '115.93',
      high: '117.39',
      low: '115.25',
      close: '116.98',
      'adjusted close': '96.1286727243824',
      volume: '4785773',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-14': {
      open: '114.57',
      high: '117.09',
      low: '111.81',
      close: '116.95',
      'adjusted close': '96.1040201326426',
      volume: '5255607',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-13': {
      open: '119.95',
      high: '119.99',
      low: '114.85',
      close: '115.73',
      'adjusted close': '95.1014814018873',
      volume: '5874676',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-12': {
      open: '123.01',
      high: '124.32',
      low: '120.26',
      close: '120.26',
      'adjusted close': '98.8240227546096',
      volume: '4779635',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-11': {
      open: '121.81',
      high: '123.45',
      low: '120.67',
      close: '122.59',
      'adjusted close': '100.738707379741',
      volume: '3533382',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-08': {
      open: '122.67',
      high: '123.23',
      low: '121.06',
      close: '122.99',
      'adjusted close': '101.067408602939',
      volume: '5002450',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-07': {
      open: '122.98',
      high: '123.26',
      low: '120.85',
      close: '121.23',
      'adjusted close': '99.6211232208658',
      volume: '4412047',
      'dividend amount': '1.6300',
      'split coefficient': '1.0',
    },
    '2020-05-06': {
      open: '123.24',
      high: '124.05',
      low: '122.41',
      close: '123.17',
      'adjusted close': '99.8724869535573',
      volume: '3861081',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-05': {
      open: '123.39',
      high: '124.32',
      low: '122.47',
      close: '122.58',
      'adjusted close': '99.3940850106929',
      volume: '3897761',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-04': {
      open: '120.82',
      high: '121.97',
      low: '119.39',
      close: '121.68',
      'adjusted close': '98.6643193351372',
      volume: '4016551',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-05-01': {
      open: '123.19',
      high: '123.47',
      low: '121.39',
      close: '121.87',
      'adjusted close': '98.8183809777545',
      volume: '4923913',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-30': {
      open: '126.52',
      high: '127.27',
      low: '125.22',
      close: '125.56',
      'adjusted close': '101.810420247533',
      volume: '6630032',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-29': {
      open: '128.88',
      high: '129.31',
      low: '127.53',
      close: '128.69',
      'adjusted close': '104.348383096966',
      volume: '5252734',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-28': {
      open: '128.03',
      high: '128.2',
      low: '125.38',
      close: '126.27',
      'adjusted close': '102.386124280471',
      volume: '4775867',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-27': {
      open: '125.56',
      high: '126.99',
      low: '125.47',
      close: '125.92',
      'adjusted close': '102.102326517755',
      volume: '4920443',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-24': {
      open: '122.41',
      high: '125.0',
      low: '120.76',
      close: '124.72',
      'adjusted close': '101.129305617014',
      volume: '4985575',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-23': {
      open: '119.57',
      high: '123.03',
      low: '119.12',
      close: '121.35',
      'adjusted close': '98.3967385874334',
      volume: '6873899',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-22': {
      open: '119.87',
      high: '120.33',
      low: '117.55',
      close: '119.31',
      'adjusted close': '96.7426030561737',
      volume: '7085901',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-21': {
      open: '114.0',
      high: '117.15',
      low: '112.06',
      close: '116.76',
      'adjusted close': '94.6749336420991',
      volume: '14341304',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-20': {
      open: '119.15',
      high: '122.86',
      low: '118.14',
      close: '120.41',
      'adjusted close': '97.634538881853',
      volume: '7965530',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-17': {
      open: '119.3',
      high: '120.39',
      low: '117.92',
      close: '120.12',
      'adjusted close': '97.3993921641739',
      volume: '4964018',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-16': {
      open: '119.01',
      high: '119.75',
      low: '114.42',
      close: '115.73',
      'adjusted close': '93.8397573689631',
      volume: '6438128',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-15': {
      open: '119.12',
      high: '120.19',
      low: '116.76',
      close: '118.69',
      'adjusted close': '96.2398755907909',
      volume: '5632156',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-14': {
      open: '123.46',
      high: '125.18',
      low: '122.62',
      close: '123.91',
      'adjusted close': '100.472516509014',
      volume: '5087292',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-13': {
      open: '121.63',
      high: '121.8',
      low: '118.04',
      close: '121.15',
      'adjusted close': '98.2345684373099',
      volume: '5118777',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-09': {
      open: '120.48',
      high: '122.92',
      low: '120.17',
      close: '121.5',
      'adjusted close': '98.518366200026',
      volume: '5576210',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-08': {
      open: '116.31',
      high: '119.96',
      low: '115.07',
      close: '119.29',
      'adjusted close': '96.7263860411614',
      volume: '5155987',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-07': {
      open: '118.8',
      high: '119.57',
      low: '114.87',
      close: '114.94',
      'adjusted close': '93.1991852759752',
      volume: '5592463',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-06': {
      open: '110.35',
      high: '115.63',
      low: '110.13',
      close: '114.82',
      'adjusted close': '93.1018831859011',
      volume: '7026457',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-03': {
      open: '108.92',
      high: '110.07',
      low: '104.92',
      close: '106.34',
      'adjusted close': '86.2258688206648',
      volume: '4538113',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-02': {
      open: '105.37',
      high: '110.32',
      low: '105.14',
      close: '110.0',
      'adjusted close': '89.1935825679248',
      volume: '6327540',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-04-01': {
      open: '106.36',
      high: '109.92',
      low: '104.52',
      close: '105.14',
      'adjusted close': '85.2528479199238',
      volume: '6111890',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-31': {
      open: '112.0',
      high: '113.81',
      low: '110.17',
      close: '110.93',
      'adjusted close': '89.9476737659991',
      volume: '6342167',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-30': {
      open: '108.09',
      high: '113.46',
      low: '107.81',
      close: '112.93',
      'adjusted close': '91.5693752672341',
      volume: '5562501',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-27': {
      open: '108.58',
      high: '111.5',
      low: '107.65',
      close: '108.03',
      'adjusted close': '87.5962065892083',
      volume: '6421470',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-26': {
      open: '106.91',
      high: '113.15',
      low: '105.57',
      close: '112.89',
      'adjusted close': '91.5369412372094',
      volume: '7153135',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-25': {
      open: '105.48',
      high: '112.48',
      low: '102.83',
      close: '105.85',
      'adjusted close': '85.8285519528622',
      volume: '8646557',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-24': {
      open: '99.99',
      high: '106.04',
      low: '99.26',
      close: '105.48',
      'adjusted close': '85.5285371751337',
      volume: '7967768',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-23': {
      open: '94.6',
      high: '97.74',
      low: '90.56',
      close: '94.77',
      'adjusted close': '76.8443256360203',
      volume: '9718602',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-20': {
      open: '100.92',
      high: '100.99',
      low: '94.71',
      close: '95.39',
      'adjusted close': '77.3470531014032',
      volume: '10543213',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-19': {
      open: '102.33',
      high: '103.69',
      low: '98.24',
      close: '100.34',
      'adjusted close': '81.3607643169598',
      volume: '8391294',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-18': {
      open: '99.41',
      high: '106.93',
      low: '99.4',
      close: '103.55',
      'adjusted close': '83.9635952264419',
      volume: '8751994',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-17': {
      open: '100.61',
      high: '107.4',
      low: '97.1',
      close: '106.65',
      'adjusted close': '86.4772325533562',
      volume: '8887501',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-16': {
      open: '98.0',
      high: '107.41',
      low: '95.0',
      close: '99.08',
      'adjusted close': '80.3390923711817',
      volume: '10564044',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-13': {
      open: '108.68',
      high: '109.16',
      low: '100.81',
      close: '107.95',
      'adjusted close': '87.5313385291589',
      volume: '12493419',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-12': {
      open: '109.65',
      high: '109.8',
      low: '102.28',
      close: '102.81',
      'adjusted close': '83.363565670985',
      volume: '12485925',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-11': {
      open: '121.01',
      high: '122.58',
      low: '116.38',
      close: '117.97',
      'adjusted close': '95.6560630503463',
      volume: '8446480',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-10': {
      open: '122.78',
      high: '124.88',
      low: '115.76',
      close: '124.77',
      'adjusted close': '101.169848154545',
      volume: '11403865',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-09': {
      open: '120.16',
      high: '122.41',
      low: '117.28',
      close: '117.81',
      'adjusted close': '95.5263269302475',
      volume: '10750357',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-06': {
      open: '126.7',
      high: '128.33',
      low: '124.52',
      close: '127.73',
      'adjusted close': '103.569966376373',
      volume: '8192525',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-05': {
      open: '130.5',
      high: '132.29',
      low: '128.45',
      close: '129.55',
      'adjusted close': '105.045714742497',
      volume: '5445193',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-04': {
      open: '131.58',
      high: '134.28',
      low: '129.0',
      close: '134.22',
      'adjusted close': '108.832387747881',
      volume: '4994682',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-03': {
      open: '134.48',
      high: '136.1',
      low: '127.8',
      close: '128.9',
      'adjusted close': '104.518661754596',
      volume: '7893390',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-03-02': {
      open: '130.75',
      high: '134.44',
      low: '127.95',
      close: '134.3',
      'adjusted close': '108.89725580793',
      volume: '8528971',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-28': {
      open: '129.98',
      high: '131.09',
      low: '126.36',
      close: '130.15',
      'adjusted close': '105.532225192867',
      volume: '13009236',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-27': {
      open: '137.24',
      high: '138.69',
      low: '133.01',
      close: '133.11',
      'adjusted close': '107.932343414695',
      volume: '8404903',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-26': {
      open: '142.9',
      high: '144.06',
      low: '139.6',
      close: '139.75',
      'adjusted close': '113.316392398795',
      volume: '5407690',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-25': {
      open: '146.51',
      high: '147.07',
      low: '141.26',
      close: '141.71',
      'adjusted close': '114.905659870006',
      volume: '6210878',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-24': {
      open: '145.51',
      high: '148.05',
      low: '145.38',
      close: '146.43',
      'adjusted close': '118.73287541292',
      volume: '5447948',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-21': {
      open: '151.04',
      high: '151.04',
      low: '148.84',
      close: '149.84',
      'adjusted close': '121.497876472526',
      volume: '3536740',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-20': {
      open: '150.81',
      high: '151.62',
      low: '149.51',
      close: '151.22',
      'adjusted close': '122.616850508378',
      volume: '2880483',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-19': {
      open: '151.54',
      high: '151.89',
      low: '150.31',
      close: '150.86',
      'adjusted close': '122.324944238156',
      volume: '2732905',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-18': {
      open: '149.79',
      high: '151.38',
      low: '149.64',
      close: '151.1',
      'adjusted close': '122.519548418304',
      volume: '3343038',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-14': {
      open: '154.42',
      high: '154.56',
      low: '149.77',
      close: '150.7',
      'adjusted close': '122.195208118057',
      volume: '6095081',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-13': {
      open: '154.37',
      high: '154.83',
      low: '153.41',
      close: '154.31',
      'adjusted close': '125.122379327786',
      volume: '3819757',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-12': {
      open: '153.75',
      high: '155.6',
      low: '153.52',
      close: '155.31',
      'adjusted close': '125.933230078404',
      volume: '4437792',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-11': {
      open: '155.12',
      high: '155.22',
      low: '152.73',
      close: '153.48',
      'adjusted close': '124.449373204774',
      volume: '3833574',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-10': {
      open: '152.97',
      high: '154.44',
      low: '151.58',
      close: '154.43',
      'adjusted close': '125.21968141786',
      volume: '5237553',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-07': {
      open: '154.55',
      high: '155.54',
      low: '152.92',
      close: '153.41',
      'adjusted close': '124.39261365223',
      volume: '6414553',
      'dividend amount': '1.6200',
      'split coefficient': '1.0',
    },
    '2020-02-06': {
      open: '156.82',
      high: '158.75',
      low: '155.42',
      close: '156.76',
      'adjusted close': '125.780727060076',
      volume: '8394374',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-05': {
      open: '150.21',
      high: '157.44',
      low: '150.15',
      close: '156.33',
      'adjusted close': '125.435704652346',
      volume: '14379714',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-04': {
      open: '147.78',
      high: '149.38',
      low: '146.88',
      close: '149.11',
      'adjusted close': '119.642537713243',
      volume: '5619700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-02-03': {
      open: '144.25',
      high: '147.28',
      low: '143.9',
      close: '146.27',
      'adjusted close': '117.363785066837',
      volume: '8886414',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-31': {
      open: '142.9',
      high: '144.05',
      low: '140.79',
      close: '143.73',
      'adjusted close': '115.32574572815',
      volume: '18987138',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-30': {
      open: '136.76',
      high: '136.97',
      low: '134.97',
      close: '136.77',
      'adjusted close': '109.74119698907',
      volume: '5007038',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-29': {
      open: '139.17',
      high: '139.58',
      low: '137.6',
      close: '137.69',
      'adjusted close': '110.479384466075',
      volume: '3053189',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-28': {
      open: '139.5',
      high: '140.47',
      low: '138.75',
      close: '139.55',
      'adjusted close': '111.971806973932',
      volume: '3449000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-27': {
      open: '138.5',
      high: '140.07',
      low: '138.1',
      close: '138.62',
      'adjusted close': '111.225595720004',
      volume: '4165146',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-24': {
      open: '143.39',
      high: '143.92',
      low: '140.46',
      close: '140.56',
      'adjusted close': '112.782208443253',
      volume: '5579292',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-23': {
      open: '144.2',
      high: '144.41',
      low: '142.15',
      close: '142.87',
      'adjusted close': '114.635700912689',
      volume: '5654308',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-22': {
      open: '143.32',
      high: '145.79',
      low: '142.55',
      close: '143.89',
      'adjusted close': '115.454126158933',
      volume: '16464948',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-21': {
      open: '137.81',
      high: '139.35',
      low: '137.6',
      close: '139.17',
      'adjusted close': '111.666903450822',
      volume: '7130819',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-17': {
      open: '136.54',
      high: '138.33',
      low: '136.16',
      close: '138.31',
      'adjusted close': '110.976858635361',
      volume: '5622251',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-16': {
      open: '137.32',
      high: '138.19',
      low: '137.01',
      close: '137.98',
      'adjusted close': '110.71207399687',
      volume: '4319896',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-15': {
      open: '136.0',
      high: '138.06',
      low: '135.71',
      close: '136.62',
      'adjusted close': '109.620840335211',
      volume: '4045591',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-14': {
      open: '136.28',
      high: '137.14',
      low: '135.55',
      close: '135.82',
      'adjusted close': '108.978938181294',
      volume: '3680958',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-13': {
      open: '135.48',
      high: '136.64',
      low: '135.07',
      close: '136.6',
      'adjusted close': '109.604792781363',
      volume: '3162843',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-10': {
      open: '137.0',
      high: '137.87',
      low: '136.31',
      close: '136.69',
      'adjusted close': '109.677006773678',
      volume: '3255398',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-09': {
      open: '135.74',
      high: '136.79',
      low: '135.31',
      close: '136.74',
      'adjusted close': '109.717125658298',
      volume: '3730549',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-08': {
      open: '134.51',
      high: '135.86',
      low: '133.92',
      close: '135.31',
      'adjusted close': '108.569725558171',
      volume: '4345952',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-07': {
      open: '133.69',
      high: '134.96',
      low: '133.4',
      close: '134.19',
      'adjusted close': '107.671062542687',
      volume: '3267592',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-06': {
      open: '133.42',
      high: '134.24',
      low: '133.2',
      close: '134.1',
      'adjusted close': '107.598848550372',
      volume: '2421128',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-03': {
      open: '133.57',
      high: '134.86',
      low: '133.56',
      close: '134.34',
      'adjusted close': '107.791419196547',
      volume: '2373470',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2020-01-02': {
      open: '135.0',
      high: '135.92',
      low: '134.77',
      close: '135.42',
      'adjusted close': '108.657987104335',
      volume: '3148461',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-31': {
      open: '132.53',
      high: '134.12',
      low: '132.4',
      close: '134.04',
      'adjusted close': '107.550705888828',
      volume: '3777504',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-30': {
      open: '135.2',
      high: '135.3',
      low: '132.5',
      close: '132.81',
      'adjusted close': '106.56378132718',
      volume: '4118929',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-27': {
      open: '135.0',
      high: '135.75',
      low: '134.87',
      close: '135.27',
      'adjusted close': '108.537630450475',
      volume: '2752185',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-26': {
      open: '134.98',
      high: '135.31',
      low: '134.65',
      close: '134.91',
      'adjusted close': '108.248774481213',
      volume: '2129654',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-24': {
      open: '135.61',
      high: '135.62',
      low: '134.61',
      close: '134.98',
      'adjusted close': '108.30494091968',
      volume: '1202087',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-23': {
      open: '135.78',
      high: '136.15',
      low: '135.0',
      close: '135.55',
      'adjusted close': '108.762296204346',
      volume: '2803158',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-20': {
      open: '135.74',
      high: '136.42',
      low: '134.95',
      close: '135.59',
      'adjusted close': '108.794391312042',
      volume: '7111779',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-19': {
      open: '134.46',
      high: '134.73',
      low: '134.19',
      close: '134.56',
      'adjusted close': '107.967942288874',
      volume: '3866494',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-18': {
      open: '134.55',
      high: '135.0',
      low: '134.15',
      close: '134.41',
      'adjusted close': '107.847585635014',
      volume: '3101819',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-17': {
      open: '134.28',
      high: '134.69',
      low: '133.46',
      close: '134.22',
      'adjusted close': '107.695133873459',
      volume: '2907186',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-16': {
      open: '134.94',
      high: '135.45',
      low: '133.85',
      close: '134.13',
      'adjusted close': '107.622919881143',
      volume: '3049224',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-13': {
      open: '134.75',
      high: '135.5',
      low: '134.01',
      close: '134.21',
      'adjusted close': '107.687110096535',
      volume: '2534970',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-12': {
      open: '133.7',
      high: '135.66',
      low: '133.66',
      close: '135.32',
      'adjusted close': '108.577749335095',
      volume: '4824103',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-11': {
      open: '134.11',
      high: '134.51',
      low: '133.67',
      close: '133.76',
      'adjusted close': '107.326040134957',
      volume: '3698494',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-10': {
      open: '133.92',
      high: '134.84',
      low: '133.56',
      close: '133.91',
      'adjusted close': '107.446396788816',
      volume: '3478362',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-09': {
      open: '133.35',
      high: '134.59',
      low: '133.26',
      close: '133.92',
      'adjusted close': '107.45442056574',
      volume: '2968310',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-06': {
      open: '132.75',
      high: '133.89',
      low: '132.7',
      close: '133.22',
      'adjusted close': '106.892756181063',
      volume: '3437438',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-05': {
      open: '132.99',
      high: '133.24',
      low: '131.63',
      close: '131.91',
      'adjusted close': '105.841641404023',
      volume: '3595312',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-04': {
      open: '132.86',
      high: '133.68',
      low: '131.96',
      close: '131.99',
      'adjusted close': '105.905831619415',
      volume: '3530028',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-03': {
      open: '132.0',
      high: '132.44',
      low: '130.69',
      close: '132.12',
      'adjusted close': '106.010140719426',
      volume: '3708102',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-12-02': {
      open: '134.45',
      high: '134.5',
      low: '132.48',
      close: '132.91',
      'adjusted close': '106.64401909642',
      volume: '3066813',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-29': {
      open: '133.6',
      high: '134.52',
      low: '133.03',
      close: '134.45',
      'adjusted close': '107.87968074271',
      volume: '1825255',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-27': {
      open: '135.35',
      high: '135.71',
      low: '133.62',
      close: '133.77',
      'adjusted close': '107.334063911881',
      volume: '3280699',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-26': {
      open: '136.15',
      high: '136.15',
      low: '134.77',
      close: '135.09',
      'adjusted close': '108.393202465844',
      volume: '3806859',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-25': {
      open: '134.47',
      high: '136.0',
      low: '134.35',
      close: '135.97',
      'adjusted close': '109.099294835153',
      volume: '2590146',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-22': {
      open: '134.19',
      high: '134.65',
      low: '133.59',
      close: '134.34',
      'adjusted close': '107.791419196547',
      volume: '3297397',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-21': {
      open: '133.4',
      high: '134.17',
      low: '132.98',
      close: '133.84',
      'adjusted close': '107.390230350348',
      volume: '3322158',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-20': {
      open: '134.06',
      high: '134.46',
      low: '132.75',
      close: '133.2',
      'adjusted close': '106.876708627215',
      volume: '4138165',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-19': {
      open: '134.88',
      high: '135.38',
      low: '134.46',
      close: '134.52',
      'adjusted close': '107.935847181178',
      volume: '2995004',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-18': {
      open: '134.3',
      high: '134.48',
      low: '133.23',
      close: '134.31',
      'adjusted close': '107.767347865775',
      volume: '3210383',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-15': {
      open: '134.39',
      high: '135.12',
      low: '134.03',
      close: '134.4',
      'adjusted close': '107.83956185809',
      volume: '3262504',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-14': {
      open: '134.13',
      high: '134.33',
      low: '133.33',
      close: '134.0',
      'adjusted close': '107.518610781132',
      volume: '4231231',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-13': {
      open: '135.39',
      high: '135.58',
      low: '133.85',
      close: '134.48',
      'adjusted close': '107.903752073482',
      volume: '3060919',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-12': {
      open: '135.87',
      high: '136.66',
      low: '135.02',
      close: '135.59',
      'adjusted close': '108.794391312042',
      volume: '3046496',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-11': {
      open: '137.2',
      high: '137.2',
      low: '135.0',
      close: '135.47',
      'adjusted close': '108.698105988955',
      volume: '2568022',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-08': {
      open: '137.58',
      high: '137.61',
      low: '136.16',
      close: '137.61',
      'adjusted close': '110.415194250683',
      volume: '2278446',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-07': {
      open: '137.87',
      high: '139.14',
      low: '137.58',
      close: '137.69',
      'adjusted close': '110.479384466075',
      volume: '4073697',
      'dividend amount': '1.6200',
      'split coefficient': '1.0',
    },
    '2019-11-06': {
      open: '138.0',
      high: '138.78',
      low: '137.55',
      close: '138.78',
      'adjusted close': '110.059069529839',
      volume: '4476362',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-05': {
      open: '137.8',
      high: '138.76',
      low: '137.63',
      close: '137.89',
      'adjusted close': '109.353257655783',
      volume: '3012926',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-04': {
      open: '136.24',
      high: '137.74',
      low: '136.23',
      close: '137.67',
      'adjusted close': '109.178787304892',
      volume: '3333510',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-11-01': {
      open: '134.5',
      high: '135.56',
      low: '134.09',
      close: '135.53',
      'adjusted close': '107.481666618959',
      volume: '3088769',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-31': {
      open: '135.11',
      high: '135.25',
      low: '133.23',
      close: '133.73',
      'adjusted close': '106.054181929856',
      volume: '3410851',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-30': {
      open: '133.83',
      high: '135.28',
      low: '133.2',
      close: '135.25',
      'adjusted close': '107.259613445098',
      volume: '2252680',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-29': {
      open: '135.42',
      high: '135.57',
      low: '133.44',
      close: '133.82',
      'adjusted close': '106.125556164311',
      volume: '4158483',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-28': {
      open: '136.0',
      high: '136.63',
      low: '135.45',
      close: '135.97',
      'adjusted close': '107.830607320739',
      volume: '3225692',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-25': {
      open: '134.12',
      high: '135.93',
      low: '134.1',
      close: '135.44',
      'adjusted close': '107.410292384504',
      volume: '2577995',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-24': {
      open: '134.83',
      high: '135.07',
      low: '133.3',
      close: '134.07',
      'adjusted close': '106.323817926686',
      volume: '2619190',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-23': {
      open: '133.52',
      high: '134.57',
      low: '132.7',
      close: '134.38',
      'adjusted close': '106.569662512032',
      volume: '3626724',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-22': {
      open: '132.55',
      high: '134.05',
      low: '131.61',
      close: '133.96',
      'adjusted close': '106.236582751241',
      volume: '4193653',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-21': {
      open: '132.61',
      high: '133.1',
      low: '130.9',
      close: '132.58',
      'adjusted close': '105.142177822929',
      volume: '6370639',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-18': {
      open: '134.22',
      high: '134.49',
      low: '132.25',
      close: '134.09',
      'adjusted close': '106.339678867676',
      volume: '7177468',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-17': {
      open: '135.0',
      high: '136.0',
      low: '132.91',
      close: '134.26',
      'adjusted close': '106.474496866092',
      volume: '15824614',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-16': {
      open: '142.49',
      high: '142.95',
      low: '141.39',
      close: '142.11',
      'adjusted close': '112.69991620468',
      volume: '5835941',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-15': {
      open: '142.54',
      high: '143.72',
      low: '141.81',
      close: '143.0',
      'adjusted close': '113.405728078736',
      volume: '3044933',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-14': {
      open: '142.31',
      high: '142.43',
      low: '141.32',
      close: '142.04',
      'adjusted close': '112.644402911214',
      volume: '2325055',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-11': {
      open: '142.77',
      high: '144.5',
      low: '142.61',
      close: '142.76',
      'adjusted close': '113.215396786856',
      volume: '3015458',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-10': {
      open: '139.77',
      high: '141.78',
      low: '139.56',
      close: '141.13',
      'adjusted close': '111.922730096168',
      volume: '2619287',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-09': {
      open: '139.77',
      high: '140.33',
      low: '138.79',
      close: '139.67',
      'adjusted close': '110.764881403896',
      volume: '2631860',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-08': {
      open: '140.24',
      high: '140.47',
      low: '138.25',
      close: '138.38',
      'adjusted close': '109.741850710038',
      volume: '3342266',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-07': {
      open: '142.26',
      high: '142.7',
      low: '141.22',
      close: '141.28',
      'adjusted close': '112.041687153593',
      volume: '2487737',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-04': {
      open: '142.06',
      high: '143.06',
      low: '141.03',
      close: '142.99',
      'adjusted close': '113.397797608241',
      volume: '2447901',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-03': {
      open: '141.62',
      high: '142.07',
      low: '139.18',
      close: '142.02',
      'adjusted close': '112.628541970224',
      volume: '3138743',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-02': {
      open: '142.19',
      high: '142.27',
      low: '140.57',
      close: '141.69',
      'adjusted close': '112.366836443889',
      volume: '3347129',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-10-01': {
      open: '145.59',
      high: '147.35',
      low: '143.54',
      close: '143.66',
      'adjusted close': '113.929139131407',
      volume: '2973628',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-30': {
      open: '143.73',
      high: '146.57',
      low: '143.73',
      close: '145.42',
      'adjusted close': '115.32490193853',
      volume: '4004019',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-27': {
      open: '144.41',
      high: '145.09',
      low: '142.38',
      close: '143.24',
      'adjusted close': '113.596059370616',
      volume: '2356682',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-26': {
      open: '143.17',
      high: '143.88',
      low: '141.41',
      close: '143.55',
      'adjusted close': '113.841903955962',
      volume: '2313892',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-25': {
      open: '141.74',
      high: '143.63',
      low: '140.86',
      close: '143.16',
      'adjusted close': '113.532615606656',
      volume: '2544628',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-24': {
      open: '142.5',
      high: '142.89',
      low: '141.07',
      close: '141.68',
      'adjusted close': '112.358905973394',
      volume: '3311403',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-23': {
      open: '141.19',
      high: '142.22',
      low: '140.56',
      close: '142.07',
      'adjusted close': '112.6681943227',
      volume: '1849558',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-20': {
      open: '143.25',
      high: '143.83',
      low: '141.82',
      close: '141.88',
      'adjusted close': '112.517515383294',
      volume: '5217726',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-19': {
      open: '142.46',
      high: '145.03',
      low: '142.45',
      close: '142.97',
      'adjusted close': '113.381936667251',
      volume: '3181790',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-18': {
      open: '142.05',
      high: '142.3',
      low: '140.51',
      close: '142.22',
      'adjusted close': '112.787151380125',
      volume: '1994714',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-17': {
      open: '142.48',
      high: '142.48',
      low: '140.66',
      close: '142.2',
      'adjusted close': '112.771290439135',
      volume: '2940494',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-16': {
      open: '142.56',
      high: '143.64',
      low: '142.27',
      close: '142.48',
      'adjusted close': '112.993343612995',
      volume: '1928269',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-13': {
      open: '144.32',
      high: '144.65',
      low: '143.26',
      close: '143.67',
      'adjusted close': '113.937069601902',
      volume: '2206605',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-12': {
      open: '144.03',
      high: '144.04',
      low: '141.88',
      close: '143.62',
      'adjusted close': '113.897417249427',
      volume: '2504162',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-11': {
      open: '144.85',
      high: '145.12',
      low: '142.71',
      close: '143.6',
      'adjusted close': '113.881556308437',
      volume: '3867963',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-10': {
      open: '143.0',
      high: '145.46',
      low: '142.96',
      close: '145.05',
      'adjusted close': '115.031474530214',
      volume: '4979341',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-09': {
      open: '140.59',
      high: '143.02',
      low: '140.46',
      close: '142.6',
      'adjusted close': '113.088509258935',
      volume: '4218622',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-06': {
      open: '141.52',
      high: '141.53',
      low: '140.46',
      close: '140.57',
      'adjusted close': '111.478623748447',
      volume: '2576441',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-05': {
      open: '138.3',
      high: '141.7',
      low: '138.05',
      close: '140.97',
      'adjusted close': '111.795842568248',
      volume: '5011628',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-04': {
      open: '135.71',
      high: '136.43',
      low: '135.15',
      close: '136.32',
      'adjusted close': '108.108173788065',
      volume: '2247924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-09-03': {
      open: '134.85',
      high: '134.9',
      low: '133.33',
      close: '134.1',
      'adjusted close': '106.347609338171',
      volume: '2827919',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-30': {
      open: '135.58',
      high: '136.07',
      low: '134.36',
      close: '135.53',
      'adjusted close': '107.481666618959',
      volume: '2960594',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-29': {
      open: '134.18',
      high: '135.69',
      low: '134.04',
      close: '134.88',
      'adjusted close': '106.966186036783',
      volume: '2972842',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-28': {
      open: '130.44',
      high: '132.89',
      low: '130.04',
      close: '132.76',
      'adjusted close': '105.284926291839',
      volume: '2713251',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-27': {
      open: '131.2',
      high: '131.7',
      low: '130.31',
      close: '131.17',
      'adjusted close': '104.023981483132',
      volume: '4728392',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-26': {
      open: '131.05',
      high: '131.3',
      low: '129.16',
      close: '129.99',
      'adjusted close': '103.08818596472',
      volume: '2836837',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-23': {
      open: '133.63',
      high: '134.26',
      low: '128.83',
      close: '129.57',
      'adjusted close': '102.755106203929',
      volume: '4818759',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-22': {
      open: '134.69',
      high: '135.68',
      low: '133.81',
      close: '134.32',
      'adjusted close': '106.522079689062',
      volume: '2695009',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-21': {
      open: '134.96',
      high: '135.94',
      low: '133.8',
      close: '134.25',
      'adjusted close': '106.466566395597',
      volume: '2806774',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-20': {
      open: '135.24',
      high: '135.28',
      low: '132.81',
      close: '133.0',
      'adjusted close': '105.47525758372',
      volume: '3018934',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-19': {
      open: '134.88',
      high: '136.33',
      low: '134.88',
      close: '135.04',
      'adjusted close': '107.093073564703',
      volume: '3165436',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-16': {
      open: '133.04',
      high: '134.59',
      low: '132.95',
      close: '133.76',
      'adjusted close': '106.077973341341',
      volume: '3011993',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-15': {
      open: '131.22',
      high: '132.37',
      low: '130.25',
      close: '131.92',
      'adjusted close': '104.618766770258',
      volume: '3738945',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-14': {
      open: '133.91',
      high: '134.16',
      low: '130.98',
      close: '131.25',
      'adjusted close': '104.087425247092',
      volume: '5114159',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-13': {
      open: '133.8',
      high: '136.57',
      low: '132.81',
      close: '135.79',
      'adjusted close': '107.687858851829',
      volume: '4551170',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-12': {
      open: '135.66',
      high: '135.87',
      low: '133.18',
      close: '134.12',
      'adjusted close': '106.363470279161',
      volume: '4516866',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-09': {
      open: '139.27',
      high: '139.31',
      low: '135.35',
      close: '136.13',
      'adjusted close': '107.95749484866',
      volume: '5244594',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-08': {
      open: '138.45',
      high: '140.42',
      low: '137.76',
      close: '140.1',
      'adjusted close': '111.105891635181',
      volume: '5261444',
      'dividend amount': '1.6200',
      'split coefficient': '1.0',
    },
    '2019-08-07': {
      open: '138.74',
      high: '139.58',
      low: '136.41',
      close: '139.11',
      'adjusted close': '109.059699304051',
      volume: '5931883',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-06': {
      open: '142.03',
      high: '142.47',
      low: '139.31',
      close: '140.73',
      'adjusted close': '110.329749716477',
      volume: '5070650',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-05': {
      open: '144.98',
      high: '145.1',
      low: '139.15',
      close: '140.76',
      'adjusted close': '110.353269168559',
      volume: '7114871',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-02': {
      open: '149.03',
      high: '152.95',
      low: '145.59',
      close: '147.25',
      'adjusted close': '115.441310635623',
      volume: '8101720',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-08-01': {
      open: '148.9',
      high: '152.82',
      low: '148.5',
      close: '150.32',
      'adjusted close': '117.848134565343',
      volume: '6344006',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-31': {
      open: '149.7',
      high: '150.18',
      low: '146.39',
      close: '148.24',
      'adjusted close': '116.217452554327',
      volume: '3722907',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-30': {
      open: '150.0',
      high: '150.4',
      low: '149.22',
      close: '149.77',
      'adjusted close': '117.416944610507',
      volume: '2632726',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-29': {
      open: '151.11',
      high: '151.48',
      low: '150.32',
      close: '150.88',
      'adjusted close': '118.28716433754',
      volume: '2075528',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-26': {
      open: '151.0',
      high: '151.41',
      low: '150.1',
      close: '151.36',
      'adjusted close': '118.663475570851',
      volume: '2904494',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-25': {
      open: '149.82',
      high: '150.62',
      low: '148.61',
      close: '150.39',
      'adjusted close': '117.903013286868',
      volume: '3102113',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-24': {
      open: '150.12',
      high: '151.45',
      low: '149.2',
      close: '150.03',
      'adjusted close': '117.620779861884',
      volume: '2952435',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-23': {
      open: '150.57',
      high: '150.72',
      low: '148.38',
      close: '150.38',
      'adjusted close': '117.895173469507',
      volume: '3742204',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-22': {
      open: '150.16',
      high: '151.94',
      low: '149.7',
      close: '149.74',
      'adjusted close': '117.393425158425',
      volume: '4093239',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-19': {
      open: '149.96',
      high: '151.58',
      low: '149.55',
      close: '149.68',
      'adjusted close': '117.346386254261',
      volume: '7290678',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-18': {
      open: '142.5',
      high: '150.31',
      low: '142.2',
      close: '149.63',
      'adjusted close': '117.307187167458',
      volume: '12695387',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-17': {
      open: '143.61',
      high: '143.8',
      low: '141.95',
      close: '143.07',
      'adjusted close': '112.16426697887',
      volume: '6064265',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-16': {
      open: '143.17',
      high: '144.04',
      low: '143.02',
      close: '143.53',
      'adjusted close': '112.52489857746',
      volume: '3474688',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-15': {
      open: '142.89',
      high: '143.51',
      low: '142.12',
      close: '143.32',
      'adjusted close': '112.360262412886',
      volume: '2368309',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-12': {
      open: '141.92',
      high: '142.92',
      low: '141.66',
      close: '142.74',
      'adjusted close': '111.905553005968',
      volume: '2786276',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-11': {
      open: '140.79',
      high: '141.58',
      low: '140.3',
      close: '141.24',
      'adjusted close': '110.72958040187',
      volume: '2133327',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-10': {
      open: '140.0',
      high: '141.91',
      low: '139.79',
      close: '140.47',
      'adjusted close': '110.1259144651',
      volume: '2513931',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-09': {
      open: '140.61',
      high: '140.99',
      low: '139.13',
      close: '139.33',
      'adjusted close': '109.232175285985',
      volume: '3365227',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-08': {
      open: '141.05',
      high: '141.29',
      low: '140.27',
      close: '140.57',
      'adjusted close': '110.204312638706',
      volume: '2395487',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-05': {
      open: '140.87',
      high: '141.49',
      low: '139.91',
      close: '141.38',
      'adjusted close': '110.839337844919',
      volume: '2135085',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-03': {
      open: '140.72',
      high: '141.82',
      low: '140.43',
      close: '141.54',
      'adjusted close': '110.96477492269',
      volume: '1930361',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-02': {
      open: '139.75',
      high: '140.43',
      low: '139.47',
      close: '140.22',
      'adjusted close': '109.929919031083',
      volume: '2410092',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-07-01': {
      open: '139.6',
      high: '141.49',
      low: '139.28',
      close: '139.88',
      'adjusted close': '109.663365240821',
      volume: '3387184',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-28': {
      open: '138.57',
      high: '139.14',
      low: '137.84',
      close: '137.9',
      'adjusted close': '108.111081403412',
      volume: '6154039',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-27': {
      open: '138.72',
      high: '139.3',
      low: '137.95',
      close: '138.52',
      'adjusted close': '108.597150079772',
      volume: '2149824',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-26': {
      open: '138.87',
      high: '139.68',
      low: '138.41',
      close: '138.52',
      'adjusted close': '108.597150079772',
      volume: '2284567',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-25': {
      open: '139.46',
      high: '139.55',
      low: '138.13',
      close: '138.36',
      'adjusted close': '108.471713002002',
      volume: '2801251',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-24': {
      open: '139.2',
      high: '140.15',
      low: '139.05',
      close: '139.35',
      'adjusted close': '109.247854920706',
      volume: '2186137',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-21': {
      open: '138.44',
      high: '139.54',
      low: '138.39',
      close: '139.2',
      'adjusted close': '109.130257660297',
      volume: '5288759',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-20': {
      open: '138.93',
      high: '139.54',
      low: '138.2',
      close: '138.85',
      'adjusted close': '108.855864052674',
      volume: '3071017',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-19': {
      open: '136.43',
      high: '138.36',
      low: '136.24',
      close: '137.08',
      'adjusted close': '107.468216379838',
      volume: '2382520',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-18': {
      open: '135.2',
      high: '137.19',
      low: '135.2',
      close: '136.38',
      'adjusted close': '106.919429164592',
      volume: '2914698',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-17': {
      open: '135.39',
      high: '136.03',
      low: '134.7',
      close: '134.95',
      'adjusted close': '105.798335282019',
      volume: '2194129',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-14': {
      open: '135.44',
      high: '135.88',
      low: '134.43',
      close: '135.15',
      'adjusted close': '105.955131629232',
      volume: '2193367',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-13': {
      open: '135.13',
      high: '136.27',
      low: '135.09',
      close: '135.76',
      'adjusted close': '106.433360488232',
      volume: '2955419',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-12': {
      open: '135.56',
      high: '135.94',
      low: '134.41',
      close: '134.87',
      'adjusted close': '105.735616743134',
      volume: '2254741',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-11': {
      open: '135.32',
      high: '136.46',
      low: '135.05',
      close: '135.95',
      'adjusted close': '106.582317018084',
      volume: '3407345',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-10': {
      open: '134.38',
      high: '135.35',
      low: '133.91',
      close: '134.74',
      'adjusted close': '105.633699117445',
      volume: '2911327',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-07': {
      open: '132.47',
      high: '134.72',
      low: '132.21',
      close: '133.31',
      'adjusted close': '104.512605234872',
      volume: '2553398',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-06': {
      open: '132.19',
      high: '132.65',
      low: '130.9',
      close: '132.22',
      'adjusted close': '103.65806514256',
      volume: '2298248',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-05': {
      open: '133.38',
      high: '133.61',
      low: '130.47',
      close: '131.49',
      'adjusted close': '103.085758475233',
      volume: '3161584',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-04': {
      open: '129.56',
      high: '132.74',
      low: '129.09',
      close: '132.69',
      'adjusted close': '104.026536558511',
      volume: '3913535',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-06-03': {
      open: '127.1',
      high: '128.56',
      low: '127.06',
      close: '128.27',
      'adjusted close': '100.561337285102',
      volume: '4267080',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-31': {
      open: '128.44',
      high: '128.44',
      low: '126.85',
      close: '126.99',
      'adjusted close': '99.5578406629387',
      volume: '3539343',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-30': {
      open: '129.74',
      high: '129.97',
      low: '128.93',
      close: '129.57',
      'adjusted close': '101.580513541987',
      volume: '2741880',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-29': {
      open: '130.0',
      high: '130.28',
      low: '128.32',
      close: '129.69',
      'adjusted close': '101.674591350315',
      volume: '3678978',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-28': {
      open: '132.13',
      high: '132.64',
      low: '130.27',
      close: '130.46',
      'adjusted close': '102.278257287085',
      volume: '4909121',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-24': {
      open: '133.53',
      high: '134.25',
      low: '131.59',
      close: '132.28',
      'adjusted close': '103.705104046724',
      volume: '2596121',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-23': {
      open: '135.13',
      high: '135.13',
      low: '130.44',
      close: '132.39',
      'adjusted close': '103.791342037692',
      volume: '5343437',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-22': {
      open: '136.0',
      high: '136.75',
      low: '135.71',
      close: '136.35',
      'adjusted close': '106.89590971251',
      volume: '1849821',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-21': {
      open: '136.12',
      high: '137.06',
      low: '135.79',
      close: '136.45',
      'adjusted close': '106.974307886117',
      volume: '2503504',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-20': {
      open: '133.53',
      high: '135.43',
      low: '132.93',
      close: '135.12',
      'adjusted close': '105.93161217715',
      volume: '3264649',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-17': {
      open: '134.68',
      high: '135.41',
      low: '133.94',
      close: '134.32',
      'adjusted close': '105.304426788298',
      volume: '2619135',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-16': {
      open: '134.74',
      high: '136.11',
      low: '134.64',
      close: '135.88',
      'adjusted close': '106.52743829656',
      volume: '3017472',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-15': {
      open: '132.59',
      high: '134.59',
      low: '132.37',
      close: '134.4',
      'adjusted close': '105.367145327183',
      volume: '2551009',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-14': {
      open: '131.82',
      high: '134.32',
      low: '131.68',
      close: '133.31',
      'adjusted close': '104.512605234872',
      volume: '3280374',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-13': {
      open: '133.28',
      high: '133.57',
      low: '130.96',
      close: '131.42',
      'adjusted close': '103.030879753708',
      volume: '4958021',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-10': {
      open: '134.88',
      high: '135.75',
      low: '132.42',
      close: '135.32',
      'adjusted close': '106.088408524363',
      volume: '3977686',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-09': {
      open: '134.89',
      high: '135.58',
      low: '133.03',
      close: '135.34',
      'adjusted close': '106.104088159084',
      volume: '4192942',
      'dividend amount': '1.6200',
      'split coefficient': '1.0',
    },
    '2019-05-08': {
      open: '137.77',
      high: '138.7',
      low: '137.13',
      close: '138.0',
      'adjusted close': '106.909785090199',
      volume: '3818909',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-07': {
      open: '139.15',
      high: '139.51',
      low: '136.19',
      close: '137.64',
      'adjusted close': '106.630889998659',
      volume: '4726771',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-06': {
      open: '138.3',
      high: '140.69',
      low: '137.9',
      close: '140.38',
      'adjusted close': '108.753591528711',
      volume: '2793655',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-03': {
      open: '139.7',
      high: '141.0',
      low: '139.63',
      close: '140.25',
      'adjusted close': '108.652879412322',
      volume: '2580034',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-02': {
      open: '140.5',
      high: '141.28',
      low: '139.41',
      close: '139.59',
      'adjusted close': '108.141571744499',
      volume: '3541352',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-05-01': {
      open: '140.55',
      high: '141.81',
      low: '140.17',
      close: '140.56',
      'adjusted close': '108.893039074481',
      volume: '3053683',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-30': {
      open: '139.11',
      high: '140.44',
      low: '138.68',
      close: '140.27',
      'adjusted close': '108.668373584074',
      volume: '4638828',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-29': {
      open: '139.15',
      high: '139.63',
      low: '138.81',
      close: '139.05',
      'adjusted close': '107.72322910719',
      volume: '2958333',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-26': {
      open: '139.34',
      high: '139.89',
      low: '138.81',
      close: '139.44',
      'adjusted close': '108.025365456358',
      volume: '2319834',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-25': {
      open: '139.7',
      high: '139.75',
      low: '137.71',
      close: '138.63',
      'adjusted close': '107.397851500393',
      volume: '2910113',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-24': {
      open: '140.6',
      high: '141.31',
      low: '139.78',
      close: '139.95',
      'adjusted close': '108.420466836039',
      volume: '2774867',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-23': {
      open: '139.15',
      high: '140.92',
      low: '138.94',
      close: '140.44',
      'adjusted close': '108.800074043968',
      volume: '4462240',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-22': {
      open: '139.42',
      high: '140.56',
      low: '138.48',
      close: '138.89',
      'adjusted close': '107.599275733172',
      volume: '4494333',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-18': {
      open: '139.0',
      high: '140.49',
      low: '138.7',
      close: '140.33',
      'adjusted close': '108.714856099331',
      volume: '4960015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-17': {
      open: '137.37',
      high: '141.98',
      low: '136.26',
      close: '139.11',
      'adjusted close': '107.769711622446',
      volume: '12525693',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-16': {
      open: '144.4',
      high: '145.39',
      low: '144.02',
      close: '145.14',
      'adjusted close': '112.441204405735',
      volume: '6301671',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-15': {
      open: '144.4',
      high: '144.46',
      low: '143.27',
      close: '143.9',
      'adjusted close': '111.480565757099',
      volume: '3603158',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-12': {
      open: '144.26',
      high: '144.44',
      low: '143.7',
      close: '144.35',
      'adjusted close': '111.829184621523',
      volume: '2898899',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-11': {
      open: '143.8',
      high: '144.11',
      low: '143.03',
      close: '143.78',
      'adjusted close': '111.387600726586',
      volume: '2941985',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-10': {
      open: '142.2',
      high: '143.5',
      low: '141.98',
      close: '143.02',
      'adjusted close': '110.798822200002',
      volume: '2678310',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-09': {
      open: '142.66',
      high: '142.95',
      low: '141.84',
      close: '142.11',
      'adjusted close': '110.093837385277',
      volume: '3038460',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-08': {
      open: '143.02',
      high: '143.42',
      low: '142.87',
      close: '143.39',
      'adjusted close': '111.085464377418',
      volume: '2118228',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-05': {
      open: '143.29',
      high: '143.5',
      low: '142.46',
      close: '143.28',
      'adjusted close': '111.00024643278',
      volume: '2731985',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-04': {
      open: '143.62',
      high: '144.14',
      low: '142.54',
      close: '142.78',
      'adjusted close': '110.612892138975',
      volume: '2771462',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-03': {
      open: '143.65',
      high: '144.22',
      low: '143.01',
      close: '143.63',
      'adjusted close': '111.271394438444',
      volume: '2681645',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-02': {
      open: '143.35',
      high: '143.95',
      low: '142.6',
      close: '143.0',
      'adjusted close': '110.78332802825',
      volume: '2404920',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-04-01': {
      open: '141.51',
      high: '143.41',
      low: '141.51',
      close: '143.3',
      'adjusted close': '111.015740604533',
      volume: '3984250',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-29': {
      open: '140.5',
      high: '141.22',
      low: '140.15',
      close: '141.1',
      'adjusted close': '109.31138171179',
      volume: '3101454',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-28': {
      open: '139.91',
      high: '140.44',
      low: '139.1',
      close: '139.92',
      'adjusted close': '108.39722557841',
      volume: '2663884',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-27': {
      open: '140.41',
      high: '140.49',
      low: '138.4',
      close: '139.24',
      'adjusted close': '107.870423738835',
      volume: '3098233',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-26': {
      open: '139.93',
      high: '141.02',
      low: '139.42',
      close: '140.22',
      'adjusted close': '108.629638154693',
      volume: '2553708',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-25': {
      open: '139.06',
      high: '139.91',
      low: '138.35',
      close: '139.18',
      'adjusted close': '107.823941223579',
      volume: '2839766',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-22': {
      open: '140.97',
      high: '141.44',
      low: '138.9',
      close: '139.45',
      'adjusted close': '108.033112542234',
      volume: '3877616',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-21': {
      open: '139.1',
      high: '142.12',
      low: '138.88',
      close: '141.44',
      'adjusted close': '109.574782631578',
      volume: '3605420',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-20': {
      open: '140.53',
      high: '140.7',
      low: '138.98',
      close: '139.6',
      'adjusted close': '108.149318830375',
      volume: '3649455',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-19': {
      open: '140.96',
      high: '141.7',
      low: '140.0',
      close: '140.49',
      'adjusted close': '108.838809473348',
      volume: '3482336',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-18': {
      open: '139.83',
      high: '140.37',
      low: '138.72',
      close: '140.21',
      'adjusted close': '108.621891068817',
      volume: '3268510',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-15': {
      open: '139.37',
      high: '140.33',
      low: '139.23',
      close: '139.43',
      'adjusted close': '108.017618370481',
      volume: '7153687',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-14': {
      open: '138.51',
      high: '138.94',
      low: '138.06',
      close: '138.79',
      'adjusted close': '107.521804874411',
      volume: '2738790',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-13': {
      open: '138.05',
      high: '139.32',
      low: '137.85',
      close: '138.56',
      'adjusted close': '107.343621899261',
      volume: '3173179',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-12': {
      open: '138.52',
      high: '139.7',
      low: '137.86',
      close: '138.28',
      'adjusted close': '107.12670349473',
      volume: '3954653',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-11': {
      open: '136.03',
      high: '137.73',
      low: '135.84',
      close: '137.71',
      'adjusted close': '106.685119599792',
      volume: '3939517',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-08': {
      open: '134.21',
      high: '135.24',
      low: '133.58',
      close: '135.09',
      'adjusted close': '104.655383100253',
      volume: '3773722',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-07': {
      open: '136.61',
      high: '136.89',
      low: '134.63',
      close: '135.36',
      'adjusted close': '104.864554418908',
      volume: '4358802',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-06': {
      open: '138.12',
      high: '138.39',
      low: '136.72',
      close: '136.98',
      'adjusted close': '106.119582330837',
      volume: '2768256',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-05': {
      open: '138.37',
      high: '138.74',
      low: '137.56',
      close: '137.88',
      'adjusted close': '106.816820059686',
      volume: '4250650',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-04': {
      open: '139.99',
      high: '140.08',
      low: '137.17',
      close: '138.43',
      'adjusted close': '107.242909782871',
      volume: '3407217',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-03-01': {
      open: '139.31',
      high: '140.04',
      low: '138.64',
      close: '139.2',
      'adjusted close': '107.839435395331',
      volume: '3030044',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-28': {
      open: '138.77',
      high: '139.06',
      low: '137.72',
      close: '138.13',
      'adjusted close': '107.010497206588',
      volume: '3457788',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-27': {
      open: '139.25',
      high: '139.57',
      low: '138.4',
      close: '139.17',
      'adjusted close': '107.816194137703',
      volume: '2530968',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-26': {
      open: '139.67',
      high: '140.49',
      low: '139.47',
      close: '139.72',
      'adjusted close': '108.242283860888',
      volume: '3060381',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-25': {
      open: '140.0',
      high: '140.47',
      low: '139.32',
      close: '139.46',
      'adjusted close': '108.04085962811',
      volume: '3194161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-22': {
      open: '138.73',
      high: '139.38',
      low: '138.43',
      close: '139.25',
      'adjusted close': '107.878170824712',
      volume: '3113698',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-21': {
      open: '137.82',
      high: '138.35',
      low: '137.35',
      close: '137.84',
      'adjusted close': '106.785831716181',
      volume: '2937467',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-20': {
      open: '138.76',
      high: '139.24',
      low: '137.22',
      close: '138.0',
      'adjusted close': '106.909785090199',
      volume: '3801979',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-19': {
      open: '137.81',
      high: '138.7',
      low: '137.36',
      close: '138.7',
      'adjusted close': '107.452081101526',
      volume: '3385675',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-15': {
      open: '137.58',
      high: '138.19',
      low: '137.39',
      close: '138.03',
      'adjusted close': '106.933026347827',
      volume: '3844062',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-14': {
      open: '137.17',
      high: '137.6',
      low: '136.21',
      close: '136.48',
      'adjusted close': '105.732228037031',
      volume: '2789839',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-13': {
      open: '136.92',
      high: '137.92',
      low: '136.41',
      close: '137.52',
      'adjusted close': '106.537924968146',
      volume: '4253042',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-12': {
      open: '135.15',
      high: '136.2',
      low: '134.86',
      close: '136.05',
      'adjusted close': '105.399103344359',
      volume: '3317161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-11': {
      open: '134.29',
      high: '135.15',
      low: '133.91',
      close: '133.99',
      'adjusted close': '103.803203653882',
      volume: '3095069',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-08': {
      open: '132.34',
      high: '133.71',
      low: '132.19',
      close: '133.71',
      'adjusted close': '103.586285249351',
      volume: '3250074',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-07': {
      open: '133.55',
      high: '134.47',
      low: '132.12',
      close: '133.19',
      'adjusted close': '103.183436783794',
      volume: '4379439',
      'dividend amount': '1.5700',
      'split coefficient': '1.0',
    },
    '2019-02-06': {
      open: '135.22',
      high: '136.65',
      low: '135.17',
      close: '136.32',
      'adjusted close': '104.377902214061',
      volume: '4879670',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-05': {
      open: '135.28',
      high: '135.82',
      low: '134.92',
      close: '135.55',
      'adjusted close': '103.788326328609',
      volume: '5398907',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-04': {
      open: '134.02',
      high: '135.2',
      low: '132.99',
      close: '135.19',
      'adjusted close': '103.512680460086',
      volume: '3966597',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-02-01': {
      open: '134.97',
      high: '135.2',
      low: '133.35',
      close: '134.1',
      'adjusted close': '102.678086024835',
      volume: '3806047',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-31': {
      open: '134.45',
      high: '134.72',
      low: '133.74',
      close: '134.42',
      'adjusted close': '102.923104574633',
      volume: '4884031',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-30': {
      open: '134.0',
      high: '135.03',
      low: '133.25',
      close: '134.38',
      'adjusted close': '102.892477255909',
      volume: '4500919',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-29': {
      open: '134.29',
      high: '135.41',
      low: '133.6',
      close: '134.33',
      'adjusted close': '102.854193107503',
      volume: '5037129',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-28': {
      open: '133.1',
      high: '134.81',
      low: '132.58',
      close: '134.27',
      'adjusted close': '102.808252129416',
      volume: '5357736',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-25': {
      open: '132.87',
      high: '134.44',
      low: '132.43',
      close: '133.97',
      'adjusted close': '102.57854723898',
      volume: '5708500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-24': {
      open: '132.63',
      high: '133.21',
      low: '131.43',
      close: '132.53',
      'adjusted close': '101.475963764887',
      volume: '6322928',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-23': {
      open: '131.37',
      high: '135.0',
      low: '130.31',
      close: '132.89',
      'adjusted close': '101.751609633411',
      volume: '22063666',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-22': {
      open: '123.3',
      high: '123.8',
      low: '121.54',
      close: '122.52',
      'adjusted close': '93.8114772540105',
      volume: '10052384',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-18': {
      open: '123.27',
      high: '124.72',
      low: '122.71',
      close: '123.82',
      'adjusted close': '94.806865112566',
      volume: '6008509',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-17': {
      open: '120.56',
      high: '122.41',
      low: '120.55',
      close: '122.19',
      'adjusted close': '93.5588018745311',
      volume: '5029871',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-16': {
      open: '121.58',
      high: '122.0',
      low: '120.83',
      close: '121.62',
      'adjusted close': '93.1223625827029',
      volume: '3841100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-15': {
      open: '120.96',
      high: '121.93',
      low: '120.82',
      close: '121.73',
      'adjusted close': '93.2065877091961',
      volume: '3507719',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-14': {
      open: '120.51',
      high: '120.65',
      low: '119.76',
      close: '120.39',
      'adjusted close': '92.1805725319158',
      volume: '5228909',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-11': {
      open: '121.58',
      high: '121.62',
      low: '120.2',
      close: '121.46',
      'adjusted close': '92.9998533078038',
      volume: '3722370',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-10': {
      open: '120.08',
      high: '121.86',
      low: '119.95',
      close: '121.79',
      'adjusted close': '93.2525286872832',
      volume: '3909971',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-09': {
      open: '120.91',
      high: '121.4',
      low: '119.87',
      close: '120.69',
      'adjusted close': '92.4102774223517',
      volume: '3633663',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-08': {
      open: '119.66',
      high: '120.57',
      low: '118.98',
      close: '119.83',
      'adjusted close': '91.7517900697689',
      volume: '4763636',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-07': {
      open: '117.5',
      high: '118.83',
      low: '116.67',
      close: '118.15',
      'adjusted close': '90.465442683328',
      volume: '3751204',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-04': {
      open: '114.91',
      high: '117.49',
      low: '114.44',
      close: '117.32',
      'adjusted close': '89.8299258197887',
      volume: '4477755',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-03': {
      open: '114.53',
      high: '114.88',
      low: '112.69',
      close: '112.91',
      'adjusted close': '86.4532639303814',
      volume: '4346659',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2019-01-02': {
      open: '112.01',
      high: '115.98',
      low: '111.69',
      close: '115.21',
      'adjusted close': '88.2143347570564',
      volume: '4239924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-31': {
      open: '113.33',
      high: '114.35',
      low: '112.4201',
      close: '113.67',
      'adjusted close': '87.0351829861523',
      volume: '4982755',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-28': {
      open: '114.22',
      high: '114.8',
      low: '112.5',
      close: '113.03',
      'adjusted close': '86.5451458865558',
      volume: '5117533',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-27': {
      open: '109.99',
      high: '113.78',
      low: '109.47',
      close: '113.78',
      'adjusted close': '87.1194081126454',
      volume: '6045590',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-26': {
      open: '108.0',
      high: '111.39',
      low: '105.94',
      close: '111.39',
      'adjusted close': '85.2894258188396',
      volume: '6645794',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-24': {
      open: '109.9',
      high: '111.0',
      low: '107.5',
      close: '107.57',
      'adjusted close': '82.3645168806229',
      volume: '3821376',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-21': {
      open: '112.5',
      high: '115.28',
      low: '110.44',
      close: '110.94',
      'adjusted close': '84.9448684831858',
      volume: '10386453',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-20': {
      open: '115.7',
      high: '116.45',
      low: '111.7',
      close: '113.02',
      'adjusted close': '86.5374890568745',
      volume: '8454350',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-19': {
      open: '117.15',
      high: '120.27',
      low: '115.97',
      close: '116.43',
      'adjusted close': '89.1484679781623',
      volume: '6979823',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-18': {
      open: '116.9',
      high: '118.23',
      low: '116.02',
      close: '116.65',
      'adjusted close': '89.3169182311486',
      volume: '6290186',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-17': {
      open: '119.07',
      high: '119.78',
      low: '115.07',
      close: '116.1',
      'adjusted close': '88.8957925986828',
      volume: '7374476',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-14': {
      open: '119.5',
      high: '121.11',
      low: '119.44',
      close: '119.9',
      'adjusted close': '91.8053878775373',
      volume: '4554204',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-13': {
      open: '121.26',
      high: '121.79',
      low: '120.17',
      close: '120.73',
      'adjusted close': '92.4409047410765',
      volume: '3993597',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-12': {
      open: '122.51',
      high: '122.88',
      low: '121.0',
      close: '121.16',
      'adjusted close': '92.7701484173679',
      volume: '3560790',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-11': {
      open: '123.25',
      high: '123.81',
      low: '120.0',
      close: '120.89',
      'adjusted close': '92.5634140159756',
      volume: '5043081',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-10': {
      open: '119.74',
      high: '121.68',
      low: '118.52',
      close: '121.13',
      'adjusted close': '92.7471779283243',
      volume: '6549506',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-07': {
      open: '123.9',
      high: '124.05',
      low: '118.87',
      close: '119.34',
      'adjusted close': '91.3766054153903',
      volume: '7019616',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-06': {
      open: '119.72',
      high: '123.91',
      low: '119.301',
      close: '123.91',
      'adjusted close': '94.8757765796967',
      volume: '6938049',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-04': {
      open: '124.49',
      high: '124.92',
      low: '121.33',
      close: '121.6',
      'adjusted close': '93.1070489233405',
      volume: '6000697',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-12-03': {
      open: '125.67',
      high: '126.59',
      low: '124.36',
      close: '125.31',
      'adjusted close': '95.9477327350642',
      volume: '5843112',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-30': {
      open: '121.66',
      high: '124.69',
      low: '121.48',
      close: '124.27',
      'adjusted close': '95.1514224482198',
      volume: '7251590',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-29': {
      open: '122.44',
      high: '123.12',
      low: '121.4',
      close: '121.48',
      'adjusted close': '93.0151669671662',
      volume: '5200113',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-28': {
      open: '121.01',
      high: '123.09',
      low: '120.22',
      close: '123.0',
      'adjusted close': '94.1790050787079',
      volume: '5104520',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-27': {
      open: '118.38',
      high: '120.04',
      low: '118.37',
      close: '120.03',
      'adjusted close': '91.9049266633928',
      volume: '4903771',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-26': {
      open: '118.37',
      high: '120.25',
      low: '118.06',
      close: '119.56',
      'adjusted close': '91.5450556683766',
      volume: '5011130',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-23': {
      open: '118.09',
      high: '118.3382',
      low: '116.91',
      close: '117.19',
      'adjusted close': '89.7303870339332',
      volume: '2334730',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-21': {
      open: '117.61',
      high: '119.51',
      low: '117.39',
      close: '118.57',
      'adjusted close': '90.7870295299382',
      volume: '5335842',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-20': {
      open: '118.49',
      high: '119.49',
      low: '116.7',
      close: '117.2',
      'adjusted close': '89.7380438636144',
      volume: '6233777',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-19': {
      open: '121.63',
      high: '122.18',
      low: '119.77',
      close: '120.31',
      'adjusted close': '92.1193178944663',
      volume: '4131238',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-16': {
      open: '120.89',
      high: '121.85',
      low: '120.54',
      close: '121.57',
      'adjusted close': '93.0840784342969',
      volume: '4076315',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-15': {
      open: '119.66',
      high: '121.44',
      low: '119.51',
      close: '121.44',
      'adjusted close': '92.9845396484414',
      volume: '5207973',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-14': {
      open: '121.11',
      high: '121.93',
      low: '119.77',
      close: '120.2',
      'adjusted close': '92.0350927679731',
      volume: '4762275',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-13': {
      open: '121.19',
      high: '122.25',
      low: '119.9',
      close: '120.84',
      'adjusted close': '92.5251298675696',
      volume: '4084385',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-12': {
      open: '123.5',
      high: '123.57',
      low: '120.65',
      close: '120.9',
      'adjusted close': '92.5710708456568',
      volume: '5379113',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-09': {
      open: '123.61',
      high: '124.57',
      low: '121.96',
      close: '123.54',
      'adjusted close': '94.5924738814925',
      volume: '6961902',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-08': {
      open: '122.66',
      high: '124.4',
      low: '122.63',
      close: '123.38',
      'adjusted close': '94.4699646065934',
      volume: '10396439',
      'dividend amount': '1.5700',
      'split coefficient': '1.0',
    },
    '2018-11-07': {
      open: '123.79',
      high: '125.06',
      low: '122.33',
      close: '124.84',
      'adjusted close': '94.3867977710053',
      volume: '12382666',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-06': {
      open: '120.7',
      high: '123.81',
      low: '120.31',
      close: '123.12',
      'adjusted close': '93.0863708872651',
      volume: '9597392',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-05': {
      open: '116.1',
      high: '120.77',
      low: '116.0',
      close: '120.06',
      'adjusted close': '90.7728207336343',
      volume: '9144598',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-02': {
      open: '117.51',
      high: '117.75',
      low: '114.54',
      close: '115.67',
      'adjusted close': '87.4537079315299',
      volume: '10592681',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-11-01': {
      open: '115.5',
      high: '117.0',
      low: '115.11',
      close: '116.83',
      'adjusted close': '88.3307400159128',
      volume: '13626730',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-31': {
      open: '116.49',
      high: '116.5',
      low: '114.09',
      close: '115.43',
      'adjusted close': '87.2722530175196',
      volume: '21644978',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-30': {
      open: '120.48',
      high: '121.5',
      low: '115.15',
      close: '115.4',
      'adjusted close': '87.2495711532683',
      volume: '21458594',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-29': {
      open: '119.34',
      high: '123.95',
      low: '118.3',
      close: '119.64',
      'adjusted close': '90.4552746341163',
      volume: '20450448',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-26': {
      open: '125.21',
      high: '125.78',
      low: '123.71',
      close: '124.79',
      'adjusted close': '94.3489946639199',
      volume: '8767029',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-25': {
      open: '127.25',
      high: '127.79',
      low: '125.14',
      close: '126.45',
      'adjusted close': '95.6040578191575',
      volume: '10304327',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-24': {
      open: '131.17',
      high: '131.69',
      low: '127.0',
      close: '127.21',
      'adjusted close': '96.1786650468567',
      volume: '5710320',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-23': {
      open: '129.02',
      high: '131.9',
      low: '128.41',
      close: '131.21',
      'adjusted close': '99.2029136136944',
      volume: '6463580',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-22': {
      open: '129.58',
      high: '130.44',
      low: '128.4',
      close: '130.02',
      'adjusted close': '98.3031996650602',
      volume: '5719518',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-19': {
      open: '130.65',
      high: '131.89',
      low: '127.96',
      close: '129.1',
      'adjusted close': '97.6076224946875',
      volume: '7791636',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-18': {
      open: '132.73',
      high: '133.4',
      low: '130.04',
      close: '130.55',
      'adjusted close': '98.7039126001662',
      volume: '10011678',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-17': {
      open: '135.89',
      high: '137.15',
      low: '133.42',
      close: '134.05',
      'adjusted close': '101.350130096149',
      volume: '19464000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-16': {
      open: '142.93',
      high: '145.45',
      low: '141.58',
      close: '145.12',
      'adjusted close': '109.719738004873',
      volume: '8695489',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-15': {
      open: '140.38',
      high: '142.26',
      low: '140.14',
      close: '141.13',
      'adjusted close': '106.703050059452',
      volume: '4965533',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-12': {
      open: '141.12',
      high: '141.54',
      low: '139.08',
      close: '140.85',
      'adjusted close': '106.491352659773',
      volume: '6563403',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-11': {
      open: '142.62',
      high: '144.19',
      low: '138.78',
      close: '139.02',
      'adjusted close': '105.107758940445',
      volume: '8710491',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-10': {
      open: '147.5',
      high: '147.69',
      low: '142.5',
      close: '142.69',
      'adjusted close': '107.882507000519',
      volume: '5547697',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-09': {
      open: '148.08',
      high: '148.36',
      low: '146.93',
      close: '147.24',
      'adjusted close': '111.322589745297',
      volume: '3642496',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-08': {
      open: '148.5',
      high: '149.07',
      low: '147.18',
      close: '148.39',
      'adjusted close': '112.192061208262',
      volume: '3239347',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-05': {
      open: '150.9',
      high: '151.79',
      low: '147.87',
      close: '149.03',
      'adjusted close': '112.675940978956',
      volume: '4602252',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-04': {
      open: '151.69',
      high: '152.8',
      low: '150.36',
      close: '151.31',
      'adjusted close': '114.399762662054',
      volume: '4238143',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-03': {
      open: '154.0',
      high: '154.36',
      low: '152.8',
      close: '153.22',
      'adjusted close': '115.843841352719',
      volume: '3315298',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-02': {
      open: '152.99',
      high: '153.84',
      low: '152.5',
      close: '153.75',
      'adjusted close': '116.244554287825',
      volume: '3030125',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-10-01': {
      open: '151.71',
      high: '153.42',
      low: '151.71',
      close: '153.0',
      'adjusted close': '115.677507681543',
      volume: '3757419',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-28': {
      open: '151.16',
      high: '151.63',
      low: '150.79',
      close: '151.21',
      'adjusted close': '114.324156447883',
      volume: '3305798',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-27': {
      open: '152.01',
      high: '152.39',
      low: '151.16',
      close: '151.5',
      'adjusted close': '114.543414468979',
      volume: '3477643',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-26': {
      open: '152.4',
      high: '153.35',
      low: '151.34',
      close: '151.61',
      'adjusted close': '114.626581304567',
      volume: '7401862',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-25': {
      open: '149.91',
      high: '150.41',
      low: '148.36',
      close: '148.91',
      'adjusted close': '112.585213521951',
      volume: '4397457',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-24': {
      open: '151.1',
      high: '151.2',
      low: '149.46',
      close: '150.03',
      'adjusted close': '113.432003120666',
      volume: '4264984',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-21': {
      open: '151.48',
      high: '152.6',
      low: '150.74',
      close: '151.35',
      'adjusted close': '114.430005147722',
      volume: '19947878',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-20': {
      open: '149.58',
      high: '151.38',
      low: '149.44',
      close: '151.15',
      'adjusted close': '114.27879271938',
      volume: '5952223',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-19': {
      open: '148.63',
      high: '149.96',
      low: '148.23',
      close: '149.06',
      'adjusted close': '112.698622843208',
      volume: '4246382',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-18': {
      open: '147.76',
      high: '148.99',
      low: '147.47',
      close: '148.56',
      'adjusted close': '112.320591772353',
      volume: '3629596',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-17': {
      open: '148.44',
      high: '149.07',
      low: '147.77',
      close: '147.94',
      'adjusted close': '111.851833244493',
      volume: '3551882',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-14': {
      open: '148.85',
      high: '149.3',
      low: '147.78',
      close: '148.33',
      'adjusted close': '112.14669747976',
      volume: '3452144',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-13': {
      open: '147.35',
      high: '149.0',
      low: '147.12',
      close: '148.95',
      'adjusted close': '112.61545600762',
      volume: '5346540',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-12': {
      open: '146.37',
      high: '147.98',
      low: '146.03',
      close: '146.57',
      'adjusted close': '110.816028110351',
      volume: '4506679',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-11': {
      open: '146.0',
      high: '146.99',
      low: '145.25',
      close: '146.49',
      'adjusted close': '110.755543139015',
      volume: '3374774',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-10': {
      open: '146.6',
      high: '147.3',
      low: '145.56',
      close: '145.65',
      'adjusted close': '110.120450939979',
      volume: '3618209',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-07': {
      open: '145.94',
      high: '146.5',
      low: '145.12',
      close: '145.45',
      'adjusted close': '109.969238511637',
      volume: '4693127',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-06': {
      open: '146.88',
      high: '147.66',
      low: '145.54',
      close: '146.39',
      'adjusted close': '110.679936924844',
      volume: '4248831',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-05': {
      open: '145.19',
      high: '146.75',
      low: '145.05',
      close: '146.66',
      'adjusted close': '110.884073703105',
      volume: '3126540',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-09-04': {
      open: '145.98',
      high: '146.19',
      low: '144.81',
      close: '145.68',
      'adjusted close': '110.14313280423',
      volume: '3326153',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-31': {
      open: '145.72',
      high: '146.78',
      low: '145.54',
      close: '146.48',
      'adjusted close': '110.747982517597',
      volume: '3488476',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-30': {
      open: '147.03',
      high: '147.3',
      low: '145.25',
      close: '145.93',
      'adjusted close': '110.332148339657',
      volume: '3340369',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-29': {
      open: '146.87',
      high: '147.93',
      low: '146.6',
      close: '147.54',
      'adjusted close': '111.549408387809',
      volume: '3153845',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-28': {
      open: '146.95',
      high: '146.99',
      low: '146.19',
      close: '146.59',
      'adjusted close': '110.831149353185',
      volume: '2455328',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-27': {
      open: '146.94',
      high: '147.62',
      low: '146.52',
      close: '146.69',
      'adjusted close': '110.906755567356',
      volume: '3491565',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-24': {
      open: '145.46',
      high: '146.09',
      low: '144.87',
      close: '146.04',
      'adjusted close': '110.415315175245',
      volume: '2776813',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-23': {
      open: '145.04',
      high: '145.52',
      low: '144.75',
      close: '145.37',
      'adjusted close': '109.9087535403',
      volume: '2220603',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-22': {
      open: '146.01',
      high: '146.16',
      low: '145.19',
      close: '145.24',
      'adjusted close': '109.810465461878',
      volume: '2388961',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-21': {
      open: '147.0',
      high: '147.12',
      low: '145.89',
      close: '145.97',
      'adjusted close': '110.362390825326',
      volume: '3106876',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-20': {
      open: '146.37',
      high: '147.16',
      low: '146.15',
      close: '146.51',
      'adjusted close': '110.770664381849',
      volume: '2499654',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-17': {
      open: '144.79',
      high: '146.39',
      low: '144.69',
      close: '146.06',
      'adjusted close': '110.430436418079',
      volume: '2678820',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-16': {
      open: '144.37',
      high: '145.68',
      low: '144.37',
      close: '145.34',
      'adjusted close': '109.886071676049',
      volume: '5250878',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-15': {
      open: '142.55',
      high: '144.0',
      low: '142.0',
      close: '143.91',
      'adjusted close': '108.804902813404',
      volume: '4241496',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-14': {
      open: '143.0',
      high: '143.82',
      low: '142.93',
      close: '143.36',
      'adjusted close': '108.389068635464',
      volume: '2860041',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-13': {
      open: '144.15',
      high: '144.3',
      low: '142.53',
      close: '142.71',
      'adjusted close': '107.897628243353',
      volume: '2858842',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-10': {
      open: '144.5',
      high: '145.07',
      low: '143.88',
      close: '144.48',
      'adjusted close': '109.235858234179',
      volume: '3003158',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-09': {
      open: '145.53',
      high: '145.94',
      low: '144.73',
      close: '144.77',
      'adjusted close': '109.455116255274',
      volume: '3093504',
      'dividend amount': '1.5700',
      'split coefficient': '1.0',
    },
    '2018-08-08': {
      open: '147.33',
      high: '147.43',
      low: '146.24',
      close: '146.89',
      'adjusted close': '109.86648918093',
      volume: '3581746',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-07': {
      open: '146.65',
      high: '147.64',
      low: '146.35',
      close: '147.01',
      'adjusted close': '109.9562432738',
      volume: '3508758',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-06': {
      open: '146.95',
      high: '147.2',
      low: '145.57',
      close: '146.03',
      'adjusted close': '109.223251515359',
      volume: '3913743',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-03': {
      open: '143.24',
      high: '147.92',
      low: '143.0',
      close: '147.7',
      'adjusted close': '110.472329307804',
      volume: '8053567',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-02': {
      open: '142.86',
      high: '143.36',
      low: '142.33',
      close: '142.96',
      'adjusted close': '106.927042639429',
      volume: '3841447',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-08-01': {
      open: '144.76',
      high: '145.19',
      low: '143.34',
      close: '143.5',
      'adjusted close': '107.330936057345',
      volume: '3388386',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-31': {
      open: '145.74',
      high: '145.8',
      low: '144.16',
      close: '144.93',
      'adjusted close': '108.400505664049',
      volume: '5474478',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-30': {
      open: '144.8',
      high: '146.24',
      low: '144.69',
      close: '145.49',
      'adjusted close': '108.819358097443',
      volume: '3608283',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-27': {
      open: '146.89',
      high: '147.14',
      low: '144.66',
      close: '145.15',
      'adjusted close': '108.565054834311',
      volume: '3749642',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-26': {
      open: '147.43',
      high: '149.27',
      low: '146.63',
      close: '146.71',
      'adjusted close': '109.731858041624',
      volume: '4778022',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-25': {
      open: '146.01',
      high: '146.65',
      low: '145.5',
      close: '146.62',
      'adjusted close': '109.664542471972',
      volume: '3623182',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-24': {
      open: '146.7',
      high: '147.04',
      low: '145.92',
      close: '146.38',
      'adjusted close': '109.485034286231',
      volume: '3891625',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-23': {
      open: '146.35',
      high: '146.7',
      low: '145.01',
      close: '145.7',
      'adjusted close': '108.976427759966',
      volume: '3897892',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-20': {
      open: '148.58',
      high: '148.86',
      low: '146.26',
      close: '146.35',
      'adjusted close': '109.462595763013',
      volume: '6415972',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-19': {
      open: '147.85',
      high: '150.54',
      low: '147.25',
      close: '149.24',
      'adjusted close': '111.624173499639',
      volume: '14655804',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-18': {
      open: '143.51',
      high: '144.8',
      low: '142.74',
      close: '144.52',
      'adjusted close': '108.093845846742',
      volume: '6935288',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-17': {
      open: '144.75',
      high: '145.0',
      low: '143.34',
      close: '143.49',
      'adjusted close': '107.323456549606',
      volume: '5096741',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-16': {
      open: '145.67',
      high: '145.79',
      low: '144.21',
      close: '145.46',
      'adjusted close': '108.796919574226',
      volume: '3468817',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-13': {
      open: '146.45',
      high: '146.98',
      low: '145.8',
      close: '145.9',
      'adjusted close': '109.12601791475',
      volume: '3067638',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-12': {
      open: '145.85',
      high: '146.83',
      low: '145.74',
      close: '146.45',
      'adjusted close': '109.537390840405',
      volume: '3119505',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-11': {
      open: '144.0',
      high: '146.19',
      low: '144.0',
      close: '144.94',
      'adjusted close': '108.407985171788',
      volume: '3526565',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-10': {
      open: '144.51',
      high: '145.59',
      low: '144.26',
      close: '144.71',
      'adjusted close': '108.235956493787',
      volume: '3776991',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-09': {
      open: '142.59',
      high: '144.72',
      low: '142.47',
      close: '144.39',
      'adjusted close': '107.996612246133',
      volume: '3904652',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-06': {
      open: '141.53',
      high: '142.94',
      low: '141.17',
      close: '142.48',
      'adjusted close': '106.568026267948',
      volume: '2910426',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-05': {
      open: '140.48',
      high: '141.43',
      low: '139.93',
      close: '141.43',
      'adjusted close': '105.782677955333',
      volume: '3744730',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-03': {
      open: '140.65',
      high: '140.94',
      low: '139.37',
      close: '139.57',
      'adjusted close': '104.391489515844',
      volume: '1963232',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-07-02': {
      open: '138.28',
      high: '140.22',
      low: '138.2',
      close: '139.86',
      'adjusted close': '104.608395240281',
      volume: '3405642',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-29': {
      open: '140.32',
      high: '141.36',
      low: '139.61',
      close: '139.7',
      'adjusted close': '104.488723116454',
      volume: '3753684',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-28': {
      open: '138.1',
      high: '140.54',
      low: '137.95',
      close: '140.04',
      'adjusted close': '104.743026379586',
      volume: '4523666',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-27': {
      open: '139.11',
      high: '139.78',
      low: '137.45',
      close: '137.48',
      'adjusted close': '102.828272398354',
      volume: '4097588',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-26': {
      open: '139.36',
      high: '139.85',
      low: '138.33',
      close: '139.08',
      'adjusted close': '104.024993636624',
      volume: '4139455',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-25': {
      open: '140.4',
      high: '140.4',
      low: '138.28',
      close: '138.79',
      'adjusted close': '103.808087912188',
      volume: '8937085',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-22': {
      open: '141.64',
      high: '141.81',
      low: '140.78',
      close: '141.28',
      'adjusted close': '105.670485339245',
      volume: '6013678',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-21': {
      open: '142.59',
      high: '143.14',
      low: '140.83',
      close: '141.25',
      'adjusted close': '105.648046816028',
      volume: '3887650',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-20': {
      open: '144.22',
      high: '144.52',
      low: '142.41',
      close: '142.63',
      'adjusted close': '106.680218884036',
      volume: '3329917',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-19': {
      open: '143.12',
      high: '144.22',
      low: '142.95',
      close: '143.9',
      'adjusted close': '107.630116366912',
      volume: '3487015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-18': {
      open: '144.18',
      high: '144.52',
      low: '143.14',
      close: '144.48',
      'adjusted close': '108.063927815785',
      volume: '4286406',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-15': {
      open: '144.9',
      high: '145.47',
      low: '143.7',
      close: '145.39',
      'adjusted close': '108.744563020051',
      volume: '9116048',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-14': {
      open: '146.65',
      high: '146.98',
      low: '145.09',
      close: '145.2',
      'adjusted close': '108.602452373007',
      volume: '3326343',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-13': {
      open: '146.79',
      high: '147.47',
      low: '146.2',
      close: '146.35',
      'adjusted close': '109.462595763013',
      volume: '2968180',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-12': {
      open: '147.1',
      high: '147.52',
      low: '146.62',
      close: '146.87',
      'adjusted close': '109.851530165451',
      volume: '2640515',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-11': {
      open: '146.62',
      high: '147.41',
      low: '146.14',
      close: '146.58',
      'adjusted close': '109.634624441015',
      volume: '3259986',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-08': {
      open: '145.0',
      high: '146.43',
      low: '144.37',
      close: '146.14',
      'adjusted close': '109.305526100491',
      volume: '5210468',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-07': {
      open: '145.0',
      high: '146.7',
      low: '144.93',
      close: '145.36',
      'adjusted close': '108.722124496834',
      volume: '4316484',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-06': {
      open: '144.34',
      high: '144.72',
      low: '143.54',
      close: '144.71',
      'adjusted close': '108.235956493787',
      volume: '3220139',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-05': {
      open: '143.05',
      high: '144.08',
      low: '143.04',
      close: '143.7',
      'adjusted close': '107.480526212129',
      volume: '2836017',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-04': {
      open: '142.3',
      high: '143.17',
      low: '142.18',
      close: '143.04',
      'adjusted close': '106.986878701342',
      volume: '3035257',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-06-01': {
      open: '142.43',
      high: '142.84',
      low: '141.62',
      close: '141.95',
      'adjusted close': '106.171612357771',
      volume: '3857530',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-31': {
      open: '142.81',
      high: '142.81',
      low: '141.05',
      close: '141.31',
      'adjusted close': '105.692923862463',
      volume: '6830256',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-30': {
      open: '141.9',
      high: '143.1',
      low: '141.82',
      close: '142.62',
      'adjusted close': '106.672739376296',
      volume: '5118530',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-29': {
      open: '142.53',
      high: '142.89',
      low: '140.21',
      close: '141.22',
      'adjusted close': '105.62560829281',
      volume: '4537638',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-25': {
      open: '143.9',
      high: '144.57',
      low: '143.46',
      close: '143.64',
      'adjusted close': '107.435649165694',
      volume: '4914294',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-24': {
      open: '144.39',
      high: '144.48',
      low: '142.95',
      close: '144.07',
      'adjusted close': '107.757267998479',
      volume: '3398701',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-23': {
      open: '144.35',
      high: '144.7',
      low: '143.48',
      close: '144.67',
      'adjusted close': '108.20603846283',
      volume: '3459747',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-22': {
      open: '145.52',
      high: '146.2',
      low: '145.0',
      close: '145.09',
      'adjusted close': '108.520177787876',
      volume: '2284020',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-21': {
      open: '144.95',
      high: '146.04',
      low: '144.68',
      close: '145.49',
      'adjusted close': '108.819358097443',
      volume: '2894698',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-18': {
      open: '144.47',
      high: '144.94',
      low: '143.99',
      close: '144.08',
      'adjusted close': '107.764747506218',
      volume: '2682243',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-17': {
      open: '144.5',
      high: '145.19',
      low: '143.77',
      close: '144.5',
      'adjusted close': '108.078886831264',
      volume: '3147659',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-16': {
      open: '143.7',
      high: '145.03',
      low: '143.27',
      close: '144.63',
      'adjusted close': '108.176120431873',
      volume: '3168659',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-15': {
      open: '143.5',
      high: '143.99',
      low: '142.92',
      close: '143.74',
      'adjusted close': '107.510444243085',
      volume: '4089397',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-14': {
      open: '144.47',
      high: '145.12',
      low: '144.14',
      close: '144.3',
      'adjusted close': '107.92929667648',
      volume: '3216062',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-11': {
      open: '144.11',
      high: '144.85',
      low: '143.58',
      close: '144.14',
      'adjusted close': '107.809624552653',
      volume: '3073167',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-10': {
      open: '143.15',
      high: '144.33',
      low: '143.01',
      close: '144.24',
      'adjusted close': '107.884419630045',
      volume: '3726608',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-09': {
      open: '141.9',
      high: '143.02',
      low: '141.28',
      close: '142.61',
      'adjusted close': '106.665259868557',
      volume: '4195953',
      'dividend amount': '1.5700',
      'split coefficient': '1.0',
    },
    '2018-05-08': {
      open: '143.0',
      high: '143.51',
      low: '142.06',
      close: '143.0',
      'adjusted close': '105.792288536577',
      volume: '5369119',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-07': {
      open: '144.0',
      high: '144.32',
      low: '142.64',
      close: '143.22',
      'adjusted close': '105.955045903556',
      volume: '3657009',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-04': {
      open: '141.1',
      high: '144.35',
      low: '140.28',
      close: '143.91',
      'adjusted close': '106.465512190901',
      volume: '4402170',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-03': {
      open: '142.13',
      high: '142.23',
      low: '139.9',
      close: '141.99',
      'adjusted close': '105.045084260899',
      volume: '5018592',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-02': {
      open: '144.46',
      high: '144.82',
      low: '142.07',
      close: '142.45',
      'adjusted close': '105.385395119129',
      volume: '5194917',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-05-01': {
      open: '144.65',
      high: '145.02',
      low: '143.47',
      close: '145.0',
      'adjusted close': '107.271900963662',
      volume: '4433393',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-30': {
      open: '146.86',
      high: '147.38',
      low: '144.96',
      close: '144.96',
      'adjusted close': '107.24230871512',
      volume: '4415533',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-27': {
      open: '146.84',
      high: '147.25',
      low: '145.66',
      close: '146.48',
      'adjusted close': '108.366814159705',
      volume: '3205482',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-26': {
      open: '146.6',
      high: '147.36',
      low: '146.2',
      close: '146.72',
      'adjusted close': '108.544367650955',
      volume: '4265812',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-25': {
      open: '145.79',
      high: '146.4',
      low: '144.11',
      close: '145.94',
      'adjusted close': '107.967318804392',
      volume: '7324654',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-24': {
      open: '146.51',
      high: '147.3',
      low: '144.53',
      close: '145.56',
      'adjusted close': '107.686192443246',
      volume: '4916476',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-23': {
      open: '145.03',
      high: '145.96',
      low: '144.33',
      close: '145.86',
      'adjusted close': '107.908134307309',
      volume: '5315625',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-20': {
      open: '147.9',
      high: '147.97',
      low: '144.51',
      close: '144.9',
      'adjusted close': '107.197920342308',
      volume: '8265643',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-19': {
      open: '149.19',
      high: '149.21',
      low: '146.62',
      close: '147.7',
      'adjusted close': '109.269377740227',
      volume: '8086056',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-18': {
      open: '152.14',
      high: '152.63',
      low: '148.26',
      close: '148.79',
      'adjusted close': '110.075766512988',
      volume: '20381695',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-17': {
      open: '158.82',
      high: '162.0',
      low: '158.51',
      close: '160.91',
      'adjusted close': '119.042217821123',
      volume: '10207534',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-16': {
      open: '157.99',
      high: '159.14',
      low: '157.67',
      close: '157.89',
      'adjusted close': '116.808003056225',
      volume: '4186755',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-13': {
      open: '158.67',
      high: '159.22',
      low: '155.91',
      close: '156.71',
      'adjusted close': '115.935031724245',
      volume: '4057058',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-12': {
      open: '156.75',
      high: '158.98',
      low: '156.67',
      close: '158.07',
      'adjusted close': '116.941168174663',
      volume: '5639444',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-11': {
      open: '154.37',
      high: '155.78',
      low: '153.88',
      close: '155.36',
      'adjusted close': '114.936293335962',
      volume: '3306539',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-10': {
      open: '155.03',
      high: '156.6',
      low: '154.75',
      close: '155.39',
      'adjusted close': '114.958487522369',
      volume: '3955952',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-09': {
      open: '151.8',
      high: '154.66',
      low: '151.74',
      close: '152.69',
      'adjusted close': '112.961010745804',
      volume: '4413178',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-06': {
      open: '153.46',
      high: '153.95',
      low: '149.54',
      close: '150.57',
      'adjusted close': '111.392621573094',
      volume: '3672919',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-05': {
      open: '154.44',
      high: '154.92',
      low: '153.34',
      close: '154.03',
      'adjusted close': '113.952351071951',
      volume: '3185388',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-04': {
      open: '147.89',
      high: '154.47',
      low: '147.45',
      close: '154.12',
      'adjusted close': '114.01893363117',
      volume: '4805261',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-03': {
      open: '150.8',
      high: '151.0',
      low: '148.3',
      close: '149.85',
      'adjusted close': '110.859961099343',
      volume: '4135696',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-04-02': {
      open: '153.34',
      high: '153.38',
      low: '148.43',
      close: '150.07',
      'adjusted close': '111.022718466323',
      volume: '5150435',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-29': {
      open: '153.07',
      high: '153.89',
      low: '151.08',
      close: '153.43',
      'adjusted close': '113.508467343825',
      volume: '3419959',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-28': {
      open: '152.07',
      high: '153.86',
      low: '151.89',
      close: '152.52',
      'adjusted close': '112.835243689502',
      volume: '3664826',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-27': {
      open: '153.95',
      high: '154.87',
      low: '151.16',
      close: '151.91',
      'adjusted close': '112.383961899241',
      volume: '3810994',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-26': {
      open: '151.21',
      high: '153.66',
      low: '150.28',
      close: '153.37',
      'adjusted close': '113.464078971013',
      volume: '4038586',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-23': {
      open: '152.25',
      high: '152.58',
      low: '148.54',
      close: '148.89',
      'adjusted close': '110.149747134342',
      volume: '4389015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-22': {
      open: '155.0',
      high: '155.25',
      low: '152.0',
      close: '152.09',
      'adjusted close': '112.517127017678',
      volume: '4617371',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-21': {
      open: '156.57',
      high: '158.2',
      low: '155.92',
      close: '156.69',
      'adjusted close': '115.920235599974',
      volume: '3240695',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-20': {
      open: '157.52',
      high: '157.93',
      low: '155.29',
      close: '156.2',
      'adjusted close': '115.557730555338',
      volume: '4296935',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-19': {
      open: '159.71',
      high: '159.89',
      low: '156.45',
      close: '157.35',
      'adjusted close': '116.408507700912',
      volume: '5415258',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-16': {
      open: '160.06',
      high: '161.98',
      low: '159.64',
      close: '160.26',
      'adjusted close': '118.561343782321',
      volume: '8447294',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-15': {
      open: '158.71',
      high: '161.25',
      low: '158.52',
      close: '159.61',
      'adjusted close': '118.080469743518',
      volume: '3860479',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-14': {
      open: '160.17',
      high: '160.68',
      low: '157.74',
      close: '158.12',
      'adjusted close': '116.97815848534',
      volume: '3614293',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-13': {
      open: '160.09',
      high: '162.11',
      low: '158.81',
      close: '159.32',
      'adjusted close': '117.865925941591',
      volume: '4097662',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-12': {
      open: '159.64',
      high: '161.02',
      low: '158.87',
      close: '160.26',
      'adjusted close': '118.561343782321',
      volume: '5056489',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-09': {
      open: '157.47',
      high: '159.58',
      low: '157.3',
      close: '159.31',
      'adjusted close': '117.858527879455',
      volume: '4783247',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-08': {
      open: '159.0',
      high: '159.57',
      low: '155.07',
      close: '156.21',
      'adjusted close': '115.565128617474',
      volume: '6095521',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-07': {
      open: '155.0',
      high: '158.83',
      low: '154.73',
      close: '158.32',
      'adjusted close': '117.126119728048',
      volume: '4607677',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-06': {
      open: '157.28',
      high: '157.89',
      low: '155.16',
      close: '155.72',
      'adjusted close': '115.202623572838',
      volume: '3685736',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-05': {
      open: '154.12',
      high: '157.49',
      low: '153.75',
      close: '156.95',
      'adjusted close': '116.112585215495',
      volume: '3670630',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-02': {
      open: '152.79',
      high: '154.76',
      low: '151.88',
      close: '154.49',
      'adjusted close': '114.29266193018',
      volume: '3261141',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-03-01': {
      open: '155.53',
      high: '156.97',
      low: '152.79',
      close: '153.81',
      'adjusted close': '113.789593704972',
      volume: '4039339',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-28': {
      open: '157.5',
      high: '158.14',
      low: '155.8',
      close: '155.83',
      'adjusted close': '115.284002256327',
      volume: '3761597',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-27': {
      open: '158.46',
      high: '159.78',
      low: '156.53',
      close: '156.55',
      'adjusted close': '115.816662730078',
      volume: '4192962',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-26': {
      open: '155.81',
      high: '158.88',
      low: '155.51',
      close: '158.58',
      'adjusted close': '117.318469343569',
      volume: '3576723',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-23': {
      open: '154.3',
      high: '155.85',
      low: '154.07',
      close: '155.52',
      'adjusted close': '115.054662330129',
      volume: '3208908',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-22': {
      open: '154.5',
      high: '155.04',
      low: '152.79',
      close: '153.18',
      'adjusted close': '113.32351579044',
      volume: '4017699',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-21': {
      open: '155.43',
      high: '156.79',
      low: '153.9',
      close: '153.96',
      'adjusted close': '113.900564637003',
      volume: '3492694',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-20': {
      open: '155.79',
      high: '155.96',
      low: '153.87',
      close: '155.16',
      'adjusted close': '114.788332093254',
      volume: '4267801',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-16': {
      open: '155.71',
      high: '157.79',
      low: '155.69',
      close: '156.18',
      'adjusted close': '115.542934431067',
      volume: '4239560',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-15': {
      open: '155.5',
      high: '156.86',
      low: '153.7',
      close: '156.01',
      'adjusted close': '115.417167374765',
      volume: '5525079',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-14': {
      open: '150.19',
      high: '155.0',
      low: '150.0',
      close: '154.76',
      'adjusted close': '114.492409607837',
      volume: '5422862',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-13': {
      open: '150.9',
      high: '151.18',
      low: '149.64',
      close: '150.75',
      'adjusted close': '111.525786691531',
      volume: '4854790',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-12': {
      open: '150.9',
      high: '152.4',
      low: '150.3',
      close: '151.4',
      'adjusted close': '112.006660730334',
      volume: '5659623',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-09': {
      open: '148.6',
      high: '150.54',
      low: '144.4',
      close: '149.51',
      'adjusted close': '110.608426986739',
      volume: '7763603',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-08': {
      open: '152.48',
      high: '153.16',
      low: '147.5',
      close: '147.59',
      'adjusted close': '109.187999056737',
      volume: '6599136',
      'dividend amount': '1.5000',
      'split coefficient': '1.0',
    },
    '2018-02-07': {
      open: '154.17',
      high: '155.34',
      low: '153.28',
      close: '153.85',
      'adjusted close': '112.674046917158',
      volume: '6069258',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-06': {
      open: '150.29',
      high: '155.49',
      low: '149.11',
      close: '155.34',
      'adjusted close': '113.765267781029',
      volume: '9823824',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-05': {
      open: '157.89',
      high: '158.5',
      low: '150.0',
      close: '152.53',
      'adjusted close': '111.707327762587',
      volume: '8600416',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-02': {
      open: '161.7',
      high: '162.0',
      low: '158.87',
      close: '159.03',
      'adjusted close': '116.46768723585',
      volume: '5174779',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-02-01': {
      open: '163.19',
      high: '164.13',
      low: '161.9',
      close: '162.4',
      'adjusted close': '118.935750531988',
      volume: '4422505',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-31': {
      open: '163.75',
      high: '164.99',
      low: '162.76',
      close: '163.7',
      'adjusted close': '119.887822426641',
      volume: '4025105',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-30': {
      open: '166.21',
      high: '166.77',
      low: '163.16',
      close: '163.62',
      'adjusted close': '119.82923338697',
      volume: '4796200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-29': {
      open: '167.45',
      high: '168.39',
      low: '166.01',
      close: '166.8',
      'adjusted close': '122.158147713889',
      volume: '3423903',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-26': {
      open: '166.12',
      high: '167.41',
      low: '165.79',
      close: '167.34',
      'adjusted close': '122.553623731668',
      volume: '3664496',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-25': {
      open: '165.91',
      high: '166.78',
      low: '165.19',
      close: '165.47',
      'adjusted close': '121.18410492936',
      volume: '3240177',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-24': {
      open: '166.75',
      high: '168.72',
      low: '164.82',
      close: '165.37',
      'adjusted close': '121.110868629771',
      volume: '5507664',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-23': {
      open: '162.82',
      high: '166.26',
      low: '162.51',
      close: '166.25',
      'adjusted close': '121.755348066152',
      volume: '7417268',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-22': {
      open: '161.51',
      high: '163.98',
      low: '161.11',
      close: '162.6',
      'adjusted close': '119.082223131166',
      volume: '8340507',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-19': {
      open: '164.46',
      high: '165.4',
      low: '161.3',
      close: '162.37',
      'adjusted close': '118.913779642112',
      volume: '21079530',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-18': {
      open: '170.0',
      high: '171.13',
      low: '168.15',
      close: '169.12',
      'adjusted close': '123.857229864346',
      volume: '10948033',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-17': {
      open: '168.01',
      high: '169.13',
      low: '167.26',
      close: '168.65',
      'adjusted close': '123.51301925628',
      volume: '11630539',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-16': {
      open: '165.1',
      high: '165.29',
      low: '163.35',
      close: '163.85',
      'adjusted close': '119.997676876024',
      volume: '7588202',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-12': {
      open: '164.02',
      high: '164.74',
      low: '163.03',
      close: '163.14',
      'adjusted close': '119.477699148944',
      volume: '5007254',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-11': {
      open: '164.8',
      high: '164.94',
      low: '163.3',
      close: '164.2',
      'adjusted close': '120.254003924584',
      volume: '3783614',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-10': {
      open: '162.91',
      high: '164.33',
      low: '162.5',
      close: '164.18',
      'adjusted close': '120.239356664666',
      volume: '4150836',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-09': {
      open: '163.9',
      high: '164.53',
      low: '163.06',
      close: '163.83',
      'adjusted close': '119.983029616106',
      volume: '4333418',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-08': {
      open: '162.66',
      high: '163.91',
      low: '161.7',
      close: '163.47',
      'adjusted close': '119.719378937587',
      volume: '5101023',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-05': {
      open: '162.44',
      high: '162.9',
      low: '161.1',
      close: '162.49',
      'adjusted close': '119.001663201618',
      volume: '5162075',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-04': {
      open: '159.65',
      high: '162.32',
      low: '159.37',
      close: '161.7',
      'adjusted close': '118.423096434868',
      volume: '7363843',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-03': {
      open: '157.34',
      high: '159.81',
      low: '156.33',
      close: '158.49',
      'adjusted close': '116.072211218071',
      volume: '9439063',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2018-01-02': {
      open: '154.5',
      high: '154.81',
      low: '153.54',
      close: '154.25',
      'adjusted close': '112.966992115512',
      volume: '4195225',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-29': {
      open: '154.17',
      high: '154.72',
      low: '153.42',
      close: '153.42',
      'adjusted close': '112.359130828926',
      volume: '3310894',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-28': {
      open: '153.2',
      high: '154.12',
      low: '153.2',
      close: '154.04',
      'adjusted close': '112.813195886376',
      volume: '2669076',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-27': {
      open: '152.95',
      high: '153.18',
      low: '152.61',
      close: '153.13',
      'adjusted close': '112.146745560119',
      volume: '2116522',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-26': {
      open: '152.51',
      high: '153.86',
      low: '152.5',
      close: '152.83',
      'adjusted close': '111.927036661353',
      volume: '2477240',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-22': {
      open: '151.82',
      high: '153.0',
      low: '151.5',
      close: '152.5',
      'adjusted close': '111.685356872711',
      volume: '2982114',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-21': {
      open: '153.17',
      high: '153.46',
      low: '151.49',
      close: '151.5',
      'adjusted close': '110.952993876824',
      volume: '4126632',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-20': {
      open: '153.65',
      high: '153.89',
      low: '152.78',
      close: '152.95',
      'adjusted close': '112.01492022086',
      volume: '3771425',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-19': {
      open: '154.135',
      high: '154.17',
      low: '153.09',
      close: '153.23',
      'adjusted close': '112.219981859708',
      volume: '4111495',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-18': {
      open: '153.41',
      high: '154.18',
      low: '153.21',
      close: '153.33',
      'adjusted close': '112.293218159297',
      volume: '5054265',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-15': {
      open: '153.75',
      high: '153.8',
      low: '152.03',
      close: '152.5',
      'adjusted close': '111.685356872711',
      volume: '10469312',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-14': {
      open: '154.5',
      high: '155.11',
      low: '153.7',
      close: '154.0',
      'adjusted close': '112.783901366541',
      volume: '4623845',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-13': {
      open: '156.6',
      high: '156.73',
      low: '153.89',
      close: '153.91',
      'adjusted close': '112.717988696911',
      volume: '5624428',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-12': {
      open: '155.2',
      high: '157.85',
      low: '155.16',
      close: '156.74',
      'adjusted close': '114.79057597527',
      volume: '6314835',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-11': {
      open: '154.97',
      high: '155.89',
      low: '154.57',
      close: '155.41',
      'adjusted close': '113.816533190741',
      volume: '4068982',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-08': {
      open: '153.94',
      high: '155.03',
      low: '153.55',
      close: '154.81',
      'adjusted close': '113.377115393209',
      volume: '3440847',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-07': {
      open: '154.03',
      high: '154.45',
      low: '153.26',
      close: '153.57',
      'adjusted close': '112.468985278309',
      volume: '3768443',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-06': {
      open: '155.69',
      high: '156.22',
      low: '154.09',
      close: '154.1',
      'adjusted close': '112.857137666129',
      volume: '3345712',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-05': {
      open: '156.45',
      high: '156.74',
      low: '154.68',
      close: '155.35',
      'adjusted close': '113.772591410987',
      volume: '5042724',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-04': {
      open: '155.67',
      high: '156.8',
      low: '155.07',
      close: '156.46',
      'adjusted close': '114.585514336422',
      volume: '4639109',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-12-01': {
      open: '154.4',
      high: '155.02',
      low: '152.91',
      close: '154.76',
      'adjusted close': '113.340497243414',
      volume: '5567852',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-30': {
      open: '153.65',
      high: '154.41',
      low: '153.08',
      close: '153.97',
      'adjusted close': '112.761930476664',
      volume: '6500769',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-29': {
      open: '152.81',
      high: '153.61',
      low: '152.17',
      close: '153.55',
      'adjusted close': '112.454338018392',
      volume: '5098702',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-28': {
      open: '152.35',
      high: '152.93',
      low: '151.7',
      close: '152.47',
      'adjusted close': '111.663385982834',
      volume: '3915618',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-27': {
      open: '152.06',
      high: '152.49',
      low: '151.62',
      close: '151.98',
      'adjusted close': '111.30452811485',
      volume: '3303663',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-24': {
      open: '151.95',
      high: '152.2',
      low: '151.33',
      close: '151.84',
      'adjusted close': '111.201997295425',
      volume: '1193025',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-22': {
      open: '152.0',
      high: '152.39',
      low: '151.33',
      close: '151.77',
      'adjusted close': '111.150731885713',
      volume: '3125416',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-21': {
      open: '151.3',
      high: '152.45',
      low: '151.2',
      close: '151.95',
      'adjusted close': '111.282557224973',
      volume: '3756184',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-20': {
      open: '150.64',
      high: '151.95',
      low: '149.9',
      close: '150.51',
      'adjusted close': '110.227954510896',
      volume: '5654091',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-17': {
      open: '149.34',
      high: '150.15',
      low: '148.83',
      close: '148.97',
      'adjusted close': '109.100115497231',
      volume: '4865373',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-16': {
      open: '147.73',
      high: '149.65',
      low: '147.5',
      close: '149.12',
      'adjusted close': '109.209969946614',
      volume: '5393609',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-15': {
      open: '148.0',
      high: '148.705',
      low: '146.211',
      close: '147.1',
      'adjusted close': '107.730596694923',
      volume: '4695745',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-14': {
      open: '147.95',
      high: '148.97',
      low: '147.49',
      close: '148.89',
      'adjusted close': '109.04152645756',
      volume: '3691934',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-13': {
      open: '148.88',
      high: '149.0',
      low: '147.92',
      close: '148.4',
      'adjusted close': '108.682668589575',
      volume: '5085257',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-10': {
      open: '150.65',
      high: '150.89',
      low: '149.14',
      close: '149.16',
      'adjusted close': '109.239264466449',
      volume: '4304369',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-09': {
      open: '149.93',
      high: '151.8',
      low: '149.86',
      close: '150.3',
      'adjusted close': '110.07415828176',
      volume: '4770841',
      'dividend amount': '1.5000',
      'split coefficient': '1.0',
    },
    '2017-11-08': {
      open: '151.6',
      high: '151.7944',
      low: '150.28',
      close: '151.57',
      'adjusted close': '109.90737925406',
      volume: '4630031',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-07': {
      open: '151.37',
      high: '151.51',
      low: '150.5',
      close: '151.35',
      'adjusted close': '109.747851488435',
      volume: '3688378',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-06': {
      open: '151.77',
      high: '151.82',
      low: '150.28',
      close: '150.84',
      'adjusted close': '109.378037122666',
      volume: '4498372',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-03': {
      open: '153.42',
      high: '153.47',
      low: '151.49',
      close: '151.58',
      'adjusted close': '109.914630516134',
      volume: '4420582',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-02': {
      open: '154.18',
      high: '154.49',
      low: '152.91',
      close: '153.35',
      'adjusted close': '111.198103903214',
      volume: '3388284',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-11-01': {
      open: '154.1',
      high: '154.9',
      low: '153.83',
      close: '154.03',
      'adjusted close': '111.691189724239',
      volume: '3228302',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-31': {
      open: '154.33',
      high: '154.93',
      low: '152.93',
      close: '154.06',
      'adjusted close': '111.712943510461',
      volume: '4224346',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-30': {
      open: '153.76',
      high: '154.59',
      low: '152.71',
      close: '154.36',
      'adjusted close': '111.930481372678',
      volume: '4206189',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-27': {
      open: '154.54',
      high: '154.63',
      low: '152.911',
      close: '153.68',
      'adjusted close': '111.437395551653',
      volume: '4893779',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-26': {
      open: '154.31',
      high: '155.12',
      low: '153.54',
      close: '153.6',
      'adjusted close': '111.379385455062',
      volume: '4253664',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-25': {
      open: '156.01',
      high: '156.37',
      low: '153.06',
      close: '153.495',
      'adjusted close': '111.303247203286',
      volume: '5443794',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-24': {
      open: '159.65',
      high: '159.7',
      low: '155.171',
      close: '155.88',
      'adjusted close': '113.03267320791',
      volume: '8173017',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-23': {
      open: '162.05',
      high: '162.51',
      low: '159.54',
      close: '159.55',
      'adjusted close': '115.69388638903',
      volume: '5765384',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-20': {
      open: '161.07',
      high: '162.48',
      low: '159.77',
      close: '162.07',
      'adjusted close': '117.521204431652',
      volume: '7848320',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-19': {
      open: '159.8',
      high: '160.96',
      low: '159.085',
      close: '160.9',
      'adjusted close': '116.672806769006',
      volume: '9878844',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-18': {
      open: '157.12',
      high: '161.23',
      low: '156.95',
      close: '159.53',
      'adjusted close': '115.679383864883',
      volume: '30445283',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-17': {
      open: '146.63',
      high: '147.12',
      low: '146.18',
      close: '146.54',
      'adjusted close': '106.25999443089',
      volume: '4941313',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-16': {
      open: '147.22',
      high: '147.67',
      low: '146.51',
      close: '146.83',
      'adjusted close': '106.470281031033',
      volume: '3035184',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-13': {
      open: '147.48',
      high: '147.85',
      low: '146.94',
      close: '147.1',
      'adjusted close': '106.666065107028',
      volume: '2490548',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-12': {
      open: '147.56',
      high: '147.89',
      low: '146.77',
      close: '147.03',
      'adjusted close': '106.615306272511',
      volume: '3255559',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-11': {
      open: '148.4',
      high: '148.47',
      low: '147.28',
      close: '147.62',
      'adjusted close': '107.043130734871',
      volume: '3674765',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-10': {
      open: '147.71',
      high: '148.95',
      low: '147.65',
      close: '148.5',
      'adjusted close': '107.681241797374',
      volume: '4028877',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-09': {
      open: '146.5',
      high: '147.79',
      low: '146.35',
      close: '147.39',
      'adjusted close': '106.876351707171',
      volume: '2678887',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-06': {
      open: '146.64',
      high: '146.85',
      low: '146.32',
      close: '146.48',
      'adjusted close': '106.216486858447',
      volume: '2248474',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-05': {
      open: '146.68',
      high: '147.54',
      low: '146.48',
      close: '146.72',
      'adjusted close': '106.39051714822',
      volume: '2536971',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-04': {
      open: '147.0',
      high: '147.02',
      low: '146.11',
      close: '146.48',
      'adjusted close': '106.216486858447',
      volume: '2225673',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-03': {
      open: '146.69',
      high: '147.1999',
      low: '146.34',
      close: '146.78',
      'adjusted close': '106.434024720664',
      volume: '2285725',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-10-02': {
      open: '145.35',
      high: '146.865',
      low: '145.21',
      close: '146.66',
      'adjusted close': '106.347009575777',
      volume: '2955450',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-29': {
      open: '145.45',
      high: '145.69',
      low: '144.92',
      close: '145.08',
      'adjusted close': '105.201310168101',
      volume: '2635887',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-28': {
      open: '145.33',
      high: '145.86',
      low: '144.21',
      close: '145.66',
      'adjusted close': '105.621883368387',
      volume: '2763219',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-27': {
      open: '146.68',
      high: '146.9',
      low: '145.03',
      close: '145.66',
      'adjusted close': '105.621883368387',
      volume: '3446073',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-26': {
      open: '146.36',
      high: '147.42',
      low: '145.94',
      close: '146.56',
      'adjusted close': '106.274496955038',
      volume: '4106513',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-25': {
      open: '145.12',
      high: '146.03',
      low: '144.91',
      close: '145.87',
      'adjusted close': '105.774159871939',
      volume: '5179476',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-22': {
      open: '145.59',
      high: '145.59',
      low: '144.59',
      close: '145.13',
      'adjusted close': '105.237566478471',
      volume: '2741663',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-21': {
      open: '145.65',
      high: '145.8911',
      low: '145.1',
      close: '145.26',
      'adjusted close': '105.331832885431',
      volume: '2289276',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-20': {
      open: '144.93',
      high: '145.88',
      low: '144.42',
      close: '145.88',
      'adjusted close': '105.781411134013',
      volume: '5384615',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-19': {
      open: '144.79',
      high: '144.98',
      low: '144.22',
      close: '144.39',
      'adjusted close': '104.700973085002',
      volume: '3456574',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-18': {
      open: '144.81',
      high: '145.4799',
      low: '144.27',
      close: '144.55',
      'adjusted close': '104.816993278185',
      volume: '3018012',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-15': {
      open: '144.82',
      high: '145.17',
      low: '144.08',
      close: '144.82',
      'adjusted close': '105.01277735418',
      volume: '7297773',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-14': {
      open: '145.86',
      high: '146.38',
      low: '145.51',
      close: '145.54',
      'adjusted close': '105.5348682235',
      volume: '3742944',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-13': {
      open: '145.35',
      high: '146.29',
      low: '145.34',
      close: '145.99',
      'adjusted close': '105.861175016826',
      volume: '4070378',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-12': {
      open: '145.11',
      high: '146.37',
      low: '144.51',
      close: '145.76',
      'adjusted close': '105.694395989126',
      volume: '5222522',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-11': {
      open: '143.54',
      high: '145.13',
      low: '143.01',
      close: '144.86',
      'adjusted close': '105.041782402475',
      volume: '4549334',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-08': {
      open: '143.15',
      high: '143.15',
      low: '141.64',
      close: '142.45',
      'adjusted close': '103.294228242666',
      volume: '3344701',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-07': {
      open: '144.1',
      high: '144.64',
      low: '142.41',
      close: '142.9',
      'adjusted close': '103.620535035991',
      volume: '4155968',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-06': {
      open: '143.88',
      high: '144.47',
      low: '143.5',
      close: '143.82',
      'adjusted close': '104.28765114679',
      volume: '3205295',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-05': {
      open: '143.5',
      high: '144.3575',
      low: '142.7',
      close: '143.04',
      'adjusted close': '103.722052705026',
      volume: '3097503',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-09-01': {
      open: '142.98',
      high: '144.5',
      low: '142.98',
      close: '144.08',
      'adjusted close': '104.476183960711',
      volume: '3348503',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-31': {
      open: '143.02',
      high: '143.66',
      low: '142.63',
      close: '143.03',
      'adjusted close': '103.714801442952',
      volume: '3614517',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-30': {
      open: '143.26',
      high: '143.28',
      low: '142.41',
      close: '142.56',
      'adjusted close': '103.373992125479',
      volume: '2715466',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-29': {
      open: '142.1',
      high: '143.425',
      low: '141.9',
      close: '143.14',
      'adjusted close': '103.794565325765',
      volume: '3000993',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-28': {
      open: '144.11',
      high: '144.56',
      low: '142.39',
      close: '142.51',
      'adjusted close': '103.337735815109',
      volume: '5086015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-25': {
      open: '143.3',
      high: '144.19',
      low: '143.25',
      close: '143.74',
      'adjusted close': '104.229641050199',
      volume: '2866131',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-24': {
      open: '142.5',
      high: '143.68',
      low: '142.25',
      close: '142.94',
      'adjusted close': '103.649540084287',
      volume: '3580245',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-23': {
      open: '140.7',
      high: '143.05',
      low: '140.68',
      close: '142.14',
      'adjusted close': '103.069439118375',
      volume: '4035421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-22': {
      open: '140.64',
      high: '141.15',
      low: '140.38',
      close: '141.01',
      'adjusted close': '102.250046504025',
      volume: '2495902',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-21': {
      open: '139.59',
      high: '140.42',
      low: '139.13',
      close: '140.33',
      'adjusted close': '101.756960683',
      volume: '2949410',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-18': {
      open: '140.87',
      high: '141.15',
      low: '139.58',
      close: '139.7',
      'adjusted close': '101.300131172344',
      volume: '4128761',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-17': {
      open: '142.52',
      high: '142.89',
      low: '140.7',
      close: '140.7',
      'adjusted close': '102.025257379734',
      volume: '4342286',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-16': {
      open: '141.79',
      high: '142.91',
      low: '141.61',
      close: '142.5',
      'adjusted close': '103.330484553036',
      volume: '3239516',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-15': {
      open: '142.55',
      high: '143.34',
      low: '140.58',
      close: '142.07',
      'adjusted close': '103.018680283858',
      volume: '4652618',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-14': {
      open: '142.0',
      high: '142.76',
      low: '141.94',
      close: '142.32',
      'adjusted close': '103.199961835705',
      volume: '2222304',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-11': {
      open: '142.45',
      high: '142.59',
      low: '141.52',
      close: '141.84',
      'adjusted close': '102.851901256158',
      volume: '3004040',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-10': {
      open: '141.64',
      high: '142.42',
      low: '141.23',
      close: '141.84',
      'adjusted close': '102.851901256158',
      volume: '4378186',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-09': {
      open: '141.75',
      high: '142.03',
      low: '141.19',
      close: '141.77',
      'adjusted close': '102.801142421641',
      volume: '2920582',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-08': {
      open: '142.0',
      high: '142.76',
      low: '142.0',
      close: '142.11',
      'adjusted close': '103.047685332154',
      volume: '3281972',
      'dividend amount': '1.5000',
      'split coefficient': '1.0',
    },
    '2017-08-07': {
      open: '145.0',
      high: '145.09',
      low: '142.75',
      close: '143.47',
      'adjusted close': '102.947228010613',
      volume: '4576508',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-04': {
      open: '145.0',
      high: '145.39',
      low: '144.4',
      close: '145.16',
      'adjusted close': '104.159891392072',
      volume: '2542800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-03': {
      open: '144.43',
      high: '145.34',
      low: '144.43',
      close: '144.94',
      'adjusted close': '104.002029886793',
      volume: '3028769',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-02': {
      open: '145.12',
      high: '145.29',
      low: '144.22',
      close: '144.45',
      'adjusted close': '103.6504292614',
      volume: '3400024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-08-01': {
      open: '145.0',
      high: '145.67',
      low: '144.72',
      close: '145.3',
      'adjusted close': '104.260348713613',
      volume: '3197848',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-31': {
      open: '144.38',
      high: '144.93',
      low: '144.17',
      close: '144.67',
      'adjusted close': '103.808290766678',
      volume: '4333680',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-28': {
      open: '144.81',
      high: '145.04',
      low: '143.84',
      close: '144.29',
      'adjusted close': '103.535620893924',
      volume: '3022662',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-27': {
      open: '145.0',
      high: '145.395',
      low: '143.64',
      close: '145.07',
      'adjusted close': '104.095311685367',
      volume: '6374650',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-26': {
      open: '146.27',
      high: '146.46',
      low: '144.47',
      close: '145.36',
      'adjusted close': '104.303401851416',
      volume: '3806651',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-25': {
      open: '146.57',
      high: '147.49',
      low: '146.03',
      close: '146.19',
      'adjusted close': '104.898970257695',
      volume: '4184435',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-24': {
      open: '147.0',
      high: '147.04',
      low: '145.8',
      close: '145.99',
      'adjusted close': '104.75545979835',
      volume: '3818232',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-21': {
      open: '147.59',
      high: '147.87',
      low: '146.51',
      close: '147.08',
      'adjusted close': '105.537591801777',
      volume: '6456514',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-20': {
      open: '147.53',
      high: '148.83',
      low: '147.03',
      close: '147.66',
      'adjusted close': '105.953772133875',
      volume: '7105481',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-19': {
      open: '150.02',
      high: '150.25',
      low: '146.71',
      close: '147.53',
      'adjusted close': '105.860490335301',
      volume: '14043000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-18': {
      open: '152.36',
      high: '154.29',
      low: '152.0',
      close: '154.0',
      'adjusted close': '110.503053695088',
      volume: '6867302',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-17': {
      open: '153.59',
      high: '153.88',
      low: '152.2399',
      close: '153.01',
      'adjusted close': '109.792676921334',
      volume: '4751141',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-14': {
      open: '154.01',
      high: '154.615',
      low: '153.4',
      close: '154.24',
      'adjusted close': '110.675266246302',
      volume: '3214337',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-13': {
      open: '153.7',
      high: '154.19',
      low: '153.19',
      close: '153.63',
      'adjusted close': '110.237559345302',
      volume: '2474847',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-12': {
      open: '153.48',
      high: '154.24',
      low: '153.05',
      close: '153.7',
      'adjusted close': '110.287788006072',
      volume: '3090315',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-11': {
      open: '153.26',
      high: '153.65',
      low: '152.05',
      close: '153.19',
      'adjusted close': '109.921836334744',
      volume: '3431418',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-10': {
      open: '152.91',
      high: '153.89',
      low: '152.63',
      close: '153.42',
      'adjusted close': '110.08687336299',
      volume: '2804780',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-07': {
      open: '152.62',
      high: '153.49',
      low: '152.14',
      close: '152.94',
      'adjusted close': '109.742448260564',
      volume: '2345721',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-06': {
      open: '153.36',
      high: '153.83',
      low: '152.23',
      close: '152.36',
      'adjusted close': '109.326267928465',
      volume: '2735717',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-05': {
      open: '155.77',
      high: '155.89',
      low: '153.63',
      close: '153.67',
      'adjusted close': '110.26626143717',
      volume: '3558639',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-07-03': {
      open: '153.58',
      high: '156.025',
      low: '153.52',
      close: '155.58',
      'adjusted close': '111.636786323908',
      volume: '2822499',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-30': {
      open: '154.28',
      high: '154.5',
      low: '153.14',
      close: '153.83',
      'adjusted close': '110.381069804646',
      volume: '3501395',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-29': {
      open: '155.35',
      high: '155.74',
      low: '153.62',
      close: '154.13',
      'adjusted close': '110.596335493662',
      volume: '3245649',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-28': {
      open: '155.15',
      high: '155.55',
      low: '154.78',
      close: '155.32',
      'adjusted close': '111.450222726761',
      volume: '2203062',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-27': {
      open: '155.0',
      high: '155.34',
      low: '154.5',
      close: '154.75',
      'adjusted close': '111.041217917629',
      volume: '2385619',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-26': {
      open: '154.71',
      high: '155.75',
      low: '154.63',
      close: '155.23',
      'adjusted close': '111.385643020056',
      volume: '2129945',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-23': {
      open: '154.34',
      high: '154.68',
      low: '153.8',
      close: '154.11',
      'adjusted close': '110.581984447728',
      volume: '3148468',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-22': {
      open: '154.55',
      high: '154.99',
      low: '153.69',
      close: '154.4',
      'adjusted close': '110.790074613777',
      volume: '2361275',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-21': {
      open: '155.79',
      high: '155.79',
      low: '153.39',
      close: '153.79',
      'adjusted close': '110.352367712777',
      volume: '3934281',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-20': {
      open: '154.48',
      high: '155.389',
      low: '154.25',
      close: '154.95',
      'adjusted close': '111.184728376974',
      volume: '4469538',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-19': {
      open: '155.51',
      high: '155.86',
      low: '154.54',
      close: '154.84',
      'adjusted close': '111.105797624334',
      volume: '4381436',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-16': {
      open: '154.23',
      high: '155.42',
      low: '152.9676',
      close: '155.38',
      'adjusted close': '111.493275864564',
      volume: '6128190',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-15': {
      open: '153.29',
      high: '154.69',
      low: '153.29',
      close: '154.22',
      'adjusted close': '110.660915200367',
      volume: '4626272',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-14': {
      open: '153.97',
      high: '154.9399',
      low: '152.94',
      close: '153.81',
      'adjusted close': '110.366718758711',
      volume: '3035864',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-13': {
      open: '155.44',
      high: '155.48',
      low: '154.15',
      close: '154.25',
      'adjusted close': '110.682441769269',
      volume: '3509558',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-12': {
      open: '154.19',
      high: '157.2',
      low: '154.02',
      close: '155.18',
      'adjusted close': '111.34976540522',
      volume: '6461725',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-09': {
      open: '152.0',
      high: '154.265',
      low: '151.88',
      close: '154.1',
      'adjusted close': '110.574808924761',
      volume: '4355401',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-08': {
      open: '151.0',
      high: '152.82',
      low: '150.92',
      close: '152.1',
      'adjusted close': '109.139704331318',
      volume: '3681319',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-07': {
      open: '153.05',
      high: '154.2',
      low: '150.8',
      close: '150.98',
      'adjusted close': '108.33604575899',
      volume: '4858631',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-06': {
      open: '152.0',
      high: '152.89',
      low: '152.0',
      close: '152.37',
      'adjusted close': '109.333443451433',
      volume: '3786593',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-05': {
      open: '151.82',
      high: '152.93',
      low: '151.6826',
      close: '152.41',
      'adjusted close': '109.362145543301',
      volume: '3971157',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-02': {
      open: '153.07',
      high: '153.2',
      low: '151.8',
      close: '152.05',
      'adjusted close': '109.103826716482',
      volume: '3573193',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-06-01': {
      open: '152.8',
      high: '152.87',
      low: '151.63',
      close: '152.67',
      'adjusted close': '109.548709140449',
      volume: '2904189',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-31': {
      open: '152.03',
      high: '152.8',
      low: '151.65',
      close: '152.63',
      'adjusted close': '109.52000704858',
      volume: '3496466',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-30': {
      open: '151.95',
      high: '152.67',
      low: '151.59',
      close: '151.73',
      'adjusted close': '108.874209981531',
      volume: '3609710',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-26': {
      open: '152.85',
      high: '153.0',
      low: '152.06',
      close: '152.49',
      'adjusted close': '109.419549727039',
      volume: '2440117',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-25': {
      open: '153.25',
      high: '153.73',
      low: '152.9459',
      close: '153.2',
      'adjusted close': '109.929011857711',
      volume: '2579828',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-24': {
      open: '152.21',
      high: '152.76',
      low: '151.23',
      close: '152.51',
      'adjusted close': '109.433900772974',
      volume: '3731333',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-23': {
      open: '152.57',
      high: '153.68',
      low: '151.92',
      close: '152.03',
      'adjusted close': '109.089475670547',
      volume: '2563975',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-22': {
      open: '152.1',
      high: '152.84',
      low: '151.5992',
      close: '152.64',
      'adjusted close': '109.527182571547',
      volume: '3248372',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-19': {
      open: '151.01',
      high: '152.46',
      low: '150.89',
      close: '151.98',
      'adjusted close': '109.053598055711',
      volume: '5617824',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-18': {
      open: '150.86',
      high: '151.33',
      low: '150.11',
      close: '150.78',
      'adjusted close': '108.192535299646',
      volume: '3268772',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-17': {
      open: '153.3',
      high: '154.14',
      low: '150.89',
      close: '150.93',
      'adjusted close': '108.300168144154',
      volume: '5048937',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-16': {
      open: '151.66',
      high: '153.99',
      low: '151.66',
      close: '153.68',
      'adjusted close': '110.273436960138',
      volume: '5452539',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-15': {
      open: '150.62',
      high: '151.83',
      low: '150.4502',
      close: '151.51',
      'adjusted close': '108.716348476252',
      volume: '4218359',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-12': {
      open: '150.3',
      high: '151.265',
      low: '150.0',
      close: '150.37',
      'adjusted close': '107.89833885799',
      volume: '3651964',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-11': {
      open: '151.05',
      high: '151.15',
      low: '149.79',
      close: '150.65',
      'adjusted close': '108.099253501072',
      volume: '5546379',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-10': {
      open: '151.651',
      high: '152.37',
      low: '151.13',
      close: '151.25',
      'adjusted close': '108.529784879105',
      volume: '4995590',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-09': {
      open: '152.6',
      high: '153.1',
      low: '151.5552',
      close: '152.11',
      'adjusted close': '109.146879854285',
      volume: '6823015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-08': {
      open: '152.8',
      high: '153.47',
      low: '152.2',
      close: '153.03',
      'adjusted close': '109.807027967269',
      volume: '7415765',
      'dividend amount': '1.5000',
      'split coefficient': '1.0',
    },
    '2017-05-05': {
      open: '153.52',
      high: '155.78',
      low: '153.0',
      close: '155.05',
      'adjusted close': '110.176533270724',
      volume: '12415240',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-04': {
      open: '158.89',
      high: '159.14',
      low: '158.36',
      close: '159.05',
      'adjusted close': '113.018881758844',
      volume: '4271669',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-03': {
      open: '158.74',
      high: '159.45',
      low: '158.52',
      close: '158.63',
      'adjusted close': '112.720435167591',
      volume: '3990429',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-02': {
      open: '159.44',
      high: '159.49',
      low: '158.64',
      close: '159.1',
      'adjusted close': '113.054411114945',
      volume: '3149949',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-05-01': {
      open: '160.05',
      high: '160.42',
      low: '158.7',
      close: '158.84',
      'adjusted close': '112.869658463217',
      volume: '4919710',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-28': {
      open: '160.5',
      high: '160.59',
      low: '159.7',
      close: '160.29',
      'adjusted close': '113.900009790161',
      volume: '4029533',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-27': {
      open: '160.29',
      high: '160.48',
      low: '159.63',
      close: '160.32',
      'adjusted close': '113.921327403821',
      volume: '4081640',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-26': {
      open: '160.53',
      high: '161.1',
      low: '159.89',
      close: '160.06',
      'adjusted close': '113.736574752094',
      volume: '4311484',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-25': {
      open: '161.78',
      high: '162.04',
      low: '160.38',
      close: '160.39',
      'adjusted close': '113.971068502363',
      volume: '4842189',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-24': {
      open: '161.29',
      high: '161.57',
      low: '160.42',
      close: '160.75',
      'adjusted close': '114.226879866294',
      volume: '5320608',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-21': {
      open: '162.05',
      high: '162.11',
      low: '160.38',
      close: '160.38',
      'adjusted close': '113.963962631143',
      volume: '5607756',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-20': {
      open: '161.32',
      high: '162.4',
      low: '160.5',
      close: '162.3',
      'adjusted close': '115.32828990544',
      volume: '6486809',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-19': {
      open: '161.76',
      high: '162.64',
      low: '159.6',
      close: '161.69',
      'adjusted close': '114.894831761002',
      volume: '192843',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-18': {
      open: '170.79',
      high: '171.69',
      low: '169.83',
      close: '170.05',
      'adjusted close': '120.835340101172',
      volume: '6345465',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-17': {
      open: '169.75',
      high: '171.3',
      low: '169.62',
      close: '171.1',
      'adjusted close': '121.581456579303',
      volume: '3720218',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-13': {
      open: '169.92',
      high: '171.36',
      low: '169.53',
      close: '169.53',
      'adjusted close': '120.465834797716',
      volume: '3205462',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-12': {
      open: '171.04',
      high: '171.2',
      low: '170.02',
      close: '170.66',
      'adjusted close': '121.26879824561',
      volume: '3276931',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-11': {
      open: '170.65',
      high: '171.23',
      low: '168.98',
      close: '170.58',
      'adjusted close': '121.211951275847',
      volume: '4890163',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-10': {
      open: '172.53',
      high: '172.56',
      low: '171.0',
      close: '171.2',
      'adjusted close': '121.652515291506',
      volume: '3789936',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-07': {
      open: '172.08',
      high: '172.93',
      low: '171.28',
      close: '172.14',
      'adjusted close': '122.320467186214',
      volume: '3588302',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-06': {
      open: '173.47',
      high: '173.47',
      low: '172.25',
      close: '172.45',
      'adjusted close': '122.540749194043',
      volume: '3435403',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-05': {
      open: '174.7',
      high: '176.33',
      low: '172.81',
      close: '172.88',
      'adjusted close': '122.846301656516',
      volume: '6238894',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-04': {
      open: '173.52',
      high: '174.96',
      low: '173.26',
      close: '174.52',
      'adjusted close': '124.011664536645',
      volume: '3108855',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-04-03': {
      open: '173.82',
      high: '174.87',
      low: '173.38',
      close: '174.5',
      'adjusted close': '123.997452794204',
      volume: '4286498',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-31': {
      open: '173.98',
      high: '174.95',
      low: '173.69',
      close: '174.14',
      'adjusted close': '123.741641430274',
      volume: '2913684',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-30': {
      open: '173.86',
      high: '174.59',
      low: '173.69',
      close: '173.86',
      'adjusted close': '123.542677036105',
      volume: '3168254',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-29': {
      open: '174.3',
      high: '174.49',
      low: '173.46',
      close: '173.94',
      'adjusted close': '123.599524005868',
      volume: '3085606',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-28': {
      open: '173.94',
      high: '175.0',
      low: '173.0',
      close: '174.51',
      'adjusted close': '124.004558665425',
      volume: '3523345',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-27': {
      open: '172.69',
      high: '174.16',
      low: '172.0937',
      close: '173.77',
      'adjusted close': '123.478724195123',
      volume: '3259049',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-24': {
      open: '175.12',
      high: '175.5',
      low: '173.39',
      close: '173.83',
      'adjusted close': '123.521359422444',
      volume: '3221672',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-23': {
      open: '174.43',
      high: '175.67',
      low: '173.56',
      close: '174.82',
      'adjusted close': '124.224840673254',
      volume: '3739976',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-22': {
      open: '174.04',
      high: '175.06',
      low: '172.8',
      close: '174.78',
      'adjusted close': '124.196417188373',
      volume: '3450512',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-21': {
      open: '176.01',
      high: '176.23',
      low: '173.84',
      close: '173.88',
      'adjusted close': '123.556888778546',
      volume: '3927741',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-20': {
      open: '175.65',
      high: '176.18',
      low: '175.14',
      close: '175.7',
      'adjusted close': '124.85015734064',
      volume: '2476125',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-17': {
      open: '176.29',
      high: '176.79',
      low: '175.65',
      close: '175.65',
      'adjusted close': '124.814627984539',
      volume: '5920967',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-16': {
      open: '178.46',
      high: '179.0',
      low: '176.82',
      close: '177.24',
      'adjusted close': '125.944461508566',
      volume: '4311848',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-15': {
      open: '175.71',
      high: '176.28',
      low: '174.75',
      close: '175.81',
      'adjusted close': '124.928321924063',
      volume: '3815537',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-14': {
      open: '176.18',
      high: '176.82',
      low: '175.211',
      close: '175.72',
      'adjusted close': '124.864369083081',
      volume: '3147497',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-13': {
      open: '177.85',
      high: '178.06',
      low: '176.415',
      close: '176.46',
      'adjusted close': '125.390203553383',
      volume: '3482189',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-10': {
      open: '178.21',
      high: '179.49',
      low: '177.42',
      close: '177.83',
      'adjusted close': '126.363707910564',
      volume: '3100116',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-09': {
      open: '179.15',
      high: '179.25',
      low: '175.88',
      close: '177.18',
      'adjusted close': '125.901826281244',
      volume: '5444076',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-08': {
      open: '180.75',
      high: '180.95',
      low: '179.3',
      close: '179.45',
      'adjusted close': '127.514859048252',
      volume: '3557396',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-07': {
      open: '180.71',
      high: '181.29',
      low: '180.1997',
      close: '180.38',
      'adjusted close': '128.17570507174',
      volume: '2977596',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-06': {
      open: '179.72',
      high: '180.99',
      low: '179.57',
      close: '180.47',
      'adjusted close': '128.239657912722',
      volume: '3204434',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-03': {
      open: '180.04',
      high: '181.32',
      low: '179.7649',
      close: '180.05',
      'adjusted close': '127.94121132147',
      volume: '1825048',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-02': {
      open: '181.88',
      high: '181.88',
      low: '180.43',
      close: '180.53',
      'adjusted close': '128.282293140044',
      volume: '2918153',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-03-01': {
      open: '180.48',
      high: '182.55',
      low: '180.03',
      close: '181.95',
      'adjusted close': '129.291326853326',
      volume: '3005564',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-28': {
      open: '179.38',
      high: '180.63',
      low: '179.35',
      close: '179.82',
      'adjusted close': '127.777776283403',
      volume: '3273335',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-27': {
      open: '181.19',
      high: '181.25',
      low: '179.28',
      close: '179.4',
      'adjusted close': '127.47932969215',
      volume: '3691209',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-24': {
      open: '180.21',
      high: '181.49',
      low: '179.89',
      close: '181.35',
      'adjusted close': '128.864974580109',
      volume: '3299019',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-23': {
      open: '182.0',
      high: '182.5',
      low: '180.92',
      close: '181.65',
      'adjusted close': '129.078150716718',
      volume: '2282526',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-22': {
      open: '180.09',
      high: '181.34',
      low: '180.09',
      close: '181.15',
      'adjusted close': '128.722857155703',
      volume: '3020540',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-21': {
      open: '180.6',
      high: '180.79',
      low: '179.12',
      close: '180.26',
      'adjusted close': '128.090434617096',
      volume: '4480199',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-17': {
      open: '181.25',
      high: '181.57',
      low: '180.0',
      close: '180.67',
      'adjusted close': '128.381775337128',
      volume: '3552305',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-16': {
      open: '181.45',
      high: '182.79',
      low: '180.87',
      close: '181.43',
      'adjusted close': '128.921821549871',
      volume: '3251547',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-15': {
      open: '180.2',
      high: '181.92',
      low: '179.81',
      close: '181.68',
      'adjusted close': '129.099468330378',
      volume: '2914292',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-14': {
      open: '178.57',
      high: '180.13',
      low: '178.35',
      close: '180.13',
      'adjusted close': '127.998058291232',
      volume: '2775818',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-13': {
      open: '179.24',
      high: '179.9',
      low: '178.84',
      close: '179.36',
      'adjusted close': '127.450906207269',
      volume: '3093372',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-10': {
      open: '177.37',
      high: '178.8701',
      low: '176.76',
      close: '178.68',
      'adjusted close': '126.967706964289',
      volume: '2930710',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-09': {
      open: '176.17',
      high: '177.8',
      low: '175.91',
      close: '177.21',
      'adjusted close': '125.923143894905',
      volume: '3090710',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-08': {
      open: '177.5',
      high: '177.5',
      low: '175.88',
      close: '176.17',
      'adjusted close': '125.184133287994',
      volume: '2913126',
      'dividend amount': '1.4000',
      'split coefficient': '1.0',
    },
    '2017-02-07': {
      open: '176.0',
      high: '178.62',
      low: '175.9',
      close: '178.46',
      'adjusted close': '125.81156967154',
      volume: '3873233',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-06': {
      open: '175.31',
      high: '175.98',
      low: '174.38',
      close: '175.86',
      'adjusted close': '123.978609449945',
      volume: '2650509',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-03': {
      open: '175.0',
      high: '176.34',
      low: '174.89',
      close: '175.82',
      'adjusted close': '123.95041006192',
      volume: '3309179',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-02': {
      open: '174.23',
      high: '174.97',
      low: '173.55',
      close: '174.58',
      'adjusted close': '123.076229033159',
      volume: '2620526',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-02-01': {
      open: '175.0',
      high: '175.7025',
      low: '172.89',
      close: '174.29',
      'adjusted close': '122.871783469981',
      volume: '2795445',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-31': {
      open: '175.05',
      high: '175.58',
      low: '173.61',
      close: '174.52',
      'adjusted close': '123.033929951122',
      volume: '4134049',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-30': {
      open: '176.98',
      high: '177.07',
      low: '174.5768',
      close: '175.8',
      'adjusted close': '123.936310367908',
      volume: '4093555',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-27': {
      open: '178.47',
      high: '179.2',
      low: '177.3',
      close: '177.3',
      'adjusted close': '124.993787418828',
      volume: '3482105',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-26': {
      open: '178.06',
      high: '178.88',
      low: '177.51',
      close: '178.66',
      'adjusted close': '125.952566611663',
      volume: '3063911',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-25': {
      open: '176.26',
      high: '179.25',
      low: '176.13',
      close: '178.29',
      'adjusted close': '125.691722272436',
      volume: '5922462',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-24': {
      open: '171.36',
      high: '176.0',
      low: '171.16',
      close: '175.9',
      'adjusted close': '124.006808837969',
      volume: '6952369',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-23': {
      open: '170.08',
      high: '171.25',
      low: '170.01',
      close: '171.03',
      'adjusted close': '120.57353334598',
      volume: '5477314',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-20': {
      open: '167.81',
      high: '170.64',
      low: '166.0',
      close: '170.55',
      'adjusted close': '120.235140689685',
      volume: '12606847',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-19': {
      open: '166.96',
      high: '167.45',
      low: '165.8',
      close: '166.81',
      'adjusted close': '117.59849790939',
      volume: '6963386',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-18': {
      open: '167.45',
      high: '168.59',
      low: '166.69',
      close: '166.8',
      'adjusted close': '117.591448062383',
      volume: '4005626',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-17': {
      open: '166.69',
      high: '168.18',
      low: '166.1201',
      close: '167.89',
      'adjusted close': '118.359881386052',
      volume: '3315655',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-13': {
      open: '167.97',
      high: '168.48',
      low: '166.875',
      close: '167.34',
      'adjusted close': '117.972139800715',
      volume: '2875319',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-12': {
      open: '167.77',
      high: '168.01',
      low: '165.56',
      close: '167.95',
      'adjusted close': '118.402180468089',
      volume: '2927953',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-11': {
      open: '166.05',
      high: '167.76',
      low: '165.6',
      close: '167.75',
      'adjusted close': '118.261183527967',
      volume: '3599464',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-10': {
      open: '167.98',
      high: '168.09',
      low: '165.34',
      close: '165.52',
      'adjusted close': '116.689067645598',
      volume: '4118694',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-09': {
      open: '169.47',
      high: '169.8',
      low: '167.62',
      close: '167.65',
      'adjusted close': '118.190685057905',
      volume: '3189413',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-06': {
      open: '168.69',
      high: '169.92',
      low: '167.52',
      close: '169.53',
      'adjusted close': '119.516056295059',
      volume: '2945536',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-05': {
      open: '169.25',
      high: '169.39',
      low: '167.26',
      close: '168.7',
      'adjusted close': '118.93091899355',
      volume: '2682181',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-04': {
      open: '167.77',
      high: '169.87',
      low: '167.36',
      close: '169.26',
      'adjusted close': '119.325710425893',
      volume: '3381232',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2017-01-03': {
      open: '167.0',
      high: '167.87',
      low: '166.01',
      close: '167.19',
      'adjusted close': '117.866392095623',
      volume: '2934299',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-30': {
      open: '166.44',
      high: '166.7',
      low: '165.5',
      close: '165.99',
      'adjusted close': '117.020410454886',
      volume: '2952200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-29': {
      open: '166.02',
      high: '166.99',
      low: '166.0',
      close: '166.6',
      'adjusted close': '117.450451122261',
      volume: '1663542',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-28': {
      open: '167.29',
      high: '167.74',
      low: '166.0',
      close: '166.19',
      'adjusted close': '117.161407395009',
      volume: '1757500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-27': {
      open: '166.98',
      high: '167.98',
      low: '166.85',
      close: '167.14',
      'adjusted close': '117.831142860592',
      volume: '1397455',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-23': {
      open: '167.0',
      high: '167.49',
      low: '166.45',
      close: '166.71',
      'adjusted close': '117.527999439328',
      volume: '1701228',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-22': {
      open: '167.36',
      high: '168.23',
      low: '166.58',
      close: '167.06',
      'adjusted close': '117.774744084543',
      volume: '2802631',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-21': {
      open: '166.25',
      high: '167.94',
      low: '165.25',
      close: '167.33',
      'adjusted close': '117.965089953709',
      volume: '3575210',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-20': {
      open: '167.49',
      high: '168.25',
      low: '166.45',
      close: '167.6',
      'adjusted close': '118.155435822874',
      volume: '2174569',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-19': {
      open: '166.83',
      high: '167.26',
      low: '166.0',
      close: '166.68',
      'adjusted close': '117.50684989831',
      volume: '2955864',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-16': {
      open: '168.97',
      high: '169.11',
      low: '166.06',
      close: '166.73',
      'adjusted close': '117.54209913334',
      volume: '7117050',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-15': {
      open: '168.01',
      high: '169.85',
      low: '167.78',
      close: '168.02',
      'adjusted close': '118.451529397132',
      volume: '3388570',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-14': {
      open: '168.37',
      high: '169.89',
      low: '167.45',
      close: '168.51',
      'adjusted close': '118.796971900433',
      volume: '4123821',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-13': {
      open: '165.68',
      high: '169.952',
      low: '165.68',
      close: '168.29',
      'adjusted close': '118.641875266298',
      volume: '5932271',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-12': {
      open: '166.72',
      high: '166.79',
      low: '165.07',
      close: '165.5',
      'adjusted close': '116.674967951585',
      volume: '3392030',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-09': {
      open: '165.18',
      high: '166.72',
      low: '164.6042',
      close: '166.52',
      'adjusted close': '117.394052346212',
      volume: '3146930',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-08': {
      open: '164.87',
      high: '166.0',
      low: '164.22',
      close: '165.36',
      'adjusted close': '116.5762700935',
      volume: '3266336',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-07': {
      open: '160.6',
      high: '165.18',
      low: '160.39',
      close: '164.79',
      'adjusted close': '116.17442881415',
      volume: '4435050',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-06': {
      open: '160.13',
      high: '160.79',
      low: '158.93',
      close: '160.35',
      'adjusted close': '113.044296743424',
      volume: '2858962',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-05': {
      open: '160.85',
      high: '161.15',
      low: '159.5875',
      close: '159.84',
      'adjusted close': '112.684754546111',
      volume: '3447052',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-02': {
      open: '159.0',
      high: '160.29',
      low: '158.41',
      close: '160.02',
      'adjusted close': '112.811651792222',
      volume: '2740782',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-12-01': {
      open: '161.95',
      high: '162.195',
      low: '158.3',
      close: '159.82',
      'adjusted close': '112.670654852099',
      volume: '4634740',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-30': {
      open: '163.35',
      high: '163.8',
      low: '162.21',
      close: '162.22',
      'adjusted close': '114.362618133572',
      volume: '4397777',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-29': {
      open: '164.0',
      high: '164.41',
      low: '163.03',
      close: '163.53',
      'adjusted close': '115.286148091376',
      volume: '3148196',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-28': {
      open: '163.2',
      high: '164.66',
      low: '162.7',
      close: '164.52',
      'adjusted close': '115.984082944984',
      volume: '4449355',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-25': {
      open: '161.83',
      high: '163.19',
      low: '161.83',
      close: '163.14',
      'adjusted close': '115.011204058137',
      volume: '1605344',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-23': {
      open: '161.94',
      high: '162.38',
      low: '161.3557',
      close: '161.98',
      'adjusted close': '114.193421805425',
      volume: '2256035',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-22': {
      open: '163.0',
      high: '163.0',
      low: '161.95',
      close: '162.67',
      'adjusted close': '114.679861248848',
      volume: '2770736',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-21': {
      open: '160.69',
      high: '163.0',
      low: '160.3697',
      close: '162.77',
      'adjusted close': '114.75035971891',
      volume: '4637097',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-18': {
      open: '159.8',
      high: '160.72',
      low: '159.21',
      close: '160.39',
      'adjusted close': '113.072496131449',
      volume: '2981633',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-17': {
      open: '159.22',
      high: '159.93',
      low: '158.85',
      close: '159.8',
      'adjusted close': '112.656555158087',
      volume: '2260984',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-16': {
      open: '158.46',
      high: '159.55',
      low: '158.03',
      close: '159.29',
      'adjusted close': '112.297012960774',
      volume: '2252806',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-15': {
      open: '158.42',
      high: '159.15',
      low: '157.55',
      close: '158.67',
      'adjusted close': '111.859922446393',
      volume: '3476996',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-14': {
      open: '161.25',
      high: '161.86',
      low: '157.58',
      close: '158.21',
      'adjusted close': '111.535629484111',
      volume: '5196498',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-11': {
      open: '159.97',
      high: '161.34',
      low: '159.33',
      close: '161.27',
      'adjusted close': '113.692882667989',
      volume: '4446630',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-10': {
      open: '157.66',
      high: '161.16',
      low: '157.59',
      close: '160.22',
      'adjusted close': '112.952648732345',
      volume: '7715807',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-09': {
      open: '152.96',
      high: '155.56',
      low: '151.0',
      close: '154.81',
      'adjusted close': '109.138681502024',
      volume: '5403688',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-08': {
      open: '154.56',
      high: '155.93',
      low: '153.06',
      close: '155.17',
      'adjusted close': '109.392475994245',
      volume: '3921044',
      'dividend amount': '1.4000',
      'split coefficient': '1.0',
    },
    '2016-11-07': {
      open: '153.99',
      high: '156.11',
      low: '153.84',
      close: '155.72',
      'adjusted close': '108.79859718863',
      volume: '3804943',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-04': {
      open: '152.4',
      high: '153.64',
      low: '151.87',
      close: '152.43',
      'adjusted close': '106.499936870427',
      volume: '2470353',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-03': {
      open: '152.51',
      high: '153.74',
      low: '151.8',
      close: '152.37',
      'adjusted close': '106.45801601356',
      volume: '2878728',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-02': {
      open: '152.48',
      high: '153.345',
      low: '151.67',
      close: '151.95',
      'adjusted close': '106.164570015491',
      volume: '3074338',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-11-01': {
      open: '153.5',
      high: '153.91',
      low: '151.74',
      close: '152.79',
      'adjusted close': '106.751462011628',
      volume: '3191924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-31': {
      open: '152.76',
      high: '154.33',
      low: '152.76',
      close: '153.69',
      'adjusted close': '107.380274864632',
      volume: '3553172',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-28': {
      open: '154.05',
      high: '154.44',
      low: '152.18',
      close: '152.61',
      'adjusted close': '106.625699441028',
      volume: '3654446',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-27': {
      open: '152.82',
      high: '154.06',
      low: '152.02',
      close: '153.35',
      'adjusted close': '107.142723342386',
      volume: '4229336',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-26': {
      open: '150.71',
      high: '152.94',
      low: '150.255',
      close: '151.81',
      'adjusted close': '106.066754682802',
      volume: '2811421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-25': {
      open: '150.69',
      high: '151.16',
      low: '149.83',
      close: '150.88',
      'adjusted close': '105.416981401365',
      volume: '2647416',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-24': {
      open: '150.4',
      high: '151.52',
      low: '150.4',
      close: '150.57',
      'adjusted close': '105.200390307552',
      volume: '2666740',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-21': {
      open: '150.58',
      high: '151.15',
      low: '149.56',
      close: '149.63',
      'adjusted close': '104.543630216637',
      volume: '4414171',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-20': {
      open: '151.28',
      high: '152.9',
      low: '151.02',
      close: '151.52',
      'adjusted close': '105.864137207945',
      volume: '4023075',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-19': {
      open: '151.27',
      high: '152.45',
      low: '150.83',
      close: '151.26',
      'adjusted close': '105.682480161522',
      volume: '4632854',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-18': {
      open: '150.02',
      high: '151.0',
      low: '147.79',
      close: '150.72',
      'adjusted close': '105.305192449719',
      volume: '12770570',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-17': {
      open: '154.45',
      high: '155.89',
      low: '154.34',
      close: '154.77',
      'adjusted close': '108.134850288237',
      volume: '5890421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-14': {
      open: '154.47',
      high: '155.53',
      low: '154.09',
      close: '154.45',
      'adjusted close': '107.911272384947',
      volume: '4358147',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-13': {
      open: '153.7',
      high: '154.22',
      low: '152.27',
      close: '153.72',
      'adjusted close': '107.401235293066',
      volume: '2909948',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-12': {
      open: '154.97',
      high: '154.97',
      low: '153.08',
      close: '154.29',
      'adjusted close': '107.799483433302',
      volume: '2963843',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-11': {
      open: '156.73',
      high: '156.95',
      low: '153.89',
      close: '154.79',
      'adjusted close': '108.148823907193',
      volume: '2901226',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-10': {
      open: '156.71',
      high: '158.49',
      low: '156.655',
      close: '157.02',
      'adjusted close': '109.706882420747',
      volume: '2481288',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-07': {
      open: '157.14',
      high: '157.7',
      low: '154.86',
      close: '155.67',
      'adjusted close': '108.763663141241',
      volume: '2671256',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-06': {
      open: '156.84',
      high: '157.43',
      low: '155.89',
      close: '156.88',
      'adjusted close': '109.609067088057',
      volume: '1950106',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-05': {
      open: '157.07',
      high: '157.83',
      low: '156.72',
      close: '157.08',
      'adjusted close': '109.748803277614',
      volume: '1684458',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-04': {
      open: '157.67',
      high: '158.53',
      low: '155.82',
      close: '156.46',
      'adjusted close': '109.315621089989',
      volume: '2884146',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-10-03': {
      open: '158.06',
      high: '158.37',
      low: '157.02',
      close: '157.61',
      'adjusted close': '110.119104179938',
      volume: '2227800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-30': {
      open: '158.9',
      high: '159.838',
      low: '158.06',
      close: '158.85',
      'adjusted close': '110.985468555188',
      volume: '3596878',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-29': {
      open: '158.63',
      high: '165.0',
      low: '157.46',
      close: '158.11',
      'adjusted close': '110.468444653829',
      volume: '3374838',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-28': {
      open: '156.99',
      high: '158.625',
      low: '156.23',
      close: '158.29',
      'adjusted close': '110.59420722443',
      volume: '3309408',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-27': {
      open: '154.32',
      high: '156.77',
      low: '153.81',
      close: '156.77',
      'adjusted close': '109.532212183801',
      volume: '2987195',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-26': {
      open: '154.46',
      high: '154.46',
      low: '153.46',
      close: '153.98',
      'adjusted close': '107.582892339489',
      volume: '2533325',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-23': {
      open: '155.62',
      high: '156.04',
      low: '154.71',
      close: '154.98',
      'adjusted close': '108.281573287271',
      volume: '2702143',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-22': {
      open: '156.15',
      high: '157.22',
      low: '155.69',
      close: '156.11',
      'adjusted close': '109.071082758265',
      volume: '2690915',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-21': {
      open: '154.91',
      high: '155.68',
      low: '153.87',
      close: '155.53',
      'adjusted close': '108.665847808551',
      volume: '2473895',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-20': {
      open: '155.87',
      high: '156.57',
      low: '154.45',
      close: '154.45',
      'adjusted close': '107.911272384947',
      volume: '2159092',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-19': {
      open: '154.87',
      high: '156.19',
      low: '154.55',
      close: '154.87',
      'adjusted close': '108.204718383015',
      volume: '2450117',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-16': {
      open: '155.04',
      high: '155.4',
      low: '153.47',
      close: '153.84',
      'adjusted close': '107.4850770068',
      volume: '6463213',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-15': {
      open: '154.3',
      high: '156.33',
      low: '153.21',
      close: '155.66',
      'adjusted close': '108.756676331763',
      volume: '3662573',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-14': {
      open: '155.7',
      high: '155.87',
      low: '153.39',
      close: '154.05',
      'adjusted close': '107.631800005834',
      volume: '3860553',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-13': {
      open: '157.63',
      high: '157.93',
      low: '155.5',
      close: '155.81',
      'adjusted close': '108.86147847393',
      volume: '3636719',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-12': {
      open: '155.26',
      high: '158.53',
      low: '154.84',
      close: '158.29',
      'adjusted close': '110.59420722443',
      volume: '4367529',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-09': {
      open: '158.03',
      high: '158.4',
      low: '155.65',
      close: '155.69',
      'adjusted close': '108.777636760197',
      volume: '5185972',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-08': {
      open: '160.55',
      high: '161.21',
      low: '158.76',
      close: '159.0',
      'adjusted close': '111.090270697355',
      volume: '3963164',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-07': {
      open: '160.19',
      high: '161.76',
      low: '160.0',
      close: '161.64',
      'adjusted close': '112.9347883995',
      volume: '2866227',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-06': {
      open: '159.88',
      high: '160.86',
      low: '159.11',
      close: '160.35',
      'adjusted close': '112.033489976861',
      volume: '2994056',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-02': {
      open: '159.88',
      high: '160.57',
      low: '159.15',
      close: '159.55',
      'adjusted close': '111.474545218635',
      volume: '2315366',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-09-01': {
      open: '158.32',
      high: '159.62',
      low: '158.1',
      close: '159.54',
      'adjusted close': '111.467558409158',
      volume: '2358385',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-31': {
      open: '159.66',
      high: '159.66',
      low: '158.26',
      close: '158.88',
      'adjusted close': '111.006428983621',
      volume: '2323649',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-30': {
      open: '159.76',
      high: '160.15',
      low: '158.81',
      close: '159.4',
      'adjusted close': '111.369743076468',
      volume: '1813343',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-29': {
      open: '158.83',
      high: '160.21',
      low: '158.5',
      close: '159.72',
      'adjusted close': '111.593320979758',
      volume: '2475853',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-26': {
      open: '158.88',
      high: '160.44',
      low: '157.85',
      close: '158.32',
      'adjusted close': '110.615167652863',
      volume: '2498909',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-25': {
      open: '159.0',
      high: '159.54',
      low: '158.42',
      close: '158.63',
      'adjusted close': '110.831758746676',
      volume: '2575023',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-24': {
      open: '159.89',
      high: '160.18',
      low: '158.32',
      close: '159.05',
      'adjusted close': '111.125204744744',
      volume: '3620365',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-23': {
      open: '160.33',
      high: '161.34',
      low: '160.23',
      close: '160.26',
      'adjusted close': '111.970608691561',
      volume: '2838137',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-22': {
      open: '160.0',
      high: '160.57',
      low: '159.13',
      close: '160.0',
      'adjusted close': '111.788951645137',
      volume: '2039331',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-19': {
      open: '160.84',
      high: '160.91',
      low: '159.52',
      close: '160.04',
      'adjusted close': '111.816898883049',
      volume: '2815163',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-18': {
      open: '160.89',
      high: '162.0',
      low: '160.4401',
      close: '161.36',
      'adjusted close': '112.739157734121',
      volume: '3652013',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-17': {
      open: '160.97',
      high: '161.16',
      low: '159.84',
      close: '160.44',
      'adjusted close': '112.096371262162',
      volume: '3225919',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-16': {
      open: '161.11',
      high: '161.93',
      low: '160.69',
      close: '160.7',
      'adjusted close': '112.278028308585',
      volume: '2210229',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-15': {
      open: '162.4',
      high: '162.97',
      low: '161.78',
      close: '161.88',
      'adjusted close': '113.102471826968',
      volume: '2970415',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-12': {
      open: '163.19',
      high: '163.46',
      low: '161.52',
      close: '161.95',
      'adjusted close': '113.151379493313',
      volume: '2543079',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-11': {
      open: '162.25',
      high: '164.95',
      low: '162.18',
      close: '163.53',
      'adjusted close': '114.255295390808',
      volume: '8768585',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-10': {
      open: '162.19',
      high: '162.66',
      low: '161.955',
      close: '162.08',
      'adjusted close': '113.242208016524',
      volume: '2466675',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-09': {
      open: '162.22',
      high: '162.772',
      low: '161.645',
      close: '161.77',
      'adjusted close': '113.025616922712',
      volume: '2737530',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-08': {
      open: '162.73',
      high: '163.27',
      low: '161.58',
      close: '162.04',
      'adjusted close': '113.214260778613',
      volume: '3039081',
      'dividend amount': '1.4000',
      'split coefficient': '1.0',
    },
    '2016-08-05': {
      open: '162.0',
      high: '163.51',
      low: '161.57',
      close: '163.5',
      'adjusted close': '113.255822548355',
      volume: '3812370',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-04': {
      open: '160.86',
      high: '161.7',
      low: '160.12',
      close: '161.55',
      'adjusted close': '111.905065031724',
      volume: '2488878',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-03': {
      open: '160.13',
      high: '160.86',
      low: '159.8',
      close: '160.67',
      'adjusted close': '111.295492408833',
      volume: '2861558',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-02': {
      open: '161.27',
      high: '161.4499',
      low: '160.36',
      close: '160.58',
      'adjusted close': '111.23314975422',
      volume: '3080899',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-08-01': {
      open: '160.65',
      high: '161.65',
      low: '159.84',
      close: '161.45',
      'adjusted close': '111.835795415486',
      volume: '2823578',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-29': {
      open: '161.36',
      high: '161.67',
      low: '160.03',
      close: '160.62',
      'adjusted close': '111.260857600715',
      volume: '3547395',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-28': {
      open: '161.38',
      high: '161.81',
      low: '160.71',
      close: '161.37',
      'adjusted close': '111.780379722496',
      volume: '2340369',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-27': {
      open: '162.12',
      high: '162.57',
      low: '161.18',
      close: '161.83',
      'adjusted close': '112.099019957189',
      volume: '2162680',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-26': {
      open: '162.65',
      high: '163.6',
      low: '161.37',
      close: '162.12',
      'adjusted close': '112.299901844278',
      volume: '2892969',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-25': {
      open: '162.0',
      high: '162.88',
      low: '161.75',
      close: '162.65',
      'adjusted close': '112.667030810336',
      volume: '2804578',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-22': {
      open: '160.78',
      high: '162.17',
      low: '160.479',
      close: '162.07',
      'adjusted close': '112.265267036159',
      volume: '2520077',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-21': {
      open: '160.75',
      high: '161.85',
      low: '159.89',
      close: '160.45',
      'adjusted close': '111.143099253111',
      volume: '4046030',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-20': {
      open: '159.97',
      high: '161.71',
      low: '159.6',
      close: '161.36',
      'adjusted close': '111.773452760872',
      volume: '4373609',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-19': {
      open: '161.78',
      high: '162.14',
      low: '157.89',
      close: '159.58',
      'adjusted close': '110.540453591844',
      volume: '7119917',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-18': {
      open: '159.3',
      high: '160.75',
      low: '158.73',
      close: '159.86',
      'adjusted close': '110.734408517309',
      volume: '6716340',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-15': {
      open: '159.9',
      high: '159.98',
      low: '158.5',
      close: '159.78',
      'adjusted close': '110.678992824319',
      volume: '4474358',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-14': {
      open: '158.81',
      high: '161.4',
      low: '158.58',
      close: '160.28',
      'adjusted close': '111.025340905507',
      volume: '4637624',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-13': {
      open: '157.75',
      high: '158.34',
      low: '157.06',
      close: '158.02',
      'adjusted close': '109.459847578539',
      volume: '2746089',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-12': {
      open: '156.0',
      high: '157.12',
      low: '155.52',
      close: '157.04',
      'adjusted close': '108.781005339411',
      volume: '3843773',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-11': {
      open: '154.6',
      high: '156.04',
      low: '154.5',
      close: '155.33',
      'adjusted close': '107.59649490175',
      volume: '2833612',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-08': {
      open: '154.0',
      high: '154.6',
      low: '153.08',
      close: '154.46',
      'adjusted close': '106.993849240483',
      volume: '3040593',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-07': {
      open: '152.75',
      high: '153.6891',
      low: '151.89',
      close: '152.6',
      'adjusted close': '105.705434378465',
      volume: '2138421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-06': {
      open: '151.85',
      high: '152.41',
      low: '149.92',
      close: '152.37',
      'adjusted close': '105.546114261119',
      volume: '2569393',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-05': {
      open: '151.19',
      high: '152.0',
      low: '150.76',
      close: '151.68',
      'adjusted close': '105.06815390908',
      volume: '2464820',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-07-01': {
      open: '152.19',
      high: '152.97',
      low: '151.4',
      close: '152.35',
      'adjusted close': '105.532260337871',
      volume: '2658280',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-30': {
      open: '148.86',
      high: '151.91',
      low: '148.67',
      close: '151.78',
      'adjusted close': '105.137423525317',
      volume: '3967126',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-29': {
      open: '146.5',
      high: '148.48',
      low: '146.45',
      close: '148.46',
      'adjusted close': '102.837672266231',
      volume: '3367736',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-28': {
      open: '144.78',
      high: '145.73',
      low: '144.22',
      close: '145.7',
      'adjusted close': '100.925830858076',
      volume: '3996007',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-27': {
      open: '146.18',
      high: '146.6',
      low: '142.5',
      close: '143.5',
      'adjusted close': '99.4018993008502',
      volume: '5166665',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-24': {
      open: '149.97',
      high: '150.61',
      low: '146.18',
      close: '146.59',
      'adjusted close': '101.54233044259',
      volume: '9081182',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-23': {
      open: '153.45',
      high: '155.48',
      low: '152.6',
      close: '155.35',
      'adjusted close': '107.610348824997',
      volume: '3967419',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-22': {
      open: '153.75',
      high: '154.71',
      low: '152.27',
      close: '152.92',
      'adjusted close': '105.927097150425',
      volume: '2833364',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-21': {
      open: '154.16',
      high: '154.5689',
      low: '153.69',
      close: '154.05',
      'adjusted close': '106.709843813909',
      volume: '2760344',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-20': {
      open: '152.6',
      high: '154.67',
      low: '152.52',
      close: '153.61',
      'adjusted close': '106.405057502464',
      volume: '3572102',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-17': {
      open: '150.96',
      high: '152.72',
      low: '150.6044',
      close: '151.99',
      'adjusted close': '105.282889719416',
      volume: '4663870',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-16': {
      open: '150.19',
      high: '151.295',
      low: '149.0',
      close: '151.06',
      'adjusted close': '104.638682288407',
      volume: '2449064',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-15': {
      open: '151.46',
      high: '151.7925',
      low: '150.6',
      close: '150.68',
      'adjusted close': '104.375457746705',
      volume: '2260406',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-14': {
      open: '151.28',
      high: '151.79',
      low: '150.4',
      close: '151.06',
      'adjusted close': '104.638682288407',
      volume: '2865833',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-13': {
      open: '151.63',
      high: '152.5',
      low: '150.28',
      close: '151.28',
      'adjusted close': '104.79107544413',
      volume: '3610407',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-10': {
      open: '152.79',
      high: '153.3299',
      low: '151.86',
      close: '152.37',
      'adjusted close': '105.546114261119',
      volume: '3120563',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-09': {
      open: '152.92',
      high: '153.76',
      low: '152.73',
      close: '153.42',
      'adjusted close': '106.273445231613',
      volume: '2021796',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-08': {
      open: '153.53',
      high: '154.09',
      low: '153.04',
      close: '154.0',
      'adjusted close': '106.67520900579',
      volume: '2935036',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-07': {
      open: '153.31',
      high: '153.93',
      low: '152.66',
      close: '153.33',
      'adjusted close': '106.211102576999',
      volume: '4464985',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-06': {
      open: '153.09',
      high: '153.95',
      low: '152.34',
      close: '152.73',
      'adjusted close': '105.795484879574',
      volume: '2378462',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-03': {
      open: '152.4',
      high: '153.04',
      low: '151.7',
      close: '152.89',
      'adjusted close': '105.906316265554',
      volume: '2328056',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-02': {
      open: '152.59',
      high: '153.5',
      low: '152.12',
      close: '153.5',
      'adjusted close': '106.328860924603',
      volume: '2642601',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-06-01': {
      open: '153.0',
      high: '153.27',
      low: '151.54',
      close: '152.51',
      'adjusted close': '105.643091723851',
      volume: '2662937',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-31': {
      open: '152.56',
      high: '153.81',
      low: '152.27',
      close: '153.74',
      'adjusted close': '106.495108003573',
      volume: '5836645',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-27': {
      open: '152.35',
      high: '152.93',
      low: '152.15',
      close: '152.84',
      'adjusted close': '105.871681457435',
      volume: '2457037',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-26': {
      open: '151.55',
      high: '152.51',
      low: '151.05',
      close: '152.44',
      'adjusted close': '105.594602992485',
      volume: '3062934',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-25': {
      open: '148.93',
      high: '152.09',
      low: '148.5001',
      close: '151.69',
      'adjusted close': '105.075080870704',
      volume: '4358039',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-24': {
      open: '146.88',
      high: '148.75',
      low: '146.88',
      close: '148.31',
      'adjusted close': '102.733767841875',
      volume: '2929132',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-23': {
      open: '147.61',
      high: '147.95',
      low: '146.66',
      close: '146.77',
      'adjusted close': '101.667015751817',
      volume: '2103733',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-20': {
      open: '145.71',
      high: '147.51',
      low: '145.55',
      close: '147.25',
      'adjusted close': '101.999509909757',
      volume: '3670262',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-19': {
      open: '146.48',
      high: '146.93',
      low: '143.955',
      close: '144.93',
      'adjusted close': '100.392454813047',
      volume: '3685595',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-18': {
      open: '147.99',
      high: '148.52',
      low: '146.36',
      close: '147.34',
      'adjusted close': '102.061852564371',
      volume: '2491086',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-17': {
      open: '149.21',
      high: '149.5',
      low: '147.29',
      close: '148.0',
      'adjusted close': '102.519032031539',
      volume: '3490374',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-16': {
      open: '147.65',
      high: '149.99',
      low: '147.44',
      close: '149.46',
      'adjusted close': '103.530368428607',
      volume: '3069126',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-13': {
      open: '148.79',
      high: '149.86',
      low: '147.42',
      close: '147.72',
      'adjusted close': '102.325077106074',
      volume: '2397902',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-12': {
      open: '149.21',
      high: '149.39',
      low: '147.11',
      close: '148.84',
      'adjusted close': '103.100896807934',
      volume: '3249032',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-11': {
      open: '149.71',
      high: '151.09',
      low: '148.74',
      close: '148.95',
      'adjusted close': '103.177093385795',
      volume: '3092408',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-10': {
      open: '148.24',
      high: '150.04',
      low: '147.74',
      close: '149.97',
      'adjusted close': '103.883643471418',
      volume: '3992536',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-09': {
      open: '147.7',
      high: '148.2',
      low: '147.01',
      close: '147.34',
      'adjusted close': '102.061852564371',
      volume: '4302425',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-06': {
      open: '144.86',
      high: '147.97',
      low: '144.47',
      close: '147.29',
      'adjusted close': '102.027217756252',
      volume: '4895684',
      'dividend amount': '1.4000',
      'split coefficient': '1.0',
    },
    '2016-05-05': {
      open: '145.95',
      high: '147.3',
      low: '145.45',
      close: '146.47',
      'adjusted close': '100.503911391205',
      volume: '6500738',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-04': {
      open: '143.36',
      high: '145.0',
      low: '143.31',
      close: '144.25',
      'adjusted close': '98.9806050261579',
      volume: '2576786',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-03': {
      open: '144.65',
      high: '144.9',
      low: '142.9',
      close: '144.13',
      'adjusted close': '98.8982641415607',
      volume: '3584261',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-05-02': {
      open: '146.56',
      high: '147.0',
      low: '144.43',
      close: '145.27',
      'adjusted close': '99.6805025452336',
      volume: '3502443',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-29': {
      open: '146.49',
      high: '147.34',
      low: '144.191',
      close: '145.94',
      'adjusted close': '100.140239150901',
      volume: '4225790',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-28': {
      open: '149.75',
      high: '150.18',
      low: '146.725',
      close: '147.07',
      'adjusted close': '100.915615814191',
      volume: '3791337',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-27': {
      open: '149.35',
      high: '150.78',
      low: '148.97',
      close: '150.47',
      'adjusted close': '103.248607544443',
      volume: '3110789',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-26': {
      open: '148.65',
      high: '149.79',
      low: '147.9',
      close: '149.08',
      'adjusted close': '102.294825631193',
      volume: '2979699',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-25': {
      open: '148.16',
      high: '148.9',
      low: '147.11',
      close: '148.81',
      'adjusted close': '102.10955864085',
      volume: '2848935',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-22': {
      open: '149.44',
      high: '151.0',
      low: '147.5',
      close: '148.5',
      'adjusted close': '101.896844688974',
      volume: '5191802',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-21': {
      open: '146.58',
      high: '150.12',
      low: '146.46',
      close: '149.3',
      'adjusted close': '102.445783919621',
      volume: '6015526',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-20': {
      open: '144.24',
      high: '147.2',
      low: '144.0',
      close: '146.11',
      'adjusted close': '100.256888737414',
      volume: '6754189',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-19': {
      open: '146.47',
      high: '146.95',
      low: '142.61',
      close: '144.0',
      'adjusted close': '98.8090615165805',
      volume: '13188308',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-18': {
      open: '151.75',
      high: '153.14',
      low: '151.49',
      close: '152.53',
      'adjusted close': '104.662126063361',
      volume: '6538819',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-15': {
      open: '151.04',
      high: '152.1',
      low: '150.96',
      close: '151.72',
      'adjusted close': '104.10632509233',
      volume: '3657466',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-14': {
      open: '151.5',
      high: '152.76',
      low: '150.38',
      close: '151.16',
      'adjusted close': '103.722067630877',
      volume: '3176300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-13': {
      open: '150.75',
      high: '151.23',
      low: '149.63',
      close: '151.23',
      'adjusted close': '103.770099813559',
      volume: '3167970',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-12': {
      open: '149.61',
      high: '150.11',
      low: '148.58',
      close: '149.63',
      'adjusted close': '102.672221352263',
      volume: '3595549',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-11': {
      open: '150.26',
      high: '151.95',
      low: '149.22',
      close: '149.25',
      'adjusted close': '102.411475217706',
      volume: '3845918',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-08': {
      open: '149.06',
      high: '149.99',
      low: '148.12',
      close: '149.35',
      'adjusted close': '102.480092621537',
      volume: '3293512',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-07': {
      open: '149.07',
      high: '149.6',
      low: '148.0',
      close: '148.25',
      'adjusted close': '101.725301179396',
      volume: '3662508',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-06': {
      open: '149.9',
      high: '150.15',
      low: '147.81',
      close: '150.02',
      'adjusted close': '102.939829227204',
      volume: '4194265',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-05': {
      open: '151.43',
      high: '151.6',
      low: '149.65',
      close: '150.0',
      'adjusted close': '102.926105746438',
      volume: '3336952',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-04': {
      open: '152.34',
      high: '153.52',
      low: '151.91',
      close: '152.07',
      'adjusted close': '104.346486005739',
      volume: '3930319',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-04-01': {
      open: '150.51',
      high: '152.96',
      low: '150.25',
      close: '152.52',
      'adjusted close': '104.655264322978',
      volume: '3925181',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-31': {
      open: '149.79',
      high: '153.1',
      low: '149.36',
      close: '151.45',
      'adjusted close': '103.921058101987',
      volume: '6780132',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-30': {
      open: '149.95',
      high: '150.41',
      low: '148.32',
      close: '148.41',
      'adjusted close': '101.835089025526',
      volume: '3330841',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-29': {
      open: '147.59',
      high: '149.76',
      low: '147.5',
      close: '149.33',
      'adjusted close': '102.466369140771',
      volume: '3138545',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-28': {
      open: '147.75',
      high: '148.65',
      low: '147.23',
      close: '148.4',
      'adjusted close': '101.828227285143',
      volume: '3265084',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-24': {
      open: '144.98',
      high: '148.22',
      low: '144.49',
      close: '147.95',
      'adjusted close': '101.519448967903',
      volume: '5044335',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-23': {
      open: '148.0',
      high: '148.03',
      low: '145.13',
      close: '145.4',
      'adjusted close': '99.7697051702139',
      volume: '4763987',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-22': {
      open: '148.06',
      high: '149.28',
      low: '147.84',
      close: '148.1',
      'adjusted close': '101.62237507365',
      volume: '4081924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-21': {
      open: '147.3',
      high: '148.71',
      low: '146.7201',
      close: '148.63',
      'adjusted close': '101.986047313954',
      volume: '3721463',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-18': {
      open: '147.4',
      high: '147.51',
      low: '145.51',
      close: '147.09',
      'adjusted close': '100.929339294957',
      volume: '12088126',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-17': {
      open: '144.78',
      high: '147.32',
      low: '144.45',
      close: '147.04',
      'adjusted close': '100.895030593042',
      volume: '5821180',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-16': {
      open: '142.62',
      high: '144.88',
      low: '142.11',
      close: '144.79',
      'adjusted close': '99.351139006845',
      volume: '4221939',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-15': {
      open: '141.74',
      high: '143.33',
      low: '141.54',
      close: '142.96',
      'adjusted close': '98.0954405167385',
      volume: '3707538',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-14': {
      open: '142.01',
      high: '143.19',
      low: '141.04',
      close: '142.78',
      'adjusted close': '97.9719291898428',
      volume: '4047634',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-11': {
      open: '141.73',
      high: '142.9236',
      low: '140.51',
      close: '142.36',
      'adjusted close': '97.6837360937527',
      volume: '4421748',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-10': {
      open: '141.24',
      high: '141.47',
      low: '138.09',
      close: '140.19',
      'adjusted close': '96.1947384306209',
      volume: '4020024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-09': {
      open: '139.31',
      high: '142.17',
      low: '139.23',
      close: '140.41',
      'adjusted close': '96.345696719049',
      volume: '4406304',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-08': {
      open: '139.71',
      high: '140.35',
      low: '137.42',
      close: '139.07',
      'adjusted close': '95.4262235077142',
      volume: '5537856',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-07': {
      open: '137.28',
      high: '140.51',
      low: '136.8718',
      close: '140.15',
      'adjusted close': '96.1672914690886',
      volume: '5170228',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-04': {
      open: '137.535',
      high: '139.42',
      low: '137.02',
      close: '137.8',
      'adjusted close': '94.554782479061',
      volume: '3968423',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-03': {
      open: '137.22',
      high: '137.9602',
      low: '136.07',
      close: '137.8',
      'adjusted close': '94.554782479061',
      volume: '5155978',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-02': {
      open: '133.7',
      high: '137.44',
      low: '133.22',
      close: '136.3',
      'adjusted close': '93.5255214215967',
      volume: '5217230',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-03-01': {
      open: '132.24',
      high: '134.64',
      low: '132.03',
      close: '134.37',
      'adjusted close': '92.2012055276591',
      volume: '3788084',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-29': {
      open: '132.0',
      high: '133.36',
      low: '130.9',
      close: '131.03',
      'adjusted close': '89.9093842397051',
      volume: '4427472',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-26': {
      open: '134.51',
      high: '134.92',
      low: '131.95',
      close: '132.03',
      'adjusted close': '90.5955582780147',
      volume: '4385699',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-25': {
      open: '133.29',
      high: '134.51',
      low: '130.88',
      close: '134.5',
      'adjusted close': '92.2904081526394',
      volume: '4358205',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-24': {
      open: '131.55',
      high: '133.49',
      low: '129.68',
      close: '132.8',
      'adjusted close': '91.1239122875131',
      volume: '4122421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-23': {
      open: '133.14',
      high: '133.41',
      low: '132.01',
      close: '132.4',
      'adjusted close': '90.8494426721893',
      volume: '3439631',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-22': {
      open: '133.84',
      high: '134.31',
      low: '132.41',
      close: '133.77',
      'adjusted close': '91.7895011046734',
      volume: '4457444',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-19': {
      open: '132.36',
      high: '133.65',
      low: '130.84',
      close: '133.08',
      'adjusted close': '91.3160410182398',
      volume: '5125276',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-18': {
      open: '130.0',
      high: '134.0',
      low: '129.75',
      close: '132.45',
      'adjusted close': '90.8837513741047',
      volume: '9951438',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-17': {
      open: '123.71',
      high: '126.57',
      low: '123.25',
      close: '126.1',
      'adjusted close': '86.5265462308389',
      volume: '4820982',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-16': {
      open: '121.83',
      high: '123.09',
      low: '121.34',
      close: '122.74',
      'adjusted close': '84.2210014621187',
      volume: '4061066',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-12': {
      open: '119.26',
      high: '121.05',
      low: '118.35',
      close: '121.04',
      'adjusted close': '83.0545055969924',
      volume: '4935670',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-11': {
      open: '118.78',
      high: '119.66',
      low: '116.901',
      close: '117.85',
      'adjusted close': '80.8656104147848',
      volume: '7307503',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-10': {
      open: '125.0',
      high: '125.29',
      low: '119.84',
      close: '120.19',
      'adjusted close': '82.4712576644292',
      volume: '6462375',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-09': {
      open: '126.12',
      high: '126.93',
      low: '123.77',
      close: '124.07',
      'adjusted close': '85.1336129330704',
      volume: '5956138',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-08': {
      open: '126.0',
      high: '127.51',
      low: '124.13',
      close: '126.98',
      'adjusted close': '87.1303793845513',
      volume: '7363893',
      'dividend amount': '1.3000',
      'split coefficient': '1.0',
    },
    '2016-02-05': {
      open: '127.22',
      high: '128.64',
      low: '125.85',
      close: '128.57',
      'adjusted close': '87.3273532699701',
      volume: '7176765',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-04': {
      open: '125.08',
      high: '128.5',
      low: '125.0',
      close: '127.65',
      'adjusted close': '86.7024705989864',
      volume: '5265650',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-03': {
      open: '123.99',
      high: '125.4499',
      low: '122.29',
      close: '124.72',
      'adjusted close': '84.7123551359623',
      volume: '6223424',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-02': {
      open: '123.67',
      high: '124.14',
      low: '122.64',
      close: '122.94',
      'adjusted close': '83.5033430116677',
      volume: '3287218',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-02-01': {
      open: '124.4',
      high: '125.29',
      low: '123.71',
      close: '124.83',
      'adjusted close': '84.7870693683625',
      volume: '3574834',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-29': {
      open: '123.28',
      high: '124.79',
      low: '122.96',
      close: '124.79',
      'adjusted close': '84.7599005565806',
      volume: '8248077',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-28': {
      open: '121.26',
      high: '122.83',
      low: '121.01',
      close: '122.22',
      'adjusted close': '83.0143043995936',
      volume: '3942537',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-27': {
      open: '122.73',
      high: '123.37',
      low: '120.65',
      close: '120.96',
      'adjusted close': '82.1584868284637',
      volume: '5025161',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-26': {
      open: '122.25',
      high: '123.61',
      low: '122.24',
      close: '122.59',
      'adjusted close': '83.2656159085761',
      volume: '4617414',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-25': {
      open: '122.1',
      high: '124.23',
      low: '121.64',
      close: '122.08',
      'adjusted close': '82.9192135583569',
      volume: '5445560',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-22': {
      open: '124.67',
      high: '124.74',
      low: '121.77',
      close: '122.5',
      'adjusted close': '83.2044860820669',
      volume: '9238219',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-21': {
      open: '121.3',
      high: '125.1',
      low: '120.5751',
      close: '122.91',
      'adjusted close': '83.4829664028313',
      volume: '8851531',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-20': {
      open: '118.46',
      high: '123.99',
      low: '118.0',
      close: '121.86',
      'adjusted close': '82.7697850935565',
      volume: '16157769',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-19': {
      open: '130.11',
      high: '132.1',
      low: '128.06',
      close: '128.11',
      'adjusted close': '87.0149119344782',
      volume: '9955973',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-15': {
      open: '130.01',
      high: '130.88',
      low: '128.87',
      close: '130.03',
      'adjusted close': '88.3190149000094',
      volume: '9007945',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-14': {
      open: '131.93',
      high: '133.79',
      low: '131.22',
      close: '132.91',
      'adjusted close': '90.2751693483062',
      volume: '5709426',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-13': {
      open: '133.5',
      high: '134.2799',
      low: '131.1',
      close: '131.17',
      'adjusted close': '89.0933260357935',
      volume: '4700115',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-12': {
      open: '133.65',
      high: '133.78',
      low: '131.13',
      close: '132.9',
      'adjusted close': '90.2683771453607',
      volume: '5078360',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-11': {
      open: '131.81',
      high: '133.82',
      low: '131.76',
      close: '133.23',
      'adjusted close': '90.4925198425614',
      volume: '4974236',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-08': {
      open: '133.18',
      high: '133.816',
      low: '131.32',
      close: '131.63',
      'adjusted close': '89.4057673712854',
      volume: '4762583',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-07': {
      open: '133.7',
      high: '135.02',
      low: '132.43',
      close: '132.86',
      'adjusted close': '90.2412083335788',
      volume: '7024500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-06': {
      open: '134.38',
      high: '135.58',
      low: '133.62',
      close: '135.17',
      'adjusted close': '91.8102072139835',
      volume: '4310774',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-05': {
      open: '136.76',
      high: '136.89',
      low: '134.8501',
      close: '135.85',
      'adjusted close': '92.2720770142758',
      volume: '3924785',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2016-01-04': {
      open: '135.6',
      high: '135.97',
      low: '134.24',
      close: '135.95',
      'adjusted close': '92.3399990437305',
      volume: '5229231',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-31': {
      open: '139.07',
      high: '139.1',
      low: '137.57',
      close: '137.62',
      'adjusted close': '93.4742969356248',
      volume: '3462037',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-30': {
      open: '139.58',
      high: '140.44',
      low: '139.22',
      close: '139.34',
      'adjusted close': '94.6425558422465',
      volume: '2989367',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-29': {
      open: '138.25',
      high: '140.06',
      low: '138.2',
      close: '139.78',
      'adjusted close': '94.9414127718474',
      volume: '3943691',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-28': {
      open: '137.74',
      high: '138.045',
      low: '136.54',
      close: '137.61',
      'adjusted close': '93.4675047326794',
      volume: '3143412',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-24': {
      open: '138.43',
      high: '138.88',
      low: '138.11',
      close: '138.25',
      'adjusted close': '93.9022057211897',
      volume: '1495226',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-23': {
      open: '138.3',
      high: '139.305',
      low: '138.11',
      close: '138.54',
      'adjusted close': '94.0991796066085',
      volume: '5164909',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-22': {
      open: '135.88',
      high: '138.19',
      low: '135.65',
      close: '137.93',
      'adjusted close': '93.6848552269345',
      volume: '4263538',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-21': {
      open: '135.83',
      high: '135.83',
      low: '134.02',
      close: '135.5',
      'adjusted close': '92.0343499111842',
      volume: '5616636',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-18': {
      open: '136.41',
      high: '136.96',
      low: '134.27',
      close: '134.9',
      'adjusted close': '91.6268177344557',
      volume: '10008280',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-17': {
      open: '139.35',
      high: '139.5',
      low: '136.3075',
      close: '136.75',
      'adjusted close': '92.8833752793685',
      volume: '4089539',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-16': {
      open: '139.12',
      high: '139.65',
      low: '137.79',
      close: '139.29',
      'adjusted close': '94.6085948275191',
      volume: '4345474',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-15': {
      open: '137.4',
      high: '138.97',
      low: '137.28',
      close: '137.79',
      'adjusted close': '93.5897643856979',
      volume: '4238546',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-14': {
      open: '135.31',
      high: '136.14',
      low: '134.02',
      close: '135.93',
      'adjusted close': '92.3264146378396',
      volume: '5141549',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-11': {
      open: '135.23',
      high: '135.44',
      low: '133.91',
      close: '134.57',
      'adjusted close': '91.402675037255',
      volume: '5333567',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-10': {
      open: '137.03',
      high: '137.85',
      low: '135.7201',
      close: '136.78',
      'adjusted close': '92.9037518882049',
      volume: '4221948',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-09': {
      open: '137.38',
      high: '139.84',
      low: '136.23',
      close: '136.61',
      'adjusted close': '92.7882844381319',
      volume: '4614993',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-08': {
      open: '138.28',
      high: '139.06',
      low: '137.53',
      close: '138.05',
      'adjusted close': '93.7663616622802',
      volume: '3904755',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-07': {
      open: '140.16',
      high: '140.41',
      low: '138.81',
      close: '139.55',
      'adjusted close': '94.7851921041015',
      volume: '3279300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-04': {
      open: '138.09',
      high: '141.02',
      low: '137.99',
      close: '140.43',
      'adjusted close': '95.3829059633033',
      volume: '4567752',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-03': {
      open: '140.1',
      high: '140.73',
      low: '138.19',
      close: '138.92',
      'adjusted close': '94.3572833185365',
      volume: '5905763',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-02': {
      open: '140.93',
      high: '141.21',
      low: '139.5',
      close: '139.7',
      'adjusted close': '94.8870751482836',
      volume: '3725193',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-12-01': {
      open: '139.58',
      high: '141.4',
      low: '139.58',
      close: '141.28',
      'adjusted close': '95.9602432136686',
      volume: '4195119',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-30': {
      open: '138.61',
      high: '139.9',
      low: '138.52',
      close: '139.42',
      'adjusted close': '94.6968934658103',
      volume: '4542014',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-27': {
      open: '138.0',
      high: '138.81',
      low: '137.21',
      close: '138.46',
      'adjusted close': '94.0448419830447',
      volume: '1415840',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-25': {
      open: '138.37',
      high: '138.43',
      low: '137.375',
      close: '138.0',
      'adjusted close': '93.7324006475529',
      volume: '3238166',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-24': {
      open: '137.65',
      high: '139.34',
      low: '137.31',
      close: '138.6',
      'adjusted close': '94.1399328242813',
      volume: '3407603',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-23': {
      open: '138.53',
      high: '138.87',
      low: '137.12',
      close: '138.46',
      'adjusted close': '94.0448419830447',
      volume: '5137872',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-20': {
      open: '137.37',
      high: '138.915',
      low: '137.25',
      close: '138.5',
      'adjusted close': '94.0720107948266',
      volume: '5176255',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-19': {
      open: '136.21',
      high: '137.7355',
      low: '136.01',
      close: '136.74',
      'adjusted close': '92.876583076423',
      volume: '4753503',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-18': {
      open: '134.79',
      high: '135.91',
      low: '134.26',
      close: '135.82',
      'adjusted close': '92.2517004054393',
      volume: '4148771',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-17': {
      open: '134.22',
      high: '134.82',
      low: '133.31',
      close: '133.82',
      'adjusted close': '90.8932598163444',
      volume: '2814487',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-16': {
      open: '131.79',
      high: '134.09',
      low: '131.79',
      close: '133.71',
      'adjusted close': '90.8185455839442',
      volume: '4190777',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-13': {
      open: '132.95',
      high: '133.0',
      low: '131.65',
      close: '131.75',
      'adjusted close': '89.4872738066311',
      volume: '4609621',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-12': {
      open: '134.7',
      high: '134.7',
      low: '133.01',
      close: '133.04',
      'adjusted close': '90.3634679865973',
      volume: '4700465',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-11': {
      open: '136.09',
      high: '136.2',
      low: '134.5',
      close: '135.02',
      'adjusted close': '91.7083241698014',
      volume: '3164806',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-10': {
      open: '135.36',
      high: '135.735',
      low: '134.12',
      close: '135.47',
      'adjusted close': '92.0139733023477',
      volume: '4520387',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-09': {
      open: '137.92',
      high: '138.08',
      low: '134.88',
      close: '135.31',
      'adjusted close': '91.9052980552201',
      volume: '8074476',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-06': {
      open: '138.6',
      high: '139.55',
      low: '137.87',
      close: '138.25',
      'adjusted close': '93.9022057211897',
      volume: '4744493',
      'dividend amount': '1.3000',
      'split coefficient': '1.0',
    },
    '2015-11-05': {
      open: '141.61',
      high: '142.38',
      low: '139.63',
      close: '139.89',
      'adjusted close': '94.1309893109081',
      volume: '5093471',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-04': {
      open: '142.55',
      high: '142.65',
      low: '141.3',
      close: '141.63',
      'adjusted close': '95.3018229759377',
      volume: '3516890',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-03': {
      open: '140.02',
      high: '142.8',
      low: '139.9',
      close: '141.88',
      'adjusted close': '95.4700462036718',
      volume: '4349317',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-11-02': {
      open: '140.5',
      high: '140.5165',
      low: '139.57',
      close: '140.37',
      'adjusted close': '94.4539779081577',
      volume: '3822212',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-30': {
      open: '140.44',
      high: '141.33',
      low: '139.9',
      close: '140.08',
      'adjusted close': '94.2588389639861',
      volume: '3983990',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-29': {
      open: '140.36',
      high: '140.82',
      low: '139.0201',
      close: '140.55',
      'adjusted close': '94.5750986321262',
      volume: '3710245',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-28': {
      open: '137.92',
      high: '141.23',
      low: '137.5',
      close: '140.83',
      'adjusted close': '94.7635086471885',
      volume: '8510027',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-27': {
      open: '143.51',
      high: '143.83',
      low: '137.33',
      close: '137.86',
      'adjusted close': '92.765016701707',
      volume: '15005072',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-26': {
      open: '144.75',
      high: '145.0',
      low: '143.21',
      close: '143.66',
      'adjusted close': '96.6677955851388',
      volume: '3489428',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-23': {
      open: '144.61',
      high: '145.49',
      low: '143.7',
      close: '144.68',
      'adjusted close': '97.354146354294',
      volume: '5370439',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-22': {
      open: '141.74',
      high: '145.07',
      low: '141.62',
      close: '144.09',
      'adjusted close': '96.9571395368415',
      volume: '5583076',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-21': {
      open: '140.25',
      high: '142.66',
      low: '139.3',
      close: '140.92',
      'adjusted close': '94.8240690091727',
      volume: '7134526',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-20': {
      open: '142.49',
      high: '142.88',
      low: '140.27',
      close: '140.64',
      'adjusted close': '94.6356589941105',
      volume: '16025591',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-19': {
      open: '149.85',
      high: '149.97',
      low: '148.38',
      close: '149.22',
      'adjusted close': '100.409080169946',
      volume: '7954684',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-16': {
      open: '150.45',
      high: '151.2',
      low: '149.26',
      close: '150.39',
      'adjusted close': '101.196364875741',
      volume: '3483599',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-15': {
      open: '150.91',
      high: '151.24',
      low: '148.58',
      close: '150.09',
      'adjusted close': '100.994497002461',
      volume: '3476219',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-14': {
      open: '149.6',
      high: '150.65',
      low: '149.02',
      close: '150.01',
      'adjusted close': '100.940665569586',
      volume: '3352327',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-13': {
      open: '150.78',
      high: '150.78',
      low: '149.18',
      close: '149.62',
      'adjusted close': '100.67823733432',
      volume: '3915707',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-12': {
      open: '152.4',
      high: '152.58',
      low: '150.85',
      close: '151.14',
      'adjusted close': '101.701034558944',
      volume: '3227636',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-09': {
      open: '152.46',
      high: '153.147',
      low: '151.27',
      close: '152.39',
      'adjusted close': '102.542150697614',
      volume: '3531107',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-08': {
      open: '149.69',
      high: '153.019',
      low: '149.29',
      close: '152.28',
      'adjusted close': '102.468132477411',
      volume: '4794357',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-07': {
      open: '150.04',
      high: '150.73',
      low: '148.8565',
      close: '150.09',
      'adjusted close': '100.994497002461',
      volume: '2989915',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-06': {
      open: '149.06',
      high: '150.15',
      low: '148.49',
      close: '148.78',
      'adjusted close': '100.113007289134',
      volume: '3018074',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-05': {
      open: '145.82',
      high: '149.8925',
      low: '145.82',
      close: '149.04',
      'adjusted close': '100.287959445977',
      volume: '5131669',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-02': {
      open: '141.05',
      high: '144.59',
      low: '140.56',
      close: '144.58',
      'adjusted close': '97.2868570632004',
      volume: '3053928',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-10-01': {
      open: '145.31',
      high: '145.67',
      low: '141.59',
      close: '143.59',
      'adjusted close': '96.6206930813732',
      volume: '3880747',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-30': {
      open: '143.69',
      high: '145.71',
      low: '143.66',
      close: '144.97',
      'adjusted close': '97.5492852984656',
      volume: '3923866',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-29': {
      open: '141.99',
      high: '142.66',
      low: '141.1501',
      close: '142.47',
      'adjusted close': '95.8670530211243',
      volume: '4763509',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-28': {
      open: '144.42',
      high: '145.38',
      low: '142.47',
      close: '142.52',
      'adjusted close': '95.9006976666712',
      volume: '4292421',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-25': {
      open: '145.55',
      high: '146.27',
      low: '144.53',
      close: '145.42',
      'adjusted close': '97.852087108387',
      volume: '3474279',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-24': {
      open: '142.6',
      high: '145.07',
      low: '141.95',
      close: '144.41',
      'adjusted close': '97.1724652683412',
      volume: '3278207',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-23': {
      open: '144.21',
      high: '144.57',
      low: '142.75',
      close: '143.66',
      'adjusted close': '96.6677955851388',
      volume: '2674225',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-22': {
      open: '144.62',
      high: '145.06',
      low: '143.7699',
      close: '144.43',
      'adjusted close': '97.1859231265599',
      volume: '3564015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-21': {
      open: '145.39',
      high: '146.98',
      low: '144.92',
      close: '146.48',
      'adjusted close': '98.5653535939797',
      volume: '3824858',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-18': {
      open: '146.05',
      high: '146.385',
      low: '143.98',
      close: '144.51',
      'adjusted close': '97.2397545594348',
      volume: '7975845',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-17': {
      open: '148.1',
      high: '149.68',
      low: '147.3',
      close: '148.14',
      'adjusted close': '99.6823558261343',
      volume: '4002952',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-16': {
      open: '147.84',
      high: '148.89',
      low: '147.54',
      close: '148.41',
      'adjusted close': '99.8640369120872',
      volume: '2799222',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-15': {
      open: '146.6',
      high: '147.93',
      low: '145.76',
      close: '147.53',
      'adjusted close': '99.2718911504631',
      volume: '2717060',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-14': {
      open: '147.37',
      high: '147.37',
      low: '145.41',
      close: '145.65',
      'adjusted close': '98.0068524779024',
      volume: '3226747',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-11': {
      open: '145.91',
      high: '147.5',
      low: '145.67',
      close: '147.37',
      'adjusted close': '99.1642282847132',
      volume: '3113953',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-10': {
      open: '145.85',
      high: '147.16',
      low: '144.51',
      close: '146.2',
      'adjusted close': '98.3769435789175',
      volume: '3461658',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-09': {
      open: '148.74',
      high: '149.04',
      low: '144.85',
      close: '145.05',
      'adjusted close': '97.6031167313405',
      volume: '3407761',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-08': {
      open: '145.86',
      high: '147.34',
      low: '145.66',
      close: '147.23',
      'adjusted close': '99.0700232771821',
      volume: '3933010',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-04': {
      open: '144.57',
      high: '145.4',
      low: '143.32',
      close: '143.7',
      'adjusted close': '96.6947113015762',
      volume: '4201068',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-03': {
      open: '146.05',
      high: '148.03',
      low: '145.77',
      close: '146.78',
      'adjusted close': '98.7672214672607',
      volume: '3603518',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-02': {
      open: '144.92',
      high: '145.08',
      low: '143.18',
      close: '145.05',
      'adjusted close': '97.6031167313405',
      volume: '4251778',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-09-01': {
      open: '144.84',
      high: '144.98',
      low: '141.85',
      close: '142.68',
      'adjusted close': '96.008360532421',
      volume: '5271696',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-31': {
      open: '147.26',
      high: '148.4',
      low: '146.26',
      close: '147.89',
      'adjusted close': '99.5141325984002',
      volume: '4093078',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-28': {
      open: '147.75',
      high: '148.2',
      low: '147.18',
      close: '147.98',
      'adjusted close': '99.5746929603845',
      volume: '4076211',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-27': {
      open: '148.63',
      high: '148.97',
      low: '145.66',
      close: '148.54',
      'adjusted close': '99.9515129905089',
      volume: '4975966',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-26': {
      open: '144.09',
      high: '146.98',
      low: '142.14',
      close: '146.7',
      'adjusted close': '98.7133900343858',
      volume: '6197229',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-25': {
      open: '146.94',
      high: '147.11',
      low: '140.62',
      close: '140.96',
      'adjusted close': '94.8509847256102',
      volume: '7071869',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-24': {
      open: '143.47',
      high: '147.76',
      low: '142.32',
      close: '143.47',
      'adjusted close': '96.5399459320608',
      volume: '10186817',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-21': {
      open: '151.5',
      high: '153.19',
      low: '148.7',
      close: '148.85',
      'adjusted close': '100.160109792899',
      volume: '7360898',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-20': {
      open: '152.74',
      high: '153.91',
      low: '152.5',
      close: '152.66',
      'adjusted close': '102.723831783567',
      volume: '4011514',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-19': {
      open: '155.15',
      high: '155.67',
      low: '153.41',
      close: '153.94',
      'adjusted close': '103.585134709566',
      volume: '4205904',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-18': {
      open: '155.51',
      high: '156.52',
      low: '155.25',
      close: '156.01',
      'adjusted close': '104.978023035205',
      volume: '2018337',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-17': {
      open: '155.2',
      high: '156.69',
      low: '154.7',
      close: '156.31',
      'adjusted close': '105.179890908486',
      volume: '2249554',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-14': {
      open: '155.0',
      high: '156.21',
      low: '154.58',
      close: '155.75',
      'adjusted close': '104.803070878361',
      volume: '3226434',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-13': {
      open: '156.06',
      high: '156.09',
      low: '154.3171',
      close: '155.07',
      'adjusted close': '104.345503698924',
      volume: '2533318',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-12': {
      open: '154.26',
      high: '156.52',
      low: '153.95',
      close: '156.16',
      'adjusted close': '105.078956971845',
      volume: '3624852',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-11': {
      open: '155.96',
      high: '155.99',
      low: '154.86',
      close: '155.51',
      'adjusted close': '104.641576579736',
      volume: '3167111',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-10': {
      open: '156.77',
      high: '157.4652',
      low: '156.12',
      close: '156.75',
      'adjusted close': '105.475963789298',
      volume: '4613371',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-07': {
      open: '155.89',
      high: '156.25',
      low: '154.335',
      close: '155.12',
      'adjusted close': '104.379148344471',
      volume: '3794897',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-06': {
      open: '157.13',
      high: '157.54',
      low: '156.18',
      close: '156.32',
      'adjusted close': '105.186619837595',
      volume: '3004487',
      'dividend amount': '1.3000',
      'split coefficient': '1.0',
    },
    '2015-08-05': {
      open: '159.1',
      high: '159.54',
      low: '157.48',
      close: '157.9',
      'adjusted close': '105.373475906333',
      volume: '3463034',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-04': {
      open: '158.4',
      high: '158.82',
      low: '156.87',
      close: '157.6',
      'adjusted close': '105.173272975542',
      volume: '5260575',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-08-03': {
      open: '161.7',
      high: '161.85',
      low: '157.9',
      close: '158.71',
      'adjusted close': '105.914023819469',
      volume: '4615511',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-31': {
      open: '161.4',
      high: '162.06',
      low: '160.63',
      close: '161.99',
      'adjusted close': '108.102909196117',
      volume: '3580226',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-30': {
      open: '160.5',
      high: '161.4',
      low: '159.75',
      close: '160.96',
      'adjusted close': '107.415545800402',
      volume: '1994660',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-29': {
      open: '160.0',
      high: '161.5',
      low: '159.39',
      close: '161.09',
      'adjusted close': '107.502300403744',
      volume: '3378396',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-28': {
      open: '159.91',
      high: '160.19',
      low: '158.5',
      close: '160.05',
      'adjusted close': '106.808263577002',
      volume: '2720890',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-27': {
      open: '159.0',
      high: '160.18',
      low: '158.6',
      close: '159.07',
      'adjusted close': '106.154267336418',
      volume: '3706240',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-24': {
      open: '161.29',
      high: '161.93',
      low: '159.41',
      close: '159.75',
      'adjusted close': '106.608060646211',
      volume: '3777631',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-23': {
      open: '160.27',
      high: '162.75',
      low: '159.83',
      close: '161.73',
      'adjusted close': '107.929399989432',
      volume: '5034246',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-22': {
      open: '162.73',
      high: '163.0695',
      low: '159.7',
      close: '160.35',
      'adjusted close': '107.008466507793',
      volume: '7180385',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-21': {
      open: '164.34',
      high: '166.35',
      low: '162.02',
      close: '163.07',
      'adjusted close': '108.823639746965',
      volume: '13747503',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-20': {
      open: '172.79',
      high: '173.78',
      low: '172.14',
      close: '173.22',
      'adjusted close': '115.597172238727',
      volume: '7335491',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-17': {
      open: '170.33',
      high: '172.52',
      low: '170.1',
      close: '172.51',
      'adjusted close': '115.123358635855',
      volume: '4356391',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-16': {
      open: '169.47',
      high: '171.09',
      low: '169.16',
      close: '171.0',
      'adjusted close': '114.115670550874',
      volume: '3529874',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-15': {
      open: '168.48',
      high: '169.27',
      low: '168.0',
      close: '168.53',
      'adjusted close': '112.467333087361',
      volume: '1998004',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-14': {
      open: '169.43',
      high: '169.54',
      low: '168.24',
      close: '168.61',
      'adjusted close': '112.520720535572',
      volume: '3025263',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-13': {
      open: '167.93',
      high: '169.89',
      low: '167.52',
      close: '169.38',
      'adjusted close': '113.034574724602',
      volume: '4228963',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-10': {
      open: '165.66',
      high: '167.4',
      low: '165.515',
      close: '166.95',
      'adjusted close': '111.412930985195',
      volume: '4538349',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-09': {
      open: '165.34',
      high: '165.51',
      low: '163.7',
      close: '163.85',
      'adjusted close': '109.344167367022',
      volume: '4199708',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-08': {
      open: '164.0',
      high: '164.94',
      low: '162.68',
      close: '163.16',
      'adjusted close': '108.883700626202',
      volume: '2776286',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-07': {
      open: '164.94',
      high: '165.35',
      low: '162.2317',
      close: '165.0',
      'adjusted close': '110.111611935054',
      volume: '3549861',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-06': {
      open: '163.83',
      high: '165.23',
      low: '163.52',
      close: '164.73',
      'adjusted close': '109.931429297342',
      volume: '3079782',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-02': {
      open: '165.05',
      high: '165.32',
      low: '164.42',
      close: '165.09',
      'adjusted close': '110.171672814291',
      volume: '2852696',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-07-01': {
      open: '163.97',
      high: '165.61',
      low: '163.6',
      close: '164.49',
      'adjusted close': '109.771266952709',
      volume: '3214547',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-30': {
      open: '163.99',
      high: '164.0',
      low: '162.12',
      close: '162.66',
      'adjusted close': '108.550029074884',
      volume: '3597288',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-29': {
      open: '163.92',
      high: '165.35',
      low: '162.89',
      close: '162.97',
      'adjusted close': '108.756905436701',
      volume: '3314679',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-26': {
      open: '166.23',
      high: '166.8116',
      low: '165.23',
      close: '165.46',
      'adjusted close': '110.418589762267',
      volume: '8945485',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-25': {
      open: '167.8',
      high: '168.13',
      low: '166.08',
      close: '166.08',
      'adjusted close': '110.832342485901',
      volume: '2408780',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-24': {
      open: '168.71',
      high: '168.84',
      low: '166.8301',
      close: '166.97',
      'adjusted close': '111.426277847248',
      volume: '3548079',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-23': {
      open: '168.38',
      high: '169.97',
      low: '167.573',
      close: '168.62',
      'adjusted close': '112.527393966599',
      volume: '3701637',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-22': {
      open: '167.65',
      high: '168.34',
      low: '167.2',
      close: '167.73',
      'adjusted close': '111.933458605252',
      volume: '2335796',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-19': {
      open: '167.62',
      high: '168.42',
      low: '166.77',
      close: '166.99',
      'adjusted close': '111.439624709301',
      volume: '7072020',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-18': {
      open: '167.05',
      high: '168.72',
      low: '167.05',
      close: '168.25',
      'adjusted close': '112.280477018623',
      volume: '3330864',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-17': {
      open: '167.0',
      high: '167.8476',
      low: '166.1',
      close: '167.17',
      'adjusted close': '111.559746467775',
      volume: '2862993',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-16': {
      open: '166.33',
      high: '167.4',
      low: '165.91',
      close: '166.84',
      'adjusted close': '111.339523243905',
      volume: '3249842',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-15': {
      open: '165.33',
      high: '166.44',
      low: '164.25',
      close: '166.26',
      'adjusted close': '110.952464244376',
      volume: '4245657',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-12': {
      open: '168.23',
      high: '168.3',
      low: '166.69',
      close: '166.99',
      'adjusted close': '111.439624709301',
      volume: '3065085',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-11': {
      open: '169.26',
      high: '170.44',
      low: '168.54',
      close: '168.78',
      'adjusted close': '112.63416886302',
      volume: '3464013',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-10': {
      open: '166.49',
      high: '169.39',
      low: '166.06',
      close: '168.92',
      'adjusted close': '112.72759689739',
      volume: '4680545',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-09': {
      open: '165.34',
      high: '166.02',
      low: '163.37',
      close: '165.68',
      'adjusted close': '110.565405244847',
      volume: '3395901',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-08': {
      open: '167.17',
      high: '167.28',
      low: '165.02',
      close: '165.34',
      'adjusted close': '110.33850858995',
      volume: '3758726',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-05': {
      open: '168.25',
      high: '168.9103',
      low: '167.2',
      close: '167.4',
      'adjusted close': '111.713235381382',
      volume: '3100505',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-04': {
      open: '169.53',
      high: '170.6',
      low: '167.93',
      close: '168.38',
      'adjusted close': '112.367231621966',
      volume: '3079334',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-03': {
      open: '170.5',
      high: '171.5601',
      low: '169.63',
      close: '169.92',
      'adjusted close': '113.394940000026',
      volume: '2131031',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-02': {
      open: '169.66',
      high: '170.45',
      low: '168.43',
      close: '169.65',
      'adjusted close': '113.214757362314',
      volume: '2571762',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-06-01': {
      open: '170.21',
      high: '171.04',
      low: '169.03',
      close: '170.18',
      'adjusted close': '113.568449206712',
      volume: '2985479',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-29': {
      open: '171.35',
      high: '171.35',
      low: '169.65',
      close: '169.65',
      'adjusted close': '113.214757362314',
      volume: '4091431',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-28': {
      open: '171.45',
      high: '171.84',
      low: '170.66',
      close: '171.71',
      'adjusted close': '114.589484153746',
      volume: '1731372',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-27': {
      open: '171.16',
      high: '172.48',
      low: '170.49',
      close: '172.0',
      'adjusted close': '114.783013653511',
      volume: '2764378',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-26': {
      open: '172.11',
      high: '172.12',
      low: '169.13',
      close: '170.13',
      'adjusted close': '113.53508205158',
      volume: '3848738',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-22': {
      open: '173.04',
      high: '173.39',
      low: '172.19',
      close: '172.22',
      'adjusted close': '114.929829136091',
      volume: '2849692',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-21': {
      open: '173.32',
      high: '174.14',
      low: '173.035',
      close: '173.34',
      'adjusted close': '115.677253411044',
      volume: '2295590',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-20': {
      open: '173.33',
      high: '174.44',
      low: '172.46',
      close: '173.76',
      'adjusted close': '115.957537514151',
      volume: '2300693',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-19': {
      open: '172.97',
      high: '173.75',
      low: '171.93',
      close: '173.48',
      'adjusted close': '115.770681445413',
      volume: '2523002',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-18': {
      open: '173.44',
      high: '173.49',
      low: '172.3',
      close: '173.06',
      'adjusted close': '115.490397342305',
      volume: '1970630',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-15': {
      open: '173.91',
      high: '174.41',
      low: '172.6',
      close: '173.26',
      'adjusted close': '115.623865962833',
      volume: '2916115',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-14': {
      open: '173.5',
      high: '174.4',
      low: '173.22',
      close: '174.05',
      'adjusted close': '116.151067013916',
      volume: '2438669',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-13': {
      open: '171.24',
      high: '172.74',
      low: '170.75',
      close: '172.28',
      'adjusted close': '114.969869722249',
      volume: '2457521',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-12': {
      open: '170.55',
      high: '171.4899',
      low: '168.84',
      close: '170.55',
      'adjusted close': '113.815366154687',
      volume: '2961739',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-11': {
      open: '172.65',
      high: '172.99',
      low: '170.86',
      close: '171.12',
      'adjusted close': '114.19575172319',
      volume: '2661030',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-08': {
      open: '172.94',
      high: '173.33',
      low: '172.24',
      close: '172.68',
      'adjusted close': '115.236806963304',
      volume: '3092599',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-07': {
      open: '169.63',
      high: '171.98',
      low: '169.04',
      close: '170.99',
      'adjusted close': '114.108997119848',
      volume: '2472627',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-06': {
      open: '172.9',
      high: '174.05',
      low: '168.86',
      close: '170.05',
      'adjusted close': '113.481694603369',
      volume: '3610900',
      'dividend amount': '1.3000',
      'split coefficient': '1.0',
    },
    '2015-05-05': {
      open: '173.51',
      high: '174.23',
      low: '171.96',
      close: '173.08',
      'adjusted close': '114.627439171002',
      volume: '3593465',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-04': {
      open: '174.47',
      high: '176.3',
      low: '173.7',
      close: '173.97',
      'adjusted close': '115.21686845724',
      volume: '4023850',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-05-01': {
      open: '173.2',
      high: '174.0',
      low: '172.42',
      close: '173.67',
      'adjusted close': '115.018184428171',
      volume: '3312052',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-30': {
      open: '173.77',
      high: '174.28',
      low: '170.3',
      close: '171.29',
      'adjusted close': '113.441957797555',
      volume: '5062613',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-29': {
      open: '173.4',
      high: '175.1303',
      low: '172.8',
      close: '174.4',
      'adjusted close': '115.501648898906',
      volume: '4517190',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-28': {
      open: '170.83',
      high: '174.69',
      low: '170.02',
      close: '173.92',
      'adjusted close': '115.183754452395',
      volume: '5816308',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-27': {
      open: '170.89',
      high: '171.49',
      low: '170.3',
      close: '170.73',
      'adjusted close': '113.071080943293',
      volume: '3166588',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-24': {
      open: '170.23',
      high: '170.85',
      low: '168.74',
      close: '169.78',
      'adjusted close': '112.44191485124',
      volume: '3888439',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-23': {
      open: '165.23',
      high: '171.91',
      low: '165.14',
      close: '170.24',
      'adjusted close': '112.746563695813',
      volume: '8230392',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-22': {
      open: '164.39',
      high: '166.0',
      low: '162.89',
      close: '165.36',
      'adjusted close': '109.514636822954',
      volume: '4024674',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-21': {
      open: '166.07',
      high: '167.38',
      low: '164.05',
      close: '164.26',
      'adjusted close': '108.786128716366',
      volume: '9683592',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-20': {
      open: '162.1',
      high: '166.64',
      low: '161.9',
      close: '166.16',
      'adjusted close': '110.044460900472',
      volume: '9609204',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-17': {
      open: '161.75',
      high: '161.86',
      low: '160.03',
      close: '160.67',
      'adjusted close': '106.408543168505',
      volume: '4308880',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-16': {
      open: '163.31',
      high: '163.98',
      low: '162.54',
      close: '163.13',
      'adjusted close': '108.037752206872',
      volume: '3136947',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-15': {
      open: '162.63',
      high: '164.96',
      low: '162.5',
      close: '164.13',
      'adjusted close': '108.70003230377',
      volume: '3498756',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-14': {
      open: '162.42',
      high: '162.7352',
      low: '160.79',
      close: '162.3',
      'adjusted close': '107.488059726448',
      volume: '2719287',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-13': {
      open: '162.37',
      high: '164.0',
      low: '162.36',
      close: '162.38',
      'adjusted close': '107.541042134199',
      volume: '3868911',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-10': {
      open: '162.34',
      high: '163.33',
      low: '161.25',
      close: '162.86',
      'adjusted close': '107.85893658071',
      volume: '2515603',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-09': {
      open: '161.7',
      high: '162.47',
      low: '160.72',
      close: '162.34',
      'adjusted close': '107.514550930323',
      volume: '2263490',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-08': {
      open: '161.72',
      high: '163.55',
      low: '161.01',
      close: '161.85',
      'adjusted close': '107.190033682844',
      volume: '2524323',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-07': {
      open: '161.67',
      high: '163.84',
      low: '161.62',
      close: '162.07',
      'adjusted close': '107.335735304161',
      volume: '3147966',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-06': {
      open: '159.69',
      high: '162.8',
      low: '158.702',
      close: '162.04',
      'adjusted close': '107.315866901254',
      volume: '3465681',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-02': {
      open: '159.52',
      high: '162.54',
      low: '158.89',
      close: '160.45',
      'adjusted close': '106.262841547187',
      volume: '4671578',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-04-01': {
      open: '160.23',
      high: '160.62',
      low: '158.39',
      close: '159.18',
      'adjusted close': '105.421745824128',
      volume: '3700791',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-31': {
      open: '161.08',
      high: '162.33',
      low: '160.4',
      close: '160.5',
      'adjusted close': '106.295955552032',
      volume: '4255158',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-30': {
      open: '161.31',
      high: '163.6',
      low: '161.25',
      close: '162.67',
      'adjusted close': '107.7331033623',
      volume: '4138018',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-27': {
      open: '160.79',
      high: '160.9',
      low: '159.23',
      close: '160.4',
      'adjusted close': '106.229727542343',
      volume: '3477826',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-26': {
      open: '159.27',
      high: '161.25',
      low: '158.91',
      close: '160.59',
      'adjusted close': '106.355560760753',
      volume: '4396024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-25': {
      open: '163.08',
      high: '163.08',
      low: '159.2',
      close: '159.2',
      'adjusted close': '105.434991426066',
      volume: '5428730',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-24': {
      open: '164.79',
      high: '164.89',
      low: '162.9',
      close: '163.0',
      'adjusted close': '107.951655794276',
      volume: '4336706',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-23': {
      open: '162.77',
      high: '165.35',
      low: '162.73',
      close: '164.63',
      'adjusted close': '109.031172352218',
      volume: '5929927',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-20': {
      open: '160.95',
      high: '163.0',
      low: '160.35',
      close: '162.88',
      'adjusted close': '107.872182182648',
      volume: '9006417',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-19': {
      open: '159.7',
      high: '160.92',
      low: '158.81',
      close: '159.81',
      'adjusted close': '105.838982285173',
      volume: '3878559',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-18': {
      open: '156.85',
      high: '160.7',
      low: '155.8',
      close: '159.81',
      'adjusted close': '105.838982285173',
      volume: '5738466',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-17': {
      open: '156.2',
      high: '157.29',
      low: '155.37',
      close: '156.96',
      'adjusted close': '103.951484009015',
      volume: '3311792',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-16': {
      open: '155.05',
      high: '157.09',
      low: '154.8',
      close: '157.08',
      'adjusted close': '104.030957620643',
      volume: '3749638',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-13': {
      open: '157.46',
      high: '157.52',
      low: '153.4',
      close: '154.28',
      'adjusted close': '102.17657334933',
      volume: '6063970',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-12': {
      open: '156.27',
      high: '158.72',
      low: '156.27',
      close: '157.98',
      'adjusted close': '104.627009707851',
      volume: '4567346',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-11': {
      open: '158.57',
      high: '159.14',
      low: '155.935',
      close: '156.8',
      'adjusted close': '103.845519193512',
      volume: '5709290',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-10': {
      open: '159.6',
      high: '160.23',
      low: '157.81',
      close: '157.81',
      'adjusted close': '104.514422091378',
      volume: '4600968',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-09': {
      open: '158.2',
      high: '161.15',
      low: '158.2',
      close: '160.77',
      'adjusted close': '106.474771178195',
      volume: '5193874',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-06': {
      open: '160.19',
      high: '161.44',
      low: '158.06',
      close: '158.5',
      'adjusted close': '104.971395358237',
      volume: '4540174',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-05': {
      open: '159.86',
      high: '161.5',
      low: '159.703',
      close: '161.18',
      'adjusted close': '106.746306017922',
      volume: '3791660',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-04': {
      open: '160.54',
      high: '160.56',
      low: '158.72',
      close: '159.42',
      'adjusted close': '105.580693047383',
      volume: '3633335',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-03': {
      open: '160.27',
      high: '161.75',
      low: '160.0',
      close: '161.03',
      'adjusted close': '106.646964003388',
      volume: '3234068',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-03-02': {
      open: '161.68',
      high: '161.89',
      low: '159.8101',
      close: '160.48',
      'adjusted close': '106.282709950094',
      volume: '5917812',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-27': {
      open: '161.05',
      high: '162.29',
      low: '160.16',
      close: '161.94',
      'adjusted close': '107.249638891565',
      volume: '4410037',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-26': {
      open: '163.74',
      high: '164.14',
      low: '159.77',
      close: '160.87',
      'adjusted close': '106.540999187884',
      volume: '7110571',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-25': {
      open: '164.94',
      high: '164.96',
      low: '162.58',
      close: '162.81',
      'adjusted close': '107.825822575865',
      volume: '4004559',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-24': {
      open: '162.49',
      high: '164.99',
      low: '161.51',
      close: '164.83',
      'adjusted close': '109.163628371598',
      volume: '4701626',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-23': {
      open: '164.23',
      high: '164.4',
      low: '162.5',
      close: '162.91',
      'adjusted close': '107.892050585555',
      volume: '2714434',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-20': {
      open: '163.64',
      high: '164.34',
      low: '162.09',
      close: '163.65',
      'adjusted close': '108.382137857259',
      volume: '3352781',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-19': {
      open: '162.05',
      high: '164.49',
      low: '161.44',
      close: '163.89',
      'adjusted close': '108.541085080514',
      volume: '3504269',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-18': {
      open: '161.86',
      high: '162.47',
      low: '160.64',
      close: '162.19',
      'adjusted close': '107.415208915789',
      volume: '4358252',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-17': {
      open: '159.75',
      high: '161.41',
      low: '159.73',
      close: '160.96',
      'adjusted close': '106.600604396605',
      volume: '2893590',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-13': {
      open: '158.78',
      high: '160.8',
      low: '158.64',
      close: '160.4',
      'adjusted close': '106.229727542343',
      volume: '3706919',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-12': {
      open: '158.72',
      high: '159.5',
      low: '158.09',
      close: '158.52',
      'adjusted close': '104.984640960175',
      volume: '3333063',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-11': {
      open: '157.76',
      high: '159.09',
      low: '157.17',
      close: '158.2',
      'adjusted close': '104.772711329168',
      volume: '3626658',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-10': {
      open: '156.74',
      high: '158.56',
      low: '155.08',
      close: '158.56',
      'adjusted close': '105.011132164051',
      volume: '4440614',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-09': {
      open: '156.0',
      high: '157.5',
      low: '155.4',
      close: '155.75',
      'adjusted close': '103.15012509177',
      volume: '3057502',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-06': {
      open: '157.34',
      high: '158.08',
      low: '156.23',
      close: '156.72',
      'adjusted close': '103.79253678576',
      volume: '3256440',
      'dividend amount': '1.1000',
      'split coefficient': '1.0',
    },
    '2015-02-05': {
      open: '157.29',
      high: '158.59',
      low: '157.1501',
      close: '157.91',
      'adjusted close': '103.851726548216',
      volume: '5253628',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-04': {
      open: '157.21',
      high: '158.71',
      low: '156.7',
      close: '156.96',
      'adjusted close': '103.226945722297',
      volume: '3678339',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-03': {
      open: '154.75',
      high: '158.6',
      low: '154.75',
      close: '158.47',
      'adjusted close': '104.220018403494',
      volume: '5539406',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-02-02': {
      open: '154.0',
      high: '154.66',
      low: '151.51',
      close: '154.66',
      'adjusted close': '101.714318459547',
      volume: '4712017',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-30': {
      open: '153.91',
      high: '155.24',
      low: '153.04',
      close: '153.31',
      'adjusted close': '100.826472022715',
      volume: '6563447',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-29': {
      open: '151.38',
      high: '155.58',
      low: '149.52',
      close: '155.48',
      'adjusted close': '102.253602961919',
      volume: '8320622',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-28': {
      open: '154.0',
      high: '154.53',
      low: '151.55',
      close: '151.55',
      'adjusted close': '99.6689833346974',
      volume: '4495633',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-27': {
      open: '154.94',
      high: '155.09',
      low: '152.59',
      close: '153.67',
      'adjusted close': '101.063231072537',
      volume: '5659568',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-26': {
      open: '158.26',
      high: '159.46',
      low: '155.77',
      close: '156.36',
      'adjusted close': '102.832347305927',
      volume: '7888037',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-23': {
      open: '155.03',
      high: '157.6',
      low: '154.89',
      close: '155.87',
      'adjusted close': '102.510091932559',
      volume: '4834447',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-22': {
      open: '151.94',
      high: '155.72',
      low: '151.76',
      close: '155.39',
      'adjusted close': '102.194413199463',
      volume: '6119523',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-21': {
      open: '153.03',
      high: '154.5',
      low: '151.07',
      close: '152.09',
      'adjusted close': '100.02412190943',
      volume: '11896012',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-20': {
      open: '156.7',
      high: '157.33',
      low: '154.03',
      close: '156.95',
      'adjusted close': '103.220369082024',
      volume: '8380815',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-16': {
      open: '153.82',
      high: '157.63',
      low: '153.82',
      close: '157.14',
      'adjusted close': '103.345325247208',
      volume: '5756041',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-15': {
      open: '156.69',
      high: '156.97',
      low: '154.16',
      close: '154.57',
      'adjusted close': '101.655128697091',
      volume: '4251698',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-14': {
      open: '154.86',
      high: '156.49',
      low: '153.74',
      close: '155.8',
      'adjusted close': '102.464055450649',
      volume: '4689740',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-13': {
      open: '157.26',
      high: '159.97',
      low: '155.68',
      close: '156.81',
      'adjusted close': '103.128296118205',
      volume: '4386989',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-12': {
      open: '159.0',
      high: '159.25',
      low: '155.76',
      close: '156.44',
      'adjusted close': '102.88496042811',
      volume: '4187588',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-09': {
      open: '158.42',
      high: '160.34',
      low: '157.25',
      close: '159.11',
      'adjusted close': '104.640923380955',
      volume: '4472701',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-08': {
      open: '156.24',
      high: '159.044',
      low: '155.55',
      close: '158.42',
      'adjusted close': '104.18713520213',
      volume: '4240585',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-07': {
      open: '157.2',
      high: '157.2',
      low: '154.03',
      close: '155.05',
      'adjusted close': '101.970807430187',
      volume: '4701015',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-06': {
      open: '159.67',
      high: '159.96',
      low: '155.17',
      close: '156.07',
      'adjusted close': '102.641624738015',
      volume: '6145670',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-05': {
      open: '161.27',
      high: '161.27',
      low: '159.19',
      close: '159.51',
      'adjusted close': '104.903988991868',
      volume: '4880389',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2015-01-02': {
      open: '161.31',
      high: '163.31',
      low: '161.0',
      close: '162.06',
      'adjusted close': '106.581032261439',
      volume: '5525341',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-31': {
      open: '160.41',
      high: '161.5',
      low: '160.38',
      close: '160.44',
      'adjusted close': '105.515616537241',
      volume: '4011909',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-30': {
      open: '160.02',
      high: '160.82',
      low: '159.79',
      close: '160.05',
      'adjusted close': '105.259127566601',
      volume: '2829904',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-29': {
      open: '162.0',
      high: '162.34',
      low: '159.45',
      close: '160.51',
      'adjusted close': '105.561653019151',
      volume: '3331833',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-26': {
      open: '162.27',
      high: '163.09',
      low: '162.01',
      close: '162.34',
      'adjusted close': '106.765178189078',
      volume: '1912310',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-24': {
      open: '162.88',
      high: '162.99',
      low: '161.61',
      close: '161.82',
      'adjusted close': '106.423192894891',
      volume: '1869977',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-23': {
      open: '162.23',
      high: '162.9',
      low: '161.61',
      close: '162.24',
      'adjusted close': '106.69941178635',
      volume: '4044120',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-22': {
      open: '158.33',
      high: '161.91',
      low: '158.33',
      close: '161.44',
      'adjusted close': '106.173280564524',
      volume: '4682509',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-19': {
      open: '157.49',
      high: '160.41',
      low: '157.49',
      close: '158.51',
      'adjusted close': '104.246324964585',
      volume: '8864850',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-18': {
      open: '153.58',
      high: '157.68',
      low: '153.3',
      close: '157.68',
      'adjusted close': '103.700463821941',
      volume: '7301971',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-17': {
      open: '151.52',
      high: '153.308',
      low: '151.11',
      close: '151.93',
      'adjusted close': '99.9188956650648',
      volume: '5139465',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-16': {
      open: '152.03',
      high: '154.89',
      low: '150.5',
      close: '151.41',
      'adjusted close': '99.5769103708778',
      volume: '6782173',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-15': {
      open: '155.93',
      high: '156.739',
      low: '152.84',
      close: '153.06',
      'adjusted close': '100.662056015894',
      volume: '6500311',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-12': {
      open: '160.25',
      high: '160.5',
      low: '155.33',
      close: '155.38',
      'adjusted close': '102.18783655919',
      volume: '8611249',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-11': {
      open: '160.82',
      high: '162.93',
      low: '160.52',
      close: '161.07',
      'adjusted close': '105.929944874429',
      volume: '3987625',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-10': {
      open: '163.01',
      high: '163.33',
      low: '160.03',
      close: '160.51',
      'adjusted close': '105.561653019151',
      volume: '4081714',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-09': {
      open: '160.84',
      high: '163.02',
      low: '160.8',
      close: '162.99',
      'adjusted close': '107.192659806812',
      volume: '3865785',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-08': {
      open: '163.29',
      high: '163.29',
      low: '161.54',
      close: '161.86',
      'adjusted close': '106.449499455982',
      volume: '2851383',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-05': {
      open: '163.61',
      high: '164.5',
      low: '162.91',
      close: '163.27',
      'adjusted close': '107.376805734451',
      volume: '3013500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-04': {
      open: '164.01',
      high: '164.5',
      low: '163.01',
      close: '164.05',
      'adjusted close': '107.889783675731',
      volume: '3862816',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-03': {
      open: '162.47',
      high: '164.52',
      low: '162.0',
      close: '164.52',
      'adjusted close': '108.198885768554',
      volume: '6434994',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-02': {
      open: '162.47',
      high: '162.73',
      low: '161.64',
      close: '162.67',
      'adjusted close': '106.982207318081',
      volume: '3465747',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-12-01': {
      open: '161.64',
      high: '163.315',
      low: '161.35',
      close: '161.54',
      'adjusted close': '106.239046967252',
      volume: '4168384',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-28': {
      open: '162.75',
      high: '163.37',
      low: '161.44',
      close: '162.17',
      'adjusted close': '106.65337530444',
      volume: '2405512',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-26': {
      open: '161.93',
      high: '162.1',
      low: '161.01',
      close: '161.95',
      'adjusted close': '106.508689218438',
      volume: '3967223',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-25': {
      open: '162.65',
      high: '163.5',
      low: '161.56',
      close: '161.76',
      'adjusted close': '106.383733053254',
      volume: '4062378',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-24': {
      open: '161.54',
      high: '163.86',
      low: '161.06',
      close: '162.15',
      'adjusted close': '106.640222023894',
      volume: '6618779',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-21': {
      open: '161.83',
      high: '161.95',
      low: '160.75',
      close: '160.92',
      'adjusted close': '105.831295270337',
      volume: '4076868',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-20': {
      open: '160.95',
      high: '161.5',
      low: '159.8',
      close: '160.64',
      'adjusted close': '105.647149342697',
      volume: '4182746',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-19': {
      open: '162.05',
      high: '162.1',
      low: '160.96',
      close: '161.43',
      'adjusted close': '106.166703924251',
      volume: '3802175',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-18': {
      open: '164.73',
      high: '164.75',
      low: '161.89',
      close: '161.89',
      'adjusted close': '106.469229376801',
      volume: '5412495',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-17': {
      open: '164.16',
      high: '164.97',
      low: '163.72',
      close: '164.16',
      'adjusted close': '107.962126718733',
      volume: '4798933',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-14': {
      open: '162.1',
      high: '164.49',
      low: '161.69',
      close: '164.16',
      'adjusted close': '107.962126718733',
      volume: '4976697',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-13': {
      open: '162.0',
      high: '162.8',
      low: '161.8',
      close: '162.79',
      'adjusted close': '107.061127001355',
      volume: '3239726',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-12': {
      open: '162.28',
      high: '163.0',
      low: '161.76',
      close: '161.92',
      'adjusted close': '106.488959297619',
      volume: '3377798',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-11': {
      open: '163.7',
      high: '163.9',
      low: '162.6',
      close: '163.3',
      'adjusted close': '107.396535655269',
      volume: '3535694',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-10': {
      open: '161.9',
      high: '164.47',
      low: '161.61',
      close: '163.49',
      'adjusted close': '107.521491820453',
      volume: '4958236',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-07': {
      open: '161.42',
      high: '162.2069',
      low: '160.85',
      close: '162.07',
      'adjusted close': '106.587608901712',
      volume: '3494803',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-06': {
      open: '161.28',
      high: '161.53',
      low: '160.05',
      close: '161.46',
      'adjusted close': '106.186433845069',
      volume: '4067587',
      'dividend amount': '1.1000',
      'split coefficient': '1.0',
    },
    '2014-11-05': {
      open: '163.13',
      high: '163.54',
      low: '161.56',
      close: '161.82',
      'adjusted close': '105.70305563982',
      volume: '4103003',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-04': {
      open: '164.34',
      high: '164.36',
      low: '162.24',
      close: '162.65',
      'adjusted close': '106.245223086248',
      volume: '4246854',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-11-03': {
      open: '164.25',
      high: '164.54',
      low: '163.375',
      close: '164.36',
      'adjusted close': '107.362218668649',
      volume: '4691074',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-31': {
      open: '165.48',
      high: '165.59',
      low: '163.6165',
      close: '164.4',
      'adjusted close': '107.388347220284',
      volume: '5818375',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-30': {
      open: '163.5',
      high: '164.62',
      low: '163.02',
      close: '164.35',
      'adjusted close': '107.35568653074',
      volume: '3895979',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-29': {
      open: '164.33',
      high: '164.615',
      low: '162.76',
      close: '163.46',
      'adjusted close': '106.774326256859',
      volume: '4741689',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-28': {
      open: '162.0',
      high: '163.6',
      low: '161.8',
      close: '163.6',
      'adjusted close': '106.865776187582',
      volume: '7896863',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-27': {
      open: '162.0',
      high: '162.91',
      low: '161.81',
      close: '161.87',
      'adjusted close': '105.735716329364',
      volume: '4989213',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-24': {
      open: '162.08',
      high: '162.44',
      low: '161.45',
      close: '162.08',
      'adjusted close': '105.872891225448',
      volume: '6652321',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-23': {
      open: '162.12',
      high: '162.83',
      low: '161.54',
      close: '162.18',
      'adjusted close': '105.938212604536',
      volume: '7599510',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-22': {
      open: '162.41',
      high: '165.41',
      low: '161.1',
      close: '161.79',
      'adjusted close': '105.683459226093',
      volume: '11088493',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-21': {
      open: '166.4',
      high: '166.68',
      low: '161.68',
      close: '163.23',
      'adjusted close': '106.624087084957',
      volume: '20952120',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-20': {
      open: '166.845',
      high: '170.331',
      low: '166.69',
      close: '169.1',
      'adjusted close': '110.458452037409',
      volume: '23408902',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-17': {
      open: '181.24',
      high: '182.84',
      low: '180.22',
      close: '182.05',
      'adjusted close': '118.917570629274',
      volume: '4354442',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-16': {
      open: '179.8',
      high: '181.48',
      low: '178.69',
      close: '179.84',
      'adjusted close': '117.473968151435',
      volume: '5578572',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-15': {
      open: '182.46',
      high: '183.79',
      low: '178.7488',
      close: '181.75',
      'adjusted close': '118.721606492011',
      volume: '6897656',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-14': {
      open: '184.89',
      high: '185.72',
      low: '183.59',
      close: '183.8',
      'adjusted close': '120.06069476331',
      volume: '3924974',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-13': {
      open: '185.49',
      high: '186.65',
      low: '183.42',
      close: '183.52',
      'adjusted close': '119.877794901865',
      volume: '3599924',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-10': {
      open: '185.86',
      high: '187.74',
      low: '185.1',
      close: '185.93',
      'adjusted close': '121.45204013788',
      volume: '5090177',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-09': {
      open: '189.12',
      high: '189.5',
      low: '186.09',
      close: '186.42',
      'adjusted close': '121.77211489541',
      volume: '2625509',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-08': {
      open: '185.97',
      high: '189.6',
      low: '185.61',
      close: '189.36',
      'adjusted close': '123.69256344059',
      volume: '2985329',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-07': {
      open: '187.77',
      high: '188.12',
      low: '185.54',
      close: '185.71',
      'adjusted close': '121.308333103887',
      volume: '2994065',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-06': {
      open: '189.69',
      high: '190.89',
      low: '188.71',
      close: '189.04',
      'adjusted close': '123.483535027509',
      volume: '2100191',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-03': {
      open: '188.11',
      high: '189.37',
      low: '187.56',
      close: '188.67',
      'adjusted close': '123.241845924884',
      volume: '3071521',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-02': {
      open: '187.66',
      high: '187.78',
      low: '186.24',
      close: '186.91',
      'adjusted close': '122.09218965294',
      volume: '2283619',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-10-01': {
      open: '189.91',
      high: '190.4',
      low: '186.79',
      close: '187.17',
      'adjusted close': '122.262025238568',
      volume: '3723166',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-30': {
      open: '189.64',
      high: '190.85',
      low: '189.15',
      close: '189.83',
      'adjusted close': '123.999573922302',
      volume: '2929335',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-29': {
      open: '188.51',
      high: '189.96',
      low: '188.12',
      close: '189.64',
      'adjusted close': '123.875463302036',
      volume: '2344723',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-26': {
      open: '188.93',
      high: '190.33',
      low: '188.61',
      close: '190.06',
      'adjusted close': '124.149813094204',
      volume: '2494024',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-25': {
      open: '192.05',
      high: '192.5',
      low: '188.97',
      close: '189.01',
      'adjusted close': '123.463938613783',
      volume: '4114751',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-24': {
      open: '191.0',
      high: '192.45',
      low: '189.88',
      close: '192.31',
      'adjusted close': '125.619544123679',
      volume: '3076722',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-23': {
      open: '192.75',
      high: '193.07',
      low: '191.52',
      close: '191.62',
      'adjusted close': '125.168826607973',
      volume: '3257449',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-22': {
      open: '193.72',
      high: '194.14',
      low: '192.63',
      close: '193.11',
      'adjusted close': '126.142115156381',
      volume: '3266955',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-19': {
      open: '194.54',
      high: '195.0',
      low: '193.19',
      close: '194.0',
      'adjusted close': '126.723475430262',
      volume: '8852000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-18': {
      open: '192.79',
      high: '194.13',
      low: '192.71',
      close: '193.75',
      'adjusted close': '126.560171982543',
      volume: '2963300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-17': {
      open: '193.22',
      high: '193.64',
      low: '192.3',
      close: '192.8',
      'adjusted close': '125.939618881209',
      volume: '3210800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-16': {
      open: '191.25',
      high: '193.57',
      low: '190.82',
      close: '192.96',
      'adjusted close': '126.04413308775',
      volume: '2561500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-15': {
      open: '191.42',
      high: '192.49',
      low: '190.58',
      close: '191.81',
      'adjusted close': '125.29293722824',
      volume: '2456400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-12': {
      open: '191.47',
      high: '191.6',
      low: '190.57',
      close: '191.28',
      'adjusted close': '124.946733919075',
      volume: '2901000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-11': {
      open: '191.08',
      high: '192.78',
      low: '190.12',
      close: '191.72',
      'adjusted close': '125.234147987061',
      volume: '2298500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-10': {
      open: '190.12',
      high: '192.15',
      low: '190.1',
      close: '191.54',
      'adjusted close': '125.116569504703',
      volume: '2764000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-09': {
      open: '190.34',
      high: '190.74',
      low: '189.78',
      close: '189.99',
      'adjusted close': '124.104088128843',
      volume: '2390400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-08': {
      open: '190.75',
      high: '191.2',
      low: '189.51',
      close: '190.14',
      'adjusted close': '124.202070197475',
      volume: '2524200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-05': {
      open: '190.53',
      high: '191.75',
      low: '190.09',
      close: '191.2',
      'adjusted close': '124.894476815805',
      volume: '2260200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-04': {
      open: '191.69',
      high: '192.69',
      low: '190.06',
      close: '190.68',
      'adjusted close': '124.554805644548',
      volume: '2865000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-03': {
      open: '192.39',
      high: '192.95',
      low: '191.86',
      close: '191.95',
      'adjusted close': '125.384387158963',
      volume: '1822800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-09-02': {
      open: '192.68',
      high: '192.97',
      low: '190.93',
      close: '191.56',
      'adjusted close': '125.129633780521',
      volume: '2679700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-29': {
      open: '192.28',
      high: '192.75',
      low: '191.14',
      close: '192.3',
      'adjusted close': '125.61301198577',
      volume: '2909400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-28': {
      open: '191.47',
      high: '192.2986',
      low: '190.66',
      close: '192.0',
      'adjusted close': '125.417047848507',
      volume: '1503600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-27': {
      open: '193.03',
      high: '194.133',
      low: '191.56',
      close: '192.25',
      'adjusted close': '125.580351296226',
      volume: '2190200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-26': {
      open: '191.02',
      high: '193.53',
      low: '191.02',
      close: '192.99',
      'adjusted close': '126.063729501476',
      volume: '3160900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-25': {
      open: '191.39',
      high: '191.81',
      low: '190.71',
      close: '191.16',
      'adjusted close': '124.86834826417',
      volume: '1723600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-22': {
      open: '190.77',
      high: '191.88',
      low: '190.29',
      close: '190.41',
      'adjusted close': '124.378437921012',
      volume: '1940300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-21': {
      open: '190.9',
      high: '192.07',
      low: '190.5',
      close: '191.23',
      'adjusted close': '124.914073229531',
      volume: '2496500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-20': {
      open: '189.86',
      high: '190.39',
      low: '189.25',
      close: '190.1',
      'adjusted close': '124.175941645839',
      volume: '2177600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-19': {
      open: '190.04',
      high: '190.25',
      low: '189.36',
      close: '190.07',
      'adjusted close': '124.156345232113',
      volume: '2040100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-18': {
      open: '188.1',
      high: '189.81',
      low: '187.76',
      close: '189.36',
      'adjusted close': '123.69256344059',
      volume: '2420300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-15': {
      open: '187.85',
      high: '188.88',
      low: '186.79',
      close: '187.38',
      'adjusted close': '122.399200134652',
      volume: '2814800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-14': {
      open: '187.57',
      high: '188.17',
      low: '186.93',
      close: '187.88',
      'adjusted close': '122.725807030091',
      volume: '1929500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-13': {
      open: '188.0',
      high: '188.48',
      low: '187.37',
      close: '187.95',
      'adjusted close': '122.771531995453',
      volume: '1797000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-12': {
      open: '187.45',
      high: '188.69',
      low: '186.81',
      close: '187.34',
      'adjusted close': '122.373071583017',
      volume: '1858600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-11': {
      open: '187.81',
      high: '188.36',
      low: '186.28',
      close: '187.47',
      'adjusted close': '122.457989375831',
      volume: '2527200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-08': {
      open: '184.4',
      high: '186.67',
      low: '183.58',
      close: '186.63',
      'adjusted close': '121.909289791494',
      volume: '2781500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-07': {
      open: '186.64',
      high: '186.68',
      low: '183.58',
      close: '184.3',
      'adjusted close': '120.387301658749',
      volume: '2708600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-06': {
      open: '185.36',
      high: '186.88',
      low: '184.44',
      close: '185.97',
      'adjusted close': '121.478168689515',
      volume: '3847000',
      'dividend amount': '1.1000',
      'split coefficient': '1.0',
    },
    '2014-08-05': {
      open: '188.75',
      high: '189.196',
      low: '186.44',
      close: '187.1',
      'adjusted close': '121.497649873353',
      volume: '3307900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-04': {
      open: '189.35',
      high: '189.95',
      low: '188.6',
      close: '189.64',
      'adjusted close': '123.147056771687',
      volume: '2125900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-08-01': {
      open: '190.5',
      high: '191.5',
      low: '188.86',
      close: '189.15',
      'adjusted close': '122.828864102324',
      volume: '5181100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-31': {
      open: '192.84',
      high: '194.29',
      low: '191.54',
      close: '191.67',
      'adjusted close': '124.465283544766',
      volume: '4207500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-30': {
      open: '195.2',
      high: '195.99',
      low: '192.9',
      close: '194.0',
      'adjusted close': '125.97832215623',
      volume: '3943800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-29': {
      open: '195.3',
      high: '195.89',
      low: '194.54',
      close: '194.57',
      'adjusted close': '126.348464649163',
      volume: '3264100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-28': {
      open: '194.3',
      high: '196.4',
      low: '193.65',
      close: '195.78',
      'adjusted close': '127.134205730653',
      volume: '3242400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-25': {
      open: '195.3',
      high: '195.9',
      low: '193.7901',
      close: '194.4',
      'adjusted close': '126.238071274077',
      volume: '3376400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-24': {
      open: '193.95',
      high: '195.62',
      low: '193.75',
      close: '195.24',
      'adjusted close': '126.783544421558',
      volume: '3612700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-23': {
      open: '194.11',
      high: '194.9',
      low: '193.57',
      close: '193.63',
      'adjusted close': '125.73805422222',
      volume: '3584200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-22': {
      open: '191.59',
      high: '194.72',
      low: '191.56',
      close: '194.09',
      'adjusted close': '126.036765707745',
      volume: '4853000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-21': {
      open: '191.3',
      high: '191.7',
      low: '189.25',
      close: '190.85',
      'adjusted close': '123.932797853177',
      volume: '4154400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-18': {
      open: '191.96',
      high: '193.44',
      low: '190.0',
      close: '192.5',
      'adjusted close': '125.0042629643',
      volume: '8166400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-17': {
      open: '192.36',
      high: '195.95',
      low: '192.0',
      close: '192.49',
      'adjusted close': '124.997769236354',
      volume: '8872300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-16': {
      open: '192.24',
      high: '193.36',
      low: '190.76',
      close: '192.36',
      'adjusted close': '124.913350773053',
      volume: '7812600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-15': {
      open: '189.54',
      high: '190.08',
      low: '188.21',
      close: '188.49',
      'adjusted close': '122.400278057875',
      volume: '4864700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-14': {
      open: '188.55',
      high: '190.44',
      low: '188.53',
      close: '189.86',
      'adjusted close': '123.289918786504',
      volume: '4501100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-11': {
      open: '187.73',
      high: '188.35',
      low: '186.705',
      close: '188.0',
      'adjusted close': '122.082085388511',
      volume: '2402500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-10': {
      open: '186.44',
      high: '188.05',
      low: '186.21',
      close: '187.7',
      'adjusted close': '121.887273550125',
      volume: '3177800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-09': {
      open: '187.68',
      high: '188.9',
      low: '186.89',
      close: '188.42',
      'adjusted close': '122.354821962251',
      volume: '3309600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-08': {
      open: '187.65',
      high: '188.08',
      low: '186.37',
      close: '187.22',
      'adjusted close': '121.575574608708',
      volume: '3135100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-07': {
      open: '187.61',
      high: '188.27',
      low: '187.44',
      close: '188.04',
      'adjusted close': '122.108060300296',
      volume: '2958700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-03': {
      open: '188.39',
      high: '188.806',
      low: '187.35',
      close: '188.53',
      'adjusted close': '122.42625296966',
      volume: '2422400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-02': {
      open: '186.34',
      high: '188.99',
      low: '186.17',
      close: '188.39',
      'adjusted close': '122.335340778413',
      volume: '5093000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-07-01': {
      open: '181.7',
      high: '187.27',
      low: '181.7',
      close: '186.35',
      'adjusted close': '121.010620277389',
      volume: '6643100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-30': {
      open: '181.33',
      high: '181.93',
      low: '180.26',
      close: '181.27',
      'adjusted close': '117.71180648072',
      volume: '4223800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-27': {
      open: '179.77',
      high: '182.46',
      low: '179.66',
      close: '181.71',
      'adjusted close': '117.997530510353',
      volume: '4575500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-26': {
      open: '180.87',
      high: '181.37',
      low: '179.27',
      close: '180.37',
      'adjusted close': '117.127370965563',
      volume: '3258500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-25': {
      open: '180.25',
      high: '180.97',
      low: '180.06',
      close: '180.72',
      'adjusted close': '117.354651443679',
      volume: '2762800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-24': {
      open: '181.5',
      high: '183.0',
      low: '180.65',
      close: '180.88',
      'adjusted close': '117.458551090819',
      volume: '3875400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-23': {
      open: '181.92',
      high: '182.25',
      low: '181.0',
      close: '182.14',
      'adjusted close': '118.276760812039',
      volume: '3231700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-20': {
      open: '182.59',
      high: '182.67',
      low: '181.4',
      close: '181.55',
      'adjusted close': '117.893630863214',
      volume: '10686800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-19': {
      open: '184.12',
      high: '184.47',
      low: '182.356',
      close: '182.82',
      'adjusted close': '118.718334312381',
      volume: '3551100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-18': {
      open: '182.04',
      high: '183.61',
      low: '181.79',
      close: '183.6',
      'adjusted close': '119.224845092184',
      volume: '3931800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-17': {
      open: '181.9',
      high: '182.81',
      low: '181.56',
      close: '182.26',
      'adjusted close': '118.354685547394',
      volume: '2460900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-16': {
      open: '182.4',
      high: '182.71',
      low: '181.235',
      close: '182.35',
      'adjusted close': '118.41312909891',
      volume: '3556200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-13': {
      open: '182.0',
      high: '183.0',
      low: '181.52',
      close: '182.56',
      'adjusted close': '118.54949738578',
      volume: '2773600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-12': {
      open: '182.48',
      high: '182.55',
      low: '180.91',
      close: '181.22',
      'adjusted close': '117.679337840989',
      volume: '4425300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-11': {
      open: '183.61',
      high: '184.2',
      low: '182.01',
      close: '182.25',
      'adjusted close': '118.348191819448',
      volume: '4061700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-10': {
      open: '186.2',
      high: '186.2199',
      low: '183.82',
      close: '184.29',
      'adjusted close': '119.672912320472',
      volume: '4154900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-09': {
      open: '186.22',
      high: '187.64',
      low: '185.96',
      close: '186.22',
      'adjusted close': '120.926201814088',
      volume: '2728400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-06': {
      open: '186.47',
      high: '187.65',
      low: '185.9',
      close: '186.37',
      'adjusted close': '121.023607733281',
      volume: '3296700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-05': {
      open: '184.66',
      high: '186.09',
      low: '183.92',
      close: '185.98',
      'adjusted close': '120.770352343379',
      volume: '2852200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-04': {
      open: '184.71',
      high: '185.45',
      low: '184.2',
      close: '184.51',
      'adjusted close': '119.815774335288',
      volume: '2376800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-03': {
      open: '185.55',
      high: '185.76',
      low: '184.12',
      close: '184.37',
      'adjusted close': '119.724862144041',
      volume: '2517100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-06-02': {
      open: '184.76',
      high: '186.2801',
      low: '184.67',
      close: '185.69',
      'adjusted close': '120.58203423294',
      volume: '3200500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-30': {
      open: '183.38',
      high: '184.57',
      low: '182.49',
      close: '184.36',
      'adjusted close': '119.718368416095',
      volume: '4620500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-29': {
      open: '183.64',
      high: '183.78',
      low: '182.33',
      close: '183.76',
      'adjusted close': '119.328744739323',
      volume: '2759900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-28': {
      open: '184.48',
      high: '184.88',
      low: '182.94',
      close: '183.08',
      'adjusted close': '118.887171238982',
      volume: '3721600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-27': {
      open: '184.8',
      high: '185.65',
      low: '183.93',
      close: '184.78',
      'adjusted close': '119.991104989836',
      volume: '4793800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-23': {
      open: '185.84',
      high: '186.14',
      low: '185.3101',
      close: '185.94',
      'adjusted close': '120.744377431594',
      volume: '2562900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-22': {
      open: '186.45',
      high: '186.45',
      low: '185.03',
      close: '185.68',
      'adjusted close': '120.575540504993',
      volume: '2218000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-21': {
      open: '185.85',
      high: '187.16',
      low: '184.95',
      close: '186.39',
      'adjusted close': '121.036595189173',
      volume: '2988000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-20': {
      open: '186.25',
      high: '186.7',
      low: '184.7',
      close: '184.89',
      'adjusted close': '120.062535997244',
      volume: '3026500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-19': {
      open: '186.61',
      high: '187.13',
      low: '186.04',
      close: '187.0',
      'adjusted close': '121.432712593891',
      volume: '3270700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-16': {
      open: '186.27',
      high: '187.41',
      low: '185.93',
      close: '187.06',
      'adjusted close': '121.471674961569',
      volume: '3949500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-15': {
      open: '189.07',
      high: '189.08',
      low: '186.18',
      close: '186.46',
      'adjusted close': '121.082051284797',
      volume: '4202200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-14': {
      open: '191.91',
      high: '191.99',
      low: '188.17',
      close: '188.72',
      'adjusted close': '122.549633800637',
      volume: '5251500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-13': {
      open: '192.79',
      high: '193.03',
      low: '191.76',
      close: '192.19',
      'adjusted close': '124.802957397968',
      volume: '2226600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-12': {
      open: '191.12',
      high: '193.02',
      low: '190.94',
      close: '192.57',
      'adjusted close': '125.049719059923',
      volume: '3777400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-09': {
      open: '188.98',
      high: '190.5854',
      low: '188.37',
      close: '190.08',
      'adjusted close': '123.43278080132',
      volume: '2261600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-08': {
      open: '189.16',
      high: '190.57',
      low: '188.23',
      close: '188.91',
      'adjusted close': '122.673014631615',
      volume: '2431900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-07': {
      open: '189.34',
      high: '190.3',
      low: '186.93',
      close: '189.3',
      'adjusted close': '122.926270021517',
      volume: '3821000',
      'dividend amount': '1.1000',
      'split coefficient': '1.0',
    },
    '2014-05-06': {
      open: '190.93',
      high: '190.95',
      low: '189.25',
      close: '190.03',
      'adjusted close': '122.687390190067',
      volume: '3188700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-05': {
      open: '191.05',
      high: '191.49',
      low: '189.8',
      close: '191.26',
      'adjusted close': '123.481504224345',
      volume: '2222100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-02': {
      open: '193.75',
      high: '193.84',
      low: '191.25',
      close: '191.44',
      'adjusted close': '123.597716034239',
      volume: '3049900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-05-01': {
      open: '196.31',
      high: '196.74',
      low: '193.15',
      close: '193.53',
      'adjusted close': '124.947064271345',
      volume: '3674000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-30': {
      open: '194.38',
      high: '196.86',
      low: '194.35',
      close: '196.47',
      'adjusted close': '126.845190499619',
      volume: '4206800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-29': {
      open: '193.59',
      high: '195.94',
      low: '193.41',
      close: '195.11',
      'adjusted close': '125.967145713751',
      volume: '4626300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-28': {
      open: '191.14',
      high: '193.99',
      low: '190.58',
      close: '193.14',
      'adjusted close': '124.695272016574',
      volume: '5638300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-25': {
      open: '190.54',
      high: '190.67',
      low: '189.11',
      close: '189.63',
      'adjusted close': '122.429141723636',
      volume: '3871000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-24': {
      open: '192.22',
      high: '192.22',
      low: '190.03',
      close: '190.22',
      'adjusted close': '122.810058211622',
      volume: '4407100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-23': {
      open: '191.79',
      high: '192.41',
      low: '190.11',
      close: '191.73',
      'adjusted close': '123.784946172402',
      volume: '4736400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-22': {
      open: '192.01',
      high: '193.0',
      low: '191.2',
      close: '192.15',
      'adjusted close': '124.056107062156',
      volume: '3878600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-21': {
      open: '189.8',
      high: '192.805',
      low: '189.74',
      close: '192.27',
      'adjusted close': '124.133581602085',
      volume: '5419700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-17': {
      open: '187.25',
      high: '190.7',
      low: '187.01',
      close: '190.01',
      'adjusted close': '122.674477766746',
      volume: '11255500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-16': {
      open: '198.05',
      high: '198.71',
      low: '195.0',
      close: '196.4',
      'adjusted close': '126.799997017993',
      volume: '8527300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-15': {
      open: '195.98',
      high: '197.41',
      low: '195.42',
      close: '197.02',
      'adjusted close': '127.200282140962',
      volume: '5351300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-14': {
      open: '196.24',
      high: '198.24',
      low: '195.88',
      close: '197.77',
      'adjusted close': '127.684498015522',
      volume: '5431100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-11': {
      open: '195.04',
      high: '197.0',
      low: '194.27',
      close: '195.19',
      'adjusted close': '126.018795407037',
      volume: '4835000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-10': {
      open: '196.06',
      high: '199.21',
      low: '195.66',
      close: '195.68',
      'adjusted close': '126.335149778416',
      volume: '8417900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-09': {
      open: '193.88',
      high: '197.0',
      low: '193.6',
      close: '196.64',
      'adjusted close': '126.954946097852',
      volume: '5480100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-08': {
      open: '193.89',
      high: '194.1675',
      low: '192.6898',
      close: '193.29',
      'adjusted close': '124.792115191486',
      volume: '5125000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-07': {
      open: '191.72',
      high: '195.309',
      low: '191.57',
      close: '194.52',
      'adjusted close': '125.586229225764',
      volume: '6740500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-04': {
      open: '193.12',
      high: '193.97',
      low: '191.28',
      close: '191.77',
      'adjusted close': '123.810771019046',
      volume: '6089900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-03': {
      open: '193.2',
      high: '193.38',
      low: '192.35',
      close: '192.69',
      'adjusted close': '124.404742491839',
      volume: '4091100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-02': {
      open: '194.24',
      high: '194.5',
      low: '192.49',
      close: '193.55',
      'adjusted close': '124.959976694667',
      volume: '4924100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-04-01': {
      open: '193.12',
      high: '195.13',
      low: '192.78',
      close: '194.5',
      'adjusted close': '125.573316802442',
      volume: '5394100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-31': {
      open: '191.64',
      high: '193.77',
      low: '191.4',
      close: '192.49',
      'adjusted close': '124.275618258623',
      volume: '8537300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-28': {
      open: '189.94',
      high: '192.62',
      low: '189.11',
      close: '190.45',
      'adjusted close': '122.958551079821',
      volume: '5194400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-27': {
      open: '191.91',
      high: '192.67',
      low: '189.322',
      close: '189.83',
      'adjusted close': '122.558265956852',
      volume: '6767700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-26': {
      open: '194.98',
      high: '195.63',
      low: '191.96',
      close: '192.62',
      'adjusted close': '124.359549010213',
      volume: '6851700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-25': {
      open: '188.76',
      high: '195.395',
      low: '188.7',
      close: '195.04',
      'adjusted close': '125.921952232125',
      volume: '10912700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-24': {
      open: '187.43',
      high: '189.44',
      low: '187.37',
      close: '188.25',
      'adjusted close': '121.538184514446',
      volume: '5738500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-21': {
      open: '188.5',
      high: '188.7',
      low: '186.4',
      close: '186.67',
      'adjusted close': '120.518103072041',
      volume: '12535200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-20': {
      open: '184.47',
      high: '188.26',
      low: '184.17',
      close: '187.9',
      'adjusted close': '121.312217106318',
      volume: '5062600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-19': {
      open: '185.6',
      high: '186.7',
      low: '183.5001',
      close: '184.71',
      'adjusted close': '119.252685586525',
      volume: '4323900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-18': {
      open: '185.99',
      high: '187.9',
      low: '185.54',
      close: '186.81',
      'adjusted close': '120.608490035292',
      volume: '3961300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-17': {
      open: '182.66',
      high: '186.35',
      low: '182.66',
      close: '185.81',
      'adjusted close': '119.962868869212',
      volume: '4938100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-14': {
      open: '183.89',
      high: '184.29',
      low: '182.21',
      close: '182.21',
      'adjusted close': '117.638632671327',
      volume: '5490300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-13': {
      open: '186.41',
      high: '187.0',
      low: '183.71',
      close: '183.9',
      'adjusted close': '118.729732442001',
      volume: '4913200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-12': {
      open: '186.01',
      high: '187.45',
      low: '185.89',
      close: '186.22',
      'adjusted close': '120.227573547305',
      volume: '4833000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-11': {
      open: '187.41',
      high: '188.45',
      low: '186.04',
      close: '186.76',
      'adjusted close': '120.576208976988',
      volume: '4229500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-10': {
      open: '187.55',
      high: '188.37',
      low: '185.849',
      close: '186.39',
      'adjusted close': '120.337329145538',
      volume: '4591200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-07': {
      open: '188.35',
      high: '188.92',
      low: '187.18',
      close: '187.68',
      'adjusted close': '121.170180449781',
      volume: '4117800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-06': {
      open: '187.37',
      high: '188.39',
      low: '186.9',
      close: '187.64',
      'adjusted close': '121.144355603138',
      volume: '3817300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-05': {
      open: '186.57',
      high: '187.95',
      low: '186.4',
      close: '187.14',
      'adjusted close': '120.821545020098',
      volume: '3485200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-04': {
      open: '185.81',
      high: '187.16',
      low: '185.68',
      close: '186.44',
      'adjusted close': '120.369610203842',
      volume: '4733000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-03-03': {
      open: '183.33',
      high: '184.64',
      low: '182.82',
      close: '184.26',
      'adjusted close': '118.962156061789',
      volume: '3950100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-28': {
      open: '185.38',
      high: '186.12',
      low: '183.65',
      close: '185.17',
      'adjusted close': '119.549671322922',
      volume: '4667900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-27': {
      open: '183.83',
      high: '185.95',
      low: '183.81',
      close: '185.27',
      'adjusted close': '119.614233439529',
      volume: '3827800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-26': {
      open: '183.94',
      high: '185.28',
      low: '183.56',
      close: '184.06',
      'adjusted close': '118.833031828573',
      volume: '3740700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-25': {
      open: '183.52',
      high: '184.6',
      low: '182.88',
      close: '183.23',
      'adjusted close': '118.297166260728',
      volume: '3902300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-24': {
      open: '182.82',
      high: '185.16',
      low: '182.82',
      close: '183.45',
      'adjusted close': '118.439202917265',
      volume: '4595300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-21': {
      open: '184.25',
      high: '185.71',
      low: '182.62',
      close: '182.79',
      'adjusted close': '118.013092947653',
      volume: '5699300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-20': {
      open: '182.96',
      high: '184.8599',
      low: '182.79',
      close: '184.26',
      'adjusted close': '118.962156061789',
      volume: '4968200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-19': {
      open: '182.74',
      high: '185.65',
      low: '182.41',
      close: '182.95',
      'adjusted close': '118.116392334225',
      volume: '4671500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-18': {
      open: '183.18',
      high: '184.0',
      low: '182.32',
      close: '183.19',
      'adjusted close': '118.271341414084',
      volume: '4435200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-14': {
      open: '181.26',
      high: '184.43',
      low: '180.99',
      close: '183.69',
      'adjusted close': '118.594151997124',
      volume: '4659900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-13': {
      open: '178.22',
      high: '182.36',
      low: '177.86',
      close: '181.84',
      'adjusted close': '117.399752839877',
      volume: '4482000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-12': {
      open: '179.52',
      high: '181.25',
      low: '179.26',
      close: '180.24',
      'adjusted close': '116.36675897415',
      volume: '3983000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-11': {
      open: '176.81',
      high: '180.39',
      low: '176.8',
      close: '179.7',
      'adjusted close': '116.018123544467',
      volume: '4647300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-10': {
      open: '176.97',
      high: '177.65',
      low: '176.25',
      close: '177.14',
      'adjusted close': '114.365333359304',
      volume: '3540400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-07': {
      open: '175.64',
      high: '177.56',
      low: '175.07',
      close: '177.25',
      'adjusted close': '114.436351687573',
      volume: '4692900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-06': {
      open: '173.97',
      high: '174.85',
      low: '173.79',
      close: '174.67',
      'adjusted close': '112.770649079088',
      volume: '4292200',
      'dividend amount': '0.9500',
      'split coefficient': '1.0',
    },
    '2014-02-05': {
      open: '172.19',
      high: '174.97',
      low: '172.19',
      close: '174.24',
      'adjusted close': '111.884511419772',
      volume: '4712300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-04': {
      open: '173.53',
      high: '173.75',
      low: '172.36',
      close: '172.84',
      'adjusted close': '110.985531185682',
      volume: '4349800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-02-03': {
      open: '176.02',
      high: '176.02',
      low: '172.72',
      close: '172.9',
      'adjusted close': '111.024058910001',
      volume: '7186800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-31': {
      open: '176.11',
      high: '177.835',
      low: '175.34',
      close: '176.68',
      'adjusted close': '113.451305542041',
      volume: '5193400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-30': {
      open: '177.17',
      high: '177.86',
      low: '176.36',
      close: '177.36',
      'adjusted close': '113.887953084313',
      volume: '4853700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-29': {
      open: '175.98',
      high: '178.53',
      low: '175.89',
      close: '176.4',
      'adjusted close': '113.271509495223',
      volume: '4970900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-28': {
      open: '178.05',
      high: '178.45',
      low: '176.16',
      close: '176.85',
      'adjusted close': '113.560467427609',
      volume: '5333300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-27': {
      open: '179.605',
      high: '179.65',
      low: '177.66',
      close: '177.9',
      'adjusted close': '114.234702603176',
      volume: '5208600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-24': {
      open: '181.25',
      high: '182.8141',
      low: '179.64',
      close: '179.64',
      'adjusted close': '115.352006608401',
      volume: '6890600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-23': {
      open: '181.43',
      high: '183.72',
      low: '180.71',
      close: '182.73',
      'adjusted close': '117.336184410783',
      volume: '6973900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-22': {
      open: '181.28',
      high: '183.5',
      low: '179.67',
      close: '182.25',
      'adjusted close': '117.027962616238',
      volume: '13796100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-21': {
      open: '190.23',
      high: '190.39',
      low: '186.79',
      close: '188.43',
      'adjusted close': '120.996318221003',
      volume: '11076300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-17': {
      open: '188.04',
      high: '190.81',
      low: '187.86',
      close: '190.09',
      'adjusted close': '122.062251927137',
      volume: '7644600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-16': {
      open: '187.53',
      high: '188.99',
      low: '186.8',
      close: '188.76',
      'adjusted close': '121.208220704752',
      volume: '4770300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-15': {
      open: '185.82',
      high: '188.65',
      low: '185.49',
      close: '187.74',
      'adjusted close': '120.553249391345',
      volume: '4816500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-14': {
      open: '185.06',
      high: '186.428',
      low: '183.8818',
      close: '185.92',
      'adjusted close': '119.384575087029',
      volume: '4619200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-13': {
      open: '186.26',
      high: '186.95',
      low: '183.86',
      close: '184.16',
      'adjusted close': '118.254428507031',
      volume: '5784700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-10': {
      open: '188.31',
      high: '188.57',
      low: '186.28',
      close: '187.26',
      'adjusted close': '120.2450275968',
      volume: '4022400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-09': {
      open: '189.02',
      high: '189.5',
      low: '186.55',
      close: '187.38',
      'adjusted close': '120.322083045436',
      volume: '4321300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-08': {
      open: '189.33',
      high: '189.4175',
      low: '187.26',
      close: '187.97',
      'adjusted close': '120.700939001231',
      volume: '4603700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-07': {
      open: '186.39',
      high: '190.35',
      low: '186.38',
      close: '189.71',
      'adjusted close': '121.818243006456',
      volume: '5932300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-06': {
      open: '187.15',
      high: '187.355',
      low: '185.3',
      close: '186.0',
      'adjusted close': '119.43594538612',
      volume: '4067800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-03': {
      open: '185.83',
      high: '187.35',
      low: '185.3',
      close: '186.64',
      'adjusted close': '119.846907778846',
      volume: '4063200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2014-01-02': {
      open: '187.21',
      high: '187.4',
      low: '185.2',
      close: '185.53',
      'adjusted close': '119.134144878961',
      volume: '4546500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-31': {
      open: '186.49',
      high: '187.79',
      low: '186.3',
      close: '187.57',
      'adjusted close': '120.444087505777',
      volume: '3619700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-30': {
      open: '185.32',
      high: '186.7',
      low: '184.67',
      close: '186.41',
      'adjusted close': '119.69921816896',
      volume: '3018600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-27': {
      open: '185.84',
      high: '186.5',
      low: '184.56',
      close: '185.08',
      'adjusted close': '118.845186946576',
      volume: '3381600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-26': {
      open: '183.56',
      high: '185.56',
      low: '183.51',
      close: '185.35',
      'adjusted close': '119.018561706007',
      volume: '3325700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-24': {
      open: '181.955',
      high: '183.5',
      low: '181.91',
      close: '183.22',
      'adjusted close': '117.650827492714',
      volume: '1613600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-23': {
      open: '181.05',
      high: '182.93',
      low: '180.61',
      close: '182.23',
      'adjusted close': '117.015120041466',
      volume: '4079900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-20': {
      open: '180.12',
      high: '182.0',
      low: '180.02',
      close: '180.02',
      'adjusted close': '115.596015529082',
      volume: '7653500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-19': {
      open: '177.88',
      high: '180.54',
      low: '177.88',
      close: '180.22',
      'adjusted close': '115.724441276809',
      volume: '5927000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-18': {
      open: '176.45',
      high: '178.74',
      low: '174.851',
      close: '178.7',
      'adjusted close': '114.748405594084',
      volume: '5697700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-17': {
      open: '177.36',
      high: '177.49',
      low: '175.12',
      close: '175.76',
      'adjusted close': '112.860547102497',
      volume: '5471900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-16': {
      open: '173.22',
      high: '178.35',
      low: '172.73',
      close: '177.85',
      'adjusted close': '114.202596166244',
      volume: '7517000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-13': {
      open: '173.52',
      high: '174.379',
      low: '172.73',
      close: '172.8',
      'adjusted close': '110.959846036137',
      volume: '4184400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-12': {
      open: '175.06',
      high: '175.8',
      low: '173.3',
      close: '173.37',
      'adjusted close': '111.325859417159',
      volume: '5671600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-11': {
      open: '177.0',
      high: '177.76',
      low: '175.1',
      close: '175.2',
      'adjusted close': '112.500955008861',
      volume: '4090400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-10': {
      open: '176.9',
      high: '177.67',
      low: '176.06',
      close: '177.12',
      'adjusted close': '113.73384218704',
      volume: '4127800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-09': {
      open: '177.99',
      high: '178.152',
      low: '176.83',
      close: '177.46',
      'adjusted close': '113.952165958176',
      volume: '3796600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-06': {
      open: '176.7',
      high: '178.0',
      low: '176.01',
      close: '177.67',
      'adjusted close': '114.08701299329',
      volume: '4739800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-05': {
      open: '176.15',
      high: '176.86',
      low: '175.28',
      close: '176.08',
      'adjusted close': '113.06602829886',
      volume: '4384900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-04': {
      open: '175.37',
      high: '177.5',
      low: '175.16',
      close: '175.74',
      'adjusted close': '112.847704527724',
      volume: '5267400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-03': {
      open: '177.0',
      high: '178.23',
      low: '175.64',
      close: '176.08',
      'adjusted close': '113.06602829886',
      volume: '5864000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-12-02': {
      open: '179.46',
      high: '179.59',
      low: '177.12',
      close: '177.48',
      'adjusted close': '113.965008532949',
      volume: '4560000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-29': {
      open: '179.21',
      high: '180.76',
      low: '179.0',
      close: '179.68',
      'adjusted close': '115.377691757946',
      volume: '2870500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-27': {
      open: '177.83',
      high: '180.18',
      low: '177.82',
      close: '178.97',
      'adjusted close': '114.921780353515',
      volume: '4596500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-26': {
      open: '178.67',
      high: '178.94',
      low: '177.31',
      close: '177.31',
      'adjusted close': '113.855846647381',
      volume: '5756000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-25': {
      open: '180.25',
      high: '180.75',
      low: '177.82',
      close: '178.94',
      'adjusted close': '114.902516491356',
      volume: '7161900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-22': {
      open: '183.5001',
      high: '184.99',
      low: '179.92',
      close: '181.3',
      'adjusted close': '116.417940314535',
      volume: '7610200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-21': {
      open: '185.54',
      high: '185.75',
      low: '183.411',
      close: '184.13',
      'adjusted close': '118.235164644872',
      volume: '4459500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-20': {
      open: '185.22',
      high: '186.24',
      low: '184.645',
      close: '185.19',
      'adjusted close': '118.915821107825',
      volume: '3645800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-19': {
      open: '184.63',
      high: '186.2',
      low: '184.15',
      close: '185.25',
      'adjusted close': '118.954348832143',
      volume: '4577000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-18': {
      open: '183.52',
      high: '184.99',
      low: '183.27',
      close: '184.47',
      'adjusted close': '118.453488416008',
      volume: '5344900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-15': {
      open: '182.38',
      high: '183.28',
      low: '181.16',
      close: '183.19',
      'adjusted close': '117.631563630555',
      volume: '5176100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-14': {
      open: '180.48',
      high: '183.2',
      low: '179.66',
      close: '182.21',
      'adjusted close': '117.002277466693',
      volume: '6321500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-13': {
      open: '182.27',
      high: '183.55',
      low: '181.59',
      close: '183.55',
      'adjusted close': '117.862729976464',
      volume: '4704400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-12': {
      open: '182.53',
      high: '184.0487',
      low: '182.26',
      close: '183.07',
      'adjusted close': '117.554508181919',
      volume: '4258500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-11': {
      open: '180.19',
      high: '183.39',
      low: '180.04',
      close: '182.88',
      'adjusted close': '117.432503721578',
      volume: '5222300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-08': {
      open: '178.83',
      high: '180.08',
      low: '177.35',
      close: '179.99',
      'adjusted close': '115.576751666923',
      volume: '6275000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-07': {
      open: '179.6',
      high: '181.39',
      low: '179.6',
      close: '180.0',
      'adjusted close': '115.583172954309',
      volume: '5219500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-06': {
      open: '177.91',
      high: '179.75',
      low: '177.78',
      close: '179.19',
      'adjusted close': '115.063048676015',
      volume: '4560700',
      'dividend amount': '0.9500',
      'split coefficient': '1.0',
    },
    '2013-11-05': {
      open: '179.54',
      high: '179.8',
      low: '177.71',
      close: '177.85',
      'adjusted close': '113.600328672306',
      volume: '6096800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-04': {
      open: '179.9',
      high: '180.8',
      low: '179.34',
      close: '180.27',
      'adjusted close': '115.146085182776',
      volume: '3483300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-11-01': {
      open: '179.81',
      high: '180.339',
      low: '178.88',
      close: '179.23',
      'adjusted close': '114.48179312869',
      volume: '3644500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-31': {
      open: '179.65',
      high: '181.67',
      low: '179.04',
      close: '179.21',
      'adjusted close': '114.469018281496',
      volume: '4318100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-30': {
      open: '181.69',
      high: '182.18',
      low: '179.86',
      close: '180.15',
      'adjusted close': '115.069436099612',
      volume: '5273700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-29': {
      open: '177.62',
      high: '182.32',
      low: '177.5',
      close: '182.12',
      'adjusted close': '116.327758548217',
      volume: '8904600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-28': {
      open: '177.04',
      high: '177.89',
      low: '176.2',
      close: '177.35',
      'adjusted close': '113.280957492457',
      volume: '3712700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-25': {
      open: '178.43',
      high: '179.1',
      low: '176.26',
      close: '176.85',
      'adjusted close': '112.961586312608',
      volume: '4842800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-24': {
      open: '176.43',
      high: '177.89',
      low: '176.25',
      close: '177.8',
      'adjusted close': '113.568391554322',
      volume: '5533300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-23': {
      open: '175.09',
      high: '175.99',
      low: '174.4',
      close: '175.77',
      'adjusted close': '112.271744564134',
      volume: '5409400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-22': {
      open: '173.35',
      high: '175.57',
      low: '172.9512',
      close: '174.97',
      'adjusted close': '111.760750676376',
      volume: '6977300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-21': {
      open: '174.42',
      high: '174.75',
      low: '172.6301',
      close: '172.86',
      'adjusted close': '110.413004297413',
      volume: '7098700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-18': {
      open: '174.8',
      high: '175.0',
      low: '173.25',
      close: '173.78',
      'adjusted close': '111.000647268335',
      volume: '10548000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-17': {
      open: '173.84',
      high: '177.0',
      low: '172.57',
      close: '174.83',
      'adjusted close': '111.671326746018',
      volume: '22368900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-16': {
      open: '185.42',
      high: '186.73',
      low: '184.994',
      close: '186.73',
      'adjusted close': '119.272360826425',
      volume: '6718000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-15': {
      open: '185.74',
      high: '185.94',
      low: '184.22',
      close: '184.66',
      'adjusted close': '117.95016414185',
      volume: '3365100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-14': {
      open: '185.41',
      high: '186.99',
      low: '184.42',
      close: '186.97',
      'adjusted close': '119.425658992753',
      volume: '2663100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-11': {
      open: '185.25',
      high: '186.23',
      low: '184.12',
      close: '186.16',
      'adjusted close': '118.908277681398',
      volume: '3232600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-10': {
      open: '183.17',
      high: '184.77',
      low: '182.358',
      close: '184.77',
      'adjusted close': '118.020425801417',
      volume: '3658900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-09': {
      open: '179.37',
      high: '181.67',
      low: '179.1',
      close: '181.32',
      'adjusted close': '115.816764660459',
      volume: '4423500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-08': {
      open: '181.89',
      high: '181.99',
      low: '178.71',
      close: '178.72',
      'adjusted close': '114.156034525244',
      volume: '5578300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-07': {
      open: '181.85',
      high: '183.31',
      low: '181.85',
      close: '182.01',
      'adjusted close': '116.25749688865',
      volume: '3966400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-04': {
      open: '184.17',
      high: '185.13',
      low: '183.58',
      close: '184.1',
      'adjusted close': '117.59246842042',
      volume: '2863600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-03': {
      open: '184.7',
      high: '184.96',
      low: '183.0',
      close: '183.86',
      'adjusted close': '117.439170254092',
      volume: '3211800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-02': {
      open: '185.54',
      high: '186.3099',
      low: '184.41',
      close: '184.96',
      'adjusted close': '118.14178684976',
      volume: '3617100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-10-01': {
      open: '185.34',
      high: '186.65',
      low: '184.65',
      close: '186.38',
      'adjusted close': '119.048801000531',
      volume: '2681200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-30': {
      open: '185.56',
      high: '186.74',
      low: '184.34',
      close: '185.18',
      'adjusted close': '118.282310168893',
      volume: '3978200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-27': {
      open: '188.92',
      high: '188.94',
      low: '186.45',
      close: '186.92',
      'adjusted close': '119.393721874768',
      volume: '3905500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-26': {
      open: '190.1',
      high: '191.76',
      low: '189.455',
      close: '190.22',
      'adjusted close': '121.501571661772',
      volume: '2093600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-25': {
      open: '190.14',
      high: '190.62',
      low: '188.5',
      close: '189.47',
      'adjusted close': '121.022514891998',
      volume: '2654500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-24': {
      open: '190.93',
      high: '191.56',
      low: '189.66',
      close: '189.97',
      'adjusted close': '121.341886071847',
      volume: '3085300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-23': {
      open: '190.06',
      high: '192.41',
      low: '189.3301',
      close: '190.99',
      'adjusted close': '121.993403278739',
      volume: '3340800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-20': {
      open: '193.96',
      high: '194.4299',
      low: '190.02',
      close: '190.02',
      'adjusted close': '121.373823189832',
      volume: '8525900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-19': {
      open: '194.18',
      high: '194.89',
      low: '193.22',
      close: '193.39',
      'adjusted close': '123.526384942015',
      volume: '3178100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-18': {
      open: '192.6',
      high: '194.89',
      low: '192.0',
      close: '194.42',
      'adjusted close': '124.184289572504',
      volume: '3841800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-17': {
      open: '193.42',
      high: '194.15',
      low: '191.83',
      close: '192.16',
      'adjusted close': '122.740731839586',
      volume: '2930900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-16': {
      open: '193.7',
      high: '194.81',
      low: '192.61',
      close: '193.15',
      'adjusted close': '123.373086775687',
      volume: '3902400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-13': {
      open: '191.21',
      high: '193.1',
      low: '191.0',
      close: '192.17',
      'adjusted close': '122.747119263183',
      volume: '3710400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-12': {
      open: '190.96',
      high: '191.32',
      low: '189.85',
      close: '190.73',
      'adjusted close': '121.827330265218',
      volume: '3354800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-11': {
      open: '186.83',
      high: '190.87',
      low: '186.82',
      close: '190.7',
      'adjusted close': '121.808167994427',
      volume: '4962900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-10': {
      open: '187.2',
      high: '187.65',
      low: '186.37',
      close: '186.6',
      'adjusted close': '119.189324319665',
      volume: '3149600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-09': {
      open: '183.68',
      high: '185.49',
      low: '183.31',
      close: '184.98',
      'adjusted close': '118.154561696954',
      volume: '3017200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-06': {
      open: '184.65',
      high: '184.99',
      low: '182.65',
      close: '183.03',
      'adjusted close': '116.909014095543',
      volume: '2903500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-05': {
      open: '183.35',
      high: '185.0',
      low: '183.07',
      close: '184.15',
      'adjusted close': '117.624405538404',
      volume: '2867600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-04': {
      open: '183.58',
      high: '184.19',
      low: '182.31',
      close: '183.13',
      'adjusted close': '116.972888331512',
      volume: '2597900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-09-03': {
      open: '183.63',
      high: '184.32',
      low: '182.51',
      close: '183.96',
      'adjusted close': '117.503044490062',
      volume: '3487200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-30': {
      open: '182.75',
      high: '182.99',
      low: '181.51',
      close: '182.27',
      'adjusted close': '116.423569902172',
      volume: '2731000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-29': {
      open: '181.96',
      high: '183.7',
      low: '181.44',
      close: '182.64',
      'adjusted close': '116.65990457526',
      volume: '2980900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-28': {
      open: '182.68',
      high: '183.47',
      low: '181.1',
      close: '182.16',
      'adjusted close': '116.353308242605',
      volume: '3979200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-27': {
      open: '183.63',
      high: '184.5',
      low: '182.5701',
      close: '182.74',
      'adjusted close': '116.72377881123',
      volume: '3190700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-26': {
      open: '185.27',
      high: '187.0',
      low: '184.68',
      close: '184.74',
      'adjusted close': '118.001263530626',
      volume: '2170400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-23': {
      open: '185.34',
      high: '185.74',
      low: '184.57',
      close: '185.42',
      'adjusted close': '118.435608335221',
      volume: '2292700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-22': {
      open: '185.65',
      high: '186.25',
      low: '184.25',
      close: '185.19',
      'adjusted close': '118.28869759249',
      volume: '2354300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-21': {
      open: '184.67',
      high: '186.57',
      low: '184.28',
      close: '184.86',
      'adjusted close': '118.07791261379',
      volume: '3551000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-20': {
      open: '184.37',
      high: '185.63',
      low: '183.1685',
      close: '184.56',
      'adjusted close': '117.886289905881',
      volume: '3117800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-19': {
      open: '185.3',
      high: '186.5',
      low: '183.9787',
      close: '184.23',
      'adjusted close': '117.67550492718',
      volume: '3248900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-16': {
      open: '185.54',
      high: '186.46',
      low: '185.34',
      close: '185.34',
      'adjusted close': '118.384508946445',
      volume: '3426400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-15': {
      open: '186.23',
      high: '187.0',
      low: '185.45',
      close: '185.79',
      'adjusted close': '118.671943008309',
      volume: '3692400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-14': {
      open: '188.58',
      high: '188.926',
      low: '187.3',
      close: '187.53',
      'adjusted close': '119.783354714184',
      volume: '2892300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-13': {
      open: '189.54',
      high: '189.99',
      low: '187.56',
      close: '188.42',
      'adjusted close': '120.351835414315',
      volume: '3542900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-12': {
      open: '186.97',
      high: '189.556',
      low: '186.89',
      close: '189.09',
      'adjusted close': '120.779792795313',
      volume: '3026500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-09': {
      open: '188.0',
      high: '189.16',
      low: '187.0',
      close: '187.82',
      'adjusted close': '119.968589998496',
      volume: '3249600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-08': {
      open: '189.45',
      high: '189.87',
      low: '186.79',
      close: '187.93',
      'adjusted close': '120.038851658063',
      volume: '4547600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-07': {
      open: '189.6',
      high: '189.93',
      low: '188.05',
      close: '188.56',
      'adjusted close': '120.441259344673',
      volume: '3901700',
      'dividend amount': '0.9500',
      'split coefficient': '1.0',
    },
    '2013-08-06': {
      open: '192.26',
      high: '192.51',
      low: '190.27',
      close: '190.99',
      'adjusted close': '121.381859122152',
      volume: '5938500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-05': {
      open: '195.16',
      high: '195.88',
      low: '194.35',
      close: '195.5',
      'adjusted close': '124.248146281904',
      volume: '2490900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-02': {
      open: '195.5',
      high: '195.5',
      low: '193.2215',
      close: '195.16',
      'adjusted close': '124.03206254924',
      volume: '3874000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-08-01': {
      open: '196.65',
      high: '197.17',
      low: '195.41',
      close: '195.81',
      'adjusted close': '124.445163802862',
      volume: '2877700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-31': {
      open: '194.49',
      high: '196.9099',
      low: '194.49',
      close: '195.04',
      'adjusted close': '123.955797702417',
      volume: '3810000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-30': {
      open: '196.99',
      high: '197.83',
      low: '195.81',
      close: '196.01',
      'adjusted close': '124.5722718809',
      volume: '2663200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-29': {
      open: '196.83',
      high: '197.19',
      low: '195.53',
      close: '196.21',
      'adjusted close': '124.699379958938',
      volume: '2113700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-26': {
      open: '196.59',
      high: '197.37',
      low: '195.0',
      close: '197.35',
      'adjusted close': '125.423896003753',
      volume: '2485100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-25': {
      open: '196.3',
      high: '197.83',
      low: '195.66',
      close: '197.22',
      'adjusted close': '125.341275753028',
      volume: '3014300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-24': {
      open: '195.95',
      high: '197.3',
      low: '195.861',
      close: '196.61',
      'adjusted close': '124.953596115013',
      volume: '2957900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-23': {
      open: '194.21',
      high: '196.43',
      low: '194.1',
      close: '194.98',
      'adjusted close': '123.917665279006',
      volume: '2863800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-22': {
      open: '193.4',
      high: '195.79',
      low: '193.2804',
      close: '194.09',
      'adjusted close': '123.352034331738',
      volume: '3398000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-19': {
      open: '197.91',
      high: '197.99',
      low: '193.24',
      close: '193.54',
      'adjusted close': '123.002487117134',
      volume: '6997600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-18': {
      open: '198.27',
      high: '200.94',
      low: '195.99',
      close: '197.99',
      'adjusted close': '125.830641853474',
      volume: '8393400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-17': {
      open: '194.72',
      high: '194.89',
      low: '193.03',
      close: '194.55',
      'adjusted close': '123.644382911224',
      volume: '6868400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-16': {
      open: '194.04',
      high: '194.58',
      low: '192.685',
      close: '193.85',
      'adjusted close': '123.199504638092',
      volume: '3745300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-15': {
      open: '192.42',
      high: '194.89',
      low: '191.68',
      close: '194.0',
      'adjusted close': '123.294835696621',
      volume: '5674700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-12': {
      open: '193.07',
      high: '193.6999',
      low: '191.5901',
      close: '192.07',
      'adjusted close': '122.068242743556',
      volume: '4494700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-11': {
      open: '193.78',
      high: '194.11',
      low: '192.61',
      close: '192.8',
      'adjusted close': '122.532187228394',
      volume: '4177500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-10': {
      open: '191.51',
      high: '193.43',
      low: '191.2714',
      close: '192.25',
      'adjusted close': '122.18264001379',
      volume: '3892000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-09': {
      open: '191.88',
      high: '192.8',
      low: '190.78',
      close: '191.3',
      'adjusted close': '121.578876643111',
      volume: '5204200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-08': {
      open: '195.6',
      high: '195.78',
      low: '194.61',
      close: '194.98',
      'adjusted close': '123.917665279006',
      volume: '2947500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-05': {
      open: '194.49',
      high: '195.16',
      low: '192.35',
      close: '194.93',
      'adjusted close': '123.885888259496',
      volume: '2405400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-03': {
      open: '190.65',
      high: '194.22',
      low: '190.51',
      close: '193.25',
      'adjusted close': '122.818180403979',
      volume: '1872600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-02': {
      open: '190.78',
      high: '193.23',
      low: '190.26',
      close: '191.5',
      'adjusted close': '121.705984721149',
      volume: '3657700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-07-01': {
      open: '192.15',
      high: '193.8',
      low: '191.12',
      close: '191.28',
      'adjusted close': '121.566165835307',
      volume: '4442400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-28': {
      open: '191.73',
      high: '192.66',
      low: '188.41',
      close: '191.11',
      'adjusted close': '121.458123968975',
      volume: '10055800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-27': {
      open: '196.05',
      high: '196.9',
      low: '195.29',
      close: '195.65',
      'adjusted close': '124.343477340432',
      volume: '3514200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-26': {
      open: '195.97',
      high: '196.23',
      low: '194.5',
      close: '194.86',
      'adjusted close': '123.841400432183',
      volume: '3320200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-25': {
      open: '195.31',
      high: '195.59',
      low: '193.15',
      close: '194.98',
      'adjusted close': '123.917665279006',
      volume: '3846200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-24': {
      open: '193.99',
      high: '194.91',
      low: '191.34',
      close: '193.54',
      'adjusted close': '123.002487117134',
      volume: '4356200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-21': {
      open: '198.5',
      high: '198.52',
      low: '193.5401',
      close: '195.46',
      'adjusted close': '124.222724666296',
      volume: '8914800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-20': {
      open: '200.67',
      high: '201.7',
      low: '197.28',
      close: '197.35',
      'adjusted close': '125.423896003753',
      volume: '4514800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-19': {
      open: '204.44',
      high: '205.03',
      low: '201.93',
      close: '201.94',
      'adjusted close': '128.341026394719',
      volume: '2846100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-18': {
      open: '203.02',
      high: '206.09',
      low: '202.87',
      close: '204.87',
      'adjusted close': '130.203159737972',
      volume: '3277800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-17': {
      open: '203.44',
      high: '205.1713',
      low: '202.55',
      close: '203.04',
      'adjusted close': '129.040120823927',
      volume: '3219900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-14': {
      open: '203.97',
      high: '204.745',
      low: '201.81',
      close: '202.2',
      'adjusted close': '128.506266896168',
      volume: '2804500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-13': {
      open: '201.01',
      high: '204.4',
      low: '200.34',
      close: '203.77',
      'adjusted close': '129.504065308765',
      volume: '2899500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-12': {
      open: '204.59',
      high: '205.23',
      low: '200.51',
      close: '201.2',
      'adjusted close': '127.87072650598',
      volume: '3409800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-11': {
      open: '203.17',
      high: '205.95',
      low: '202.51',
      close: '203.98',
      'adjusted close': '129.637528790704',
      volume: '2814400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-10': {
      open: '206.97',
      high: '206.98',
      low: '204.9',
      close: '205.02',
      'adjusted close': '130.298490796501',
      volume: '2652300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-07': {
      open: '204.85',
      high: '206.35',
      low: '204.11',
      close: '206.35',
      'adjusted close': '131.143759515452',
      volume: '2953400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-06': {
      open: '203.0',
      high: '204.2',
      low: '201.47',
      close: '203.8',
      'adjusted close': '129.52313152047',
      volume: '3679400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-05': {
      open: '205.16',
      high: '206.29',
      low: '202.4',
      close: '202.74',
      'adjusted close': '128.84945870687',
      volume: '4215400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-04': {
      open: '208.6',
      high: '209.0',
      low: '205.08',
      close: '206.19',
      'adjusted close': '131.042073053022',
      volume: '3598300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-06-03': {
      open: '208.25',
      high: '210.05',
      low: '207.33',
      close: '208.95',
      'adjusted close': '132.796164529943',
      volume: '3322000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-31': {
      open: '208.59',
      high: '211.98',
      low: '208.02',
      close: '208.02',
      'adjusted close': '132.205111967067',
      volume: '4949000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-30': {
      open: '206.97',
      high: '210.99',
      low: '206.97',
      close: '209.36',
      'adjusted close': '133.05673608992',
      volume: '4399700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-29': {
      open: '206.22',
      high: '208.42',
      low: '206.04',
      close: '207.92',
      'adjusted close': '132.141557928048',
      volume: '3439400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-28': {
      open: '207.44',
      high: '208.59',
      low: '207.16',
      close: '207.78',
      'adjusted close': '132.052582273422',
      volume: '4011500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-24': {
      open: '204.69',
      high: '206.43',
      low: '204.42',
      close: '205.72',
      'adjusted close': '130.743369069633',
      volume: '3295800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-23': {
      open: '205.48',
      high: '208.61',
      low: '205.13',
      close: '206.16',
      'adjusted close': '131.023006841316',
      volume: '5179100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-22': {
      open: '208.5',
      high: '210.15',
      low: '206.121',
      close: '206.99',
      'adjusted close': '131.550505365173',
      volume: '5186800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-21': {
      open: '207.24',
      high: '209.48',
      low: '207.0',
      close: '208.65',
      'adjusted close': '132.605502412886',
      volume: '2982900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-20': {
      open: '208.02',
      high: '209.15',
      low: '207.42',
      close: '207.6',
      'adjusted close': '131.938185003188',
      volume: '3614500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-17': {
      open: '205.25',
      high: '209.5',
      low: '204.99',
      close: '208.44',
      'adjusted close': '132.472038930946',
      volume: '5704100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-16': {
      open: '204.0',
      high: '206.59',
      low: '204.0',
      close: '204.69',
      'adjusted close': '130.088762467738',
      volume: '4507000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-15': {
      open: '202.25',
      high: '203.6799',
      low: '202.04',
      close: '203.32',
      'adjusted close': '129.21807213318',
      volume: '4028100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-14': {
      open: '202.09',
      high: '203.67',
      low: '202.08',
      close: '203.21',
      'adjusted close': '129.148162690259',
      volume: '3699700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-13': {
      open: '204.18',
      high: '204.47',
      low: '202.22',
      close: '202.47',
      'adjusted close': '128.677862801519',
      volume: '3648400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-10': {
      open: '203.37',
      high: '204.53',
      low: '202.82',
      close: '204.47',
      'adjusted close': '129.948943581897',
      volume: '3279200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-09': {
      open: '204.69',
      high: '205.0',
      low: '202.72',
      close: '203.24',
      'adjusted close': '129.167228901965',
      volume: '3542300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-08': {
      open: '202.94',
      high: '204.85',
      low: '202.51',
      close: '204.82',
      'adjusted close': '130.171382718463',
      volume: '3601700',
      'dividend amount': '0.9500',
      'split coefficient': '1.0',
    },
    '2013-05-07': {
      open: '202.81',
      high: '203.73',
      low: '201.65',
      close: '203.63',
      'adjusted close': '128.817605399041',
      volume: '3451000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-06': {
      open: '203.79',
      high: '203.99',
      low: '201.52',
      close: '202.78',
      'adjusted close': '128.279890108616',
      volume: '4826500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-03': {
      open: '203.94',
      high: '205.319',
      low: '203.75',
      close: '204.51',
      'adjusted close': '129.374298876186',
      volume: '4492700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-02': {
      open: '200.12',
      high: '202.43',
      low: '199.75',
      close: '202.39',
      'adjusted close': '128.033173681245',
      volume: '3945100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-05-01': {
      open: '201.87',
      high: '202.17',
      low: '199.2',
      close: '199.63',
      'adjusted close': '126.287180502924',
      volume: '4899100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-30': {
      open: '199.13',
      high: '202.59',
      low: '197.35',
      close: '202.54',
      'adjusted close': '128.128064614849',
      volume: '7539900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-29': {
      open: '194.78',
      high: '199.68',
      low: '194.65',
      close: '199.15',
      'adjusted close': '125.983529515391',
      volume: '5932400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-26': {
      open: '194.16',
      high: '194.71',
      low: '193.25',
      close: '194.31',
      'adjusted close': '122.92171539109',
      volume: '3490300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-25': {
      open: '192.69',
      high: '195.17',
      low: '192.32',
      close: '193.95',
      'adjusted close': '122.693977150439',
      volume: '4650900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-24': {
      open: '192.16',
      high: '192.5',
      low: '191.09',
      close: '191.71',
      'adjusted close': '121.276939208614',
      volume: '3693900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-23': {
      open: '189.27',
      high: '192.555',
      low: '188.61',
      close: '191.61',
      'adjusted close': '121.213678586211',
      volume: '6296100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-22': {
      open: '191.15',
      high: '191.38',
      low: '187.68',
      close: '187.83',
      'adjusted close': '118.822427059381',
      volume: '9845400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-19': {
      open: '195.74',
      high: '196.5',
      low: '189.76',
      close: '190.0',
      'adjusted close': '120.195182565524',
      volume: '18847000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-18': {
      open: '210.11',
      high: '210.25',
      low: '206.15',
      close: '207.15',
      'adjusted close': '131.044379307623',
      volume: '6502000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-17': {
      open: '210.53',
      high: '211.09',
      low: '209.5',
      close: '209.67',
      'adjusted close': '132.638546992176',
      volume: '3269800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-16': {
      open: '210.84',
      high: '212.0',
      low: '209.2',
      close: '212.0',
      'adjusted close': '134.112519494164',
      volume: '2859500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-15': {
      open: '210.44',
      high: '211.69',
      low: '209.26',
      close: '209.26',
      'adjusted close': '132.379178440324',
      volume: '4218100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-12': {
      open: '210.79',
      high: '211.49',
      low: '209.78',
      close: '211.38',
      'adjusted close': '133.720303635266',
      volume: '3165400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-11': {
      open: '211.5',
      high: '213.09',
      low: '210.85',
      close: '212.92',
      'adjusted close': '134.694517220271',
      volume: '3655600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-10': {
      open: '211.86',
      high: '212.51',
      low: '210.47',
      close: '212.0',
      'adjusted close': '134.112519494164',
      volume: '3524200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-09': {
      open: '209.56',
      high: '210.0',
      low: '208.61',
      close: '209.22',
      'adjusted close': '132.353874191363',
      volume: '2577300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-08': {
      open: '209.07',
      high: '209.32',
      low: '207.33',
      close: '209.32',
      'adjusted close': '132.417134813766',
      volume: '2351200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-05': {
      open: '209.1',
      high: '209.84',
      low: '206.34',
      close: '209.41',
      'adjusted close': '132.474069373929',
      volume: '4148000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-04': {
      open: '212.77',
      high: '212.93',
      low: '210.04',
      close: '211.31',
      'adjusted close': '133.676021199584',
      volume: '3709500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-03': {
      open: '214.32',
      high: '214.89',
      low: '212.6275',
      close: '212.66',
      'adjusted close': '134.530039602023',
      volume: '3128800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-02': {
      open: '212.92',
      high: '214.71',
      low: '211.57',
      close: '214.36',
      'adjusted close': '135.605470182873',
      volume: '3116800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-04-01': {
      open: '212.8',
      high: '213.5',
      low: '211.25',
      close: '212.38',
      'adjusted close': '134.352909859295',
      volume: '2144500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-28': {
      open: '209.83',
      high: '213.44',
      low: '209.735',
      close: '213.3',
      'adjusted close': '134.934907585402',
      volume: '3749900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-27': {
      open: '210.96',
      high: '212.16',
      low: '210.1',
      close: '210.89',
      'adjusted close': '133.410326585492',
      volume: '3223400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-26': {
      open: '211.77',
      high: '212.504',
      low: '211.5',
      close: '212.36',
      'adjusted close': '134.340257734815',
      volume: '2300300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-25': {
      open: '212.54',
      high: '212.81',
      low: '210.05',
      close: '210.74',
      'adjusted close': '133.315435651888',
      volume: '3242500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-22': {
      open: '212.21',
      high: '213.17',
      low: '211.619',
      close: '212.08',
      'adjusted close': '134.163127992087',
      volume: '3031500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-21': {
      open: '212.96',
      high: '213.0',
      low: '210.11',
      close: '212.26',
      'adjusted close': '134.276997112412',
      volume: '5830400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-20': {
      open: '214.76',
      high: '215.82',
      low: '214.3',
      close: '215.06',
      'adjusted close': '136.048294539693',
      volume: '3019000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-19': {
      open: '214.13',
      high: '215.12',
      low: '211.829',
      close: '213.44',
      'adjusted close': '135.023472456766',
      volume: '3198600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-18': {
      open: '212.9',
      high: '214.5',
      low: '212.64',
      close: '213.21',
      'adjusted close': '134.877973025239',
      volume: '3006000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-15': {
      open: '215.38',
      high: '215.9',
      low: '213.41',
      close: '214.92',
      'adjusted close': '135.959729668329',
      volume: '7930300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-14': {
      open: '212.15',
      high: '215.86',
      low: '212.15',
      close: '215.8',
      'adjusted close': '136.516423145475',
      volume: '5505500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-13': {
      open: '210.2001',
      high: '212.36',
      low: '209.77',
      close: '212.06',
      'adjusted close': '134.150475867606',
      volume: '3355900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-12': {
      open: '209.4',
      high: '210.73',
      low: '209.09',
      close: '210.55',
      'adjusted close': '133.195240469322',
      volume: '3591600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-11': {
      open: '210.04',
      high: '210.1999',
      low: '209.04',
      close: '210.08',
      'adjusted close': '132.897915544028',
      volume: '3049700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-08': {
      open: '209.85',
      high: '210.74',
      low: '209.43',
      close: '210.38',
      'adjusted close': '133.087697411237',
      volume: '3700500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-07': {
      open: '208.29',
      high: '209.6',
      low: '208.24',
      close: '209.42',
      'adjusted close': '132.480395436169',
      volume: '3884400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-06': {
      open: '207.03',
      high: '208.49',
      low: '206.66',
      close: '208.38',
      'adjusted close': '131.822484963179',
      volume: '3594800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-05': {
      open: '205.86',
      high: '207.7',
      low: '205.69',
      close: '206.53',
      'adjusted close': '130.652163448725',
      volume: '3807800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-04': {
      open: '202.59',
      high: '205.19',
      low: '202.55',
      close: '205.19',
      'adjusted close': '129.804471108526',
      volume: '3693400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-03-01': {
      open: '200.65',
      high: '202.94',
      low: '199.36',
      close: '202.91',
      'adjusted close': '128.36212891774',
      volume: '3308300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-28': {
      open: '202.18',
      high: '203.123',
      low: '200.79',
      close: '200.83',
      'adjusted close': '127.046307971759',
      volume: '4689200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-27': {
      open: '198.89',
      high: '202.75',
      low: '198.6',
      close: '202.33',
      'adjusted close': '127.995217307803',
      volume: '4185100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-26': {
      open: '198.63',
      high: '199.9',
      low: '197.84',
      close: '199.14',
      'adjusted close': '125.97720345315',
      volume: '3391600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-25': {
      open: '201.67',
      high: '202.49',
      low: '197.51',
      close: '197.51',
      'adjusted close': '124.946055307983',
      volume: '3844800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-22': {
      open: '199.23',
      high: '201.09',
      low: '198.84',
      close: '201.09',
      'adjusted close': '127.210785590007',
      volume: '3107900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-21': {
      open: '198.63',
      high: '199.07',
      low: '198.11',
      close: '198.33',
      'adjusted close': '125.464792411687',
      volume: '3922900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-20': {
      open: '200.62',
      high: '201.72',
      low: '198.86',
      close: '199.31',
      'adjusted close': '126.084746511235',
      volume: '3715400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-19': {
      open: '200.6',
      high: '201.89',
      low: '200.22',
      close: '200.32',
      'adjusted close': '126.723678797505',
      volume: '2998300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-15': {
      open: '199.98',
      high: '201.25',
      low: '199.82',
      close: '200.98',
      'adjusted close': '127.141198905364',
      volume: '3627800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-14': {
      open: '199.73',
      high: '200.32',
      low: '199.26',
      close: '199.65',
      'adjusted close': '126.299832627405',
      volume: '3294200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-13': {
      open: '200.65',
      high: '200.95',
      low: '199.57',
      close: '200.09',
      'adjusted close': '126.578179365978',
      volume: '2169800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-12': {
      open: '200.01',
      high: '200.735',
      low: '199.02',
      close: '200.04',
      'adjusted close': '126.546549054776',
      volume: '2461800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-11': {
      open: '200.98',
      high: '201.95',
      low: '199.75',
      close: '200.16',
      'adjusted close': '126.62246180166',
      volume: '2944700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-08': {
      open: '199.97',
      high: '202.09',
      low: '199.68',
      close: '201.68',
      'adjusted close': '127.584023262184',
      volume: '2893300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-07': {
      open: '200.62',
      high: '200.91',
      low: '198.68',
      close: '199.74',
      'adjusted close': '126.356767187568',
      volume: '3076700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-06': {
      open: '200.39',
      high: '201.29',
      low: '199.56',
      close: '201.02',
      'adjusted close': '127.166503154325',
      volume: '3624200',
      'dividend amount': '0.8500',
      'split coefficient': '1.0',
    },
    '2013-02-05': {
      open: '204.31',
      high: '204.75',
      low: '202.51',
      close: '202.79',
      'adjusted close': '127.746050302995',
      volume: '3636900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-04': {
      open: '204.19',
      high: '205.02',
      low: '203.57',
      close: '203.79',
      'adjusted close': '128.375992855897',
      volume: '3188800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-02-01': {
      open: '204.65',
      high: '205.35',
      low: '203.84',
      close: '205.18',
      'adjusted close': '129.251613004431',
      volume: '3370700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-31': {
      open: '203.32',
      high: '204.4699',
      low: '202.96',
      close: '203.07',
      'adjusted close': '127.922434217807',
      volume: '3091400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-30': {
      open: '203.69',
      high: '204.88',
      low: '203.186',
      close: '203.52',
      'adjusted close': '128.205908366613',
      volume: '2998400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-29': {
      open: '204.34',
      high: '205.7301',
      low: '203.64',
      close: '203.9',
      'adjusted close': '128.445286536716',
      volume: '3617100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-28': {
      open: '204.85',
      high: '206.22',
      low: '204.29',
      close: '204.93',
      'adjusted close': '129.094127366205',
      volume: '2823700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-25': {
      open: '204.45',
      high: '205.18',
      low: '204.13',
      close: '204.97',
      'adjusted close': '129.119325068321',
      volume: '3358900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-24': {
      open: '203.91',
      high: '205.06',
      low: '203.08',
      close: '204.42',
      'adjusted close': '128.772856664225',
      volume: '4447200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-23': {
      open: '203.5',
      high: '208.58',
      low: '203.36',
      close: '204.72',
      'adjusted close': '128.961839430096',
      volume: '12530500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-22': {
      open: '194.36',
      high: '196.08',
      low: '194.01',
      close: '196.08',
      'adjusted close': '123.519135773022',
      volume: '7172300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-18': {
      open: '194.03',
      high: '195.0',
      low: '193.8',
      close: '194.47',
      'adjusted close': '122.50492826285',
      volume: '4559600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-17': {
      open: '193.85',
      high: '194.46',
      low: '193.24',
      close: '193.65',
      'adjusted close': '121.988375369471',
      volume: '3881600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-16': {
      open: '192.0',
      high: '193.18',
      low: '191.35',
      close: '192.59',
      'adjusted close': '121.320636263394',
      volume: '2962900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-15': {
      open: '191.31',
      high: '192.73',
      low: '190.39',
      close: '192.5',
      'adjusted close': '121.263941433633',
      volume: '4172100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-14': {
      open: '192.82',
      high: '193.28',
      low: '191.75',
      close: '192.62',
      'adjusted close': '121.339534539981',
      volume: '4172200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-11': {
      open: '194.15',
      high: '195.0',
      low: '192.9',
      close: '194.45',
      'adjusted close': '122.492329411792',
      volume: '3880400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-10': {
      open: '192.65',
      high: '192.96',
      low: '191.28',
      close: '192.88',
      'adjusted close': '121.503319603736',
      volume: '3608100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-09': {
      open: '193.48',
      high: '193.49',
      low: '191.65',
      close: '192.32',
      'adjusted close': '121.150551774111',
      volume: '3212000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-08': {
      open: '192.92',
      high: '193.3',
      low: '191.605',
      close: '192.87',
      'adjusted close': '121.497020178207',
      volume: '3026900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-07': {
      open: '193.4',
      high: '193.78',
      low: '192.34',
      close: '193.14',
      'adjusted close': '121.667104667491',
      volume: '2862300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-04': {
      open: '194.19',
      high: '194.46',
      low: '192.78',
      close: '193.99',
      'adjusted close': '122.202555837457',
      volume: '3380200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-03': {
      open: '195.67',
      high: '196.29',
      low: '194.44',
      close: '195.27',
      'adjusted close': '123.008882305172',
      volume: '3644700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2013-01-02': {
      open: '194.09',
      high: '196.35',
      low: '193.8',
      close: '196.35',
      'adjusted close': '123.689220262306',
      volume: '4234100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-31': {
      open: '189.23',
      high: '191.68',
      low: '188.84',
      close: '191.55',
      'adjusted close': '120.665496008376',
      volume: '4684900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-28': {
      open: '191.11',
      high: '192.22',
      low: '189.83',
      close: '189.83',
      'adjusted close': '119.581994817385',
      volume: '3536600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-27': {
      open: '191.53',
      high: '193.28',
      low: '190.7',
      close: '192.71',
      'adjusted close': '121.396229369743',
      volume: '3967600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-26': {
      open: '192.21',
      high: '193.53',
      low: '190.95',
      close: '191.95',
      'adjusted close': '120.917473029537',
      volume: '2776500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-24': {
      open: '193.05',
      high: '193.83',
      low: '192.02',
      close: '192.4',
      'adjusted close': '121.200947178343',
      volume: '1631200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-21': {
      open: '193.1',
      high: '194.5',
      low: '191.57',
      close: '193.42',
      'adjusted close': '121.843488582303',
      volume: '8225300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-20': {
      open: '194.47',
      high: '194.95',
      low: '193.16',
      close: '194.77',
      'adjusted close': '122.693911028721',
      volume: '4184500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-19': {
      open: '195.46',
      high: '196.45',
      low: '195.0',
      close: '195.08',
      'adjusted close': '122.88919322012',
      volume: '4270500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-18': {
      open: '193.85',
      high: '195.92',
      low: '193.84',
      close: '195.69',
      'adjusted close': '123.273458177391',
      volume: '4125900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-17': {
      open: '191.76',
      high: '193.91',
      low: '191.76',
      close: '193.62',
      'adjusted close': '121.969477092883',
      volume: '3792400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-14': {
      open: '191.69',
      high: '193.4',
      low: '191.26',
      close: '191.76',
      'adjusted close': '120.797783944486',
      volume: '3826900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-13': {
      open: '192.18',
      high: '193.05',
      low: '191.37',
      close: '191.99',
      'adjusted close': '120.942670731653',
      volume: '3623200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-12': {
      open: '194.6',
      high: '194.75',
      low: '192.46',
      close: '192.95',
      'adjusted close': '121.547415582439',
      volume: '4241900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-11': {
      open: '193.18',
      high: '194.8',
      low: '193.15',
      close: '194.2',
      'adjusted close': '122.334843773567',
      volume: '4144000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-10': {
      open: '192.17',
      high: '193.54',
      low: '191.65',
      close: '192.62',
      'adjusted close': '121.339534539981',
      volume: '3438500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-07': {
      open: '190.14',
      high: '192.2',
      low: '190.11',
      close: '191.95',
      'adjusted close': '120.917473029537',
      volume: '4091300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-06': {
      open: '189.17',
      high: '190.18',
      low: '188.41',
      close: '189.7',
      'adjusted close': '119.500102285508',
      volume: '3216600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-05': {
      open: '189.01',
      high: '189.46',
      low: '186.94',
      close: '188.65',
      'adjusted close': '118.838662604961',
      volume: '4202100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-04': {
      open: '189.71',
      high: '190.75',
      low: '189.02',
      close: '189.36',
      'adjusted close': '119.285921817521',
      volume: '3990700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-12-03': {
      open: '190.76',
      high: '191.3',
      low: '188.36',
      close: '189.48',
      'adjusted close': '119.361514923869',
      volume: '3349600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-30': {
      open: '191.75',
      high: '192.0',
      low: '189.501',
      close: '190.07',
      'adjusted close': '119.733181030081',
      volume: '4936400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-29': {
      open: '192.75',
      high: '192.9',
      low: '190.2',
      close: '191.53',
      'adjusted close': '120.652897157318',
      volume: '4077900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-28': {
      open: '190.98',
      high: '192.04',
      low: '189.27',
      close: '191.98',
      'adjusted close': '120.936371306124',
      volume: '3603600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-27': {
      open: '192.08',
      high: '193.05',
      low: '191.0',
      close: '191.23',
      'adjusted close': '120.463914391448',
      volume: '3290900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-26': {
      open: '192.45',
      high: '193.37',
      low: '191.77',
      close: '192.88',
      'adjusted close': '121.503319603736',
      volume: '5210300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-23': {
      open: '191.0',
      high: '193.49',
      low: '190.8',
      close: '193.49',
      'adjusted close': '121.887584561006',
      volume: '3877600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-21': {
      open: '189.3',
      high: '190.89',
      low: '188.95',
      close: '190.29',
      'adjusted close': '119.87176839172',
      volume: '5189600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-20': {
      open: '189.88',
      high: '190.11',
      low: '188.25',
      close: '189.2',
      'adjusted close': '119.185131009057',
      volume: '3170200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-19': {
      open: '188.62',
      high: '190.39',
      low: '188.3',
      close: '190.35',
      'adjusted close': '119.909564944894',
      volume: '3588900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-16': {
      open: '186.33',
      high: '187.56',
      low: '185.1',
      close: '186.94',
      'adjusted close': '117.761460839498',
      volume: '4660800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-15': {
      open: '185.83',
      high: '187.2',
      low: '184.78',
      close: '185.85',
      'adjusted close': '117.074823456835',
      volume: '3411700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-14': {
      open: '189.14',
      high: '189.27',
      low: '185.28',
      close: '185.51',
      'adjusted close': '116.860642988848',
      volume: '4404400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-13': {
      open: '188.39',
      high: '190.88',
      low: '188.25',
      close: '188.32',
      'adjusted close': '118.630781562503',
      volume: '3406700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-12': {
      open: '190.03',
      high: '190.77',
      low: '187.407',
      close: '189.25',
      'adjusted close': '119.216628136702',
      volume: '3067400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-09': {
      open: '189.85',
      high: '191.64',
      low: '188.88',
      close: '189.64',
      'adjusted close': '119.462305732334',
      volume: '3671600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-08': {
      open: '191.4',
      high: '191.81',
      low: '190.09',
      close: '190.1',
      'adjusted close': '119.752079306668',
      volume: '3542200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-07': {
      open: '193.36',
      high: '193.68',
      low: '191.16',
      close: '191.16',
      'adjusted close': '120.419818412745',
      volume: '4746200',
      'dividend amount': '0.8500',
      'split coefficient': '1.0',
    },
    '2012-11-06': {
      open: '195.26',
      high: '196.75',
      low: '194.68',
      close: '195.07',
      'adjusted close': '122.338909316046',
      volume: '3281000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-05': {
      open: '192.36',
      high: '194.97',
      low: '192.01',
      close: '194.14',
      'adjusted close': '121.755656198376',
      volume: '2736200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-02': {
      open: '197.53',
      high: '198.0',
      low: '193.29',
      close: '193.43',
      'adjusted close': '121.310376936499',
      volume: '4260100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-11-01': {
      open: '194.96',
      high: '197.89',
      low: '194.55',
      close: '197.15',
      'adjusted close': '123.64338940718',
      volume: '3758800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-31': {
      open: '194.8',
      high: '196.41',
      low: '193.63',
      close: '194.53',
      'adjusted close': '122.000246215464',
      volume: '6052300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-26': {
      open: '191.49',
      high: '193.87',
      low: '191.47',
      close: '193.27',
      'adjusted close': '121.210032314104',
      volume: '4006500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-25': {
      open: '191.41',
      high: '192.44',
      low: '190.71',
      close: '191.6',
      'adjusted close': '120.162685317858',
      volume: '3249600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-24': {
      open: '192.44',
      high: '193.29',
      low: '190.56',
      close: '190.72',
      'adjusted close': '119.610789894686',
      volume: '3738000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-23': {
      open: '193.53',
      high: '194.0',
      low: '190.94',
      close: '191.25',
      'adjusted close': '119.943181456369',
      volume: '4427400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-22': {
      open: '193.71',
      high: '194.68',
      low: '192.44',
      close: '194.4',
      'adjusted close': '121.918716209768',
      volume: '4402500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-19': {
      open: '195.29',
      high: '196.08',
      low: '193.18',
      close: '193.36',
      'adjusted close': '121.266476164201',
      volume: '6620300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-18': {
      open: '199.91',
      high: '200.14',
      low: '194.07',
      close: '194.96',
      'adjusted close': '122.26992238815',
      volume: '9295900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-17': {
      open: '201.21',
      high: '203.41',
      low: '198.55',
      close: '200.63',
      'adjusted close': '125.825884944268',
      volume: '12673900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-16': {
      open: '209.94',
      high: '211.0',
      low: '209.474',
      close: '211.0',
      'adjusted close': '132.329470783236',
      volume: '5632100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-15': {
      open: '208.88',
      high: '209.4',
      low: '207.82',
      close: '208.93',
      'adjusted close': '131.031262231002',
      volume: '3060900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-12': {
      open: '205.64',
      high: '207.96',
      low: '205.56',
      close: '207.8',
      'adjusted close': '130.322578335338',
      volume: '2712000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-11': {
      open: '206.57',
      high: '207.27',
      low: '205.63',
      close: '205.76',
      'adjusted close': '129.043184399804',
      volume: '2910000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-10': {
      open: '207.82',
      high: '208.23',
      low: '205.05',
      close: '205.82',
      'adjusted close': '129.080813633202',
      volume: '4024500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-09': {
      open: '209.95',
      high: '210.74',
      low: '207.7',
      close: '207.99',
      'adjusted close': '130.441737574432',
      volume: '3580400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-08': {
      open: '210.02',
      high: '210.64',
      low: '209.21',
      close: '209.82',
      'adjusted close': '131.589429193074',
      volume: '1982600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-05': {
      open: '211.15',
      high: '211.79',
      low: '210.06',
      close: '210.59',
      'adjusted close': '132.072337688349',
      volume: '2858400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-04': {
      open: '210.28',
      high: '210.97',
      low: '208.53',
      close: '210.39',
      'adjusted close': '131.946906910355',
      volume: '2957500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-03': {
      open: '209.94',
      high: '211.305',
      low: '209.3',
      close: '210.51',
      'adjusted close': '132.022165377152',
      volume: '3192300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-02': {
      open: '210.96',
      high: '211.47',
      low: '208.5',
      close: '209.84',
      'adjusted close': '131.601972270873',
      volume: '3321000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-10-01': {
      open: '208.01',
      high: '211.75',
      low: '207.94',
      close: '210.47',
      'adjusted close': '131.997079221553',
      volume: '5456900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-28': {
      open: '205.66',
      high: '208.32',
      low: '205.31',
      close: '207.45',
      'adjusted close': '130.10307447385',
      volume: '4910900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-27': {
      open: '204.37',
      high: '206.9',
      low: '204.14',
      close: '205.91',
      'adjusted close': '129.137257483299',
      volume: '2771900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-26': {
      open: '205.05',
      high: '205.98',
      low: '203.9',
      close: '204.0',
      'adjusted close': '127.93939355346',
      volume: '3423300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-25': {
      open: '205.6',
      high: '207.32',
      low: '204.95',
      close: '204.98',
      'adjusted close': '128.554004365629',
      volume: '3345200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-24': {
      open: '205.02',
      high: '206.06',
      low: '204.9',
      close: '205.29',
      'adjusted close': '128.748422071519',
      volume: '3511100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-21': {
      open: '207.36',
      high: '207.94',
      low: '205.921',
      close: '205.98',
      'adjusted close': '129.181158255597',
      volume: '12771500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-20': {
      open: '205.85',
      high: '206.99',
      low: '205.3',
      close: '206.18',
      'adjusted close': '129.30658903359',
      volume: '3739100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-19': {
      open: '207.55',
      high: '207.57',
      low: '206.01',
      close: '206.43',
      'adjusted close': '129.463377506082',
      volume: '2605600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-18': {
      open: '206.88',
      high: '207.8798',
      low: '206.48',
      close: '207.07',
      'adjusted close': '129.864755995662',
      volume: '2476100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-17': {
      open: '205.55',
      high: '207.99',
      low: '205.55',
      close: '207.15',
      'adjusted close': '129.914928306859',
      volume: '3275100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-14': {
      open: '206.24',
      high: '207.65',
      low: '206.05',
      close: '206.81',
      'adjusted close': '129.70169598427',
      volume: '4057100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-13': {
      open: '204.38',
      high: '206.81',
      low: '203.46',
      close: '206.36',
      'adjusted close': '129.419476733785',
      volume: '3879600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-12': {
      open: '203.52',
      high: '204.65',
      low: '202.96',
      close: '203.77',
      'adjusted close': '127.795148158768',
      volume: '3284000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-11': {
      open: '200.55',
      high: '203.46',
      low: '200.51',
      close: '203.27',
      'adjusted close': '127.481571213784',
      volume: '3910600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-10': {
      open: '199.39',
      high: '201.82',
      low: '198.73',
      close: '200.95',
      'adjusted close': '126.026574189058',
      volume: '4208000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-07': {
      open: '199.12',
      high: '199.5',
      low: '198.08',
      close: '199.5',
      'adjusted close': '125.117201048604',
      volume: '3413700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-06': {
      open: '196.26',
      high: '199.46',
      low: '196.11',
      close: '199.1',
      'adjusted close': '124.866339492617',
      volume: '3931700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-05': {
      open: '194.41',
      high: '195.85',
      low: '193.89',
      close: '195.04',
      'adjusted close': '122.320094699347',
      volume: '3312500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-09-04': {
      open: '196.61',
      high: '197.17',
      low: '193.25',
      close: '194.54',
      'adjusted close': '122.006517754363',
      volume: '4514400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-31': {
      open: '194.31',
      high: '195.95',
      low: '193.46',
      close: '194.85',
      'adjusted close': '122.200935460254',
      volume: '3193300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-30': {
      open: '194.77',
      high: '195.47',
      low: '193.18',
      close: '193.37',
      'adjusted close': '121.272747703101',
      volume: '2740900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-29': {
      open: '195.13',
      high: '196.0399',
      low: '194.9',
      close: '195.08',
      'adjusted close': '122.345180854946',
      volume: '2141400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-28': {
      open: '195.56',
      high: '196.11',
      low: '194.5',
      close: '194.87',
      'adjusted close': '122.213478538053',
      volume: '2539200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-27': {
      open: '197.96',
      high: '198.3',
      low: '195.61',
      close: '195.69',
      'adjusted close': '122.727744727827',
      volume: '2498800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-24': {
      open: '194.96',
      high: '198.11',
      low: '194.2',
      close: '197.77',
      'adjusted close': '124.03222481896',
      volume: '2639500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-23': {
      open: '197.04',
      high: '197.37',
      low: '195.44',
      close: '195.7',
      'adjusted close': '122.734016266726',
      volume: '2757400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-22': {
      open: '198.64',
      high: '198.65',
      low: '196.905',
      close: '197.25',
      'adjusted close': '123.706104796177',
      volume: '3296100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-21': {
      open: '200.99',
      high: '201.0',
      low: '198.37',
      close: '198.65',
      'adjusted close': '124.584120242132',
      volume: '3111300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-20': {
      open: '200.69',
      high: '201.13',
      low: '200.03',
      close: '200.5',
      'adjusted close': '125.744354938572',
      volume: '2336000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-17': {
      open: '201.08',
      high: '202.0',
      low: '200.66',
      close: '201.22',
      'adjusted close': '126.195905739349',
      volume: '2551800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-16': {
      open: '198.77',
      high: '201.324',
      low: '198.12',
      close: '200.84',
      'adjusted close': '125.957587261162',
      volume: '2729500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-15': {
      open: '198.9',
      high: '199.33',
      low: '197.92',
      close: '198.4',
      'adjusted close': '124.42733176964',
      volume: '2111800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-14': {
      open: '198.88',
      high: '199.33',
      low: '197.72',
      close: '198.29',
      'adjusted close': '124.358344841743',
      volume: '2562300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-13': {
      open: '198.88',
      high: '199.98',
      low: '197.79',
      close: '199.01',
      'adjusted close': '124.80989564252',
      volume: '2418300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-10': {
      open: '197.87',
      high: '199.37',
      low: '197.24',
      close: '199.29',
      'adjusted close': '124.985498731711',
      volume: '2600500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-09': {
      open: '198.62',
      high: '199.46',
      low: '197.89',
      close: '198.42',
      'adjusted close': '124.439874847439',
      volume: '2160100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-08': {
      open: '198.27',
      high: '199.69',
      low: '198.16',
      close: '199.03',
      'adjusted close': '124.82243872032',
      volume: '2068300',
      'dividend amount': '0.8500',
      'split coefficient': '1.0',
    },
    '2012-08-07': {
      open: '199.43',
      high: '200.88',
      low: '198.8',
      close: '199.93',
      'adjusted close': '124.853663064606',
      volume: '3209400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-06': {
      open: '198.76',
      high: '199.94',
      low: '198.52',
      close: '198.76',
      'adjusted close': '124.123013408299',
      volume: '2337800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-03': {
      open: '196.48',
      high: '198.95',
      low: '196.16',
      close: '198.52',
      'adjusted close': '123.973136555723',
      volume: '3278100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-02': {
      open: '194.16',
      high: '196.6',
      low: '193.02',
      close: '194.45',
      'adjusted close': '121.431474930789',
      volume: '2812600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-08-01': {
      open: '196.96',
      high: '197.85',
      low: '194.72',
      close: '195.18',
      'adjusted close': '121.887350357374',
      volume: '2559300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-31': {
      open: '196.5',
      high: '197.58',
      low: '195.88',
      close: '195.98',
      'adjusted close': '122.386939865961',
      volume: '3507000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-30': {
      open: '196.32',
      high: '197.84',
      low: '195.92',
      close: '196.68',
      'adjusted close': '122.824080685974',
      volume: '2787000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-27': {
      open: '195.1',
      high: '197.41',
      low: '193.95',
      close: '196.39',
      'adjusted close': '122.642979489111',
      volume: '4177300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-26': {
      open: '193.49',
      high: '194.95',
      low: '192.57',
      close: '193.95',
      'adjusted close': '121.119231487923',
      volume: '3282900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-25': {
      open: '190.31',
      high: '192.77',
      low: '189.32',
      close: '191.08',
      'adjusted close': '119.326954125869',
      volume: '3833800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-24': {
      open: '190.92',
      high: '191.32',
      low: '188.56',
      close: '190.34',
      'adjusted close': '118.864833830426',
      volume: '3597100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-23': {
      open: '189.78',
      high: '191.3',
      low: '188.2',
      close: '190.83',
      'adjusted close': '119.170832404436',
      volume: '3904500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-20': {
      open: '194.09',
      high: '194.9',
      low: '192.17',
      close: '192.45',
      'adjusted close': '120.182501159323',
      volume: '4789700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-19': {
      open: '193.4',
      high: '196.85',
      low: '192.97',
      close: '195.34',
      'adjusted close': '121.987268259092',
      volume: '10395400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-18': {
      open: '184.15',
      high: '188.59',
      low: '183.55',
      close: '188.25',
      'adjusted close': '117.559656239244',
      volume: '8019500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-17': {
      open: '185.73',
      high: '186.29',
      low: '183.2',
      close: '183.65',
      'adjusted close': '114.687016564872',
      volume: '5158600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-16': {
      open: '185.58',
      high: '186.1',
      low: '184.58',
      close: '184.79',
      'adjusted close': '115.398931614608',
      volume: '3144400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-13': {
      open: '183.46',
      high: '186.33',
      low: '183.03',
      close: '186.01',
      'adjusted close': '116.160805615202',
      volume: '3933000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-12': {
      open: '184.25',
      high: '184.39',
      low: '181.85',
      close: '183.09',
      'adjusted close': '114.337303908862',
      volume: '4931300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-11': {
      open: '186.22',
      high: '187.36',
      low: '183.51',
      close: '185.25',
      'adjusted close': '115.686195582045',
      volume: '5456100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-10': {
      open: '190.3',
      high: '191.14',
      low: '185.6',
      close: '186.26',
      'adjusted close': '116.316927336636',
      volume: '4690300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-09': {
      open: '190.76',
      high: '190.9991',
      low: '188.05',
      close: '189.67',
      'adjusted close': '118.446427616985',
      volume: '3988100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-06': {
      open: '193.92',
      high: '193.94',
      low: '189.74',
      close: '191.41',
      'adjusted close': '119.533034798161',
      volume: '4952900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-05': {
      open: '194.88',
      high: '196.85',
      low: '193.63',
      close: '195.29',
      'adjusted close': '121.956043914805',
      volume: '2690200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-03': {
      open: '195.46',
      high: '196.34',
      low: '194.91',
      close: '195.93',
      'adjusted close': '122.355715521674',
      volume: '1450400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-07-02': {
      open: '196.36',
      high: '197.2',
      low: '194.85',
      close: '195.83',
      'adjusted close': '122.293266833101',
      volume: '2827000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-29': {
      open: '194.55',
      high: '195.81',
      low: '192.41',
      close: '195.58',
      'adjusted close': '122.137145111667',
      volume: '5259600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-28': {
      open: '191.23',
      high: '192.08',
      low: '188.9',
      close: '191.4',
      'adjusted close': '119.526789929303',
      volume: '3967300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-27': {
      open: '192.14',
      high: '193.7',
      low: '190.9',
      close: '193.0',
      'adjusted close': '120.525968946476',
      volume: '2762700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-26': {
      open: '193.54',
      high: '193.68',
      low: '190.75',
      close: '191.95',
      'adjusted close': '119.870257716457',
      volume: '3435900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-25': {
      open: '192.48',
      high: '193.39',
      low: '191.68',
      close: '192.86',
      'adjusted close': '120.438540782474',
      volume: '3211600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-22': {
      open: '193.87',
      high: '194.88',
      low: '193.22',
      close: '193.7',
      'adjusted close': '120.963109766489',
      volume: '8823000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-21': {
      open: '199.07',
      high: '199.45',
      low: '193.19',
      close: '193.39',
      'adjusted close': '120.769518831912',
      volume: '4316600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-20': {
      open: '198.7',
      high: '199.74',
      low: '197.5',
      close: '198.78',
      'adjusted close': '124.135503146013',
      volume: '3378200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-19': {
      open: '198.83',
      high: '199.99',
      low: '198.8',
      close: '198.93',
      'adjusted close': '124.229176178873',
      volume: '3297600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-18': {
      open: '197.57',
      high: '199.64',
      low: '197.17',
      close: '198.29',
      'adjusted close': '123.829504572004',
      volume: '3378900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-15': {
      open: '196.08',
      high: '199.27',
      low: '195.4',
      close: '199.1',
      'adjusted close': '124.335338949448',
      volume: '6389000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-14': {
      open: '193.0',
      high: '195.83',
      low: '192.74',
      close: '195.1',
      'adjusted close': '121.837391406516',
      volume: '3585000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-13': {
      open: '194.36',
      high: '195.19',
      low: '192.345',
      close: '193.1',
      'adjusted close': '120.58841763505',
      volume: '3247700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-12': {
      open: '192.14',
      high: '194.67',
      low: '192.14',
      close: '194.55',
      'adjusted close': '121.493923619362',
      volume: '3023700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-11': {
      open: '196.6',
      high: '196.7',
      low: '192.37',
      close: '192.51',
      'adjusted close': '120.219970372467',
      volume: '3273300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-08': {
      open: '193.71',
      high: '195.14',
      low: '192.7',
      close: '195.14',
      'adjusted close': '121.862370881945',
      volume: '2479200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-07': {
      open: '195.28',
      high: '195.8299',
      low: '193.94',
      close: '194.44',
      'adjusted close': '121.425230061932',
      volume: '3701100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-06': {
      open: '190.78',
      high: '194.0',
      low: '190.02',
      close: '193.99',
      'adjusted close': '121.144210963352',
      volume: '3641300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-05': {
      open: '187.92',
      high: '189.85',
      low: '187.56',
      close: '189.2',
      'adjusted close': '118.152918780691',
      volume: '3556500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-04': {
      open: '188.34',
      high: '189.38',
      low: '187.0',
      close: '188.54',
      'adjusted close': '117.740757436107',
      volume: '4624500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-06-01': {
      open: '190.12',
      high: '191.72',
      low: '188.6',
      close: '189.08',
      'adjusted close': '118.077980354403',
      volume: '5206400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-31': {
      open: '194.1',
      high: '194.93',
      low: '192.0',
      close: '192.9',
      'adjusted close': '120.463520257903',
      volume: '9287500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-30': {
      open: '194.8',
      high: '195.47',
      low: '193.77',
      close: '194.53',
      'adjusted close': '121.481433881648',
      volume: '3602500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-29': {
      open: '195.22',
      high: '198.08',
      low: '195.22',
      close: '196.46',
      'adjusted close': '122.686693571113',
      volume: '3618500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-25': {
      open: '195.11',
      high: '196.0',
      low: '194.0',
      close: '194.3',
      'adjusted close': '121.337801897929',
      volume: '2901900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-24': {
      open: '195.99',
      high: '196.28',
      low: '194.0',
      close: '196.09',
      'adjusted close': '122.455633423391',
      volume: '3569300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-23': {
      open: '195.53',
      high: '196.49',
      low: '193.2',
      close: '196.12',
      'adjusted close': '122.474368029963',
      volume: '4100000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-22': {
      open: '198.04',
      high: '198.26',
      low: '196.06',
      close: '196.82',
      'adjusted close': '122.911508849976',
      volume: '3567100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-21': {
      open: '195.98',
      high: '197.93',
      low: '194.63',
      close: '197.76',
      'adjusted close': '123.498526522566',
      volume: '3831700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-18': {
      open: '198.78',
      high: '198.78',
      low: '195.16',
      close: '195.88',
      'adjusted close': '122.324491177387',
      volume: '5737100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-17': {
      open: '199.67',
      high: '200.79',
      low: '197.64',
      close: '197.89',
      'adjusted close': '123.579709817711',
      volume: '5626300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-16': {
      open: '199.42',
      high: '201.35',
      low: '198.18',
      close: '199.73',
      'adjusted close': '124.72876568746',
      volume: '5001000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-15': {
      open: '199.44',
      high: '201.4679',
      low: '198.76',
      close: '199.04',
      'adjusted close': '124.297869736304',
      volume: '2987400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-14': {
      open: '199.92',
      high: '200.71',
      low: '198.56',
      close: '199.44',
      'adjusted close': '124.547664490597',
      volume: '3029200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-11': {
      open: '199.96',
      high: '202.12',
      low: '199.95',
      close: '201.17',
      'adjusted close': '125.628026802915',
      volume: '2391500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-10': {
      open: '201.0',
      high: '203.25',
      low: '200.02',
      close: '200.6',
      'adjusted close': '125.272069278047',
      volume: '3830500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-09': {
      open: '199.99',
      high: '203.0',
      low: '199.06',
      close: '201.23',
      'adjusted close': '125.665496016059',
      volume: '3742500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-08': {
      open: '202.44',
      high: '203.06',
      low: '199.718',
      close: '201.48',
      'adjusted close': '125.821617737492',
      volume: '3881500',
      'dividend amount': '0.8500',
      'split coefficient': '1.0',
    },
    '2012-05-07': {
      open: '203.96',
      high: '204.77',
      low: '202.9',
      close: '203.75',
      'adjusted close': '126.704663737528',
      volume: '3373400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-04': {
      open: '206.47',
      high: '207.11',
      low: '204.4',
      close: '204.99',
      'adjusted close': '127.475774329109',
      volume: '4023600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-03': {
      open: '208.27',
      high: '208.92',
      low: '206.55',
      close: '207.24',
      'adjusted close': '128.874966934799',
      volume: '2826600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-02': {
      open: '206.97',
      high: '208.27',
      low: '206.91',
      close: '208.06',
      'adjusted close': '129.384894906651',
      volume: '2360300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-05-01': {
      open: '207.18',
      high: '208.929',
      low: '206.71',
      close: '208.0',
      'adjusted close': '129.347583103832',
      volume: '2715900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-30': {
      open: '206.71',
      high: '207.96',
      low: '206.32',
      close: '207.08',
      'adjusted close': '128.77546879395',
      volume: '2773200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-27': {
      open: '206.47',
      high: '208.17',
      low: '205.91',
      close: '206.81',
      'adjusted close': '128.607565681267',
      volume: '3496900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-26': {
      open: '203.4',
      high: '206.21',
      low: '203.4',
      close: '205.58',
      'adjusted close': '127.84267372349',
      volume: '3302700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-25': {
      open: '202.02',
      high: '203.69',
      low: '201.8',
      close: '203.57',
      'adjusted close': '126.592728329073',
      volume: '4003400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-24': {
      open: '199.06',
      high: '202.48',
      low: '198.87',
      close: '200.0',
      'adjusted close': '124.372676061377',
      volume: '5626300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-23': {
      open: '197.89',
      high: '199.1',
      low: '196.791',
      close: '198.62',
      'adjusted close': '123.514504596554',
      volume: '3618800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-20': {
      open: '199.83',
      high: '200.12',
      low: '199.09',
      close: '199.6',
      'adjusted close': '124.123930709255',
      volume: '4758200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-19': {
      open: '200.03',
      high: '201.26',
      low: '197.521',
      close: '199.51',
      'adjusted close': '124.067963005027',
      volume: '5762900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-18': {
      open: '202.43',
      high: '204.07',
      low: '200.0',
      close: '200.13',
      'adjusted close': '124.453518300817',
      volume: '10153500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-17': {
      open: '204.57',
      high: '207.64',
      low: '204.15',
      close: '207.45',
      'adjusted close': '129.005558244664',
      volume: '6065500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-16': {
      open: '204.74',
      high: '205.97',
      low: '202.72',
      close: '202.72',
      'adjusted close': '126.064144455812',
      volume: '3826600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-13': {
      open: '204.32',
      high: '204.6499',
      low: '202.8',
      close: '202.8',
      'adjusted close': '126.113893526237',
      volume: '3767000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-12': {
      open: '202.8',
      high: '205.56',
      low: '202.64',
      close: '205.32',
      'adjusted close': '127.68098924461',
      volume: '2929600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-11': {
      open: '203.99',
      high: '204.32',
      low: '202.4593',
      close: '202.58',
      'adjusted close': '125.977083582569',
      volume: '3366200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-10': {
      open: '204.94',
      high: '205.74',
      low: '202.17',
      close: '202.33',
      'adjusted close': '125.821617737492',
      volume: '4132900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-09': {
      open: '204.4',
      high: '206.17',
      low: '203.37',
      close: '204.94',
      'adjusted close': '127.444681160093',
      volume: '3326900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-05': {
      open: '205.15',
      high: '205.68',
      low: '204.46',
      close: '205.47',
      'adjusted close': '127.774268751656',
      volume: '3809000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-04': {
      open: '206.22',
      high: '206.9802',
      low: '205.19',
      close: '206.05',
      'adjusted close': '128.134949512234',
      volume: '4404000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-03': {
      open: '209.31',
      high: '210.69',
      low: '208.54',
      close: '209.5',
      'adjusted close': '130.280378174293',
      volume: '4091500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-04-02': {
      open: '208.96',
      high: '209.69',
      low: '208.09',
      close: '209.47',
      'adjusted close': '130.261722272884',
      volume: '3387900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-30': {
      open: '208.74',
      high: '209.12',
      low: '208.012',
      close: '208.65',
      'adjusted close': '129.751794301032',
      volume: '3215200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-29': {
      open: '206.09',
      high: '208.5',
      low: '206.02',
      close: '208.27',
      'adjusted close': '129.515486216515',
      volume: '3403700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-28': {
      open: '207.44',
      high: '208.69',
      low: '206.27',
      close: '207.29',
      'adjusted close': '128.906060103815',
      volume: '3265700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-27': {
      open: '208.46',
      high: '208.66',
      low: '207.0',
      close: '207.18',
      'adjusted close': '128.837655131981',
      volume: '2704500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-26': {
      open: '206.45',
      high: '207.92',
      low: '206.32',
      close: '207.77',
      'adjusted close': '129.204554526362',
      volume: '3418200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-23': {
      open: '205.89',
      high: '205.96',
      low: '204.56',
      close: '205.48',
      'adjusted close': '127.780487385459',
      volume: '3036200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-22': {
      open: '204.0',
      high: '205.99',
      low: '203.96',
      close: '205.49',
      'adjusted close': '127.786706019262',
      volume: '3307800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-21': {
      open: '204.32',
      high: '205.77',
      low: '204.3',
      close: '204.69',
      'adjusted close': '127.289215315017',
      volume: '3329900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-20': {
      open: '204.74',
      high: '205.0',
      low: '203.702',
      close: '204.25',
      'adjusted close': '127.015595427682',
      volume: '3695100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-19': {
      open: '205.71',
      high: '206.53',
      low: '204.5',
      close: '205.72',
      'adjusted close': '127.929734596733',
      volume: '3763200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-16': {
      open: '207.03',
      high: '207.52',
      low: '206.01',
      close: '206.01',
      'adjusted close': '128.110074977022',
      volume: '9637000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-15': {
      open: '204.94',
      high: '206.18',
      low: '204.74',
      close: '206.0',
      'adjusted close': '128.103856343219',
      volume: '3621100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-14': {
      open: '203.78',
      high: '205.15',
      low: '203.64',
      close: '204.72',
      'adjusted close': '127.307871216426',
      volume: '4602300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-13': {
      open: '201.72',
      high: '203.9',
      low: '201.6107',
      close: '203.78',
      'adjusted close': '126.723319638937',
      volume: '4866000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-12': {
      open: '200.93',
      high: '201.57',
      low: '200.23',
      close: '201.0',
      'adjusted close': '124.994539441684',
      volume: '3119700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-09': {
      open: '199.98',
      high: '201.1',
      low: '199.72',
      close: '200.62',
      'adjusted close': '124.758231357168',
      volume: '3178200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-08': {
      open: '199.24',
      high: '199.99',
      low: '198.65',
      close: '199.81',
      'adjusted close': '124.254522019119',
      volume: '3145800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-07': {
      open: '198.09',
      high: '198.57',
      low: '197.31',
      close: '197.77',
      'adjusted close': '122.985920723293',
      volume: '3346900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-06': {
      open: '199.14',
      high: '199.64',
      low: '196.812',
      close: '197.26',
      'adjusted close': '122.668770399336',
      volume: '5214700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-05': {
      open: '198.36',
      high: '201.19',
      low: '198.3',
      close: '200.66',
      'adjusted close': '124.78310589238',
      volume: '6938000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-02': {
      open: '197.1',
      high: '198.89',
      low: '197.06',
      close: '198.81',
      'adjusted close': '123.632658638812',
      volume: '3727400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-03-01': {
      open: '197.23',
      high: '198.47',
      low: '196.93',
      close: '197.53',
      'adjusted close': '122.836673512019',
      volume: '3560300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-29': {
      open: '197.86',
      high: '199.17',
      low: '196.45',
      close: '196.73',
      'adjusted close': '122.339182807774',
      volume: '5117100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-28': {
      open: '197.75',
      high: '198.96',
      low: '197.4322',
      close: '197.98',
      'adjusted close': '123.116512033157',
      volume: '3167400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-27': {
      open: '196.78',
      high: '198.71',
      low: '196.1',
      close: '197.53',
      'adjusted close': '122.836673512019',
      volume: '3758400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-24': {
      open: '198.38',
      high: '198.56',
      low: '196.61',
      close: '197.76',
      'adjusted close': '122.97970208949',
      volume: '3353200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-23': {
      open: '193.87',
      high: '199.23',
      low: '193.28',
      close: '197.61',
      'adjusted close': '122.886422582444',
      volume: '8032700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-22': {
      open: '193.03',
      high: '194.74',
      low: '192.75',
      close: '193.87',
      'adjusted close': '120.560653540096',
      volume: '3275800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-21': {
      open: '193.73',
      high: '194.24',
      low: '192.46',
      close: '193.39',
      'adjusted close': '120.262159117549',
      volume: '3293600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-17': {
      open: '193.71',
      high: '193.86',
      low: '192.3',
      close: '193.42',
      'adjusted close': '120.280815018958',
      volume: '3746500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-16': {
      open: '192.61',
      high: '193.25',
      low: '192.0',
      close: '193.02',
      'adjusted close': '120.032069666835',
      volume: '3525500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-15': {
      open: '192.31',
      high: '193.44',
      low: '191.26',
      close: '192.25',
      'adjusted close': '119.553234863999',
      volume: '4020900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-14': {
      open: '191.76',
      high: '192.6',
      low: '190.831',
      close: '192.22',
      'adjusted close': '119.53457896259',
      volume: '3398100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-13': {
      open: '193.31',
      high: '193.69',
      low: '191.74',
      close: '192.62',
      'adjusted close': '119.783324314713',
      volume: '2458800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-10': {
      open: '191.76',
      high: '192.43',
      low: '191.28',
      close: '192.42',
      'adjusted close': '119.658951638651',
      volume: '3391100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-09': {
      open: '193.03',
      high: '194.46',
      low: '192.55',
      close: '193.13',
      'adjusted close': '120.100474638669',
      volume: '3876400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-08': {
      open: '192.78',
      high: '193.58',
      low: '191.73',
      close: '192.95',
      'adjusted close': '119.988539230214',
      volume: '3803800',
      'dividend amount': '0.7500',
      'split coefficient': '1.0',
    },
    '2012-02-07': {
      open: '192.45',
      high: '194.14',
      low: '191.97',
      close: '193.35',
      'adjusted close': '119.771729789168',
      volume: '3433000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-06': {
      open: '192.48',
      high: '193.76',
      low: '192.0',
      close: '192.82',
      'adjusted close': '119.44341834987',
      volume: '3639800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-03': {
      open: '192.93',
      high: '194.13',
      low: '192.54',
      close: '193.64',
      'adjusted close': '119.951371897463',
      volume: '4521700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-02': {
      open: '192.72',
      high: '193.33',
      low: '191.33',
      close: '191.53',
      'adjusted close': '118.64432069573',
      volume: '3907300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-02-01': {
      open: '193.21',
      high: '194.81',
      low: '192.41',
      close: '192.62',
      'adjusted close': '119.319527240701',
      volume: '5088800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-31': {
      open: '193.09',
      high: '193.1',
      low: '191.0',
      close: '192.6',
      'adjusted close': '119.307138129784',
      volume: '4826800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-30': {
      open: '189.39',
      high: '192.73',
      low: '188.22',
      close: '192.5',
      'adjusted close': '119.2451925752',
      volume: '4359000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-27': {
      open: '190.01',
      high: '191.77',
      low: '189.81',
      close: '190.46',
      'adjusted close': '117.981503261675',
      volume: '3360400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-26': {
      open: '191.79',
      high: '192.79',
      low: '190.47',
      close: '190.98',
      'adjusted close': '118.303620145515',
      volume: '4004700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-25': {
      open: '191.33',
      high: '192.2399',
      low: '189.61',
      close: '191.73',
      'adjusted close': '118.768211804899',
      volume: '4359700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-24': {
      open: '188.63',
      high: '192.305',
      low: '188.5241',
      close: '191.93',
      'adjusted close': '118.892102914068',
      volume: '5345700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-23': {
      open: '187.91',
      high: '190.52',
      low: '187.67',
      close: '189.98',
      'adjusted close': '117.68416459967',
      volume: '5751700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-20': {
      open: '185.77',
      high: '188.97',
      low: '184.75',
      close: '188.52',
      'adjusted close': '116.779759502736',
      volume: '12849700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-19': {
      open: '181.79',
      high: '182.3599',
      low: '180.35',
      close: '180.52',
      'adjusted close': '111.824115135974',
      volume: '8567200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-18': {
      open: '179.83',
      high: '181.6',
      low: '179.5',
      close: '181.07',
      'adjusted close': '112.164815686189',
      volume: '4600600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-17': {
      open: '180.36',
      high: '182.0',
      low: '179.32',
      close: '180.0',
      'adjusted close': '111.501998252135',
      volume: '6003400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-13': {
      open: '179.48',
      high: '179.61',
      low: '177.3472',
      close: '179.16',
      'adjusted close': '110.981655593625',
      volume: '5279200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-12': {
      open: '181.86',
      high: '181.91',
      low: '178.38',
      close: '180.55',
      'adjusted close': '111.84269880235',
      volume: '6881000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-11': {
      open: '180.73',
      high: '182.81',
      low: '180.5',
      close: '182.32',
      'adjusted close': '112.939135118496',
      volume: '4110800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-10': {
      open: '183.23',
      high: '183.72',
      low: '181.2',
      close: '181.31',
      'adjusted close': '112.313485017192',
      volume: '5161000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-09': {
      open: '182.2',
      high: '182.27',
      low: '180.27',
      close: '181.59',
      'adjusted close': '112.486932570029',
      volume: '5201200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-06': {
      open: '184.39',
      high: '184.48',
      low: '182.31',
      close: '182.54',
      'adjusted close': '113.075415338581',
      volume: '4897100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-05': {
      open: '184.81',
      high: '185.03',
      low: '183.1',
      close: '184.66',
      'adjusted close': '114.388661095773',
      volume: '4463100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-04': {
      open: '185.57',
      high: '186.33',
      low: '184.94',
      close: '185.54',
      'adjusted close': '114.933781976117',
      volume: '4346700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2012-01-03': {
      open: '186.73',
      high: '188.71',
      low: '186.0',
      close: '186.3',
      'adjusted close': '115.404568190959',
      volume: '5646000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-30': {
      open: '186.33',
      high: '186.48',
      low: '183.85',
      close: '183.88',
      'adjusted close': '113.905485770014',
      volume: '3530900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-29': {
      open: '184.07',
      high: '186.23',
      low: '184.01',
      close: '186.18',
      'adjusted close': '115.330233525458',
      volume: '2941600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-28': {
      open: '185.19',
      high: '185.4',
      low: '183.34',
      close: '183.99',
      'adjusted close': '113.973625880057',
      volume: '2872000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-27': {
      open: '184.97',
      high: '185.85',
      low: '184.39',
      close: '184.95',
      'adjusted close': '114.568303204068',
      volume: '2775200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-23': {
      open: '183.11',
      high: '184.85',
      low: '181.89',
      close: '184.75',
      'adjusted close': '114.444412094899',
      volume: '3052300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-22': {
      open: '182.71',
      high: '182.8',
      low: '181.0',
      close: '182.04',
      'adjusted close': '112.765687565659',
      volume: '5052000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-21': {
      open: '183.22',
      high: '183.5',
      low: '179.04',
      close: '181.47',
      'adjusted close': '112.412597904527',
      volume: '9282500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-20': {
      open: '185.5',
      high: '187.33',
      low: '184.755',
      close: '187.24',
      'adjusted close': '115.986856404054',
      volume: '4740100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-19': {
      open: '184.51',
      high: '184.69',
      low: '182.25',
      close: '182.89',
      'adjusted close': '113.292224779627',
      volume: '5039900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-16': {
      open: '188.01',
      high: '188.01',
      low: '181.91',
      close: '183.57',
      'adjusted close': '113.713454550802',
      volume: '11849400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-15': {
      open: '190.48',
      high: '191.2',
      low: '186.89',
      close: '187.48',
      'adjusted close': '116.135525735057',
      volume: '4474500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-14': {
      open: '189.84',
      high: '190.28',
      low: '188.0',
      close: '188.72',
      'adjusted close': '116.903650611905',
      volume: '5057700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-13': {
      open: '193.46',
      high: '194.3',
      low: '190.64',
      close: '191.15',
      'adjusted close': '118.408927588309',
      volume: '5008400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-12': {
      open: '193.64',
      high: '193.9',
      low: '191.22',
      close: '192.18',
      'adjusted close': '119.046966800529',
      volume: '3796100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-09': {
      open: '192.91',
      high: '194.87',
      low: '192.4',
      close: '194.56',
      'adjusted close': '120.521270999641',
      volume: '4847900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-08': {
      open: '192.48',
      high: '193.8',
      low: '191.07',
      close: '191.58',
      'adjusted close': '118.675293473022',
      volume: '4369000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-07': {
      open: '191.99',
      high: '194.9',
      low: '191.28',
      close: '194.05',
      'adjusted close': '120.20534867126',
      volume: '5081700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-06': {
      open: '190.65',
      high: '193.53',
      low: '190.32',
      close: '192.94',
      'adjusted close': '119.517753015371',
      volume: '4144900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-05': {
      open: '191.18',
      high: '193.61',
      low: '190.38',
      close: '190.84',
      'adjusted close': '118.216896369097',
      volume: '5699400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-02': {
      open: '189.92',
      high: '191.33',
      low: '189.45',
      close: '189.66',
      'adjusted close': '117.485938824999',
      volume: '4962200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-12-01': {
      open: '187.01',
      high: '189.99',
      low: '186.77',
      close: '189.45',
      'adjusted close': '117.355853160372',
      volume: '4858800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-30': {
      open: '184.57',
      high: '188.35',
      low: '184.21',
      close: '188.0',
      'adjusted close': '116.457642618896',
      volume: '7836800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-29': {
      open: '182.02',
      high: '183.31',
      low: '180.84',
      close: '180.94',
      'adjusted close': '112.084286465229',
      volume: '3661600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-28': {
      open: '182.71',
      high: '182.93',
      low: '180.67',
      close: '182.21',
      'adjusted close': '112.870995008453',
      volume: '4524200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-25': {
      open: '178.01',
      high: '179.56',
      low: '177.06',
      close: '177.06',
      'adjusted close': '109.68079894735',
      volume: '2232800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-23': {
      open: '180.67',
      high: '181.49',
      low: '177.52',
      close: '177.95',
      'adjusted close': '110.232114383152',
      volume: '4800900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-22': {
      open: '182.22',
      high: '182.98',
      low: '180.29',
      close: '181.31',
      'adjusted close': '112.313485017192',
      volume: '4178600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-21': {
      open: '183.37',
      high: '183.96',
      low: '180.0',
      close: '181.48',
      'adjusted close': '112.418792459986',
      volume: '5145100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-18': {
      open: '186.71',
      high: '186.97',
      low: '184.66',
      close: '185.24',
      'adjusted close': '114.747945312363',
      volume: '4440900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-17': {
      open: '186.81',
      high: '188.83',
      low: '183.39',
      close: '185.73',
      'adjusted close': '115.051478529828',
      volume: '5840500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-16': {
      open: '187.82',
      high: '189.9',
      low: '186.3',
      close: '186.62',
      'adjusted close': '115.60279396563',
      volume: '4616800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-15': {
      open: '187.49',
      high: '189.97',
      low: '185.64',
      close: '188.75',
      'adjusted close': '116.92223427828',
      volume: '4585300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-14': {
      open: '189.17',
      high: '189.84',
      low: '186.85',
      close: '187.35',
      'adjusted close': '116.054996514097',
      volume: '6038600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-11': {
      open: '185.27',
      high: '187.83',
      low: '185.14',
      close: '187.38',
      'adjusted close': '116.073580180472',
      volume: '3665300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-10': {
      open: '183.58',
      high: '184.77',
      low: '181.87',
      close: '183.35',
      'adjusted close': '113.577174330716',
      volume: '3881600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-09': {
      open: '184.2',
      high: '185.24',
      low: '181.16',
      close: '182.24',
      'adjusted close': '112.889578674828',
      volume: '5494800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-08': {
      open: '187.48',
      high: '187.88',
      low: '185.51',
      close: '187.25',
      'adjusted close': '115.993050959512',
      volume: '4330600',
      'dividend amount': '0.7500',
      'split coefficient': '1.0',
    },
    '2011-11-07': {
      open: '186.22',
      high: '187.73',
      low: '184.75',
      close: '187.32',
      'adjusted close': '115.573501626254',
      volume: '3530800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-04': {
      open: '186.27',
      high: '187.14',
      low: '184.74',
      close: '186.38',
      'adjusted close': '114.993536371457',
      volume: '3237600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-03': {
      open: '185.13',
      high: '187.78',
      low: '184.02',
      close: '187.3',
      'adjusted close': '115.561161939982',
      volume: '4953500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-02': {
      open: '184.0',
      high: '185.25',
      low: '183.03',
      close: '183.92',
      'adjusted close': '113.475754959965',
      volume: '4136600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-11-01': {
      open: '181.55',
      high: '183.26',
      low: '180.74',
      close: '181.35',
      'adjusted close': '111.890105273976',
      volume: '5881200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-31': {
      open: '185.59',
      high: '186.91',
      low: '184.63',
      close: '184.63',
      'adjusted close': '113.913813822632',
      volume: '4710000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-28': {
      open: '185.57',
      high: '188.07',
      low: '185.28',
      close: '187.45',
      'adjusted close': '115.653709587024',
      volume: '5009800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-27': {
      open: '184.99',
      high: '187.0',
      low: '183.18',
      close: '186.34',
      'adjusted close': '114.968856998912',
      volume: '7057800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-26': {
      open: '181.74',
      high: '182.28',
      low: '179.03',
      close: '181.97',
      'adjusted close': '112.272635548417',
      volume: '5387700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-25': {
      open: '181.66',
      high: '182.97',
      low: '180.01',
      close: '180.36',
      'adjusted close': '111.279290803498',
      volume: '5373100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-24': {
      open: '181.51',
      high: '183.39',
      low: '180.62',
      close: '182.25',
      'adjusted close': '112.445391156229',
      volume: '5890600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-21': {
      open: '179.11',
      high: '181.67',
      low: '178.75',
      close: '181.63',
      'adjusted close': '112.062860881788',
      volume: '8054200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-20': {
      open: '178.13',
      high: '179.24',
      low: '176.17',
      close: '177.25',
      'adjusted close': '109.360469588157',
      volume: '7513800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-19': {
      open: '179.68',
      high: '179.81',
      low: '177.01',
      close: '177.39',
      'adjusted close': '109.446847392063',
      volume: '6708300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-18': {
      open: '178.0',
      high: '179.38',
      low: '176.25',
      close: '178.9',
      'adjusted close': '110.378493705621',
      volume: '15729400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-17': {
      open: '189.71',
      high: '190.16',
      low: '185.9',
      close: '186.59',
      'adjusted close': '115.123103077316',
      volume: '8790300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-14': {
      open: '188.19',
      high: '190.53',
      low: '187.7314',
      close: '190.53',
      'adjusted close': '117.554021272957',
      volume: '5368200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-13': {
      open: '184.97',
      high: '187.5',
      low: '184.5',
      close: '186.82',
      'adjusted close': '115.265009469447',
      volume: '4399200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-12': {
      open: '186.08',
      high: '188.0',
      low: '185.65',
      close: '186.12',
      'adjusted close': '114.833120449917',
      volume: '5338200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-11': {
      open: '184.59',
      high: '186.0',
      low: '184.11',
      close: '185.0',
      'adjusted close': '114.142098018669',
      volume: '5330500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-10': {
      open: '183.0',
      high: '186.63',
      low: '182.9',
      close: '186.62',
      'adjusted close': '115.141612606724',
      volume: '5784800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-07': {
      open: '182.32',
      high: '183.72',
      low: '181.64',
      close: '182.39',
      'adjusted close': '112.531768960135',
      volume: '6842600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-06': {
      open: '176.81',
      high: '181.99',
      low: '175.66',
      close: '181.69',
      'adjusted close': '112.099879940605',
      volume: '7180900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-05': {
      open: '174.57',
      high: '177.2961',
      low: '172.68',
      close: '176.85',
      'adjusted close': '109.113675862711',
      volume: '5851700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-04': {
      open: '171.54',
      high: '175.07',
      low: '168.88',
      close: '174.74',
      'adjusted close': '107.811838960985',
      volume: '9200000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-10-03': {
      open: '174.36',
      high: '177.92',
      low: '173.04',
      close: '173.29',
      'adjusted close': '106.917211706244',
      volume: '9042600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-30': {
      open: '176.01',
      high: '178.27',
      low: '174.75',
      close: '174.87',
      'adjusted close': '107.892046921755',
      volume: '7807400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-29': {
      open: '179.69',
      high: '180.91',
      low: '175.53',
      close: '179.17',
      'adjusted close': '110.545079470297',
      volume: '6944300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-28': {
      open: '177.97',
      high: '180.75',
      low: '177.08',
      close: '177.55',
      'adjusted close': '109.545564882242',
      volume: '7732200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-27': {
      open: '176.82',
      high: '179.62',
      low: '176.43',
      close: '177.71',
      'adjusted close': '109.64428237242',
      volume: '7638800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-26': {
      open: '170.96',
      high: '174.7',
      low: '169.86',
      close: '174.51',
      'adjusted close': '107.669932568854',
      volume: '6745700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-23': {
      open: '166.99',
      high: '169.54',
      low: '165.76',
      close: '169.34',
      'adjusted close': '104.480123667467',
      volume: '5586600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-22': {
      open: '168.33',
      high: '169.97',
      low: '166.5',
      close: '168.62',
      'adjusted close': '104.035894961665',
      volume: '8195600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-21': {
      open: '175.0',
      high: '177.67',
      low: '172.62',
      close: '173.02',
      'adjusted close': '106.750625941568',
      volume: '7043100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-20': {
      open: '173.57',
      high: '177.43',
      low: '172.7',
      close: '174.72',
      'adjusted close': '107.799499274713',
      volume: '6209600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-19': {
      open: '169.5',
      high: '173.87',
      low: '169.43',
      close: '173.13',
      'adjusted close': '106.818494216066',
      volume: '4755600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-16': {
      open: '171.33',
      high: '172.99',
      low: '169.74',
      close: '172.99',
      'adjusted close': '106.73211641216',
      volume: '11048100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-15': {
      open: '168.64',
      high: '170.16',
      low: '167.23',
      close: '170.09',
      'adjusted close': '104.942861902678',
      volume: '5464400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-14': {
      open: '164.01',
      high: '169.66',
      low: '161.99',
      close: '167.24',
      'adjusted close': '103.184456608877',
      volume: '6980700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-13': {
      open: '163.64',
      high: '163.92',
      low: '161.54',
      close: '163.43',
      'adjusted close': '100.833746374006',
      volume: '4723800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-12': {
      open: '159.64',
      high: '162.44',
      low: '158.76',
      close: '162.42',
      'adjusted close': '100.210592217255',
      volume: '5247200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-09': {
      open: '164.57',
      high: '165.19',
      low: '160.81',
      close: '161.37',
      'adjusted close': '99.5627586879601',
      volume: '6743900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-08': {
      open: '167.0',
      high: '169.58',
      low: '165.1',
      close: '165.25',
      'adjusted close': '101.956657824784',
      volume: '6027200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-07': {
      open: '167.28',
      high: '167.9',
      low: '166.16',
      close: '167.31',
      'adjusted close': '103.22764551083',
      volume: '6796600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-06': {
      open: '163.06',
      high: '165.55',
      low: '162.2',
      close: '165.11',
      'adjusted close': '101.870280020878',
      volume: '5761200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-02': {
      open: '167.69',
      high: '168.93',
      low: '166.0',
      close: '166.98',
      'adjusted close': '103.024040687337',
      volume: '4979100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-09-01': {
      open: '172.71',
      high: '173.68',
      low: '170.12',
      close: '170.33',
      'adjusted close': '105.090938137945',
      volume: '5463900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-31': {
      open: '173.29',
      high: '173.72',
      low: '170.71',
      close: '171.91',
      'adjusted close': '106.065773353456',
      volume: '6074200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-30': {
      open: '172.06',
      high: '173.51',
      low: '170.88',
      close: '172.51',
      'adjusted close': '106.435963941625',
      volume: '4477700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-29': {
      open: '170.58',
      high: '172.72',
      low: '170.36',
      close: '172.62',
      'adjusted close': '106.503832216122',
      volume: '5293400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-26': {
      open: '164.85',
      high: '169.33',
      low: '163.28',
      close: '169.14',
      'adjusted close': '104.356726804744',
      volume: '6438200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-25': {
      open: '167.52',
      high: '168.53',
      low: '164.85',
      close: '165.58',
      'adjusted close': '102.160262648277',
      volume: '6751600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-24': {
      open: '164.13',
      high: '166.83',
      low: '163.35',
      close: '166.76',
      'adjusted close': '102.888304138342',
      volume: '6427000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-23': {
      open: '160.65',
      high: '164.59',
      low: '159.53',
      close: '164.32',
      'adjusted close': '101.382862413123',
      volume: '6923300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-22': {
      open: '161.35',
      high: '161.68',
      low: '157.62',
      close: '158.98',
      'adjusted close': '98.0881661784216',
      volume: '7249100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-19': {
      open: '162.17',
      high: '163.94',
      low: '157.13',
      close: '157.54',
      'adjusted close': '97.1997087668169',
      volume: '14068600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-18': {
      open: '166.32',
      high: '166.46',
      low: '160.16',
      close: '163.83',
      'adjusted close': '101.080540099452',
      volume: '15082600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-17': {
      open: '171.57',
      high: '172.99',
      low: '169.89',
      close: '171.48',
      'adjusted close': '105.800470098602',
      volume: '5129700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-16': {
      open: '172.19',
      high: '172.19',
      low: '169.0',
      close: '171.24',
      'adjusted close': '105.652393863335',
      volume: '5585400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-15': {
      open: '171.32',
      high: '174.0',
      low: '170.39',
      close: '172.99',
      'adjusted close': '106.73211641216',
      volume: '7645400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-12': {
      open: '168.5',
      high: '169.38',
      low: '165.83',
      close: '168.2',
      'adjusted close': '103.776761549947',
      volume: '5187000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-11': {
      open: '164.1',
      high: '168.77',
      low: '162.94',
      close: '166.73',
      'adjusted close': '102.869794608933',
      volume: '9175600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-10': {
      open: '168.18',
      high: '169.23',
      low: '161.85',
      close: '162.54',
      'adjusted close': '100.284630334889',
      volume: '13993600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-09': {
      open: '167.46',
      high: '171.05',
      low: '162.0',
      close: '170.61',
      'adjusted close': '105.263693745757',
      volume: '12517600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-08': {
      open: '168.83',
      high: '172.61',
      low: '166.0',
      close: '166.22',
      'adjusted close': '102.55513260899',
      volume: '15804900',
      'dividend amount': '0.7500',
      'split coefficient': '1.0',
    },
    '2011-08-05': {
      open: '172.1',
      high: '174.22',
      low: '166.52',
      close: '172.98',
      'adjusted close': '106.24655230702',
      volume: '11871800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-04': {
      open: '176.5',
      high: '177.92',
      low: '171.18',
      close: '171.48',
      'adjusted close': '105.325232914833',
      volume: '11022300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-03': {
      open: '177.67',
      high: '179.23',
      low: '175.41',
      close: '178.83',
      'adjusted close': '109.83969793655',
      volume: '6722000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-02': {
      open: '179.95',
      high: '182.29',
      low: '177.86',
      close: '178.05',
      'adjusted close': '109.360611852612',
      volume: '5770400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-08-01': {
      open: '182.6',
      high: '183.69',
      low: '178.5',
      close: '180.75',
      'adjusted close': '111.018986758549',
      volume: '5842700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-29': {
      open: '180.26',
      high: '183.43',
      low: '179.73',
      close: '181.85',
      'adjusted close': '111.694620979486',
      volume: '6665600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-28': {
      open: '181.06',
      high: '183.27',
      low: '180.88',
      close: '181.8',
      'adjusted close': '111.66391033308',
      volume: '4478800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-27': {
      open: '182.01',
      high: '182.91',
      low: '180.93',
      close: '181.35',
      'adjusted close': '111.387514515424',
      volume: '5806800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-26': {
      open: '182.94',
      high: '184.05',
      low: '182.65',
      close: '182.93',
      'adjusted close': '112.357970941861',
      volume: '4356200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-25': {
      open: '183.89',
      high: '184.96',
      low: '183.28',
      close: '183.7',
      'adjusted close': '112.830914896517',
      volume: '4987000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-22': {
      open: '184.64',
      high: '185.63',
      low: '184.26',
      close: '185.18',
      'adjusted close': '113.739950030142',
      volume: '4516100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-21': {
      open: '183.59',
      high: '185.5',
      low: '182.9',
      close: '184.9',
      'adjusted close': '113.567970410267',
      volume: '4958400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-20': {
      open: '183.84',
      high: '184.42',
      low: '183.0',
      close: '183.65',
      'adjusted close': '112.800204250111',
      volume: '5901100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-19': {
      open: '178.74',
      high: '185.21',
      low: '178.65',
      close: '185.21',
      'adjusted close': '113.758376417986',
      volume: '13245600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-18': {
      open: '174.73',
      high: '176.46',
      low: '173.58',
      close: '175.28',
      'adjusted close': '107.659242041707',
      volume: '8879700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-15': {
      open: '175.08',
      high: '175.94',
      low: '174.07',
      close: '175.54',
      'adjusted close': '107.818937403019',
      volume: '5347100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-14': {
      open: '174.4',
      high: '176.1',
      low: '173.84',
      close: '174.23',
      'adjusted close': '107.014318467176',
      volume: '4613100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-13': {
      open: '174.9',
      high: '176.32',
      low: '174.0',
      close: '174.32',
      'adjusted close': '107.069597630707',
      volume: '4074800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-12': {
      open: '174.93',
      high: '175.37',
      low: '173.89',
      close: '174.05',
      'adjusted close': '106.903760140113',
      volume: '5036800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-11': {
      open: '174.9',
      high: '176.15',
      low: '174.61',
      close: '174.99',
      'adjusted close': '107.481120292551',
      volume: '4766500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-08': {
      open: '175.49',
      high: '176.49',
      low: '175.01',
      close: '176.49',
      'adjusted close': '108.402439684738',
      volume: '4399900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-07': {
      open: '176.78',
      high: '177.27',
      low: '176.12',
      close: '176.48',
      'adjusted close': '108.396297555456',
      volume: '5905600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-06': {
      open: '175.19',
      high: '177.77',
      low: '175.09',
      close: '177.71',
      'adjusted close': '109.15177945705',
      volume: '5885900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-05': {
      open: '173.52',
      high: '175.4309',
      low: '173.52',
      close: '175.43',
      'adjusted close': '107.751373980926',
      volume: '4586500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-07-01': {
      open: '171.61',
      high: '174.65',
      low: '171.49',
      close: '174.54',
      'adjusted close': '107.204724474894',
      volume: '5181700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-30': {
      open: '171.11',
      high: '172.4468',
      low: '170.75',
      close: '171.55',
      'adjusted close': '105.368227819801',
      volume: '5105200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-29': {
      open: '170.61',
      high: '170.86',
      low: '169.82',
      close: '170.54',
      'adjusted close': '104.747872762395',
      volume: '3817100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-28': {
      open: '168.33',
      high: '170.7',
      low: '168.01',
      close: '170.01',
      'adjusted close': '104.422339910489',
      volume: '4850000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-27': {
      open: '165.74',
      high: '168.24',
      low: '165.21',
      close: '167.62',
      'adjusted close': '102.954371012271',
      volume: '4446700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-24': {
      open: '165.85',
      high: '165.94',
      low: '164.57',
      close: '165.07',
      'adjusted close': '101.388128045553',
      volume: '4402600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-23': {
      open: '164.06',
      high: '166.73',
      low: '163.8',
      close: '166.12',
      'adjusted close': '102.033051620084',
      volume: '5271500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-22': {
      open: '165.59',
      high: '166.81',
      low: '165.1',
      close: '165.68',
      'adjusted close': '101.762797931709',
      volume: '3960500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-21': {
      open: '165.2',
      high: '166.75',
      low: '164.0',
      close: '166.22',
      'adjusted close': '102.094472912897',
      volume: '5387300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-20': {
      open: '163.7',
      high: '165.61',
      low: '163.59',
      close: '165.02',
      'adjusted close': '101.357417399147',
      volume: '3956100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-17': {
      open: '164.24',
      high: '165.1',
      low: '163.58',
      close: '164.44',
      'adjusted close': '101.001173900834',
      volume: '7149700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-16': {
      open: '162.06',
      high: '163.63',
      low: '161.78',
      close: '162.67',
      'adjusted close': '99.9140170180536',
      volume: '4861100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-15': {
      open: '162.75',
      high: '163.41',
      low: '161.52',
      close: '162.33',
      'adjusted close': '99.7051846224912',
      volume: '5225600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-14': {
      open: '163.87',
      high: '164.57',
      low: '163.65',
      close: '164.12',
      'adjusted close': '100.804625763835',
      volume: '7050000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-13': {
      open: '164.44',
      high: '164.46',
      low: '162.73',
      close: '163.17',
      'adjusted close': '100.221123482116',
      volume: '5099200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-10': {
      open: '164.57',
      high: '164.84',
      low: '162.87',
      close: '163.18',
      'adjusted close': '100.227265611397',
      volume: '4683300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-09': {
      open: '165.01',
      high: '165.96',
      low: '164.76',
      close: '164.84',
      'adjusted close': '101.246859072084',
      volume: '4299800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-08': {
      open: '163.27',
      high: '164.85',
      low: '163.26',
      close: '164.34',
      'adjusted close': '100.939752608022',
      volume: '3850800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-07': {
      open: '165.11',
      high: '165.24',
      low: '163.61',
      close: '163.69',
      'adjusted close': '100.540514204741',
      volume: '4187000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-06': {
      open: '164.76',
      high: '165.58',
      low: '164.27',
      close: '164.75',
      'adjusted close': '101.191579908553',
      volume: '3619700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-03': {
      open: '164.3',
      high: '165.89',
      low: '164.13',
      close: '165.05',
      'adjusted close': '101.375843786991',
      volume: '5230500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-02': {
      open: '166.44',
      high: '167.1',
      low: '165.71',
      close: '166.09',
      'adjusted close': '102.01462523224',
      volume: '3854100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-06-01': {
      open: '168.9',
      high: '169.58',
      low: '166.5',
      close: '166.56',
      'adjusted close': '102.303305308459',
      volume: '5134600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-31': {
      open: '168.44',
      high: '169.89',
      low: '167.82',
      close: '168.93',
      'adjusted close': '103.758989948115',
      volume: '9123400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-27': {
      open: '167.91',
      high: '168.47',
      low: '167.0',
      close: '167.5',
      'adjusted close': '102.880665460896',
      volume: '3277600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-26': {
      open: '167.46',
      high: '167.5',
      low: '165.9',
      close: '167.18',
      'adjusted close': '102.684117323896',
      volume: '5771300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-25': {
      open: '167.63',
      high: '168.4',
      low: '167.51',
      close: '167.75',
      'adjusted close': '103.034218692927',
      volume: '3882100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-24': {
      open: '168.5',
      high: '168.67',
      low: '167.51',
      close: '167.99',
      'adjusted close': '103.181629795677',
      volume: '4643600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-23': {
      open: '168.58',
      high: '168.69',
      low: '167.07',
      close: '168.26',
      'adjusted close': '103.347467286271',
      volume: '5144000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-20': {
      open: '170.4',
      high: '171.15',
      low: '169.38',
      close: '170.16',
      'adjusted close': '104.514471849708',
      volume: '4869900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-19': {
      open: '170.86',
      high: '171.4',
      low: '169.32',
      close: '170.59',
      'adjusted close': '104.778583408802',
      volume: '3538300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-18': {
      open: '170.1',
      high: '171.19',
      low: '169.46',
      close: '170.44',
      'adjusted close': '104.686451469583',
      volume: '4154300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-17': {
      open: '167.85',
      high: '171.41',
      low: '166.53',
      close: '170.5',
      'adjusted close': '104.72330424527',
      volume: '8773200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-16': {
      open: '169.81',
      high: '170.64',
      low: '168.31',
      close: '168.86',
      'adjusted close': '103.715995043146',
      volume: '4662200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-13': {
      open: '171.7',
      high: '172.15',
      low: '169.44',
      close: '169.92',
      'adjusted close': '104.367060746958',
      volume: '5167400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-12': {
      open: '169.65',
      high: '172.77',
      low: '168.65',
      close: '172.24',
      'adjusted close': '105.792034740208',
      volume: '5138500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-11': {
      open: '170.3',
      high: '170.52',
      low: '167.82',
      close: '169.5',
      'adjusted close': '104.109091317146',
      volume: '4663200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-10': {
      open: '169.25',
      high: '170.9',
      low: '169.22',
      close: '170.38',
      'adjusted close': '104.649598693895',
      volume: '4934800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-09': {
      open: '168.39',
      high: '169.85',
      low: '168.31',
      close: '169.1',
      'adjusted close': '103.863406145896',
      volume: '3594800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-06': {
      open: '169.03',
      high: '170.15',
      low: '168.24',
      close: '168.89',
      'adjusted close': '103.73442143099',
      volume: '4839400',
      'dividend amount': '0.7500',
      'split coefficient': '1.0',
    },
    '2011-05-05': {
      open: '170.47',
      high: '170.87',
      low: '167.5',
      close: '168.46',
      'adjusted close': '103.012854481635',
      volume: '7332000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-04': {
      open: '172.36',
      high: '172.62',
      low: '169.59',
      close: '170.62',
      'adjusted close': '104.333688897403',
      volume: '6890300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-03': {
      open: '172.0',
      high: '173.48',
      low: '171.23',
      close: '172.87',
      'adjusted close': '105.709558080495',
      volume: '5007700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-05-02': {
      open: '172.11',
      high: '173.54',
      low: '171.49',
      close: '172.15',
      'adjusted close': '105.269279941906',
      volume: '5396200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-29': {
      open: '171.1',
      high: '173.0',
      low: '170.48',
      close: '170.58',
      'adjusted close': '104.309229000815',
      volume: '5587700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-28': {
      open: '169.99',
      high: '171.38',
      low: '169.7',
      close: '170.78',
      'adjusted close': '104.431528483756',
      volume: '5087700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-27': {
      open: '168.61',
      high: '170.59',
      low: '168.46',
      close: '170.37',
      'adjusted close': '104.180814543726',
      volume: '5299700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-26': {
      open: '167.99',
      high: '169.2',
      low: '167.4',
      close: '168.49',
      'adjusted close': '103.031199404076',
      volume: '7300000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-25': {
      open: '167.65',
      high: '168.77',
      low: '167.23',
      close: '167.67',
      'adjusted close': '102.529771524016',
      volume: '3583800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-21': {
      open: '165.63',
      high: '168.45',
      low: '164.86',
      close: '168.28',
      'adjusted close': '102.902784946987',
      volume: '6158400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-20': {
      open: '163.1',
      high: '165.89',
      low: '162.19',
      close: '164.75',
      'adjusted close': '100.74419907307',
      volume: '10891800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-19': {
      open: '165.71',
      high: '166.38',
      low: '164.44',
      close: '165.4',
      'adjusted close': '101.14167239263',
      volume: '6547100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-18': {
      open: '164.64',
      high: '166.16',
      low: '162.86',
      close: '165.94',
      'adjusted close': '101.471880996572',
      volume: '6160700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-15': {
      open: '165.0',
      high: '166.34',
      low: '164.87',
      close: '166.21',
      'adjusted close': '101.636985298543',
      volume: '5287400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-14': {
      open: '163.84',
      high: '165.36',
      low: '163.16',
      close: '164.97',
      'adjusted close': '100.878728504305',
      volume: '4699100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-13': {
      open: '163.97',
      high: '164.93',
      low: '163.66',
      close: '163.95',
      'adjusted close': '100.255001141304',
      volume: '4181900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-12': {
      open: '163.36',
      high: '163.77',
      low: '162.3',
      close: '163.25',
      'adjusted close': '99.8269529510083',
      volume: '3860600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-11': {
      open: '163.44',
      high: '164.0',
      low: '163.11',
      close: '163.95',
      'adjusted close': '100.255001141304',
      volume: '4139000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-08': {
      open: '164.35',
      high: '164.38',
      low: '163.16',
      close: '164.05',
      'adjusted close': '100.316150882774',
      volume: '3324100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-07': {
      open: '164.1',
      high: '164.5463',
      low: '163.28',
      close: '164.38',
      'adjusted close': '100.517945029628',
      volume: '3666700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-06': {
      open: '164.34',
      high: '164.75',
      low: '163.81',
      close: '164.04',
      'adjusted close': '100.310035908627',
      volume: '3774800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-05': {
      open: '163.81',
      high: '164.7',
      low: '163.62',
      close: '163.99',
      'adjusted close': '100.279461037892',
      volume: '3612000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-04': {
      open: '164.42',
      high: '164.73',
      low: '163.58',
      close: '164.25',
      'adjusted close': '100.438450365716',
      volume: '3225100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-04-01': {
      open: '163.7',
      high: '164.42',
      low: '163.04',
      close: '164.27',
      'adjusted close': '100.45068031401',
      volume: '4381900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-31': {
      open: '163.4',
      high: '164.06',
      low: '163.07',
      close: '163.07',
      'adjusted close': '99.716883416361',
      volume: '3860800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-30': {
      open: '163.55',
      high: '163.99',
      low: '163.02',
      close: '163.6',
      'adjusted close': '100.040977046156',
      volume: '3394100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-29': {
      open: '161.34',
      high: '163.09',
      low: '161.15',
      close: '162.88',
      'adjusted close': '99.6006989075665',
      volume: '3456700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-28': {
      open: '161.54',
      high: '162.58',
      low: '161.12',
      close: '161.37',
      'adjusted close': '98.6773378113581',
      volume: '3972800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-25': {
      open: '160.98',
      high: '162.74',
      low: '160.76',
      close: '162.18',
      'adjusted close': '99.1726507172713',
      volume: '5348100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-24': {
      open: '160.23',
      high: '160.71',
      low: '158.84',
      close: '160.04',
      'adjusted close': '97.8640462497971',
      volume: '5145800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-23': {
      open: '158.0',
      high: '160.08',
      low: '157.53',
      close: '159.53',
      'adjusted close': '97.5521825682962',
      volume: '4782500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-22': {
      open: '158.56',
      high: '158.61',
      low: '157.52',
      close: '158.0',
      'adjusted close': '96.6165915237937',
      volume: '4311400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-21': {
      open: '157.64',
      high: '159.63',
      low: '157.07',
      close: '157.68',
      'adjusted close': '96.4209123510873',
      volume: '5784000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-18': {
      open: '156.5',
      high: '156.95',
      low: '154.32',
      close: '155.89',
      'adjusted close': '95.3263319787607',
      volume: '11450000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-17': {
      open: '154.0',
      high: '154.5',
      low: '152.7',
      close: '154.18',
      'adjusted close': '94.2806713996108',
      volume: '7376400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-16': {
      open: '156.52',
      high: '156.98',
      low: '151.71',
      close: '153.0',
      'adjusted close': '93.5591044502559',
      volume: '12195300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-15': {
      open: '157.35',
      high: '159.64',
      low: '156.88',
      close: '159.02',
      'adjusted close': '97.2403188867954',
      volume: '6475800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-14': {
      open: '161.16',
      high: '161.9804',
      low: '160.09',
      close: '161.39',
      'adjusted close': '98.6895677596523',
      volume: '4300500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-11': {
      open: '162.4',
      high: '163.24',
      low: '160.84',
      close: '162.43',
      'adjusted close': '99.3255250709481',
      volume: '4271000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-10': {
      open: '164.64',
      high: '164.67',
      low: '161.38',
      close: '162.02',
      'adjusted close': '99.0748111309181',
      volume: '7041400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-09': {
      open: '163.47',
      high: '167.72',
      low: '163.28',
      close: '165.86',
      'adjusted close': '101.422961203395',
      volume: '9086600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-08': {
      open: '159.7',
      high: '163.43',
      low: '159.21',
      close: '162.28',
      'adjusted close': '99.233800458742',
      volume: '5384800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-07': {
      open: '161.6',
      high: '162.98',
      low: '158.85',
      close: '159.93',
      'adjusted close': '97.7967815341793',
      volume: '5312000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-04': {
      open: '163.4',
      high: '164.31',
      low: '160.65',
      close: '161.83',
      'adjusted close': '98.9586266221236',
      volume: '4910700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-03': {
      open: '161.42',
      high: '164.0',
      low: '161.27',
      close: '163.48',
      'adjusted close': '99.9675973563911',
      volume: '4057800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-02': {
      open: '159.43',
      high: '161.11',
      low: '159.41',
      close: '160.16',
      'adjusted close': '97.937425939562',
      volume: '3413000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-03-01': {
      open: '163.15',
      high: '163.15',
      low: '159.88',
      close: '159.97',
      'adjusted close': '97.8212414307676',
      volume: '4382800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-28': {
      open: '162.36',
      high: '162.99',
      low: '161.24',
      close: '161.88',
      'adjusted close': '98.989201492859',
      volume: '4773000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-25': {
      open: '161.06',
      high: '162.34',
      low: '160.86',
      close: '162.28',
      'adjusted close': '99.233800458742',
      volume: '4518400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-24': {
      open: '159.63',
      high: '161.27',
      low: '159.03',
      close: '160.77',
      'adjusted close': '98.3104393625336',
      volume: '5707500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-23': {
      open: '161.81',
      high: '162.68',
      low: '160.14',
      close: '160.18',
      'adjusted close': '97.9496558878561',
      volume: '5998100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-22': {
      open: '163.57',
      high: '164.26',
      low: '161.78',
      close: '161.95',
      'adjusted close': '99.0320063118885',
      volume: '5209300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-18': {
      open: '164.46',
      high: '164.84',
      low: '164.1',
      close: '164.84',
      'adjusted close': '100.799233840393',
      volume: '4245000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-17': {
      open: '163.3',
      high: '164.67',
      low: '162.85',
      close: '164.24',
      'adjusted close': '100.432335391569',
      volume: '3230500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-16': {
      open: '163.33',
      high: '163.6',
      low: '162.75',
      close: '163.4',
      'adjusted close': '99.9186775632145',
      volume: '3216000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-15': {
      open: '162.89',
      high: '163.57',
      low: '162.52',
      close: '162.84',
      'adjusted close': '99.5762390109782',
      volume: '3768700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-14': {
      open: '164.18',
      high: '164.38',
      low: '162.85',
      close: '163.22',
      'adjusted close': '99.8086080285671',
      volume: '4129800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-11': {
      open: '163.98',
      high: '165.01',
      low: '163.31',
      close: '163.85',
      'adjusted close': '100.193851399833',
      volume: '5185200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-10': {
      open: '163.9',
      high: '165.0',
      low: '163.18',
      close: '164.09',
      'adjusted close': '100.340610779363',
      volume: '5737800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-09': {
      open: '165.62',
      high: '165.97',
      low: '164.1',
      close: '164.65',
      'adjusted close': '100.683049331599',
      volume: '4633600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-08': {
      open: '164.82',
      high: '166.25',
      low: '164.32',
      close: '166.05',
      'adjusted close': '101.539145712189',
      volume: '5612600',
      'dividend amount': '0.6500',
      'split coefficient': '1.0',
    },
    '2011-02-07': {
      open: '164.08',
      high: '164.99',
      low: '164.02',
      close: '164.82',
      'adjusted close': '100.394013175063',
      volume: '4928100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-04': {
      open: '163.48',
      high: '164.14',
      low: '163.22',
      close: '164.0',
      'adjusted close': '99.8945404726999',
      volume: '3755200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-03': {
      open: '163.16',
      high: '164.2',
      low: '162.81',
      close: '163.53',
      'adjusted close': '99.6082573384184',
      volume: '4683400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-02': {
      open: '163.4',
      high: '163.6',
      low: '162.61',
      close: '163.3',
      'adjusted close': '99.468161336536',
      volume: '3904000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-02-01': {
      open: '162.11',
      high: '163.94',
      low: '162.0',
      close: '163.56',
      'adjusted close': '99.6265307299683',
      volume: '5831300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-31': {
      open: '159.18',
      high: '162.0',
      low: '158.68',
      close: '162.0',
      'adjusted close': '98.6763143693743',
      volume: '7197200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-28': {
      open: '161.05',
      high: '161.9201',
      low: '158.67',
      close: '159.21',
      'adjusted close': '96.9768889552351',
      volume: '6725600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-27': {
      open: '161.43',
      high: '162.18',
      low: '160.86',
      close: '161.07',
      'adjusted close': '98.1098392313279',
      volume: '4878300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-26': {
      open: '161.67',
      high: '161.904',
      low: '160.42',
      close: '161.04',
      'adjusted close': '98.091565839778',
      volume: '5353100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-25': {
      open: '159.21',
      high: '164.35',
      low: '159.0',
      close: '161.44',
      'adjusted close': '98.3352110604431',
      volume: '8260800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-24': {
      open: '155.42',
      high: '159.79',
      low: '155.33',
      close: '159.63',
      'adjusted close': '97.2327164369334',
      volume: '7285100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-21': {
      open: '156.4',
      high: '156.78',
      low: '154.96',
      close: '155.5',
      'adjusted close': '94.7170795335661',
      volume: '7009000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-20': {
      open: '154.53',
      high: '155.96',
      low: '154.45',
      close: '155.8',
      'adjusted close': '94.8998134490649',
      volume: '7439900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-19': {
      open: '153.26',
      high: '156.13',
      low: '152.8301',
      close: '155.69',
      'adjusted close': '94.832811013382',
      volume: '12141000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-18': {
      open: '149.82',
      high: '151.465',
      low: '149.38',
      close: '150.65',
      'adjusted close': '91.7628812330015',
      volume: '9176900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-14': {
      open: '148.89',
      high: '150.0',
      low: '148.47',
      close: '150.0',
      'adjusted close': '91.3669577494206',
      volume: '4544200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-13': {
      open: '149.24',
      high: '149.29',
      low: '148.25',
      close: '148.82',
      'adjusted close': '90.6482043484585',
      volume: '3445800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-12': {
      open: '147.99',
      high: '149.29',
      low: '147.67',
      close: '149.1',
      'adjusted close': '90.8187560029241',
      volume: '4091500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-11': {
      open: '148.2',
      high: '148.35',
      low: '146.75',
      close: '147.28',
      'adjusted close': '89.7101702488978',
      volume: '4163600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-10': {
      open: '147.58',
      high: '148.06',
      low: '147.23',
      close: '147.64',
      'adjusted close': '89.9294509474964',
      volume: '3633400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-07': {
      open: '148.79',
      high: '148.86',
      low: '146.94',
      close: '147.93',
      'adjusted close': '90.1060937324786',
      volume: '4135700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-06': {
      open: '147.13',
      high: '148.79',
      low: '146.82',
      close: '148.66',
      'adjusted close': '90.5507462601925',
      volume: '5029200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-05': {
      open: '147.34',
      high: '147.48',
      low: '146.73',
      close: '147.05',
      'adjusted close': '89.5700742470154',
      volume: '4657400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-04': {
      open: '147.56',
      high: '148.22',
      low: '146.64',
      close: '147.64',
      'adjusted close': '89.9294509474964',
      volume: '5060100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2011-01-03': {
      open: '147.21',
      high: '148.2',
      low: '147.14',
      close: '147.48',
      'adjusted close': '89.8319928592304',
      volume: '4603800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-31': {
      open: '146.73',
      high: '147.07',
      low: '145.96',
      close: '146.76',
      'adjusted close': '89.3934314620332',
      volume: '2969800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-30': {
      open: '146.36',
      high: '147.11',
      low: '146.25',
      close: '146.67',
      'adjusted close': '89.3386112873835',
      volume: '3039100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-29': {
      open: '146.0',
      high: '147.5',
      low: '145.71',
      close: '146.52',
      'adjusted close': '89.2472443296341',
      volume: '3661100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-28': {
      open: '145.31',
      high: '146.15',
      low: '145.06',
      close: '145.71',
      'adjusted close': '88.7538627577872',
      volume: '2699600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-27': {
      open: '145.12',
      high: '145.7',
      low: '145.0',
      close: '145.34',
      'adjusted close': '88.528490928672',
      volume: '2314700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-23': {
      open: '145.89',
      high: '146.33',
      low: '145.33',
      close: '145.89',
      'adjusted close': '88.8635031070865',
      volume: '2424500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-22': {
      open: '145.66',
      high: '146.4',
      low: '145.58',
      close: '145.95',
      'adjusted close': '88.9000498901863',
      volume: '3388800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-21': {
      open: '144.99',
      high: '145.99',
      low: '144.33',
      close: '145.74',
      'adjusted close': '88.7721361493371',
      volume: '3777100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-20': {
      open: '145.39',
      high: '145.54',
      low: '144.39',
      close: '144.51',
      'adjusted close': '88.0229270957918',
      volume: '3995000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-17': {
      open: '144.69',
      high: '145.5',
      low: '144.4',
      close: '145.0',
      'adjusted close': '88.3213924911066',
      volume: '10027000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-16': {
      open: '144.84',
      high: '145.32',
      low: '144.15',
      close: '144.55',
      'adjusted close': '88.0472916178584',
      volume: '4178600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-15': {
      open: '145.13',
      high: '145.72',
      low: '144.31',
      close: '144.72',
      'adjusted close': '88.150840836641',
      volume: '4447400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-14': {
      open: '144.26',
      high: '146.01',
      low: '144.26',
      close: '145.82',
      'adjusted close': '88.8208651934701',
      volume: '4800800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-13': {
      open: '145.14',
      high: '145.4',
      low: '144.21',
      close: '144.28',
      'adjusted close': '87.8828310939094',
      volume: '4741000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-10': {
      open: '144.88',
      high: '144.95',
      low: '143.73',
      close: '144.82',
      'adjusted close': '88.2117521418073',
      volume: '3503800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-09': {
      open: '145.94',
      high: '145.94',
      low: '143.52',
      close: '144.3',
      'adjusted close': '87.8950133549427',
      volume: '4405300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-08': {
      open: '144.35',
      high: '145.65',
      low: '143.84',
      close: '144.98',
      'adjusted close': '88.3092102300734',
      volume: '4961400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-07': {
      open: '146.02',
      high: '146.2999',
      low: '143.87',
      close: '144.02',
      'adjusted close': '87.7244617004771',
      volume: '4828600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-06': {
      open: '144.54',
      high: '145.87',
      low: '144.52',
      close: '144.99',
      'adjusted close': '88.31530136059',
      volume: '3321800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-03': {
      open: '144.25',
      high: '145.68',
      low: '144.25',
      close: '145.38',
      'adjusted close': '88.5528554507385',
      volume: '3710600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-02': {
      open: '144.33',
      high: '145.85',
      low: '144.3',
      close: '145.18',
      'adjusted close': '88.4310328404059',
      volume: '5374000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-12-01': {
      open: '143.61',
      high: '145.13',
      low: '143.51',
      close: '144.41',
      'adjusted close': '87.9620157906256',
      volume: '6822800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-30': {
      open: '142.24',
      high: '142.76',
      low: '141.28',
      close: '141.46',
      'adjusted close': '86.1651322882203',
      volume: '7674800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-29': {
      open: '143.53',
      high: '143.67',
      low: '141.5',
      close: '142.89',
      'adjusted close': '87.0361639520981',
      volume: '5040300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-26': {
      open: '145.3',
      high: '145.3',
      low: '143.57',
      close: '143.9',
      'adjusted close': '87.6513681342775',
      volume: '2081300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-24': {
      open: '143.95',
      high: '146.44',
      low: '143.82',
      close: '145.81',
      'adjusted close': '88.8147740629535',
      volume: '4874100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-23': {
      open: '144.24',
      high: '144.53',
      low: '142.33',
      close: '143.18',
      'adjusted close': '87.2128067370803',
      volume: '4570300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-22': {
      open: '144.0',
      high: '145.43',
      low: '143.56',
      close: '145.39',
      'adjusted close': '88.5589465812551',
      volume: '3730700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-19': {
      open: '144.38',
      high: '145.35',
      low: '143.99',
      close: '145.05',
      'adjusted close': '88.3518481436898',
      volume: '5015900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-18': {
      open: '143.04',
      high: '144.99',
      low: '142.75',
      close: '144.36',
      'adjusted close': '87.9315601380424',
      volume: '4236200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-17': {
      open: '142.49',
      high: '142.49',
      low: '141.39',
      close: '141.95',
      'adjusted close': '86.4635976835351',
      volume: '4785900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-16': {
      open: '142.93',
      high: '143.38',
      low: '141.18',
      close: '142.24',
      'adjusted close': '86.6402404685173',
      volume: '6342100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-15': {
      open: '143.89',
      high: '144.75',
      low: '143.27',
      close: '143.64',
      'adjusted close': '87.4929987408452',
      volume: '3827700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-12': {
      open: '144.59',
      high: '145.77',
      low: '143.55',
      close: '143.74',
      'adjusted close': '87.5539100460115',
      volume: '4731500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-11': {
      open: '144.7',
      high: '145.5',
      low: '143.35',
      close: '145.43',
      'adjusted close': '88.5833111033216',
      volume: '4752200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-10': {
      open: '146.09',
      high: '146.79',
      low: '145.63',
      close: '146.55',
      'adjusted close': '89.265517721184',
      volume: '5172000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-09': {
      open: '146.7',
      high: '147.53',
      low: '145.63',
      close: '146.14',
      'adjusted close': '89.0157813700022',
      volume: '5700600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-08': {
      open: '145.35',
      high: '146.58',
      low: '145.25',
      close: '146.46',
      'adjusted close': '89.2106975465343',
      volume: '4459700',
      'dividend amount': '0.6500',
      'split coefficient': '1.0',
    },
    '2010-11-05': {
      open: '145.94',
      high: '146.93',
      low: '145.67',
      close: '146.92',
      'adjusted close': '89.0954774219076',
      volume: '4995800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-04': {
      open: '144.82',
      high: '146.84',
      low: '144.71',
      close: '146.79',
      'adjusted close': '89.0166425997945',
      volume: '6622500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-03': {
      open: '144.24',
      high: '144.46',
      low: '142.63',
      close: '144.17',
      'adjusted close': '87.4278177233625',
      volume: '5182100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-02': {
      open: '143.85',
      high: '144.71',
      low: '143.71',
      close: '143.84',
      'adjusted close': '87.2276985595371',
      volume: '4626400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-11-01': {
      open: '143.64',
      high: '144.26',
      low: '142.32',
      close: '143.32',
      'adjusted close': '86.9123592710849',
      volume: '5674600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-29': {
      open: '140.9',
      high: '144.0',
      low: '140.75',
      close: '143.6',
      'adjusted close': '87.0821573494822',
      volume: '8439300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-28': {
      open: '141.84',
      high: '141.95',
      low: '140.42',
      close: '140.9',
      'adjusted close': '85.4448187363652',
      volume: '5481600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-27': {
      open: '139.52',
      high: '141.57',
      low: '139.0',
      close: '141.43',
      'adjusted close': '85.766222241903',
      volume: '6465300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-26': {
      open: '139.29',
      high: '142.0',
      low: '138.53',
      close: '140.67',
      'adjusted close': '85.305341743396',
      volume: '7327100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-25': {
      open: '140.42',
      high: '141.4',
      low: '139.81',
      close: '139.84',
      'adjusted close': '84.8020117252896',
      volume: '4870000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-22': {
      open: '140.15',
      high: '140.75',
      low: '139.46',
      close: '139.67',
      'adjusted close': '84.6989200348341',
      volume: '4105300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-21': {
      open: '139.81',
      high: '140.49',
      low: '138.4',
      close: '139.83',
      'adjusted close': '84.795947508204',
      volume: '6221200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-20': {
      open: '138.28',
      high: '139.87',
      low: '138.13',
      close: '139.07',
      'adjusted close': '84.335067009697',
      volume: '6945500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-19': {
      open: '137.3',
      high: '139.34',
      low: '136.7',
      close: '138.03',
      'adjusted close': '83.7043884327927',
      volume: '14159100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-18': {
      open: '140.9',
      high: '143.03',
      low: '140.84',
      close: '142.83',
      'adjusted close': '86.6152126338896',
      volume: '10322700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-15': {
      open: '142.1',
      high: '142.1',
      low: '140.54',
      close: '141.06',
      'adjusted close': '85.5418462097351',
      volume: '7220400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-14': {
      open: '140.35',
      high: '141.5',
      low: '139.69',
      close: '141.5',
      'adjusted close': '85.8086717615023',
      volume: '5653100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-13': {
      open: '139.91',
      high: '141.48',
      low: '139.78',
      close: '140.37',
      'adjusted close': '85.1234152308274',
      volume: '8784300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-12': {
      open: '138.4',
      high: '139.99',
      low: '138.27',
      close: '139.85',
      'adjusted close': '84.8080759423753',
      volume: '5637300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-11': {
      open: '138.79',
      high: '139.94',
      low: '138.64',
      close: '139.66',
      'adjusted close': '84.6928558177485',
      volume: '4004300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-08': {
      open: '138.4',
      high: '139.09',
      low: '138.06',
      close: '138.85',
      'adjusted close': '84.2016542338134',
      volume: '4540800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-07': {
      open: '137.92',
      high: '138.88',
      low: '137.52',
      close: '138.72',
      'adjusted close': '84.1228194117004',
      volume: '6440800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-06': {
      open: '136.88',
      high: '137.96',
      low: '136.83',
      close: '137.84',
      'adjusted close': '83.5891683081659',
      volume: '4783000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-05': {
      open: '136.18',
      high: '137.9015',
      low: '136.12',
      close: '137.66',
      'adjusted close': '83.4800124006248',
      volume: '6202500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-04': {
      open: '135.23',
      high: '135.92',
      low: '134.39',
      close: '135.25',
      'adjusted close': '82.0185360829907',
      volume: '4059900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-10-01': {
      open: '135.51',
      high: '136.28',
      low: '135.09',
      close: '135.64',
      'adjusted close': '82.2550405493298',
      volume: '5621200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-30': {
      open: '135.5',
      high: '136.11',
      low: '133.67',
      close: '134.14',
      'adjusted close': '81.3454079864871',
      volume: '6425800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-29': {
      open: '134.4',
      high: '135.805',
      low: '134.34',
      close: '135.48',
      'adjusted close': '82.15801307596',
      volume: '5151200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-28': {
      open: '134.67',
      high: '135.14',
      low: '133.71',
      close: '134.89',
      'adjusted close': '81.8002242679085',
      volume: '7481300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-27': {
      open: '133.51',
      high: '135.0',
      low: '133.51',
      close: '134.65',
      'adjusted close': '81.6546830578536',
      volume: '6155400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-24': {
      open: '132.42',
      high: '134.15',
      low: '132.34',
      close: '134.11',
      'adjusted close': '81.3272153352302',
      volume: '7122400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-23': {
      open: '131.42',
      high: '132.78',
      low: '131.22',
      close: '131.67',
      'adjusted close': '79.8475463663393',
      volume: '3898800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-22': {
      open: '131.61',
      high: '132.58',
      low: '131.4',
      close: '132.57',
      'adjusted close': '80.393325904045',
      volume: '4922400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-21': {
      open: '131.45',
      high: '132.8',
      low: '130.78',
      close: '131.98',
      'adjusted close': '80.0355370959935',
      volume: '5267900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-20': {
      open: '130.24',
      high: '132.09',
      low: '130.11',
      close: '131.79',
      'adjusted close': '79.9203169713667',
      volume: '7145500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-17': {
      open: '129.92',
      high: '130.6',
      low: '129.7',
      close: '130.19',
      'adjusted close': '78.9500422376678',
      volume: '10397400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-16': {
      open: '129.2',
      high: '129.95',
      low: '128.8',
      close: '129.67',
      'adjusted close': '78.6347029492156',
      volume: '4685000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-15': {
      open: '128.65',
      high: '129.96',
      low: '128.54',
      close: '129.43',
      'adjusted close': '78.4891617391607',
      volume: '4762500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-14': {
      open: '128.43',
      high: '129.92',
      low: '128.43',
      close: '128.85',
      'adjusted close': '78.1374371481949',
      volume: '4850500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-13': {
      open: '128.63',
      high: '130.0',
      low: '128.52',
      close: '129.61',
      'adjusted close': '78.5983176467019',
      volume: '5271300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-10': {
      open: '126.99',
      high: '128.29',
      low: '126.26',
      close: '127.99',
      'adjusted close': '77.6159144788317',
      volume: '4418700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-09': {
      open: '127.27',
      high: '127.57',
      low: '126.15',
      close: '126.36',
      'adjusted close': '76.6274470938759',
      volume: '3436700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-08': {
      open: '126.02',
      high: '126.5199',
      low: '125.39',
      close: '126.08',
      'adjusted close': '76.4576490154785',
      volume: '4477500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-07': {
      open: '126.99',
      high: '127.36',
      low: '125.8801',
      close: '125.95',
      'adjusted close': '76.3788141933655',
      volume: '4353300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-03': {
      open: '126.22',
      high: '127.6',
      low: '125.9001',
      close: '127.58',
      'adjusted close': '77.3672815783213',
      volume: '5142900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-02': {
      open: '126.03',
      high: '126.23',
      low: '124.65',
      close: '125.04',
      'adjusted close': '75.8269704385742',
      volume: '5103300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-09-01': {
      open: '125.31',
      high: '126.31',
      low: '124.52',
      close: '125.77',
      'adjusted close': '76.2696582858244',
      volume: '5623100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-31': {
      open: '123.31',
      high: '123.99',
      low: '122.28',
      close: '123.13',
      'adjusted close': '74.6687049752211',
      volume: '5624200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-30': {
      open: '125.08',
      high: '125.2699',
      low: '123.4',
      close: '123.4',
      'adjusted close': '74.8324388365328',
      volume: '3604000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-27': {
      open: '123.83',
      high: '124.8',
      low: '122.42',
      close: '124.73',
      'adjusted close': '75.63897970892',
      volume: '6214200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-26': {
      open: '125.78',
      high: '125.78',
      low: '122.78',
      close: '122.78',
      'adjusted close': '74.4564573772244',
      volume: '7746600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-25': {
      open: '124.57',
      high: '125.6',
      low: '124.0',
      close: '125.27',
      'adjusted close': '75.9664474315434',
      volume: '5235300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-24': {
      open: '125.21',
      high: '126.02',
      low: '124.57',
      close: '124.9',
      'adjusted close': '75.7420713993756',
      volume: '5621200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-23': {
      open: '127.57',
      high: '128.03',
      low: '126.44',
      close: '126.47',
      'adjusted close': '76.6941534818177',
      volume: '4034400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-20': {
      open: '128.72',
      high: '128.98',
      low: '126.96',
      close: '127.5',
      'adjusted close': '77.3187678416364',
      volume: '6246300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-19': {
      open: '128.67',
      high: '129.59',
      low: '128.02',
      close: '128.9',
      'adjusted close': '78.167758233623',
      volume: '5402400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-18': {
      open: '128.87',
      high: '130.195',
      low: '127.8',
      close: '129.3925',
      'adjusted close': '78.4664209250897',
      volume: '4883200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-17': {
      open: '128.83',
      high: '129.85',
      low: '127.905',
      close: '128.45',
      'adjusted close': '77.8948684647701',
      volume: '4298300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-16': {
      open: '127.47',
      high: '128.23',
      low: '126.96',
      close: '127.77',
      'adjusted close': '77.4825017029481',
      volume: '4009200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-13': {
      open: '127.96',
      high: '128.46',
      low: '127.33',
      close: '127.87',
      'adjusted close': '77.5431438738043',
      volume: '4669400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-12': {
      open: '127.69',
      high: '128.78',
      low: '127.52',
      close: '128.3',
      'adjusted close': '77.8039052084859',
      volume: '5132200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-11': {
      open: '130.69',
      high: '130.69',
      low: '129.461',
      close: '129.83',
      'adjusted close': '78.7317304225855',
      volume: '6316100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-10': {
      open: '131.18',
      high: '132.49',
      low: '130.77',
      close: '131.84',
      'adjusted close': '79.9506380567948',
      volume: '5471700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-09': {
      open: '130.79',
      high: '132.34',
      low: '130.4',
      close: '132.0',
      'adjusted close': '80.0476655301647',
      volume: '6131700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-06': {
      open: '130.41',
      high: '130.48',
      low: '128.76',
      close: '130.14',
      'adjusted close': '78.9197211522397',
      volume: '6136200',
      'dividend amount': '0.6500',
      'split coefficient': '1.0',
    },
    '2010-08-05': {
      open: '130.73',
      high: '131.98',
      low: '130.53',
      close: '131.83',
      'adjusted close': '79.5472653834372',
      volume: '4520600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-04': {
      open: '130.46',
      high: '131.5',
      low: '129.85',
      close: '131.27',
      'adjusted close': '79.2093569512539',
      volume: '4567500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-03': {
      open: '130.03',
      high: '131.04',
      low: '129.33',
      close: '130.37',
      'adjusted close': '78.6662898281022',
      volume: '5091800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-08-02': {
      open: '129.25',
      high: '131.2',
      low: '129.25',
      close: '130.76',
      'adjusted close': '78.9016189148013',
      volume: '6437500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-30': {
      open: '127.43',
      high: '128.98',
      low: '127.04',
      close: '128.4',
      'adjusted close': '77.4775762363145',
      volume: '6021000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-29': {
      open: '129.06',
      high: '129.5',
      low: '127.14',
      close: '128.02',
      'adjusted close': '77.2482812287615',
      volume: '8994200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-28': {
      open: '128.67',
      high: '129.35',
      low: '127.88',
      close: '128.43',
      'adjusted close': '77.4956784737529',
      volume: '4248800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-27': {
      open: '128.78',
      high: '129.17',
      low: '127.89',
      close: '128.63',
      'adjusted close': '77.6163600566755',
      volume: '4648200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-26': {
      open: '128.18',
      high: '128.43',
      low: '127.14',
      close: '128.41',
      'adjusted close': '77.4836103154606',
      volume: '5174900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-23': {
      open: '127.3',
      high: '128.8',
      low: '127.0',
      close: '128.38',
      'adjusted close': '77.4655080780222',
      volume: '5078000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-22': {
      open: '126.32',
      high: '127.78',
      low: '126.05',
      close: '127.47',
      'adjusted close': '76.9164068757244',
      volume: '6909000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-21': {
      open: '126.44',
      high: '126.5',
      low: '124.62',
      close: '125.27',
      'adjusted close': '75.5889094635757',
      volume: '8615500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-20': {
      open: '122.97',
      high: '126.56',
      low: '122.93',
      close: '126.55',
      'adjusted close': '76.3612715942804',
      volume: '16337400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-19': {
      open: '128.67',
      high: '130.38',
      low: '128.37',
      close: '129.79',
      'adjusted close': '78.3163132376266',
      volume: '8388600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-16': {
      open: '129.96',
      high: '130.15',
      low: '127.85',
      close: '128.03',
      'adjusted close': '77.2543153079077',
      volume: '7002900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-15': {
      open: '129.87',
      high: '130.92',
      low: '129.55',
      close: '130.72',
      'adjusted close': '78.8774825982167',
      volume: '6186800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-14': {
      open: '129.32',
      high: '131.6',
      low: '129.14',
      close: '130.72',
      'adjusted close': '78.8774825982167',
      volume: '6607200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-13': {
      open: '128.97',
      high: '130.98',
      low: '128.69',
      close: '130.48',
      'adjusted close': '78.7326646987096',
      volume: '6687700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-12': {
      open: '127.37',
      high: '128.83',
      low: '127.16',
      close: '128.67',
      'adjusted close': '77.64049637326',
      volume: '4206900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-09': {
      open: '127.9',
      high: '128.2',
      low: '127.29',
      close: '127.96',
      'adjusted close': '77.2120767538847',
      volume: '3898000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-08': {
      open: '127.37',
      high: '128.15',
      low: '126.74',
      close: '127.97',
      'adjusted close': '77.2181108330309',
      volume: '5439300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-07': {
      open: '123.47',
      high: '127.12',
      low: '123.47',
      close: '127.0',
      'adjusted close': '76.6328051558562',
      volume: '7090300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-06': {
      open: '123.58',
      high: '124.63',
      low: '122.17',
      close: '123.46',
      'adjusted close': '74.4967411381261',
      volume: '6348700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-02': {
      open: '123.29',
      high: '123.29',
      low: '120.61',
      close: '121.86',
      'adjusted close': '73.5312884747452',
      volume: '6454500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-07-01': {
      open: '123.55',
      high: '124.21',
      low: '121.61',
      close: '122.57',
      'adjusted close': '73.9597080941205',
      volume: '9742100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-30': {
      open: '124.83',
      high: '125.22',
      low: '123.0',
      close: '123.48',
      'adjusted close': '74.5088092964183',
      volume: '8018000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-29': {
      open: '127.35',
      high: '128.4',
      low: '124.12',
      close: '125.09',
      'adjusted close': '75.4802960389453',
      volume: '9376200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-28': {
      open: '127.65',
      high: '129.47',
      low: '127.22',
      close: '128.98',
      'adjusted close': '77.8275528267901',
      volume: '6335100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-25': {
      open: '128.34',
      high: '129.095',
      low: '127.12',
      close: '127.12',
      'adjusted close': '76.7052141056098',
      volume: '10420400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-24': {
      open: '129.57',
      high: '129.73',
      low: '127.7',
      close: '128.19',
      'adjusted close': '77.3508605742458',
      volume: '5565500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-23': {
      open: '129.25',
      high: '131.47',
      low: '129.09',
      close: '130.11',
      'adjusted close': '78.5094037703028',
      volume: '6855800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-22': {
      open: '130.37',
      high: '131.47',
      low: '129.07',
      close: '129.3',
      'adjusted close': '78.0206433594662',
      volume: '6030200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-21': {
      open: '131.42',
      high: '131.94',
      low: '130.22',
      close: '130.65',
      'adjusted close': '78.8352440441938',
      volume: '6857800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-18': {
      open: '131.02',
      high: '131.25',
      low: '130.13',
      close: '130.15',
      'adjusted close': '78.5335400868873',
      volume: '9581400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-17': {
      open: '130.07',
      high: '131.03',
      low: '129.86',
      close: '130.98',
      'adjusted close': '79.0343686560161',
      volume: '5571000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-16': {
      open: '128.34',
      high: '130.68',
      low: '128.34',
      close: '130.35',
      'adjusted close': '78.6542216698099',
      volume: '6401000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-15': {
      open: '128.93',
      high: '129.95',
      low: '128.37',
      close: '129.79',
      'adjusted close': '78.3163132376266',
      volume: '6652500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-14': {
      open: '128.5',
      high: '129.97',
      low: '128.49',
      close: '128.5',
      'adjusted close': '77.5379170277758',
      volume: '6753000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-11': {
      open: '126.73',
      high: '128.8',
      low: '126.44',
      close: '128.45',
      'adjusted close': '77.5077466320451',
      volume: '5820200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-10': {
      open: '125.99',
      high: '128.22',
      low: '125.8',
      close: '127.68',
      'adjusted close': '77.0431225377931',
      volume: '7479700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-09': {
      open: '124.83',
      high: '125.84',
      low: '123.58',
      close: '123.9',
      'adjusted close': '74.7622406205558',
      volume: '7795500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-08': {
      open: '124.26',
      high: '124.46',
      low: '122.82',
      close: '123.72',
      'adjusted close': '74.6536271959255',
      volume: '8399200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-07': {
      open: '125.57',
      high: '125.86',
      low: '124.13',
      close: '124.13',
      'adjusted close': '74.9010244409168',
      volume: '6951400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-04': {
      open: '126.37',
      high: '127.1',
      low: '124.67',
      close: '125.28',
      'adjusted close': '75.5949435427218',
      volume: '9669200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-03': {
      open: '127.75',
      high: '128.22',
      low: '126.46',
      close: '127.96',
      'adjusted close': '77.2120767538847',
      volume: '6645300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-02': {
      open: '124.85',
      high: '127.5',
      low: '124.35',
      close: '127.41',
      'adjusted close': '76.8802024008476',
      volume: '7705300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-06-01': {
      open: '124.69',
      high: '126.88',
      low: '124.2',
      close: '124.34',
      'adjusted close': '75.0277401029855',
      volume: '7135400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-28': {
      open: '125.96',
      high: '126.2794',
      low: '124.29',
      close: '125.26',
      'adjusted close': '75.5828753844295',
      volume: '7421900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-27': {
      open: '125.05',
      high: '126.39',
      low: '124.77',
      close: '126.39',
      'adjusted close': '76.2647263279423',
      volume: '7725900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-26': {
      open: '124.89',
      high: '125.94',
      low: '123.0',
      close: '123.23',
      'adjusted close': '74.3579573177651',
      volume: '9083200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-25': {
      open: '121.47',
      high: '124.95',
      low: '121.47',
      close: '124.52',
      'adjusted close': '75.1363535276159',
      volume: '9494800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-24': {
      open: '125.26',
      high: '126.02',
      low: '124.04',
      close: '124.45',
      'adjusted close': '75.094114973593',
      volume: '6868600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-21': {
      open: '122.16',
      high: '125.61',
      low: '121.4',
      close: '125.42',
      'adjusted close': '75.6794206507676',
      volume: '12639500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-20': {
      open: '127.22',
      high: '127.96',
      low: '123.68',
      close: '123.8',
      'adjusted close': '74.7018998290945',
      volume: '13176500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-19': {
      open: '129.37',
      high: '130.5',
      low: '127.82',
      close: '128.86',
      'adjusted close': '77.7551438770365',
      volume: '8669300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-18': {
      open: '131.26',
      high: '131.99',
      low: '129.9',
      close: '129.95',
      'adjusted close': '78.4128585039647',
      volume: '9330000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-17': {
      open: '130.68',
      high: '131.76',
      low: '128.7',
      close: '130.44',
      'adjusted close': '78.7085283821251',
      volume: '8923400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-14': {
      open: '131.06',
      high: '131.67',
      low: '129.41',
      close: '131.19',
      'adjusted close': '79.1610843180849',
      volume: '9920100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-13': {
      open: '130.93',
      high: '133.1',
      low: '130.85',
      close: '131.48',
      'adjusted close': '79.3360726133227',
      volume: '10497800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-12': {
      open: '127.16',
      high: '132.85',
      low: '127.01',
      close: '132.68',
      'adjusted close': '80.0601621108583',
      volume: '16626900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-11': {
      open: '125.21',
      high: '128.42',
      low: '125.15',
      close: '126.89',
      'adjusted close': '76.5664302852488',
      volume: '6498900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-10': {
      open: '126.27',
      high: '126.67',
      low: '125.06',
      close: '126.27',
      'adjusted close': '76.1923173781887',
      volume: '8463900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-07': {
      open: '123.36',
      high: '124.39',
      low: '120.0',
      close: '122.1',
      'adjusted close': '73.6761063742523',
      volume: '10584800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-06': {
      open: '126.29',
      high: '127.93',
      low: '116.0',
      close: '123.92',
      'adjusted close': '74.7743087788481',
      volume: '13168800',
      'dividend amount': '0.6500',
      'split coefficient': '1.0',
    },
    '2010-05-05': {
      open: '127.12',
      high: '128.23',
      low: '126.87',
      close: '127.46',
      'adjusted close': '76.5090583362926',
      volume: '6072800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-04': {
      open: '128.89',
      high: '128.93',
      low: '126.5754',
      close: '128.12',
      'adjusted close': '76.9052295155014',
      volume: '8285000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-05-03': {
      open: '129.39',
      high: '130.14',
      low: '128.8',
      close: '129.6',
      'adjusted close': '77.793613371909',
      volume: '4992100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-30': {
      open: '130.43',
      high: '130.636',
      low: '128.84',
      close: '129.0',
      'adjusted close': '77.4334577544465',
      volume: '6266700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-29': {
      open: '130.55',
      high: '131.21',
      low: '130.15',
      close: '130.46',
      'adjusted close': '78.3098364236053',
      volume: '5786900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-28': {
      open: '129.4',
      high: '130.47',
      low: '129.03',
      close: '130.1',
      'adjusted close': '78.0937430531278',
      volume: '7122300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-27': {
      open: '129.9',
      high: '132.0',
      low: '128.71',
      close: '128.82',
      'adjusted close': '77.3254110692077',
      volume: '10916700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-26': {
      open: '129.76',
      high: '131.04',
      low: '129.54',
      close: '130.73',
      'adjusted close': '78.4719064514635',
      volume: '5285400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-23': {
      open: '129.08',
      high: '130.1',
      low: '128.71',
      close: '129.99',
      'adjusted close': '78.0277145232597',
      volume: '6197300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-22': {
      open: '128.64',
      high: '129.36',
      low: '127.77',
      close: '129.13',
      'adjusted close': '77.5114914715634',
      volume: '6018500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-21': {
      open: '129.87',
      high: '130.27',
      low: '128.5',
      close: '128.99',
      'adjusted close': '77.4274551608221',
      volume: '7559200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-20': {
      open: '129.2',
      high: '130.33',
      low: '128.26',
      close: '129.69',
      'adjusted close': '77.8476367145284',
      volume: '15216500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-19': {
      open: '130.38',
      high: '132.28',
      low: '130.38',
      close: '132.23',
      'adjusted close': '79.3722954951198',
      volume: '11353700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-16': {
      open: '130.68',
      high: '132.17',
      low: '130.25',
      close: '130.63',
      'adjusted close': '78.4118805152197',
      volume: '9546200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-15': {
      open: '130.53',
      high: '131.14',
      low: '130.1902',
      close: '130.89',
      'adjusted close': '78.5679479494535',
      volume: '6425300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-14': {
      open: '129.73',
      high: '131.42',
      low: '129.46',
      close: '131.25',
      'adjusted close': '78.784041319931',
      volume: '8545800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-13': {
      open: '128.26',
      high: '129.435',
      low: '127.84',
      close: '129.03',
      'adjusted close': '77.4514655353196',
      volume: '6819100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-12': {
      open: '128.57',
      high: '128.956',
      low: '128.24',
      close: '128.36',
      'adjusted close': '77.0492917624865',
      volume: '3992300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-09': {
      open: '127.88',
      high: '128.87',
      low: '127.12',
      close: '128.76',
      'adjusted close': '77.2893955074615',
      volume: '5185100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-08': {
      open: '128.04',
      high: '128.23',
      low: '127.2',
      close: '127.61',
      'adjusted close': '76.5990972406583',
      volume: '6006900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-07': {
      open: '128.53',
      high: '129.27',
      low: '128.01',
      close: '128.48',
      'adjusted close': '77.121322885979',
      volume: '5157000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-06': {
      open: '128.68',
      high: '129.3',
      low: '128.05',
      close: '128.93',
      'adjusted close': '77.3914395990759',
      volume: '3926300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-05': {
      open: '128.38',
      high: '129.8',
      low: '128.14',
      close: '129.35',
      'adjusted close': '77.6435485312996',
      volume: '4118700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-04-01': {
      open: '128.95',
      high: '129.31',
      low: '127.55',
      close: '128.25',
      'adjusted close': '76.9832632326183',
      volume: '4980500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-31': {
      open: '128.23',
      high: '128.75',
      low: '127.65',
      close: '128.25',
      'adjusted close': '76.9832632326183',
      volume: '4904800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-30': {
      open: '128.9',
      high: '129.13',
      low: '128.25',
      close: '128.77',
      'adjusted close': '77.2953981010859',
      volume: '3426600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-29': {
      open: '129.3',
      high: '129.95',
      low: '128.26',
      close: '128.59',
      'adjusted close': '77.1873514158471',
      volume: '4643000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-26': {
      open: '128.93',
      high: '129.78',
      low: '128.7205',
      close: '129.26',
      'adjusted close': '77.5895251886803',
      volume: '5550800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-25': {
      open: '129.36',
      high: '130.73',
      low: '129.13',
      close: '129.24',
      'adjusted close': '77.5775200014315',
      volume: '7603200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-24': {
      open: '128.63',
      high: '129.95',
      low: '128.47',
      close: '128.53',
      'adjusted close': '77.1513358541008',
      volume: '6669300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-23': {
      open: '127.94',
      high: '129.43',
      low: '127.64',
      close: '129.37',
      'adjusted close': '77.6555537185484',
      volume: '5978500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-22': {
      open: '127.11',
      high: '128.39',
      low: '126.57',
      close: '127.98',
      'adjusted close': '76.8211932047602',
      volume: '5651900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-19': {
      open: '128.84',
      high: '128.93',
      low: '126.78',
      close: '127.71',
      'adjusted close': '76.659123176902',
      volume: '10744300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-18': {
      open: '127.46',
      high: '128.75',
      low: '127.45',
      close: '128.38',
      'adjusted close': '77.0612969497352',
      volume: '4954400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-17': {
      open: '128.9',
      high: '128.93',
      low: '127.36',
      close: '127.76',
      'adjusted close': '76.6891361450239',
      volume: '6348400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-16': {
      open: '128.0',
      high: '128.88',
      low: '127.45',
      close: '128.67',
      'adjusted close': '77.2353721648421',
      volume: '6134900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-15': {
      open: '127.4',
      high: '128.34',
      low: '127.28',
      close: '127.83',
      'adjusted close': '76.7311543003945',
      volume: '4497700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-12': {
      open: '127.91',
      high: '128.37',
      low: '127.51',
      close: '127.94',
      'adjusted close': '76.7971828302627',
      volume: '5169500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-11': {
      open: '125.71',
      high: '127.81',
      low: '125.71',
      close: '127.6',
      'adjusted close': '76.5930946470339',
      volume: '7922200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-10': {
      open: '125.985',
      high: '126.36',
      low: '125.21',
      close: '125.62',
      'adjusted close': '75.4045811094075',
      volume: '6916200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-09': {
      open: '126.27',
      high: '126.29',
      low: '125.2',
      close: '125.55',
      'adjusted close': '75.3625629540369',
      volume: '7528800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-08': {
      open: '127.06',
      high: '127.5',
      low: '126.36',
      close: '126.41',
      'adjusted close': '75.8787860057332',
      volume: '6199300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-05': {
      open: '127.17',
      high: '127.55',
      low: '127.04',
      close: '127.25',
      'adjusted close': '76.3830038701807',
      volume: '6140200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-04': {
      open: '126.88',
      high: '127.07',
      low: '125.47',
      close: '126.72',
      'adjusted close': '76.0648664080888',
      volume: '6032300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-03': {
      open: '127.73',
      high: '128.02',
      low: '126.68',
      close: '126.88',
      'adjusted close': '76.1609079060788',
      volume: '6390000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-02': {
      open: '128.7',
      high: '129.09',
      low: '127.13',
      close: '127.42',
      'adjusted close': '76.4850479617951',
      volume: '6013300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-03-01': {
      open: '127.5',
      high: '128.83',
      low: '127.47',
      close: '128.57',
      'adjusted close': '77.1753462285983',
      volume: '4577700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-26': {
      open: '127.01',
      high: '128.0',
      low: '126.74',
      close: '127.16',
      'adjusted close': '76.3289805275614',
      volume: '4784200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-25': {
      open: '126.05',
      high: '127.24',
      low: '125.57',
      close: '127.07',
      'adjusted close': '76.274957184942',
      volume: '5658700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-24': {
      open: '127.02',
      high: '128.27',
      low: '126.81',
      close: '127.59',
      'adjusted close': '76.5870920534095',
      volume: '4782000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-23': {
      open: '126.48',
      high: '127.66',
      low: '126.0',
      close: '126.46',
      'adjusted close': '75.9087989738551',
      volume: '4591900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-22': {
      open: '127.3',
      high: '127.43',
      low: '126.31',
      close: '126.85',
      'adjusted close': '76.1429001252057',
      volume: '3808100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-19': {
      open: '127.35',
      high: '128.06',
      low: '126.8724',
      close: '127.19',
      'adjusted close': '76.3469883084345',
      volume: '6303100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-18': {
      open: '126.13',
      high: '128.0',
      low: '126.0',
      close: '127.81',
      'adjusted close': '76.7191491131458',
      volume: '5525900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-17': {
      open: '125.5',
      high: '126.53',
      low: '125.21',
      close: '126.33',
      'adjusted close': '75.8307652567382',
      volume: '5827400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-16': {
      open: '124.91',
      high: '125.23',
      low: '124.11',
      close: '125.23',
      'adjusted close': '75.1704799580569',
      volume: '6777300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-12': {
      open: '123.01',
      high: '124.05',
      low: '121.61',
      close: '124.0',
      'adjusted close': '74.4321609422586',
      volume: '8017700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-11': {
      open: '122.58',
      high: '124.2',
      low: '122.06',
      close: '123.73',
      'adjusted close': '74.2700909144005',
      volume: '5089000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-10': {
      open: '122.94',
      high: '123.65',
      low: '122.21',
      close: '122.81',
      'adjusted close': '73.7178523009579',
      volume: '5219100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-09': {
      open: '122.65',
      high: '124.2',
      low: '122.46',
      close: '123.21',
      'adjusted close': '73.957956045933',
      volume: '6044500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-08': {
      open: '123.15',
      high: '123.22',
      low: '121.74',
      close: '121.88',
      'adjusted close': '73.159611093891',
      volume: '5718500',
      'dividend amount': '0.5500',
      'split coefficient': '1.0',
    },
    '2010-02-05': {
      open: '123.04',
      high: '123.72',
      low: '121.83',
      close: '122.97',
      'adjusted close': '73.4822949948197',
      volume: '8617000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-04': {
      open: '125.19',
      high: '125.44',
      low: '122.9',
      close: '123.0',
      'adjusted close': '73.5002218782046',
      volume: '9126900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-03': {
      open: '125.16',
      high: '126.07',
      low: '125.07',
      close: '125.66',
      'adjusted close': '75.0897388716682',
      volume: '4177100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-02': {
      open: '124.79',
      high: '125.81',
      low: '123.95',
      close: '125.53',
      'adjusted close': '75.0120557103336',
      volume: '5899900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-02-01': {
      open: '123.23',
      high: '124.95',
      low: '122.78',
      close: '124.67',
      'adjusted close': '74.4981517199656',
      volume: '7242900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-29': {
      open: '124.32',
      high: '125.0',
      low: '121.9',
      close: '122.39',
      'adjusted close': '73.1357085827111',
      volume: '11571200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-28': {
      open: '127.03',
      high: '127.04',
      low: '123.05',
      close: '123.75',
      'adjusted close': '73.9483939628278',
      volume: '9622200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-27': {
      open: '125.82',
      high: '126.96',
      low: '125.04',
      close: '126.33',
      'adjusted close': '75.4901059339316',
      volume: '8719200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-26': {
      open: '125.92',
      high: '127.75',
      low: '125.41',
      close: '125.75',
      'adjusted close': '75.143519521823',
      volume: '7135300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-25': {
      open: '126.33',
      high: '126.895',
      low: '125.71',
      close: '126.12',
      'adjusted close': '75.3646177502371',
      volume: '5738500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-22': {
      open: '128.67',
      high: '128.89',
      low: '125.37',
      close: '125.5',
      'adjusted close': '74.9941288269486',
      volume: '10088600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-21': {
      open: '130.47',
      high: '130.69',
      low: '128.06',
      close: '129.0',
      'adjusted close': '77.0855985551902',
      volume: '9608600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-20': {
      open: '130.46',
      high: '131.15',
      low: '128.95',
      close: '130.25',
      'adjusted close': '77.8325520295622',
      volume: '15197500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-19': {
      open: '131.63',
      high: '134.25',
      low: '131.56',
      close: '134.14',
      'adjusted close': '80.1570712418079',
      volume: '13916200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-15': {
      open: '132.03',
      high: '132.89',
      low: '131.089',
      close: '131.78',
      'adjusted close': '78.7468230821935',
      volume: '8494400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-14': {
      open: '130.55',
      high: '132.71',
      low: '129.91',
      close: '132.31',
      'adjusted close': '79.0635313553273',
      volume: '7111800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-13': {
      open: '130.39',
      high: '131.12',
      low: '129.16',
      close: '130.23',
      'adjusted close': '77.8206007739723',
      volume: '6455400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-12': {
      open: '129.03',
      high: '131.33',
      low: '129.0',
      close: '130.51',
      'adjusted close': '77.9879183522316',
      volume: '8081500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-11': {
      open: '131.06',
      high: '131.06',
      low: '128.67',
      close: '129.48',
      'adjusted close': '77.3724286893491',
      volume: '5730400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-08': {
      open: '129.07',
      high: '130.9199',
      low: '129.05',
      close: '130.85',
      'adjusted close': '78.1910896972608',
      volume: '4197200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-07': {
      open: '129.87',
      high: '130.25',
      low: '128.91',
      close: '129.55',
      'adjusted close': '77.4142580839139',
      volume: '5840600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-06': {
      open: '130.68',
      high: '131.49',
      low: '129.81',
      close: '130.0',
      'adjusted close': '77.6831613346878',
      volume: '5605300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-05': {
      open: '131.68',
      high: '131.85',
      low: '130.1',
      close: '130.85',
      'adjusted close': '78.1910896972608',
      volume: '6841400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2010-01-04': {
      open: '131.18',
      high: '132.97',
      low: '130.85',
      close: '132.45',
      'adjusted close': '79.1471901444569',
      volume: '6155300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-31': {
      open: '132.41',
      high: '132.85',
      low: '130.75',
      close: '130.9',
      'adjusted close': '78.2209678362357',
      volume: '4223400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-30': {
      open: '131.23',
      high: '132.68',
      low: '130.68',
      close: '132.57',
      'adjusted close': '79.2188976779967',
      volume: '3867000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-29': {
      open: '132.28',
      high: '132.37',
      low: '131.8',
      close: '131.85',
      'adjusted close': '78.7886524767584',
      volume: '4184200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-28': {
      open: '130.99',
      high: '132.31',
      low: '130.72',
      close: '132.31',
      'adjusted close': '79.0635313553273',
      volume: '5800400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-24': {
      open: '129.89',
      high: '130.57',
      low: '129.48',
      close: '130.57',
      'adjusted close': '78.0237721190014',
      volume: '4265100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-23': {
      open: '129.7',
      high: '130.0',
      low: '129.3',
      close: '130.0',
      'adjusted close': '77.6831613346878',
      volume: '4127600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-22': {
      open: '129.3',
      high: '129.98',
      low: '129.19',
      close: '129.93',
      'adjusted close': '77.641331940123',
      volume: '5535500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-21': {
      open: '127.8',
      high: '128.94',
      low: '127.68',
      close: '128.65',
      'adjusted close': '76.8764515823661',
      volume: '4772500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-18': {
      open: '127.97',
      high: '128.39',
      low: '127.0',
      close: '127.91',
      'adjusted close': '76.4342551255378',
      volume: '9106600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-17': {
      open: '128.0',
      high: '128.56',
      low: '127.12',
      close: '127.4',
      'adjusted close': '76.1294981079941',
      volume: '5909500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-16': {
      open: '128.74',
      high: '129.6',
      low: '128.35',
      close: '128.71',
      'adjusted close': '76.9123053491359',
      volume: '6372500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-15': {
      open: '129.46',
      high: '129.86',
      low: '127.94',
      close: '128.49',
      'adjusted close': '76.7808415376465',
      volume: '7862600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-14': {
      open: '129.65',
      high: '129.98',
      low: '129.6',
      close: '129.93',
      'adjusted close': '77.641331940123',
      volume: '5201300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-11': {
      open: '129.01',
      high: '129.77',
      low: '128.71',
      close: '129.68',
      'adjusted close': '77.4919412452486',
      volume: '6597200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-10': {
      open: '128.13',
      high: '129.47',
      low: '128.09',
      close: '129.34',
      'adjusted close': '77.2887699002194',
      volume: '7077800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-09': {
      open: '126.7',
      high: '128.39',
      low: '126.11',
      close: '128.39',
      'adjusted close': '76.7210852596967',
      volume: '6071900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-08': {
      open: '126.97',
      high: '127.35',
      low: '126.16',
      close: '126.8',
      'adjusted close': '75.7709604402955',
      volume: '5351400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-07': {
      open: '126.88',
      high: '127.53',
      low: '126.59',
      close: '127.04',
      'adjusted close': '75.9143755073749',
      volume: '4144400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-04': {
      open: '128.4',
      high: '128.9',
      low: '126.0',
      close: '127.25',
      'adjusted close': '76.0398636910694',
      volume: '7068500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-03': {
      open: '127.6',
      high: '128.47',
      low: '127.25',
      close: '127.55',
      'adjusted close': '76.2191325249187',
      volume: '5760000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-02': {
      open: '127.32',
      high: '128.39',
      low: '127.16',
      close: '127.21',
      'adjusted close': '76.0159611798895',
      volume: '4597900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-12-01': {
      open: '127.29',
      high: '128.39',
      low: '126.85',
      close: '127.94',
      'adjusted close': '76.4521820089228',
      volume: '6578600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-30': {
      open: '125.12',
      high: '126.6',
      low: '124.92',
      close: '126.35',
      'adjusted close': '75.5020571895216',
      volume: '6302700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-27': {
      open: '124.59',
      high: '126.62',
      low: '124.26',
      close: '125.7',
      'adjusted close': '75.1136413828481',
      volume: '3319200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-25': {
      open: '127.88',
      high: '128.22',
      low: '127.08',
      close: '127.28',
      'adjusted close': '76.0577905744544',
      volume: '3967700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-24': {
      open: '127.9',
      high: '128.32',
      low: '127.14',
      close: '127.93',
      'adjusted close': '76.4462063811278',
      volume: '4816500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-23': {
      open: '127.7',
      high: '128.94',
      low: '127.65',
      close: '128.2',
      'adjusted close': '76.6075483315921',
      volume: '7003500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-20': {
      open: '127.17',
      high: '127.69',
      low: '126.46',
      close: '126.96',
      'adjusted close': '75.8665704850151',
      volume: '5354200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-19': {
      open: '127.28',
      high: '127.84',
      low: '126.51',
      close: '127.54',
      'adjusted close': '76.2131568971237',
      volume: '5308400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-18': {
      open: '128.05',
      high: '128.35',
      low: '127.55',
      close: '128.15',
      'adjusted close': '76.5776701926173',
      volume: '3970100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-17': {
      open: '127.43',
      high: '128.655',
      low: '127.4',
      close: '128.63',
      'adjusted close': '76.8645003267761',
      volume: '1027500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-16': {
      open: '127.17',
      high: '128.24',
      low: '127.03',
      close: '128.21',
      'adjusted close': '76.6135239593871',
      volume: '7822500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-13': {
      open: '126.41',
      high: '127.8',
      low: '126.27',
      close: '127.03',
      'adjusted close': '75.90839987958',
      volume: '4979700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-12': {
      open: '126.61',
      high: '127.65',
      low: '126.11',
      close: '126.26',
      'adjusted close': '75.4482765393668',
      volume: '5345400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-11': {
      open: '126.55',
      high: '127.3',
      low: '125.94',
      close: '127.19',
      'adjusted close': '76.0040099242996',
      volume: '6530800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-10': {
      open: '125.3',
      high: '127.05',
      low: '125.17',
      close: '126.91',
      'adjusted close': '75.8366923460402',
      volume: '6444500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-09': {
      open: '123.92',
      high: '126.0',
      low: '123.49',
      close: '126.0',
      'adjusted close': '75.2929102166974',
      volume: '7764600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-06': {
      open: '122.51',
      high: '123.49',
      low: '122.23',
      close: '123.49',
      'adjusted close': '73.7930276401585',
      volume: '4979600',
      'dividend amount': '0.5500',
      'split coefficient': '1.0',
    },
    '2009-11-05': {
      open: '122.12',
      high: '123.5',
      low: '122.0',
      close: '122.55',
      'adjusted close': '72.9066070404823',
      volume: '6701800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-04': {
      open: '121.46',
      high: '122.5',
      low: '121.16',
      close: '121.29',
      'adjusted close': '72.1570164662594',
      volume: '6353300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-03': {
      open: '119.53',
      high: '121.19',
      low: '119.53',
      close: '121.16',
      'adjusted close': '72.0796777562206',
      volume: '5706800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-11-02': {
      open: '120.61',
      high: '121.93',
      low: '119.5',
      close: '120.56',
      'adjusted close': '71.7227298637335',
      volume: '7049400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-30': {
      open: '122.63',
      high: '124.3',
      low: '120.48',
      close: '120.61',
      'adjusted close': '71.7524755214408',
      volume: '7711700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-29': {
      open: '122.15',
      high: '123.03',
      low: '121.6',
      close: '122.87',
      'adjusted close': '73.0969792498087',
      volume: '6309400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-28': {
      open: '120.47',
      high: '121.68',
      low: '119.87',
      close: '121.5',
      'adjusted close': '72.2819482286299',
      volume: '8780400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-27': {
      open: '120.18',
      high: '122.14',
      low: '119.15',
      close: '120.65',
      'adjusted close': '71.7762720476066',
      volume: '9445400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-26': {
      open: '120.61',
      high: '122.44',
      low: '119.55',
      close: '120.11',
      'adjusted close': '71.4550189443682',
      volume: '6538500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-23': {
      open: '122.95',
      high: '123.06',
      low: '120.0',
      close: '120.36',
      'adjusted close': '71.6037472329045',
      volume: '6799200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-22': {
      open: '120.75',
      high: '123.0',
      low: '120.34',
      close: '122.69',
      'adjusted close': '72.9898948820626',
      volume: '6027000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-21': {
      open: '122.18',
      high: '124.11',
      low: '120.86',
      close: '120.87',
      'adjusted close': '71.9071529415185',
      volume: '7171000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-20': {
      open: '123.36',
      high: '123.5',
      low: '121.5',
      close: '122.82',
      'adjusted close': '73.0672335921014',
      volume: '8780700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-19': {
      open: '121.76',
      high: '123.36',
      low: '121.61',
      close: '123.06',
      'adjusted close': '73.2100127490963',
      volume: '7514100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-16': {
      open: '123.15',
      high: '123.7',
      low: '121.25',
      close: '121.64',
      'adjusted close': '72.3652360702102',
      volume: '19216900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-15': {
      open: '127.56',
      high: '128.0',
      low: '126.32',
      close: '127.98',
      'adjusted close': '76.1369854674902',
      volume: '11904600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-14': {
      open: '127.71',
      high: '128.61',
      low: '126.6',
      close: '128.35',
      'adjusted close': '76.3571033345238',
      volume: '9545500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-13': {
      open: '126.22',
      high: '127.39',
      low: '126.2',
      close: '127.02',
      'adjusted close': '75.5658688395109',
      volume: '7581300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-12': {
      open: '125.44',
      high: '127.1',
      low: '125.2',
      close: '127.04',
      'adjusted close': '75.5777671025938',
      volume: '7730500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-09': {
      open: '122.43',
      high: '126.0',
      low: '122.3',
      close: '125.93',
      'adjusted close': '74.9174135014927',
      volume: '13123100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-08': {
      open: '122.52',
      high: '122.98',
      low: '121.85',
      close: '122.29',
      'adjusted close': '72.7519296204045',
      volume: '6498000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-07': {
      open: '121.12',
      high: '122.85',
      low: '120.94',
      close: '122.78',
      'adjusted close': '73.0434370659356',
      volume: '5872000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-06': {
      open: '120.39',
      high: '121.59',
      low: '120.22',
      close: '121.35',
      'adjusted close': '72.1927112555081',
      volume: '6659100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-05': {
      open: '118.9',
      high: '120.18',
      low: '118.13',
      close: '119.75',
      'adjusted close': '71.240850208876',
      volume: '5673300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-02': {
      open: '117.4',
      high: '119.66',
      low: '117.26',
      close: '119.02',
      'adjusted close': '70.80656360635',
      volume: '7581900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-10-01': {
      open: '119.39',
      high: '119.61',
      low: '117.56',
      close: '117.9',
      'adjusted close': '70.1402608737075',
      volume: '7067500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-30': {
      open: '119.18',
      high: '120.0',
      low: '117.55',
      close: '119.61',
      'adjusted close': '71.1575623672957',
      volume: '7733300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-29': {
      open: '119.36',
      high: '119.94',
      low: '118.6',
      close: '118.81',
      'adjusted close': '70.6816318439796',
      volume: '6748800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-28': {
      open: '120.52',
      high: '122.0',
      low: '118.94',
      close: '119.33',
      'adjusted close': '70.990986684135',
      volume: '8248600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-25': {
      open: '120.27',
      high: '121.65',
      low: '120.1',
      close: '121.08',
      'adjusted close': '72.032084703889',
      volume: '5187900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-24': {
      open: '120.69',
      high: '121.65',
      low: '120.4',
      close: '120.94',
      'adjusted close': '71.9487968623086',
      volume: '5426800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-23': {
      open: '121.26',
      high: '122.74',
      low: '120.71',
      close: '120.82',
      'adjusted close': '71.8774072838112',
      volume: '5538100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-22': {
      open: '122.09',
      high: '122.11',
      low: '120.98',
      close: '121.61',
      'adjusted close': '72.3473886755859',
      volume: '5564700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-21': {
      open: '121.03',
      high: '122.08',
      low: '120.88',
      close: '121.57',
      'adjusted close': '72.3235921494201',
      volume: '4515100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-18': {
      open: '122.45',
      high: '122.88',
      low: '121.62',
      close: '122.11',
      'adjusted close': '72.6448452526584',
      volume: '9935800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-17': {
      open: '121.14',
      high: '122.78',
      low: '121.05',
      close: '121.88',
      'adjusted close': '72.508015227205',
      volume: '8392600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-16': {
      open: '119.5',
      high: '122.0',
      low: '119.3',
      close: '121.82',
      'adjusted close': '72.4723204379563',
      volume: '11236700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-15': {
      open: '118.78',
      high: '119.45',
      low: '118.16',
      close: '119.35',
      'adjusted close': '71.0028849472179',
      volume: '6536000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-14': {
      open: '117.0',
      high: '118.99',
      low: '116.94',
      close: '118.88',
      'adjusted close': '70.7232757647697',
      volume: '4758600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-11': {
      open: '117.8',
      high: '119.25',
      low: '117.47',
      close: '118.05',
      'adjusted close': '70.2294978468293',
      volume: '5893700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-10': {
      open: '116.99',
      high: '117.83',
      low: '116.75',
      close: '117.67',
      'adjusted close': '70.0034308482542',
      volume: '4889400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-09': {
      open: '117.03',
      high: '117.67',
      low: '116.16',
      close: '116.76',
      'adjusted close': '69.4620598779821',
      volume: '5248100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-08': {
      open: '117.94',
      high: '118.17',
      low: '116.63',
      close: '117.16',
      'adjusted close': '69.7000251396402',
      volume: '5797500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-04': {
      open: '116.58',
      high: '117.5',
      low: '116.21',
      close: '117.46',
      'adjusted close': '69.8784990858837',
      volume: '3451700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-03': {
      open: '116.55',
      high: '116.73',
      low: '115.15',
      close: '116.33',
      'adjusted close': '69.2062472216997',
      volume: '4936200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-02': {
      open: '116.34',
      high: '117.24',
      low: '116.0',
      close: '116.09',
      'adjusted close': '69.0634680647049',
      volume: '5178800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-09-01': {
      open: '117.67',
      high: '118.93',
      low: '116.5',
      close: '116.69',
      'adjusted close': '69.420415957192',
      volume: '6303800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-31': {
      open: '117.1',
      high: '118.06',
      low: '116.92',
      close: '118.05',
      'adjusted close': '70.2294978468293',
      volume: '5439400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-28': {
      open: '120.1',
      high: '120.23',
      low: '117.77',
      close: '118.22',
      'adjusted close': '70.330633083034',
      volume: '6235700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-27': {
      open: '118.73',
      high: '119.59',
      low: '117.85',
      close: '119.43',
      'adjusted close': '71.0504779995495',
      volume: '4356900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-26': {
      open: '118.64',
      high: '119.85',
      low: '117.51',
      close: '119.47',
      'adjusted close': '71.0742745257153',
      volume: '4515500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-25': {
      open: '119.25',
      high: '120.15',
      low: '118.71',
      close: '118.83',
      'adjusted close': '70.6935301070625',
      volume: '4652200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-24': {
      open: '119.92',
      high: '120.88',
      low: '119.1',
      close: '119.32',
      'adjusted close': '70.9850375525936',
      volume: '6363400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-21': {
      open: '119.65',
      high: '120.01',
      low: '118.55',
      close: '119.9',
      'adjusted close': '71.3300871819977',
      volume: '8306800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-20': {
      open: '118.44',
      high: '119.41',
      low: '118.1',
      close: '118.95',
      'adjusted close': '70.7649196855599',
      volume: '4841700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-19': {
      open: '116.36',
      high: '118.77',
      low: '116.12',
      close: '118.57',
      'adjusted close': '70.5388526869848',
      volume: '4837900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-18': {
      open: '116.81',
      high: '118.0',
      low: '116.73',
      close: '117.63',
      'adjusted close': '69.9796343220884',
      volume: '4286200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-17': {
      open: '116.69',
      high: '117.44',
      low: '116.21',
      close: '116.86',
      'adjusted close': '69.5215511933966',
      volume: '5349600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-14': {
      open: '119.29',
      high: '119.74',
      low: '117.62',
      close: '118.57',
      'adjusted close': '70.5388526869848',
      volume: '4796400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-13': {
      open: '119.28',
      high: '119.78',
      low: '118.63',
      close: '119.58',
      'adjusted close': '71.1397149726713',
      volume: '5489200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-12': {
      open: '117.6',
      high: '119.96',
      low: '117.42',
      close: '119.29',
      'adjusted close': '70.9671901579692',
      volume: '6289700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-11': {
      open: '118.03',
      high: '118.5',
      low: '117.32',
      close: '117.79',
      'adjusted close': '70.0748204267516',
      volume: '4844500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-10': {
      open: '118.17',
      high: '119.19',
      low: '117.97',
      close: '118.7',
      'adjusted close': '70.6161913970236',
      volume: '3981300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-07': {
      open: '118.11',
      high: '119.91',
      low: '118.11',
      close: '119.33',
      'adjusted close': '70.990986684135',
      volume: '5763200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-06': {
      open: '118.31',
      high: '118.4',
      low: '116.7',
      close: '117.38',
      'adjusted close': '69.8309060335521',
      volume: '6003100',
      'dividend amount': '0.5500',
      'split coefficient': '1.0',
    },
    '2009-08-05': {
      open: '118.515',
      high: '119.0',
      low: '117.86',
      close: '117.92',
      'adjusted close': '69.8249846474727',
      volume: '5025800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-04': {
      open: '119.17',
      high: '119.8',
      low: '119.02',
      close: '119.6',
      'adjusted close': '70.8197775088004',
      volume: '4993500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-08-03': {
      open: '118.88',
      high: '119.96',
      low: '118.69',
      close: '119.92',
      'adjusted close': '71.009261863339',
      volume: '6108200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-31': {
      open: '117.56',
      high: '118.99',
      low: '117.56',
      close: '117.93',
      'adjusted close': '69.8309060335521',
      volume: '5329600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-30': {
      open: '118.0',
      high: '119.37',
      low: '117.67',
      close: '117.86',
      'adjusted close': '69.7894563309968',
      volume: '6803100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-29': {
      open: '116.36',
      high: '117.28',
      low: '116.05',
      close: '117.26',
      'adjusted close': '69.4341731662369',
      volume: '5100900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-28': {
      open: '116.63',
      high: '117.52',
      low: '116.34',
      close: '117.28',
      'adjusted close': '69.4460159383956',
      volume: '5782900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-27': {
      open: '116.95',
      high: '117.92',
      low: '116.57',
      close: '117.63',
      'adjusted close': '69.6532644511721',
      volume: '5069200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-24': {
      open: '116.68',
      high: '118.15',
      low: '116.63',
      close: '117.64',
      'adjusted close': '69.6591858372515',
      volume: '6261000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-23': {
      open: '115.06',
      high: '118.0',
      low: '115.06',
      close: '117.06',
      'adjusted close': '69.3157454446503',
      volume: '8461400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-22': {
      open: '116.18',
      high: '117.2',
      low: '115.09',
      close: '115.57',
      'adjusted close': '68.4334589188299',
      volume: '8813400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-21': {
      open: '115.87',
      high: '117.04',
      low: '115.38',
      close: '117.04',
      'adjusted close': '69.3039026724916',
      volume: '8301700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-20': {
      open: '114.53',
      high: '116.88',
      low: '114.39',
      close: '116.44',
      'adjusted close': '68.9486195077317',
      volume: '10682500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-17': {
      open: '113.41',
      high: '115.53',
      low: '113.16',
      close: '115.42',
      'adjusted close': '68.34463812764',
      volume: '20188900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-16': {
      open: '106.84',
      high: '110.97',
      low: '106.79',
      close: '110.64',
      'adjusted close': '65.5142155817197',
      volume: '14997900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-15': {
      open: '104.75',
      high: '107.22',
      low: '104.6',
      close: '107.22',
      'adjusted close': '63.4891015425884',
      volume: '8699100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-14': {
      open: '103.42',
      high: '103.62',
      low: '102.52',
      close: '103.25',
      'adjusted close': '61.138311269094',
      volume: '5413500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-13': {
      open: '101.28',
      high: '103.65',
      low: '100.19',
      close: '103.62',
      'adjusted close': '61.3574025540292',
      volume: '9494300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-10': {
      open: '100.97',
      high: '101.72',
      low: '99.8',
      close: '100.83',
      'adjusted close': '59.7053358378958',
      volume: '7465900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-09': {
      open: '101.12',
      high: '102.78',
      low: '100.85',
      close: '102.08',
      'adjusted close': '60.4455090978122',
      volume: '6153200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-08': {
      open: '100.29',
      high: '101.17',
      low: '99.5',
      close: '100.68',
      'adjusted close': '59.6165150467059',
      volume: '7380800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-07': {
      open: '101.34',
      high: '101.96',
      low: '100.03',
      close: '100.19',
      'adjusted close': '59.3263671288186',
      volume: '6685600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-06': {
      open: '101.57',
      high: '102.23',
      low: '100.47',
      close: '101.65',
      'adjusted close': '60.190889496401',
      volume: '6522500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-02': {
      open: '103.8',
      high: '103.91',
      low: '101.73',
      close: '101.73',
      'adjusted close': '60.2382605850356',
      volume: '7026700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-07-01': {
      open: '105.0',
      high: '106.27',
      low: '104.73',
      close: '104.84',
      'adjusted close': '62.0798116557076',
      volume: '5537300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-30': {
      open: '105.69',
      high: '106.03',
      low: '103.81',
      close: '104.42',
      'adjusted close': '61.8311134403757',
      volume: '7512600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-29': {
      open: '105.99',
      high: '106.18',
      low: '105.16',
      close: '105.83',
      'adjusted close': '62.6660288775614',
      volume: '4681800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-26': {
      open: '106.5',
      high: '106.5',
      low: '105.05',
      close: '105.68',
      'adjusted close': '62.5772080863714',
      volume: '5127900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-25': {
      open: '103.7',
      high: '106.785',
      low: '103.51',
      close: '106.06',
      'adjusted close': '62.802220757386',
      volume: '6900600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-24': {
      open: '105.39',
      high: '106.48',
      low: '103.72',
      close: '104.15',
      'adjusted close': '61.6712360162338',
      volume: '6395200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-23': {
      open: '104.75',
      high: '104.87',
      low: '103.79',
      close: '104.44',
      'adjusted close': '61.8429562125344',
      volume: '5152600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-22': {
      open: '105.18',
      high: '105.88',
      low: '104.23',
      close: '104.52',
      'adjusted close': '61.890327301169',
      volume: '7295400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-19': {
      open: '106.31',
      high: '106.65',
      low: '105.5',
      close: '105.89',
      'adjusted close': '62.7015571940374',
      volume: '13559000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-18': {
      open: '106.93',
      high: '107.53',
      low: '106.12',
      close: '106.33',
      'adjusted close': '62.962098181528',
      volume: '6206900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-17': {
      open: '107.46',
      high: '107.88',
      low: '106.88',
      close: '107.0',
      'adjusted close': '63.3588310488432',
      volume: '7366200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-16': {
      open: '108.47',
      high: '109.14',
      low: '107.27',
      close: '107.32',
      'adjusted close': '63.5483154033817',
      volume: '7231000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-15': {
      open: '107.63',
      high: '108.83',
      low: '107.24',
      close: '107.62',
      'adjusted close': '63.7259569857617',
      volume: '8567600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-12': {
      open: '108.4',
      high: '109.28',
      low: '107.75',
      close: '108.21',
      'adjusted close': '64.0753187644422',
      volume: '7250900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-11': {
      open: '108.75',
      high: '110.64',
      low: '108.56',
      close: '109.4',
      'adjusted close': '64.7799637078826',
      volume: '9134300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-10': {
      open: '109.2',
      high: '109.28',
      low: '107.05',
      close: '108.35',
      'adjusted close': '64.1582181695529',
      volume: '7578100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-09': {
      open: '108.15',
      high: '109.14',
      low: '107.99',
      close: '108.14',
      'adjusted close': '64.0338690618869',
      volume: '8585900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-08': {
      open: '106.64',
      high: '107.98',
      low: '105.5',
      close: '107.49',
      'adjusted close': '63.6489789667304',
      volume: '6363800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-05': {
      open: '107.33',
      high: '108.4',
      low: '106.75',
      close: '107.24',
      'adjusted close': '63.5009443147471',
      volume: '7665000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-04': {
      open: '106.17',
      high: '106.87',
      low: '105.6',
      close: '106.33',
      'adjusted close': '62.962098181528',
      volume: '5468500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-03': {
      open: '106.53',
      high: '106.6',
      low: '105.11',
      close: '106.49',
      'adjusted close': '63.0568403587973',
      volume: '7688000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-02': {
      open: '107.51',
      high: '108.8',
      low: '106.66',
      close: '106.83',
      'adjusted close': '63.2581674854945',
      volume: '7045800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-06-01': {
      open: '106.94',
      high: '108.67',
      low: '106.67',
      close: '108.37',
      'adjusted close': '64.1700609417115',
      volume: '7725000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-29': {
      open: '104.47',
      high: '106.5',
      low: '103.95',
      close: '106.28',
      'adjusted close': '62.9324912511313',
      volume: '6871800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-28': {
      open: '103.34',
      high: '105.14',
      low: '102.68',
      close: '104.69',
      'adjusted close': '61.9909908645177',
      volume: '6127500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-27': {
      open: '104.34',
      high: '105.17',
      low: '102.91',
      close: '102.93',
      'adjusted close': '60.9488269145554',
      volume: '7119600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-26': {
      open: '101.32',
      high: '105.63',
      low: '101.02',
      close: '105.02',
      'adjusted close': '62.1863966051356',
      volume: '7211200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-22': {
      open: '102.67',
      high: '103.31',
      low: '101.75',
      close: '101.89',
      'adjusted close': '60.3330027623049',
      volume: '5596200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-21': {
      open: '103.07',
      high: '103.25',
      low: '101.74',
      close: '102.82',
      'adjusted close': '60.8836916676827',
      volume: '7411500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-20': {
      open: '105.51',
      high: '105.98',
      low: '103.81',
      close: '104.05',
      'adjusted close': '61.6120221554405',
      volume: '7083100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-19': {
      open: '104.33',
      high: '106.12',
      low: '104.0',
      close: '105.51',
      'adjusted close': '62.4765445230228',
      volume: '7079200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-18': {
      open: '102.0',
      high: '104.71',
      low: '101.94',
      close: '104.58',
      'adjusted close': '61.925855617645',
      volume: '7429500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-15': {
      open: '100.9',
      high: '103.07',
      low: '100.57',
      close: '101.37',
      'adjusted close': '60.0250906861797',
      volume: '8367400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-14': {
      open: '101.72',
      high: '102.71',
      low: '100.77',
      close: '101.05',
      'adjusted close': '59.8356063316411',
      volume: '9445100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-13': {
      open: '102.88',
      high: '103.1',
      low: '101.35',
      close: '102.26',
      'adjusted close': '60.5520940472402',
      volume: '8202100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-12': {
      open: '102.98',
      high: '104.38',
      low: '102.37',
      close: '103.94',
      'adjusted close': '61.5468869085678',
      volume: '7961600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-11': {
      open: '101.09',
      high: '103.877',
      low: '100.95',
      close: '102.9',
      'adjusted close': '60.9310627563174',
      volume: '8253000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-08': {
      open: '103.58',
      high: '103.75',
      low: '99.83',
      close: '101.49',
      'adjusted close': '60.0961473191317',
      volume: '11575500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-07': {
      open: '104.65',
      high: '104.7964',
      low: '101.83',
      close: '102.59',
      'adjusted close': '60.7474997878581',
      volume: '8521400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-06': {
      open: '105.23',
      high: '105.5',
      low: '103.71',
      close: '104.62',
      'adjusted close': '61.9495411619623',
      volume: '7530900',
      'dividend amount': '0.5500',
      'split coefficient': '1.0',
    },
    '2009-05-05': {
      open: '106.0',
      high: '106.82',
      low: '105.51',
      close: '105.85',
      'adjusted close': '62.35008968331',
      volume: '6750000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-04': {
      open: '105.26',
      high: '106.75',
      low: '104.61',
      close: '106.19',
      'adjusted close': '62.5503639439838',
      volume: '8313900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-05-01': {
      open: '103.78',
      high: '104.86',
      low: '102.94',
      close: '104.61',
      'adjusted close': '61.6196776737937',
      volume: '6234200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-30': {
      open: '104.55',
      high: '106.05',
      low: '102.85',
      close: '103.21',
      'adjusted close': '60.795018953372',
      volume: '10654500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-29': {
      open: '101.98',
      high: '105.0',
      low: '101.67',
      close: '104.04',
      'adjusted close': '61.2839237661934',
      volume: '9783700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-28': {
      open: '99.36',
      high: '102.73',
      low: '99.25',
      close: '101.94',
      'adjusted close': '60.0469356855609',
      volume: '11110500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-27': {
      open: '99.61',
      high: '101.69',
      low: '99.29',
      close: '99.95',
      'adjusted close': '58.8747422186758',
      volume: '8611400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-24': {
      open: '101.17',
      high: '101.58',
      low: '99.5',
      close: '100.08',
      'adjusted close': '58.9513176712864',
      volume: '10157500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-23': {
      open: '102.65',
      high: '102.78',
      low: '99.8',
      close: '101.42',
      'adjusted close': '59.7406338751186',
      volume: '9440400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-22': {
      open: '101.17',
      high: '103.86',
      low: '101.06',
      close: '102.55',
      'adjusted close': '60.4062512708875',
      volume: '12120000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-21': {
      open: '98.41',
      high: '102.48',
      low: '98.2',
      close: '102.31',
      'adjusted close': '60.2648812045295',
      volume: '15226700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-20': {
      open: '100.29',
      high: '101.1899',
      low: '99.21',
      close: '100.43',
      'adjusted close': '59.1574823513918',
      volume: '12524700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-17': {
      open: '101.18',
      high: '102.04',
      low: '99.69',
      close: '101.27',
      'adjusted close': '59.6522775836448',
      volume: '10214200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-16': {
      open: '99.74',
      high: '101.92',
      low: '99.18',
      close: '101.43',
      'adjusted close': '59.7465242945502',
      volume: '9259500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-15': {
      open: '98.23',
      high: '99.06',
      low: '96.44',
      close: '98.85',
      'adjusted close': '58.2267960812016',
      volume: '8164200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-14': {
      open: '99.08',
      high: '99.95',
      low: '98.27',
      close: '99.27',
      'adjusted close': '58.4741936973281',
      volume: '6276700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-13': {
      open: '100.28',
      high: '101.65',
      low: '99.04',
      close: '99.95',
      'adjusted close': '58.8747422186758',
      volume: '7797200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-09': {
      open: '102.01',
      high: '102.45',
      low: '100.25',
      close: '101.7',
      'adjusted close': '59.9055656192029',
      volume: '8013700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-08': {
      open: '99.45',
      high: '101.55',
      low: '99.0',
      close: '101.19',
      'adjusted close': '59.6051542281921',
      volume: '7285200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-07': {
      open: '100.1725',
      high: '100.5',
      low: '98.52',
      close: '98.75',
      'adjusted close': '58.1678918868858',
      volume: '8768400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-06': {
      open: '100.9',
      high: '101.85',
      low: '100.0',
      close: '101.56',
      'adjusted close': '59.8230997471607',
      volume: '8819800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-03': {
      open: '99.73',
      high: '102.66',
      low: '99.71',
      close: '102.22',
      'adjusted close': '60.2118674296452',
      volume: '10962900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-02': {
      open: '99.15',
      high: '101.99',
      low: '98.38',
      close: '100.82',
      'adjusted close': '59.3872087092236',
      volume: '16219000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-04-01': {
      open: '96.13',
      high: '98.0',
      low: '95.7',
      close: '97.61',
      'adjusted close': '57.4963840716853',
      volume: '12212900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-31': {
      open: '94.96',
      high: '98.66',
      low: '94.85',
      close: '96.89',
      'adjusted close': '57.0722738726113',
      volume: '14553700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-30': {
      open: '92.0',
      high: '94.74',
      low: '91.8',
      close: '94.52',
      'adjusted close': '55.676244467326',
      volume: '10964000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-27': {
      open: '95.99',
      high: '96.0',
      low: '92.75',
      close: '94.15',
      'adjusted close': '55.4582989483575',
      volume: '15745200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-26': {
      open: '98.47',
      high: '99.2',
      low: '97.75',
      close: '98.78',
      'adjusted close': '58.1855631451806',
      volume: '11434600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-25': {
      open: '98.47',
      high: '99.86',
      low: '96.09',
      close: '97.95',
      'adjusted close': '57.6966583323591',
      volume: '12017000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-24': {
      open: '97.69',
      high: '99.55',
      low: '97.52',
      close: '98.3',
      'adjusted close': '57.9028230124646',
      volume: '9346700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-23': {
      open: '93.7',
      high: '98.71',
      low: '93.7',
      close: '98.71',
      'adjusted close': '58.1443302091595',
      volume: '12407200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-20': {
      open: '93.16',
      high: '95.0',
      low: '92.18',
      close: '92.51',
      'adjusted close': '54.4922701615778',
      volume: '12193900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-19': {
      open: '93.08',
      high: '93.35',
      low: '91.51',
      close: '92.66',
      'adjusted close': '54.5806264530515',
      volume: '9899300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-18': {
      open: '89.41',
      high: '92.9',
      low: '89.41',
      close: '91.95',
      'adjusted close': '54.1624066734091',
      volume: '17954300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-17': {
      open: '91.11',
      high: '92.91',
      low: '90.45',
      close: '92.91',
      'adjusted close': '54.7278869388411',
      volume: '11489700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-16': {
      open: '91.34',
      high: '93.0',
      low: '90.54',
      close: '91.22',
      'adjusted close': '53.7324060549035',
      volume: '11793700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-13': {
      open: '90.4',
      high: '90.65',
      low: '89.29',
      close: '90.36',
      'adjusted close': '53.2258299837874',
      volume: '9111900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-12': {
      open: '88.59',
      high: '90.6',
      low: '87.49',
      close: '90.4',
      'adjusted close': '53.2493916615137',
      volume: '11829200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-11': {
      open: '87.51',
      high: '89.54',
      low: '86.72',
      close: '88.62',
      'adjusted close': '52.2008970026919',
      volume: '11823900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-10': {
      open: '85.11',
      high: '87.25',
      low: '83.64',
      close: '87.25',
      'adjusted close': '51.3939095405649',
      volume: '15914800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-09': {
      open: '84.7',
      high: '86.8',
      low: '83.02',
      close: '83.48',
      'adjusted close': '49.173221414858',
      volume: '13151800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-06': {
      open: '87.47',
      high: '88.25',
      low: '83.81',
      close: '85.81',
      'adjusted close': '50.5456891424169',
      volume: '15186000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-05': {
      open: '88.13',
      high: '90.16',
      low: '87.02',
      close: '87.48',
      'adjusted close': '51.5293891874913',
      volume: '14930100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-04': {
      open: '88.97',
      high: '91.24',
      low: '87.602',
      close: '89.49',
      'adjusted close': '52.7133634932396',
      volume: '15391700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-03': {
      open: '90.16',
      high: '90.18',
      low: '87.19',
      close: '87.77',
      'adjusted close': '51.7002113510073',
      volume: '13744100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-03-02': {
      open: '91.17',
      high: '91.9',
      low: '88.75',
      close: '89.05',
      'adjusted close': '52.4541850382499',
      volume: '15231700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-27': {
      open: '88.01',
      high: '93.28',
      low: '88.01',
      close: '92.03',
      'adjusted close': '54.2095300288618',
      volume: '21311200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-26': {
      open: '87.0',
      high: '90.2',
      low: '86.33',
      close: '88.97',
      'adjusted close': '52.4070616827973',
      volume: '17423200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-25': {
      open: '86.0',
      high: '87.12',
      low: '82.85',
      close: '85.9',
      'adjusted close': '50.5987029173012',
      volume: '12646400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-24': {
      open: '84.42',
      high: '87.08',
      low: '84.1',
      close: '86.4',
      'adjusted close': '50.8932238888803',
      volume: '12915300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-23': {
      open: '89.3',
      high: '89.33',
      low: '84.19',
      close: '84.37',
      'adjusted close': '49.6974687442689',
      volume: '13509600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-20': {
      open: '88.05',
      high: '89.9',
      low: '87.34',
      close: '88.79',
      'adjusted close': '52.3010341330288',
      volume: '12400700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-19': {
      open: '91.69',
      high: '92.16',
      low: '88.45',
      close: '88.93',
      'adjusted close': '52.3835000050709',
      volume: '12622700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-18': {
      open: '91.17',
      high: '92.25',
      low: '90.4406',
      close: '91.51',
      'adjusted close': '53.9032282184195',
      volume: '8492600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-17': {
      open: '91.73',
      high: '92.49',
      low: '90.5',
      close: '90.67',
      'adjusted close': '53.4084329861664',
      volume: '8903500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-13': {
      open: '94.79',
      high: '95.18',
      low: '93.57',
      close: '93.84',
      'adjusted close': '55.2756959459784',
      volume: '6742400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-12': {
      open: '94.41',
      high: '95.2',
      low: '92.2',
      close: '95.07',
      'adjusted close': '56.0002175360631',
      volume: '9917700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-11': {
      open: '93.48',
      high: '95.77',
      low: '93.0',
      close: '95.16',
      'adjusted close': '56.0532313109474',
      volume: '10765700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-10': {
      open: '95.58',
      high: '96.82',
      low: '92.96',
      close: '93.27',
      'adjusted close': '54.9399420383781',
      volume: '13541300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-09': {
      open: '95.93',
      high: '96.98',
      low: '94.72',
      close: '96.82',
      'adjusted close': '57.0310409365902',
      volume: '8165800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-06': {
      open: '92.51',
      high: '97.1',
      low: '91.65',
      close: '96.14',
      'adjusted close': '56.6304924152425',
      volume: '13055900',
      'dividend amount': '0.5000',
      'split coefficient': '1.0',
    },
    '2009-02-05': {
      open: '92.13',
      high: '93.2',
      low: '91.22',
      close: '92.41',
      'adjusted close': '54.1517363834081',
      volume: '11622900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-04': {
      open: '93.74',
      high: '94.91',
      low: '91.82',
      close: '92.83',
      'adjusted close': '54.3978540035903',
      volume: '10189800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-03': {
      open: '91.07',
      high: '93.73',
      low: '90.16',
      close: '93.48',
      'adjusted close': '54.7787503205388',
      volume: '8864500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-02-02': {
      open: '90.6',
      high: '91.86',
      low: '89.69',
      close: '90.93',
      'adjusted close': '53.284464769433',
      volume: '9926400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-30': {
      open: '92.23',
      high: '93.48',
      low: '91.25',
      close: '91.65',
      'adjusted close': '53.7063806897452',
      volume: '9617000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-29': {
      open: '93.58',
      high: '94.58',
      low: '92.02',
      close: '92.51',
      'adjusted close': '54.2103358167849',
      volume: '9233500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-28': {
      open: '92.7',
      high: '94.94',
      low: '91.91',
      close: '94.82',
      'adjusted close': '55.5639827277866',
      volume: '13407200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-27': {
      open: '91.77',
      high: '91.97',
      low: '90.5',
      close: '91.66',
      'adjusted close': '53.7122406330829',
      volume: '8720800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-26': {
      open: '89.77',
      high: '92.67',
      low: '89.52',
      close: '91.6',
      'adjusted close': '53.6770809730569',
      volume: '9840800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-23': {
      open: '88.4',
      high: '90.7',
      low: '87.81',
      close: '89.49',
      'adjusted close': '52.4406329288085',
      volume: '10253700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-22': {
      open: '89.83',
      high: '90.74',
      low: '88.11',
      close: '90.07',
      'adjusted close': '52.7805096423934',
      volume: '12350700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-21': {
      open: '86.35',
      high: '91.6',
      low: '86.0',
      close: '91.42',
      'adjusted close': '53.5716019929788',
      volume: '24905000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-20': {
      open: '84.76',
      high: '84.92',
      low: '81.76',
      close: '81.98',
      'adjusted close': '48.0398154822184',
      volume: '13800100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-16': {
      open: '84.61',
      high: '85.74',
      low: '83.08',
      close: '84.92',
      'adjusted close': '49.7626388234933',
      volume: '11245300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-15': {
      open: '83.34',
      high: '84.83',
      low: '82.256',
      close: '84.12',
      'adjusted close': '49.2938433564798',
      volume: '11734800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-14': {
      open: '84.2',
      high: '84.82',
      low: '82.11',
      close: '83.19',
      'adjusted close': '48.7488686260764',
      volume: '9470000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-13': {
      open: '85.5',
      high: '86.59',
      low: '84.79',
      close: '85.34',
      'adjusted close': '50.0087564436755',
      volume: '7657200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-12': {
      open: '84.57',
      high: '86.6',
      low: '84.42',
      close: '85.71',
      'adjusted close': '50.2255743471693',
      volume: '8072700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-09': {
      open: '87.05',
      high: '87.5',
      low: '84.25',
      close: '84.7',
      'adjusted close': '49.6337200700646',
      volume: '7440300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-08': {
      open: '87.81',
      high: '88.14',
      low: '85.98',
      close: '87.18',
      'adjusted close': '51.0869860178068',
      volume: '7231800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-07': {
      open: '87.83',
      high: '88.8',
      low: '87.12',
      close: '87.79',
      'adjusted close': '51.4444425614046',
      volume: '8455100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-06': {
      open: '87.11',
      high: '90.41',
      low: '86.37',
      close: '89.23',
      'adjusted close': '52.2882744020291',
      volume: '9649500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-05': {
      open: '86.42',
      high: '87.67',
      low: '86.18',
      close: '86.82',
      'adjusted close': '50.8760280576506',
      volume: '8315700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2009-01-02': {
      open: '83.89',
      high: '87.59',
      low: '83.89',
      close: '87.37',
      'adjusted close': '51.1983249412225',
      volume: '7558200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-31': {
      open: '83.5',
      high: '85.0',
      low: '83.5',
      close: '84.16',
      'adjusted close': '49.3172831298304',
      volume: '6667700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-30': {
      open: '81.83',
      high: '83.64',
      low: '81.52',
      close: '83.55',
      'adjusted close': '48.9598265862326',
      volume: '5774400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-29': {
      open: '81.72',
      high: '81.72',
      low: '79.68',
      close: '81.25',
      'adjusted close': '47.6120396185685',
      volume: '6062600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-26': {
      open: '80.93',
      high: '81.8',
      low: '80.55',
      close: '81.33',
      'adjusted close': '47.6589191652698',
      volume: '3101300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-24': {
      open: '80.55',
      high: '81.22',
      low: '79.92',
      close: '80.52',
      'adjusted close': '47.1842637549186',
      volume: '2415200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-23': {
      open: '82.35',
      high: '82.81',
      low: '80.13',
      close: '80.6',
      'adjusted close': '47.2311433016199',
      volume: '6374400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-22': {
      open: '83.27',
      high: '83.45',
      low: '80.32',
      close: '81.99',
      'adjusted close': '48.045675425556',
      volume: '7083300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-19': {
      open: '84.59',
      high: '85.22',
      low: '82.97',
      close: '83.52',
      'adjusted close': '48.9422467562195',
      volume: '13098600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-18': {
      open: '85.77',
      high: '86.64',
      low: '82.96',
      close: '84.0',
      'adjusted close': '49.2235240364277',
      volume: '8029500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-17': {
      open: '85.44',
      high: '87.27',
      low: '84.6566',
      close: '85.84',
      'adjusted close': '50.301753610559',
      volume: '8592000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-16': {
      open: '83.11',
      high: '86.84',
      low: '82.87',
      close: '86.4',
      'adjusted close': '50.6299104374685',
      volume: '11559800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-15': {
      open: '82.51',
      high: '83.54',
      low: '80.0',
      close: '82.77',
      'adjusted close': '48.5027510058943',
      volume: '8848200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-12': {
      open: '78.68',
      high: '82.94',
      low: '78.06',
      close: '82.2',
      'adjusted close': '48.1687342356471',
      volume: '10381700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-11': {
      open: '81.5',
      high: '82.86',
      low: '79.77',
      close: '80.58',
      'adjusted close': '47.2194234149446',
      volume: '10682400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-10': {
      open: '83.95',
      high: '84.99',
      low: '81.83',
      close: '82.86',
      'adjusted close': '48.5554904959333',
      volume: '8187000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-09': {
      open: '83.82',
      high: '85.434',
      low: '82.2',
      close: '82.69',
      'adjusted close': '48.4558714591929',
      volume: '9356400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-08': {
      open: '82.57',
      high: '85.88',
      low: '81.73',
      close: '84.86',
      'adjusted close': '49.7274791634673',
      volume: '11177600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-05': {
      open: '76.78',
      high: '81.5',
      low: '75.31',
      close: '80.59',
      'adjusted close': '47.2252833582823',
      volume: '11212000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-04': {
      open: '80.03',
      high: '80.83',
      low: '76.18',
      close: '77.44',
      'adjusted close': '45.3794012069162',
      volume: '10914000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-03': {
      open: '78.62',
      high: '81.0',
      low: '76.99',
      close: '80.67',
      'adjusted close': '47.2721629049836',
      volume: '9757800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-02': {
      open: '77.8',
      high: '80.0',
      low: '76.14',
      close: '79.84',
      'adjusted close': '46.785787607957',
      volume: '9305200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-12-01': {
      open: '80.95',
      high: '81.36',
      low: '76.79',
      close: '76.9',
      'adjusted close': '45.062964266682',
      volume: '10265000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-28': {
      open: '81.0',
      high: '81.9',
      low: '80.1',
      close: '81.6',
      'adjusted close': '47.8171376353869',
      volume: '3908300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-26': {
      open: '79.52',
      high: '81.79',
      low: '78.61',
      close: '81.67',
      'adjusted close': '47.8581572387506',
      volume: '8240600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-25': {
      open: '81.37',
      high: '82.4',
      low: '78.22',
      close: '80.65',
      'adjusted close': '47.2604430183083',
      volume: '12519700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-24': {
      open: '75.74',
      high: '82.09',
      low: '75.0',
      close: '79.89',
      'adjusted close': '46.8150873246454',
      volume: '15023200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-21': {
      open: '72.74',
      high: '76.98',
      low: '69.5',
      close: '74.88',
      'adjusted close': '43.8792557124727',
      volume: '19804500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-20': {
      open: '75.0',
      high: '78.39',
      low: '70.51',
      close: '71.74',
      'adjusted close': '42.0392335044443',
      volume: '17634700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-19': {
      open: '79.93',
      high: '81.0',
      low: '75.73',
      close: '75.97',
      'adjusted close': '44.5179895362787',
      volume: '12606400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-18': {
      open: '79.13',
      high: '80.93',
      low: '77.0',
      close: '80.08',
      'adjusted close': '46.9264262480611',
      volume: '13050600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-17': {
      open: '79.4',
      high: '79.74',
      low: '77.16',
      close: '77.48',
      'adjusted close': '45.4028409802669',
      volume: '10315300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-14': {
      open: '82.63',
      high: '85.4',
      low: '79.5',
      close: '80.33',
      'adjusted close': '47.0729248315028',
      volume: '12023700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-13': {
      open: '79.62',
      high: '84.39',
      low: '75.4',
      close: '84.21',
      'adjusted close': '49.3465828465188',
      volume: '15103600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-12': {
      open: '80.73',
      high: '82.4',
      low: '79.57',
      close: '79.74',
      'adjusted close': '46.7271881745803',
      volume: '11368400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-11': {
      open: '83.25',
      high: '84.29',
      low: '80.25',
      close: '82.74',
      'adjusted close': '48.4851711758813',
      volume: '10013600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-10': {
      open: '87.99',
      high: '88.1',
      low: '82.74',
      close: '83.87',
      'adjusted close': '49.147344773038',
      volume: '9685100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-07': {
      open: '85.78',
      high: '86.71',
      low: '84.25',
      close: '86.27',
      'adjusted close': '50.5537311740788',
      volume: '7927800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-06': {
      open: '87.68',
      high: '88.83',
      low: '84.28',
      close: '85.15',
      'adjusted close': '49.8974175202598',
      volume: '12522000',
      'dividend amount': '0.5000',
      'split coefficient': '1.0',
    },
    '2008-11-05': {
      open: '92.93',
      high: '93.4',
      low: '89.7',
      close: '89.94',
      'adjusted close': '52.3966576972815',
      volume: '8706200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-04': {
      open: '94.76',
      high: '94.76',
      low: '92.07',
      close: '93.4',
      'adjusted close': '54.4123618959984',
      volume: '10173000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-11-03': {
      open: '92.64',
      high: '94.67',
      low: '92.17',
      close: '92.68',
      'adjusted close': '53.9929089991556',
      volume: '7686300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-31': {
      open: '90.9',
      high: '94.19',
      low: '89.03',
      close: '92.97',
      'adjusted close': '54.1618553048284',
      volume: '10506300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-30': {
      open: '91.01',
      high: '92.4',
      low: '88.9',
      close: '90.69',
      'adjusted close': '52.8335877981594',
      volume: '12033800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-29': {
      open: '87.74',
      high: '91.0',
      low: '86.25',
      close: '88.2',
      'adjusted close': '51.3829798632447',
      volume: '12692000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-28': {
      open: '81.4',
      high: '87.61',
      low: '79.52',
      close: '87.28',
      'adjusted close': '50.8470122728345',
      volume: '14205900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-27': {
      open: '80.27',
      high: '84.5',
      low: '79.01',
      close: '79.66',
      'adjusted close': '46.4078024479147',
      volume: '11099100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-24': {
      open: '79.27',
      high: '84.31',
      low: '78.82',
      close: '82.07',
      'adjusted close': '47.8118045054024',
      volume: '12287300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-23': {
      open: '84.09',
      high: '85.89',
      low: '81.0',
      close: '84.35',
      'adjusted close': '49.1400720120713',
      volume: '12358000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-22': {
      open: '87.0',
      high: '87.59',
      low: '80.8',
      close: '83.6',
      'adjusted close': '48.7031419111934',
      volume: '14198100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-21': {
      open: '92.22',
      high: '92.46',
      low: '88.57',
      close: '88.86',
      'adjusted close': '51.7674783520173',
      volume: '9716400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-20': {
      open: '92.21',
      high: '93.31',
      low: '89.33',
      close: '92.51',
      'adjusted close': '53.8938715096232',
      volume: '9747100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-17': {
      open: '91.75',
      high: '95.91',
      low: '87.71',
      close: '90.78',
      'adjusted close': '52.8860194102648',
      volume: '15230700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-16': {
      open: '89.38',
      high: '92.0',
      low: '84.35',
      close: '91.52',
      'adjusted close': '53.3171237764644',
      volume: '16271300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-15': {
      open: '92.77',
      high: '95.29',
      low: '87.71',
      close: '88.29',
      'adjusted close': '51.4354114753501',
      volume: '11330800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-14': {
      open: '96.42',
      high: '99.0',
      low: '92.0',
      close: '93.6',
      'adjusted close': '54.5288765895658',
      volume: '15114200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-13': {
      open: '90.44',
      high: '93.61',
      low: '86.95',
      close: '92.21',
      'adjusted close': '53.7190994692721',
      volume: '17101600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-10': {
      open: '87.41',
      high: '91.66',
      low: '83.51',
      close: '87.75',
      'adjusted close': '51.120821802718',
      volume: '24637400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-09': {
      open: '95.29',
      high: '95.31',
      low: '88.23',
      close: '89.0',
      'adjusted close': '51.8490386375145',
      volume: '21293600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-08': {
      open: '91.51',
      high: '97.48',
      low: '89.98',
      close: '90.55',
      'adjusted close': '52.7520275126622',
      volume: '17980400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-07': {
      open: '101.5',
      high: '102.62',
      low: '94.24',
      close: '95.65',
      'adjusted close': '55.7231521986322',
      volume: '11416100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-06': {
      open: '101.21',
      high: '103.0',
      low: '96.6',
      close: '100.62',
      'adjusted close': '58.6185423337833',
      volume: '11521400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-03': {
      open: '105.16',
      high: '110.95',
      low: '103.03',
      close: '103.44',
      'adjusted close': '60.2613995130843',
      volume: '9637900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-02': {
      open: '108.48',
      high: '109.97',
      low: '103.04',
      close: '104.74',
      'adjusted close': '61.0187450212727',
      volume: '11458200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-10-01': {
      open: '115.51',
      high: '116.8',
      low: '107.75',
      close: '110.13',
      'adjusted close': '64.1588160129154',
      volume: '13134400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-30': {
      open: '115.83',
      high: '116.96',
      low: '111.81',
      close: '116.96',
      'adjusted close': '68.1377927982438',
      volume: '18991900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-29': {
      open: '117.44',
      high: '118.43',
      low: '109.95',
      close: '114.46',
      'adjusted close': '66.6813591286507',
      volume: '9586700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-26': {
      open: '117.21',
      high: '121.01',
      low: '117.01',
      close: '119.42',
      'adjusted close': '69.5709235291234',
      volume: '4760500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-25': {
      open: '117.75',
      high: '121.99',
      low: '116.12',
      close: '120.11',
      'adjusted close': '69.9728992219311',
      volume: '6539200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-24': {
      open: '116.93',
      high: '118.17',
      low: '114.26',
      close: '116.46',
      'adjusted close': '67.8465060643252',
      volume: '6574100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-23': {
      open: '116.21',
      high: '118.17',
      low: '114.14',
      close: '115.36',
      'adjusted close': '67.2056752497042',
      volume: '8012800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-22': {
      open: '118.45',
      high: '120.14',
      low: '116.02',
      close: '116.21',
      'adjusted close': '67.7008626973659',
      volume: '8844000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-19': {
      open: '119.59',
      high: '124.0',
      low: '117.5',
      close: '118.85',
      'adjusted close': '69.2388566524562',
      volume: '14432600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-18': {
      open: '112.91',
      high: '118.59',
      low: '111.04',
      close: '115.12',
      'adjusted close': '67.0658576174233',
      volume: '15995000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-17': {
      open: '114.79',
      high: '115.5',
      low: '110.61',
      close: '111.47',
      'adjusted close': '64.9394644598173',
      volume: '12298000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-16': {
      open: '113.73',
      high: '117.64',
      low: '113.32',
      close: '116.05',
      'adjusted close': '67.6076509425119',
      volume: '13673200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-15': {
      open: '115.36',
      high: '118.41',
      low: '115.19',
      close: '115.19',
      'adjusted close': '67.1066377601719',
      volume: '9908300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-12': {
      open: '118.66',
      high: '119.28',
      low: '116.82',
      close: '118.97',
      'adjusted close': '69.3087654685966',
      volume: '6635100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-11': {
      open: '116.6',
      high: '119.37',
      low: '116.11',
      close: '119.2',
      'adjusted close': '69.4427573661992',
      volume: '9737400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-10': {
      open: '116.4',
      high: '119.95',
      low: '116.1',
      close: '118.04',
      'adjusted close': '68.766972143508',
      volume: '11852200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-09': {
      open: '117.78',
      high: '118.1',
      low: '115.0',
      close: '115.04',
      'adjusted close': '67.0192517399963',
      volume: '8106300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-08': {
      open: '118.0',
      high: '118.28',
      low: '115.14',
      close: '117.29',
      'adjusted close': '68.3300420426301',
      volume: '10452800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-05': {
      open: '114.15',
      high: '115.09',
      low: '113.17',
      close: '114.33',
      'adjusted close': '66.6056245778318',
      volume: '8811400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-04': {
      open: '117.99',
      high: '118.9',
      low: '114.9',
      close: '115.0',
      'adjusted close': '66.9959488012828',
      volume: '10133800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-03': {
      open: '118.34',
      high: '118.65',
      low: '115.15',
      close: '118.34',
      'adjusted close': '68.9417441838592',
      volume: '11316600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-09-02': {
      open: '122.87',
      high: '124.0',
      low: '117.9',
      close: '118.41',
      'adjusted close': '68.9825243266078',
      volume: '11749800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-29': {
      open: '123.71',
      high: '123.99',
      low: '121.63',
      close: '121.73',
      'adjusted close': '70.9166682398274',
      volume: '7156000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-28': {
      open: '124.06',
      high: '125.45',
      low: '123.58',
      close: '124.58',
      'adjusted close': '72.5770026231636',
      volume: '5448200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-27': {
      open: '122.42',
      high: '124.85',
      low: '122.18',
      close: '123.38',
      'adjusted close': '71.8779144617589',
      volume: '5843800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-26': {
      open: '122.58',
      high: '122.85',
      low: '121.5',
      close: '122.5',
      'adjusted close': '71.3652498100621',
      volume: '6218200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-25': {
      open: '124.48',
      high: '124.93',
      low: '122.7',
      close: '122.86',
      'adjusted close': '71.5749762584835',
      volume: '5827400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-22': {
      open: '123.3',
      high: '125.35',
      low: '122.8',
      close: '124.93',
      'adjusted close': '72.7809033369066',
      volume: '5989200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-21': {
      open: '121.58',
      high: '123.43',
      low: '121.55',
      close: '122.99',
      'adjusted close': '71.6507108093024',
      volume: '6315000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-20': {
      open: '123.5',
      high: '123.97',
      low: '122.01',
      close: '122.51',
      'adjusted close': '71.3710755447405',
      volume: '6871600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-19': {
      open: '123.63',
      high: '124.79',
      low: '122.01',
      close: '122.56',
      'adjusted close': '71.4002042181323',
      volume: '9311600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-18': {
      open: '126.49',
      high: '127.0',
      low: '123.68',
      close: '124.59',
      'adjusted close': '72.5828283578419',
      volume: '5970000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-15': {
      open: '127.07',
      high: '127.19',
      low: '125.35',
      close: '126.36',
      'adjusted close': '73.6139833959139',
      volume: '5566200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-14': {
      open: '125.01',
      high: '128.25',
      low: '124.8',
      close: '126.94',
      'adjusted close': '73.9518760072595',
      volume: '6813000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-13': {
      open: '125.0',
      high: '126.99',
      low: '124.75',
      close: '125.8',
      'adjusted close': '73.287742253925',
      volume: '5857800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-12': {
      open: '126.01',
      high: '126.5',
      low: '124.4',
      close: '125.22',
      'adjusted close': '72.9498496425794',
      volume: '6996800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-11': {
      open: '128.43',
      high: '128.43',
      low: '125.75',
      close: '126.6',
      'adjusted close': '73.7538010281948',
      volume: '7490400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-08': {
      open: '129.05',
      high: '129.88',
      low: '127.63',
      close: '128.81',
      'adjusted close': '75.0412883921151',
      volume: '8561800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-07': {
      open: '129.16',
      high: '129.97',
      low: '127.9',
      close: '129.05',
      'adjusted close': '75.1811060243961',
      volume: '6633500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-06': {
      open: '128.45',
      high: '129.5',
      low: '127.75',
      close: '129.16',
      'adjusted close': '75.2451891058581',
      volume: '6253800',
      'dividend amount': '0.5000',
      'split coefficient': '1.0',
    },
    '2008-08-05': {
      open: '127.56',
      high: '128.9',
      low: '127.08',
      close: '128.87',
      'adjusted close': '74.786730835045',
      volume: '6969900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-04': {
      open: '126.77',
      high: '128.1',
      low: '126.25',
      close: '127.56',
      'adjusted close': '74.0265025631904',
      volume: '5994900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-08-01': {
      open: '128.52',
      high: '129.3',
      low: '126.28',
      close: '126.64',
      'adjusted close': '73.4926017921169',
      volume: '4939700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-31': {
      open: '128.1',
      high: '129.5',
      low: '127.74',
      close: '127.98',
      'adjusted close': '74.2702398717239',
      volume: '5857300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-30': {
      open: '128.12',
      high: '129.0',
      low: '127.09',
      close: '128.86',
      'adjusted close': '74.7809275657942',
      volume: '5826100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-29': {
      open: '126.54',
      high: '128.168',
      low: '126.25',
      close: '127.66',
      'adjusted close': '74.0845352556984',
      volume: '5979400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-28': {
      open: '127.69',
      high: '128.44',
      low: '126.13',
      close: '126.25',
      'adjusted close': '73.2662742913357',
      volume: '6096800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-25': {
      open: '129.0',
      high: '129.9',
      low: '128.0',
      close: '128.53',
      'adjusted close': '74.5894196805179',
      volume: '7565500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-24': {
      open: '129.06',
      high: '130.93',
      low: '128.8',
      close: '130.0',
      'adjusted close': '75.4425002603853',
      volume: '8946300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-23': {
      open: '129.78',
      high: '130.33',
      low: '128.29',
      close: '129.52',
      'adjusted close': '75.163943336347',
      volume: '10230300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-22': {
      open: '127.5',
      high: '130.0',
      low: '127.26',
      close: '130.0',
      'adjusted close': '75.4425002603853',
      volume: '11428600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-21': {
      open: '129.83',
      high: '129.9',
      low: '127.64',
      close: '128.66',
      'adjusted close': '74.6648621807782',
      volume: '7779600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-18': {
      open: '125.81',
      high: '130.0',
      low: '125.42',
      close: '129.89',
      'adjusted close': '75.3786642986265',
      volume: '18317800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-17': {
      open: '126.39',
      high: '126.96',
      low: '124.0',
      close: '126.52',
      'adjusted close': '73.4229625611073',
      volume: '12298200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-16': {
      open: '122.81',
      high: '126.89',
      low: '122.25',
      close: '125.94',
      'adjusted close': '73.0863729445609',
      volume: '9898800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-15': {
      open: '121.0',
      high: '124.5',
      low: '119.9',
      close: '123.2',
      'adjusted close': '71.4962771698421',
      volume: '10710500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-14': {
      open: '121.8',
      high: '124.25',
      low: '121.24',
      close: '121.54',
      'adjusted close': '70.5329344742095',
      volume: '8317400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-11': {
      open: '121.8',
      high: '123.74',
      low: '120.57',
      close: '122.12',
      'adjusted close': '70.8695240907558',
      volume: '8841400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-10': {
      open: '120.66',
      high: '123.54',
      low: '120.66',
      close: '123.18',
      'adjusted close': '71.4846706313405',
      volume: '10053600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-09': {
      open: '124.21',
      high: '124.5',
      low: '120.4',
      close: '120.4',
      'adjusted close': '69.8713617796184',
      volume: '9506700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-08': {
      open: '120.55',
      high: '123.99',
      low: '120.55',
      close: '123.88',
      'adjusted close': '71.8908994788964',
      volume: '9813300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-07': {
      open: '119.62',
      high: '122.64',
      low: '119.62',
      close: '121.5',
      'adjusted close': '70.5097213972063',
      volume: '9131100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-03': {
      open: '119.95',
      high: '120.78',
      low: '117.89',
      close: '119.54',
      'adjusted close': '69.3722806240497',
      volume: '6115200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-02': {
      open: '118.41',
      high: '120.55',
      low: '118.12',
      close: '119.1',
      'adjusted close': '69.1169367770145',
      volume: '8093200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-07-01': {
      open: '117.5',
      high: '119.36',
      low: '116.6',
      close: '119.27',
      'adjusted close': '69.2155923542781',
      volume: '10069400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-30': {
      open: '119.99',
      high: '120.22',
      low: '118.15',
      close: '118.53',
      'adjusted close': '68.786150429719',
      volume: '8439000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-27': {
      open: '121.02',
      high: '122.05',
      low: '118.26',
      close: '120.05',
      'adjusted close': '69.6682473558404',
      volume: '11660400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-26': {
      open: '123.43',
      high: '123.82',
      low: '120.76',
      close: '121.13',
      'adjusted close': '70.2950004349267',
      volume: '9710600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-25': {
      open: '123.67',
      high: '125.83',
      low: '123.2',
      close: '124.58',
      'adjusted close': '72.2971283264523',
      volume: '7131500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-24': {
      open: '123.08',
      high: '124.25',
      low: '121.9',
      close: '123.46',
      'adjusted close': '71.6471621703628',
      volume: '7552900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-23': {
      open: '123.0',
      high: '124.5',
      low: '122.4',
      close: '123.46',
      'adjusted close': '71.6471621703628',
      volume: '5852300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-20': {
      open: '123.14',
      high: '125.02',
      low: '122.5',
      close: '122.74',
      'adjusted close': '71.2293267843053',
      volume: '9624900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-19': {
      open: '123.84',
      high: '125.62',
      low: '122.36',
      close: '125.02',
      'adjusted close': '72.5524721734875',
      volume: '7570200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-18': {
      open: '124.55',
      high: '125.4312',
      low: '123.7',
      close: '124.16',
      'adjusted close': '72.0533910179188',
      volume: '6614700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-17': {
      open: '127.1',
      high: '127.1',
      low: '124.62',
      close: '125.1',
      'adjusted close': '72.5988983274938',
      volume: '5277900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-16': {
      open: '125.46',
      high: '127.14',
      low: '124.6466',
      close: '126.71',
      'adjusted close': '73.5332246768725',
      volume: '6280200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-13': {
      open: '124.42',
      high: '126.57',
      low: '124.15',
      close: '126.15',
      'adjusted close': '73.2082415988277',
      volume: '6379600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-12': {
      open: '123.85',
      high: '125.09',
      low: '123.13',
      close: '123.85',
      'adjusted close': '71.873489671144',
      volume: '6748600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-11': {
      open: '125.9',
      high: '125.9',
      low: '122.86',
      close: '123.25',
      'adjusted close': '71.5252935160961',
      volume: '7207200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-10': {
      open: '124.88',
      high: '126.722',
      low: '124.63',
      close: '125.94',
      'adjusted close': '73.0863729445609',
      volume: '5384200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-09': {
      open: '125.38',
      high: '126.18',
      low: '124.06',
      close: '125.86',
      'adjusted close': '73.0399467905546',
      volume: '5584000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-06': {
      open: '127.6',
      high: '128.14',
      low: '124.74',
      close: '124.94',
      'adjusted close': '72.5060460194811',
      volume: '7886200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-05': {
      open: '127.41',
      high: '129.04',
      low: '127.2',
      close: '128.47',
      'adjusted close': '74.5546000650131',
      volume: '6153300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-04': {
      open: '127.24',
      high: '128.5',
      low: '126.45',
      close: '127.55',
      'adjusted close': '74.0206992939396',
      volume: '6432300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-03': {
      open: '127.48',
      high: '129.0',
      low: '127.46',
      close: '127.84',
      'adjusted close': '74.1889941022127',
      volume: '7238200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-06-02': {
      open: '128.67',
      high: '129.37',
      low: '126.7',
      close: '127.36',
      'adjusted close': '73.9104371781744',
      volume: '7599300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-30': {
      open: '129.22',
      high: '129.99',
      low: '128.8',
      close: '129.43',
      'adjusted close': '75.1117139130898',
      volume: '8652900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-29': {
      open: '129.04',
      high: '129.99',
      low: '128.66',
      close: '129.71',
      'adjusted close': '75.2742054521121',
      volume: '7561500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-28': {
      open: '127.5',
      high: '129.54',
      low: '126.99',
      close: '129.54',
      'adjusted close': '75.1755498748485',
      volume: '9875600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-27': {
      open: '124.01',
      high: '127.38',
      low: '124.0',
      close: '127.32',
      'adjusted close': '73.8872241011712',
      volume: '6989200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-23': {
      open: '124.35',
      high: '124.91',
      low: '123.77',
      close: '124.2',
      'adjusted close': '72.076604094922',
      volume: '4701500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-22': {
      open: '123.55',
      high: '125.35',
      low: '123.55',
      close: '124.7',
      'adjusted close': '72.3667675574619',
      volume: '5031300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-21': {
      open: '125.05',
      high: '126.4',
      low: '123.02',
      close: '123.62',
      'adjusted close': '71.7400144783756',
      volume: '8333900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-20': {
      open: '126.05',
      high: '126.05',
      low: '124.45',
      close: '125.18',
      'adjusted close': '72.6453244815003',
      volume: '7017700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-19': {
      open: '127.68',
      high: '128.21',
      low: '126.05',
      close: '126.49',
      'adjusted close': '73.4055527533549',
      volume: '6385200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-16': {
      open: '128.46',
      high: '128.475',
      low: '126.8',
      close: '127.82',
      'adjusted close': '74.1773875637111',
      volume: '6372100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-15': {
      open: '127.06',
      high: '128.68',
      low: '126.9',
      close: '128.46',
      'adjusted close': '74.5487967957623',
      volume: '7227900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-14': {
      open: '126.61',
      high: '128.83',
      low: '126.2',
      close: '127.52',
      'adjusted close': '74.0032894861872',
      volume: '8888300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-13': {
      open: '125.31',
      high: '126.7',
      low: '125.03',
      close: '126.58',
      'adjusted close': '73.4577821766121',
      volume: '9693400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-12': {
      open: '123.8',
      high: '125.99',
      low: '123.56',
      close: '125.24',
      'adjusted close': '72.680144097005',
      volume: '8666800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-09': {
      open: '124.37',
      high: '124.65',
      low: '123.63',
      close: '124.06',
      'adjusted close': '71.9953583254108',
      volume: '5502900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-08': {
      open: '124.2',
      high: '125.17',
      low: '123.7',
      close: '124.92',
      'adjusted close': '72.4944394809795',
      volume: '9794000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-07': {
      open: '122.78',
      high: '124.98',
      low: '122.41',
      close: '124.14',
      'adjusted close': '72.0417844794172',
      volume: '11406800',
      'dividend amount': '0.5000',
      'split coefficient': '1.0',
    },
    '2008-05-06': {
      open: '121.5',
      high: '123.34',
      low: '120.78',
      close: '122.82',
      'adjusted close': '70.989826458296',
      volume: '5910400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-05': {
      open: '122.76',
      high: '123.25',
      low: '121.74',
      close: '122.03',
      'adjusted close': '70.5332073172599',
      volume: '6359300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-02': {
      open: '123.3',
      high: '124.0',
      low: '121.76',
      close: '123.18',
      'adjusted close': '71.1979060668694',
      volume: '6916600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-05-01': {
      open: '121.06',
      high: '124.43',
      low: '121.06',
      close: '123.61',
      'adjusted close': '71.4464455993321',
      volume: '8230200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-30': {
      open: '123.34',
      high: '123.5',
      low: '120.5',
      close: '120.7',
      'adjusted close': '69.7644687633637',
      volume: '8128300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-29': {
      open: '122.05',
      high: '123.2',
      low: '122.04',
      close: '122.85',
      'adjusted close': '71.0071664256771',
      volume: '7226000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-28': {
      open: '122.98',
      high: '123.05',
      low: '121.64',
      close: '121.69',
      'adjusted close': '70.3366876869406',
      volume: '5605200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-25': {
      open: '124.5',
      high: '124.5',
      low: '122.06',
      close: '123.08',
      'adjusted close': '71.140106175599',
      volume: '6313400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-24': {
      open: '123.38',
      high: '124.9',
      low: '122.73',
      close: '124.19',
      'adjusted close': '71.7816849687004',
      volume: '5996700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-23': {
      open: '123.69',
      high: '124.78',
      low: '122.64',
      close: '123.6',
      'adjusted close': '71.4406656102051',
      volume: '7593300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-22': {
      open: '123.87',
      high: '124.35',
      low: '123.01',
      close: '123.67',
      'adjusted close': '71.4811255340944',
      volume: '5417500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-21': {
      open: '122.05',
      high: '124.51',
      low: '122.05',
      close: '124.35',
      'adjusted close': '71.874164794733',
      volume: '6952800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-18': {
      open: '123.85',
      high: '125.0',
      low: '123.15',
      close: '124.4',
      'adjusted close': '71.9030647403682',
      volume: '12490800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-17': {
      open: '122.39',
      high: '123.72',
      low: '120.7',
      close: '123.08',
      'adjusted close': '71.140106175599',
      volume: '18999100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-16': {
      open: '118.21',
      high: '120.47',
      low: '117.17',
      close: '120.47',
      'adjusted close': '69.6315290134418',
      volume: '19317700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-15': {
      open: '117.1',
      high: '117.67',
      low: '115.54',
      close: '117.17',
      'adjusted close': '67.7241326015188',
      volume: '8642000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-14': {
      open: '116.2',
      high: '117.94',
      low: '115.95',
      close: '117.28',
      'adjusted close': '67.7877124819163',
      volume: '7745600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-11': {
      open: '117.33',
      high: '117.8',
      low: '115.51',
      close: '116.0',
      'adjusted close': '67.0478738736552',
      volume: '9556800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-10': {
      open: '117.5',
      high: '119.22',
      low: '116.8789',
      close: '118.78',
      'adjusted close': '68.6547108509722',
      volume: '9149300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-09': {
      open: '116.5',
      high: '117.72',
      low: '115.72',
      close: '116.77',
      'adjusted close': '67.4929330364373',
      volume: '6819100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-08': {
      open: '115.5',
      high: '116.88',
      low: '115.28',
      close: '116.27',
      'adjusted close': '67.2039335800853',
      volume: '7283300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-07': {
      open: '116.37',
      high: '117.37',
      low: '115.82',
      close: '116.31',
      'adjusted close': '67.2270535365935',
      volume: '6181200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-04': {
      open: '116.05',
      high: '116.58',
      low: '114.6',
      close: '115.76',
      'adjusted close': '66.9091541346063',
      volume: '5733200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-03': {
      open: '114.35',
      high: '116.82',
      low: '113.86',
      close: '116.02',
      'adjusted close': '67.0594338519093',
      volume: '7052900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-02': {
      open: '116.84',
      high: '116.88',
      low: '113.98',
      close: '114.81',
      'adjusted close': '66.3600551675376',
      volume: '8007600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-04-01': {
      open: '115.14',
      high: '118.37',
      low: '114.97',
      close: '116.49',
      'adjusted close': '67.3310933408802',
      volume: '11875400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-31': {
      open: '113.95',
      high: '115.87',
      low: '113.34',
      close: '115.14',
      'adjusted close': '66.5507948087299',
      volume: '9707200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-28': {
      open: '116.01',
      high: '116.75',
      low: '114.21',
      close: '114.57',
      'adjusted close': '66.2213354284886',
      volume: '6600900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-27': {
      open: '116.1',
      high: '116.2',
      low: '114.51',
      close: '115.52',
      'adjusted close': '66.7704343955574',
      volume: '8437200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-26': {
      open: '117.48',
      high: '117.97',
      low: '114.96',
      close: '116.91',
      'adjusted close': '67.5738528842158',
      volume: '9617100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-25': {
      open: '119.1',
      high: '119.1',
      low: '117.15',
      close: '117.97',
      'adjusted close': '68.186531731682',
      volume: '8443600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-24': {
      open: '118.57',
      high: '119.79',
      low: '118.13',
      close: '119.06',
      'adjusted close': '68.8165505465293',
      volume: '8398000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-20': {
      open: '117.6',
      high: '118.48',
      low: '116.65',
      close: '118.33',
      'adjusted close': '68.3946113402554',
      volume: '11417900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-19': {
      open: '118.29',
      high: '118.5',
      low: '116.65',
      close: '116.94',
      'adjusted close': '67.5911928515969',
      volume: '9314400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-18': {
      open: '116.76',
      high: '118.43',
      low: '113.81',
      close: '118.41',
      'adjusted close': '68.4408512532717',
      volume: '9966500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-17': {
      open: '113.0',
      high: '117.04',
      low: '113.0',
      close: '115.55',
      'adjusted close': '66.7877743629385',
      volume: '9280300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-14': {
      open: '116.1',
      high: '116.52',
      low: '112.69',
      close: '115.2301',
      'adjusted close': '66.6028725107645',
      volume: '9734600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-13': {
      open: '115.69',
      high: '116.78',
      low: '113.64',
      close: '115.91',
      'adjusted close': '66.9958539715119',
      volume: '9548900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-12': {
      open: '116.1',
      high: '118.0',
      low: '115.11',
      close: '117.07',
      'adjusted close': '67.6663327102485',
      volume: '9083200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-11': {
      open: '115.8',
      high: '116.56',
      low: '114.04',
      close: '116.49',
      'adjusted close': '67.3310933408802',
      volume: '10480600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-10': {
      open: '113.12',
      high: '115.99',
      low: '113.12',
      close: '114.01',
      'adjusted close': '65.8976560373744',
      volume: '9747300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-07': {
      open: '112.1',
      high: '114.75',
      low: '111.8',
      close: '113.94',
      'adjusted close': '65.8571961134852',
      volume: '8124900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-06': {
      open: '115.07',
      high: '115.15',
      low: '112.25',
      close: '112.52',
      'adjusted close': '65.0364376574456',
      volume: '8018400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-05': {
      open: '115.15',
      high: '116.41',
      low: '114.25',
      close: '115.39',
      'adjusted close': '66.6952945369059',
      volume: '8434200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-04': {
      open: '113.25',
      high: '115.72',
      low: '112.75',
      close: '115.71',
      'adjusted close': '66.8802541889711',
      volume: '8593300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-03-03': {
      open: '114.09',
      high: '114.81',
      low: '113.48',
      close: '114.23',
      'adjusted close': '66.0248157981693',
      volume: '6335700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-29': {
      open: '114.05',
      high: '115.14',
      low: '113.35',
      close: '113.86',
      'adjusted close': '65.8109562004689',
      volume: '8475100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-28': {
      open: '115.9',
      high: '115.9',
      low: '114.56',
      close: '115.24',
      'adjusted close': '66.6085947000003',
      volume: '7227200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-27': {
      open: '112.97',
      high: '116.63',
      low: '112.91',
      close: '116.46',
      'adjusted close': '67.3137533734991',
      volume: '11854900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-26': {
      open: '109.42',
      high: '114.99',
      low: '109.05',
      close: '114.38',
      'adjusted close': '66.1115156350749',
      volume: '18651500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-25': {
      open: '107.68',
      high: '110.32',
      low: '107.43',
      close: '110.08',
      'adjusted close': '63.626120310448',
      volume: '8158000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-22': {
      open: '107.14',
      high: '108.48',
      low: '105.71',
      close: '108.07',
      'adjusted close': '62.4643424959131',
      volume: '7917200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-21': {
      open: '108.1',
      high: '109.6',
      low: '106.43',
      close: '106.93',
      'adjusted close': '61.8054237354307',
      volume: '8328000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-20': {
      open: '104.6',
      high: '108.21',
      low: '104.53',
      close: '107.85',
      'adjusted close': '62.3371827351183',
      volume: '7671000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-19': {
      open: '106.79',
      high: '107.62',
      low: '104.64',
      close: '105.0',
      'adjusted close': '60.6898858339121',
      volume: '7376400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-15': {
      open: '105.83',
      high: '106.25',
      low: '105.0',
      close: '106.16',
      'adjusted close': '61.3603645726486',
      volume: '6235600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-14': {
      open: '107.99',
      high: '108.5',
      low: '105.5',
      close: '106.13',
      'adjusted close': '61.3430246052675',
      volume: '7340600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-13': {
      open: '106.92',
      high: '108.93',
      low: '106.8',
      close: '108.42',
      'adjusted close': '62.6666421153595',
      volume: '6608200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-12': {
      open: '105.16',
      high: '107.33',
      low: '104.7',
      close: '106.53',
      'adjusted close': '61.5742241703491',
      volume: '7650200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-11': {
      open: '102.9',
      high: '105.55',
      low: '102.87',
      close: '105.14',
      'adjusted close': '60.7708056816906',
      volume: '6098300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-08': {
      open: '102.34',
      high: '103.67',
      low: '102.07',
      close: '103.27',
      'adjusted close': '59.6899477149343',
      volume: '6085600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-07': {
      open: '102.89',
      high: '104.0',
      low: '100.6',
      close: '102.34',
      'adjusted close': '59.1524087261196',
      volume: '11255800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-06': {
      open: '105.05',
      high: '106.49',
      low: '103.58',
      close: '103.59',
      'adjusted close': '59.8749073669996',
      volume: '8265700',
      'dividend amount': '0.4000',
      'split coefficient': '1.0',
    },
    '2008-02-05': {
      open: '106.87',
      high: '108.05',
      low: '104.68',
      close: '105.02',
      'adjusted close': '60.4679562619703',
      volume: '9048900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-04': {
      open: '108.4',
      high: '109.0',
      low: '106.4567',
      close: '107.93',
      'adjusted close': '62.1434633341693',
      volume: '5985500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-02-01': {
      open: '107.21',
      high: '109.4',
      low: '105.86',
      close: '109.08',
      'adjusted close': '62.8056053042822',
      volume: '8047100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-31': {
      open: '104.36',
      high: '107.97',
      low: '103.7',
      close: '107.11',
      'adjusted close': '61.6713273206974',
      volume: '9054600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-30': {
      open: '105.42',
      high: '107.65',
      low: '104.855',
      close: '105.65',
      'adjusted close': '60.830694906467',
      volume: '7431100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-29': {
      open: '105.53',
      high: '106.8',
      low: '104.6',
      close: '106.1',
      'adjusted close': '61.0897939382503',
      volume: '6616200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-28': {
      open: '104.41',
      high: '105.77',
      low: '103.83',
      close: '104.98',
      'adjusted close': '60.4449252369229',
      volume: '7858500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-25': {
      open: '107.03',
      high: '107.79',
      low: '104.1',
      close: '104.52',
      'adjusted close': '60.1800684488777',
      volume: '10025400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-24': {
      open: '106.38',
      high: '107.51',
      low: '104.68',
      close: '106.91',
      'adjusted close': '61.5561721954603',
      volume: '13025700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-23': {
      open: '100.05',
      high: '106.335',
      low: '98.5',
      close: '106.1',
      'adjusted close': '61.0897939382503',
      volume: '19588100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-22': {
      open: '99.16',
      high: '103.09',
      low: '98.55',
      close: '101.22',
      'adjusted close': '58.2800088824665',
      volume: '15129500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-18': {
      open: '106.51',
      high: '106.72',
      low: '102.5',
      close: '103.4',
      'adjusted close': '59.5351997475503',
      volume: '23871700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-17': {
      open: '102.67',
      high: '103.45',
      low: '100.05',
      close: '101.1',
      'adjusted close': '58.2109158073243',
      volume: '11934000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-16': {
      open: '100.48',
      high: '102.86',
      low: '100.14',
      close: '101.63',
      'adjusted close': '58.5160768892025',
      volume: '12681700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-15': {
      open: '102.04',
      high: '104.64',
      low: '101.23',
      close: '101.83',
      'adjusted close': '58.6312320144395',
      volume: '11629500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-14': {
      open: '105.0',
      high: '105.59',
      low: '101.33',
      close: '102.93',
      'adjusted close': '59.2645852032432',
      volume: '18009400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-11': {
      open: '99.25',
      high: '99.46',
      low: '97.04',
      close: '97.67',
      'adjusted close': '56.236005409509',
      volume: '8959900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-10': {
      open: '97.39',
      high: '100.86',
      low: '97.15',
      close: '99.92',
      'adjusted close': '57.5315005684258',
      volume: '10634700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-09': {
      open: '98.08',
      high: '99.15',
      low: '97.16',
      close: '98.31',
      'adjusted close': '56.6045018102676',
      volume: '10922100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-08': {
      open: '100.05',
      high: '100.38',
      low: '97.17',
      close: '97.59',
      'adjusted close': '56.1899433594142',
      volume: '9434400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-07': {
      open: '100.355',
      high: '101.0',
      low: '99.03',
      close: '100.05',
      'adjusted close': '57.6063513998298',
      volume: '12650400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-04': {
      open: '103.95',
      high: '103.95',
      low: '100.48',
      close: '101.13',
      'adjusted close': '58.2281890761098',
      volume: '11034600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-03': {
      open: '104.41',
      high: '105.57',
      low: '103.98',
      close: '104.9',
      'adjusted close': '60.3988631868281',
      volume: '7528900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2008-01-02': {
      open: '108.99',
      high: '108.99',
      low: '104.17',
      close: '104.69',
      'adjusted close': '60.2779503053292',
      volume: '9503500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-31': {
      open: '109.25',
      high: '110.0',
      low: '107.26',
      close: '108.1',
      'adjusted close': '62.2413451906207',
      volume: '5732300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-28': {
      open: '110.76',
      high: '110.76',
      low: '109.108',
      close: '110.09',
      'adjusted close': '63.3871386867293',
      volume: '5834500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-27': {
      open: '110.75',
      high: '111.3',
      low: '109.49',
      close: '109.6',
      'adjusted close': '63.1050086298985',
      volume: '4513600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-26': {
      open: '111.25',
      high: '112.19',
      low: '110.9',
      close: '111.56',
      'adjusted close': '64.2335288572215',
      volume: '3849400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-24': {
      open: '111.2',
      high: '112.0',
      low: '110.55',
      close: '111.65',
      'adjusted close': '64.2853486635782',
      volume: '2342400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-21': {
      open: '109.75',
      high: '111.16',
      low: '109.15',
      close: '111.05',
      'adjusted close': '63.9398832878671',
      volume: '13246600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-20': {
      open: '108.42',
      high: '109.49',
      low: '107.61',
      close: '108.84',
      'adjusted close': '62.6674191539978',
      volume: '8030900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-19': {
      open: '106.54',
      high: '108.38',
      low: '105.87',
      close: '107.14',
      'adjusted close': '61.6886005894829',
      volume: '7031100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-18': {
      open: '104.4',
      high: '106.97',
      low: '104.13',
      close: '106.31',
      'adjusted close': '61.2107068197492',
      volume: '8042000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-17': {
      open: '105.37',
      high: '105.99',
      low: '104.0',
      close: '104.53',
      'adjusted close': '60.1858262051395',
      volume: '7131100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-14': {
      open: '107.31',
      high: '107.56',
      low: '104.1',
      close: '105.77',
      'adjusted close': '60.8997879816092',
      volume: '9711200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-13': {
      open: '108.0',
      high: '108.64',
      low: '106.94',
      close: '108.18',
      'adjusted close': '62.2874072407156',
      volume: '6252800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-12': {
      open: '108.95',
      high: '110.2',
      low: '107.38',
      close: '108.47',
      'adjusted close': '62.4543821723093',
      volume: '7879800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-11': {
      open: '108.9',
      high: '110.4',
      low: '106.46',
      close: '106.99',
      'adjusted close': '61.6022342455552',
      volume: '8439700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-10': {
      open: '109.0',
      high: '110.26',
      low: '109.0',
      close: '109.39',
      'adjusted close': '62.9840957483997',
      volume: '4442200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-07': {
      open: '109.68',
      high: '110.0',
      low: '107.95',
      close: '108.86',
      'adjusted close': '62.6789346665215',
      volume: '5985500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-06': {
      open: '108.0',
      high: '110.0',
      low: '107.93',
      close: '109.7',
      'adjusted close': '63.1625861925171',
      volume: '5315500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-05': {
      open: '107.46',
      high: '108.52',
      low: '106.98',
      close: '108.16',
      'adjusted close': '62.2758917281918',
      volume: '6090400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-04': {
      open: '105.83',
      high: '107.42',
      low: '104.72',
      close: '106.63',
      'adjusted close': '61.3949550201285',
      volume: '6361800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-12-03': {
      open: '105.45',
      high: '107.14',
      low: '104.58',
      close: '105.83',
      'adjusted close': '60.9343345191803',
      volume: '7918800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-30': {
      open: '107.529',
      high: '109.19',
      low: '103.93',
      close: '105.18',
      'adjusted close': '60.5600803621599',
      volume: '12900200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-29': {
      open: '106.8',
      high: '107.87',
      low: '106.25',
      close: '107.5',
      'adjusted close': '61.8958798149096',
      volume: '7813800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-28': {
      open: '104.82',
      high: '108.27',
      low: '104.36',
      close: '107.37',
      'adjusted close': '61.8210289835055',
      volume: '9397700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-27': {
      open: '101.5',
      high: '104.58',
      low: '101.5',
      close: '103.83',
      'adjusted close': '59.7827832668099',
      volume: '9117500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-26': {
      open: '104.4',
      high: '105.15',
      low: '101.72',
      close: '101.97',
      'adjusted close': '58.7118406021054',
      volume: '7340700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-23': {
      open: '103.1',
      high: '104.14',
      low: '102.32',
      close: '104.05',
      'adjusted close': '59.9094539045706',
      volume: '2827700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-21': {
      open: '101.41',
      high: '104.09',
      low: '101.41',
      close: '102.22',
      'adjusted close': '58.8557845086517',
      volume: '7097200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-20': {
      open: '102.59',
      high: '104.97',
      low: '101.38',
      close: '103.42',
      'adjusted close': '59.546715260074',
      volume: '8393400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-19': {
      open: '104.34',
      high: '105.39',
      low: '101.34',
      close: '102.22',
      'adjusted close': '58.8557845086517',
      volume: '9502200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-16': {
      open: '104.51',
      high: '105.0',
      low: '103.25',
      close: '104.79',
      'adjusted close': '60.3355278679477',
      volume: '9294000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-15': {
      open: '103.24',
      high: '105.64',
      low: '103.05',
      close: '103.6',
      'adjusted close': '59.6503548727873',
      volume: '8023600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-14': {
      open: '106.12',
      high: '106.42',
      low: '102.84',
      close: '103.44',
      'adjusted close': '59.5582307725977',
      volume: '8453600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-13': {
      open: '103.5',
      high: '105.74',
      low: '102.5',
      close: '105.27',
      'adjusted close': '60.6119001685166',
      volume: '10777900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-12': {
      open: '101.89',
      high: '104.19',
      low: '100.7',
      close: '101.45',
      'adjusted close': '58.4124372764891',
      volume: '13553600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-09': {
      open: '104.92',
      high: '104.92',
      low: '99.27',
      close: '100.2542',
      'adjusted close': '57.7239247826968',
      volume: '18084100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-08': {
      open: '110.1',
      high: '110.32',
      low: '103.99',
      close: '106.11',
      'adjusted close': '61.0955516945122',
      volume: '23092200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-07': {
      open: '113.56',
      high: '113.64',
      low: '110.9',
      close: '111.08',
      'adjusted close': '63.9571565566526',
      volume: '7087000',
      'dividend amount': '0.4000',
      'split coefficient': '1.0',
    },
    '2007-11-06': {
      open: '113.49',
      high: '113.95',
      low: '111.67',
      close: '113.17',
      'adjusted close': '64.9267259375348',
      volume: '7299700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-05': {
      open: '115.11',
      high: '115.11',
      low: '112.83',
      close: '113.4',
      'adjusted close': '65.0586791668856',
      volume: '7155300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-02': {
      open: '114.42',
      high: '115.15',
      low: '113.57',
      close: '114.59',
      'adjusted close': '65.7413937013529',
      volume: '6114800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-11-01': {
      open: '115.5',
      high: '116.09',
      low: '113.32',
      close: '113.65',
      'adjusted close': '65.2021065900931',
      volume: '7594400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-31': {
      open: '114.75',
      high: '116.25',
      low: '113.28',
      close: '116.12',
      'adjusted close': '66.6191695313824',
      volume: '7216800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-30': {
      open: '114.5',
      high: '114.9',
      low: '113.75',
      close: '114.12',
      'adjusted close': '65.471750145723',
      volume: '4015500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-29': {
      open: '113.9',
      high: '115.01',
      low: '113.85',
      close: '114.8',
      'adjusted close': '65.8618727368472',
      volume: '5103200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-26': {
      open: '113.0',
      high: '114.0',
      low: '112.07',
      close: '113.73',
      'adjusted close': '65.2480033655194',
      volume: '5030300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-25': {
      open: '113.32',
      high: '114.4',
      low: '111.69',
      close: '112.81',
      'adjusted close': '64.7201904481161',
      volume: '6519900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-24': {
      open: '114.2',
      high: '114.45',
      low: '111.68',
      close: '112.95',
      'adjusted close': '64.8005098051123',
      volume: '8072600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-23': {
      open: '113.78',
      high: '114.8',
      low: '113.5',
      close: '114.68',
      'adjusted close': '65.7930275737076',
      volume: '5562900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-22': {
      open: '110.97',
      high: '113.88',
      low: '110.96',
      close: '113.37',
      'adjusted close': '65.0414678761007',
      volume: '7576900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-19': {
      open: '113.98',
      high: '114.93',
      low: '111.8',
      close: '112.28',
      'adjusted close': '64.4161243109164',
      volume: '10327200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-18': {
      open: '114.82',
      high: '116.41',
      low: '114.44',
      close: '114.8',
      'adjusted close': '65.8618727368472',
      volume: '7736500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-17': {
      open: '118.01',
      high: '118.01',
      low: '114.15',
      close: '115.78',
      'adjusted close': '66.4241082358203',
      volume: '18100500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-16': {
      open: '118.02',
      high: '119.94',
      low: '117.5',
      close: '119.6',
      'adjusted close': '68.6156792624296',
      volume: '11527300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-15': {
      open: '117.95',
      high: '118.37',
      low: '116.75',
      close: '118.03',
      'adjusted close': '67.714955044687',
      volume: '6267300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-12': {
      open: '118.5',
      high: '118.89',
      low: '117.28',
      close: '117.81',
      'adjusted close': '67.5887389122645',
      volume: '5656200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-11': {
      open: '118.99',
      high: '121.46',
      low: '117.37',
      close: '118.05',
      'adjusted close': '67.7264292385436',
      volume: '13018800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-10': {
      open: '118.0',
      high: '118.8',
      low: '117.68',
      close: '118.62',
      'adjusted close': '68.0534437634566',
      volume: '5913800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-09': {
      open: '117.85',
      high: '118.5',
      low: '116.42',
      close: '118.3',
      'adjusted close': '67.8698566617511',
      volume: '5670600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-08': {
      open: '116.1',
      high: '118.23',
      low: '115.88',
      close: '117.77',
      'adjusted close': '67.5657905245513',
      volume: '4822900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-05': {
      open: '116.17',
      high: '116.71',
      low: '115.47',
      close: '116.3',
      'adjusted close': '66.7224372760917',
      volume: '5749400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-04': {
      open: '116.2',
      high: '116.75',
      low: '115.0',
      close: '115.69',
      'adjusted close': '66.3724743634656',
      volume: '6177500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-03': {
      open: '117.65',
      high: '117.84',
      low: '116.28',
      close: '116.4',
      'adjusted close': '66.7798082453747',
      volume: '7914100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-02': {
      open: '119.31',
      high: '119.6',
      low: '118.14',
      close: '118.36',
      'adjusted close': '67.9042792433208',
      volume: '6911500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-10-01': {
      open: '117.61',
      high: '119.5',
      low: '117.5',
      close: '119.03',
      'adjusted close': '68.2886647375167',
      volume: '6777300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-28': {
      open: '117.14',
      high: '118.5',
      low: '117.1',
      close: '117.8',
      'adjusted close': '67.5830018153362',
      volume: '7720200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-27': {
      open: '117.5',
      high: '118.21',
      low: '117.04',
      close: '117.71',
      'adjusted close': '67.5313679429815',
      volume: '5834500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-26': {
      open: '116.94',
      high: '118.08',
      low: '116.75',
      close: '117.3',
      'adjusted close': '67.2961469689214',
      volume: '6695200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-25': {
      open: '116.02',
      high: '117.59',
      low: '115.79',
      close: '116.51',
      'adjusted close': '66.8429163115859',
      volume: '7045200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-24': {
      open: '116.9',
      high: '117.43',
      low: '115.85',
      close: '116.25',
      'adjusted close': '66.6937517914502',
      volume: '5734300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-21': {
      open: '117.89',
      high: '118.1',
      low: '116.35',
      close: '116.78',
      'adjusted close': '66.99781792865',
      volume: '9097300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-20': {
      open: '116.27',
      high: '117.65',
      low: '116.2',
      close: '116.86',
      'adjusted close': '67.0437147040763',
      volume: '5806400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-19': {
      open: '116.9',
      high: '117.19',
      low: '115.78',
      close: '116.67',
      'adjusted close': '66.9347098624387',
      volume: '10311200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-18': {
      open: '115.0',
      high: '116.78',
      low: '114.83',
      close: '116.63',
      'adjusted close': '66.9117614747255',
      volume: '7839500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-17': {
      open: '114.7',
      high: '115.25',
      low: '114.3',
      close: '114.52',
      'adjusted close': '65.7012340228549',
      volume: '5022400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-14': {
      open: '115.05',
      high: '116.0',
      low: '114.65',
      close: '115.13',
      'adjusted close': '66.051196935481',
      volume: '6904400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-13': {
      open: '116.3',
      high: '117.0',
      low: '115.74',
      close: '115.95',
      'adjusted close': '66.5216388836013',
      volume: '4802700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-12': {
      open: '116.95',
      high: '117.4',
      low: '115.69',
      close: '116.0',
      'adjusted close': '66.5503243682428',
      volume: '6287200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-11': {
      open: '116.35',
      high: '117.78',
      low: '116.04',
      close: '117.35',
      'adjusted close': '67.3248324535629',
      volume: '8098700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-10': {
      open: '116.15',
      high: '117.5',
      low: '115.32',
      close: '115.8',
      'adjusted close': '66.4355824296769',
      volume: '6118300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-07': {
      open: '116.2',
      high: '116.97',
      low: '115.33',
      close: '115.55',
      'adjusted close': '66.2921550064694',
      volume: '7511700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-06': {
      open: '117.92',
      high: '118.75',
      low: '116.24',
      close: '117.62',
      'adjusted close': '67.4797340706269',
      volume: '6778600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-05': {
      open: '117.48',
      high: '118.15',
      low: '116.55',
      close: '117.88',
      'adjusted close': '67.6288985907626',
      volume: '6555500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-09-04': {
      open: '116.34',
      high: '118.89',
      low: '116.15',
      close: '118.19',
      'adjusted close': '67.8067485955398',
      volume: '8070500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-31': {
      open: '115.99',
      high: '117.35',
      low: '115.55',
      close: '116.69',
      'adjusted close': '66.9461840562953',
      volume: '6883600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-30': {
      open: '113.62',
      high: '116.0',
      low: '113.62',
      close: '115.37',
      'adjusted close': '66.1888872617601',
      volume: '7756100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-29': {
      open: '112.88',
      high: '114.98',
      low: '112.07',
      close: '114.57',
      'adjusted close': '65.7299195074963',
      volume: '7049800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-28': {
      open: '112.9',
      high: '113.59',
      low: '111.63',
      close: '112.0',
      'adjusted close': '64.2554855969241',
      volume: '6006600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-27': {
      open: '113.0',
      high: '113.85',
      low: '112.8',
      close: '113.44',
      'adjusted close': '65.0816275545988',
      volume: '4921800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-24': {
      open: '111.45',
      high: '113.25',
      low: '110.81',
      close: '113.24',
      'adjusted close': '64.9668856160329',
      volume: '7058700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-23': {
      open: '110.4',
      high: '111.68',
      low: '110.01',
      close: '111.45',
      'adjusted close': '63.9399452658678',
      volume: '8271000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-22': {
      open: '110.09',
      high: '110.5',
      low: '109.15',
      close: '110.0',
      'adjusted close': '63.1080662112647',
      volume: '6329700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-21': {
      open: '108.9',
      high: '110.11',
      low: '108.08',
      close: '109.04',
      'adjusted close': '62.5573049061482',
      volume: '8458500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-20': {
      open: '110.9',
      high: '111.1',
      low: '109.13',
      close: '109.22',
      'adjusted close': '62.6605726508576',
      volume: '9839800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-17': {
      open: '111.9',
      high: '112.67',
      low: '108.66',
      close: '110.9',
      'adjusted close': '63.6244049348114',
      volume: '15274600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-16': {
      open: '110.98',
      high: '111.42',
      low: '103.7',
      close: '109.69',
      'adjusted close': '62.9302162064875',
      volume: '16723000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-15': {
      open: '111.0',
      high: '112.85',
      low: '109.91',
      close: '111.23',
      'adjusted close': '63.8137291334452',
      volume: '11640200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-14': {
      open: '112.83',
      high: '113.15',
      low: '111.74',
      close: '112.05',
      'adjusted close': '64.2841710815656',
      volume: '5615600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-13': {
      open: '112.99',
      high: '113.95',
      low: '112.31',
      close: '112.71',
      'adjusted close': '64.6628194788331',
      volume: '6135500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-10': {
      open: '109.75',
      high: '113.5',
      low: '109.7',
      close: '112.64',
      'adjusted close': '64.6226598003351',
      volume: '9592200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-09': {
      open: '110.77',
      high: '114.49',
      low: '110.73',
      close: '110.73',
      'adjusted close': '63.5268742870304',
      volume: '9086000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-08': {
      open: '114.3',
      high: '114.6',
      low: '112.26',
      close: '112.98',
      'adjusted close': '64.8177210958972',
      volume: '7681400',
      'dividend amount': '0.4000',
      'split coefficient': '1.0',
    },
    '2007-08-07': {
      open: '113.02',
      high: '114.17',
      low: '112.25',
      close: '113.53',
      'adjusted close': '64.903473946174',
      volume: '8021400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-06': {
      open: '111.97',
      high: '113.89',
      low: '111.7',
      close: '113.89',
      'adjusted close': '65.1092807868383',
      volume: '7245500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-03': {
      open: '113.24',
      high: '114.18',
      low: '111.7',
      close: '111.89',
      'adjusted close': '63.9659094498142',
      volume: '9571300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-02': {
      open: '112.1',
      high: '114.05',
      low: '111.5',
      close: '113.23',
      'adjusted close': '64.7319682456204',
      volume: '11318900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-08-01': {
      open: '110.39',
      high: '112.73',
      low: '110.06',
      close: '112.04',
      'adjusted close': '64.051662300091',
      volume: '12390300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-31': {
      open: '114.72',
      high: '114.72',
      low: '110.02',
      close: '110.65',
      'adjusted close': '63.2570192208592',
      volume: '17816200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-30': {
      open: '115.62',
      high: '115.75',
      low: '113.52',
      close: '114.52',
      'adjusted close': '65.4694427580009',
      volume: '15095400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-27': {
      open: '116.62',
      high: '117.95',
      low: '115.62',
      close: '115.62',
      'adjusted close': '66.0982969933642',
      volume: '18138700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-26': {
      open: '117.01',
      high: '118.82',
      low: '115.52',
      close: '116.53',
      'adjusted close': '66.6185309517102',
      volume: '16822100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-25': {
      open: '116.19',
      high: '118.38',
      low: '115.24',
      close: '118.1',
      'adjusted close': '67.5160774512741',
      volume: '11340000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-24': {
      open: '115.32',
      high: '117.0',
      low: '115.02',
      close: '116.17',
      'adjusted close': '66.4127241110458',
      volume: '10499300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-23': {
      open: '114.88',
      high: '116.43',
      low: '114.85',
      close: '116.38',
      'adjusted close': '66.5327781014333',
      volume: '8040900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-20': {
      open: '114.85',
      high: '115.86',
      low: '113.94',
      close: '114.81',
      'adjusted close': '65.6352316018694',
      volume: '11706300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-19': {
      open: '115.0',
      high: '116.48',
      low: '114.65',
      close: '115.86',
      'adjusted close': '66.2355015538071',
      volume: '17281600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-18': {
      open: '110.25',
      high: '111.88',
      low: '110.04',
      close: '111.08',
      'adjusted close': '63.5028440583194',
      volume: '10154100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-17': {
      open: '109.66',
      high: '111.47',
      low: '109.45',
      close: '110.77',
      'adjusted close': '63.3256215010807',
      volume: '7619900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-16': {
      open: '108.57',
      high: '110.04',
      low: '108.42',
      close: '109.66',
      'adjusted close': '62.6910504090323',
      volume: '8864200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-13': {
      open: '108.75',
      high: '109.2',
      low: '108.1',
      close: '108.6',
      'adjusted close': '62.0850636004095',
      volume: '7124100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-12': {
      open: '109.35',
      high: '109.65',
      low: '108.22',
      close: '109.28',
      'adjusted close': '62.4738098549977',
      volume: '9544100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-11': {
      open: '108.3',
      high: '109.6',
      low: '108.25',
      close: '109.1',
      'adjusted close': '62.3709064346656',
      volume: '5887800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-10': {
      open: '108.55',
      high: '109.15',
      low: '108.25',
      close: '108.63',
      'adjusted close': '62.1022141704649',
      volume: '6209300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-09': {
      open: '108.73',
      high: '109.35',
      low: '108.2',
      close: '108.97',
      'adjusted close': '62.296587297759',
      volume: '6046300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-06': {
      open: '107.79',
      high: '109.66',
      low: '107.66',
      close: '109.03',
      'adjusted close': '62.3308884378697',
      volume: '8977200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-05': {
      open: '106.31',
      high: '108.39',
      low: '105.98',
      close: '108.05',
      'adjusted close': '61.7706364827279',
      volume: '6670900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-03': {
      open: '105.5',
      high: '107.08',
      low: '105.24',
      close: '106.58',
      'adjusted close': '60.9302585500152',
      volume: '4217900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-07-02': {
      open: '105.39',
      high: '105.8',
      low: '104.58',
      close: '105.01',
      'adjusted close': '60.0327120504512',
      volume: '4701300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-29': {
      open: '105.96',
      high: '106.58',
      low: '104.1',
      close: '105.25',
      'adjusted close': '60.1699166108941',
      volume: '7018400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-28': {
      open: '105.52',
      high: '106.92',
      low: '105.22',
      close: '105.95',
      'adjusted close': '60.5700965788526',
      volume: '5430900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-27': {
      open: '104.4',
      high: '105.47',
      low: '104.4',
      close: '105.43',
      'adjusted close': '60.2728200312263',
      volume: '5403800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-26': {
      open: '105.1',
      high: '106.06',
      low: '104.82',
      close: '105.33',
      'adjusted close': '60.2156514643751',
      volume: '5229600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-25': {
      open: '104.73',
      high: '105.77',
      low: '104.5',
      close: '105.1',
      'adjusted close': '60.0841637606173',
      volume: '8476400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-22': {
      open: '105.79',
      high: '106.44',
      low: '104.44',
      close: '104.44',
      'adjusted close': '59.7068512193994',
      volume: '10808500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-21': {
      open: '105.77',
      high: '107.0',
      low: '105.61',
      close: '106.6',
      'adjusted close': '60.9416922633854',
      volume: '5587800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-20': {
      open: '106.5',
      high: '107.02',
      low: '105.95',
      close: '106.0',
      'adjusted close': '60.5986808622782',
      volume: '6541500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-19': {
      open: '104.93',
      high: '106.66',
      low: '104.9',
      close: '106.5',
      'adjusted close': '60.8845236965342',
      volume: '5754000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-18': {
      open: '105.27',
      high: '105.65',
      low: '104.89',
      close: '105.33',
      'adjusted close': '60.2156514643751',
      volume: '6359200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-15': {
      open: '104.4',
      high: '105.19',
      low: '103.88',
      close: '105.09',
      'adjusted close': '60.0784469039322',
      volume: '9432300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-14': {
      open: '103.12',
      high: '104.24',
      low: '102.81',
      close: '103.85',
      'adjusted close': '59.3695566749772',
      volume: '5165600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-13': {
      open: '102.91',
      high: '103.26',
      low: '102.1',
      close: '103.12',
      'adjusted close': '58.9522261369635',
      volume: '6437600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-12': {
      open: '103.11',
      high: '103.18',
      low: '102.15',
      close: '102.34',
      'adjusted close': '58.506311315524',
      volume: '5578400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-11': {
      open: '102.87',
      high: '104.0',
      low: '102.5',
      close: '103.22',
      'adjusted close': '59.0093947038147',
      volume: '7903100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-08': {
      open: '101.8',
      high: '103.21',
      low: '101.75',
      close: '103.07',
      'adjusted close': '58.9236418535378',
      volume: '8216300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-07': {
      open: '102.46',
      high: '103.17',
      low: '101.56',
      close: '101.8',
      'adjusted close': '58.1976010545275',
      volume: '14384600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-06': {
      open: '105.5',
      high: '105.51',
      low: '102.39',
      close: '102.41',
      'adjusted close': '58.5463293123199',
      volume: '30770700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-05': {
      open: '106.55',
      high: '106.55',
      low: '105.54',
      close: '105.84',
      'adjusted close': '60.5072111553162',
      volume: '5244200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-04': {
      open: '106.5',
      high: '106.59',
      low: '105.58',
      close: '106.23',
      'adjusted close': '60.7301685660359',
      volume: '4569900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-06-01': {
      open: '106.62',
      high: '107.24',
      low: '106.02',
      close: '106.54',
      'adjusted close': '60.9073911232747',
      volume: '5289200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-31': {
      open: '107.23',
      high: '107.67',
      low: '106.0',
      close: '106.6',
      'adjusted close': '60.9416922633854',
      volume: '5097700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-30': {
      open: '105.4',
      high: '107.1',
      low: '105.21',
      close: '106.93',
      'adjusted close': '61.1303485339944',
      volume: '7401000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-29': {
      open: '105.57',
      high: '106.63',
      low: '105.3',
      close: '105.91',
      'adjusted close': '60.5472291521121',
      volume: '5420300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-25': {
      open: '103.9',
      high: '105.43',
      low: '103.85',
      close: '105.18',
      'adjusted close': '60.1298986140983',
      volume: '6123000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-24': {
      open: '105.25',
      high: '105.98',
      low: '103.57',
      close: '103.95',
      'adjusted close': '59.4267252418285',
      volume: '6926300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-23': {
      open: '106.8',
      high: '107.0',
      low: '105.25',
      close: '105.58',
      'adjusted close': '60.3585728815031',
      volume: '4396900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-22': {
      open: '106.95',
      high: '107.25',
      low: '106.01',
      close: '106.7',
      'adjusted close': '60.9988608302366',
      volume: '5313300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-21': {
      open: '107.15',
      high: '108.0',
      low: '106.87',
      close: '107.04',
      'adjusted close': '61.1932339575307',
      volume: '7512200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-18': {
      open: '105.84',
      high: '108.05',
      low: '105.6',
      close: '107.99',
      'adjusted close': '61.7363353426172',
      volume: '9857500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-17': {
      open: '105.61',
      high: '106.23',
      low: '104.73',
      close: '105.31',
      'adjusted close': '60.2042177510049',
      volume: '7706400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-16': {
      open: '105.1',
      high: '105.89',
      low: '104.53',
      close: '105.87',
      'adjusted close': '60.5243617253716',
      volume: '5613500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-15': {
      open: '105.4',
      high: '105.8',
      low: '104.7',
      close: '104.83',
      'adjusted close': '59.9298086301191',
      volume: '6104200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-14': {
      open: '105.81',
      high: '106.25',
      low: '104.79',
      close: '105.57',
      'adjusted close': '60.352856024818',
      volume: '5732600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-11': {
      open: '104.69',
      high: '105.99',
      low: '104.67',
      close: '105.98',
      'adjusted close': '60.5872471489079',
      volume: '6026100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-10': {
      open: '103.82',
      high: '105.11',
      low: '103.71',
      close: '104.68',
      'adjusted close': '59.8440557798423',
      volume: '8959800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-09': {
      open: '103.93',
      high: '104.75',
      low: '103.7',
      close: '104.38',
      'adjusted close': '59.6725500792886',
      volume: '8408100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-08': {
      open: '102.4',
      high: '103.39',
      low: '102.21',
      close: '103.29',
      'adjusted close': '59.0494127006105',
      volume: '5965000',
      'dividend amount': '0.4000',
      'split coefficient': '1.0',
    },
    '2007-05-07': {
      open: '102.76',
      high: '103.17',
      low: '102.4',
      close: '102.76',
      'adjusted close': '58.5197960180802',
      volume: '5620100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-04': {
      open: '102.75',
      high: '103.03',
      low: '102.25',
      close: '102.96',
      'adjusted close': '58.6336920788394',
      volume: '5464800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-03': {
      open: '102.1',
      high: '103.0',
      low: '101.35',
      close: '102.8',
      'adjusted close': '58.542575230232',
      volume: '7302200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-02': {
      open: '102.8',
      high: '103.14',
      low: '102.13',
      close: '102.22',
      'adjusted close': '58.2122766540303',
      volume: '6820300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-05-01': {
      open: '102.06',
      high: '103.17',
      low: '102.05',
      close: '103.17',
      'adjusted close': '58.7532829426366',
      volume: '8670100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-30': {
      open: '101.11',
      high: '103.0',
      low: '100.97',
      close: '102.21',
      'adjusted close': '58.2065818509924',
      volume: '10050400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-27': {
      open: '100.3',
      high: '101.17',
      low: '100.06',
      close: '101.17',
      'adjusted close': '57.6143223350445',
      volume: '6141400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-26': {
      open: '100.4',
      high: '101.5',
      low: '100.21',
      close: '100.9',
      'adjusted close': '57.4605626530196',
      volume: '8608500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-25': {
      open: '98.75',
      high: '101.7',
      low: '98.73',
      close: '101.46',
      'adjusted close': '57.7794716231453',
      volume: '14827300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-24': {
      open: '99.55',
      high: '99.9',
      low: '97.23',
      close: '98.49',
      'adjusted close': '56.0881151208711',
      volume: '21450700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-23': {
      open: '95.35',
      high: '95.73',
      low: '95.05',
      close: '95.21',
      'adjusted close': '54.2202197244201',
      volume: '7247400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-20': {
      open: '95.02',
      high: '95.15',
      low: '93.911',
      close: '94.58',
      'adjusted close': '53.8614471330286',
      volume: '9340300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-19': {
      open: '94.5',
      high: '94.87',
      low: '94.0',
      close: '94.29',
      'adjusted close': '53.6962978449278',
      volume: '9445600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-18': {
      open: '94.8',
      high: '95.91',
      low: '94.4',
      close: '94.8',
      'adjusted close': '53.9867327998638',
      volume: '15871700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-17': {
      open: '96.3',
      high: '97.66',
      low: '96.02',
      close: '97.12',
      'adjusted close': '55.3079271046706',
      volume: '10264000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-16': {
      open: '94.99',
      high: '96.3',
      low: '94.72',
      close: '96.18',
      'adjusted close': '54.7726156191023',
      volume: '6865100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-13': {
      open: '95.75',
      high: '95.75',
      low: '94.59',
      close: '94.93',
      'adjusted close': '54.0607652393573',
      volume: '6027800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-12': {
      open: '94.86',
      high: '95.82',
      low: '94.53',
      close: '95.67',
      'adjusted close': '54.4821806641663',
      volume: '7085300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-11': {
      open: '96.46',
      high: '96.7',
      low: '94.95',
      close: '95.16',
      'adjusted close': '54.1917457092303',
      volume: '5936900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-10': {
      open: '96.4',
      high: '96.8',
      low: '96.1642',
      close: '96.46',
      'adjusted close': '54.9320701041652',
      volume: '3596400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-09': {
      open: '96.43',
      high: '96.94',
      low: '96.12',
      close: '96.62',
      'adjusted close': '55.0231869527725',
      volume: '4850600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-05': {
      open: '95.98',
      high: '96.75',
      low: '95.75',
      close: '96.52',
      'adjusted close': '54.9662389223929',
      volume: '3307100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-04': {
      open: '95.8',
      high: '96.45',
      low: '95.57',
      close: '96.21',
      'adjusted close': '54.7897000282162',
      volume: '4276200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-03': {
      open: '95.45',
      high: '96.23',
      low: '95.33',
      close: '96.1',
      'adjusted close': '54.7270571947986',
      volume: '5730800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-04-02': {
      open: '94.51',
      high: '95.5',
      low: '94.06',
      close: '95.21',
      'adjusted close': '54.2202197244201',
      volume: '5883700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-30': {
      open: '94.24',
      high: '94.75',
      low: '93.57',
      close: '94.26',
      'adjusted close': '53.6792134358139',
      volume: '7638100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-29': {
      open: '94.95',
      high: '95.11',
      low: '93.96',
      close: '94.57',
      'adjusted close': '53.8557523299907',
      volume: '6851500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-28': {
      open: '93.9',
      high: '95.02',
      low: '93.8',
      close: '94.26',
      'adjusted close': '53.6792134358139',
      volume: '8128000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-27': {
      open: '94.71',
      high: '95.03',
      low: '94.2',
      close: '94.73',
      'adjusted close': '53.946869178598',
      volume: '4695800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-26': {
      open: '94.84',
      high: '95.25',
      low: '94.32',
      close: '95.0',
      'adjusted close': '54.100628860623',
      volume: '6729400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-23': {
      open: '94.86',
      high: '95.22',
      low: '94.65',
      close: '95.03',
      'adjusted close': '54.1177132697369',
      volume: '4412500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-22': {
      open: '95.22',
      high: '95.44',
      low: '94.65',
      close: '95.19',
      'adjusted close': '54.2088301183442',
      volume: '5700400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-21': {
      open: '94.5',
      high: '95.81',
      low: '93.83',
      close: '95.36',
      'adjusted close': '54.3056417699895',
      volume: '7665600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-20': {
      open: '94.0',
      high: '94.53',
      low: '93.55',
      close: '94.5',
      'adjusted close': '53.815888708725',
      volume: '4560000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-19': {
      open: '93.95',
      high: '94.32',
      low: '93.39',
      close: '93.99',
      'adjusted close': '53.525453753789',
      volume: '4568100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-16': {
      open: '93.49',
      high: '94.45',
      low: '93.02',
      close: '93.25',
      'adjusted close': '53.1040383289799',
      volume: '8728700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-15': {
      open: '93.4',
      high: '93.97',
      low: '93.1',
      close: '93.45',
      'adjusted close': '53.2179343897391',
      volume: '5910000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-14': {
      open: '92.71',
      high: '94.03',
      low: '92.1',
      close: '93.76',
      'adjusted close': '53.3944732839159',
      volume: '8713900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-13': {
      open: '93.86',
      high: '94.47',
      low: '92.62',
      close: '92.71',
      'adjusted close': '52.7965189649301',
      volume: '7539100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-12': {
      open: '93.29',
      high: '94.17',
      low: '92.97',
      close: '94.11',
      'adjusted close': '53.5937913902445',
      volume: '5531300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-09': {
      open: '93.48',
      high: '94.0',
      low: '92.82',
      close: '93.28',
      'adjusted close': '53.1211227380938',
      volume: '6333000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-08': {
      open: '94.25',
      high: '94.82',
      low: '92.519',
      close: '93.0',
      'adjusted close': '52.9616682530309',
      volume: '7403900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-07': {
      open: '93.97',
      high: '94.85',
      low: '93.62',
      close: '93.94',
      'adjusted close': '53.4969797385992',
      volume: '10269100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-06': {
      open: '92.4',
      high: '93.89',
      low: '92.26',
      close: '93.8',
      'adjusted close': '53.4172524960677',
      volume: '9184200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-05': {
      open: '90.25',
      high: '92.15',
      low: '90.1',
      close: '91.81',
      'adjusted close': '52.2839866915136',
      volume: '10314900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-02': {
      open: '91.86',
      high: '92.37',
      low: '90.84',
      close: '90.9',
      'adjusted close': '51.7657596150592',
      volume: '9451300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-03-01': {
      open: '90.25',
      high: '93.05',
      low: '88.77',
      close: '92.27',
      'adjusted close': '52.5459476312598',
      volume: '12865700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-28': {
      open: '93.71',
      high: '94.09',
      low: '92.47',
      close: '92.94',
      'adjusted close': '52.9274994348031',
      volume: '12191600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-27': {
      open: '96.0',
      high: '96.01',
      low: '92.88',
      close: '93.96',
      'adjusted close': '53.5083693446751',
      volume: '11996700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-26': {
      open: '97.86',
      high: '97.86',
      low: '96.46',
      close: '96.91',
      'adjusted close': '55.1883362408734',
      volume: '5922100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-23': {
      open: '98.4',
      high: '98.44',
      low: '97.41',
      close: '97.73',
      'adjusted close': '55.6553100899861',
      volume: '6355800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-22': {
      open: '98.8',
      high: '99.5',
      low: '97.77',
      close: '98.5',
      'adjusted close': '56.0938099239091',
      volume: '5900100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-21': {
      open: '98.9',
      high: '99.37',
      low: '98.7',
      close: '99.09',
      'adjusted close': '56.4298033031487',
      volume: '4302400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-20': {
      open: '98.66',
      high: '99.46',
      low: '98.55',
      close: '99.35',
      'adjusted close': '56.5778681821357',
      volume: '4124200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-16': {
      open: '99.12',
      high: '99.25',
      low: '98.63',
      close: '98.99',
      'adjusted close': '56.3728552727691',
      volume: '4800700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-15': {
      open: '99.06',
      high: '99.52',
      low: '98.48',
      close: '98.92',
      'adjusted close': '56.3329916515034',
      volume: '5568600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-14': {
      open: '98.5',
      high: '99.43',
      low: '98.25',
      close: '99.2',
      'adjusted close': '56.4924461365663',
      volume: '5644800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-13': {
      open: '98.6',
      high: '98.74',
      low: '97.8',
      close: '98.29',
      'adjusted close': '55.9742190601119',
      volume: '5702800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-12': {
      open: '98.8',
      high: '99.2',
      low: '98.22',
      close: '98.58',
      'adjusted close': '56.1393683482128',
      volume: '5331000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-09': {
      open: '99.35',
      high: '99.7',
      low: '97.81',
      close: '98.55',
      'adjusted close': '56.1222839390989',
      volume: '6100400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-08': {
      open: '98.9',
      high: '99.74',
      low: '98.65',
      close: '99.62',
      'adjusted close': '56.7316278641606',
      volume: '6152200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-07': {
      open: '99.8',
      high: '100.36',
      low: '99.12',
      close: '99.54',
      'adjusted close': '56.686069439857',
      volume: '7698200',
      'dividend amount': '0.3000',
      'split coefficient': '1.0',
    },
    '2007-02-06': {
      open: '100.0',
      high: '100.4',
      low: '99.54',
      close: '99.55',
      'adjusted close': '56.5214163936074',
      volume: '6532800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-05': {
      open: '99.17',
      high: '100.44',
      low: '98.9',
      close: '100.38',
      'adjusted close': '56.9926647673562',
      volume: '8184800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-02': {
      open: '99.1',
      high: '99.73',
      low: '98.88',
      close: '99.17',
      'adjusted close': '56.3056641261079',
      volume: '6656300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-02-01': {
      open: '98.97',
      high: '99.18',
      low: '97.96',
      close: '99.0',
      'adjusted close': '56.2091433748582',
      volume: '6610700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-31': {
      open: '98.8',
      high: '99.48',
      low: '98.35',
      close: '99.15',
      'adjusted close': '56.2943087436079',
      volume: '6432600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-30': {
      open: '98.62',
      high: '99.45',
      low: '98.5',
      close: '99.37',
      'adjusted close': '56.4192179511076',
      volume: '7177900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-29': {
      open: '98.1',
      high: '98.66',
      low: '97.45',
      close: '98.54',
      'adjusted close': '55.9479695773588',
      volume: '7294800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-26': {
      open: '97.49',
      high: '97.83',
      low: '96.84',
      close: '97.45',
      'adjusted close': '55.3291012311104',
      volume: '5771100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-25': {
      open: '97.22',
      high: '97.92',
      low: '97.22',
      close: '97.51',
      'adjusted close': '55.3631673786103',
      volume: '6201300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-24': {
      open: '97.14',
      high: '97.58',
      low: '96.58',
      close: '97.4',
      'adjusted close': '55.3007127748605',
      volume: '5700000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-23': {
      open: '96.91',
      high: '97.38',
      low: '96.2',
      close: '97.08',
      'adjusted close': '55.1190266548609',
      volume: '10337400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-22': {
      open: '96.42',
      high: '97.23',
      low: '96.12',
      close: '97.11',
      'adjusted close': '55.1360597286109',
      volume: '13539300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-19': {
      open: '95.0',
      high: '96.85',
      low: '94.55',
      close: '96.17',
      'adjusted close': '54.6023567511122',
      volume: '26035800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-18': {
      open: '99.79',
      high: '99.95',
      low: '98.91',
      close: '99.45',
      'adjusted close': '56.4646394811075',
      volume: '14636100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-17': {
      open: '100.69',
      high: '100.9',
      low: '99.9',
      close: '100.02',
      'adjusted close': '56.7882678823567',
      volume: '8200700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-16': {
      open: '99.4',
      high: '100.84',
      low: '99.3',
      close: '100.82',
      'adjusted close': '57.2424831823555',
      volume: '9602200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-12': {
      open: '98.99',
      high: '99.69',
      low: '98.5',
      close: '99.34',
      'adjusted close': '56.4021848773577',
      volume: '6636500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-11': {
      open: '99.85',
      high: '99.9',
      low: '98.5',
      close: '98.65',
      'adjusted close': '56.0104241811087',
      volume: '8000700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-10': {
      open: '99.03',
      high: '99.05',
      low: '97.93',
      close: '98.89',
      'adjusted close': '56.1466887711083',
      volume: '8744800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-09': {
      open: '99.24',
      high: '100.33',
      low: '99.07',
      close: '100.07',
      'adjusted close': '56.8166563386066',
      volume: '11108200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-08': {
      open: '98.5',
      high: '99.5',
      low: '98.35',
      close: '98.9',
      'adjusted close': '56.1523664623583',
      volume: '10340000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-05': {
      open: '97.6',
      high: '97.95',
      low: '96.91',
      close: '97.42',
      'adjusted close': '55.3120681573604',
      volume: '7221300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-04': {
      open: '96.95',
      high: '98.79',
      low: '96.88',
      close: '98.31',
      'adjusted close': '55.8173826786091',
      volume: '10524500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2007-01-03': {
      open: '97.17',
      high: '98.4',
      low: '96.26',
      close: '97.27',
      'adjusted close': '55.2269027886106',
      volume: '9196800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-29': {
      open: '97.0',
      high: '97.88',
      low: '96.83',
      close: '97.15',
      'adjusted close': '55.1587704936108',
      volume: '4455900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-28': {
      open: '97.11',
      high: '97.4',
      low: '96.87',
      close: '96.97',
      'adjusted close': '55.0565720511111',
      volume: '4501700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-27': {
      open: '96.3',
      high: '97.23',
      low: '96.2728',
      close: '97.2',
      'adjusted close': '55.1871589498607',
      volume: '4571600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-26': {
      open: '95.0',
      high: '95.81',
      low: '94.92',
      close: '95.66',
      'adjusted close': '54.3127944973629',
      volume: '2278700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-22': {
      open: '95.75',
      high: '95.98',
      low: '95.24',
      close: '95.25',
      'adjusted close': '54.0800091561135',
      volume: '3031500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-21': {
      open: '95.86',
      high: '96.5',
      low: '95.53',
      close: '95.91',
      'adjusted close': '54.4547367786126',
      volume: '4405600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-20': {
      open: '96.31',
      high: '96.5',
      low: '95.7',
      close: '96.0',
      'adjusted close': '54.5058359998625',
      volume: '3654600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-19': {
      open: '95.1',
      high: '96.39',
      low: '94.91',
      close: '96.0',
      'adjusted close': '54.5058359998625',
      volume: '4933200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-18': {
      open: '94.95',
      high: '96.16',
      low: '94.8',
      close: '95.44',
      'adjusted close': '54.1878852898633',
      volume: '7984900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-15': {
      open: '95.61',
      high: '95.8',
      low: '95.05',
      close: '95.3',
      'adjusted close': '54.1083976123635',
      volume: '8086500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-14': {
      open: '94.76',
      high: '95.72',
      low: '94.54',
      close: '95.36',
      'adjusted close': '54.1424637598634',
      volume: '4644100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-13': {
      open: '94.78',
      high: '95.33',
      low: '94.5',
      close: '94.77',
      'adjusted close': '53.8074799761142',
      volume: '6441300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-12': {
      open: '93.6',
      high: '94.85',
      low: '93.3',
      close: '94.12',
      'adjusted close': '53.4384300448652',
      volume: '7783800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-11': {
      open: '93.72',
      high: '94.35',
      low: '93.56',
      close: '93.64',
      'adjusted close': '53.1659008648658',
      volume: '3459200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-08': {
      open: '93.65',
      high: '94.39',
      low: '93.47',
      close: '93.86',
      'adjusted close': '53.2908100723655',
      volume: '4140000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-07': {
      open: '94.0',
      high: '94.43',
      low: '93.5521',
      close: '94.23',
      'adjusted close': '53.500884648615',
      volume: '4310800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-06': {
      open: '94.7',
      high: '94.87',
      low: '94.01',
      close: '94.12',
      'adjusted close': '53.4384300448652',
      volume: '5947300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-05': {
      open: '93.6',
      high: '94.64',
      low: '93.44',
      close: '94.48',
      'adjusted close': '53.6428269298646',
      volume: '6886200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-04': {
      open: '92.5',
      high: '93.95',
      low: '92.25',
      close: '93.51',
      'adjusted close': '53.092090878616',
      volume: '7507900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-12-01': {
      open: '91.9',
      high: '91.91',
      low: '90.55',
      close: '91.25',
      'adjusted close': '51.8089326561193',
      volume: '5445400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-30': {
      open: '91.35',
      high: '92.48',
      low: '91.25',
      close: '91.92',
      'adjusted close': '52.1893379698683',
      volume: '6470700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-29': {
      open: '91.35',
      high: '91.66',
      low: '91.01',
      close: '91.52',
      'adjusted close': '51.9622303198689',
      volume: '4623000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-28': {
      open: '91.05',
      high: '91.57',
      low: '90.43',
      close: '91.35',
      'adjusted close': '51.8657095686191',
      volume: '4962100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-27': {
      open: '92.71',
      high: '93.24',
      low: '91.12',
      close: '91.45',
      'adjusted close': '51.922486481119',
      volume: '6284000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-24': {
      open: '93.05',
      high: '93.75',
      low: '92.98',
      close: '93.35',
      'adjusted close': '53.0012478186162',
      volume: '2129800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-22': {
      open: '92.9',
      high: '93.74',
      low: '92.8',
      close: '93.52',
      'adjusted close': '53.097768569866',
      volume: '3351700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-21': {
      open: '92.85',
      high: '93.43',
      low: '92.85',
      close: '93.08',
      'adjusted close': '52.8479501548666',
      volume: '3067800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-20': {
      open: '93.77',
      high: '93.8',
      low: '93.01',
      close: '93.25',
      'adjusted close': '52.9444709061164',
      volume: '5010600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-17': {
      open: '93.42',
      high: '94.05',
      low: '93.31',
      close: '93.81',
      'adjusted close': '53.2624216161156',
      volume: '5251000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-16': {
      open: '93.07',
      high: '93.6',
      low: '92.6',
      close: '93.47',
      'adjusted close': '53.0693801136161',
      volume: '4171500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-15': {
      open: '93.08',
      high: '93.28',
      low: '92.54',
      close: '93.11',
      'adjusted close': '52.8649832286166',
      volume: '4172800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-14': {
      open: '92.0',
      high: '93.29',
      low: '91.65',
      close: '93.29',
      'adjusted close': '52.9671816711163',
      volume: '5873000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-13': {
      open: '91.75',
      high: '92.43',
      low: '91.41',
      close: '92.07',
      'adjusted close': '52.2745033386181',
      volume: '4358300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-10': {
      open: '92.3',
      high: '92.35',
      low: '91.63',
      close: '91.76',
      'adjusted close': '52.0984949098685',
      volume: '5369600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-09': {
      open: '92.59',
      high: '93.19',
      low: '92.37',
      close: '92.42',
      'adjusted close': '52.4732225323676',
      volume: '4518900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-08': {
      open: '92.25',
      high: '92.92',
      low: '92.01',
      close: '92.59',
      'adjusted close': '52.5697432836173',
      volume: '6022200',
      'dividend amount': '0.3000',
      'split coefficient': '1.0',
    },
    '2006-11-07': {
      open: '92.42',
      high: '93.4',
      low: '92.41',
      close: '92.45',
      'adjusted close': '52.3207316887762',
      volume: '7305200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-06': {
      open: '91.5',
      high: '92.74',
      low: '91.5',
      close: '92.6',
      'adjusted close': '52.4056220051993',
      volume: '6240600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-03': {
      open: '91.76',
      high: '92.34',
      low: '91.1816',
      close: '91.41',
      'adjusted close': '51.7321588282427',
      volume: '4506400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-02': {
      open: '91.8',
      high: '91.95',
      low: '91.25',
      close: '91.68',
      'adjusted close': '51.8849613978043',
      volume: '4895100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-11-01': {
      open: '92.5',
      high: '92.68',
      low: '91.5',
      close: '91.8',
      'adjusted close': '51.9528736509427',
      volume: '5845700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-31': {
      open: '91.5',
      high: '92.68',
      low: '91.5',
      close: '92.33',
      'adjusted close': '52.2528194356377',
      volume: '9267300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-30': {
      open: '90.3',
      high: '92.0',
      low: '89.8',
      close: '91.5',
      'adjusted close': '51.7830930180965',
      volume: '5594000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-27': {
      open: '90.91',
      high: '91.89',
      low: '90.58',
      close: '90.76',
      'adjusted close': '51.3643007904092',
      volume: '5840300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-26': {
      open: '91.63',
      high: '91.93',
      low: '91.25',
      close: '91.54',
      'adjusted close': '51.8057304358094',
      volume: '4990300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-25': {
      open: '91.21',
      high: '91.99',
      low: '90.94',
      close: '91.83',
      'adjusted close': '51.9698517142274',
      volume: '6122100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-24': {
      open: '90.91',
      high: '91.95',
      low: '90.8',
      close: '91.49',
      'adjusted close': '51.7774336636683',
      volume: '9222300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-23': {
      open: '90.05',
      high: '92.0',
      low: '90.01',
      close: '91.56',
      'adjusted close': '51.8170491446658',
      volume: '8862300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-20': {
      open: '90.04',
      high: '90.7',
      low: '89.55',
      close: '90.48',
      'adjusted close': '51.2058388664194',
      volume: '7390900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-19': {
      open: '89.45',
      high: '90.98',
      low: '89.31',
      close: '89.86',
      'adjusted close': '50.8549588918705',
      volume: '10671400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-18': {
      open: '90.6',
      high: '92.04',
      low: '89.73',
      close: '89.82',
      'adjusted close': '50.8323214741577',
      volume: '27875200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-17': {
      open: '86.35',
      high: '87.94',
      low: '86.22',
      close: '86.95',
      'adjusted close': '49.2080867532622',
      volume: '13295500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-16': {
      open: '86.08',
      high: '87.6',
      low: '85.75',
      close: '86.71',
      'adjusted close': '49.0722622469852',
      volume: '8633100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-13': {
      open: '84.45',
      high: '86.21',
      low: '84.44',
      close: '86.08',
      'adjusted close': '48.7157229180082',
      volume: '7788600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-12': {
      open: '84.2',
      high: '84.9',
      low: '84.1',
      close: '84.7',
      'adjusted close': '47.9347320069156',
      volume: '4661700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-11': {
      open: '84.15',
      high: '84.6869',
      low: '83.79',
      close: '84.19',
      'adjusted close': '47.646104931077',
      volume: '5210600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-10': {
      open: '83.71',
      high: '84.63',
      low: '83.56',
      close: '84.19',
      'adjusted close': '47.646104931077',
      volume: '4712900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-09': {
      open: '83.14',
      high: '84.08',
      low: '83.0',
      close: '84.0',
      'adjusted close': '47.5385771969411',
      volume: '4490200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-06': {
      open: '82.93',
      high: '83.78',
      low: '82.5',
      close: '83.14',
      'adjusted close': '47.0518727161152',
      volume: '5340900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-05': {
      open: '82.81',
      high: '83.21',
      low: '82.6',
      close: '82.92',
      'adjusted close': '46.9273669186947',
      volume: '4956600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-04': {
      open: '81.65',
      high: '83.25',
      low: '81.631',
      close: '83.1',
      'adjusted close': '47.0292352984024',
      volume: '6119300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-03': {
      open: '81.88',
      high: '82.26',
      low: '81.56',
      close: '81.65',
      'adjusted close': '46.2086289063124',
      volume: '4838500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-10-02': {
      open: '81.76',
      high: '82.47',
      low: '81.58',
      close: '81.87',
      'adjusted close': '46.3331347037329',
      volume: '4483300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-29': {
      open: '81.9',
      high: '82.79',
      low: '81.77',
      close: '81.94',
      'adjusted close': '46.3727501847304',
      volume: '4770700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-28': {
      open: '81.85',
      high: '82.35',
      low: '81.72',
      close: '81.99',
      'adjusted close': '46.4010469568714',
      volume: '4592300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-27': {
      open: '82.0',
      high: '82.67',
      low: '81.71',
      close: '82.09',
      'adjusted close': '46.4576405011535',
      volume: '5880500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-26': {
      open: '81.9',
      high: '82.66',
      low: '81.7',
      close: '82.5',
      'adjusted close': '46.68967403271',
      volume: '4731800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-25': {
      open: '81.64',
      high: '82.28',
      low: '81.28',
      close: '82.0',
      'adjusted close': '46.4067063112996',
      volume: '5683000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-22': {
      open: '81.75',
      high: '82.12',
      low: '81.01',
      close: '81.21',
      'adjusted close': '45.9596173114712',
      volume: '4350200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-21': {
      open: '82.61',
      high: '83.25',
      low: '81.35',
      close: '81.61',
      'adjusted close': '46.1859914885995',
      volume: '8909000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-20': {
      open: '82.37',
      high: '83.79',
      low: '82.2',
      close: '83.42',
      'adjusted close': '47.2103346401051',
      volume: '7524600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-19': {
      open: '81.95',
      high: '82.09',
      low: '81.42',
      close: '81.87',
      'adjusted close': '46.3331347037329',
      volume: '5088600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-18': {
      open: '82.56',
      high: '83.21',
      low: '82.17',
      close: '82.24',
      'adjusted close': '46.5425308175766',
      volume: '4417600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-15': {
      open: '83.21',
      high: '83.59',
      low: '82.79',
      close: '82.94',
      'adjusted close': '46.9386856275511',
      volume: '8203800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-14': {
      open: '81.9',
      high: '83.0',
      low: '81.74',
      close: '82.47',
      'adjusted close': '46.6726959694254',
      volume: '4265300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-13': {
      open: '82.25',
      high: '82.7',
      low: '81.84',
      close: '82.21',
      'adjusted close': '46.525552754292',
      volume: '4920200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-12': {
      open: '81.14',
      high: '82.5',
      low: '81.06',
      close: '82.28',
      'adjusted close': '46.5651682352894',
      volume: '5722200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-11': {
      open: '80.6',
      high: '81.2',
      low: '80.25',
      close: '80.93',
      'adjusted close': '45.8011553874814',
      volume: '4314300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-08': {
      open: '79.6',
      high: '80.86',
      low: '79.35',
      close: '80.66',
      'adjusted close': '45.6483528179198',
      volume: '4498600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-07': {
      open: '79.9',
      high: '80.38',
      low: '79.31',
      close: '79.4',
      'adjusted close': '44.9352741599657',
      volume: '3913300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-06': {
      open: '80.29',
      high: '80.69',
      low: '80.17',
      close: '80.28',
      'adjusted close': '45.433297349648',
      volume: '3727600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-05': {
      open: '81.19',
      high: '81.54',
      low: '80.6',
      close: '80.85',
      'adjusted close': '45.7558805520558',
      volume: '3968500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-09-01': {
      open: '81.13',
      high: '81.57',
      low: '80.81',
      close: '81.41',
      'adjusted close': '46.0728044000354',
      volume: '3294000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-31': {
      open: '81.3',
      high: '81.5',
      low: '80.78',
      close: '80.97',
      'adjusted close': '45.8237928051943',
      volume: '3786200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-30': {
      open: '81.18',
      high: '81.68',
      low: '80.84',
      close: '81.22',
      'adjusted close': '45.9652766658995',
      volume: '4688800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-29': {
      open: '80.06',
      high: '81.48',
      low: '80.06',
      close: '81.4',
      'adjusted close': '46.0671450456072',
      volume: '5208500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-28': {
      open: '79.91',
      high: '80.47',
      low: '79.76',
      close: '80.32',
      'adjusted close': '45.4559347673608',
      volume: '5259400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-25': {
      open: '79.2',
      high: '80.0',
      low: '79.14',
      close: '79.88',
      'adjusted close': '45.2069231725197',
      volume: '3427400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-24': {
      open: '78.9',
      high: '79.5',
      low: '78.87',
      close: '79.38',
      'adjusted close': '44.9239554511093',
      volume: '3473300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-23': {
      open: '78.95',
      high: '79.47',
      low: '78.42',
      close: '78.67',
      'adjusted close': '44.5221412867066',
      volume: '3425700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-22': {
      open: '79.28',
      high: '79.55',
      low: '78.61',
      close: '78.95',
      'adjusted close': '44.6806032106964',
      volume: '4057200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-21': {
      open: '79.51',
      high: '79.88',
      low: '78.87',
      close: '79.28',
      'adjusted close': '44.8673619068272',
      volume: '3676500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-18': {
      open: '79.38',
      high: '79.97',
      low: '78.63',
      close: '79.9',
      'adjusted close': '45.2182418813761',
      volume: '5399200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-17': {
      open: '78.93',
      high: '79.56',
      low: '78.53',
      close: '79.37',
      'adjusted close': '44.9182960966811',
      volume: '5535400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-16': {
      open: '77.45',
      high: '79.28',
      low: '77.35',
      close: '79.09',
      'adjusted close': '44.7598341726913',
      volume: '7386500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-15': {
      open: '76.9',
      high: '77.14',
      low: '76.31',
      close: '77.08',
      'adjusted close': '43.6223039326216',
      volume: '5985400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-14': {
      open: '75.85',
      high: '77.49',
      low: '75.6',
      close: '76.68',
      'adjusted close': '43.3959297554934',
      volume: '4997400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-11': {
      open: '75.2',
      high: '75.78',
      low: '75.2',
      close: '75.48',
      'adjusted close': '42.7168072241085',
      volume: '3695800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-10': {
      open: '75.2',
      high: '76.08',
      low: '75.05',
      close: '75.74',
      'adjusted close': '42.8639504392419',
      volume: '3213600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-09': {
      open: '75.75',
      high: '76.28',
      low: '75.39',
      close: '75.39',
      'adjusted close': '42.6658730342546',
      volume: '4480400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-08': {
      open: '75.63',
      high: '76.26',
      low: '75.1116',
      close: '75.33',
      'adjusted close': '42.6319169076854',
      volume: '3794700',
      'dividend amount': '0.3000',
      'split coefficient': '1.0',
    },
    '2006-08-07': {
      open: '75.63',
      high: '76.2',
      low: '75.28',
      close: '75.52',
      'adjusted close': '42.5699109462964',
      volume: '3870200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-04': {
      open: '76.65',
      high: '76.77',
      low: '75.32',
      close: '75.91',
      'adjusted close': '42.7897502639481',
      volume: '5062400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-03': {
      open: '76.0',
      high: '76.73',
      low: '74.9',
      close: '76.33',
      'adjusted close': '43.0265002983422',
      volume: '2765800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-02': {
      open: '76.21',
      high: '76.93',
      low: '76.2',
      close: '76.32',
      'adjusted close': '43.0208633927614',
      volume: '3283800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-08-01': {
      open: '76.86',
      high: '77.38',
      low: '75.75',
      close: '76.14',
      'adjusted close': '42.9193990923068',
      volume: '4406700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-31': {
      open: '76.44',
      high: '77.5',
      low: '76.15',
      close: '77.41',
      'adjusted close': '43.63528610107',
      volume: '5381800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-28': {
      open: '76.38',
      high: '77.3',
      low: '76.26',
      close: '76.96',
      'adjusted close': '43.3816253499334',
      volume: '4165700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-27': {
      open: '75.83',
      high: '77.29',
      low: '75.75',
      close: '76.15',
      'adjusted close': '42.9250359978876',
      volume: '6439100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-26': {
      open: '75.48',
      high: '76.3',
      low: '75.36',
      close: '75.83',
      'adjusted close': '42.7446550193016',
      volume: '3870100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-25': {
      open: '75.99',
      high: '76.41',
      low: '75.31',
      close: '75.89',
      'adjusted close': '42.7784764527865',
      volume: '5580300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-24': {
      open: '74.92',
      high: '76.15',
      low: '74.92',
      close: '75.99',
      'adjusted close': '42.8348455085946',
      volume: '5611200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-21': {
      open: '75.49',
      high: '75.5',
      low: '74.5',
      close: '74.86',
      'adjusted close': '42.1978751779628',
      volume: '8372500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-20': {
      open: '75.73',
      high: '75.88',
      low: '75.2',
      close: '75.48',
      'adjusted close': '42.5473633239732',
      volume: '12214900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-19': {
      open: '76.0',
      high: '77.06',
      low: '76.0',
      close: '76.07',
      'adjusted close': '42.8799407532411',
      volume: '14536900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-18': {
      open: '73.7',
      high: '75.42',
      low: '72.73',
      close: '74.26',
      'adjusted close': '41.8596608431141',
      volume: '8667500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-17': {
      open: '73.34',
      high: '73.94',
      low: '73.2',
      close: '73.7',
      'adjusted close': '41.5439941305886',
      volume: '6822700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-14': {
      open: '74.24',
      high: '74.3',
      low: '73.37',
      close: '73.57',
      'adjusted close': '41.470714358038',
      volume: '6957800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-13': {
      open: '75.01',
      high: '75.28',
      low: '73.83',
      close: '74.24',
      'adjusted close': '41.8483870319524',
      volume: '8754700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-12': {
      open: '76.07',
      high: '76.3',
      low: '74.82',
      close: '75.48',
      'adjusted close': '42.5473633239732',
      volume: '8372100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-11': {
      open: '76.4',
      high: '76.6499',
      low: '75.44',
      close: '76.47',
      'adjusted close': '43.1054169764736',
      volume: '6086500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-10': {
      open: '76.18',
      high: '76.83',
      low: '75.87',
      close: '76.67',
      'adjusted close': '43.2181550880899',
      volume: '7154700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-07': {
      open: '78.09',
      high: '78.09',
      low: '76.12',
      close: '76.42',
      'adjusted close': '43.0772324485696',
      volume: '6641600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-06': {
      open: '77.59',
      high: '78.53',
      low: '77.57',
      close: '78.09',
      'adjusted close': '44.0185956805653',
      volume: '4333100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-05': {
      open: '78.0',
      high: '78.39',
      low: '77.52',
      close: '77.77',
      'adjusted close': '43.8382147019793',
      volume: '4047800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-07-03': {
      open: '77.54',
      high: '78.27',
      low: '77.27',
      close: '78.02',
      'adjusted close': '43.9791373414996',
      volume: '3177300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-30': {
      open: '77.7',
      high: '77.93',
      low: '76.79',
      close: '76.82',
      'adjusted close': '43.3027086718021',
      volume: '7911700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-29': {
      open: '76.66',
      high: '77.8',
      low: '76.56',
      close: '77.59',
      'adjusted close': '43.7367504015246',
      volume: '5448900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-28': {
      open: '76.53',
      high: '76.65',
      low: '76.06',
      close: '76.56',
      'adjusted close': '43.1561491267009',
      volume: '4392100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-27': {
      open: '77.32',
      high: '77.6',
      low: '76.32',
      close: '76.63',
      'adjusted close': '43.1956074657666',
      volume: '5596800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-26': {
      open: '77.04',
      high: '77.52',
      low: '76.8',
      close: '77.15',
      'adjusted close': '43.4887265559689',
      volume: '3448000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-23': {
      open: '77.15',
      high: '77.84',
      low: '76.94',
      close: '77.1',
      'adjusted close': '43.4605420280648',
      volume: '4520600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-22': {
      open: '78.16',
      high: '78.37',
      low: '77.0',
      close: '77.19',
      'adjusted close': '43.5112741782921',
      volume: '5563700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-21': {
      open: '77.99',
      high: '78.56',
      low: '77.98',
      close: '78.3',
      'adjusted close': '44.1369706977623',
      volume: '5559100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-20': {
      open: '77.84',
      high: '78.3',
      low: '77.25',
      close: '77.99',
      'adjusted close': '43.9622266247571',
      volume: '6124500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-19': {
      open: '78.06',
      high: '78.3',
      low: '77.34',
      close: '77.67',
      'adjusted close': '43.7818456461711',
      volume: '5302100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-16': {
      open: '78.26',
      high: '78.53',
      low: '77.57',
      close: '77.95',
      'adjusted close': '43.9396790024339',
      volume: '8133500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-15': {
      open: '78.2',
      high: '78.78',
      low: '76.95',
      close: '78.56',
      'adjusted close': '44.2835302428635',
      volume: '6679400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-14': {
      open: '77.1',
      high: '77.85',
      low: '76.93',
      close: '77.71',
      'adjusted close': '43.8043932684944',
      volume: '6038600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-13': {
      open: '77.27',
      high: '77.93',
      low: '76.82',
      close: '76.93',
      'adjusted close': '43.364714633191',
      volume: '7374100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-12': {
      open: '77.68',
      high: '78.09',
      low: '76.85',
      close: '77.02',
      'adjusted close': '43.4154467834183',
      volume: '4924900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-09': {
      open: '77.33',
      high: '78.1',
      low: '77.05',
      close: '78.01',
      'adjusted close': '43.9735004359188',
      volume: '6135400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-08': {
      open: '78.74',
      high: '79.58',
      low: '76.75',
      close: '77.03',
      'adjusted close': '43.4210836889991',
      volume: '13474400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-07': {
      open: '79.78',
      high: '80.74',
      low: '79.12',
      close: '79.15',
      'adjusted close': '44.6161076721314',
      volume: '7924800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-06': {
      open: '79.06',
      high: '79.9',
      low: '78.5',
      close: '79.76',
      'adjusted close': '44.959958912561',
      volume: '7250600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-05': {
      open: '79.1',
      high: '80.04',
      low: '79.01',
      close: '79.06',
      'adjusted close': '44.5653755219041',
      volume: '5555800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-02': {
      open: '80.5',
      high: '80.56',
      low: '79.15',
      close: '79.52',
      'adjusted close': '44.8246731786215',
      volume: '7232300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-06-01': {
      open: '79.89',
      high: '80.87',
      low: '79.81',
      close: '80.69',
      'adjusted close': '45.4841911315765',
      volume: '4866500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-31': {
      open: '80.15',
      high: '80.83',
      low: '79.61',
      close: '79.9',
      'adjusted close': '45.0388755906923',
      volume: '6384900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-30': {
      open: '80.35',
      high: '80.96',
      low: '80.15',
      close: '80.16',
      'adjusted close': '45.1854351357935',
      volume: '5049100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-26': {
      open: '80.16',
      high: '80.86',
      low: '79.7',
      close: '80.75',
      'adjusted close': '45.5180125650614',
      volume: '3580800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-25': {
      open: '79.89',
      high: '80.18',
      low: '79.65',
      close: '80.14',
      'adjusted close': '45.1741613246318',
      volume: '3995000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-24': {
      open: '79.4',
      high: '80.04',
      low: '79.06',
      close: '79.78',
      'adjusted close': '44.9712327237226',
      volume: '7389600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-23': {
      open: '80.25',
      high: '81.3',
      low: '79.0',
      close: '79.83',
      'adjusted close': '44.9994172516266',
      volume: '5006400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-22': {
      open: '79.94',
      high: '80.34',
      low: '79.4',
      close: '80.02',
      'adjusted close': '45.1065184576621',
      volume: '6250900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-19': {
      open: '80.87',
      high: '81.0',
      low: '79.51',
      close: '80.28',
      'adjusted close': '45.2530780027632',
      volume: '7019100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-18': {
      open: '80.5',
      high: '81.35',
      low: '80.03',
      close: '80.66',
      'adjusted close': '45.4672804148341',
      volume: '5443600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-17': {
      open: '81.85',
      high: '82.2117',
      low: '81.02',
      close: '81.27',
      'adjusted close': '45.8111316552637',
      volume: '5936000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-16': {
      open: '83.0',
      high: '83.25',
      low: '82.07',
      close: '82.16',
      'adjusted close': '46.312816251956',
      volume: '4454800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-15': {
      open: '81.8',
      high: '83.15',
      low: '81.8',
      close: '82.89',
      'adjusted close': '46.7243103593553',
      volume: '5776400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-12': {
      open: '82.4',
      high: '83.69',
      low: '82.3',
      close: '82.39',
      'adjusted close': '46.4424650803147',
      volume: '5685700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-11': {
      open: '82.65',
      high: '83.09',
      low: '82.45',
      close: '82.46',
      'adjusted close': '46.4819234193803',
      volume: '5274500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-10': {
      open: '82.9',
      high: '83.33',
      low: '82.6',
      close: '82.9',
      'adjusted close': '46.7299472649361',
      volume: '3430400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-09': {
      open: '82.49',
      high: '83.29',
      low: '82.405',
      close: '83.23',
      'adjusted close': '46.9159651491029',
      volume: '4072800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-08': {
      open: '82.6',
      high: '83.22',
      low: '82.3',
      close: '82.89',
      'adjusted close': '46.7243103593553',
      volume: '3856500',
      'dividend amount': '0.3000',
      'split coefficient': '1.0',
    },
    '2006-05-05': {
      open: '82.95',
      high: '83.55',
      low: '82.7',
      close: '82.98',
      'adjusted close': '46.6063622264611',
      volume: '4504500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-04': {
      open: '82.71',
      high: '83.09',
      low: '82.4',
      close: '82.43',
      'adjusted close': '46.2974504498336',
      volume: '3966900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-03': {
      open: '82.54',
      high: '83.29',
      low: '82.45',
      close: '82.7',
      'adjusted close': '46.4490980492689',
      volume: '5721100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-02': {
      open: '82.11',
      high: '82.74',
      low: '82.0',
      close: '82.42',
      'adjusted close': '46.2918338720767',
      volume: '4336400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-05-01': {
      open: '82.59',
      high: '83.31',
      low: '82.1',
      close: '82.23',
      'adjusted close': '46.1851188946963',
      volume: '6011100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-28': {
      open: '83.51',
      high: '83.62',
      low: '81.98',
      close: '82.34',
      'adjusted close': '46.2469012500218',
      volume: '8201800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-27': {
      open: '83.01',
      high: '84.4',
      low: '82.7',
      close: '83.88',
      'adjusted close': '47.1118542245789',
      volume: '8174300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-26': {
      open: '82.84',
      high: '83.48',
      low: '82.71',
      close: '83.35',
      'adjusted close': '46.8141756034651',
      volume: '6648800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-25': {
      open: '82.16',
      high: '82.9',
      low: '81.6',
      close: '82.67',
      'adjusted close': '46.4322483159983',
      volume: '6856300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-24': {
      open: '81.3',
      high: '82.41',
      low: '81.1',
      close: '82.11',
      'adjusted close': '46.1177199616139',
      volume: '6604100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-21': {
      open: '82.49',
      high: '82.5',
      low: '81.38',
      close: '81.66',
      'adjusted close': '45.864973962555',
      volume: '6117400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-20': {
      open: '81.65',
      high: '82.85',
      low: '81.64',
      close: '82.02',
      'adjusted close': '46.0671707618021',
      volume: '6237200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-19': {
      open: '83.95',
      high: '84.0',
      low: '81.26',
      close: '81.86',
      'adjusted close': '45.9773055176923',
      volume: '10554700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-18': {
      open: '81.89',
      high: '83.53',
      low: '81.7',
      close: '83.31',
      'adjusted close': '46.7917092924377',
      volume: '7905800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-17': {
      open: '81.77',
      high: '82.92',
      low: '81.4',
      close: '81.64',
      'adjusted close': '45.8537408070413',
      volume: '4634200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-13': {
      open: '81.18',
      high: '82.6',
      low: '81.12',
      close: '81.98',
      'adjusted close': '46.0447044507747',
      volume: '4799700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-12': {
      open: '81.1',
      high: '81.37',
      low: '80.63',
      close: '80.75',
      'adjusted close': '45.3538653866804',
      volume: '3905200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-11': {
      open: '81.83',
      high: '82.06',
      low: '80.75',
      close: '81.16',
      'adjusted close': '45.5841450747118',
      volume: '5959700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-10': {
      open: '82.49',
      high: '82.74',
      low: '82.0',
      close: '82.1',
      'adjusted close': '46.1121033838571',
      volume: '3627700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-07': {
      open: '83.6',
      high: '83.8',
      low: '82.44',
      close: '82.48',
      'adjusted close': '46.3255333386179',
      volume: '5337500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-06': {
      open: '84.1',
      high: '84.32',
      low: '83.36',
      close: '83.81',
      'adjusted close': '47.0725381802809',
      volume: '4944100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-05': {
      open: '83.33',
      high: '84.45',
      low: '83.32',
      close: '84.17',
      'adjusted close': '47.274734979528',
      volume: '5523900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-04': {
      open: '83.1',
      high: '83.78',
      low: '82.8',
      close: '83.45',
      'adjusted close': '46.8703413810338',
      volume: '3853000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-04-03': {
      open: '82.72',
      high: '83.59',
      low: '82.65',
      close: '83.06',
      'adjusted close': '46.6512948485161',
      volume: '4121600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-31': {
      open: '82.95',
      high: '83.6',
      low: '82.29',
      close: '82.47',
      'adjusted close': '46.319916760861',
      volume: '5862000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-30': {
      open: '82.88',
      high: '83.7',
      low: '82.83',
      close: '83.2',
      'adjusted close': '46.7299269371122',
      volume: '4467000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-29': {
      open: '82.2',
      high: '83.55',
      low: '82.2',
      close: '83.13',
      'adjusted close': '46.6906108928141',
      volume: '4392100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-28': {
      open: '82.86',
      high: '83.39',
      low: '82.29',
      close: '82.43',
      'adjusted close': '46.2974504498336',
      volume: '4508600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-27': {
      open: '82.98',
      high: '83.26',
      low: '82.58',
      close: '83.08',
      'adjusted close': '46.6625280040298',
      volume: '3910100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-24': {
      open: '83.1',
      high: '83.72',
      low: '82.99',
      close: '83.36',
      'adjusted close': '46.819792181222',
      volume: '4198800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-23': {
      open: '84.23',
      high: '84.27',
      low: '82.93',
      close: '83.2',
      'adjusted close': '46.7299269371122',
      volume: '5105800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-22': {
      open: '83.66',
      high: '84.68',
      low: '83.35',
      close: '84.45',
      'adjusted close': '47.4319991567202',
      volume: '4968600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-21': {
      open: '83.82',
      high: '84.99',
      low: '83.58',
      close: '83.81',
      'adjusted close': '47.0725381802809',
      volume: '7404300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-20': {
      open: '83.11',
      high: '83.75',
      low: '82.99',
      close: '83.58',
      'adjusted close': '46.943356891873',
      volume: '3870300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-17': {
      open: '83.28',
      high: '83.41',
      low: '82.82',
      close: '83.3',
      'adjusted close': '46.7860927146808',
      volume: '6414400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-16': {
      open: '83.43',
      high: '83.72',
      low: '82.61',
      close: '82.87',
      'adjusted close': '46.5445798711356',
      volume: '4299500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-15': {
      open: '82.65',
      high: '83.76',
      low: '82.64',
      close: '83.38',
      'adjusted close': '46.8310253367357',
      volume: '5370400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-14': {
      open: '81.51',
      high: '82.99',
      low: '81.47',
      close: '82.88',
      'adjusted close': '46.5501964488925',
      volume: '4518000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-13': {
      open: '81.56',
      high: '82.4',
      low: '81.45',
      close: '81.93',
      'adjusted close': '46.0166215619904',
      volume: '4571400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-10': {
      open: '81.03',
      high: '82.25',
      low: '81.03',
      close: '81.57',
      'adjusted close': '45.8144247627432',
      volume: '4787200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-09': {
      open: '81.15',
      high: '81.98',
      low: '80.8',
      close: '81.02',
      'adjusted close': '45.5055129861157',
      volume: '4310700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-08': {
      open: '80.39',
      high: '81.6',
      low: '80.25',
      close: '81.14',
      'adjusted close': '45.5729119191981',
      volume: '5569900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-07': {
      open: '79.86',
      high: '80.79',
      low: '79.86',
      close: '80.29',
      'adjusted close': '45.0955028098646',
      volume: '4330800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-06': {
      open: '80.05',
      high: '80.64',
      low: '79.9',
      close: '80.0',
      'adjusted close': '44.9326220549155',
      volume: '4709500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-03': {
      open: '79.51',
      high: '80.83',
      low: '79.51',
      close: '79.96',
      'adjusted close': '44.9101557438881',
      volume: '4354400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-02': {
      open: '79.9',
      high: '80.21',
      low: '79.54',
      close: '79.94',
      'adjusted close': '44.8989225883743',
      volume: '4418800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-03-01': {
      open: '80.01',
      high: '80.81',
      low: '79.78',
      close: '79.9',
      'adjusted close': '44.8764562773469',
      volume: '5172300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-28': {
      open: '80.1',
      high: '80.55',
      low: '79.71',
      close: '80.24',
      'adjusted close': '45.0674199210803',
      volume: '5960700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-27': {
      open: '80.0',
      high: '80.89',
      low: '79.95',
      close: '80.63',
      'adjusted close': '45.286466453598',
      volume: '3787000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-24': {
      open: '80.65',
      high: '80.65',
      low: '79.85',
      close: '80.1',
      'adjusted close': '44.9887878324842',
      volume: '3903200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-23': {
      open: '81.05',
      high: '81.34',
      low: '80.04',
      close: '80.2',
      'adjusted close': '45.0449536100528',
      volume: '4979400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-22': {
      open: '80.0',
      high: '81.65',
      low: '79.97',
      close: '81.35',
      'adjusted close': '45.6908600520922',
      volume: '5294300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-21': {
      open: '80.1',
      high: '80.75',
      low: '79.97',
      close: '80.5',
      'adjusted close': '45.2134509427588',
      volume: '5838000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-17': {
      open: '80.91',
      high: '81.03',
      low: '80.13',
      close: '80.71',
      'adjusted close': '45.3313990756529',
      volume: '5298800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-16': {
      open: '80.68',
      high: '80.91',
      low: '80.13',
      close: '80.91',
      'adjusted close': '45.4437306307902',
      volume: '5310500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-15': {
      open: '80.86',
      high: '81.5',
      low: '80.5',
      close: '80.85',
      'adjusted close': '45.410031164249',
      volume: '5052000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-14': {
      open: '80.55',
      high: '81.45',
      low: '80.35',
      close: '81.09',
      'adjusted close': '45.5448290304138',
      volume: '6075500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-13': {
      open: '81.33',
      high: '81.91',
      low: '80.18',
      close: '80.44',
      'adjusted close': '45.1797514762176',
      volume: '5257400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-10': {
      open: '80.4',
      high: '81.46',
      low: '80.2',
      close: '81.33',
      'adjusted close': '45.6796268965785',
      volume: '5371900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-09': {
      open: '80.2',
      high: '80.8',
      low: '79.5',
      close: '80.4',
      'adjusted close': '45.1572851651901',
      volume: '7609200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-08': {
      open: '80.6',
      high: '80.9',
      low: '80.24',
      close: '80.8',
      'adjusted close': '45.3819482754647',
      volume: '5029200',
      'dividend amount': '0.2000',
      'split coefficient': '1.0',
    },
    '2006-02-07': {
      open: '79.5',
      high: '80.19',
      low: '79.3',
      close: '79.65',
      'adjusted close': '44.6255824708736',
      volume: '5247300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-06': {
      open: '79.94',
      high: '79.95',
      low: '78.93',
      close: '79.51',
      'adjusted close': '44.5471445355827',
      volume: '9568500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-03': {
      open: '80.55',
      high: '80.77',
      low: '79.72',
      close: '79.97',
      'adjusted close': '44.80486918011',
      volume: '9852000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-02': {
      open: '81.45',
      high: '81.59',
      low: '80.9',
      close: '81.23',
      'adjusted close': '45.5108105977284',
      volume: '6198000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-02-01': {
      open: '80.9',
      high: '82.24',
      low: '80.65',
      close: '81.94',
      'adjusted close': '45.9086029838466',
      volume: '5419300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-31': {
      open: '81.5',
      high: '82.0',
      low: '81.17',
      close: '81.3',
      'adjusted close': '45.5500295653738',
      volume: '6771600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-30': {
      open: '81.02',
      high: '81.81',
      low: '80.21',
      close: '81.63',
      'adjusted close': '45.7349189842739',
      volume: '5325100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-27': {
      open: '80.75',
      high: '81.77',
      low: '80.75',
      close: '81.02',
      'adjusted close': '45.393153694792',
      volume: '6103400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-26': {
      open: '81.5',
      high: '81.65',
      low: '80.59',
      close: '80.72',
      'adjusted close': '45.2250724048828',
      volume: '7810200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-25': {
      open: '81.05',
      high: '81.62',
      low: '80.61',
      close: '80.91',
      'adjusted close': '45.331523888492',
      volume: '6374300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-24': {
      open: '81.39',
      high: '82.15',
      low: '80.8',
      close: '80.85',
      'adjusted close': '45.2979076305101',
      volume: '6069000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-23': {
      open: '81.33',
      high: '81.92',
      low: '80.92',
      close: '81.41',
      'adjusted close': '45.6116593716738',
      volume: '6114100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-20': {
      open: '83.04',
      high: '83.05',
      low: '81.25',
      close: '81.36',
      'adjusted close': '45.5836458233556',
      volume: '8605800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-19': {
      open: '84.14',
      high: '84.39',
      low: '83.02',
      close: '83.09',
      'adjusted close': '46.552914595165',
      volume: '6483500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-18': {
      open: '84.0',
      high: '84.7',
      low: '83.52',
      close: '84.46',
      'adjusted close': '47.3204858190833',
      volume: '10984800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-17': {
      open: '82.8',
      high: '83.16',
      low: '82.54',
      close: '83.0',
      'adjusted close': '46.5024902081922',
      volume: '8718400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-13': {
      open: '83.0',
      high: '83.45',
      low: '82.5',
      close: '83.17',
      'adjusted close': '46.5977362724741',
      volume: '6921600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-12': {
      open: '83.82',
      high: '83.96',
      low: '83.4',
      close: '83.57',
      'adjusted close': '46.8218446590196',
      volume: '4924100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-11': {
      open: '84.37',
      high: '84.81',
      low: '83.4',
      close: '84.17',
      'adjusted close': '47.1580072388378',
      volume: '5776400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-10': {
      open: '83.15',
      high: '84.12',
      low: '83.12',
      close: '84.07',
      'adjusted close': '47.1019801422014',
      volume: '5700000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-09': {
      open: '83.9',
      high: '84.25',
      low: '83.38',
      close: '83.73',
      'adjusted close': '46.9114880136378',
      volume: '6851100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-06': {
      open: '83.95',
      high: '85.03',
      low: '83.41',
      close: '84.95',
      'adjusted close': '47.5950185926015',
      volume: '8196900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-05': {
      open: '81.4',
      high: '82.9',
      low: '80.999',
      close: '82.5',
      'adjusted close': '46.2223547250103',
      volume: '7213400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-04': {
      open: '82.2',
      high: '82.5',
      low: '81.33',
      close: '81.95',
      'adjusted close': '45.9142056935103',
      volume: '9832800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2006-01-03': {
      open: '82.45',
      high: '82.55',
      low: '80.81',
      close: '82.06',
      'adjusted close': '45.9758354998103',
      volume: '11715100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-30': {
      open: '81.85',
      high: '82.49',
      low: '81.56',
      close: '82.2',
      'adjusted close': '46.0542734351012',
      volume: '5449000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-29': {
      open: '82.8',
      high: '83.06',
      low: '82.22',
      close: '82.4',
      'adjusted close': '46.166327628374',
      volume: '3958400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-28': {
      open: '83.1',
      high: '83.57',
      low: '82.8',
      close: '83.04',
      'adjusted close': '46.5249010468468',
      volume: '3836800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-27': {
      open: '83.48',
      high: '84.5',
      low: '82.89',
      close: '82.99',
      'adjusted close': '46.4968874985286',
      volume: '4377800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-23': {
      open: '84.0',
      high: '84.2',
      low: '83.39',
      close: '83.48',
      'adjusted close': '46.7714202720468',
      volume: '3616200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-22': {
      open: '82.61',
      high: '83.23',
      low: '82.3',
      close: '83.22',
      'adjusted close': '46.6257498207922',
      volume: '6573600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-21': {
      open: '82.46',
      high: '84.0',
      low: '82.46',
      close: '83.12',
      'adjusted close': '46.5697227241559',
      volume: '6846800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-20': {
      open: '82.95',
      high: '83.1',
      low: '82.06',
      close: '82.48',
      'adjusted close': '46.2111493056831',
      volume: '5276200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-19': {
      open: '83.23',
      high: '83.6',
      low: '82.65',
      close: '82.76',
      'adjusted close': '46.3680251762649',
      volume: '4985400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-16': {
      open: '83.89',
      high: '84.0',
      low: '83.0',
      close: '83.37',
      'adjusted close': '46.7097904657468',
      volume: '7398200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-15': {
      open: '82.88',
      high: '83.63',
      low: '82.75',
      close: '83.53',
      'adjusted close': '46.799433820365',
      volume: '6155900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-14': {
      open: '83.65',
      high: '83.95',
      low: '82.95',
      close: '83.13',
      'adjusted close': '46.5753254338195',
      volume: '7161800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-13': {
      open: '84.5',
      high: '84.9',
      low: '83.5',
      close: '83.71',
      'adjusted close': '46.9002825943105',
      volume: '12545300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-12': {
      open: '87.01',
      high: '87.35',
      low: '85.76',
      close: '85.96',
      'adjusted close': '48.1608922686289',
      volume: '6044100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-09': {
      open: '86.8',
      high: '87.1',
      low: '86.33',
      close: '86.97',
      'adjusted close': '48.7267659446563',
      volume: '6461800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-08': {
      open: '88.57',
      high: '88.91',
      low: '86.67',
      close: '87.5',
      'adjusted close': '49.0237095568291',
      volume: '5787000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-07': {
      open: '89.0',
      high: '89.92',
      low: '88.45',
      close: '88.72',
      'adjusted close': '49.7072401357929',
      volume: '5284000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-06': {
      open: '88.56',
      high: '89.84',
      low: '88.56',
      close: '89.14',
      'adjusted close': '49.9425539416657',
      volume: '4871500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-05': {
      open: '88.4',
      high: '88.65',
      low: '87.71',
      close: '88.43',
      'adjusted close': '49.5447615555474',
      volume: '4023600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-02': {
      open: '89.0',
      high: '89.15',
      low: '88.36',
      close: '88.65',
      'adjusted close': '49.6680211681475',
      volume: '4730500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-12-01': {
      open: '89.15',
      high: '89.74',
      low: '89.05',
      close: '89.21',
      'adjusted close': '49.9817729093112',
      volume: '4668500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-30': {
      open: '89.05',
      high: '89.64',
      low: '88.73',
      close: '88.9',
      'adjusted close': '49.8080889097384',
      volume: '6021500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-29': {
      open: '89.18',
      high: '89.94',
      low: '89.02',
      close: '89.1',
      'adjusted close': '49.9201431030112',
      volume: '5147000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-28': {
      open: '88.8',
      high: '89.43',
      low: '88.75',
      close: '89.11',
      'adjusted close': '49.9257458126748',
      volume: '5197900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-25': {
      open: '88.95',
      high: '89.32',
      low: '88.62',
      close: '88.8',
      'adjusted close': '49.752061813102',
      volume: '2199300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-23': {
      open: '88.12',
      high: '89.39',
      low: '87.95',
      close: '88.8',
      'adjusted close': '49.752061813102',
      volume: '5399300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-22': {
      open: '86.88',
      high: '88.08',
      low: '86.88',
      close: '87.99',
      'adjusted close': '49.2982423303474',
      volume: '5786600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-21': {
      open: '87.6',
      high: '87.87',
      low: '87.01',
      close: '87.29',
      'adjusted close': '48.9060526538928',
      volume: '4807900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-18': {
      open: '88.0',
      high: '88.0',
      low: '87.17',
      close: '87.77',
      'adjusted close': '49.1749827177474',
      volume: '7985800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-17': {
      open: '86.18',
      high: '86.98',
      low: '85.76',
      close: '86.89',
      'adjusted close': '48.6819442673472',
      volume: '5293600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-16': {
      open: '85.45',
      high: '86.58',
      low: '85.39',
      close: '86.54',
      'adjusted close': '48.4858494291199',
      volume: '6694100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-15': {
      open: '84.03',
      high: '85.9',
      low: '84.03',
      close: '85.53',
      'adjusted close': '47.9199757530925',
      volume: '6830800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-14': {
      open: '84.25',
      high: '85.0',
      low: '84.11',
      close: '84.36',
      'adjusted close': '47.2644587224469',
      volume: '3915300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-11': {
      open: '84.2',
      high: '84.84',
      low: '84.15',
      close: '84.55',
      'adjusted close': '47.370910206056',
      volume: '4841300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-10': {
      open: '82.7',
      high: '84.3',
      low: '82.3',
      close: '83.99',
      'adjusted close': '47.0571584648923',
      volume: '4732000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-09': {
      open: '82.71',
      high: '83.33',
      low: '82.49',
      close: '82.84',
      'adjusted close': '46.412846853574',
      volume: '3936200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-08': {
      open: '83.41',
      high: '83.9',
      low: '83.11',
      close: '83.15',
      'adjusted close': '46.5865308531468',
      volume: '3798000',
      'dividend amount': '0.2000',
      'split coefficient': '1.0',
    },
    '2005-11-07': {
      open: '83.1',
      high: '83.85',
      low: '83.07',
      close: '83.61',
      'adjusted close': '46.7318517652262',
      volume: '4455100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-04': {
      open: '82.91',
      high: '83.3',
      low: '82.6454',
      close: '83.0',
      'adjusted close': '46.3909065484245',
      volume: '3831400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-03': {
      open: '81.55',
      high: '83.03',
      low: '81.31',
      close: '82.87',
      'adjusted close': '46.3182460923848',
      volume: '7690900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-02': {
      open: '81.2',
      high: '81.89',
      low: '80.64',
      close: '81.06',
      'adjusted close': '45.3065889736782',
      volume: '6447400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-11-01': {
      open: '81.85',
      high: '82.15',
      low: '81.29',
      close: '81.59',
      'adjusted close': '45.6028200636862',
      volume: '5653100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-31': {
      open: '81.51',
      high: '82.3',
      low: '81.3',
      close: '81.88',
      'adjusted close': '45.7649087733132',
      volume: '7531200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-28': {
      open: '82.32',
      high: '82.43',
      low: '80.5',
      close: '81.42',
      'adjusted close': '45.5078025442497',
      volume: '6941300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-27': {
      open: '82.53',
      high: '82.93',
      low: '82.25',
      close: '82.31',
      'adjusted close': '46.0052472048292',
      volume: '3705800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-26': {
      open: '82.97',
      high: '83.87',
      low: '82.72',
      close: '82.89',
      'adjusted close': '46.3294246240832',
      volume: '4270800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-25': {
      open: '83.08',
      high: '83.95',
      low: '82.71',
      close: '83.36',
      'adjusted close': '46.592120118996',
      volume: '5393300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-24': {
      open: '83.15',
      high: '83.61',
      low: '82.6',
      close: '83.47',
      'adjusted close': '46.6536020433373',
      volume: '5482200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-21': {
      open: '83.08',
      high: '84.0',
      low: '82.91',
      close: '83.33',
      'adjusted close': '46.5753523214484',
      volume: '6020700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-20': {
      open: '84.0',
      high: '84.19',
      low: '82.88',
      close: '83.17',
      'adjusted close': '46.485924067861',
      volume: '5752700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-19': {
      open: '82.41',
      high: '84.2',
      low: '82.38',
      close: '84.17',
      'adjusted close': '47.0448506527818',
      volume: '7502600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-18': {
      open: '84.33',
      high: '84.6',
      low: '83.35',
      close: '83.48',
      'adjusted close': '46.6591913091865',
      volume: '12045300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-17': {
      open: '82.36',
      high: '82.99',
      low: '81.93',
      close: '82.59',
      'adjusted close': '46.161746648607',
      volume: '7964800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-14': {
      open: '82.6',
      high: '82.71',
      low: '81.6',
      close: '82.35',
      'adjusted close': '46.027604268226',
      volume: '5573700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-13': {
      open: '81.7',
      high: '83.2',
      low: '81.7',
      close: '82.2',
      'adjusted close': '45.9437652804879',
      volume: '5530600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-12': {
      open: '82.75',
      high: '82.93',
      low: '81.95',
      close: '82.19',
      'adjusted close': '45.9381760146387',
      volume: '7580800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-11': {
      open: '82.25',
      high: '83.52',
      low: '81.97',
      close: '83.19',
      'adjusted close': '46.4971025995595',
      volume: '9781300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-10': {
      open: '81.24',
      high: '82.1',
      low: '81.08',
      close: '81.25',
      'adjusted close': '45.4127850248131',
      volume: '7882900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-07': {
      open: '80.25',
      high: '81.12',
      low: '80.09',
      close: '80.5',
      'adjusted close': '44.9935900861226',
      volume: '6130100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-06': {
      open: '79.82',
      high: '80.27',
      low: '78.7',
      close: '79.7',
      'adjusted close': '44.5464488181859',
      volume: '8130200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-05': {
      open: '80.11',
      high: '80.68',
      low: '79.56',
      close: '79.82',
      'adjusted close': '44.6135200083764',
      volume: '4998800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-04': {
      open: '80.65',
      high: '81.47',
      low: '79.93',
      close: '80.11',
      'adjusted close': '44.7756087180035',
      volume: '6365100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-10-03': {
      open: '80.22',
      high: '80.6',
      low: '79.5',
      close: '80.45',
      'adjusted close': '44.9656437568765',
      volume: '5297600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-30': {
      open: '80.02',
      high: '80.5',
      low: '79.97',
      close: '80.22',
      'adjusted close': '44.8370906423447',
      volume: '5536500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-29': {
      open: '79.25',
      high: '80.55',
      low: '78.87',
      close: '80.36',
      'adjusted close': '44.9153403642337',
      volume: '5899700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-28': {
      open: '78.06',
      high: '79.73',
      low: '78.06',
      close: '79.5',
      'adjusted close': '44.4346635012018',
      volume: '7747700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-27': {
      open: '77.17',
      high: '78.6',
      low: '77.16',
      close: '77.99',
      'adjusted close': '43.5906843579714',
      volume: '5499600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-26': {
      open: '78.31',
      high: '78.41',
      low: '76.93',
      close: '77.41',
      'adjusted close': '43.2665069387174',
      volume: '5433200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-23': {
      open: '77.7',
      high: '78.11',
      low: '77.5',
      close: '78.0',
      'adjusted close': '43.5962736238206',
      volume: '5434600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-22': {
      open: '77.56',
      high: '78.5',
      low: '77.3',
      close: '78.21',
      'adjusted close': '43.713648206654',
      volume: '6870400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-21': {
      open: '78.0',
      high: '78.46',
      low: '77.37',
      close: '77.56',
      'adjusted close': '43.3503459264555',
      volume: '7126000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-20': {
      open: '79.27',
      high: '79.65',
      low: '78.63',
      close: '78.7',
      'adjusted close': '43.9875222332652',
      volume: '7122300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-19': {
      open: '79.74',
      high: '79.98',
      low: '79.01',
      close: '79.43',
      'adjusted close': '44.3955386402573',
      volume: '5148500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-16': {
      open: '80.38',
      high: '80.5',
      low: '79.83',
      close: '80.33',
      'adjusted close': '44.898572566686',
      volume: '7624900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-15': {
      open: '80.43',
      high: '80.65',
      low: '79.67',
      close: '80.01',
      'adjusted close': '44.7197160595114',
      volume: '4864100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-14': {
      open: '80.75',
      high: '81.4',
      low: '80.42',
      close: '80.48',
      'adjusted close': '44.9824115544242',
      volume: '3887700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-13': {
      open: '81.49',
      high: '81.49',
      low: '80.52',
      close: '80.75',
      'adjusted close': '45.1333217323528',
      volume: '5040900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-12': {
      open: '81.03',
      high: '82.11',
      low: '81.0',
      close: '81.48',
      'adjusted close': '45.5413381393449',
      volume: '3692800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-09': {
      open: '80.86',
      high: '81.49',
      low: '80.52',
      close: '81.44',
      'adjusted close': '45.5189810759481',
      volume: '3982200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-08': {
      open: '81.28',
      high: '81.52',
      low: '80.5',
      close: '80.8',
      'adjusted close': '45.1612680615988',
      volume: '4277300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-07': {
      open: '80.7',
      high: '81.13',
      low: '80.32',
      close: '80.98',
      'adjusted close': '45.2618748468845',
      volume: '4116300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-06': {
      open: '79.7',
      high: '81.19',
      low: '79.7',
      close: '81.02',
      'adjusted close': '45.2842319102814',
      volume: '6305500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-02': {
      open: '79.5',
      high: '80.01',
      low: '79.46',
      close: '79.46',
      'adjusted close': '44.412306437805',
      volume: '3680600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-09-01': {
      open: '80.16',
      high: '80.32',
      low: '79.34',
      close: '79.54',
      'adjusted close': '44.4570205645986',
      volume: '6390600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-31': {
      open: '80.35',
      high: '80.79',
      low: '79.87',
      close: '80.62',
      'adjusted close': '45.0606612763131',
      volume: '5400800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-30': {
      open: '81.0',
      high: '81.0',
      low: '79.98',
      close: '80.54',
      'adjusted close': '45.0159471495194',
      volume: '5674200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-29': {
      open: '80.0',
      high: '81.75',
      low: '79.9',
      close: '81.34',
      'adjusted close': '45.463088417456',
      volume: '4025300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-26': {
      open: '80.81',
      high: '81.02',
      low: '80.26',
      close: '80.38',
      'adjusted close': '44.9265188959321',
      volume: '5029300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-25': {
      open: '81.45',
      high: '81.45',
      low: '80.72',
      close: '81.1',
      'adjusted close': '45.328946037075',
      volume: '3606100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-24': {
      open: '81.53',
      high: '82.4',
      low: '81.23',
      close: '81.32',
      'adjusted close': '45.4519098857576',
      volume: '4417600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-23': {
      open: '82.7',
      high: '83.14',
      low: '81.6',
      close: '82.03',
      'adjusted close': '45.8487477610514',
      volume: '4000300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-22': {
      open: '82.75',
      high: '83.35',
      low: '81.85',
      close: '82.6',
      'adjusted close': '46.1673359144562',
      volume: '5157200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-19': {
      open: '81.36',
      high: '83.3',
      low: '81.16',
      close: '82.76',
      'adjusted close': '46.2567641680435',
      volume: '6481000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-18': {
      open: '81.1',
      high: '82.0',
      low: '80.8',
      close: '81.15',
      'adjusted close': '45.3568923663211',
      volume: '3713300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-17': {
      open: '81.4',
      high: '81.94',
      low: '80.84',
      close: '81.3',
      'adjusted close': '45.4407313540592',
      volume: '4853000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-16': {
      open: '82.07',
      high: '82.43',
      low: '81.09',
      close: '81.3',
      'adjusted close': '45.4407313540592',
      volume: '4407400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-15': {
      open: '81.9',
      high: '82.94',
      low: '81.61',
      close: '82.5',
      'adjusted close': '46.1114432559641',
      volume: '3431100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-12': {
      open: '82.15',
      high: '82.58',
      low: '82.0572',
      close: '82.19',
      'adjusted close': '45.9381760146387',
      volume: '4378200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-11': {
      open: '81.93',
      high: '82.75',
      low: '81.62',
      close: '82.66',
      'adjusted close': '46.2008715095514',
      volume: '4570400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-10': {
      open: '83.65',
      high: '84.01',
      low: '81.97',
      close: '82.02',
      'adjusted close': '45.8431584952021',
      volume: '5402400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-09': {
      open: '83.4',
      high: '83.95',
      low: '82.94',
      close: '83.5',
      'adjusted close': '46.6703698408849',
      volume: '3793500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-08': {
      open: '83.41',
      high: '83.89',
      low: '83.02',
      close: '83.36',
      'adjusted close': '46.592120118996',
      volume: '4424300',
      'dividend amount': '0.2000',
      'split coefficient': '1.0',
    },
    '2005-08-05': {
      open: '82.75',
      high: '83.74',
      low: '82.73',
      close: '83.36',
      'adjusted close': '46.4806023590175',
      volume: '3714600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-04': {
      open: '83.75',
      high: '83.89',
      low: '82.98',
      close: '83.12',
      'adjusted close': '46.3467810470434',
      volume: '5249900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-03': {
      open: '83.31',
      high: '84.2',
      low: '83.11',
      close: '84.06',
      'adjusted close': '46.8709145189421',
      volume: '4256200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-02': {
      open: '83.27',
      high: '83.86',
      low: '83.04',
      close: '83.31',
      'adjusted close': '46.4527229190229',
      volume: '5380700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-08-01': {
      open: '83.0',
      high: '83.85',
      low: '82.85',
      close: '83.43',
      'adjusted close': '46.51963357501',
      volume: '3664300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-29': {
      open: '83.3',
      high: '83.95',
      low: '83.22',
      close: '83.46',
      'adjusted close': '46.5363612390068',
      volume: '4330300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-28': {
      open: '83.55',
      high: '83.97',
      low: '83.27',
      close: '83.8',
      'adjusted close': '46.7259414309701',
      volume: '6189700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-27': {
      open: '83.8',
      high: '84.13',
      low: '82.81',
      close: '83.87',
      'adjusted close': '46.7649726469626',
      volume: '4862200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-26': {
      open: '84.15',
      high: '84.35',
      low: '83.85',
      close: '84.12',
      'adjusted close': '46.9043698469356',
      volume: '4542900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-25': {
      open: '83.98',
      high: '84.52',
      low: '83.92',
      close: '84.2',
      'adjusted close': '46.948976950927',
      volume: '4457800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-22': {
      open: '83.98',
      high: '84.63',
      low: '83.86',
      close: '84.44',
      'adjusted close': '47.0827982629012',
      volume: '4570400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-21': {
      open: '84.6',
      high: '84.95',
      low: '83.4',
      close: '84.4',
      'adjusted close': '47.0604947109055',
      volume: '8265300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-20': {
      open: '83.2',
      high: '84.96',
      low: '82.99',
      close: '84.6',
      'adjusted close': '47.1720124708839',
      volume: '9323200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-19': {
      open: '84.72',
      high: '85.11',
      low: '83.7',
      close: '83.7',
      'adjusted close': '46.6701825509809',
      volume: '14149700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-18': {
      open: '81.99',
      high: '83.94',
      low: '81.68',
      close: '81.81',
      'adjusted close': '45.6163397191846',
      volume: '8705600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-15': {
      open: '82.43',
      high: '82.75',
      low: '81.52',
      close: '82.38',
      'adjusted close': '45.9341653351231',
      volume: '7644100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-14': {
      open: '82.0',
      high: '82.67',
      low: '82.0',
      close: '82.42',
      'adjusted close': '45.9564688871188',
      volume: '8546200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-13': {
      open: '81.16',
      high: '81.75',
      low: '81.16',
      close: '81.45',
      'adjusted close': '45.4156077512234',
      volume: '11340000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-12': {
      open: '79.2',
      high: '80.49',
      low: '79.18',
      close: '80.04',
      'adjusted close': '44.6294075433753',
      volume: '10945500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-11': {
      open: '79.4',
      high: '79.52',
      low: '78.52',
      close: '78.96',
      'adjusted close': '44.0272116394917',
      volume: '7186700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-08': {
      open: '77.38',
      high: '79.52',
      low: '77.14',
      close: '79.3',
      'adjusted close': '44.216791831455',
      volume: '13440500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-07': {
      open: '75.15',
      high: '77.53',
      low: '75.0',
      close: '77.38',
      'adjusted close': '43.1462213356619',
      volume: '10757200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-06': {
      open: '74.8',
      high: '76.15',
      low: '74.4',
      close: '75.81',
      'adjusted close': '42.2708069198311',
      volume: '8009300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-05': {
      open: '74.38',
      high: '74.97',
      low: '74.16',
      close: '74.79',
      'adjusted close': '41.702066343941',
      volume: '5181800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-07-01': {
      open: '74.3',
      high: '75.33',
      low: '74.3',
      close: '74.67',
      'adjusted close': '41.6351556879539',
      volume: '4353100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-30': {
      open: '74.8',
      high: '75.49',
      low: '74.07',
      close: '74.2',
      'adjusted close': '41.3730889520046',
      volume: '6127000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-29': {
      open: '75.26',
      high: '75.68',
      low: '74.62',
      close: '74.73',
      'adjusted close': '41.6686110159475',
      volume: '5519100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-28': {
      open: '73.83',
      high: '75.301',
      low: '73.45',
      close: '75.3',
      'adjusted close': '41.986436631886',
      volume: '8188800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-27': {
      open: '74.01',
      high: '74.77',
      low: '73.5',
      close: '73.88',
      'adjusted close': '41.1946605360391',
      volume: '6489800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-24': {
      open: '75.17',
      high: '75.4',
      low: '74.0',
      close: '74.01',
      'adjusted close': '41.2671470800251',
      volume: '10792100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-23': {
      open: '76.81',
      high: '76.97',
      low: '75.06',
      close: '75.41',
      'adjusted close': '42.0477713998742',
      volume: '7766700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-22': {
      open: '76.83',
      high: '77.49',
      low: '76.67',
      close: '77.23',
      'adjusted close': '43.0625830156781',
      volume: '5646300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-21': {
      open: '76.7',
      high: '77.0',
      low: '76.11',
      close: '76.41',
      'adjusted close': '42.6053601997664',
      volume: '5339100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-20': {
      open: '76.03',
      high: '76.98',
      low: '75.57',
      close: '76.55',
      'adjusted close': '42.6834226317513',
      volume: '3745600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-17': {
      open: '77.7',
      high: '77.73',
      low: '76.38',
      close: '76.39',
      'adjusted close': '42.5942084237686',
      volume: '8593800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-16': {
      open: '76.4',
      high: '77.25',
      low: '76.31',
      close: '77.05',
      'adjusted close': '42.9622170316975',
      volume: '7840000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-15': {
      open: '75.7',
      high: '76.5',
      low: '75.15',
      close: '76.3',
      'adjusted close': '42.5440254317783',
      volume: '7103600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-14': {
      open: '75.05',
      high: '75.43',
      low: '74.73',
      close: '74.89',
      'adjusted close': '41.7578252239302',
      volume: '4314900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-13': {
      open: '74.5',
      high: '75.93',
      low: '74.45',
      close: '75.05',
      'adjusted close': '41.847039431913',
      volume: '5715700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-10': {
      open: '74.25',
      high: '75.05',
      low: '74.1',
      close: '74.77',
      'adjusted close': '41.6909145679432',
      volume: '4895800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-09': {
      open: '74.58',
      high: '75.47',
      low: '74.23',
      close: '74.93',
      'adjusted close': '41.7801287759259',
      volume: '4423200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-08': {
      open: '75.15',
      high: '75.4',
      low: '74.63',
      close: '74.8',
      'adjusted close': '41.7076422319399',
      volume: '4280000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-07': {
      open: '75.0',
      high: '76.09',
      low: '75.0',
      close: '75.04',
      'adjusted close': '41.8414635439141',
      volume: '5226600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-06': {
      open: '75.8',
      high: '75.9',
      low: '74.92',
      close: '75.0',
      'adjusted close': '41.8191599919184',
      volume: '5978600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-03': {
      open: '77.06',
      high: '77.1',
      low: '75.74',
      close: '75.79',
      'adjusted close': '42.2596551438332',
      volume: '6149900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-02': {
      open: '76.75',
      high: '77.39',
      low: '76.68',
      close: '77.35',
      'adjusted close': '43.1294936716651',
      volume: '4025600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-06-01': {
      open: '75.57',
      high: '77.5',
      low: '75.57',
      close: '76.84',
      'adjusted close': '42.8451233837201',
      volume: '7380600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-31': {
      open: '76.6',
      high: '77.41',
      low: '75.5',
      close: '75.55',
      'adjusted close': '42.1258338318591',
      volume: '6419000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-27': {
      open: '77.11',
      high: '77.24',
      low: '76.53',
      close: '77.1',
      'adjusted close': '42.9900964716921',
      volume: '3163900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-26': {
      open: '76.45',
      high: '77.41',
      low: '76.29',
      close: '77.14',
      'adjusted close': '43.0124000236878',
      volume: '5832000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-25': {
      open: '75.42',
      high: '76.03',
      low: '75.17',
      close: '76.0',
      'adjusted close': '42.3767487918106',
      volume: '5483400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-24': {
      open: '76.14',
      high: '76.51',
      low: '75.56',
      close: '75.81',
      'adjusted close': '42.2708069198311',
      volume: '5660700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-23': {
      open: '76.3',
      high: '76.95',
      low: '76.0',
      close: '76.51',
      'adjusted close': '42.6611190797557',
      volume: '4759800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-20': {
      open: '77.28',
      high: '77.28',
      low: '76.36',
      close: '76.41',
      'adjusted close': '42.6053601997664',
      volume: '6329800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-19': {
      open: '76.5',
      high: '77.64',
      low: '76.37',
      close: '77.16',
      'adjusted close': '43.0235517996856',
      volume: '7030400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-18': {
      open: '75.0',
      high: '76.816',
      low: '74.69',
      close: '76.36',
      'adjusted close': '42.5774807597718',
      volume: '10309600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-17': {
      open: '73.93',
      high: '74.43',
      low: '73.33',
      close: '74.29',
      'adjusted close': '41.4232719439949',
      volume: '5135600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-16': {
      open: '73.09',
      high: '74.49',
      low: '73.09',
      close: '74.34',
      'adjusted close': '41.4511513839895',
      volume: '5501500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-13': {
      open: '72.53',
      high: '73.86',
      low: '72.52',
      close: '73.16',
      'adjusted close': '40.7931966001166',
      volume: '7150600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-12': {
      open: '73.53',
      high: '73.8',
      low: '72.5',
      close: '72.62',
      'adjusted close': '40.4920986481748',
      volume: '6061900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-11': {
      open: '73.63',
      high: '73.69',
      low: '72.51',
      close: '73.28',
      'adjusted close': '40.8601072561037',
      volume: '6915700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-10': {
      open: '74.75',
      high: '74.76',
      low: '73.04',
      close: '73.3',
      'adjusted close': '40.8712590321016',
      volume: '7982200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-09': {
      open: '75.26',
      high: '75.46',
      low: '74.71',
      close: '74.98',
      'adjusted close': '41.8080082159205',
      volume: '5616700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-06': {
      open: '75.56',
      high: '75.92',
      low: '74.97',
      close: '75.26',
      'adjusted close': '41.9641330798904',
      volume: '7750300',
      'dividend amount': '0.2000',
      'split coefficient': '1.0',
    },
    '2005-05-05': {
      open: '78.0',
      high: '78.11',
      low: '75.33',
      close: '75.5',
      'adjusted close': '41.9863775183107',
      volume: '12371800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-04': {
      open: '76.6',
      high: '77.2',
      low: '76.5',
      close: '77.08',
      'adjusted close': '42.8650328359124',
      volume: '5512300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-03': {
      open: '76.78',
      high: '76.96',
      low: '75.93',
      close: '76.47',
      'adjusted close': '42.5258051500029',
      volume: '6196300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-05-02': {
      open: '76.88',
      high: '77.29',
      low: '76.03',
      close: '76.51',
      'adjusted close': '42.5480495884232',
      volume: '7232500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-29': {
      open: '77.05',
      high: '77.08',
      low: '75.91',
      close: '76.38',
      'adjusted close': '42.4757551635572',
      volume: '8147700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-28': {
      open: '77.05',
      high: '77.11',
      low: '75.65',
      close: '75.91',
      'adjusted close': '42.2143830121187',
      volume: '8629200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-27': {
      open: '75.69',
      high: '77.18',
      low: '75.5',
      close: '77.05',
      'adjusted close': '42.8483495070972',
      volume: '11631400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-26': {
      open: '74.68',
      high: '76.98',
      low: '74.65',
      close: '75.43',
      'adjusted close': '41.9474497510751',
      volume: '12484300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-25': {
      open: '75.24',
      high: '75.72',
      low: '74.05',
      close: '74.61',
      'adjusted close': '41.491438763459',
      volume: '10219400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-22': {
      open: '74.05',
      high: '74.7',
      low: '73.26',
      close: '74.21',
      'adjusted close': '41.2689943792561',
      volume: '10984400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-21': {
      open: '72.99',
      high: '74.1',
      low: '72.8',
      close: '74.0',
      'adjusted close': '41.1522110775495',
      volume: '16224400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-20': {
      open: '75.48',
      high: '75.87',
      low: '71.85',
      close: '72.01',
      'adjusted close': '40.0455502661397',
      volume: '20366300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-19': {
      open: '76.98',
      high: '77.2',
      low: '75.25',
      close: '75.48',
      'adjusted close': '41.9752552991005',
      volume: '9259900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-18': {
      open: '77.15',
      high: '77.75',
      low: '76.14',
      close: '76.65',
      'adjusted close': '42.6259051228942',
      volume: '13255000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-15': {
      open: '79.49',
      high: '79.66',
      low: '76.33',
      close: '76.7',
      'adjusted close': '42.6537106709196',
      volume: '27906300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-14': {
      open: '84.63',
      high: '85.41',
      low: '83.47',
      close: '83.64',
      'adjusted close': '46.5131207368411',
      volume: '10421200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-13': {
      open: '85.76',
      high: '85.9722',
      low: '84.24',
      close: '84.57',
      'adjusted close': '47.030303930113',
      volume: '7090700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-12': {
      open: '85.9',
      high: '86.24',
      low: '85.17',
      close: '85.75',
      'adjusted close': '47.6865148635118',
      volume: '8429700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-11': {
      open: '87.35',
      high: '87.56',
      low: '86.09',
      close: '86.2',
      'adjusted close': '47.9367647957401',
      volume: '8296500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-08': {
      open: '88.28',
      high: '88.46',
      low: '87.5',
      close: '87.6',
      'adjusted close': '48.7153201404505',
      volume: '5179200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-07': {
      open: '89.0',
      high: '89.2',
      low: '88.1',
      close: '88.44',
      'adjusted close': '49.1824533472767',
      volume: '6355700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-06': {
      open: '89.05',
      high: '89.38',
      low: '88.706',
      close: '89.0',
      'adjusted close': '49.4938754851609',
      volume: '7836800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-05': {
      open: '90.23',
      high: '90.33',
      low: '89.26',
      close: '89.57',
      'adjusted close': '49.8108587326501',
      volume: '4568400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-04': {
      open: '90.08',
      high: '90.62',
      low: '89.773',
      close: '90.32',
      'adjusted close': '50.2279419530307',
      volume: '3737800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-04-01': {
      open: '91.49',
      high: '91.76',
      low: '90.04',
      close: '90.44',
      'adjusted close': '50.2946752682916',
      volume: '5721200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-31': {
      open: '90.46',
      high: '91.41',
      low: '90.22',
      close: '91.38',
      'adjusted close': '50.8174195711686',
      volume: '4418600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-30': {
      open: '90.32',
      high: '90.88',
      low: '90.32',
      close: '90.68',
      'adjusted close': '50.4281418988134',
      volume: '5253900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-29': {
      open: '90.74',
      high: '91.07',
      low: '90.18',
      close: '90.6',
      'adjusted close': '50.3836530219728',
      volume: '6070400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-28': {
      open: '90.71',
      high: '91.63',
      low: '90.62',
      close: '91.04',
      'adjusted close': '50.6283418445961',
      volume: '4088900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-24': {
      open: '90.8',
      high: '91.55',
      low: '90.7',
      close: '90.7',
      'adjusted close': '50.4392641180235',
      volume: '4577100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-23': {
      open: '89.97',
      high: '91.17',
      low: '89.82',
      close: '90.52',
      'adjusted close': '50.3391641451322',
      volume: '5472100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-22': {
      open: '89.61',
      high: '90.34',
      low: '89.26',
      close: '89.5',
      'adjusted close': '49.7719309654146',
      volume: '4452500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-21': {
      open: '89.42',
      high: '90.28',
      low: '89.27',
      close: '89.51',
      'adjusted close': '49.7774920750197',
      volume: '4402400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-18': {
      open: '89.86',
      high: '89.9',
      low: '89.09',
      close: '89.28',
      'adjusted close': '49.649586554103',
      volume: '7541500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-17': {
      open: '90.49',
      high: '90.68',
      low: '89.7',
      close: '89.86',
      'adjusted close': '49.9721309111973',
      volume: '5562100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-16': {
      open: '90.74',
      high: '91.4',
      low: '90.25',
      close: '90.65',
      'adjusted close': '50.4114585699982',
      volume: '5065300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-15': {
      open: '92.0',
      high: '92.27',
      low: '91.2',
      close: '91.38',
      'adjusted close': '50.8174195711686',
      volume: '3663100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-14': {
      open: '91.5',
      high: '92.04',
      low: '91.5',
      close: '91.9',
      'adjusted close': '51.1065972706324',
      volume: '3935500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-11': {
      open: '92.25',
      high: '92.41',
      low: '91.2',
      close: '91.51',
      'adjusted close': '50.8897139960345',
      volume: '4494300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-10': {
      open: '92.35',
      high: '92.8',
      low: '92.09',
      close: '92.41',
      'adjusted close': '51.3902138604912',
      volume: '3422500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-09': {
      open: '92.02',
      high: '93.0',
      low: '92.01',
      close: '92.35',
      'adjusted close': '51.3568472028608',
      volume: '4511200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-08': {
      open: '91.7',
      high: '92.56',
      low: '91.7',
      close: '92.13',
      'adjusted close': '51.2345027915491',
      volume: '5087800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-07': {
      open: '92.35',
      high: '92.51',
      low: '91.59',
      close: '91.6',
      'adjusted close': '50.9397639824802',
      volume: '7046900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-04': {
      open: '92.94',
      high: '93.18',
      low: '92.36',
      close: '92.37',
      'adjusted close': '51.3679694220709',
      volume: '4754000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-03': {
      open: '93.15',
      high: '93.21',
      low: '92.2',
      close: '92.41',
      'adjusted close': '51.3902138604912',
      volume: '4037800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-02': {
      open: '92.75',
      high: '93.73',
      low: '92.75',
      close: '92.92',
      'adjusted close': '51.67383045035',
      volume: '4467400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-03-01': {
      open: '92.64',
      high: '93.43',
      low: '92.59',
      close: '93.3',
      'adjusted close': '51.8851526153428',
      volume: '3819900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-28': {
      open: '92.53',
      high: '92.76',
      low: '92.41',
      close: '92.58',
      'adjusted close': '51.4847527237775',
      volume: '4949800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-25': {
      open: '92.35',
      high: '92.8',
      low: '92.29',
      close: '92.8',
      'adjusted close': '51.6070971350891',
      volume: '4078500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-24': {
      open: '91.98',
      high: '93.09',
      low: '91.85',
      close: '92.64',
      'adjusted close': '51.5181193814079',
      volume: '4153900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-23': {
      open: '92.29',
      high: '92.44',
      low: '91.55',
      close: '92.1',
      'adjusted close': '51.2178194627339',
      volume: '5622800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-22': {
      open: '92.67',
      high: '93.5',
      low: '92.23',
      close: '92.32',
      'adjusted close': '51.3401638740456',
      volume: '4825100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-18': {
      open: '93.75',
      high: '94.25',
      low: '92.55',
      close: '93.27',
      'adjusted close': '51.8684692865276',
      volume: '4265300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-17': {
      open: '94.5',
      high: '94.76',
      low: '93.74',
      close: '93.75',
      'adjusted close': '52.1354025475712',
      volume: '4254200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-16': {
      open: '94.23',
      high: '94.97',
      low: '94.2',
      close: '94.62',
      'adjusted close': '52.6192190832126',
      volume: '4466500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-15': {
      open: '93.5',
      high: '94.67',
      low: '93.48',
      close: '94.33',
      'adjusted close': '52.4579469046655',
      volume: '4148000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-14': {
      open: '93.16',
      high: '94.02',
      low: '93.16',
      close: '93.57',
      'adjusted close': '52.0353025746798',
      volume: '2868800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-11': {
      open: '92.7',
      high: '93.97',
      low: '92.5',
      close: '93.3',
      'adjusted close': '51.8851526153428',
      volume: '4229300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-10': {
      open: '92.95',
      high: '93.1',
      low: '92.3',
      close: '92.76',
      'adjusted close': '51.5848526966688',
      volume: '4838400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-09': {
      open: '94.14',
      high: '94.83',
      low: '92.54',
      close: '92.7',
      'adjusted close': '51.5514860390384',
      volume: '5657100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-08': {
      open: '94.28',
      high: '94.64',
      low: '94.13',
      close: '94.13',
      'adjusted close': '52.346724712564',
      volume: '3541100',
      'dividend amount': '0.1800',
      'split coefficient': '1.0',
    },
    '2005-02-07': {
      open: '94.35',
      high: '94.9',
      low: '94.33',
      close: '94.53',
      'adjusted close': '52.4688356174178',
      volume: '2837800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-04': {
      open: '93.01',
      high: '94.74',
      low: '93.0',
      close: '94.51',
      'adjusted close': '52.4577346260675',
      volume: '3807600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-03': {
      open: '93.87',
      high: '93.94',
      low: '93.06',
      close: '93.54',
      'adjusted close': '51.9193365455756',
      volume: '3928700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-02': {
      open: '93.78',
      high: '94.35',
      low: '93.63',
      close: '94.3',
      'adjusted close': '52.3411742168888',
      volume: '3597400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-02-01': {
      open: '93.67',
      high: '94.0',
      low: '93.37',
      close: '93.86',
      'adjusted close': '52.0969524071812',
      volume: '3637400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-31': {
      open: '93.65',
      high: '93.95',
      low: '93.05',
      close: '93.42',
      'adjusted close': '51.8527305974735',
      volume: '4759900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-28': {
      open: '92.68',
      high: '93.59',
      low: '92.48',
      close: '92.89',
      'adjusted close': '51.5585543266893',
      volume: '5960600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-27': {
      open: '91.5',
      high: '92.22',
      low: '91.44',
      close: '91.98',
      'adjusted close': '51.0534592202485',
      volume: '4746900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-26': {
      open: '92.3',
      high: '92.87',
      low: '91.94',
      close: '91.95',
      'adjusted close': '51.036807733223',
      volume: '5352000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-25': {
      open: '91.98',
      high: '92.59',
      low: '91.95',
      close: '92.19',
      'adjusted close': '51.1700196294272',
      volume: '5070700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-24': {
      open: '92.7',
      high: '92.85',
      low: '91.76',
      close: '91.79',
      'adjusted close': '50.9479998024202',
      volume: '6537300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-21': {
      open: '93.0',
      high: '93.3',
      low: '92.23',
      close: '92.38',
      'adjusted close': '51.2754790472555',
      volume: '7002600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-20': {
      open: '92.61',
      high: '93.84',
      low: '92.6',
      close: '93.0',
      'adjusted close': '51.6196097791162',
      volume: '5708600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-19': {
      open: '94.95',
      high: '95.15',
      low: '92.93',
      close: '93.1',
      'adjusted close': '51.675114735868',
      volume: '7352700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-18': {
      open: '93.65',
      high: '95.34',
      low: '93.62',
      close: '94.9',
      'adjusted close': '52.6742039573993',
      volume: '8492100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-14': {
      open: '94.01',
      high: '94.25',
      low: '93.55',
      close: '94.1',
      'adjusted close': '52.2301643033853',
      volume: '5520800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-13': {
      open: '95.39',
      high: '96.2',
      low: '93.7',
      close: '94.45',
      'adjusted close': '52.4244316520164',
      volume: '5339400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-12': {
      open: '95.0',
      high: '95.28',
      low: '94.06',
      close: '95.21',
      'adjusted close': '52.8462693233296',
      volume: '5828600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-11': {
      open: '95.68',
      high: '95.79',
      low: '94.71',
      close: '95.0',
      'adjusted close': '52.729708914151',
      volume: '4746400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-10': {
      open: '95.78',
      high: '96.09',
      low: '95.24',
      close: '95.68',
      'adjusted close': '53.1071426200628',
      volume: '4625100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-07': {
      open: '96.5',
      high: '96.8',
      low: '95.47',
      close: '95.78',
      'adjusted close': '53.1626475768145',
      volume: '6200700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-06': {
      open: '96.54',
      high: '96.98',
      low: '96.05',
      close: '96.2',
      'adjusted close': '53.3957683951718',
      volume: '4561700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-05': {
      open: '96.6',
      high: '97.83',
      low: '96.4',
      close: '96.5',
      'adjusted close': '53.5622832654271',
      volume: '5646700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-04': {
      open: '97.74',
      high: '98.42',
      low: '96.52',
      close: '96.7',
      'adjusted close': '53.6732931789305',
      volume: '5711000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2005-01-03': {
      open: '98.97',
      high: '99.1',
      low: '97.25',
      close: '97.75',
      'adjusted close': '54.2560952248238',
      volume: '5295200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-31': {
      open: '98.6',
      high: '98.91',
      low: '98.49',
      close: '98.58',
      'adjusted close': '54.7167863658632',
      volume: '2793200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-30': {
      open: '98.1',
      high: '99.0',
      low: '98.07',
      close: '98.3',
      'adjusted close': '54.5613724869583',
      volume: '3812400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-29': {
      open: '97.81',
      high: '98.47',
      low: '97.8',
      close: '98.18',
      'adjusted close': '54.4947665388563',
      volume: '3296300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-28': {
      open: '97.4',
      high: '98.55',
      low: '97.37',
      close: '98.3',
      'adjusted close': '54.5613724869583',
      volume: '4336400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-27': {
      open: '97.69',
      high: '97.97',
      low: '97.38',
      close: '97.5',
      'adjusted close': '54.1173328329444',
      volume: '3262900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-23': {
      open: '97.5',
      high: '98.0',
      low: '97.5',
      close: '97.72',
      'adjusted close': '54.2394437377983',
      volume: '3590600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-22': {
      open: '97.35',
      high: '97.98',
      low: '97.29',
      close: '97.61',
      'adjusted close': '54.1783882853713',
      volume: '4950100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-21': {
      open: '96.59',
      high: '97.15',
      low: '96.51',
      close: '97.02',
      'adjusted close': '53.8509090405361',
      volume: '4841800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-20': {
      open: '96.35',
      high: '97.57',
      low: '96.35',
      close: '96.55',
      'adjusted close': '53.5900357438029',
      volume: '4769900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-17': {
      open: '97.0',
      high: '98.0',
      low: '96.2',
      close: '96.2',
      'adjusted close': '53.3957683951718',
      volume: '8853100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-16': {
      open: '96.8',
      high: '98.15',
      low: '96.8',
      close: '97.45',
      'adjusted close': '54.0895803545686',
      volume: '5660100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-15': {
      open: '96.92',
      high: '97.7',
      low: '96.81',
      close: '97.33',
      'adjusted close': '54.0229744064665',
      volume: '3914500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-14': {
      open: '96.3',
      high: '97.7',
      low: '96.2884',
      close: '97.31',
      'adjusted close': '54.0118734151161',
      volume: '4493200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-13': {
      open: '96.85',
      high: '97.2',
      low: '96.08',
      close: '96.45',
      'adjusted close': '53.5345307870512',
      volume: '4799500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-10': {
      open: '96.1',
      high: '97.99',
      low: '96.1',
      close: '96.67',
      'adjusted close': '53.656641691905',
      volume: '4188300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-09': {
      open: '96.2',
      high: '97.6',
      low: '95.71',
      close: '97.51',
      'adjusted close': '54.1228833286196',
      volume: '5713700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-08': {
      open: '96.43',
      high: '97.35',
      low: '95.77',
      close: '96.65',
      'adjusted close': '53.6455407005547',
      volume: '5310700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-07': {
      open: '97.7',
      high: '98.25',
      low: '95.99',
      close: '96.1',
      'adjusted close': '53.3402634384201',
      volume: '6477100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-06': {
      open: '96.88',
      high: '97.9',
      low: '96.65',
      close: '97.67',
      'adjusted close': '54.2116912594224',
      volume: '5263000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-03': {
      open: '96.55',
      high: '97.63',
      low: '96.55',
      close: '97.08',
      'adjusted close': '53.8842120145871',
      volume: '7026800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-02': {
      open: '95.65',
      high: '96.78',
      low: '95.49',
      close: '95.76',
      'adjusted close': '53.1515465854642',
      volume: '5152300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-12-01': {
      open: '94.5',
      high: '96.07',
      low: '94.47',
      close: '95.88',
      'adjusted close': '53.2181525335663',
      volume: '5664500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-30': {
      open: '95.15',
      high: '95.65',
      low: '94.24',
      close: '94.24',
      'adjusted close': '52.3078712428378',
      volume: '5870300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-29': {
      open: '94.94',
      high: '96.38',
      low: '94.94',
      close: '95.5',
      'adjusted close': '53.0072336979097',
      volume: '5699800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-26': {
      open: '95.05',
      high: '95.38',
      low: '94.58',
      close: '94.72',
      'adjusted close': '52.5742950352461',
      volume: '2204300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-24': {
      open: '95.04',
      high: '95.79',
      low: '95.0375',
      close: '95.46',
      'adjusted close': '52.985031715209',
      volume: '3750600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-23': {
      open: '94.7',
      high: '95.37',
      low: '94.55',
      close: '95.28',
      'adjusted close': '52.8851227930559',
      volume: '5529900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-22': {
      open: '94.3',
      high: '95.4',
      low: '94.16',
      close: '95.11',
      'adjusted close': '52.7907643665779',
      volume: '5814100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-19': {
      open: '94.95',
      high: '95.16',
      low: '94.25',
      close: '94.45',
      'adjusted close': '52.4244316520164',
      volume: '5679100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-18': {
      open: '95.36',
      high: '95.7',
      low: '95.03',
      close: '95.1',
      'adjusted close': '52.7852138709027',
      volume: '4655900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-17': {
      open: '95.3',
      high: '96.63',
      low: '95.3',
      close: '95.46',
      'adjusted close': '52.985031715209',
      volume: '6353200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-16': {
      open: '95.25',
      high: '95.5',
      low: '94.65',
      close: '94.89',
      'adjusted close': '52.6686534617241',
      volume: '5684100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-15': {
      open: '95.08',
      high: '96.0',
      low: '94.8',
      close: '95.92',
      'adjusted close': '53.240354516267',
      volume: '4887600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-12': {
      open: '94.66',
      high: '95.5',
      low: '94.56',
      close: '95.32',
      'adjusted close': '52.9073247757565',
      volume: '4958400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-11': {
      open: '93.6',
      high: '95.22',
      low: '93.54',
      close: '94.79',
      'adjusted close': '52.6131485049723',
      volume: '7453400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-10': {
      open: '92.92',
      high: '94.3',
      low: '92.92',
      close: '93.61',
      'adjusted close': '51.9581900153018',
      volume: '6258400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-09': {
      open: '93.0',
      high: '93.95',
      low: '93.0',
      close: '93.37',
      'adjusted close': '51.8249781190977',
      volume: '4513100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-08': {
      open: '92.5',
      high: '93.7',
      low: '92.5',
      close: '93.37',
      'adjusted close': '51.8249781190977',
      volume: '4907300',
      'dividend amount': '0.1800',
      'split coefficient': '1.0',
    },
    '2004-11-05': {
      open: '92.4',
      high: '93.52',
      low: '92.4',
      close: '93.28',
      'adjusted close': '51.6754030887165',
      volume: '6708400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-04': {
      open: '91.05',
      high: '92.7',
      low: '90.82',
      close: '92.38',
      'adjusted close': '51.1768196541127',
      volume: '6951600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-03': {
      open: '91.25',
      high: '91.9',
      low: '90.97',
      close: '91.2',
      'adjusted close': '50.5231213731877',
      volume: '6553300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-02': {
      open: '89.55',
      high: '91.22',
      low: '89.5',
      close: '90.47',
      'adjusted close': '50.1187148095646',
      volume: '5388700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-11-01': {
      open: '89.33',
      high: '90.6',
      low: '89.23',
      close: '90.11',
      'adjusted close': '49.919281435723',
      volume: '5160600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-29': {
      open: '89.4',
      high: '89.9',
      low: '88.95',
      close: '89.75',
      'adjusted close': '49.7198480618815',
      volume: '4518500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-28': {
      open: '89.8',
      high: '90.24',
      low: '89.43',
      close: '89.5',
      'adjusted close': '49.5813526633804',
      volume: '4226500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-27': {
      open: '88.58',
      high: '90.27',
      low: '88.5',
      close: '90.0',
      'adjusted close': '49.8583434603826',
      volume: '6035100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-26': {
      open: '88.33',
      high: '89.57',
      low: '88.25',
      close: '89.0',
      'adjusted close': '49.3043618663783',
      volume: '7335800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-25': {
      open: '87.36',
      high: '88.9',
      low: '87.31',
      close: '88.43',
      'adjusted close': '48.9885923577959',
      volume: '5774500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-22': {
      open: '88.22',
      high: '88.45',
      low: '87.29',
      close: '87.39',
      'adjusted close': '48.4124515000315',
      volume: '5988700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-21': {
      open: '88.4',
      high: '88.76',
      low: '87.66',
      close: '88.1',
      'adjusted close': '48.8057784317745',
      volume: '6137500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-20': {
      open: '88.45',
      high: '89.19',
      low: '88.29',
      close: '88.82',
      'adjusted close': '49.2046451794576',
      volume: '6926800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-19': {
      open: '88.2',
      high: '89.73',
      low: '88.0',
      close: '89.37',
      'adjusted close': '49.5093350561599',
      volume: '13692200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-18': {
      open: '84.3',
      high: '86.15',
      low: '84.29',
      close: '85.92',
      'adjusted close': '47.5980985568452',
      volume: '7182600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-15': {
      open: '84.78',
      high: '85.25',
      low: '84.6',
      close: '84.85',
      'adjusted close': '47.0053382512607',
      volume: '5928500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-14': {
      open: '84.75',
      high: '84.98',
      low: '84.3',
      close: '84.78',
      'adjusted close': '46.9665595396804',
      volume: '4233700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-13': {
      open: '86.26',
      high: '86.48',
      low: '84.43',
      close: '84.98',
      'adjusted close': '47.0773558584812',
      volume: '6651400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-12': {
      open: '86.02',
      high: '86.2',
      low: '85.58',
      close: '86.0',
      'adjusted close': '47.6424170843656',
      volume: '4626600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-11': {
      open: '86.77',
      high: '87.2',
      low: '86.27',
      close: '86.63',
      'adjusted close': '47.9914254885882',
      volume: '3016300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-08': {
      open: '87.43',
      high: '87.91',
      low: '86.51',
      close: '86.71',
      'adjusted close': '48.0357440161086',
      volume: '4090000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-07': {
      open: '88.04',
      high: '88.1',
      low: '87.4',
      close: '87.42',
      'adjusted close': '48.4290709478516',
      volume: '3076900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-06': {
      open: '87.14',
      high: '88.1',
      low: '87.1',
      close: '88.04',
      'adjusted close': '48.7725395361342',
      volume: '3984400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-05': {
      open: '87.95',
      high: '88.03',
      low: '87.13',
      close: '87.32',
      'adjusted close': '48.3736727884512',
      volume: '5150700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-04': {
      open: '87.0',
      high: '88.1',
      low: '86.72',
      close: '87.16',
      'adjusted close': '48.2850357334105',
      volume: '5001400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-10-01': {
      open: '85.95',
      high: '86.98',
      low: '85.88',
      close: '86.72',
      'adjusted close': '48.0412838320486',
      volume: '4538000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-30': {
      open: '85.14',
      high: '85.98',
      low: '85.01',
      close: '85.74',
      'adjusted close': '47.4983818699245',
      volume: '5198000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-29': {
      open: '84.48',
      high: '84.98',
      low: '84.15',
      close: '84.98',
      'adjusted close': '47.0773558584812',
      volume: '4204500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-28': {
      open: '84.35',
      high: '84.65',
      low: '83.88',
      close: '84.48',
      'adjusted close': '46.8003650614791',
      volume: '3874200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-27': {
      open: '84.1',
      high: '84.44',
      low: '83.98',
      close: '84.16',
      'adjusted close': '46.6230909513977',
      volume: '4650300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-24': {
      open: '83.8',
      high: '84.74',
      low: '83.78',
      close: '84.43',
      'adjusted close': '46.7726659817789',
      volume: '4899500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-23': {
      open: '84.04',
      high: '84.27',
      low: '83.24',
      close: '83.88',
      'adjusted close': '46.4679761050766',
      volume: '4801200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-22': {
      open: '85.35',
      high: '85.44',
      low: '84.17',
      close: '84.31',
      'adjusted close': '46.7061881904984',
      volume: '5037100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-21': {
      open: '85.7',
      high: '86.11',
      low: '85.34',
      close: '85.72',
      'adjusted close': '47.4873022380444',
      volume: '4049700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-20': {
      open: '85.4',
      high: '86.43',
      low: '85.4',
      close: '85.7',
      'adjusted close': '47.4762226061643',
      volume: '4380400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-17': {
      open: '86.4',
      high: '86.5',
      low: '85.44',
      close: '85.74',
      'adjusted close': '47.4983818699245',
      volume: '6198700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-16': {
      open: '86.2',
      high: '86.96',
      low: '85.93',
      close: '86.12',
      'adjusted close': '47.7088948756461',
      volume: '3623000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-15': {
      open: '85.94',
      high: '86.5',
      low: '85.89',
      close: '86.37',
      'adjusted close': '47.8473902741471',
      volume: '4631200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-14': {
      open: '86.6',
      high: '86.88',
      low: '86.15',
      close: '86.72',
      'adjusted close': '48.0412838320486',
      volume: '3953500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-13': {
      open: '87.0',
      high: '87.28',
      low: '86.08',
      close: '86.49',
      'adjusted close': '47.9138680654276',
      volume: '4801400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-10': {
      open: '86.43',
      high: '87.0',
      low: '86.25',
      close: '86.76',
      'adjusted close': '48.0634430958088',
      volume: '3753500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-09': {
      open: '85.86',
      high: '86.79',
      low: '85.86',
      close: '86.44',
      'adjusted close': '47.8861689857274',
      volume: '4517400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-08': {
      open: '84.9',
      high: '86.51',
      low: '84.88',
      close: '85.86',
      'adjusted close': '47.564859661205',
      volume: '5721200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-07': {
      open: '84.7',
      high: '85.44',
      low: '84.6',
      close: '84.97',
      'adjusted close': '47.0718160425412',
      volume: '4002800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-03': {
      open: '84.3',
      high: '84.69',
      low: '83.96',
      close: '84.39',
      'adjusted close': '46.7505067180187',
      volume: '3479800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-02': {
      open: '84.22',
      high: '84.78',
      low: '83.85',
      close: '84.57',
      'adjusted close': '46.8502234049395',
      volume: '3380400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-09-01': {
      open: '84.05',
      high: '85.09',
      low: '84.01',
      close: '84.22',
      'adjusted close': '46.656329847038',
      volume: '4748500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-31': {
      open: '84.55',
      high: '84.69',
      low: '83.65',
      close: '84.69',
      'adjusted close': '46.91670119622',
      volume: '3399500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-30': {
      open: '84.57',
      high: '84.99',
      low: '84.39',
      close: '84.4',
      'adjusted close': '46.7560465339588',
      volume: '2277900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-27': {
      open: '84.65',
      high: '84.95',
      low: '84.59',
      close: '84.94',
      'adjusted close': '47.0551965947211',
      volume: '2444800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-26': {
      open: '85.0',
      high: '85.04',
      low: '84.69',
      close: '84.69',
      'adjusted close': '46.91670119622',
      volume: '3134400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-25': {
      open: '85.0',
      high: '85.27',
      low: '84.55',
      close: '85.07',
      'adjusted close': '47.1272142019416',
      volume: '4405600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-24': {
      open: '85.0',
      high: '85.15',
      low: '84.35',
      close: '84.71',
      'adjusted close': '46.9277808281001',
      volume: '2710400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-23': {
      open: '85.23',
      high: '85.45',
      low: '84.65',
      close: '84.65',
      'adjusted close': '46.8945419324598',
      volume: '4260600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-20': {
      open: '84.52',
      high: '85.25',
      low: '84.52',
      close: '85.25',
      'adjusted close': '47.2269308888624',
      volume: '4501400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-19': {
      open: '84.75',
      high: '85.35',
      low: '84.45',
      close: '84.89',
      'adjusted close': '47.0274975150208',
      volume: '4704500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-18': {
      open: '83.6',
      high: '85.13',
      low: '83.57',
      close: '85.13',
      'adjusted close': '47.1604530975819',
      volume: '4397500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-17': {
      open: '84.1',
      high: '84.54',
      low: '83.66',
      close: '84.04',
      'adjusted close': '46.5566131601172',
      volume: '3559400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-16': {
      open: '83.7',
      high: '84.56',
      low: '83.51',
      close: '84.02',
      'adjusted close': '46.5455335282372',
      volume: '4361500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-13': {
      open: '82.54',
      high: '83.94',
      low: '82.51',
      close: '83.91',
      'adjusted close': '46.4845955528967',
      volume: '5801900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-12': {
      open: '83.05',
      high: '83.05',
      low: '81.9',
      close: '82.21',
      'adjusted close': '45.5428268430895',
      volume: '7134900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-11': {
      open: '83.7',
      high: '83.98',
      low: '83.1',
      close: '83.69',
      'adjusted close': '46.3627196022157',
      volume: '5288000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-10': {
      open: '84.0',
      high: '84.99',
      low: '83.81',
      close: '84.99',
      'adjusted close': '47.0828956744213',
      volume: '4351000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-09': {
      open: '83.48',
      high: '83.96',
      low: '82.87',
      close: '83.55',
      'adjusted close': '46.2851621790551',
      volume: '3621100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-06': {
      open: '84.38',
      high: '84.76',
      low: '83.42',
      close: '83.48',
      'adjusted close': '46.2463834674749',
      volume: '5219200',
      'dividend amount': '0.1800',
      'split coefficient': '1.0',
    },
    '2004-08-05': {
      open: '85.97',
      high: '86.42',
      low: '85.01',
      close: '85.19',
      'adjusted close': '47.0921516566362',
      volume: '3879900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-04': {
      open: '85.3',
      high: '86.65',
      low: '85.25',
      close: '85.97',
      'adjusted close': '47.5233275962086',
      volume: '4160600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-03': {
      open: '86.7',
      high: '86.8',
      low: '85.44',
      close: '85.71',
      'adjusted close': '47.3796022830178',
      volume: '4147200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-08-02': {
      open: '86.87',
      high: '87.39',
      low: '86.42',
      close: '86.69',
      'adjusted close': '47.9213361558139',
      volume: '3408400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-30': {
      open: '86.45',
      high: '87.4',
      low: '86.3875',
      close: '87.07',
      'adjusted close': '48.131396228939',
      volume: '3508500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-29': {
      open: '86.25',
      high: '87.22',
      low: '86.23',
      close: '86.77',
      'adjusted close': '47.9655593291034',
      volume: '4232300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-28': {
      open: '85.88',
      high: '86.28',
      low: '84.88',
      close: '85.85',
      'adjusted close': '47.4569928362744',
      volume: '6566200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-27': {
      open: '85.6',
      high: '86.3',
      low: '85.45',
      close: '85.88',
      'adjusted close': '47.473576526258',
      volume: '4098500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-26': {
      open: '84.85',
      high: '85.58',
      low: '84.51',
      close: '85.09',
      'adjusted close': '47.0368726900243',
      volume: '4517500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-23': {
      open: '85.7',
      high: '86.06',
      low: '84.5',
      close: '84.85',
      'adjusted close': '46.9042031701559',
      volume: '4309400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-22': {
      open: '85.0',
      high: '86.4',
      low: '84.68',
      close: '86.06',
      'adjusted close': '47.5730786661593',
      volume: '5972600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-21': {
      open: '86.75',
      high: '87.11',
      low: '85.3',
      close: '85.3',
      'adjusted close': '47.1529585199092',
      volume: '5534000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-20': {
      open: '85.63',
      high: '86.49',
      low: '85.3',
      close: '86.36',
      'adjusted close': '47.7389155659948',
      volume: '5651300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-19': {
      open: '84.5',
      high: '85.85',
      low: '84.4',
      close: '85.3',
      'adjusted close': '47.1529585199092',
      volume: '6596000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-16': {
      open: '86.05',
      high: '86.48',
      low: '84.28',
      close: '84.28',
      'adjusted close': '46.5891130604683',
      volume: '10441400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-15': {
      open: '84.13',
      high: '84.63',
      low: '83.61',
      close: '84.02',
      'adjusted close': '46.4453877472775',
      volume: '7086500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-14': {
      open: '84.84',
      high: '85.24',
      low: '83.78',
      close: '84.13',
      'adjusted close': '46.5061946105506',
      volume: '6298600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-13': {
      open: '85.9',
      high: '86.09',
      low: '85.2',
      close: '85.25',
      'adjusted close': '47.1253190366033',
      volume: '6001000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-12': {
      open: '84.0',
      high: '85.25',
      low: '83.42',
      close: '84.95',
      'adjusted close': '46.9594821367677',
      volume: '6009200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-09': {
      open: '84.4',
      high: '84.5',
      low: '83.51',
      close: '83.89',
      'adjusted close': '46.3735250906821',
      volume: '6595200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-08': {
      open: '85.0',
      high: '85.01',
      low: '83.58',
      close: '83.65',
      'adjusted close': '46.2408555708137',
      volume: '7529000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-07': {
      open: '85.3',
      high: '85.94',
      low: '85.05',
      close: '85.35',
      'adjusted close': '47.1805980032151',
      volume: '5677300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-06': {
      open: '86.5',
      high: '86.73',
      low: '85.13',
      close: '85.7',
      'adjusted close': '47.3740743863566',
      volume: '6408100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-02': {
      open: '87.45',
      high: '87.55',
      low: '86.6',
      close: '87.04',
      'adjusted close': '48.1148125389554',
      volume: '2874500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-07-01': {
      open: '88.28',
      high: '88.44',
      low: '86.57',
      close: '87.5',
      'adjusted close': '48.3690957853699',
      volume: '5093900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-30': {
      open: '88.3',
      high: '88.5',
      low: '87.7',
      close: '88.15',
      'adjusted close': '48.728409068347',
      volume: '4724000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-29': {
      open: '88.35',
      high: '88.49',
      low: '87.9',
      close: '88.29',
      'adjusted close': '48.8057996216036',
      volume: '4394800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-28': {
      open: '89.71',
      high: '89.9',
      low: '88.36',
      close: '88.71',
      'adjusted close': '49.0379712813733',
      volume: '4423200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-25': {
      open: '89.95',
      high: '90.23',
      low: '88.94',
      close: '89.55',
      'adjusted close': '49.5023146009129',
      volume: '5604700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-24': {
      open: '90.28',
      high: '90.92',
      low: '89.84',
      close: '89.99',
      'adjusted close': '49.745542054005',
      volume: '4069400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-23': {
      open: '90.1',
      high: '90.84',
      low: '89.84',
      close: '90.79',
      'adjusted close': '50.1877737868999',
      volume: '4354700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-22': {
      open: '89.28',
      high: '90.24',
      low: '89.13',
      close: '90.02',
      'adjusted close': '49.7621257439886',
      volume: '4149300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-21': {
      open: '90.4',
      high: '90.43',
      low: '89.31',
      close: '89.49',
      'adjusted close': '49.4691472209458',
      volume: '3843400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-18': {
      open: '90.2',
      high: '90.8',
      low: '89.9',
      close: '90.06',
      'adjusted close': '49.7842373306333',
      volume: '6470800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-17': {
      open: '90.5',
      high: '90.56',
      low: '90.07',
      close: '90.44',
      'adjusted close': '49.9942974037584',
      volume: '4180200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-16': {
      open: '90.25',
      high: '90.93',
      low: '90.09',
      close: '90.38',
      'adjusted close': '49.9611300237913',
      volume: '3145700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-15': {
      open: '90.49',
      high: '91.21',
      low: '90.2315',
      close: '90.54',
      'adjusted close': '50.0495763703702',
      volume: '4508300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-14': {
      open: '90.05',
      high: '90.58',
      low: '89.62',
      close: '90.07',
      'adjusted close': '49.7897652272945',
      volume: '4121400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-10': {
      open: '90.23',
      high: '90.75',
      low: '89.891',
      close: '90.46',
      'adjusted close': '50.0053531970807',
      volume: '3468700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-09': {
      open: '89.9',
      high: '90.55',
      low: '89.81',
      close: '90.09',
      'adjusted close': '49.8008210206169',
      volume: '5233400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-08': {
      open: '88.64',
      high: '90.5',
      low: '88.4',
      close: '90.04',
      'adjusted close': '49.773181537311',
      volume: '5400300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-07': {
      open: '88.75',
      high: '88.99',
      low: '88.01',
      close: '88.64',
      'adjusted close': '48.999276004745',
      volume: '4264500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-04': {
      open: '87.95',
      high: '88.49',
      low: '87.5',
      close: '87.56',
      'adjusted close': '48.4022631653371',
      volume: '3803400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-03': {
      open: '87.85',
      high: '88.1',
      low: '87.35',
      close: '87.35',
      'adjusted close': '48.2861773354522',
      volume: '3011500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-02': {
      open: '88.64',
      high: '88.64',
      low: '87.89',
      close: '87.98',
      'adjusted close': '48.6344348251068',
      volume: '3912600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-06-01': {
      open: '88.09',
      high: '88.48',
      low: '87.3',
      close: '88.12',
      'adjusted close': '48.7118253783634',
      volume: '4884300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-28': {
      open: '88.48',
      high: '88.85',
      low: '88.25',
      close: '88.59',
      'adjusted close': '48.9716365214391',
      volume: '3203800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-27': {
      open: '88.75',
      high: '89.08',
      low: '87.98',
      close: '88.59',
      'adjusted close': '48.9716365214391',
      volume: '4094600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-26': {
      open: '88.64',
      high: '88.85',
      low: '88.04',
      close: '88.35',
      'adjusted close': '48.8389670015707',
      volume: '4308200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-25': {
      open: '86.75',
      high: '88.92',
      low: '86.55',
      close: '88.7',
      'adjusted close': '49.0324433847122',
      volume: '5358100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-24': {
      open: '87.75',
      high: '87.84',
      low: '86.55',
      close: '87.1',
      'adjusted close': '48.1479799189225',
      volume: '3778100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-21': {
      open: '87.59',
      high: '88.18',
      low: '86.91',
      close: '87.13',
      'adjusted close': '48.1645636089061',
      volume: '5130000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-20': {
      open: '87.06',
      high: '87.95',
      low: '87.05',
      close: '87.42',
      'adjusted close': '48.3248726120805',
      volume: '4031500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-19': {
      open: '87.4',
      high: '88.88',
      low: '86.5',
      close: '87.05',
      'adjusted close': '48.1203404356166',
      volume: '7431800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-18': {
      open: '86.08',
      high: '86.49',
      low: '86.0',
      close: '86.06',
      'adjusted close': '47.5730786661593',
      volume: '4401100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-17': {
      open: '85.3',
      high: '86.03',
      low: '85.15',
      close: '85.53',
      'adjusted close': '47.2801001431165',
      volume: '5700000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-14': {
      open: '86.65',
      high: '87.35',
      low: '86.07',
      close: '86.41',
      'adjusted close': '47.7665550493008',
      volume: '5197800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-13': {
      open: '86.79',
      high: '87.41',
      low: '86.26',
      close: '87.19',
      'adjusted close': '48.1977309888732',
      volume: '4708400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-12': {
      open: '87.25',
      high: '87.25',
      low: '85.12',
      close: '86.81',
      'adjusted close': '47.9876709157482',
      volume: '8445800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-11': {
      open: '87.2',
      high: '88.15',
      low: '86.82',
      close: '87.13',
      'adjusted close': '48.1645636089061',
      volume: '6575000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-10': {
      open: '87.6',
      high: '88.1025',
      low: '86.37',
      close: '86.88',
      'adjusted close': '48.0263661923765',
      volume: '6692400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-07': {
      open: '87.75',
      high: '89.4',
      low: '87.62',
      close: '88.19',
      'adjusted close': '48.7505206549917',
      volume: '5407400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-06': {
      open: '88.1',
      high: '89.1',
      low: '87.59',
      close: '88.36',
      'adjusted close': '48.8444948982319',
      volume: '4319100',
      'dividend amount': '0.1800',
      'split coefficient': '1.0',
    },
    '2004-05-05': {
      open: '89.75',
      high: '89.75',
      low: '88.51',
      close: '88.96',
      'adjusted close': '49.0761945577898',
      volume: '4402300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-04': {
      open: '88.02',
      high: '89.74',
      low: '88.0',
      close: '89.0',
      'adjusted close': '49.0982611920334',
      volume: '5451800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-05-03': {
      open: '88.13',
      high: '88.52',
      low: '87.2012',
      close: '88.02',
      'adjusted close': '48.5576286530649',
      volume: '6070100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-30': {
      open: '89.01',
      high: '89.2',
      low: '88.01',
      close: '88.17',
      'adjusted close': '48.6403785314785',
      volume: '5019500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-29': {
      open: '90.01',
      high: '90.26',
      low: '88.19',
      close: '89.08',
      'adjusted close': '49.1423944605206',
      volume: '7763200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-28': {
      open: '90.3',
      high: '90.98',
      low: '90.21',
      close: '90.41',
      'adjusted close': '49.8761100491207',
      volume: '4706000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-27': {
      open: '90.65',
      high: '91.99',
      low: '90.56',
      close: '91.11',
      'adjusted close': '50.2622761483838',
      volume: '4768000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-26': {
      open: '90.75',
      high: '91.56',
      low: '90.349',
      close: '90.43',
      'adjusted close': '49.8871433662425',
      volume: '4533600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-23': {
      open: '91.0',
      high: '91.61',
      low: '90.36',
      close: '91.28',
      'adjusted close': '50.3560593439192',
      volume: '5063200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-22': {
      open: '90.5',
      high: '91.51',
      low: '89.7',
      close: '90.74',
      'adjusted close': '50.0581597816304',
      volume: '7988000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-21': {
      open: '91.15',
      high: '91.42',
      low: '90.69',
      close: '91.26',
      'adjusted close': '50.3450260267974',
      volume: '4623400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-20': {
      open: '92.0',
      high: '92.48',
      low: '90.65',
      close: '90.65',
      'adjusted close': '50.0085098545823',
      volume: '4260200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-19': {
      open: '91.9',
      high: '92.3',
      low: '91.7',
      close: '91.94',
      'adjusted close': '50.7201588089388',
      volume: '4159400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-16': {
      open: '92.3',
      high: '92.35',
      low: '91.04',
      close: '92.28',
      'adjusted close': '50.9077252000095',
      volume: '11118000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-15': {
      open: '93.8',
      high: '94.09',
      low: '93.06',
      close: '93.97',
      'adjusted close': '51.840040496802',
      volume: '6844200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-14': {
      open: '92.1',
      high: '93.86',
      low: '92.1',
      close: '93.7',
      'adjusted close': '51.6910907156576',
      volume: '5316300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-13': {
      open: '93.79',
      high: '94.04',
      low: '92.5',
      close: '93.04',
      'adjusted close': '51.3269912506381',
      volume: '5013600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-12': {
      open: '93.5',
      high: '94.36',
      low: '93.4',
      close: '93.74',
      'adjusted close': '51.7131573499012',
      volume: '3112600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-08': {
      open: '94.0',
      high: '94.39',
      low: '92.55',
      close: '93.12',
      'adjusted close': '51.3711245191253',
      volume: '3868000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-07': {
      open: '93.37',
      high: '93.58',
      low: '92.51',
      close: '93.08',
      'adjusted close': '51.3490578848817',
      volume: '4537300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-06': {
      open: '93.6',
      high: '93.79',
      low: '93.02',
      close: '93.7',
      'adjusted close': '51.6910907156576',
      volume: '3860100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-05': {
      open: '93.95',
      high: '94.37',
      low: '93.51',
      close: '94.18',
      'adjusted close': '51.9558903265809',
      volume: '3936000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-02': {
      open: '93.55',
      high: '94.55',
      low: '93.33',
      close: '94.2',
      'adjusted close': '51.9669236437028',
      volume: '5894500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-04-01': {
      open: '91.67',
      high: '92.68',
      low: '91.62',
      close: '92.37',
      'adjusted close': '50.9573751270576',
      volume: '4246900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-31': {
      open: '92.07',
      high: '92.24',
      low: '91.51',
      close: '91.84',
      'adjusted close': '50.6649922233297',
      volume: '4613500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-30': {
      open: '92.67',
      high: '92.67',
      low: '91.35',
      close: '92.32',
      'adjusted close': '50.9297918342531',
      volume: '5415700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-29': {
      open: '92.99',
      high: '93.61',
      low: '92.18',
      close: '92.68',
      'adjusted close': '51.1283915424456',
      volume: '4876300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-26': {
      open: '92.39',
      high: '93.25',
      low: '92.16',
      close: '92.77',
      'adjusted close': '51.1780414694937',
      volume: '4397700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-25': {
      open: '92.15',
      high: '92.63',
      low: '91.45',
      close: '92.39',
      'adjusted close': '50.9684084441794',
      volume: '5585800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-24': {
      open: '91.57',
      high: '92.49',
      low: '91.04',
      close: '91.77',
      'adjusted close': '50.6263756134034',
      volume: '6210400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-23': {
      open: '91.6',
      high: '92.16',
      low: '90.68',
      close: '91.32',
      'adjusted close': '50.3781259781628',
      volume: '5065300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-22': {
      open: '91.27',
      high: '91.48',
      low: '90.28',
      close: '91.02',
      'adjusted close': '50.2126262213357',
      volume: '6223500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-19': {
      open: '92.86',
      high: '92.97',
      low: '91.51',
      close: '91.62',
      'adjusted close': '50.5436257349899',
      volume: '7058700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-18': {
      open: '93.05',
      high: '93.18',
      low: '91.9',
      close: '92.85',
      'adjusted close': '51.2221747379809',
      volume: '4605200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-17': {
      open: '92.57',
      high: '93.79',
      low: '92.45',
      close: '93.39',
      'adjusted close': '51.5200743002696',
      volume: '4663000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-16': {
      open: '92.4',
      high: '92.7',
      low: '91.42',
      close: '92.45',
      'adjusted close': '51.0015083955448',
      volume: '4627600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-15': {
      open: '92.6',
      high: '92.69',
      low: '90.88',
      close: '91.82',
      'adjusted close': '50.6539589062079',
      volume: '6523000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-12': {
      open: '92.0',
      high: '93.38',
      low: '91.68',
      close: '93.3',
      'adjusted close': '51.4704243732215',
      volume: '6290600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-11': {
      open: '92.0',
      high: '92.98',
      low: '91.15',
      close: '91.21',
      'adjusted close': '50.3174427339929',
      volume: '8560800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-10': {
      open: '94.38',
      high: '94.74',
      low: '92.68',
      close: '93.06',
      'adjusted close': '51.3380245677599',
      volume: '6536300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-09': {
      open: '94.3',
      high: '95.28',
      low: '93.77',
      close: '94.53',
      'adjusted close': '52.1489733762125',
      volume: '6390600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-08': {
      open: '96.49',
      high: '96.88',
      low: '94.59',
      close: '94.59',
      'adjusted close': '52.182073327578',
      volume: '5084200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-05': {
      open: '95.95',
      high: '96.98',
      low: '95.56',
      close: '96.45',
      'adjusted close': '53.2081718199058',
      volume: '3970900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-04': {
      open: '96.58',
      high: '96.92',
      low: '96.132',
      close: '96.39',
      'adjusted close': '53.1750718685404',
      volume: '3547900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-03': {
      open: '96.57',
      high: '96.89',
      low: '95.6',
      close: '96.84',
      'adjusted close': '53.423321503781',
      volume: '4806500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-02': {
      open: '97.6',
      high: '97.6',
      low: '96.62',
      close: '96.82',
      'adjusted close': '53.4122881866592',
      volume: '4976200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-03-01': {
      open: '96.5',
      high: '97.25',
      low: '96.15',
      close: '97.04',
      'adjusted close': '53.5336546749991',
      volume: '4454000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-27': {
      open: '96.8',
      high: '97.38',
      low: '96.1',
      close: '96.5',
      'adjusted close': '53.2357551127104',
      volume: '4715000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-26': {
      open: '96.27',
      high: '97.26',
      low: '96.25',
      close: '96.79',
      'adjusted close': '53.3957382109765',
      volume: '3965700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-25': {
      open: '96.5',
      high: '97.09',
      low: '96.23',
      close: '96.54',
      'adjusted close': '53.257821746954',
      volume: '3362500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-24': {
      open: '95.2',
      high: '97.46',
      low: '95.2',
      close: '96.79',
      'adjusted close': '53.3957382109765',
      volume: '7008300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-23': {
      open: '97.4',
      high: '97.51',
      low: '95.46',
      close: '95.96',
      'adjusted close': '52.9378555504216',
      volume: '6629100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-20': {
      open: '98.6',
      high: '98.6',
      low: '97.19',
      close: '97.31',
      'adjusted close': '53.6826044561435',
      volume: '5690200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-19': {
      open: '98.42',
      high: '99.23',
      low: '97.52',
      close: '97.8',
      'adjusted close': '53.9529207256277',
      volume: '5447200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-18': {
      open: '99.31',
      high: '99.77',
      low: '98.15',
      close: '98.42',
      'adjusted close': '54.2949535564037',
      volume: '4830300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-17': {
      open: '99.99',
      high: '100.0',
      low: '99.32',
      close: '99.37',
      'adjusted close': '54.8190361196894',
      volume: '3930400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-13': {
      open: '99.1',
      high: '100.09',
      low: '99.0828',
      close: '99.71',
      'adjusted close': '55.0066025107601',
      volume: '3562400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-12': {
      open: '100.06',
      high: '100.3',
      low: '99.3',
      close: '99.3',
      'adjusted close': '54.7804195097631',
      volume: '3611500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-11': {
      open: '99.2',
      high: '100.31',
      low: '98.8',
      close: '99.96',
      'adjusted close': '55.1445189747827',
      volume: '5505700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-10': {
      open: '98.45',
      high: '99.97',
      low: '98.41',
      close: '99.61',
      'adjusted close': '54.9514359251511',
      volume: '4057500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-09': {
      open: '99.31',
      high: '99.44',
      low: '98.6',
      close: '98.95',
      'adjusted close': '54.5873364601315',
      volume: '3742400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-06': {
      open: '98.85',
      high: '99.24',
      low: '98.25',
      close: '98.94',
      'adjusted close': '54.5818198015706',
      volume: '5516900',
      'dividend amount': '0.1600',
      'split coefficient': '1.0',
    },
    '2004-02-05': {
      open: '100.0',
      high: '100.09',
      low: '98.26',
      close: '98.86',
      'adjusted close': '54.4496337596697',
      volume: '5975000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-04': {
      open: '99.38',
      high: '100.43',
      low: '99.3',
      close: '100.19',
      'adjusted close': '55.1821647418704',
      volume: '8387500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-03': {
      open: '99.0',
      high: '100.0',
      low: '98.95',
      close: '100.0',
      'adjusted close': '55.0775174586989',
      volume: '5604300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-02-02': {
      open: '99.15',
      high: '99.94',
      low: '98.5',
      close: '99.39',
      'adjusted close': '54.7415446022008',
      volume: '6200000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-30': {
      open: '98.02',
      high: '99.33',
      low: '97.84',
      close: '99.23',
      'adjusted close': '54.6534205742669',
      volume: '6137600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-29': {
      open: '98.1',
      high: '98.6',
      low: '96.55',
      close: '98.01',
      'adjusted close': '53.9814748612708',
      volume: '6642500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-28': {
      open: '99.15',
      high: '99.42',
      low: '97.28',
      close: '97.38',
      'adjusted close': '53.634486501281',
      volume: '6549700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-27': {
      open: '99.4',
      high: '99.67',
      low: '98.7',
      close: '98.8',
      'adjusted close': '54.4165872491945',
      volume: '5345300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-26': {
      open: '97.9',
      high: '99.85',
      low: '97.56',
      close: '99.85',
      'adjusted close': '54.9949011825108',
      volume: '6189400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-23': {
      open: '97.82',
      high: '98.21',
      low: '97.1',
      close: '97.9',
      'adjusted close': '53.9208895920662',
      volume: '5283900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-22': {
      open: '97.84',
      high: '98.16',
      low: '97.32',
      close: '97.51',
      'adjusted close': '53.7060872739773',
      volume: '4371600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-21': {
      open: '97.23',
      high: '98.04',
      low: '96.64',
      close: '97.7',
      'adjusted close': '53.8107345571488',
      volume: '6949000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-20': {
      open: '96.0',
      high: '97.44',
      low: '95.73',
      close: '97.1',
      'adjusted close': '53.4802694523966',
      volume: '9285100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-16': {
      open: '95.0',
      high: '95.35',
      low: '94.71',
      close: '95.32',
      'adjusted close': '52.4998896416318',
      volume: '9302000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-15': {
      open: '95.07',
      high: '95.65',
      low: '93.55',
      close: '94.02',
      'adjusted close': '51.7838819146687',
      volume: '19812000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-14': {
      open: '89.9',
      high: '90.46',
      low: '89.75',
      close: '90.31',
      'adjusted close': '49.740506016951',
      volume: '5505100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-13': {
      open: '91.45',
      high: '91.51',
      low: '89.01',
      close: '89.7',
      'adjusted close': '49.4045331604529',
      volume: '8842500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-12': {
      open: '91.21',
      high: '92.14',
      low: '91.21',
      close: '91.55',
      'adjusted close': '50.4234672334388',
      volume: '5130400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-09': {
      open: '91.75',
      high: '92.35',
      low: '91.0',
      close: '91.21',
      'adjusted close': '50.2362036740793',
      volume: '7930900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-08': {
      open: '93.21',
      high: '93.21',
      low: '92.031',
      close: '93.04',
      'adjusted close': '51.2441222435735',
      volume: '6179800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-07': {
      open: '93.14',
      high: '93.38',
      low: '92.47',
      close: '92.78',
      'adjusted close': '51.1009206981808',
      volume: '4927600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-06': {
      open: '92.2',
      high: '93.19',
      low: '92.14',
      close: '93.06',
      'adjusted close': '51.2551377470652',
      volume: '4380000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-05': {
      open: '92.0',
      high: '93.09',
      low: '92.0',
      close: '93.05',
      'adjusted close': '51.2496299953193',
      volume: '5276300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2004-01-02': {
      open: '92.86',
      high: '93.05',
      low: '91.2',
      close: '91.55',
      'adjusted close': '50.4234672334388',
      volume: '5327800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-31': {
      open: '92.66',
      high: '92.85',
      low: '92.16',
      close: '92.68',
      'adjusted close': '51.0458431807221',
      volume: '4726900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-30': {
      open: '93.27',
      high: '93.5',
      low: '92.36',
      close: '92.63',
      'adjusted close': '51.0183044219928',
      volume: '4003400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-29': {
      open: '93.1',
      high: '93.73',
      low: '93.03',
      close: '93.52',
      'adjusted close': '51.5084943273752',
      volume: '4033900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-26': {
      open: '92.37',
      high: '93.0',
      low: '92.3',
      close: '92.9',
      'adjusted close': '51.1670137191313',
      volume: '1408500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-24': {
      open: '92.8',
      high: '92.8',
      low: '92.18',
      close: '92.27',
      'adjusted close': '50.8200253591415',
      volume: '1760500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-23': {
      open: '93.07',
      high: '93.44',
      low: '92.34',
      close: '92.79',
      'adjusted close': '51.1064284499267',
      volume: '3677000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-22': {
      open: '92.83',
      high: '93.5',
      low: '92.78',
      close: '93.39',
      'adjusted close': '51.4368935546789',
      volume: '4332100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-19': {
      open: '93.23',
      high: '93.25',
      low: '92.67',
      close: '93.14',
      'adjusted close': '51.2991997610321',
      volume: '7090500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-18': {
      open: '93.0',
      high: '93.38',
      low: '92.5',
      close: '92.73',
      'adjusted close': '51.0733819394515',
      volume: '6873600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-17': {
      open: '93.85',
      high: '93.87',
      low: '92.85',
      close: '93.4',
      'adjusted close': '51.4424013064248',
      volume: '6052900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-16': {
      open: '92.11',
      high: '94.12',
      low: '91.88',
      close: '93.98',
      'adjusted close': '51.7618509076852',
      volume: '7501100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-15': {
      open: '93.43',
      high: '93.97',
      low: '92.08',
      close: '92.11',
      'adjusted close': '50.7319013312075',
      volume: '5964500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-12': {
      open: '92.7',
      high: '93.16',
      low: '92.24',
      close: '92.71',
      'adjusted close': '51.0623664359597',
      volume: '5079800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-11': {
      open: '91.96',
      high: '92.97',
      low: '91.92',
      close: '92.4',
      'adjusted close': '50.8916261318378',
      volume: '6412100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-10': {
      open: '90.69',
      high: '92.74',
      low: '90.65',
      close: '91.73',
      'adjusted close': '50.5226067648645',
      volume: '7138000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-09': {
      open: '91.15',
      high: '92.16',
      low: '90.61',
      close: '90.63',
      'adjusted close': '49.9167540728188',
      volume: '6971500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-08': {
      open: '90.52',
      high: '91.47',
      low: '90.52',
      close: '91.2',
      'adjusted close': '50.2306959223334',
      volume: '5342000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-05': {
      open: '91.3',
      high: '91.3',
      low: '90.5',
      close: '90.64',
      'adjusted close': '49.9222618245647',
      volume: '5878400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-04': {
      open: '90.05',
      high: '91.44',
      low: '90.03',
      close: '91.42',
      'adjusted close': '50.3518664607425',
      volume: '5360400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-03': {
      open: '91.15',
      high: '91.44',
      low: '90.3',
      close: '90.3',
      'adjusted close': '49.7349982652051',
      volume: '6169800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-02': {
      open: '90.57',
      high: '91.48',
      low: '90.52',
      close: '90.75',
      'adjusted close': '49.9828470937692',
      volume: '5091800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-12-01': {
      open: '90.9',
      high: '91.36',
      low: '90.48',
      close: '91.01',
      'adjusted close': '50.1260486391619',
      volume: '5234900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-28': {
      open: '89.9',
      high: '90.68',
      low: '89.62',
      close: '90.54',
      'adjusted close': '49.867184307106',
      volume: '2195500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-26': {
      open: '89.79',
      high: '90.09',
      low: '89.11',
      close: '89.91',
      'adjusted close': '49.5201959471162',
      volume: '4146800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-25': {
      open: '89.66',
      high: '89.85',
      low: '89.27',
      close: '89.43',
      'adjusted close': '49.2558238633144',
      volume: '4305700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-24': {
      open: '89.03',
      high: '89.74',
      low: '88.95',
      close: '89.66',
      'adjusted close': '49.3825021534694',
      volume: '4722200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-21': {
      open: '88.4',
      high: '88.85',
      low: '88.23',
      close: '88.63',
      'adjusted close': '48.8152037236448',
      volume: '5935400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-20': {
      open: '89.3',
      high: '89.79',
      low: '88.38',
      close: '88.39',
      'adjusted close': '48.6830176817439',
      volume: '5111000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-19': {
      open: '88.32',
      high: '89.83',
      low: '88.32',
      close: '89.4',
      'adjusted close': '49.2393006080768',
      volume: '4156600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-18': {
      open: '89.92',
      high: '90.18',
      low: '88.9',
      close: '88.95',
      'adjusted close': '48.9914517795127',
      volume: '5284300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-17': {
      open: '89.9',
      high: '90.05',
      low: '89.03',
      close: '89.8',
      'adjusted close': '49.4596106779116',
      volume: '4748200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-14': {
      open: '91.02',
      high: '91.42',
      low: '89.81',
      close: '90.25',
      'adjusted close': '49.7074595064758',
      volume: '4510200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-13': {
      open: '90.4',
      high: '91.48',
      low: '90.16',
      close: '91.09',
      'adjusted close': '50.1701106531288',
      volume: '5277900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-12': {
      open: '89.45',
      high: '91.16',
      low: '89.31',
      close: '90.69',
      'adjusted close': '49.949800583294',
      volume: '5901700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-11': {
      open: '90.0',
      high: '90.03',
      low: '88.89',
      close: '89.36',
      'adjusted close': '49.2172696010933',
      volume: '4841200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-10': {
      open: '89.55',
      high: '90.47',
      low: '89.55',
      close: '89.95',
      'adjusted close': '49.5422269540997',
      volume: '8349700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-07': {
      open: '89.4',
      high: '89.6',
      low: '88.26',
      close: '88.26',
      'adjusted close': '48.6114169090476',
      volume: '5543500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-06': {
      open: '88.33',
      high: '89.59',
      low: '87.72',
      close: '89.34',
      'adjusted close': '49.2062540976016',
      volume: '5668600',
      'dividend amount': '0.1600',
      'split coefficient': '1.0',
    },
    '2003-11-05': {
      open: '88.52',
      high: '88.88',
      low: '88.0',
      close: '88.49',
      'adjusted close': '48.6509656435393',
      volume: '6296300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-04': {
      open: '89.68',
      high: '89.8',
      low: '88.86',
      close: '89.14',
      'adjusted close': '49.008329500114',
      volume: '5146100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-11-03': {
      open: '89.9',
      high: '90.84',
      low: '89.48',
      close: '89.68',
      'adjusted close': '49.3052163963454',
      volume: '5825200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-31': {
      open: '90.71',
      high: '90.71',
      low: '89.2',
      close: '89.48',
      'adjusted close': '49.1952582866301',
      volume: '4836200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-30': {
      open: '90.0',
      high: '90.17',
      low: '89.06',
      close: '89.12',
      'adjusted close': '48.9973336891425',
      volume: '6535700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-29': {
      open: '89.6',
      high: '89.94',
      low: '89.15',
      close: '89.34',
      'adjusted close': '49.1182876098293',
      volume: '5180700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-28': {
      open: '89.05',
      high: '89.9',
      low: '88.52',
      close: '89.9',
      'adjusted close': '49.4261703170322',
      volume: '6959000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-27': {
      open: '88.8',
      high: '89.1',
      low: '88.05',
      close: '88.52',
      'adjusted close': '48.6674593599966',
      volume: '5099200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-24': {
      open: '88.15',
      high: '88.64',
      low: '87.72',
      close: '88.42',
      'adjusted close': '48.6124803051389',
      volume: '5471700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-23': {
      open: '87.6',
      high: '88.4',
      low: '87.53',
      close: '88.15',
      'adjusted close': '48.4640368570232',
      volume: '6046400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-22': {
      open: '88.3',
      high: '89.01',
      low: '87.9',
      close: '88.46',
      'adjusted close': '48.634471927082',
      volume: '5600100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-21': {
      open: '89.25',
      high: '89.34',
      low: '88.7',
      close: '88.93',
      'adjusted close': '48.892873484913',
      volume: '5287100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-20': {
      open: '89.35',
      high: '89.4',
      low: '88.86',
      close: '89.0',
      'adjusted close': '48.9313588233133',
      volume: '6971300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-17': {
      open: '89.28',
      high: '89.68',
      low: '88.81',
      close: '89.23',
      'adjusted close': '49.0578106494859',
      volume: '9506200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-16': {
      open: '89.27',
      high: '89.7',
      low: '88.66',
      close: '89.28',
      'adjusted close': '49.0853001769147',
      volume: '16994000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-15': {
      open: '93.9',
      high: '94.54',
      low: '92.74',
      close: '92.74',
      'adjusted close': '50.9875754749896',
      volume: '11558700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-14': {
      open: '92.65',
      high: '92.95',
      low: '92.32',
      close: '92.72',
      'adjusted close': '50.9765796640181',
      volume: '4699500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-13': {
      open: '93.1',
      high: '93.54',
      low: '92.58',
      close: '93.2',
      'adjusted close': '51.2404791273348',
      volume: '4839200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-10': {
      open: '92.4',
      high: '93.18',
      low: '92.36',
      close: '92.67',
      'adjusted close': '50.9490901365893',
      volume: '4500500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-09': {
      open: '93.3',
      high: '93.6',
      low: '92.2',
      close: '92.45',
      'adjusted close': '50.8281362159024',
      volume: '7593000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-08': {
      open: '92.0',
      high: '92.87',
      low: '91.95',
      close: '92.66',
      'adjusted close': '50.9435922311035',
      volume: '6418900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-07': {
      open: '90.5',
      high: '91.7',
      low: '90.4',
      close: '91.7',
      'adjusted close': '50.41579330447',
      volume: '5369800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-06': {
      open: '90.74',
      high: '91.76',
      low: '90.51',
      close: '91.18',
      'adjusted close': '50.1299022192102',
      volume: '3845400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-03': {
      open: '91.0',
      high: '91.95',
      low: '90.57',
      close: '90.64',
      'adjusted close': '49.8330153229789',
      volume: '7479300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-02': {
      open: '89.55',
      high: '90.45',
      low: '89.22',
      close: '90.08',
      'adjusted close': '49.525132615776',
      volume: '7428300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-10-01': {
      open: '88.75',
      high: '90.6',
      low: '88.7',
      close: '90.35',
      'adjusted close': '49.6735760638917',
      volume: '7925200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-30': {
      open: '89.2',
      high: '89.25',
      low: '87.9',
      close: '88.33',
      'adjusted close': '48.562999155767',
      volume: '7056700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-29': {
      open: '89.25',
      high: '90.18',
      low: '89.07',
      close: '89.45',
      'adjusted close': '49.1787645701728',
      volume: '5723200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-26': {
      open: '89.41',
      high: '90.14',
      low: '89.0',
      close: '89.05',
      'adjusted close': '48.9588483507421',
      volume: '5095800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-25': {
      open: '89.4',
      high: '90.97',
      low: '89.2',
      close: '89.41',
      'adjusted close': '49.1567729482297',
      volume: '6585500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-24': {
      open: '91.08',
      high: '91.82',
      low: '89.33',
      close: '89.4',
      'adjusted close': '49.1512750427439',
      volume: '7575800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-23': {
      open: '91.5',
      high: '92.09',
      low: '90.56',
      close: '91.34',
      'adjusted close': '50.2178687069825',
      volume: '7935200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-22': {
      open: '92.24',
      high: '92.24',
      low: '90.9',
      close: '91.39',
      'adjusted close': '50.2453582344113',
      volume: '7302600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-19': {
      open: '92.45',
      high: '93.47',
      low: '91.8',
      close: '93.28',
      'adjusted close': '51.284462371221',
      volume: '9412500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-18': {
      open: '90.63',
      high: '92.43',
      low: '90.46',
      close: '92.0',
      'adjusted close': '50.580730469043',
      volume: '8338700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-17': {
      open: '90.4',
      high: '91.94',
      low: '90.2',
      close: '90.63',
      'adjusted close': '49.8275174174931',
      volume: '9086600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-16': {
      open: '88.49',
      high: '90.41',
      low: '88.49',
      close: '90.29',
      'adjusted close': '49.6405886309771',
      volume: '7896100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-15': {
      open: '89.7',
      high: '89.96',
      low: '88.39',
      close: '88.49',
      'adjusted close': '48.6509656435393',
      volume: '7305400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-12': {
      open: '87.6',
      high: '89.01',
      low: '87.2',
      close: '88.7',
      'adjusted close': '48.7664216587403',
      volume: '7209200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-11': {
      open: '86.75',
      high: '88.42',
      low: '86.4',
      close: '87.92',
      'adjusted close': '48.3375850308506',
      volume: '9753500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-10': {
      open: '88.99',
      high: '89.0',
      low: '87.61',
      close: '87.84',
      'adjusted close': '48.2936017869645',
      volume: '7322600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-09': {
      open: '89.2',
      high: '89.97',
      low: '88.93',
      close: '89.42',
      'adjusted close': '49.1622708537155',
      volume: '8102500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-08': {
      open: '88.45',
      high: '89.66',
      low: '88.35',
      close: '89.1',
      'adjusted close': '48.986337878171',
      volume: '9213400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-05': {
      open: '87.0',
      high: '88.26',
      low: '86.75',
      close: '86.95',
      'adjusted close': '47.8042881987314',
      volume: '7896600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-04': {
      open: '86.33',
      high: '88.29',
      low: '85.69',
      close: '87.91',
      'adjusted close': '48.3320871253649',
      volume: '9889100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-03': {
      open: '86.24',
      high: '87.3',
      low: '86.0',
      close: '86.33',
      'adjusted close': '47.4634180586139',
      volume: '10835700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-09-02': {
      open: '82.4',
      high: '85.96',
      low: '82.3',
      close: '85.76',
      'adjusted close': '47.1500374459253',
      volume: '12406400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-29': {
      open: '81.95',
      high: '82.11',
      low: '81.56',
      close: '82.01',
      'adjusted close': '45.0883228887632',
      volume: '4222700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-28': {
      open: '82.0',
      high: '82.34',
      low: '81.27',
      close: '81.9',
      'adjusted close': '45.0278459284198',
      volume: '5516300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-27': {
      open: '82.0',
      high: '82.53',
      low: '81.9',
      close: '82.0',
      'adjusted close': '45.0828249832774',
      volume: '3834100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-26': {
      open: '81.65',
      high: '82.54',
      low: '81.27',
      close: '82.5',
      'adjusted close': '45.3577202575657',
      volume: '5569600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-25': {
      open: '82.85',
      high: '83.03',
      low: '81.75',
      close: '81.96',
      'adjusted close': '45.0608333613344',
      volume: '5150900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-22': {
      open: '84.25',
      high: '84.72',
      low: '82.96',
      close: '82.97',
      'adjusted close': '45.6161218153967',
      volume: '7434900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-21': {
      open: '83.39',
      high: '83.85',
      low: '82.85',
      close: '82.85',
      'adjusted close': '45.5501469495675',
      volume: '5185200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-20': {
      open: '82.0',
      high: '83.31',
      low: '82.0',
      close: '83.05',
      'adjusted close': '45.6601050592828',
      volume: '4829900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-19': {
      open: '83.1',
      high: '83.81',
      low: '82.56',
      close: '82.85',
      'adjusted close': '45.5501469495675',
      volume: '6318300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-18': {
      open: '82.05',
      high: '83.68',
      low: '81.9',
      close: '83.52',
      'adjusted close': '45.9185066171138',
      volume: '6847200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-15': {
      open: '81.8',
      high: '81.98',
      low: '81.2',
      close: '81.79',
      'adjusted close': '44.9673689680764',
      volume: '3623600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-14': {
      open: '81.0',
      high: '81.75',
      low: '80.69',
      close: '81.56',
      'adjusted close': '44.8409171419038',
      volume: '4562700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-13': {
      open: '81.65',
      high: '82.19',
      low: '80.58',
      close: '81.2',
      'adjusted close': '44.6429925444162',
      volume: '5138400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-12': {
      open: '81.45',
      high: '81.54',
      low: '80.65',
      close: '81.51',
      'adjusted close': '44.8134276144749',
      volume: '4634200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-11': {
      open: '80.87',
      high: '81.5',
      low: '80.28',
      close: '81.02',
      'adjusted close': '44.5440302456724',
      volume: '4261200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-08': {
      open: '81.05',
      high: '81.27',
      low: '80.35',
      close: '80.88',
      'adjusted close': '44.4670595688717',
      volume: '4613800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-07': {
      open: '79.69',
      high: '80.82',
      low: '79.38',
      close: '80.69',
      'adjusted close': '44.3625993646422',
      volume: '5298500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-06': {
      open: '79.7',
      high: '80.68',
      low: '78.73',
      close: '79.75',
      'adjusted close': '43.8457962489802',
      volume: '7472200',
      'dividend amount': '0.1600',
      'split coefficient': '1.0',
    },
    '2003-08-05': {
      open: '81.13',
      high: '81.33',
      low: '79.8',
      close: '79.85',
      'adjusted close': '43.8128748652367',
      volume: '7018100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-04': {
      open: '80.75',
      high: '81.36',
      low: '80.06',
      close: '81.13',
      'adjusted close': '44.5151977184303',
      volume: '5888000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-08-01': {
      open: '81.15',
      high: '81.27',
      low: '80.05',
      close: '81.27',
      'adjusted close': '44.5920142804983',
      volume: '6475400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-31': {
      open: '81.8',
      high: '82.54',
      low: '81.1',
      close: '81.25',
      'adjusted close': '44.5810404859172',
      volume: '8050300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-30': {
      open: '82.45',
      high: '82.45',
      low: '80.58',
      close: '80.96',
      'adjusted close': '44.4219204644905',
      volume: '6654600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-29': {
      open: '82.55',
      high: '82.88',
      low: '81.6',
      close: '81.8',
      'adjusted close': '44.8828198368988',
      volume: '7282700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-28': {
      open: '83.67',
      high: '83.7',
      low: '82.04',
      close: '82.54',
      'adjusted close': '45.2888502364013',
      volume: '6423500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-25': {
      open: '81.6',
      high: '83.74',
      low: '81.57',
      close: '83.55',
      'adjusted close': '45.8430268627493',
      volume: '6204700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-24': {
      open: '82.95',
      high: '82.99',
      low: '81.51',
      close: '81.51',
      'adjusted close': '44.7236998154721',
      volume: '5859100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-23': {
      open: '82.0',
      high: '82.77',
      low: '81.73',
      close: '82.3',
      'adjusted close': '45.1571647014275',
      volume: '5326800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-22': {
      open: '82.5',
      high: '83.26',
      low: '81.52',
      close: '81.85',
      'adjusted close': '44.9102543233516',
      volume: '8744300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-21': {
      open: '83.2',
      high: '83.43',
      low: '82.21',
      close: '82.5',
      'adjusted close': '45.266902647239',
      volume: '7036100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-18': {
      open: '83.85',
      high: '84.02',
      low: '83.21',
      close: '83.72',
      'adjusted close': '45.936304116689',
      volume: '8567200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-17': {
      open: '82.7',
      high: '83.94',
      low: '82.5',
      close: '83.33',
      'adjusted close': '45.7223151223566',
      volume: '13515700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-16': {
      open: '87.0',
      high: '87.03',
      low: '85.85',
      close: '86.74',
      'adjusted close': '47.5933470984425',
      volume: '8837600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-15': {
      open: '86.15',
      high: '87.0',
      low: '85.82',
      close: '86.44',
      'adjusted close': '47.4287401797253',
      volume: '7910400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-14': {
      open: '86.0',
      high: '86.58',
      low: '85.42',
      close: '85.42',
      'adjusted close': '46.8690766560867',
      volume: '6455200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-11': {
      open: '84.1',
      high: '85.28',
      low: '83.7',
      close: '84.89',
      'adjusted close': '46.5782710996862',
      volume: '4534700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-10': {
      open: '85.0',
      high: '85.22',
      low: '83.47',
      close: '84.03',
      'adjusted close': '46.1063979326969',
      volume: '7509200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-09': {
      open: '86.1',
      high: '86.35',
      low: '84.85',
      close: '85.47',
      'adjusted close': '46.8965111425396',
      volume: '6709700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-08': {
      open: '86.09',
      high: '86.75',
      low: '85.19',
      close: '86.25',
      'adjusted close': '47.3244891312044',
      volume: '5873900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-07': {
      open: '84.8',
      high: '86.45',
      low: '84.74',
      close: '86.09',
      'adjusted close': '47.2366987745552',
      volume: '7614900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-03': {
      open: '84.25',
      high: '84.69',
      low: '83.48',
      close: '83.95',
      'adjusted close': '46.0625027543723',
      volume: '3009500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-02': {
      open: '83.95',
      high: '84.89',
      low: '83.75',
      close: '84.74',
      'adjusted close': '46.4959676403276',
      volume: '6009700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-07-01': {
      open: '81.4',
      high: '83.66',
      low: '81.4',
      close: '83.59',
      'adjusted close': '45.8649744519116',
      volume: '5785800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-30': {
      open: '83.9',
      high: '84.2',
      low: '82.5',
      close: '82.5',
      'adjusted close': '45.266902647239',
      volume: '7950900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-27': {
      open: '84.15',
      high: '84.65',
      low: '83.22',
      close: '83.42',
      'adjusted close': '45.7716971979718',
      volume: '4532900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-26': {
      open: '83.7',
      high: '84.38',
      low: '82.82',
      close: '84.35',
      'adjusted close': '46.2819786459952',
      volume: '6374400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-25': {
      open: '83.55',
      high: '84.16',
      low: '82.09',
      close: '82.49',
      'adjusted close': '45.2614157499484',
      volume: '5548000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-24': {
      open: '82.95',
      high: '84.18',
      low: '82.85',
      close: '83.63',
      'adjusted close': '45.8869220410739',
      volume: '5062800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-23': {
      open: '84.79',
      high: '84.79',
      low: '82.97',
      close: '83.18',
      'adjusted close': '45.640011662998',
      volume: '6311600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-20': {
      open: '85.0',
      high: '85.24',
      low: '84.69',
      close: '84.92',
      'adjusted close': '46.594731791558',
      volume: '11900000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-19': {
      open: '84.95',
      high: '85.29',
      low: '84.5',
      close: '84.55',
      'adjusted close': '46.3917165918067',
      volume: '7277100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-18': {
      open: '84.15',
      high: '84.99',
      low: '83.22',
      close: '84.7',
      'adjusted close': '46.4740200511653',
      volume: '5941400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-17': {
      open: '84.95',
      high: '84.95',
      low: '83.8',
      close: '84.3',
      'adjusted close': '46.2545441595424',
      volume: '5873700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-16': {
      open: '83.1',
      high: '84.55',
      low: '82.55',
      close: '84.5',
      'adjusted close': '46.3642821053538',
      volume: '6492100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-13': {
      open: '84.15',
      high: '84.29',
      low: '82.51',
      close: '82.75',
      'adjusted close': '45.4040750795033',
      volume: '5727500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-12': {
      open: '84.8',
      high: '84.98',
      low: '83.55',
      close: '83.95',
      'adjusted close': '46.0625027543723',
      volume: '7072400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-11': {
      open: '82.75',
      high: '84.15',
      low: '82.29',
      close: '83.97',
      'adjusted close': '46.0734765489534',
      volume: '9815800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-10': {
      open: '82.35',
      high: '82.92',
      low: '81.21',
      close: '81.71',
      'adjusted close': '44.8334377612836',
      volume: '8896000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-09': {
      open: '80.0',
      high: '82.0',
      low: '79.81',
      close: '82.0',
      'adjusted close': '44.9925577827102',
      volume: '9756300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-06': {
      open: '82.25',
      high: '83.23',
      low: '79.84',
      close: '80.05',
      'adjusted close': '43.9226128110482',
      volume: '15160900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-05': {
      open: '84.0',
      high: '84.25',
      low: '81.75',
      close: '81.9',
      'adjusted close': '44.9376888098045',
      volume: '15256300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-04': {
      open: '84.0',
      high: '84.96',
      low: '83.83',
      close: '84.25',
      'adjusted close': '46.2271096730895',
      volume: '10734400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-03': {
      open: '84.32',
      high: '85.24',
      low: '83.24',
      close: '83.82',
      'adjusted close': '45.9911730895948',
      volume: '21106600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-06-02': {
      open: '89.0',
      high: '89.08',
      low: '87.25',
      close: '87.33',
      'adjusted close': '47.9170740385864',
      volume: '7697200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-30': {
      open: '87.45',
      high: '88.68',
      low: '87.45',
      close: '88.04',
      'adjusted close': '48.3066437462172',
      volume: '6184200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-29': {
      open: '87.57',
      high: '88.59',
      low: '87.2',
      close: '87.36',
      'adjusted close': '47.9335347304581',
      volume: '6898900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-28': {
      open: '87.6',
      high: '88.7',
      low: '87.4',
      close: '87.57',
      'adjusted close': '48.0487595735602',
      volume: '6539900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-27': {
      open: '84.51',
      high: '87.9',
      low: '84.51',
      close: '87.69',
      'adjusted close': '48.1146023410471',
      volume: '7163400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-23': {
      open: '85.75',
      high: '85.95',
      low: '85.1',
      close: '85.26',
      'adjusted close': '46.7812862994375',
      volume: '5187200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-22': {
      open: '86.25',
      high: '86.8',
      low: '85.8',
      close: '86.08',
      'adjusted close': '47.2312118772646',
      volume: '6277300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-21': {
      open: '85.77',
      high: '86.18',
      low: '85.08',
      close: '86.18',
      'adjusted close': '47.2860808501704',
      volume: '6741000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-20': {
      open: '86.58',
      high: '87.24',
      low: '84.89',
      close: '85.77',
      'adjusted close': '47.0611180612568',
      volume: '7331900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-19': {
      open: '88.05',
      high: '88.47',
      low: '86.41',
      close: '86.45',
      'adjusted close': '47.4342270770159',
      volume: '7298400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-16': {
      open: '89.67',
      high: '89.89',
      low: '88.95',
      close: '88.99',
      'adjusted close': '48.8278989888218',
      volume: '6831600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-15': {
      open: '89.5',
      high: '89.96',
      low: '89.0',
      close: '89.9',
      'adjusted close': '49.327206642264',
      volume: '7102900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-14': {
      open: '90.0',
      high: '90.4',
      low: '88.3',
      close: '88.7',
      'adjusted close': '48.6687789673951',
      volume: '7752200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-13': {
      open: '88.78',
      high: '90.1',
      low: '88.56',
      close: '89.98',
      'adjusted close': '49.3711018205886',
      volume: '8668300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-12': {
      open: '87.55',
      high: '89.26',
      low: '87.1',
      close: '89.0',
      'adjusted close': '48.8333858861123',
      volume: '8445700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-09': {
      open: '86.9',
      high: '87.7',
      low: '86.4',
      close: '87.55',
      'adjusted close': '48.037785778979',
      volume: '6222600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-08': {
      open: '86.37',
      high: '86.78',
      low: '85.75',
      close: '86.05',
      'adjusted close': '47.2147511853929',
      volume: '7188200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-07': {
      open: '86.95',
      high: '87.81',
      low: '86.5',
      close: '86.68',
      'adjusted close': '47.5604257146991',
      volume: '6754600',
      'dividend amount': '0.1600',
      'split coefficient': '1.0',
    },
    '2003-05-06': {
      open: '86.3',
      high: '88.34',
      low: '86.0',
      close: '87.51',
      'adjusted close': '47.9273705008443',
      volume: '7459600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-05': {
      open: '87.2',
      high: '88.0',
      low: '86.52',
      close: '86.52',
      'adjusted close': '47.3851685034058',
      volume: '7632500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-02': {
      open: '84.45',
      high: '87.57',
      low: '84.45',
      close: '87.57',
      'adjusted close': '47.9602312279617',
      volume: '8381900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-05-01': {
      open: '85.3',
      high: '86.23',
      low: '84.47',
      close: '85.89',
      'adjusted close': '47.0401308686723',
      volume: '7485700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-30': {
      open: '85.66',
      high: '85.67',
      low: '84.68',
      close: '84.9',
      'adjusted close': '46.4979288712339',
      volume: '8444900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-29': {
      open: '85.34',
      high: '86.49',
      low: '84.97',
      close: '85.66',
      'adjusted close': '46.9141647480553',
      volume: '8682900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-28': {
      open: '84.05',
      high: '85.5',
      low: '83.62',
      close: '85.12',
      'adjusted close': '46.618418203998',
      volume: '6363600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-25': {
      open: '85.21',
      high: '85.28',
      low: '83.86',
      close: '83.88',
      'adjusted close': '45.9392965102367',
      volume: '6790700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-24': {
      open: '84.65',
      high: '85.7',
      low: '84.63',
      close: '85.2',
      'adjusted close': '46.6622325068213',
      volume: '5943500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-23': {
      open: '85.24',
      high: '85.97',
      low: '84.96',
      close: '85.75',
      'adjusted close': '46.9634558387315',
      volume: '7084500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-22': {
      open: '83.36',
      high: '86.06',
      low: '82.6',
      close: '85.63',
      'adjusted close': '46.8977343844966',
      volume: '9542600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-21': {
      open: '84.4',
      high: '84.51',
      low: '83.1',
      close: '83.36',
      'adjusted close': '45.6545035418852',
      volume: '6703400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-17': {
      open: '81.89',
      high: '84.4',
      low: '81.89',
      close: '84.26',
      'adjusted close': '46.1474144486474',
      volume: '8802100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-16': {
      open: '83.38',
      high: '84.4',
      low: '82.74',
      close: '82.88',
      'adjusted close': '45.3916177249454',
      volume: '10790700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-15': {
      open: '80.07',
      high: '82.85',
      low: '79.71',
      close: '82.79',
      'adjusted close': '45.3423266342692',
      volume: '13613500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-14': {
      open: '78.5',
      high: '80.07',
      low: '78.16',
      close: '80.07',
      'adjusted close': '43.8526403382768',
      volume: '9006000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-11': {
      open: '80.1',
      high: '81.29',
      low: '78.45',
      close: '78.75',
      'adjusted close': '43.1297043416922',
      volume: '7573500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-10': {
      open: '79.1',
      high: '79.25',
      low: '78.13',
      close: '79.01',
      'adjusted close': '43.272100825868',
      volume: '6880100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-09': {
      open: '79.7',
      high: '80.74',
      low: '78.63',
      close: '78.71',
      'adjusted close': '43.1077971902806',
      volume: '8543200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-08': {
      open: '80.62',
      high: '80.7',
      low: '78.47',
      close: '80.07',
      'adjusted close': '43.8526403382768',
      volume: '8057600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-07': {
      open: '82.6',
      high: '82.9',
      low: '80.25',
      close: '80.47',
      'adjusted close': '44.0717118523933',
      volume: '8881400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-04': {
      open: '82.35',
      high: '82.55',
      low: '80.04',
      close: '80.79',
      'adjusted close': '44.2469690636865',
      volume: '8985000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-03': {
      open: '82.32',
      high: '83.48',
      low: '81.71',
      close: '81.91',
      'adjusted close': '44.8603693032128',
      volume: '9623600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-02': {
      open: '79.9',
      high: '81.97',
      low: '79.9',
      close: '81.46',
      'adjusted close': '44.6139138498317',
      volume: '9700100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-04-01': {
      open: '78.5',
      high: '79.56',
      low: '78.12',
      close: '78.73',
      'adjusted close': '43.1187507659864',
      volume: '7861400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-31': {
      open: '79.26',
      high: '79.61',
      low: '78.31',
      close: '78.43',
      'adjusted close': '42.954447130399',
      volume: '10169200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-28': {
      open: '81.05',
      high: '81.99',
      low: '80.5',
      close: '80.85',
      'adjusted close': '44.279829790804',
      volume: '6649200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-27': {
      open: '80.75',
      high: '82.33',
      low: '80.51',
      close: '81.45',
      'adjusted close': '44.6084370619788',
      volume: '7265800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-26': {
      open: '83.0',
      high: '83.25',
      low: '81.18',
      close: '81.55',
      'adjusted close': '44.6632049405079',
      volume: '10531600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-25': {
      open: '82.0',
      high: '84.0',
      low: '81.74',
      close: '83.45',
      'adjusted close': '45.7037946325615',
      volume: '8777100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-24': {
      open: '82.46',
      high: '83.35',
      low: '82.23',
      close: '82.25',
      'adjusted close': '45.0465800902119',
      volume: '9352600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-21': {
      open: '82.76',
      high: '84.9',
      low: '82.76',
      close: '84.9',
      'adjusted close': '46.4979288712339',
      volume: '12625400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-20': {
      open: '81.71',
      high: '82.49',
      low: '80.64',
      close: '82.2',
      'adjusted close': '45.0191961509473',
      volume: '9046400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-19': {
      open: '82.09',
      high: '82.5',
      low: '81.08',
      close: '82.0',
      'adjusted close': '44.909660393889',
      volume: '10473100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-18': {
      open: '82.46',
      high: '83.5',
      low: '81.49',
      close: '82.47',
      'adjusted close': '45.167069422976',
      volume: '10795400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-17': {
      open: '78.0',
      high: '82.67',
      low: '77.84',
      close: '82.46',
      'adjusted close': '45.161592635123',
      volume: '15193000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-14': {
      open: '78.45',
      high: '79.48',
      low: '78.11',
      close: '79.0',
      'adjusted close': '43.266624038015',
      volume: '10117700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-13': {
      open: '75.99',
      high: '78.68',
      low: '75.31',
      close: '78.45',
      'adjusted close': '42.9654007061048',
      volume: '10452500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-12': {
      open: '75.25',
      high: '75.63',
      low: '73.17',
      close: '75.18',
      'adjusted close': '41.1744910782022',
      volume: '12773700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-11': {
      open: '75.82',
      high: '76.33',
      low: '75.2',
      close: '75.35',
      'adjusted close': '41.2675964717017',
      volume: '8119200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-10': {
      open: '77.45',
      high: '77.45',
      low: '75.5',
      close: '75.7',
      'adjusted close': '41.4592840465537',
      volume: '6641300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-07': {
      open: '75.71',
      high: '77.99',
      low: '75.71',
      close: '77.9',
      'adjusted close': '42.6641773741946',
      volume: '8129200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-06': {
      open: '77.0',
      high: '77.78',
      low: '76.7',
      close: '77.07',
      'adjusted close': '42.2096039824028',
      volume: '5876300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-05': {
      open: '76.7',
      high: '77.73',
      low: '76.25',
      close: '77.73',
      'adjusted close': '42.5710719806951',
      volume: '6658000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-04': {
      open: '77.6',
      high: '77.75',
      low: '76.53',
      close: '76.7',
      'adjusted close': '42.006962831845',
      volume: '5672200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-03-03': {
      open: '78.9',
      high: '79.0',
      low: '77.12',
      close: '77.33',
      'adjusted close': '42.3520004665785',
      volume: '6618300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-28': {
      open: '77.0',
      high: '78.47',
      low: '77.0',
      close: '77.95',
      'adjusted close': '42.6915613134592',
      volume: '6585200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-27': {
      open: '77.9',
      high: '78.59',
      low: '76.75',
      close: '77.28',
      'adjusted close': '42.324616527314',
      volume: '9926500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-26': {
      open: '78.45',
      high: '79.05',
      low: '77.3',
      close: '77.4',
      'adjusted close': '42.3903379815489',
      volume: '8587000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-25': {
      open: '77.9',
      high: '79.37',
      low: '76.9',
      close: '79.07',
      'adjusted close': '43.3049615529854',
      volume: '7675900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-24': {
      open: '79.7',
      high: '79.99',
      low: '78.56',
      close: '78.56',
      'adjusted close': '43.0256453724869',
      volume: '6874700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-21': {
      open: '79.49',
      high: '80.0',
      low: '78.16',
      close: '79.95',
      'adjusted close': '43.7869188840418',
      volume: '7453900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-20': {
      open: '79.57',
      high: '80.05',
      low: '78.71',
      close: '79.15',
      'adjusted close': '43.3487758558088',
      volume: '6285100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-19': {
      open: '79.08',
      high: '79.7',
      low: '78.82',
      close: '79.51',
      'adjusted close': '43.5459402185136',
      volume: '5440300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-18': {
      open: '78.02',
      high: '79.5',
      low: '77.91',
      close: '79.33',
      'adjusted close': '43.4473580371612',
      volume: '7791200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-14': {
      open: '76.4',
      high: '77.45',
      low: '75.35',
      close: '77.45',
      'adjusted close': '42.4177219208135',
      volume: '8299500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-13': {
      open: '76.1',
      high: '76.35',
      low: '74.31',
      close: '75.86',
      'adjusted close': '41.5469126522003',
      volume: '11218400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-12': {
      open: '77.5',
      high: '78.09',
      low: '76.5',
      close: '76.5',
      'adjusted close': '41.8974270747867',
      volume: '5609200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-11': {
      open: '78.3',
      high: '78.97',
      low: '77.31',
      close: '77.39',
      'adjusted close': '42.384861193696',
      volume: '8598200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-10': {
      open: '77.1',
      high: '78.02',
      low: '76.45',
      close: '77.91',
      'adjusted close': '42.6696541620475',
      volume: '7949100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-07': {
      open: '77.97',
      high: '78.15',
      low: '76.61',
      close: '77.1',
      'adjusted close': '42.2260343459615',
      volume: '8296400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-06': {
      open: '77.11',
      high: '78.2',
      low: '76.51',
      close: '77.51',
      'adjusted close': '42.450582647931',
      volume: '7755200',
      'dividend amount': '0.1500',
      'split coefficient': '1.0',
    },
    '2003-02-05': {
      open: '77.85',
      high: '78.9',
      low: '76.87',
      close: '77.26',
      'adjusted close': '42.2319342696259',
      volume: '7427200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-04': {
      open: '78.18',
      high: '78.18',
      low: '76.25',
      close: '77.09',
      'adjusted close': '42.1390087088462',
      volume: '7216200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-02-03': {
      open: '78.2',
      high: '78.87',
      low: '77.75',
      close: '78.18',
      'adjusted close': '42.7348255397276',
      volume: '6683500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-31': {
      open: '77.7',
      high: '78.34',
      low: '77.02',
      close: '78.2',
      'adjusted close': '42.7457579586428',
      volume: '9744100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-30': {
      open: '81.3',
      high: '81.3',
      low: '78.3',
      close: '78.3',
      'adjusted close': '42.8004200532191',
      volume: '7349500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-29': {
      open: '79.5',
      high: '80.7',
      low: '78.57',
      close: '80.32',
      'adjusted close': '43.9045943636597',
      volume: '7755000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-28': {
      open: '78.5',
      high: '80.47',
      low: '78.36',
      close: '80.11',
      'adjusted close': '43.7898039650496',
      volume: '8017400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-27': {
      open: '77.99',
      high: '79.5',
      low: '77.61',
      close: '78.42',
      'adjusted close': '42.8660145667106',
      volume: '9307800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-24': {
      open: '80.6',
      high: '81.5',
      low: '78.82',
      close: '78.99',
      'adjusted close': '43.1775885057953',
      volume: '7748000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-23': {
      open: '80.15',
      high: '81.36',
      low: '79.8',
      close: '81.05',
      'adjusted close': '44.3036276540665',
      volume: '8371400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-22': {
      open: '80.0',
      high: '80.77',
      low: '79.39',
      close: '79.7',
      'adjusted close': '43.5656893772869',
      volume: '9432400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-21': {
      open: '81.3',
      high: '81.85',
      low: '80.5',
      close: '80.54',
      'adjusted close': '44.0248509717275',
      volume: '8926900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-17': {
      open: '83.74',
      high: '83.74',
      low: '81.18',
      close: '81.3',
      'adjusted close': '44.4402828905072',
      volume: '17331200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-16': {
      open: '87.4',
      high: '87.71',
      low: '85.38',
      close: '86.05',
      'adjusted close': '47.03673238288',
      volume: '9904300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-15': {
      open: '88.58',
      high: '88.65',
      low: '86.95',
      close: '87.59',
      'adjusted close': '47.8785286393545',
      volume: '8146600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-14': {
      open: '87.23',
      high: '88.59',
      low: '87.22',
      close: '88.58',
      'adjusted close': '48.4196833756596',
      volume: '7569100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-13': {
      open: '88.31',
      high: '88.95',
      low: '87.35',
      close: '87.51',
      'adjusted close': '47.8347989636935',
      volume: '10499000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-10': {
      open: '85.85',
      high: '88.04',
      low: '85.7',
      close: '87.68',
      'adjusted close': '47.9277245244732',
      volume: '9955400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-09': {
      open: '84.75',
      high: '87.02',
      low: '84.75',
      close: '87.0',
      'adjusted close': '47.5560222813545',
      volume: '10711600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-08': {
      open: '85.55',
      high: '85.69',
      low: '84.07',
      close: '84.19',
      'adjusted close': '46.0200174237614',
      volume: '9508800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-07': {
      open: '83.95',
      high: '86.18',
      low: '83.75',
      close: '86.0',
      'adjusted close': '47.0094013355918',
      volume: '11906900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-06': {
      open: '81.9',
      high: '84.8',
      low: '81.81',
      close: '83.59',
      'adjusted close': '45.6920448563038',
      volume: '7921300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-03': {
      open: '80.7',
      high: '81.65',
      low: '80.21',
      close: '81.65',
      'adjusted close': '44.6316002215241',
      volume: '5962300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2003-01-02': {
      open: '78.8',
      high: '80.57',
      low: '78.19',
      close: '80.57',
      'adjusted close': '44.0412496001004',
      volume: '7864500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-31': {
      open: '77.1',
      high: '78.46',
      low: '77.1',
      close: '77.5',
      'adjusted close': '42.3631232966089',
      volume: '7871400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-30': {
      open: '77.0',
      high: '77.43',
      low: '75.6',
      close: '76.25',
      'adjusted close': '41.6798471144056',
      volume: '8066600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-27': {
      open: '78.5',
      high: '79.18',
      low: '76.61',
      close: '77.36',
      'adjusted close': '42.2865963642022',
      volume: '6334100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-26': {
      open: '79.81',
      high: '80.7',
      low: '78.26',
      close: '78.5',
      'adjusted close': '42.9097442423716',
      volume: '5784300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-24': {
      open: '80.2',
      high: '81.0',
      low: '79.76',
      close: '79.76',
      'adjusted close': '43.5984866340326',
      volume: '2420200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-23': {
      open: '79.75',
      high: '81.5',
      low: '79.44',
      close: '80.26',
      'adjusted close': '43.871797106914',
      volume: '5922200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-20': {
      open: '79.4',
      high: '80.0',
      low: '78.88',
      close: '79.79',
      'adjusted close': '43.6148852624055',
      volume: '8053800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-19': {
      open: '78.6',
      high: '80.34',
      low: '78.35',
      close: '78.66',
      'adjusted close': '42.9972035936937',
      volume: '7319900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-18': {
      open: '79.25',
      high: '79.69',
      low: '78.24',
      close: '79.13',
      'adjusted close': '43.2541154382021',
      volume: '6514900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-17': {
      open: '80.6',
      high: '81.77',
      low: '80.03',
      close: '80.31',
      'adjusted close': '43.8991281542021',
      volume: '6167100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-16': {
      open: '79.6',
      high: '81.79',
      low: '79.05',
      close: '81.62',
      'adjusted close': '44.6152015931512',
      volume: '8483500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-13': {
      open: '79.25',
      high: '80.0',
      low: '78.27',
      close: '80.0',
      'adjusted close': '43.7296756610157',
      volume: '7773800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-12': {
      open: '81.25',
      high: '81.69',
      low: '79.7',
      close: '80.17',
      'adjusted close': '43.8226012217953',
      volume: '7208700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-11': {
      open: '80.8',
      high: '82.48',
      low: '79.99',
      close: '81.44',
      'adjusted close': '44.5168098229139',
      volume: '6661000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-10': {
      open: '78.6',
      high: '81.0',
      low: '78.6',
      close: '80.8',
      'adjusted close': '44.1669724176258',
      volume: '6869800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-09': {
      open: '81.0',
      high: '81.33',
      low: '79.5',
      close: '79.59',
      'adjusted close': '43.505561073253',
      volume: '8841900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-06': {
      open: '81.5',
      high: '82.75',
      low: '81.07',
      close: '82.32',
      'adjusted close': '44.9978362551851',
      volume: '7594800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-05': {
      open: '84.9',
      high: '84.98',
      low: '82.6',
      close: '83.06',
      'adjusted close': '45.4023357550495',
      volume: '6900000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-04': {
      open: '83.41',
      high: '84.86',
      low: '82.82',
      close: '83.69',
      'adjusted close': '45.74670695088',
      volume: '10053500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-03': {
      open: '86.72',
      high: '87.03',
      low: '85.15',
      close: '85.21',
      'adjusted close': '46.5775707884393',
      volume: '7027800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-12-02': {
      open: '88.6',
      high: '89.46',
      low: '86.3',
      close: '87.3',
      'adjusted close': '47.7200085650833',
      volume: '9694200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-29': {
      open: '87.71',
      high: '88.05',
      low: '86.76',
      close: '86.92',
      'adjusted close': '47.5122926056935',
      volume: '2980700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-27': {
      open: '85.9',
      high: '88.11',
      low: '85.85',
      close: '87.7',
      'adjusted close': '47.9386569433884',
      volume: '8209800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-26': {
      open: '86.15',
      high: '86.15',
      low: '84.39',
      close: '85.06',
      'adjusted close': '46.4955776465749',
      volume: '8560800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-25': {
      open: '84.63',
      high: '86.25',
      low: '83.52',
      close: '86.2',
      'adjusted close': '47.1187255247444',
      volume: '8651200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-22': {
      open: '83.95',
      high: '85.17',
      low: '83.8',
      close: '84.43',
      'adjusted close': '46.1512064507444',
      volume: '9053300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-21': {
      open: '82.05',
      high: '84.92',
      low: '82.05',
      close: '84.9',
      'adjusted close': '46.4081182952529',
      volume: '12737200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-20': {
      open: '77.95',
      high: '81.71',
      low: '77.9',
      close: '81.61',
      'adjusted close': '44.6097353836936',
      volume: '9070900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-19': {
      open: '78.24',
      high: '79.45',
      low: '77.84',
      close: '78.37',
      'adjusted close': '42.8386835194225',
      volume: '6918400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-18': {
      open: '80.8',
      high: '80.99',
      low: '79.08',
      close: '79.18',
      'adjusted close': '43.2814464854903',
      volume: '7321300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-15': {
      open: '80.38',
      high: '80.4',
      low: '79.01',
      close: '80.01',
      'adjusted close': '43.7351418704733',
      volume: '11367800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-14': {
      open: '80.38',
      high: '80.99',
      low: '79.4',
      close: '80.72',
      'adjusted close': '44.1232427419648',
      volume: '9662800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-13': {
      open: '79.5',
      high: '80.33',
      low: '77.98',
      close: '79.35',
      'adjusted close': '43.3743720462699',
      volume: '11984700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-12': {
      open: '77.5',
      high: '79.9',
      low: '77.3',
      close: '79.15',
      'adjusted close': '43.2650478571174',
      volume: '9027700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-11': {
      open: '77.59',
      high: '78.36',
      low: '76.7',
      close: '77.29',
      'adjusted close': '42.2483328979988',
      volume: '7386400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-08': {
      open: '78.5',
      high: '79.29',
      low: '77.2',
      close: '77.59',
      'adjusted close': '42.4123191817276',
      volume: '8119600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-07': {
      open: '80.0',
      high: '80.0',
      low: '78.32',
      close: '78.95',
      'adjusted close': '43.1557236679648',
      volume: '11036800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-06': {
      open: '80.8',
      high: '81.88',
      low: '80.05',
      close: '81.54',
      'adjusted close': '44.5714719174902',
      volume: '10480800',
      'dividend amount': '0.1500',
      'split coefficient': '1.0',
    },
    '2002-11-05': {
      open: '81.75',
      high: '82.01',
      low: '80.47',
      close: '81.68',
      'adjusted close': '44.5660157451414',
      volume: '10466700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-04': {
      open: '81.99',
      high: '83.81',
      low: '81.7',
      close: '82.5',
      'adjusted close': '45.0134218777444',
      volume: '12006000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-11-01': {
      open: '78.9',
      high: '80.5',
      low: '78.32',
      close: '80.4',
      'adjusted close': '43.8676256844928',
      volume: '9395200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-31': {
      open: '78.67',
      high: '79.79',
      low: '78.5',
      close: '78.94',
      'adjusted close': '43.0710245215654',
      volume: '9864300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-30': {
      open: '77.0',
      high: '79.4',
      low: '76.02',
      close: '78.67',
      'adjusted close': '42.9237078681473',
      volume: '12865800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-29': {
      open: '76.56',
      high: '77.05',
      low: '74.2',
      close: '76.74',
      'adjusted close': '41.8706666048256',
      volume: '11455200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-28': {
      open: '75.15',
      high: '77.5',
      low: '74.56',
      close: '76.56',
      'adjusted close': '41.7724555025468',
      volume: '12544900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-25': {
      open: '72.5',
      high: '74.62',
      low: '72.16',
      close: '74.56',
      'adjusted close': '40.6812210327833',
      volume: '7514200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-24': {
      open: '75.5',
      high: '75.55',
      low: '71.75',
      close: '72.1',
      'adjusted close': '39.3390026349742',
      volume: '10524000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-23': {
      open: '74.4',
      high: '74.97',
      low: '72.46',
      close: '74.6',
      'adjusted close': '40.7030457221786',
      volume: '12441900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-22': {
      open: '74.5',
      high: '75.68',
      low: '73.62',
      close: '74.49',
      'adjusted close': '40.6430278263416',
      volume: '10371500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-21': {
      open: '73.65',
      high: '75.9',
      low: '72.94',
      close: '75.55',
      'adjusted close': '41.2213820953163',
      volume: '10875700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-18': {
      open: '71.73',
      high: '74.25',
      low: '70.26',
      close: '74.25',
      'adjusted close': '40.51207968997',
      volume: '12482900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-17': {
      open: '72.8',
      high: '73.0',
      low: '71.23',
      close: '72.2',
      'adjusted close': '39.3935643584624',
      volume: '21344600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-16': {
      open: '66.6',
      high: '67.0',
      low: '64.24',
      close: '64.9',
      'adjusted close': '35.4105585438256',
      volume: '15900200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-15': {
      open: '67.75',
      high: '68.48',
      low: '66.58',
      close: '68.48',
      'adjusted close': '37.3638682447023',
      volume: '14600200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-14': {
      open: '61.54',
      high: '63.8',
      low: '61.54',
      close: '63.42',
      'adjusted close': '34.6030450362006',
      volume: '7925300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-11': {
      open: '62.0',
      high: '63.92',
      low: '61.5',
      close: '63.92',
      'adjusted close': '34.8758536536415',
      volume: '17853800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-10': {
      open: '54.65',
      high: '58.48',
      low: '54.01',
      close: '57.58',
      'adjusted close': '31.4166403844912',
      volume: '12670600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-09': {
      open: '56.05',
      high: '56.7',
      low: '54.81',
      close: '55.07',
      'adjusted close': '30.047141124938',
      volume: '12156000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-08': {
      open: '57.78',
      high: '58.09',
      low: '54.8',
      close: '57.05',
      'adjusted close': '31.1274632500039',
      volume: '14744500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-07': {
      open: '56.6',
      high: '58.5',
      low: '56.6',
      close: '56.86',
      'adjusted close': '31.0237959753763',
      volume: '9641600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-04': {
      open: '59.5',
      high: '59.51',
      low: '55.77',
      close: '56.6',
      'adjusted close': '30.8819354943071',
      volume: '14551900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-03': {
      open: '60.3',
      high: '61.16',
      low: '58.95',
      close: '60.0',
      'adjusted close': '32.737034092905',
      volume: '11001100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-02': {
      open: '61.18',
      high: '62.0',
      low: '58.35',
      close: '59.63',
      'adjusted close': '32.5351557159988',
      volume: '10653300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-10-01': {
      open: '59.2',
      high: '61.98',
      low: '58.99',
      close: '61.17',
      'adjusted close': '33.3754062577167',
      volume: '11642800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-30': {
      open: '59.55',
      high: '59.89',
      low: '57.99',
      close: '58.31',
      'adjusted close': '31.8149409659549',
      volume: '13020100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-27': {
      open: '61.43',
      high: '63.49',
      low: '60.31',
      close: '60.36',
      'adjusted close': '32.9334562974625',
      volume: '8793100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-26': {
      open: '64.2',
      high: '64.88',
      low: '61.33',
      close: '62.02',
      'adjusted close': '33.8391809073662',
      volume: '12368800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-25': {
      open: '61.2',
      high: '63.77',
      low: '60.99',
      close: '63.01',
      'adjusted close': '34.3793419698991',
      volume: '13119200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-24': {
      open: '61.08',
      high: '63.3',
      low: '59.55',
      close: '59.75',
      'adjusted close': '32.6006297841846',
      volume: '16710100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-23': {
      open: '63.75',
      high: '63.75',
      low: '62.37',
      close: '63.37',
      'adjusted close': '34.5757641744565',
      volume: '8377100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-20': {
      open: '65.4',
      high: '65.4',
      low: '63.9',
      close: '63.92',
      'adjusted close': '34.8758536536415',
      volume: '14195200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-19': {
      open: '64.8',
      high: '66.48',
      low: '64.65',
      close: '64.8',
      'adjusted close': '35.3559968203374',
      volume: '22034900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-18': {
      open: '70.65',
      high: '70.9',
      low: '68.86',
      close: '69.55',
      'adjusted close': '37.9476786860258',
      volume: '12712700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-17': {
      open: '73.0',
      high: '73.45',
      low: '71.35',
      close: '71.75',
      'adjusted close': '39.1480366027656',
      volume: '6195100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-16': {
      open: '72.0',
      high: '72.72',
      low: '71.23',
      close: '72.32',
      'adjusted close': '39.4590384266482',
      volume: '4595200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-13': {
      open: '71.5',
      high: '72.88',
      low: '71.31',
      close: '72.5',
      'adjusted close': '39.5572495289269',
      volume: '5949600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-12': {
      open: '74.05',
      high: '74.05',
      low: '71.64',
      close: '71.87',
      'adjusted close': '39.2135106709514',
      volume: '6707400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-11': {
      open: '76.4',
      high: '77.5',
      low: '74.06',
      close: '74.2',
      'adjusted close': '40.4847988282259',
      volume: '5394400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-10': {
      open: '74.6',
      high: '75.6',
      low: '73.85',
      close: '75.6',
      'adjusted close': '41.2486629570604',
      volume: '5538300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-09': {
      open: '72.45',
      high: '74.98',
      low: '71.85',
      close: '74.5',
      'adjusted close': '40.6484839986904',
      volume: '5785000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-06': {
      open: '73.75',
      high: '73.99',
      low: '73.07',
      close: '73.2',
      'adjusted close': '39.9391815933442',
      volume: '4586500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-05': {
      open: '72.74',
      high: '72.85',
      low: '71.5',
      close: '72.18',
      'adjusted close': '39.3826520137648',
      volume: '6467700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-04': {
      open: '72.35',
      high: '73.99',
      low: '71.6',
      close: '73.73',
      'adjusted close': '40.2283587278315',
      volume: '6264600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-09-03': {
      open: '74.2',
      high: '75.0',
      low: '72.3',
      close: '72.35',
      'adjusted close': '39.4754069436947',
      volume: '6849400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-30': {
      open: '75.55',
      high: '76.35',
      low: '75.1',
      close: '75.38',
      'adjusted close': '41.1286271653864',
      volume: '5002500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-29': {
      open: '75.6',
      high: '77.35',
      low: '75.15',
      close: '76.62',
      'adjusted close': '41.8051925366397',
      volume: '5857400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-28': {
      open: '76.0',
      high: '77.01',
      low: '75.67',
      close: '76.06',
      'adjusted close': '41.499646885106',
      volume: '6590300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-27': {
      open: '80.4',
      high: '80.44',
      low: '77.3',
      close: '77.96',
      'adjusted close': '42.5363196313813',
      volume: '7087800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-26': {
      open: '80.4',
      high: '80.89',
      low: '77.8',
      close: '79.42',
      'adjusted close': '43.3329207943086',
      volume: '6997800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-23': {
      open: '81.2',
      high: '81.48',
      low: '79.35',
      close: '80.4',
      'adjusted close': '43.8676256844928',
      volume: '5908900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-22': {
      open: '80.95',
      high: '82.45',
      low: '80.2',
      close: '81.99',
      'adjusted close': '44.7351570879547',
      volume: '5446600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-21': {
      open: '81.9',
      high: '82.33',
      low: '79.55',
      close: '81.0',
      'adjusted close': '44.1949960254218',
      volume: '7768600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-20': {
      open: '81.56',
      high: '82.0',
      low: '80.18',
      close: '81.27',
      'adjusted close': '44.3423126788399',
      volume: '7671000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-19': {
      open: '79.35',
      high: '82.85',
      low: '79.01',
      close: '82.49',
      'adjusted close': '45.0079657053956',
      volume: '10803900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-16': {
      open: '76.5',
      high: '79.98',
      low: '75.98',
      close: '79.35',
      'adjusted close': '43.2947275878669',
      volume: '11645800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-15': {
      open: '75.4',
      high: '76.71',
      low: '74.6',
      close: '76.5',
      'adjusted close': '41.7397184684539',
      volume: '9267300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-14': {
      open: '71.95',
      high: '75.02',
      low: '71.35',
      close: '74.92',
      'adjusted close': '40.8776432373408',
      volume: '8353000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-13': {
      open: '71.55',
      high: '73.8',
      low: '71.19',
      close: '71.9',
      'adjusted close': '39.2298791879979',
      volume: '8014700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-12': {
      open: '71.05',
      high: '72.64',
      low: '70.61',
      close: '71.77',
      'adjusted close': '39.1589489474633',
      volume: '5263300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-09': {
      open: '70.61',
      high: '74.3',
      low: '70.4',
      close: '71.83',
      'adjusted close': '39.1916859815562',
      volume: '6940900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-08': {
      open: '69.2',
      high: '72.05',
      low: '68.35',
      close: '71.61',
      'adjusted close': '39.0716501898822',
      volume: '8420500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-07': {
      open: '69.0',
      high: '69.9',
      low: '66.8',
      close: '69.17',
      'adjusted close': '37.7403441367707',
      volume: '8037300',
      'dividend amount': '0.1500',
      'split coefficient': '1.0',
    },
    '2002-08-06': {
      open: '67.0',
      high: '69.42',
      low: '66.61',
      close: '67.9',
      'adjusted close': '36.967244184748',
      volume: '8370800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-05': {
      open: '67.94',
      high: '68.35',
      low: '65.85',
      close: '65.99',
      'adjusted close': '35.9273703056188',
      volume: '7126900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-02': {
      open: '68.21',
      high: '68.23',
      low: '67.12',
      close: '67.88',
      'adjusted close': '36.9563554530294',
      volume: '6915000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-08-01': {
      open: '70.4',
      high: '70.7',
      low: '67.22',
      close: '68.25',
      'adjusted close': '37.157796989824',
      volume: '8832000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-31': {
      open: '70.75',
      high: '71.6',
      low: '69.11',
      close: '70.4',
      'adjusted close': '38.3283356495767',
      volume: '12718600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-30': {
      open: '70.5',
      high: '72.7',
      low: '70.02',
      close: '71.79',
      'adjusted close': '39.0851025040215',
      volume: '10138600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-29': {
      open: '70.37',
      high: '71.4',
      low: '68.0',
      close: '71.18',
      'adjusted close': '38.7529961866033',
      volume: '11919300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-26': {
      open: '68.75',
      high: '69.47',
      low: '65.76',
      close: '66.4',
      'adjusted close': '36.1505893058508',
      volume: '11576400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-25': {
      open: '68.9',
      high: '70.0',
      low: '67.3',
      close: '69.35',
      'adjusted close': '37.7566772343486',
      volume: '10989800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-24': {
      open: '66.0',
      high: '70.0',
      low: '65.7',
      close: '69.54',
      'adjusted close': '37.8601201856756',
      volume: '13804500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-23': {
      open: '68.25',
      high: '70.0',
      low: '66.66',
      close: '67.05',
      'adjusted close': '36.5044730867062',
      volume: '12766700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-22': {
      open: '71.25',
      high: '72.19',
      low: '68.31',
      close: '68.5',
      'adjusted close': '37.2939061363069',
      volume: '14866900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-19': {
      open: '70.96',
      high: '72.7',
      low: '70.0',
      close: '72.0',
      'adjusted close': '39.1994341870671',
      volume: '11612900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-18': {
      open: '70.5',
      high: '74.2',
      low: '70.0',
      close: '72.05',
      'adjusted close': '39.2266560163637',
      volume: '15310800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-17': {
      open: '71.0',
      high: '71.6',
      low: '69.62',
      close: '70.69',
      'adjusted close': '38.4862222594968',
      volume: '11537300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-16': {
      open: '69.51',
      high: '71.39',
      low: '68.48',
      close: '69.01',
      'adjusted close': '37.5715687951319',
      volume: '10486800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-15': {
      open: '68.4',
      high: '71.0',
      low: '66.6',
      close: '71.0',
      'adjusted close': '38.6549976011356',
      volume: '10420900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-12': {
      open: '70.0',
      high: '71.74',
      low: '68.8',
      close: '69.21',
      'adjusted close': '37.6804561123182',
      volume: '8572200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-11': {
      open: '68.0',
      high: '69.75',
      low: '67.55',
      close: '69.41',
      'adjusted close': '37.7893434295045',
      volume: '11026400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-10': {
      open: '70.6',
      high: '71.25',
      low: '68.53',
      close: '68.76',
      'adjusted close': '37.4354596486491',
      volume: '10073400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-09': {
      open: '71.4',
      high: '72.25',
      low: '69.45',
      close: '69.67',
      'adjusted close': '37.9308969418467',
      volume: '11165600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-08': {
      open: '73.0',
      high: '73.48',
      low: '71.03',
      close: '71.3',
      'adjusted close': '38.818328576915',
      volume: '9488100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-05': {
      open: '72.45',
      high: '73.9',
      low: '72.0',
      close: '73.5',
      'adjusted close': '40.0160890659643',
      volume: '5243000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-03': {
      open: '67.95',
      high: '70.71',
      low: '67.87',
      close: '70.51',
      'adjusted close': '38.3882236740292',
      volume: '8469900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-02': {
      open: '67.85',
      high: '69.4',
      low: '67.7',
      close: '68.58',
      'adjusted close': '37.3374610631814',
      volume: '12012900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-07-01': {
      open: '72.01',
      high: '72.41',
      low: '67.16',
      close: '67.6',
      'adjusted close': '36.8039132089685',
      volume: '12055900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-28': {
      open: '71.35',
      high: '73.62',
      low: '71.25',
      close: '72.0',
      'adjusted close': '39.1994341870671',
      volume: '10945600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-27': {
      open: '71.2',
      high: '72.15',
      low: '69.1',
      close: '71.9',
      'adjusted close': '39.1449905284739',
      volume: '10610100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-26': {
      open: '66.1',
      high: '70.59',
      low: '66.1',
      close: '70.05',
      'adjusted close': '38.1377828445007',
      volume: '11544000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-25': {
      open: '70.3',
      high: '71.45',
      low: '68.41',
      close: '68.6',
      'adjusted close': '37.3483497949',
      volume: '8554800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-24': {
      open: '67.25',
      high: '71.49',
      low: '67.25',
      close: '69.7',
      'adjusted close': '37.9472300394247',
      volume: '14687700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-21': {
      open: '70.0',
      high: '70.99',
      low: '68.19',
      close: '68.75',
      'adjusted close': '37.4300152827897',
      volume: '15542600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-20': {
      open: '74.0',
      high: '74.09',
      low: '71.43',
      close: '71.58',
      'adjusted close': '38.9707708209759',
      volume: '11116900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-19': {
      open: '74.2',
      high: '75.2',
      low: '73.25',
      close: '73.35',
      'adjusted close': '39.9344235780746',
      volume: '9455400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-18': {
      open: '75.6',
      high: '76.9',
      low: '75.47',
      close: '75.94',
      'adjusted close': '41.3445143356371',
      volume: '7242000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-17': {
      open: '76.18',
      high: '77.75',
      low: '75.16',
      close: '77.14',
      'adjusted close': '41.9978382387549',
      volume: '7775800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-14': {
      open: '74.2',
      high: '76.77',
      low: '73.25',
      close: '76.17',
      'adjusted close': '41.4697347504014',
      volume: '8592000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-13': {
      open: '75.45',
      high: '77.4',
      low: '75.3',
      close: '75.6',
      'adjusted close': '41.1594058964204',
      volume: '8909600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-12': {
      open: '75.4',
      high: '75.95',
      low: '73.25',
      close: '74.65',
      'adjusted close': '40.6421911397855',
      volume: '13296100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-11': {
      open: '77.85',
      high: '78.25',
      low: '75.2',
      close: '75.49',
      'adjusted close': '41.099517871968',
      volume: '7953400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-10': {
      open: '77.75',
      high: '78.8',
      low: '76.6',
      close: '76.8',
      'adjusted close': '41.8127297995382',
      volume: '7075800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-07': {
      open: '78.48',
      high: '78.85',
      low: '77.0',
      close: '78.3',
      'adjusted close': '42.6293846784354',
      volume: '9309400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-06': {
      open: '80.55',
      high: '80.95',
      low: '78.89',
      close: '79.66',
      'adjusted close': '43.3698184353023',
      volume: '5540600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-05': {
      open: '79.65',
      high: '80.71',
      low: '79.11',
      close: '80.55',
      'adjusted close': '43.8543669967813',
      volume: '7172100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-04': {
      open: '78.11',
      high: '79.65',
      low: '77.6',
      close: '79.31',
      'adjusted close': '43.1792656302263',
      volume: '7273200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-06-03': {
      open: '80.75',
      high: '80.91',
      low: '78.0',
      close: '78.11',
      'adjusted close': '42.5259417271085',
      volume: '8383600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-31': {
      open: '82.05',
      high: '82.05',
      low: '80.4',
      close: '80.45',
      'adjusted close': '43.7999233381882',
      volume: '5126200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-30': {
      open: '80.6',
      high: '82.25',
      low: '80.22',
      close: '82.25',
      'adjusted close': '44.7799091928648',
      volume: '7374100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-29': {
      open: '81.5',
      high: '82.0',
      low: '81.0',
      close: '81.6',
      'adjusted close': '44.4260254120094',
      volume: '4519500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-28': {
      open: '82.8',
      high: '83.11',
      low: '81.69',
      close: '82.08',
      'adjusted close': '44.6873549732565',
      volume: '4552100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-24': {
      open: '83.65',
      high: '83.75',
      low: '82.62',
      close: '83.1',
      'adjusted close': '45.2426802909066',
      volume: '4122000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-23': {
      open: '84.25',
      high: '85.05',
      low: '82.52',
      close: '84.35',
      'adjusted close': '45.923226023321',
      volume: '6865600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-22': {
      open: '83.76',
      high: '84.39',
      low: '82.74',
      close: '84.0',
      'adjusted close': '45.7326732182449',
      volume: '5040700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-21': {
      open: '84.41',
      high: '85.0',
      low: '83.3',
      close: '83.45',
      'adjusted close': '45.4332330959826',
      volume: '4454000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-20': {
      open: '85.45',
      high: '85.46',
      low: '83.93',
      close: '84.45',
      'adjusted close': '45.9776696819141',
      volume: '4530300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-17': {
      open: '86.25',
      high: '86.4',
      low: '85.0',
      close: '85.69',
      'adjusted close': '46.6527710484691',
      volume: '5874800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-16': {
      open: '84.2',
      high: '86.0',
      low: '83.4',
      close: '85.45',
      'adjusted close': '46.5221062678456',
      volume: '6768300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-15': {
      open: '84.6',
      high: '86.49',
      low: '84.05',
      close: '84.5',
      'adjusted close': '46.0048915112107',
      volume: '8552300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-14': {
      open: '84.5',
      high: '85.48',
      low: '82.19',
      close: '85.48',
      'adjusted close': '46.5384393654235',
      volume: '9624700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-13': {
      open: '80.25',
      high: '82.29',
      low: '79.5',
      close: '82.19',
      'adjusted close': '44.7472429977089',
      volume: '6527600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-10': {
      open: '79.95',
      high: '80.5',
      low: '79.25',
      close: '79.68',
      'adjusted close': '43.3807071670209',
      volume: '7386300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-09': {
      open: '81.9',
      high: '83.0',
      low: '79.0',
      close: '79.93',
      'adjusted close': '43.5168163135038',
      volume: '10130100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-08': {
      open: '78.2',
      high: '82.8',
      low: '78.2',
      close: '82.45',
      'adjusted close': '44.8887965100511',
      volume: '12779700',
      'dividend amount': '0.1500',
      'split coefficient': '1.0',
    },
    '2002-05-07': {
      open: '76.89',
      high: '77.5',
      low: '75.92',
      close: '76.5',
      'adjusted close': '41.5737643222628',
      volume: '17418700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-06': {
      open: '81.7',
      high: '81.87',
      low: '76.0',
      close: '76.0',
      'adjusted close': '41.3020403724441',
      volume: '13648400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-03': {
      open: '83.1',
      high: '83.5',
      low: '81.11',
      close: '81.78',
      'adjusted close': '44.4431692323484',
      volume: '8656300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-02': {
      open: '83.75',
      high: '84.8',
      low: '83.13',
      close: '83.86',
      'adjusted close': '45.5735408635943',
      volume: '6582900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-05-01': {
      open: '83.85',
      high: '84.65',
      low: '81.67',
      close: '84.3',
      'adjusted close': '45.8126579394347',
      volume: '12203800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-30': {
      open: '83.91',
      high: '85.23',
      low: '83.53',
      close: '83.76',
      'adjusted close': '45.5191960736305',
      volume: '8883900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-29': {
      open: '84.71',
      high: '85.1',
      low: '83.55',
      close: '83.89',
      'adjusted close': '45.5898443005834',
      volume: '6535600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-26': {
      open: '86.7',
      high: '86.96',
      low: '84.7',
      close: '84.71',
      'adjusted close': '46.0354715782861',
      volume: '6684200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-25': {
      open: '86.05',
      high: '86.92',
      low: '85.77',
      close: '86.71',
      'adjusted close': '47.1223673775609',
      volume: '6870000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-24': {
      open: '88.2',
      high: '88.25',
      low: '86.27',
      close: '86.5',
      'adjusted close': '47.0082433186371',
      volume: '6520500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-23': {
      open: '88.35',
      high: '89.08',
      low: '87.24',
      close: '87.33',
      'adjusted close': '47.4593050753361',
      volume: '5144100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-22': {
      open: '88.95',
      high: '88.95',
      low: '87.36',
      close: '87.93',
      'adjusted close': '47.7853738151186',
      volume: '6757700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-19': {
      open: '89.9',
      high: '90.03',
      low: '87.6',
      close: '89.0',
      'adjusted close': '48.3668630677306',
      volume: '9273200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-18': {
      open: '86.97',
      high: '89.1',
      low: '86.9',
      close: '88.95',
      'adjusted close': '48.3396906727488',
      volume: '14763500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-17': {
      open: '87.05',
      high: '87.2',
      low: '84.0',
      close: '84.81',
      'adjusted close': '46.0898163682498',
      volume: '11019500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-16': {
      open: '86.35',
      high: '86.85',
      low: '85.58',
      close: '86.2',
      'adjusted close': '46.8452089487458',
      volume: '7933100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-15': {
      open: '86.5',
      high: '86.6',
      low: '84.7',
      close: '85.35',
      'adjusted close': '46.383278234054',
      volume: '8871600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-12': {
      open: '87.56',
      high: '87.96',
      low: '84.4',
      close: '85.6',
      'adjusted close': '46.5191402089634',
      volume: '15819300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-11': {
      open: '88.05',
      high: '88.51',
      low: '83.34',
      close: '84.19',
      'adjusted close': '45.7528786704746',
      volume: '23343700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-10': {
      open: '88.45',
      high: '89.95',
      low: '88.2',
      close: '89.01',
      'adjusted close': '48.372297546727',
      volume: '13143900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-09': {
      open: '88.5',
      high: '88.52',
      low: '87.1',
      close: '87.74',
      'adjusted close': '47.6821187141875',
      volume: '17383600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-08': {
      open: '87.5',
      high: '88.59',
      low: '85.35',
      close: '87.41',
      'adjusted close': '47.5027809073071',
      volume: '41207300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-05': {
      open: '100.84',
      high: '101.25',
      low: '97.25',
      close: '97.25',
      'adjusted close': '52.8503082397394',
      volume: '9801800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-04': {
      open: '99.96',
      high: '101.14',
      low: '99.5',
      close: '100.84',
      'adjusted close': '54.8012861994377',
      volume: '5928500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-03': {
      open: '101.4',
      high: '101.5',
      low: '98.9',
      close: '99.96',
      'adjusted close': '54.3230520477568',
      volume: '7649100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-02': {
      open: '102.0',
      high: '102.01',
      low: '100.51',
      close: '100.95',
      'adjusted close': '54.8610654683978',
      volume: '7382200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-04-01': {
      open: '104.0',
      high: '104.0',
      low: '102.4',
      close: '102.86',
      'adjusted close': '55.8990509567053',
      volume: '5587000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-28': {
      open: '103.55',
      high: '105.14',
      low: '103.39',
      close: '104.0',
      'adjusted close': '56.518581562292',
      volume: '5347500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-27': {
      open: '103.75',
      high: '103.79',
      low: '102.5',
      close: '103.39',
      'adjusted close': '56.1870783435132',
      volume: '5602400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-26': {
      open: '103.57',
      high: '105.7',
      low: '102.3',
      close: '102.9',
      'adjusted close': '55.9207888726908',
      volume: '8144000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-25': {
      open: '105.8',
      high: '106.66',
      low: '103.5',
      close: '103.56',
      'adjusted close': '56.2794644864515',
      volume: '6277900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-22': {
      open: '106.5',
      high: '106.7',
      low: '105.07',
      close: '105.6',
      'adjusted close': '57.3880982017118',
      volume: '5507900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-21': {
      open: '105.7',
      high: '106.78',
      low: '104.7',
      close: '106.78',
      'adjusted close': '58.029366723284',
      volume: '5113100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-20': {
      open: '106.9',
      high: '106.9',
      low: '105.49',
      close: '105.5',
      'adjusted close': '57.3337534117481',
      volume: '4844100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-19': {
      open: '106.85',
      high: '108.05',
      low: '106.49',
      close: '107.49',
      'adjusted close': '58.4152147320266',
      volume: '4614800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-18': {
      open: '107.1',
      high: '108.64',
      low: '106.23',
      close: '106.35',
      'adjusted close': '57.7956841264399',
      volume: '5301200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-15': {
      open: '106.55',
      high: '107.45',
      low: '105.59',
      close: '106.79',
      'adjusted close': '58.0348012022804',
      volume: '10864100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-14': {
      open: '107.02',
      high: '107.95',
      low: '106.59',
      close: '106.6',
      'adjusted close': '57.9315461013493',
      volume: '5335500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-13': {
      open: '108.3',
      high: '108.65',
      low: '106.7',
      close: '107.18',
      'adjusted close': '58.246745883139',
      volume: '6409200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-12': {
      open: '105.52',
      high: '108.85',
      low: '105.51',
      close: '108.5',
      'adjusted close': '58.9640971106604',
      volume: '11059300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-11': {
      open: '104.35',
      high: '105.97',
      low: '103.6',
      close: '105.24',
      'adjusted close': '57.1924569578424',
      volume: '8639100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-08': {
      open: '104.82',
      high: '107.34',
      low: '104.82',
      close: '105.09',
      'adjusted close': '57.1109397728968',
      volume: '10726400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-07': {
      open: '107.3',
      high: '107.5',
      low: '103.24',
      close: '103.71',
      'adjusted close': '56.3609816713971',
      volume: '10971900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-06': {
      open: '105.15',
      high: '107.09',
      low: '104.75',
      close: '106.3',
      'adjusted close': '57.768511731458',
      volume: '7687400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-05': {
      open: '104.25',
      high: '106.5',
      low: '104.16',
      close: '105.67',
      'adjusted close': '57.4261395546865',
      volume: '7562100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-04': {
      open: '103.35',
      high: '106.53',
      low: '103.13',
      close: '105.9',
      'adjusted close': '57.5511325716031',
      volume: '10767700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-03-01': {
      open: '98.6',
      high: '103.1',
      low: '98.5',
      close: '103.02',
      'adjusted close': '55.9860026206473',
      volume: '10492900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-28': {
      open: '98.49',
      high: '99.6',
      low: '97.82',
      close: '98.12',
      'adjusted close': '53.3231079124239',
      volume: '7939000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-27': {
      open: '98.1',
      high: '100.31',
      low: '96.7',
      close: '97.83',
      'adjusted close': '53.1655080215291',
      volume: '11978700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-26': {
      open: '99.0',
      high: '99.19',
      low: '95.99',
      close: '97.15',
      'adjusted close': '52.7959634497756',
      volume: '11634500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-25': {
      open: '98.45',
      high: '99.25',
      low: '97.12',
      close: '98.3',
      'adjusted close': '53.4209285343587',
      volume: '9902800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-22': {
      open: '96.38',
      high: '99.2',
      low: '95.76',
      close: '98.45',
      'adjusted close': '53.5024457193043',
      volume: '10471600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-21': {
      open: '98.75',
      high: '99.7',
      low: '96.01',
      close: '96.38',
      'adjusted close': '52.3775085670548',
      volume: '10131700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-20': {
      open: '99.54',
      high: '100.0',
      low: '97.0',
      close: '99.31',
      'adjusted close': '53.9698109129925',
      volume: '14525200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-19': {
      open: '101.5',
      high: '101.8',
      low: '98.86',
      close: '101.3',
      'adjusted close': '55.0512722332709',
      volume: '13395900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-15': {
      open: '104.8',
      high: '104.8',
      low: '101.7',
      close: '102.89',
      'adjusted close': '55.9153543936944',
      volume: '18204600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-14': {
      open: '109.0',
      high: '109.15',
      low: '107.2',
      close: '107.89',
      'adjusted close': '58.6325938918815',
      volume: '5816300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-13': {
      open: '106.7',
      high: '108.48',
      low: '106.12',
      close: '108.07',
      'adjusted close': '58.7304145138163',
      volume: '5086900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-12': {
      open: '106.75',
      high: '107.89',
      low: '106.06',
      close: '106.57',
      'adjusted close': '57.9152426643602',
      volume: '4559800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-11': {
      open: '105.0',
      high: '107.69',
      low: '104.51',
      close: '107.38',
      'adjusted close': '58.3554354630665',
      volume: '6271900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-08': {
      open: '103.0',
      high: '105.72',
      low: '102.61',
      close: '104.99',
      'adjusted close': '57.056594982933',
      volume: '8339400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-07': {
      open: '106.64',
      high: '107.0',
      low: '103.86',
      close: '103.91',
      'adjusted close': '56.4696712513246',
      volume: '7253800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-06': {
      open: '106.7',
      high: '107.82',
      low: '105.33',
      close: '106.63',
      'adjusted close': '57.9478495383384',
      volume: '6669800',
      'dividend amount': '0.1400',
      'split coefficient': '1.0',
    },
    '2002-02-05': {
      open: '106.25',
      high: '108.5',
      low: '106.0',
      close: '106.3',
      'adjusted close': '57.692763940483',
      volume: '6737700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-04': {
      open: '108.0',
      high: '108.0',
      low: '105.99',
      close: '106.8',
      'adjusted close': '57.964131597776',
      volume: '6601900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-02-01': {
      open: '107.64',
      high: '109.3',
      low: '106.64',
      close: '108.0',
      'adjusted close': '58.6154139752791',
      volume: '6799500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-31': {
      open: '106.4',
      high: '107.98',
      low: '104.46',
      close: '107.89',
      'adjusted close': '58.5557130906746',
      volume: '9877000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-30': {
      open: '104.25',
      high: '105.75',
      low: '101.0',
      close: '105.55',
      'adjusted close': '57.2857124545436',
      volume: '17713300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-29': {
      open: '108.15',
      high: '108.56',
      low: '102.41',
      close: '103.0',
      'adjusted close': '55.9017374023495',
      volume: '14509400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-28': {
      open: '109.5',
      high: '110.21',
      low: '107.06',
      close: '108.15',
      'adjusted close': '58.696824272467',
      volume: '7092900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-25': {
      open: '108.5',
      high: '110.69',
      low: '108.1',
      close: '109.28',
      'adjusted close': '59.3101151779491',
      volume: '6761800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-24': {
      open: '108.8',
      high: '109.73',
      low: '107.07',
      close: '108.72',
      'adjusted close': '59.0061834017809',
      volume: '8581500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-23': {
      open: '110.8',
      high: '111.25',
      low: '107.0',
      close: '107.9',
      'adjusted close': '58.5611404438205',
      volume: '12556700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-22': {
      open: '114.25',
      high: '114.26',
      low: '110.33',
      close: '110.5',
      'adjusted close': '59.9722522617439',
      volume: '10130400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-18': {
      open: '114.25',
      high: '114.9',
      low: '112.81',
      close: '114.25',
      'adjusted close': '62.0075096914411',
      volume: '18440200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-17': {
      open: '119.85',
      high: '120.55',
      low: '117.26',
      close: '119.9',
      'adjusted close': '65.0739642188515',
      volume: '11153700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-16': {
      open: '118.0',
      high: '118.75',
      low: '117.25',
      close: '117.4',
      'adjusted close': '63.7171259323867',
      volume: '5366300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-15': {
      open: '118.05',
      high: '119.7',
      low: '117.72',
      close: '118.85',
      'adjusted close': '64.5040921385363',
      volume: '5647900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-14': {
      open: '120.0',
      high: '120.09',
      low: '117.1',
      close: '118.05',
      'adjusted close': '64.0699038868675',
      volume: '10026700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-11': {
      open: '121.5',
      high: '122.18',
      low: '120.28',
      close: '120.31',
      'adjusted close': '65.2964856978317',
      volume: '4797800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-10': {
      open: '123.75',
      high: '124.0',
      low: '121.42',
      close: '122.14',
      'adjusted close': '66.2896913235239',
      volume: '8926300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-09': {
      open: '124.7',
      high: '126.39',
      low: '124.15',
      close: '124.49',
      'adjusted close': '67.5651193128008',
      volume: '6839900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-08': {
      open: '124.25',
      high: '125.2',
      low: '123.73',
      close: '124.7',
      'adjusted close': '67.6790937288639',
      volume: '5311800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-07': {
      open: '125.0',
      high: '126.19',
      low: '123.7',
      close: '124.05',
      'adjusted close': '67.326315774383',
      volume: '5939600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-04': {
      open: '124.05',
      high: '125.6',
      low: '123.98',
      close: '125.6',
      'adjusted close': '68.1675555119912',
      volume: '8405200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-03': {
      open: '121.5',
      high: '124.22',
      low: '120.25',
      close: '123.66',
      'adjusted close': '67.1146490016945',
      volume: '8621700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2002-01-02': {
      open: '120.6',
      high: '121.5',
      low: '119.8',
      close: '121.5',
      'adjusted close': '65.942340722189',
      volume: '6862800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-31': {
      open: '122.75',
      high: '123.24',
      low: '120.95',
      close: '120.96',
      'adjusted close': '65.6492636523126',
      volume: '3512700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-28': {
      open: '123.5',
      high: '123.88',
      low: '122.35',
      close: '122.9',
      'adjusted close': '66.7021701626092',
      volume: '4213900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-27': {
      open: '122.75',
      high: '123.93',
      low: '122.64',
      close: '123.5',
      'adjusted close': '67.0278113513608',
      volume: '3826200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-26': {
      open: '122.0',
      high: '123.7',
      low: '121.81',
      close: '122.4',
      'adjusted close': '66.4308025053163',
      volume: '3755700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-24': {
      open: '122.05',
      high: '122.48',
      low: '121.1',
      close: '121.45',
      'adjusted close': '65.9152039564597',
      volume: '1654400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-21': {
      open: '122.7',
      high: '123.5',
      low: '121.71',
      close: '122.0',
      'adjusted close': '66.2137083794819',
      volume: '8659100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-20': {
      open: '123.89',
      high: '124.0',
      low: '122.15',
      close: '122.7',
      'adjusted close': '66.5936230996921',
      volume: '6344800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-19': {
      open: '121.9',
      high: '124.7',
      low: '121.01',
      close: '123.89',
      'adjusted close': '67.2394781240493',
      volume: '8053900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-18': {
      open: '121.51',
      high: '122.97',
      low: '121.35',
      close: '122.2',
      'adjusted close': '66.3222554423991',
      volume: '5596200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-17': {
      open: '120.15',
      high: '122.14',
      low: '120.15',
      close: '121.34',
      'adjusted close': '65.8555030718552',
      volume: '6205100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-14': {
      open: '120.8',
      high: '121.48',
      low: '119.41',
      close: '121.1',
      'adjusted close': '65.7252465963546',
      volume: '7036000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-13': {
      open: '121.55',
      high: '122.3',
      low: '120.12',
      close: '120.25',
      'adjusted close': '65.2639215789566',
      volume: '8061000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-12': {
      open: '121.12',
      high: '123.21',
      low: '120.06',
      close: '123.2',
      'adjusted close': '66.864990756985',
      volume: '7977900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-11': {
      open: '120.4',
      high: '122.74',
      low: '119.8',
      close: '121.5',
      'adjusted close': '65.942340722189',
      volume: '7793200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-10': {
      open: '120.0',
      high: '121.0',
      low: '119.41',
      close: '119.66',
      'adjusted close': '64.9437077433509',
      volume: '5570900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-07': {
      open: '119.5',
      high: '121.0',
      low: '118.75',
      close: '120.4',
      'adjusted close': '65.3453318761445',
      volume: '6889200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-06': {
      open: '120.5',
      high: '121.7',
      low: '119.8',
      close: '120.14',
      'adjusted close': '65.2042206943521',
      volume: '11225700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-05': {
      open: '116.85',
      high: '121.98',
      low: '116.65',
      close: '121.4',
      'adjusted close': '65.8880671907304',
      volume: '14646200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-04': {
      open: '114.3',
      high: '116.72',
      low: '113.38',
      close: '116.64',
      'adjusted close': '63.3046470933014',
      volume: '6585900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-12-03': {
      open: '114.8',
      high: '114.85',
      low: '113.21',
      close: '114.13',
      'adjusted close': '61.9423814536907',
      volume: '5785800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-30': {
      open: '114.43',
      high: '115.94',
      low: '113.8',
      close: '115.59',
      'adjusted close': '62.7347750129862',
      volume: '5597800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-29': {
      open: '112.9',
      high: '114.55',
      low: '111.81',
      close: '114.43',
      'adjusted close': '62.1052020480665',
      volume: '6128700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-28': {
      open: '114.1',
      high: '114.38',
      low: '112.1',
      close: '112.15',
      'adjusted close': '60.8677655308106',
      volume: '6161200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-27': {
      open: '116.25',
      high: '116.3',
      low: '114.07',
      close: '114.2',
      'adjusted close': '61.9803729257118',
      volume: '6894100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-26': {
      open: '115.9',
      high: '116.4',
      low: '115.2',
      close: '116.33',
      'adjusted close': '63.1363991457798',
      volume: '4854300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-23': {
      open: '114.29',
      high: '115.37',
      low: '113.72',
      close: '115.35',
      'adjusted close': '62.6045185374856',
      volume: '1714000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-21': {
      open: '114.95',
      high: '115.25',
      low: '113.05',
      close: '114.34',
      'adjusted close': '62.0563558697538',
      volume: '4184700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-20': {
      open: '114.35',
      high: '116.8',
      low: '114.25',
      close: '115.44',
      'adjusted close': '62.6533647157983',
      volume: '6452200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-19': {
      open: '114.7',
      high: '115.8',
      low: '113.77',
      close: '115.0',
      'adjusted close': '62.4145611773805',
      volume: '4931300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-16': {
      open: '115.0',
      high: '115.0',
      low: '112.55',
      close: '114.5',
      'adjusted close': '62.1431935200875',
      volume: '6661500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-15': {
      open: '114.1',
      high: '116.33',
      low: '113.84',
      close: '114.75',
      'adjusted close': '62.278877348734',
      volume: '7013800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-14': {
      open: '116.7',
      high: '116.85',
      low: '113.33',
      close: '114.35',
      'adjusted close': '62.0617832228996',
      volume: '8091700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-13': {
      open: '115.7',
      high: '117.0',
      low: '115.7',
      close: '116.7',
      'adjusted close': '63.3372112121766',
      volume: '9216700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-12': {
      open: '113.0',
      high: '114.9',
      low: '112.05',
      close: '114.86',
      'adjusted close': '62.3385782333385',
      volume: '5608400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-09': {
      open: '113.1',
      high: '114.9',
      low: '113.1',
      close: '114.08',
      'adjusted close': '61.9152446879614',
      volume: '5045900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-08': {
      open: '113.96',
      high: '115.56',
      low: '113.61',
      close: '113.81',
      'adjusted close': '61.7687061530233',
      volume: '7991200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-07': {
      open: '113.05',
      high: '115.2',
      low: '113.03',
      close: '113.85',
      'adjusted close': '61.7904155656067',
      volume: '10129700',
      'dividend amount': '0.1400',
      'split coefficient': '1.0',
    },
    '2001-11-06': {
      open: '109.6',
      high: '114.8',
      low: '109.0',
      close: '113.61',
      'adjusted close': '61.5844294447634',
      volume: '8509700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-05': {
      open: '109.95',
      high: '110.59',
      low: '109.08',
      close: '109.97',
      'adjusted close': '59.6112992345799',
      volume: '5708500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-02': {
      open: '109.15',
      high: '110.3',
      low: '108.77',
      close: '109.5',
      'adjusted close': '59.3565269272211',
      volume: '6216900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-11-01': {
      open: '107.25',
      high: '110.17',
      low: '106.9',
      close: '109.89',
      'adjusted close': '59.567933735455',
      volume: '8024000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-31': {
      open: '109.6',
      high: '111.12',
      low: '108.07',
      close: '108.07',
      'adjusted close': '58.5813686303633',
      volume: '7177700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-30': {
      open: '107.8',
      high: '109.75',
      low: '107.76',
      close: '108.65',
      'adjusted close': '58.8957684990189',
      volume: '8625100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-29': {
      open: '110.5',
      high: '110.7',
      low: '108.62',
      close: '108.62',
      'adjusted close': '58.8795064368471',
      volume: '7072800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-26': {
      open: '110.0',
      high: '112.1',
      low: '109.62',
      close: '111.16',
      'adjusted close': '60.256361034063',
      volume: '10142500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-25': {
      open: '108.05',
      high: '110.85',
      low: '106.75',
      close: '110.66',
      'adjusted close': '59.9853266645323',
      volume: '9683400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-24': {
      open: '106.5',
      high: '108.75',
      low: '106.09',
      close: '108.57',
      'adjusted close': '58.852402999894',
      volume: '8293200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-23': {
      open: '105.75',
      high: '106.69',
      low: '104.55',
      close: '105.81',
      'adjusted close': '57.3562932800846',
      volume: '7429300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-22': {
      open: '102.65',
      high: '105.78',
      low: '101.9',
      close: '105.25',
      'adjusted close': '57.0527347862102',
      volume: '7623900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-19': {
      open: '101.65',
      high: '102.74',
      low: '100.07',
      close: '102.65',
      'adjusted close': '55.6433560646506',
      volume: '11771900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-18': {
      open: '103.05',
      high: '103.75',
      low: '100.78',
      close: '101.26',
      'adjusted close': '54.8898805173553',
      volume: '10694900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-17': {
      open: '106.3',
      high: '106.7',
      low: '102.9',
      close: '102.9',
      'adjusted close': '55.778873249416',
      volume: '18920500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-16': {
      open: '101.87',
      high: '103.0',
      low: '99.66',
      close: '101.85',
      'adjusted close': '55.2097010734015',
      volume: '11946000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-15': {
      open: '99.85',
      high: '102.76',
      low: '99.85',
      close: '102.0',
      'adjusted close': '55.2910113842607',
      volume: '10204200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-12': {
      open: '98.7',
      high: '101.0',
      low: '98.0',
      close: '100.84',
      'adjusted close': '54.6622116469495',
      volume: '11501200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-11': {
      open: '97.7',
      high: '99.4',
      low: '96.5',
      close: '99.4',
      'adjusted close': '53.8816326627012',
      volume: '11501600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-10': {
      open: '95.0',
      high: '97.3',
      low: '94.9',
      close: '97.25',
      'adjusted close': '52.7161848737192',
      volume: '10636900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-09': {
      open: '98.5',
      high: '98.5',
      low: '96.76',
      close: '97.14',
      'adjusted close': '52.6565573124224',
      volume: '7525300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-08': {
      open: '98.03',
      high: '99.0',
      low: '96.75',
      close: '98.5',
      'adjusted close': '53.3937707975459',
      volume: '7093400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-05': {
      open: '96.65',
      high: '98.45',
      low: '95.37',
      close: '98.02',
      'adjusted close': '53.1335778027965',
      volume: '9708200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-04': {
      open: '97.15',
      high: '98.88',
      low: '96.8',
      close: '97.31',
      'adjusted close': '52.7487089980629',
      volume: '9785100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-03': {
      open: '92.9',
      high: '97.62',
      low: '92.4',
      close: '96.95',
      'adjusted close': '52.5535642520008',
      volume: '11117000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-02': {
      open: '92.3',
      high: '93.9',
      low: '92.1',
      close: '93.77',
      'adjusted close': '50.8297856617856',
      volume: '7221200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-10-01': {
      open: '91.72',
      high: '93.48',
      low: '91.34',
      close: '92.71',
      'adjusted close': '50.2551927983805',
      volume: '8676200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-28': {
      open: '90.2',
      high: '92.71',
      low: '89.9',
      close: '91.72',
      'adjusted close': '49.7185447467098',
      volume: '11077200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-27': {
      open: '91.3',
      high: '91.5',
      low: '87.49',
      close: '90.0',
      'adjusted close': '48.7861865155242',
      volume: '13172300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-26': {
      open: '94.45',
      high: '94.45',
      low: '90.3',
      close: '91.3',
      'adjusted close': '49.490875876304',
      volume: '13783100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-25': {
      open: '94.1',
      high: '96.0',
      low: '93.41',
      close: '94.45',
      'adjusted close': '51.1983924043473',
      volume: '12575100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-24': {
      open: '91.8',
      high: '95.54',
      low: '91.8',
      close: '94.8',
      'adjusted close': '51.3881164630188',
      volume: '11201800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-21': {
      open: '90.6',
      high: '93.5',
      low: '89.5',
      close: '90.5',
      'adjusted close': '49.0572208850549',
      volume: '18364700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-20': {
      open: '94.1',
      high: '95.75',
      low: '92.85',
      close: '93.4',
      'adjusted close': '50.6292202283329',
      volume: '15182900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-19': {
      open: '96.4',
      high: '96.85',
      low: '92.45',
      close: '96.0',
      'adjusted close': '52.0385989498925',
      volume: '14883500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-18': {
      open: '94.0',
      high: '96.75',
      low: '93.68',
      close: '96.4',
      'adjusted close': '52.255426445517',
      volume: '12305000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-17': {
      open: '93.9',
      high: '95.86',
      low: '92.55',
      close: '93.34',
      'adjusted close': '50.5966961039892',
      volume: '15132800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-10': {
      open: '96.0',
      high: '97.4',
      low: '95.59',
      close: '96.47',
      'adjusted close': '52.2933712572513',
      volume: '10311500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-07': {
      open: '97.9',
      high: '98.15',
      low: '96.0',
      close: '96.59',
      'adjusted close': '52.3584195059387',
      volume: '10507000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-06': {
      open: '100.68',
      high: '100.68',
      low: '97.65',
      close: '98.0',
      'adjusted close': '53.1227364280152',
      volume: '9043100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-05': {
      open: '101.5',
      high: '102.83',
      low: '99.61',
      close: '100.35',
      'adjusted close': '54.3965979648095',
      volume: '11880700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-09-04': {
      open: '100.15',
      high: '103.13',
      low: '99.29',
      close: '101.49',
      'adjusted close': '55.0145563273394',
      volume: '9891800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-31': {
      open: '101.0',
      high: '101.36',
      low: '99.24',
      close: '99.95',
      'adjusted close': '54.1797704691849',
      volume: '8126600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-30': {
      open: '102.75',
      high: '103.4',
      low: '98.86',
      close: '100.36',
      'adjusted close': '54.4020186522001',
      volume: '15015500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-29': {
      open: '105.75',
      high: '105.9',
      low: '103.82',
      close: '104.13',
      'adjusted close': '56.4456177984615',
      volume: '4840300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-28': {
      open: '106.9',
      high: '107.17',
      low: '104.87',
      close: '104.95',
      'adjusted close': '56.8901141644918',
      volume: '5808100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-27': {
      open: '106.99',
      high: '108.2',
      low: '106.64',
      close: '106.86',
      'adjusted close': '57.925465456099',
      volume: '4540900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-24': {
      open: '103.95',
      high: '107.8',
      low: '103.41',
      close: '106.99',
      'adjusted close': '57.995934392177',
      volume: '7711900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-23': {
      open: '104.0',
      high: '104.7',
      low: '103.0',
      close: '103.0',
      'adjusted close': '55.8330801233221',
      volume: '5474300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-22': {
      open: '102.5',
      high: '104.04',
      low: '101.76',
      close: '103.96',
      'adjusted close': '56.353466112821',
      volume: '8029000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-21': {
      open: '103.85',
      high: '104.97',
      low: '101.4',
      close: '101.89',
      'adjusted close': '55.231383822964',
      volume: '6515900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-20': {
      open: '104.68',
      high: '105.0',
      low: '102.8',
      close: '104.1',
      'adjusted close': '56.4293557362896',
      volume: '5612700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-17': {
      open: '105.49',
      high: '105.49',
      low: '103.9',
      close: '104.59',
      'adjusted close': '56.6949694184297',
      volume: '7006200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-16': {
      open: '104.2',
      high: '106.02',
      low: '103.66',
      close: '105.75',
      'adjusted close': '57.3237691557409',
      volume: '6215200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-15': {
      open: '106.35',
      high: '106.59',
      low: '105.01',
      close: '105.01',
      'adjusted close': '56.9226382888355',
      volume: '4712900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-14': {
      open: '106.5',
      high: '106.85',
      low: '105.4',
      close: '106.2',
      'adjusted close': '57.5677000883185',
      volume: '5730200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-13': {
      open: '105.0',
      high: '106.64',
      low: '104.65',
      close: '105.86',
      'adjusted close': '57.3833967170377',
      volume: '6024400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-10': {
      open: '103.85',
      high: '104.95',
      low: '102.81',
      close: '104.95',
      'adjusted close': '56.8901141644918',
      volume: '5254800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-09': {
      open: '103.7',
      high: '104.54',
      low: '102.7',
      close: '104.08',
      'adjusted close': '56.4185143615084',
      volume: '6763200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-08': {
      open: '105.0',
      high: '106.1',
      low: '104.1',
      close: '104.19',
      'adjusted close': '56.4781419228052',
      volume: '6398000',
      'dividend amount': '0.1400',
      'split coefficient': '1.0',
    },
    '2001-08-07': {
      open: '105.8',
      high: '107.37',
      low: '105.26',
      close: '106.25',
      'adjusted close': '57.517517294144',
      volume: '4029300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-06': {
      open: '107.25',
      high: '107.37',
      low: '105.79',
      close: '106.51',
      'adjusted close': '57.6582660423462',
      volume: '4176800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-03': {
      open: '107.8',
      high: '110.09',
      low: '107.1',
      close: '108.18',
      'adjusted close': '58.5623060788753',
      volume: '3704100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-02': {
      open: '108.5',
      high: '109.47',
      low: '107.21',
      close: '108.8',
      'adjusted close': '58.8979377092035',
      volume: '5675100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-08-01': {
      open: '105.35',
      high: '108.5',
      low: '105.31',
      close: '107.06',
      'adjusted close': '57.9560037789276',
      volume: '6036100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-31': {
      open: '105.9',
      high: '107.0',
      low: '105.15',
      close: '105.21',
      'adjusted close': '56.9545223013355',
      volume: '5603000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-30': {
      open: '104.9',
      high: '106.25',
      low: '104.76',
      close: '105.85',
      'adjusted close': '57.3009807584484',
      volume: '4030800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-27': {
      open: '104.75',
      high: '105.95',
      low: '104.11',
      close: '104.7',
      'adjusted close': '56.6784382183236',
      volume: '4346500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-26': {
      open: '104.75',
      high: '106.0',
      low: '102.93',
      close: '106.0',
      'adjusted close': '57.3821819593343',
      volume: '8428700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-25': {
      open: '104.5',
      high: '105.7',
      low: '102.73',
      close: '104.89',
      'adjusted close': '56.781293072779',
      volume: '6247600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-24': {
      open: '104.85',
      high: '106.15',
      low: '103.79',
      close: '104.51',
      'adjusted close': '56.5755833638682',
      volume: '7299800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-23': {
      open: '105.7',
      high: '106.95',
      low: '105.3',
      close: '105.85',
      'adjusted close': '57.3009807584484',
      volume: '6315700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-20': {
      open: '103.4',
      high: '105.7',
      low: '103.03',
      close: '105.7',
      'adjusted close': '57.2197795575626',
      volume: '8991700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-19': {
      open: '105.6',
      high: '106.0',
      low: '103.1',
      close: '104.0',
      'adjusted close': '56.2994992808563',
      volume: '13644500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-18': {
      open: '106.1',
      high: '107.6',
      low: '103.2',
      close: '104.28',
      'adjusted close': '56.4510748558432',
      volume: '11670300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-17': {
      open: '106.1',
      high: '108.58',
      low: '104.75',
      close: '108.53',
      'adjusted close': '58.751775547609',
      volume: '8662400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-16': {
      open: '108.53',
      high: '109.38',
      low: '107.28',
      close: '107.82',
      'adjusted close': '58.3674231967493',
      volume: '5330100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-13': {
      open: '106.15',
      high: '109.3',
      low: '106.11',
      close: '108.53',
      'adjusted close': '58.751775547609',
      volume: '7841800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-12': {
      open: '105.9',
      high: '107.8',
      low: '105.62',
      close: '107.25',
      'adjusted close': '58.058858633383',
      volume: '8766400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-11': {
      open: '101.6',
      high: '104.13',
      low: '101.56',
      close: '103.85',
      'adjusted close': '56.2182980799704',
      volume: '8895400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-10': {
      open: '104.73',
      high: '105.4',
      low: '101.6',
      close: '101.96',
      'adjusted close': '55.1951629488087',
      volume: '10266600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-09': {
      open: '106.5',
      high: '106.95',
      low: '103.65',
      close: '104.72',
      'adjusted close': '56.6892650451084',
      volume: '9312600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-06': {
      open: '110.9',
      high: '110.9',
      low: '105.81',
      close: '106.5',
      'adjusted close': '57.6528526289538',
      volume: '11704600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-05': {
      open: '112.6',
      high: '113.26',
      low: '111.79',
      close: '112.1',
      'adjusted close': '60.6843641286922',
      volume: '4419000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-03': {
      open: '114.1',
      high: '114.19',
      low: '112.76',
      close: '112.98',
      'adjusted close': '61.1607445072225',
      volume: '3325800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-07-02': {
      open: '113.5',
      high: '115.4',
      low: '112.96',
      close: '114.35',
      'adjusted close': '61.90238214198',
      volume: '5913100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-29': {
      open: '115.6',
      high: '116.65',
      low: '113.0',
      close: '113.5',
      'adjusted close': '61.4422420036268',
      volume: '9477400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-28': {
      open: '113.8',
      high: '116.95',
      low: '113.75',
      close: '115.1',
      'adjusted close': '62.3083881464092',
      volume: '8181000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-27': {
      open: '113.1',
      high: '114.18',
      low: '112.45',
      close: '113.52',
      'adjusted close': '61.4530688304116',
      volume: '4814100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-26': {
      open: '111.25',
      high: '114.05',
      low: '111.1',
      close: '113.04',
      'adjusted close': '61.1932249875769',
      volume: '7081900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-25': {
      open: '113.75',
      high: '114.9',
      low: '111.78',
      close: '112.65',
      'adjusted close': '60.9821018652737',
      volume: '5675900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-22': {
      open: '112.6',
      high: '114.43',
      low: '112.5',
      close: '112.87',
      'adjusted close': '61.1011969599062',
      volume: '5607200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-21': {
      open: '112.15',
      high: '114.7',
      low: '111.36',
      close: '112.6',
      'adjusted close': '60.9550347983117',
      volume: '8155800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-20': {
      open: '113.5',
      high: '114.99',
      low: '111.76',
      close: '113.09',
      'adjusted close': '61.2202920545388',
      volume: '8828300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-19': {
      open: '116.26',
      high: '117.25',
      low: '114.56',
      close: '114.84',
      'adjusted close': '62.1676393982071',
      volume: '6152800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-18': {
      open: '113.98',
      high: '115.44',
      low: '113.31',
      close: '114.27',
      'adjusted close': '61.8590748348408',
      volume: '5182800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-15': {
      open: '115.1',
      high: '115.45',
      low: '113.38',
      close: '113.6',
      'adjusted close': '61.4963761375507',
      volume: '12470100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-14': {
      open: '115.6',
      high: '116.08',
      low: '113.99',
      close: '115.75',
      'adjusted close': '62.6602600169146',
      volume: '7502500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-13': {
      open: '117.25',
      high: '118.47',
      low: '116.53',
      close: '116.54',
      'adjusted close': '63.0879196749134',
      volume: '5703200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-12': {
      open: '116.75',
      high: '117.8',
      low: '115.55',
      close: '117.25',
      'adjusted close': '63.4722720257731',
      volume: '7301500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-11': {
      open: '116.3',
      high: '118.14',
      low: '116.1',
      close: '117.36',
      'adjusted close': '63.5318195730894',
      volume: '6542700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-08': {
      open: '117.5',
      high: '117.5',
      low: '115.6',
      close: '116.1',
      'adjusted close': '62.8497294856482',
      volume: '3099100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-07': {
      open: '117.0',
      high: '118.25',
      low: '117.0',
      close: '117.25',
      'adjusted close': '63.4722720257731',
      volume: '6423700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-06': {
      open: '117.6',
      high: '119.0',
      low: '116.5',
      close: '117.5',
      'adjusted close': '63.6076073605828',
      volume: '9624200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-05': {
      open: '113.64',
      high: '117.6',
      low: '113.56',
      close: '116.97',
      'adjusted close': '63.3206964507862',
      volume: '6154400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-04': {
      open: '113.25',
      high: '114.75',
      low: '112.85',
      close: '113.64',
      'adjusted close': '61.5180297911203',
      volume: '3812200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-06-01': {
      open: '112.5',
      high: '113.6',
      low: '111.9',
      close: '112.89',
      'adjusted close': '61.112023786691',
      volume: '5138900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-31': {
      open: '112.65',
      high: '113.86',
      low: '111.64',
      close: '111.8',
      'adjusted close': '60.5219617269205',
      volume: '6724100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-30': {
      open: '114.0',
      high: '114.1',
      low: '112.0',
      close: '112.65',
      'adjusted close': '60.9821018652737',
      volume: '9598700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-29': {
      open: '117.35',
      high: '117.59',
      low: '115.08',
      close: '115.27',
      'adjusted close': '62.4004161740799',
      volume: '5818000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-25': {
      open: '119.52',
      high: '119.9',
      low: '117.76',
      close: '117.8',
      'adjusted close': '63.7700097623545',
      volume: '5629100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-24': {
      open: '117.5',
      high: '119.6',
      low: '117.5',
      close: '119.6',
      'adjusted close': '64.7444241729847',
      volume: '7726400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-23': {
      open: '117.8',
      high: '118.95',
      low: '117.1',
      close: '117.4',
      'adjusted close': '63.5534732266589',
      volume: '6402600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-22': {
      open: '119.7',
      high: '119.7',
      low: '117.25',
      close: '118.01',
      'adjusted close': '63.8836914435947',
      volume: '6005300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-21': {
      open: '117.95',
      high: '119.9',
      low: '117.55',
      close: '119.04',
      'adjusted close': '64.4412730230109',
      volume: '8409400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-18': {
      open: '115.07',
      high: '117.68',
      low: '114.9',
      close: '117.44',
      'adjusted close': '63.5751268802285',
      volume: '6714900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-17': {
      open: '115.8',
      high: '117.09',
      low: '115.05',
      close: '115.07',
      'adjusted close': '62.292147906232',
      volume: '6887700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-16': {
      open: '112.5',
      high: '115.8',
      low: '112.2',
      close: '115.8',
      'adjusted close': '62.6873270838765',
      volume: '7796700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-15': {
      open: '113.5',
      high: '114.15',
      low: '112.5',
      close: '113.58',
      'adjusted close': '61.4855493107659',
      volume: '5873400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-14': {
      open: '111.2',
      high: '113.18',
      low: '111.0',
      close: '112.56',
      'adjusted close': '60.9333811447422',
      volume: '5254800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-11': {
      open: '113.75',
      high: '114.15',
      low: '110.96',
      close: '111.81',
      'adjusted close': '60.5273751403129',
      volume: '8390200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-10': {
      open: '118.5',
      high: '118.9',
      low: '115.2',
      close: '115.2',
      'adjusted close': '62.3625222803331',
      volume: '7993900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-09': {
      open: '116.2',
      high: '118.18',
      low: '115.3',
      close: '116.98',
      'adjusted close': '63.3261098641785',
      volume: '7680200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-08': {
      open: '117.25',
      high: '117.75',
      low: '115.5',
      close: '117.7',
      'adjusted close': '63.7158756284306',
      volume: '7605800',
      'dividend amount': '0.1400',
      'split coefficient': '1.0',
    },
    '2001-05-07': {
      open: '116.2',
      high: '117.25',
      low: '115.0',
      close: '115.9',
      'adjusted close': '62.6669211247039',
      volume: '6088600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-04': {
      open: '112.0',
      high: '115.86',
      low: '111.2',
      close: '115.86',
      'adjusted close': '62.645293196792',
      volume: '8681400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-03': {
      open: '114.6',
      high: '115.1',
      low: '112.35',
      close: '113.7',
      'adjusted close': '61.4773850895499',
      volume: '6578000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-02': {
      open: '118.3',
      high: '118.95',
      low: '113.74',
      close: '115.4',
      'adjusted close': '62.3965720258053',
      volume: '9706200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-05-01': {
      open: '115.85',
      high: '118.65',
      low: '114.9',
      close: '118.51',
      'adjusted close': '64.0781434209548',
      volume: '7790800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-30': {
      open: '116.4',
      high: '118.05',
      low: '114.72',
      close: '115.14',
      'adjusted close': '62.255990494378',
      volume: '7357200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-27': {
      open: '116.8',
      high: '116.9',
      low: '114.55',
      close: '116.2',
      'adjusted close': '62.8291305840431',
      volume: '7390600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-26': {
      open: '114.95',
      high: '116.7',
      low: '113.68',
      close: '113.74',
      'adjusted close': '61.4990130174618',
      volume: '7346100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-25': {
      open: '112.0',
      high: '114.85',
      low: '111.99',
      close: '114.85',
      'adjusted close': '62.0991880170168',
      volume: '6858500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-24': {
      open: '113.4',
      high: '114.75',
      low: '112.3',
      close: '112.67',
      'adjusted close': '60.9204659458187',
      volume: '8742000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-23': {
      open: '114.01',
      high: '114.05',
      low: '111.68',
      close: '112.0',
      'adjusted close': '60.5581981532946',
      volume: '8537500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-20': {
      open: '114.35',
      high: '116.4',
      low: '113.75',
      close: '114.83',
      'adjusted close': '62.0883740530608',
      volume: '12759100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-19': {
      open: '112.0',
      high: '115.9',
      low: '110.45',
      close: '114.47',
      'adjusted close': '61.8937227018538',
      volume: '25822400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-18': {
      open: '103.0',
      high: '110.0',
      low: '102.3',
      close: '106.5',
      'adjusted close': '57.5843580654096',
      volume: '19814300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-17': {
      open: '97.0',
      high: '99.88',
      low: '97.0',
      close: '99.7',
      'adjusted close': '53.9076103203881',
      volume: '9957300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-16': {
      open: '96.2',
      high: '99.89',
      low: '95.2',
      close: '96.75',
      'adjusted close': '52.3125506368861',
      volume: '9188500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-12': {
      open: '96.4',
      high: '97.5',
      low: '95.15',
      close: '96.2',
      'adjusted close': '52.0151666280976',
      volume: '9390100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-11': {
      open: '101.5',
      high: '101.94',
      low: '95.8',
      close: '97.43',
      'adjusted close': '52.6802254113883',
      volume: '11498400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-10': {
      open: '97.0',
      high: '99.9',
      low: '96.56',
      close: '99.05',
      'adjusted close': '53.5561564918199',
      volume: '10499400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-09': {
      open: '98.5',
      high: '98.74',
      low: '93.8',
      close: '96.0',
      'adjusted close': '51.9070269885382',
      volume: '9486600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-06': {
      open: '98.0',
      high: '100.0',
      low: '96.4',
      close: '97.95',
      'adjusted close': '52.9613884742429',
      volume: '13424200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-05': {
      open: '95.8',
      high: '100.0',
      low: '95.53',
      close: '98.21',
      'adjusted close': '53.1019700056702',
      volume: '12506100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-04': {
      open: '90.45',
      high: '94.05',
      low: '90.05',
      close: '92.0',
      'adjusted close': '49.7442341973491',
      volume: '13515600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-03': {
      open: '94.6',
      high: '94.65',
      low: '90.1',
      close: '90.39',
      'adjusted close': '48.8737100988955',
      volume: '13330000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-04-02': {
      open: '97.45',
      high: '98.1',
      low: '93.58',
      close: '94.66',
      'adjusted close': '51.1824914034898',
      volume: '8787000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-30': {
      open: '96.5',
      high: '97.56',
      low: '95.14',
      close: '96.18',
      'adjusted close': '52.0043526641417',
      volume: '9461300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-29': {
      open: '94.46',
      high: '97.07',
      low: '94.26',
      close: '95.04',
      'adjusted close': '51.3879567186528',
      volume: '10553700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-28': {
      open: '97.9',
      high: '98.45',
      low: '94.25',
      close: '94.41',
      'adjusted close': '51.0473168540405',
      volume: '11890500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-27': {
      open: '94.0',
      high: '100.0',
      low: '93.97',
      close: '99.5',
      'adjusted close': '53.7994706808286',
      volume: '11851200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-26': {
      open: '95.25',
      high: '95.66',
      low: '93.75',
      close: '95.4',
      'adjusted close': '51.5826080698598',
      volume: '8581200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-23': {
      open: '93.5',
      high: '94.99',
      low: '90.29',
      close: '93.51',
      'adjusted close': '50.560688476023',
      volume: '12233600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-22': {
      open: '89.12',
      high: '91.0',
      low: '87.65',
      close: '89.1',
      'adjusted close': '48.176209423737',
      volume: '13328200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-21': {
      open: '88.45',
      high: '91.6',
      low: '87.75',
      close: '89.08',
      'adjusted close': '48.1653954597811',
      volume: '11013000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-20': {
      open: '91.6',
      high: '92.03',
      low: '88.1',
      close: '88.3',
      'adjusted close': '47.7436508654992',
      volume: '10101100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-19': {
      open: '91.0',
      high: '92.8',
      low: '89.2',
      close: '92.6',
      'adjusted close': '50.0686531160275',
      volume: '10235500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-16': {
      open: '93.9',
      high: '93.9',
      low: '88.2',
      close: '90.1',
      'adjusted close': '48.7169076215343',
      volume: '20029400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-15': {
      open: '96.0',
      high: '97.87',
      low: '95.17',
      close: '95.56',
      'adjusted close': '51.6691197815074',
      volume: '8420000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-14': {
      open: '95.4',
      high: '98.9',
      low: '93.34',
      close: '94.96',
      'adjusted close': '51.344700862829',
      volume: '14246600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-13': {
      open: '95.1',
      high: '98.44',
      low: '94.2',
      close: '98.39',
      'adjusted close': '53.1992956812737',
      volume: '12127900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-12': {
      open: '97.9',
      high: '98.4',
      low: '95.02',
      close: '95.49',
      'adjusted close': '51.6312709076616',
      volume: '14494800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-09': {
      open: '105.0',
      high: '105.01',
      low: '97.9',
      close: '99.29',
      'adjusted close': '53.6859240592912',
      volume: '16688100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-08': {
      open: '107.17',
      high: '107.34',
      low: '105.37',
      close: '106.47',
      'adjusted close': '57.5681371194756',
      volume: '6793600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-07': {
      open: '107.45',
      high: '108.25',
      low: '105.66',
      close: '107.55',
      'adjusted close': '58.1520911730967',
      volume: '7788900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-06': {
      open: '106.25',
      high: '108.4',
      low: '105.25',
      close: '106.0',
      'adjusted close': '57.3140089665109',
      volume: '8120000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-05': {
      open: '102.81',
      high: '105.6',
      low: '102.64',
      close: '104.91',
      'adjusted close': '56.7246479309119',
      volume: '6743900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-02': {
      open: '104.0',
      high: '107.65',
      low: '100.94',
      close: '102.3',
      'adjusted close': '55.313425634661',
      volume: '14745500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-03-01': {
      open: '99.3',
      high: '106.4',
      low: '97.5',
      close: '106.05',
      'adjusted close': '57.3410438764008',
      volume: '15102000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-28': {
      open: '102.95',
      high: '103.54',
      low: '99.1',
      close: '99.9',
      'adjusted close': '54.0157499599476',
      volume: '11933200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-27': {
      open: '104.9',
      high: '106.87',
      low: '102.56',
      close: '102.59',
      'adjusted close': '55.4702281120222',
      volume: '9787900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-26': {
      open: '105.8',
      high: '105.8',
      low: '101.83',
      close: '105.3',
      'adjusted close': '56.9355202280528',
      volume: '8344100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-23': {
      open: '107.5',
      high: '107.5',
      low: '100.25',
      close: '104.0',
      'adjusted close': '56.2326125709164',
      volume: '15276600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-22': {
      open: '106.5',
      high: '110.9',
      low: '103.38',
      close: '108.9',
      'adjusted close': '58.882033740123',
      volume: '11709000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-21': {
      open: '109.55',
      high: '111.5',
      low: '107.29',
      close: '107.51',
      'adjusted close': '58.1304632451848',
      volume: '9156900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-20': {
      open: '114.55',
      high: '115.6',
      low: '110.78',
      close: '111.5',
      'adjusted close': '60.2878490543959',
      volume: '6748300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-16': {
      open: '114.55',
      high: '115.75',
      low: '113.94',
      close: '115.0',
      'adjusted close': '62.1802927466864',
      volume: '7073000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-15': {
      open: '116.8',
      high: '118.64',
      low: '116.38',
      close: '116.78',
      'adjusted close': '63.1427355387655',
      volume: '8098700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-14': {
      open: '113.75',
      high: '116.17',
      low: '112.5',
      close: '115.1',
      'adjusted close': '62.2343625664661',
      volume: '7032900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-13': {
      open: '114.15',
      high: '117.38',
      low: '113.07',
      close: '113.75',
      'adjusted close': '61.5044199994398',
      volume: '7193200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-12': {
      open: '110.55',
      high: '115.72',
      low: '110.3',
      close: '114.9',
      'adjusted close': '62.1262229269066',
      volume: '6729300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-09': {
      open: '114.03',
      high: '114.99',
      low: '111.0',
      close: '112.0',
      'adjusted close': '60.5581981532946',
      volume: '6318800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-08': {
      open: '116.91',
      high: '118.2',
      low: '114.03',
      close: '114.1',
      'adjusted close': '61.6936643686688',
      volume: '8380800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-07': {
      open: '113.4',
      high: '117.6',
      low: '112.67',
      close: '116.91',
      'adjusted close': '63.2130263044792',
      volume: '9006900',
      'dividend amount': '0.1300',
      'split coefficient': '1.0',
    },
    '2001-02-06': {
      open: '112.23',
      high: '115.69',
      low: '111.2',
      close: '114.19',
      'adjusted close': '61.6737480665454',
      volume: '7526400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-05': {
      open: '109.4',
      high: '112.6',
      low: '109.3',
      close: '112.22',
      'adjusted close': '60.6097557406754',
      volume: '5113700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-02': {
      open: '112.75',
      high: '113.99',
      low: '109.6',
      close: '110.27',
      'adjusted close': '59.5565653673523',
      volume: '5428400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-02-01': {
      open: '112.4',
      high: '114.34',
      low: '111.2',
      close: '114.05',
      'adjusted close': '61.5981343987171',
      volume: '5580800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-31': {
      open: '115.75',
      high: '116.75',
      low: '112.0',
      close: '112.0',
      'adjusted close': '60.4909342626595',
      volume: '8314200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-30': {
      open: '115.0',
      high: '116.75',
      low: '113.4',
      close: '116.61',
      'adjusted close': '62.9807843247207',
      volume: '8242000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-29': {
      open: '112.5',
      high: '115.5',
      low: '112.47',
      close: '114.98',
      'adjusted close': '62.1004251921481',
      volume: '5125000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-26': {
      open: '109.62',
      high: '115.25',
      low: '109.19',
      close: '114.19',
      'adjusted close': '61.6737480665454',
      volume: '9095200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-25': {
      open: '110.12',
      high: '111.12',
      low: '109.44',
      close: '110.75',
      'adjusted close': '59.815812228478',
      volume: '4982100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-24': {
      open: '109.06',
      high: '111.44',
      low: '108.94',
      close: '110.44',
      'adjusted close': '59.648381964001',
      volume: '6760900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-23': {
      open: '109.56',
      high: '109.94',
      low: '107.62',
      close: '109.06',
      'adjusted close': '58.9030472382647',
      volume: '9826900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-22': {
      open: '109.5',
      high: '109.94',
      low: '108.19',
      close: '108.56',
      'adjusted close': '58.6329984245921',
      volume: '9618500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-19': {
      open: '107.5',
      high: '113.94',
      low: '107.25',
      close: '111.25',
      'adjusted close': '60.0858610421506',
      volume: '14762200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-18': {
      open: '104.37',
      high: '110.0',
      low: '103.5',
      close: '108.31',
      'adjusted close': '58.4979740177558',
      volume: '25244900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-17': {
      open: '95.37',
      high: '97.75',
      low: '94.31',
      close: '96.69',
      'adjusted close': '52.2220395880049',
      volume: '9727000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-16': {
      open: '93.75',
      high: '94.0',
      low: '91.81',
      close: '92.75',
      'adjusted close': '50.0940549362649',
      volume: '5671900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-12': {
      open: '93.69',
      high: '96.44',
      low: '92.37',
      close: '93.81',
      'adjusted close': '50.6665584212508',
      volume: '6448000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-11': {
      open: '92.94',
      high: '94.25',
      low: '91.25',
      close: '93.69',
      'adjusted close': '50.6017467059693',
      volume: '9635000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-10': {
      open: '92.5',
      high: '94.94',
      low: '91.69',
      close: '93.44',
      'adjusted close': '50.4667222991331',
      volume: '7656100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-09': {
      open: '94.44',
      high: '95.75',
      low: '91.44',
      close: '92.56',
      'adjusted close': '49.9914363870693',
      volume: '7547100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-08': {
      open: '93.5',
      high: '93.94',
      low: '91.56',
      close: '93.56',
      'adjusted close': '50.5315340144145',
      volume: '6064800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-05': {
      open: '93.94',
      high: '94.69',
      low: '91.0',
      close: '94.0',
      'adjusted close': '50.7691769704464',
      volume: '8043200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-04': {
      open: '94.75',
      high: '99.75',
      low: '92.69',
      close: '93.19',
      'adjusted close': '50.3316978922968',
      volume: '15420500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-03': {
      open: '83.75',
      high: '95.0',
      low: '83.75',
      close: '94.62',
      'adjusted close': '51.1040374994004',
      volume: '12769900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2001-01-02': {
      open: '84.5',
      high: '87.5',
      low: '84.12',
      close: '84.81',
      'adjusted close': '45.8056797751442',
      volume: '8007200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-29': {
      open: '86.94',
      high: '86.94',
      low: '84.25',
      close: '85.0',
      'adjusted close': '45.9082983243398',
      volume: '6943700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-28': {
      open: '83.5',
      high: '88.19',
      low: '82.12',
      close: '85.25',
      'adjusted close': '46.0433227311761',
      volume: '9798600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-27': {
      open: '84.75',
      high: '88.12',
      low: '83.56',
      close: '84.69',
      'adjusted close': '45.7408680598628',
      volume: '8245100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-26': {
      open: '89.0',
      high: '89.56',
      low: '84.25',
      close: '84.81',
      'adjusted close': '45.8056797751442',
      volume: '6052300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-22': {
      open: '84.5',
      high: '89.37',
      low: '84.5',
      close: '89.0',
      'adjusted close': '48.0686888337205',
      volume: '9514000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-21': {
      open: '85.5',
      high: '87.94',
      low: '80.06',
      close: '81.56',
      'adjusted close': '44.0503624862724',
      volume: '12725500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-20': {
      open: '85.87',
      high: '88.0',
      low: '84.81',
      close: '86.0',
      'adjusted close': '46.448395951685',
      volume: '12579400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-19': {
      open: '90.19',
      high: '94.44',
      low: '89.81',
      close: '90.12',
      'adjusted close': '48.6735981763471',
      volume: '7705300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-18': {
      open: '88.37',
      high: '90.5',
      low: '88.37',
      close: '90.5',
      'adjusted close': '48.8788352747382',
      volume: '7693800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-15': {
      open: '90.0',
      high: '90.69',
      low: '87.31',
      close: '87.81',
      'adjusted close': '47.4259726571797',
      volume: '14254500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-14': {
      open: '92.0',
      high: '93.69',
      low: '91.81',
      close: '92.44',
      'adjusted close': '49.9266246717879',
      volume: '6131400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-13': {
      open: '94.87',
      high: '96.62',
      low: '90.69',
      close: '91.25',
      'adjusted close': '49.2839084952471',
      volume: '6992600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-12': {
      open: '95.25',
      high: '96.12',
      low: '92.5',
      close: '93.87',
      'adjusted close': '50.6989642788915',
      volume: '7913300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-11': {
      open: '96.5',
      high: '98.25',
      low: '93.75',
      close: '95.0',
      'adjusted close': '51.3092745977915',
      volume: '6185600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-08': {
      open: '98.0',
      high: '99.0',
      low: '95.75',
      close: '97.0',
      'adjusted close': '52.3894698524819',
      volume: '6140600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-07': {
      open: '95.06',
      high: '95.94',
      low: '93.12',
      close: '93.12',
      'adjusted close': '50.2938910583826',
      volume: '7922000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-06': {
      open: '101.75',
      high: '101.81',
      low: '94.81',
      close: '96.75',
      'adjusted close': '52.2544454456456',
      volume: '10096900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-05': {
      open: '100.44',
      high: '104.75',
      low: '99.62',
      close: '103.37',
      'adjusted close': '55.8298917386706',
      volume: '7804200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-04': {
      open: '96.0',
      high: '99.19',
      low: '95.75',
      close: '98.37',
      'adjusted close': '53.1294036019448',
      volume: '5566800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-12-01': {
      open: '94.44',
      high: '99.31',
      low: '94.37',
      close: '95.62',
      'adjusted close': '51.6441351267455',
      volume: '7525100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-30': {
      open: '98.0',
      high: '98.0',
      low: '92.25',
      close: '93.5',
      'adjusted close': '50.4991281567738',
      volume: '10651400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-29': {
      open: '97.75',
      high: '99.94',
      low: '96.69',
      close: '99.81',
      'adjusted close': '53.9071441853218',
      volume: '5524600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-28': {
      open: '98.75',
      high: '100.75',
      low: '97.37',
      close: '97.87',
      'adjusted close': '52.8593547882722',
      volume: '4799100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-27': {
      open: '100.44',
      high: '102.0',
      low: '98.44',
      close: '98.44',
      'adjusted close': '53.1672104358589',
      volume: '3866200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-24': {
      open: '99.37',
      high: '101.75',
      low: '99.25',
      close: '99.94',
      'adjusted close': '53.9773568768767',
      volume: '2497500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-22': {
      open: '98.5',
      high: '100.81',
      low: '98.31',
      close: '98.5',
      'adjusted close': '53.1996162934996',
      volume: '5537100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-21': {
      open: '103.25',
      high: '104.37',
      low: '98.5',
      close: '98.5',
      'adjusted close': '53.1996162934996',
      volume: '7180700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-20': {
      open: '101.5',
      high: '103.25',
      low: '100.87',
      close: '103.25',
      'adjusted close': '55.7650800233892',
      volume: '7426600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-17': {
      open: '98.31',
      high: '102.5',
      low: '98.25',
      close: '101.94',
      'adjusted close': '55.057552131567',
      volume: '7717400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-16': {
      open: '99.25',
      high: '99.69',
      low: '98.25',
      close: '98.25',
      'adjusted close': '53.0645918866633',
      volume: '3739700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-15': {
      open: '99.5',
      high: '100.06',
      low: '97.62',
      close: '99.37',
      'adjusted close': '53.6695012292899',
      volume: '4160300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-14': {
      open: '99.25',
      high: '99.87',
      low: '98.5',
      close: '99.5',
      'adjusted close': '53.7397139208448',
      volume: '5150900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-13': {
      open: '91.81',
      high: '99.44',
      low: '91.62',
      close: '97.44',
      'adjusted close': '52.6271128085138',
      volume: '8684900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-10': {
      open: '98.0',
      high: '98.37',
      low: '93.0',
      close: '93.0',
      'adjusted close': '50.2290793431012',
      volume: '6807900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-09': {
      open: '96.75',
      high: '100.0',
      low: '95.69',
      close: '99.44',
      'adjusted close': '53.7073080632041',
      volume: '9047900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-08': {
      open: '103.62',
      high: '103.75',
      low: '99.25',
      close: '100.0',
      'adjusted close': '54.0097627345174',
      volume: '6990800',
      'dividend amount': '0.1300',
      'split coefficient': '1.0',
    },
    '2000-11-07': {
      open: '101.12',
      high: '103.5',
      low: '100.69',
      close: '102.31',
      'adjusted close': '55.1856469126983',
      volume: '5420600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-06': {
      open: '99.87',
      high: '102.37',
      low: '99.44',
      close: '100.31',
      'adjusted close': '54.1068540886791',
      volume: '4448500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-03': {
      open: '102.5',
      high: '102.62',
      low: '99.37',
      close: '100.12',
      'adjusted close': '54.0043687703973',
      volume: '5848500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-02': {
      open: '99.0',
      high: '102.31',
      low: '98.5',
      close: '101.94',
      'adjusted close': '54.9860702402547',
      volume: '7445100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-11-01': {
      open: '98.44',
      high: '99.87',
      low: '97.25',
      close: '98.56',
      'adjusted close': '53.1629103676624',
      volume: '7466200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-31': {
      open: '93.75',
      high: '99.12',
      low: '93.75',
      close: '98.5',
      'adjusted close': '53.1305465829418',
      volume: '10902600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-30': {
      open: '93.5',
      high: '93.75',
      low: '90.31',
      close: '93.31',
      'adjusted close': '50.3310792046122',
      volume: '8084000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-27': {
      open: '93.0',
      high: '93.69',
      low: '91.0',
      close: '93.69',
      'adjusted close': '50.5360498411758',
      volume: '6431400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-26': {
      open: '88.0',
      high: '92.87',
      low: '87.62',
      close: '92.75',
      'adjusted close': '50.0290172138868',
      volume: '10135700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-25': {
      open: '90.5',
      high: '90.94',
      low: '86.94',
      close: '87.56',
      'adjusted close': '47.2295498355572',
      volume: '14058500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-24': {
      open: '93.0',
      high: '94.37',
      low: '90.19',
      close: '91.44',
      'adjusted close': '49.3224079141543',
      volume: '8826200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-23': {
      open: '94.75',
      high: '95.0',
      low: '91.06',
      close: '92.87',
      'adjusted close': '50.093744783328',
      volume: '9338600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-20': {
      open: '95.12',
      high: '96.25',
      low: '94.19',
      close: '94.75',
      'adjusted close': '51.107810037906',
      volume: '9369200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-19': {
      open: '98.0',
      high: '98.5',
      low: '92.69',
      close: '96.44',
      'adjusted close': '52.0193899742021',
      volume: '17966900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-18': {
      open: '96.75',
      high: '99.12',
      low: '90.25',
      close: '95.44',
      'adjusted close': '51.4799935621926',
      volume: '29777800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-17': {
      open: '111.94',
      high: '113.87',
      low: '108.56',
      close: '113.0',
      'adjusted close': '60.9517945570805',
      volume: '8882500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-16': {
      open: '111.5',
      high: '113.12',
      low: '108.56',
      close: '111.12',
      'adjusted close': '59.9377293025025',
      volume: '7710400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-13': {
      open: '104.19',
      high: '110.44',
      low: '104.19',
      close: '109.06',
      'adjusted close': '58.8265726937628',
      volume: '9092600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-12': {
      open: '114.25',
      high: '114.37',
      low: '103.12',
      close: '103.12',
      'adjusted close': '55.622558006426',
      volume: '9704900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-11': {
      open: '111.87',
      high: '112.94',
      low: '107.5',
      close: '112.0',
      'adjusted close': '60.4123981450709',
      volume: '8775800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-10': {
      open: '116.62',
      high: '117.12',
      low: '112.44',
      close: '114.87',
      'adjusted close': '61.9604658475383',
      volume: '5614200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-09': {
      open: '115.94',
      high: '119.37',
      low: '114.31',
      close: '117.94',
      'adjusted close': '63.6164128324077',
      volume: '4898000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-06': {
      open: '114.0',
      high: '116.12',
      low: '112.75',
      close: '116.0',
      'adjusted close': '62.5699837931092',
      volume: '6108400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-05': {
      open: '113.81',
      high: '114.56',
      low: '111.56',
      close: '113.19',
      'adjusted close': '61.0542798753623',
      volume: '5547000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-04': {
      open: '110.37',
      high: '114.87',
      low: '108.56',
      close: '114.37',
      'adjusted close': '61.6907676415336',
      volume: '11668900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-03': {
      open: '118.87',
      high: '119.37',
      low: '110.0',
      close: '110.56',
      'adjusted close': '59.6356673117771',
      volume: '11744200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-10-02': {
      open: '112.62',
      high: '119.62',
      low: '112.62',
      close: '117.81',
      'adjusted close': '63.5462912988465',
      volume: '8872200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-29': {
      open: '115.5',
      high: '116.94',
      low: '110.25',
      close: '112.62',
      'adjusted close': '60.7468239205168',
      volume: '9594600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-28': {
      open: '117.37',
      high: '118.87',
      low: '115.0',
      close: '115.25',
      'adjusted close': '62.165436484102',
      volume: '5309100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-27': {
      open: '120.06',
      high: '120.31',
      low: '115.5',
      close: '118.0',
      'adjusted close': '63.6487766171283',
      volume: '5948900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-26': {
      open: '120.0',
      high: '121.87',
      low: '118.37',
      close: '119.12',
      'adjusted close': '64.252900598579',
      volume: '6217200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-25': {
      open: '124.5',
      high: '126.62',
      low: '121.0',
      close: '123.0',
      'adjusted close': '66.3457586771761',
      volume: '4291000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-22': {
      open: '123.0',
      high: '124.31',
      low: '121.31',
      close: '123.87',
      'adjusted close': '66.8150335556244',
      volume: '5930100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-21': {
      open: '124.19',
      high: '126.69',
      low: '120.0',
      close: '121.5',
      'adjusted close': '65.5366640591617',
      volume: '4302200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-20': {
      open: '124.69',
      high: '126.56',
      low: '123.5',
      close: '124.75',
      'adjusted close': '67.2897023981928',
      volume: '4623400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-19': {
      open: '122.75',
      high: '126.25',
      low: '122.56',
      close: '124.94',
      'adjusted close': '67.3921877164746',
      volume: '3341000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-18': {
      open: '125.25',
      high: '126.0',
      low: '123.06',
      close: '123.25',
      'adjusted close': '66.4806077801785',
      volume: '3932800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-15': {
      open: '126.81',
      high: '127.25',
      low: '124.75',
      close: '125.0',
      'adjusted close': '67.4245515011952',
      volume: '5481700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-14': {
      open: '128.0',
      high: '129.19',
      low: '126.31',
      close: '126.87',
      'adjusted close': '68.4332227916531',
      volume: '4425500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-13': {
      open: '123.12',
      high: '128.81',
      low: '122.75',
      close: '127.69',
      'adjusted close': '68.8755278495009',
      volume: '5473600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-12': {
      open: '124.81',
      high: '126.87',
      low: '124.06',
      close: '125.0',
      'adjusted close': '67.4245515011952',
      volume: '5001700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-11': {
      open: '127.87',
      high: '128.25',
      low: '123.62',
      close: '124.5',
      'adjusted close': '67.1548532951904',
      volume: '7617700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-08': {
      open: '132.5',
      high: '132.94',
      low: '129.38',
      close: '129.5',
      'adjusted close': '69.8518353552382',
      volume: '4993700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-07': {
      open: '131.88',
      high: '134.5',
      low: '131.88',
      close: '133.38',
      'adjusted close': '71.9446934338353',
      volume: '4622800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-06': {
      open: '130.88',
      high: '134.88',
      low: '130.38',
      close: '131.44',
      'adjusted close': '70.8982643945368',
      volume: '6473000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-05': {
      open: '132.81',
      high: '133.31',
      low: '130.81',
      close: '131.19',
      'adjusted close': '70.7634152915344',
      volume: '4112100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-09-01': {
      open: '133.06',
      high: '134.94',
      low: '132.63',
      close: '133.63',
      'adjusted close': '72.0795425368377',
      volume: '5776300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-31': {
      open: '131.88',
      high: '134.19',
      low: '131.63',
      close: '132.02',
      'adjusted close': '71.2111143135023',
      volume: '5469500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-30': {
      open: '130.75',
      high: '131.5',
      low: '129.56',
      close: '130.31',
      'adjusted close': '70.288746448966',
      volume: '4928800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-29': {
      open: '131.5',
      high: '134.25',
      low: '131.0',
      close: '132.88',
      'adjusted close': '71.6749952278305',
      volume: '6040000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-28': {
      open: '129.88',
      high: '133.19',
      low: '129.75',
      close: '131.5',
      'adjusted close': '70.9306281792574',
      volume: '8536600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-25': {
      open: '124.31',
      high: '129.88',
      low: '124.25',
      close: '129.0',
      'adjusted close': '69.5821371492334',
      volume: '8279000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-24': {
      open: '123.0',
      high: '125.44',
      low: '122.69',
      close: '124.81',
      'adjusted close': '67.3220661829134',
      volume: '5988300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-23': {
      open: '120.31',
      high: '123.75',
      low: '119.87',
      close: '123.25',
      'adjusted close': '66.4806077801785',
      volume: '4251200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-22': {
      open: '121.19',
      high: '122.19',
      low: '121.0',
      close: '121.37',
      'adjusted close': '65.4665425256005',
      volume: '2769400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-21': {
      open: '121.06',
      high: '122.19',
      low: '120.06',
      close: '121.44',
      'adjusted close': '65.5043002744412',
      volume: '3234200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-18': {
      open: '122.37',
      high: '123.37',
      low: '120.12',
      close: '120.44',
      'adjusted close': '64.9649038624316',
      volume: '4127500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-17': {
      open: '119.75',
      high: '122.81',
      low: '119.5',
      close: '122.5',
      'adjusted close': '66.0760604711713',
      volume: '4338400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-16': {
      open: '121.06',
      high: '122.94',
      low: '119.5',
      close: '122.37',
      'adjusted close': '66.0059389376101',
      volume: '5946000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-15': {
      open: '122.06',
      high: '122.62',
      low: '120.75',
      close: '122.0',
      'adjusted close': '65.8063622651665',
      volume: '4378100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-14': {
      open: '120.0',
      high: '123.69',
      low: '119.31',
      close: '123.25',
      'adjusted close': '66.4806077801785',
      volume: '4949400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-11': {
      open: '119.75',
      high: '121.81',
      low: '119.19',
      close: '120.62',
      'adjusted close': '65.0619952165933',
      volume: '6142400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-10': {
      open: '118.75',
      high: '120.25',
      low: '117.87',
      close: '119.75',
      'adjusted close': '64.592720338145',
      volume: '5484100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-09': {
      open: '119.0',
      high: '120.81',
      low: '118.75',
      close: '118.75',
      'adjusted close': '64.0533239261354',
      volume: '6585400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-08': {
      open: '115.69',
      high: '119.62',
      low: '115.56',
      close: '118.87',
      'adjusted close': '64.1180514955766',
      volume: '5736700',
      'dividend amount': '0.1300',
      'split coefficient': '1.0',
    },
    '2000-08-07': {
      open: '116.62',
      high: '119.0',
      low: '115.81',
      close: '116.31',
      'adjusted close': '62.6686602474833',
      volume: '5910700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-04': {
      open: '116.0',
      high: '116.5',
      low: '114.31',
      close: '115.87',
      'adjusted close': '62.4315850990963',
      volume: '4460500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-03': {
      open: '113.0',
      high: '116.5',
      low: '112.31',
      close: '116.0',
      'adjusted close': '62.5016300293015',
      volume: '5567400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-02': {
      open: '111.0',
      high: '114.87',
      low: '110.75',
      close: '114.25',
      'adjusted close': '61.5587175073078',
      volume: '5877900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-08-01': {
      open: '112.0',
      high: '112.44',
      low: '110.25',
      close: '110.5',
      'adjusted close': '59.538190674464',
      volume: '2996500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-31': {
      open: '110.5',
      high: '113.69',
      low: '110.06',
      close: '112.25',
      'adjusted close': '60.4811031964577',
      volume: '5113500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-28': {
      open: '111.12',
      high: '112.5',
      low: '108.87',
      close: '111.81',
      'adjusted close': '60.2440280480707',
      volume: '5355600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-27': {
      open: '109.87',
      high: '111.5',
      low: '109.31',
      close: '110.31',
      'adjusted close': '59.4358173149332',
      volume: '4047400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-26': {
      open: '111.0',
      high: '112.37',
      low: '108.87',
      close: '109.87',
      'adjusted close': '59.1987421665462',
      volume: '6670400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-25': {
      open: '113.25',
      high: '113.37',
      low: '110.25',
      close: '112.0',
      'adjusted close': '60.3464014076015',
      volume: '5566900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-24': {
      open: '114.12',
      high: '115.62',
      low: '111.62',
      close: '112.5',
      'adjusted close': '60.615804985314',
      volume: '6814400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-21': {
      open: '117.0',
      high: '117.0',
      low: '113.75',
      close: '114.75',
      'adjusted close': '61.8281210850203',
      volume: '8966400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-20': {
      open: '112.0',
      high: '117.81',
      low: '111.75',
      close: '117.25',
      'adjusted close': '63.1751389735828',
      volume: '23048400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-19': {
      open: '102.37',
      high: '109.5',
      low: '101.0',
      close: '108.75',
      'adjusted close': '58.5952781524702',
      volume: '7781400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-18': {
      open: '104.12',
      high: '104.81',
      low: '102.62',
      close: '103.31',
      'adjusted close': '55.6641672269581',
      volume: '6452700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-17': {
      open: '104.44',
      high: '107.87',
      low: '104.19',
      close: '105.5',
      'adjusted close': '56.8441548973389',
      volume: '6632700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-14': {
      open: '103.75',
      high: '104.87',
      low: '103.12',
      close: '103.94',
      'adjusted close': '56.0036157348759',
      volume: '4020000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-13': {
      open: '105.44',
      high: '105.94',
      low: '102.0',
      close: '104.0',
      'adjusted close': '56.0359441642014',
      volume: '5669700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-12': {
      open: '103.19',
      high: '105.87',
      low: '102.62',
      close: '104.62',
      'adjusted close': '56.3700046005649',
      volume: '5550500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-11': {
      open: '103.25',
      high: '104.0',
      low: '101.0',
      close: '102.0',
      'adjusted close': '54.9583298533514',
      volume: '5619800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-10': {
      open: '104.69',
      high: '104.94',
      low: '102.87',
      close: '103.31',
      'adjusted close': '55.6641672269581',
      volume: '4212200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-07': {
      open: '102.69',
      high: '105.06',
      low: '101.62',
      close: '105.06',
      'adjusted close': '56.6070797489519',
      volume: '9031700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-06': {
      open: '105.0',
      high: '105.12',
      low: '100.0',
      close: '101.25',
      'adjusted close': '54.5542244867826',
      volume: '12932700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-05': {
      open: '105.62',
      high: '105.62',
      low: '101.12',
      close: '105.0',
      'adjusted close': '56.5747513196264',
      volume: '14339600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-07-03': {
      open: '108.75',
      high: '109.87',
      low: '108.31',
      close: '109.5',
      'adjusted close': '58.999383519039',
      volume: '2304400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-30': {
      open: '112.69',
      high: '113.19',
      low: '108.62',
      close: '109.56',
      'adjusted close': '59.0317119483645',
      volume: '8612600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-29': {
      open: '111.75',
      high: '114.37',
      low: '110.06',
      close: '114.0',
      'adjusted close': '61.4240157184515',
      volume: '7688500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-28': {
      open: '110.0',
      high: '115.19',
      low: '109.87',
      close: '113.78',
      'adjusted close': '61.305478144258',
      volume: '6436600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-27': {
      open: '111.62',
      high: '112.81',
      low: '108.75',
      close: '109.75',
      'adjusted close': '59.1340853078952',
      volume: '6607900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-26': {
      open: '113.0',
      high: '115.75',
      low: '112.87',
      close: '114.41',
      'adjusted close': '61.6449266521758',
      volume: '3625900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-23': {
      open: '113.5',
      high: '113.62',
      low: '110.81',
      close: '111.87',
      'adjusted close': '60.2763564773962',
      volume: '3360900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-22': {
      open: '114.12',
      high: '114.31',
      low: '111.25',
      close: '111.81',
      'adjusted close': '60.2440280480707',
      volume: '4853100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-21': {
      open: '115.56',
      high: '115.94',
      low: '113.5',
      close: '114.5',
      'adjusted close': '61.693419296164',
      volume: '6487000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-20': {
      open: '120.0',
      high: '120.69',
      low: '115.37',
      close: '116.37',
      'adjusted close': '62.7009886768088',
      volume: '7103800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-19': {
      open: '113.5',
      high: '120.37',
      low: '113.25',
      close: '120.37',
      'adjusted close': '64.8562172985089',
      volume: '6059200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-16': {
      open: '116.31',
      high: '117.69',
      low: '113.25',
      close: '113.25',
      'adjusted close': '61.0199103518828',
      volume: '8046200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-15': {
      open: '116.0',
      high: '119.0',
      low: '115.87',
      close: '116.81',
      'adjusted close': '62.9380638251958',
      volume: '5386300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-14': {
      open: '118.87',
      high: '120.0',
      low: '115.5',
      close: '116.0',
      'adjusted close': '62.5016300293015',
      volume: '5282600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-13': {
      open: '118.87',
      high: '119.94',
      low: '116.75',
      close: '119.31',
      'adjusted close': '64.2850817137583',
      volume: '4008600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-12': {
      open: '119.12',
      high: '120.44',
      low: '118.84',
      close: '118.84',
      'adjusted close': '64.0318423507086',
      volume: '3640300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-09': {
      open: '119.87',
      high: '120.75',
      low: '118.87',
      close: '119.69',
      'adjusted close': '64.4898284328198',
      volume: '4739100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-08': {
      open: '119.37',
      high: '122.31',
      low: '117.5',
      close: '119.75',
      'adjusted close': '64.5221568621454',
      volume: '9325600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-07': {
      open: '112.5',
      high: '121.75',
      low: '111.5',
      close: '121.0',
      'adjusted close': '65.1956658064266',
      volume: '11115500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-06': {
      open: '112.25',
      high: '114.62',
      low: '111.25',
      close: '112.37',
      'adjusted close': '60.5457600551088',
      volume: '5369400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-05': {
      open: '108.44',
      high: '114.19',
      low: '108.44',
      close: '112.81',
      'adjusted close': '60.7828352034958',
      volume: '7281400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-02': {
      open: '109.0',
      high: '109.75',
      low: '107.62',
      close: '108.81',
      'adjusted close': '58.6276065817957',
      volume: '5473300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-06-01': {
      open: '107.56',
      high: '108.94',
      low: '105.5',
      close: '106.0',
      'adjusted close': '57.1135584750514',
      volume: '5712500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-31': {
      open: '110.5',
      high: '110.75',
      low: '107.19',
      close: '107.31',
      'adjusted close': '57.8193958486582',
      volume: '3732500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-30': {
      open: '107.75',
      high: '111.0',
      low: '107.69',
      close: '110.94',
      'adjusted close': '59.775265822851',
      volume: '3617500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-26': {
      open: '105.5',
      high: '107.19',
      low: '104.56',
      close: '106.94',
      'adjusted close': '57.6200372011509',
      volume: '4296900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-25': {
      open: '110.5',
      high: '112.25',
      low: '106.37',
      close: '106.5',
      'adjusted close': '57.3829620527639',
      volume: '5967600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-24': {
      open: '106.5',
      high: '110.0',
      low: '104.19',
      close: '109.62',
      'adjusted close': '59.06404037769',
      volume: '8184200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-23': {
      open: '109.0',
      high: '110.0',
      low: '107.12',
      close: '107.37',
      'adjusted close': '57.8517242779837',
      volume: '5978900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-22': {
      open: '106.0',
      high: '109.25',
      low: '103.44',
      close: '109.25',
      'adjusted close': '58.8646817301827',
      volume: '6769400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-19': {
      open: '106.06',
      high: '107.5',
      low: '105.0',
      close: '106.44',
      'adjusted close': '57.3506336234384',
      volume: '6419200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-18': {
      open: '106.12',
      high: '107.31',
      low: '104.69',
      close: '106.06',
      'adjusted close': '57.1458869043769',
      volume: '4577200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-17': {
      open: '107.5',
      high: '108.87',
      low: '106.12',
      close: '107.87',
      'adjusted close': '58.1211278556962',
      volume: '5524500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-16': {
      open: '105.12',
      high: '109.25',
      low: '105.0',
      close: '109.0',
      'adjusted close': '58.7299799413265',
      volume: '7265800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-15': {
      open: '104.0',
      high: '104.87',
      low: '102.0',
      close: '104.19',
      'adjusted close': '56.1383175237321',
      volume: '5907800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-12': {
      open: '103.75',
      high: '105.75',
      low: '103.25',
      close: '104.44',
      'adjusted close': '56.2730193125884',
      volume: '5599700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-11': {
      open: '104.0',
      high: '107.5',
      low: '103.19',
      close: '104.44',
      'adjusted close': '56.2730193125884',
      volume: '8106200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-10': {
      open: '105.5',
      high: '105.69',
      low: '102.06',
      close: '103.0',
      'adjusted close': '55.4971370087764',
      volume: '10012700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-09': {
      open: '110.5',
      high: '110.62',
      low: '108.25',
      close: '109.0',
      'adjusted close': '58.7299799413265',
      volume: '4864900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-08': {
      open: '108.06',
      high: '110.37',
      low: '107.62',
      close: '109.75',
      'adjusted close': '59.1340853078952',
      volume: '4826900',
      'dividend amount': '0.1300',
      'split coefficient': '1.0',
    },
    '2000-05-05': {
      open: '106.62',
      high: '109.62',
      low: '106.5',
      close: '107.87',
      'adjusted close': '58.0523642351898',
      volume: '4695100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-04': {
      open: '109.12',
      high: '109.19',
      low: '107.19',
      close: '107.62',
      'adjusted close': '57.9178218132115',
      volume: '3631300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-03': {
      open: '110.5',
      high: '111.12',
      low: '106.56',
      close: '108.12',
      'adjusted close': '58.1869066571681',
      volume: '5543800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-02': {
      open: '111.0',
      high: '113.62',
      low: '110.69',
      close: '111.37',
      'adjusted close': '59.9359581428858',
      volume: '4085000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-05-01': {
      open: '112.5',
      high: '113.19',
      low: '110.44',
      close: '112.0',
      'adjusted close': '60.2750050462711',
      volume: '4196100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-28': {
      open: '112.0',
      high: '112.69',
      low: '110.12',
      close: '111.5',
      'adjusted close': '60.0059202023145',
      volume: '4888600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-27': {
      open: '109.5',
      high: '113.12',
      low: '108.69',
      close: '110.62',
      'adjusted close': '59.5323308769509',
      volume: '6317500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-26': {
      open: '112.5',
      high: '113.94',
      low: '109.0',
      close: '110.5',
      'adjusted close': '59.4677505144014',
      volume: '6182800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-25': {
      open: '106.5',
      high: '113.0',
      low: '106.44',
      close: '112.5',
      'adjusted close': '60.5440898902276',
      volume: '9576400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-24': {
      open: '102.0',
      high: '107.19',
      low: '101.25',
      close: '106.5',
      'adjusted close': '57.3150717627488',
      volume: '8523100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-20': {
      open: '105.0',
      high: '105.25',
      low: '102.06',
      close: '104.0',
      'adjusted close': '55.969647542966',
      volume: '10633300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-19': {
      open: '109.75',
      high: '109.75',
      low: '102.81',
      close: '105.0',
      'adjusted close': '56.5078172308791',
      volume: '14674500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-18': {
      open: '112.25',
      high: '115.12',
      low: '110.06',
      close: '111.5',
      'adjusted close': '60.0059202023145',
      volume: '8205500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-17': {
      open: '103.87',
      high: '112.25',
      low: '103.31',
      close: '111.87',
      'adjusted close': '60.2050429868424',
      volume: '9969500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-14': {
      open: '109.81',
      high: '112.75',
      low: '102.87',
      close: '105.0',
      'adjusted close': '56.5078172308791',
      volume: '11782000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-13': {
      open: '112.5',
      high: '114.31',
      low: '110.0',
      close: '110.62',
      'adjusted close': '59.5323308769509',
      volume: '8236400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-12': {
      open: '119.62',
      high: '119.62',
      low: '111.69',
      close: '113.75',
      'adjusted close': '61.216802000119',
      volume: '8885200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-11': {
      open: '121.62',
      high: '121.62',
      low: '117.81',
      close: '119.37',
      'adjusted close': '64.2413156461909',
      volume: '7738700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-10': {
      open: '124.75',
      high: '125.37',
      low: '121.56',
      close: '122.12',
      'adjusted close': '65.721282287952',
      volume: '5507300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-07': {
      open: '123.5',
      high: '125.69',
      low: '121.5',
      close: '123.12',
      'adjusted close': '66.2594519758651',
      volume: '5380300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-06': {
      open: '123.5',
      high: '128.0',
      low: '122.62',
      close: '122.75',
      'adjusted close': '66.0603291913373',
      volume: '8887400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-05': {
      open: '120.5',
      high: '125.75',
      low: '119.75',
      close: '125.0',
      'adjusted close': '67.2712109891418',
      volume: '8693500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-04': {
      open: '121.5',
      high: '126.94',
      low: '115.06',
      close: '121.19',
      'adjusted close': '65.2207844781928',
      volume: '16667200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-04-03': {
      open: '120.0',
      high: '122.37',
      low: '119.44',
      close: '122.0',
      'adjusted close': '65.6567019254024',
      volume: '7715600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-31': {
      open: '122.62',
      high: '123.75',
      low: '117.06',
      close: '118.37',
      'adjusted close': '63.7031459582777',
      volume: '8015200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-30': {
      open: '118.87',
      high: '123.0',
      low: '118.69',
      close: '122.75',
      'adjusted close': '66.0603291913373',
      volume: '9158000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-29': {
      open: '122.31',
      high: '122.44',
      low: '118.5',
      close: '119.0',
      'adjusted close': '64.042192861663',
      volume: '7312300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-28': {
      open: '125.75',
      high: '127.0',
      low: '122.06',
      close: '122.5',
      'adjusted close': '65.925786769359',
      volume: '8149600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-27': {
      open: '125.0',
      high: '128.25',
      low: '124.62',
      close: '126.87',
      'adjusted close': '68.2775883055394',
      volume: '11862900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-24': {
      open: '115.37',
      high: '122.25',
      low: '115.06',
      close: '120.62',
      'adjusted close': '64.9140277560823',
      volume: '12125600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-23': {
      open: '115.87',
      high: '115.87',
      low: '111.62',
      close: '115.25',
      'adjusted close': '62.0240565319887',
      volume: '6492400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-22': {
      open: '114.5',
      high: '115.37',
      low: '113.87',
      close: '114.25',
      'adjusted close': '61.4858868440756',
      volume: '6137800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-21': {
      open: '112.81',
      high: '114.69',
      low: '111.94',
      close: '113.5',
      'adjusted close': '61.0822595781408',
      volume: '6771600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-20': {
      open: '110.0',
      high: '113.37',
      low: '109.94',
      close: '112.75',
      'adjusted close': '60.6786323122059',
      volume: '6563500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-17': {
      open: '108.25',
      high: '111.69',
      low: '107.69',
      close: '110.0',
      'adjusted close': '59.1986656704448',
      volume: '8857300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-16': {
      open: '109.0',
      high: '109.81',
      low: '107.87',
      close: '109.0',
      'adjusted close': '58.6604959825317',
      volume: '6622500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-15': {
      open: '107.5',
      high: '108.25',
      low: '106.12',
      close: '107.0',
      'adjusted close': '57.5841566067054',
      volume: '5665900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-14': {
      open: '107.75',
      high: '110.0',
      low: '107.37',
      close: '108.62',
      'adjusted close': '58.4559915011247',
      volume: '7973700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-13': {
      open: '104.0',
      high: '109.0',
      low: '102.5',
      close: '107.69',
      'adjusted close': '57.9554936913654',
      volume: '7871000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-10': {
      open: '107.62',
      high: '109.0',
      low: '105.0',
      close: '105.25',
      'adjusted close': '56.6423596528574',
      volume: '7093200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-09': {
      open: '106.5',
      high: '108.0',
      low: '104.12',
      close: '108.0',
      'adjusted close': '58.1223262946185',
      volume: '6334100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-08': {
      open: '103.0',
      high: '106.87',
      low: '102.75',
      close: '106.25',
      'adjusted close': '57.1805293407705',
      volume: '8376400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-07': {
      open: '106.0',
      high: '107.0',
      low: '101.69',
      close: '103.0',
      'adjusted close': '55.4314778550528',
      volume: '10035100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-06': {
      open: '109.94',
      high: '111.0',
      low: '101.0',
      close: '103.06',
      'adjusted close': '55.4637680363276',
      volume: '10747400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-03': {
      open: '107.25',
      high: '110.0',
      low: '106.06',
      close: '108.0',
      'adjusted close': '58.1223262946185',
      volume: '10162800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-02': {
      open: '100.5',
      high: '105.44',
      low: '99.5',
      close: '103.12',
      'adjusted close': '55.4960582176024',
      volume: '11192900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-03-01': {
      open: '102.0',
      high: '105.5',
      low: '100.06',
      close: '100.25',
      'adjusted close': '53.9515112132917',
      volume: '10807800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-29': {
      open: '105.56',
      high: '105.56',
      low: '100.94',
      close: '102.75',
      'adjusted close': '55.2969354330746',
      volume: '10484900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-28': {
      open: '104.62',
      high: '106.5',
      low: '103.94',
      close: '104.5',
      'adjusted close': '56.2387323869225',
      volume: '8479900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-25': {
      open: '109.87',
      high: '109.87',
      low: '104.94',
      close: '108.0',
      'adjusted close': '58.1223262946185',
      volume: '10312200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-24': {
      open: '108.81',
      high: '111.0',
      low: '108.19',
      close: '110.5',
      'adjusted close': '59.4677505144014',
      volume: '9112000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-23': {
      open: '110.5',
      high: '110.5',
      low: '108.5',
      close: '108.75',
      'adjusted close': '58.5259535605534',
      volume: '7435300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-22': {
      open: '112.0',
      high: '113.44',
      low: '108.75',
      close: '111.0',
      'adjusted close': '59.7368353583579',
      volume: '7654800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-18': {
      open: '115.25',
      high: '115.94',
      low: '111.5',
      close: '112.5',
      'adjusted close': '60.5440898902276',
      volume: '7673400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-17': {
      open: '116.5',
      high: '118.87',
      low: '115.75',
      close: '116.75',
      'adjusted close': '62.8313110638585',
      volume: '5237500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-16': {
      open: '116.5',
      high: '117.31',
      low: '115.25',
      close: '115.75',
      'adjusted close': '62.2931413759453',
      volume: '3966900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-15': {
      open: '115.87',
      high: '117.44',
      low: '113.87',
      close: '117.12',
      'adjusted close': '63.0304338483863',
      volume: '5177500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-14': {
      open: '116.0',
      high: '116.37',
      low: '114.5',
      close: '116.06',
      'adjusted close': '62.4599739791984',
      volume: '4544000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-11': {
      open: '119.06',
      high: '119.44',
      low: '114.56',
      close: '115.37',
      'adjusted close': '62.0886368945383',
      volume: '5749400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-10': {
      open: '117.69',
      high: '119.12',
      low: '116.87',
      close: '119.12',
      'adjusted close': '64.1067732242126',
      volume: '4446600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-09': {
      open: '119.0',
      high: '119.75',
      low: '117.0',
      close: '117.37',
      'adjusted close': '63.1649762703646',
      volume: '5374700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-08': {
      open: '116.94',
      high: '119.0',
      low: '116.19',
      close: '118.81',
      'adjusted close': '63.9399406209595',
      volume: '5464300',
      'dividend amount': '0.1200',
      'split coefficient': '1.0',
    },
    '2000-02-07': {
      open: '116.0',
      high: '116.87',
      low: '113.12',
      close: '114.12',
      'adjusted close': '61.3539563076087',
      volume: '5779500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-04': {
      open: '118.19',
      high: '118.44',
      low: '114.81',
      close: '115.62',
      'adjusted close': '62.1603963221671',
      volume: '5405300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-03': {
      open: '115.0',
      high: '117.5',
      low: '114.06',
      close: '117.12',
      'adjusted close': '62.9668363367256',
      volume: '6213800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-02': {
      open: '112.0',
      high: '115.81',
      low: '110.37',
      close: '113.5',
      'adjusted close': '61.0206277682578',
      volume: '7707700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-02-01': {
      open: '112.37',
      high: '113.44',
      low: '109.12',
      close: '110.0',
      'adjusted close': '59.1389344009547',
      volume: '7304700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-31': {
      open: '111.37',
      high: '112.81',
      low: '109.62',
      close: '112.25',
      'adjusted close': '60.3485944227924',
      volume: '6202700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-28': {
      open: '112.75',
      high: '114.19',
      low: '110.06',
      close: '111.56',
      'adjusted close': '59.9776320160955',
      volume: '6669400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-27': {
      open: '118.0',
      high: '118.44',
      low: '111.62',
      close: '113.5',
      'adjusted close': '61.0206277682578',
      volume: '8324600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-26': {
      open: '119.06',
      high: '119.94',
      low: '116.0',
      close: '116.75',
      'adjusted close': '62.7679144664679',
      volume: '4895100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-25': {
      open: '116.75',
      high: '119.5',
      low: '116.0',
      close: '119.12',
      'adjusted close': '64.0420896894702',
      volume: '6936900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-24': {
      open: '121.87',
      high: '122.87',
      low: '116.56',
      close: '121.5',
      'adjusted close': '65.3216411792364',
      volume: '6499200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-21': {
      open: '121.87',
      high: '123.0',
      low: '119.94',
      close: '121.5',
      'adjusted close': '65.3216411792364',
      volume: '7868700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-20': {
      open: '123.0',
      high: '124.75',
      low: '119.0',
      close: '119.0',
      'adjusted close': '63.9775744883056',
      volume: '17783400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-19': {
      open: '115.56',
      high: '122.0',
      low: '112.69',
      close: '119.5',
      'adjusted close': '64.2463878264917',
      volume: '8634500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-18': {
      open: '119.69',
      high: '119.75',
      low: '115.0',
      close: '115.75',
      'adjusted close': '62.2302877900955',
      volume: '7643900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-14': {
      open: '120.94',
      high: '123.31',
      low: '117.5',
      close: '119.62',
      'adjusted close': '64.3109030276564',
      volume: '10956600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-13': {
      open: '119.94',
      high: '121.0',
      low: '115.75',
      close: '118.25',
      'adjusted close': '63.5743544810263',
      volume: '8489700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-12': {
      open: '119.62',
      high: '122.0',
      low: '118.25',
      close: '119.5',
      'adjusted close': '64.2463878264917',
      volume: '6803800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-11': {
      open: '117.87',
      high: '121.12',
      low: '116.62',
      close: '119.0',
      'adjusted close': '63.9775744883056',
      volume: '7873300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-10': {
      open: '117.25',
      high: '119.37',
      low: '115.37',
      close: '118.0',
      'adjusted close': '63.4399478119332',
      volume: '8540500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-07': {
      open: '117.25',
      high: '117.94',
      low: '110.62',
      close: '113.5',
      'adjusted close': '61.0206277682578',
      volume: '11856700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-06': {
      open: '118.0',
      high: '118.94',
      low: '113.5',
      close: '114.0',
      'adjusted close': '61.289441106444',
      volume: '7971900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-05': {
      open: '112.94',
      high: '119.75',
      low: '112.12',
      close: '116.0',
      'adjusted close': '62.3646944591886',
      volume: '12733200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-04': {
      open: '114.0',
      high: '114.5',
      low: '110.87',
      close: '112.06',
      'adjusted close': '60.2464453542817',
      volume: '8227800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '2000-01-03': {
      open: '112.44',
      high: '116.0',
      low: '111.87',
      close: '116.0',
      'adjusted close': '62.3646944591886',
      volume: '10347700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-31': {
      open: '109.19',
      high: '109.5',
      low: '106.62',
      close: '107.87',
      'adjusted close': '57.9937895802817',
      volume: '2870300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-30': {
      open: '109.69',
      high: '110.5',
      low: '108.56',
      close: '108.75',
      'adjusted close': '58.4669010554893',
      volume: '3435100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-29': {
      open: '110.44',
      high: '110.5',
      low: '108.75',
      close: '109.0',
      'adjusted close': '58.6013077245824',
      volume: '2683300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-28': {
      open: '109.56',
      high: '110.75',
      low: '109.06',
      close: '109.81',
      'adjusted close': '59.036785332444',
      volume: '4083100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-27': {
      open: '109.69',
      high: '110.0',
      low: '108.12',
      close: '109.75',
      'adjusted close': '59.0045277318616',
      volume: '3740700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-23': {
      open: '109.12',
      high: '110.44',
      low: '108.19',
      close: '108.62',
      'adjusted close': '58.3970095875609',
      volume: '4528700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-22': {
      open: '109.87',
      high: '110.31',
      low: '108.0',
      close: '108.0',
      'adjusted close': '58.0636810482101',
      volume: '4005600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-21': {
      open: '108.5',
      high: '110.12',
      low: '108.06',
      close: '110.12',
      'adjusted close': '59.2034496021194',
      volume: '4773500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-20': {
      open: '109.06',
      high: '110.44',
      low: '107.75',
      close: '109.2',
      'adjusted close': '58.7088330598569',
      volume: '4836300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-17': {
      open: '110.69',
      high: '111.06',
      low: '108.62',
      close: '110.0',
      'adjusted close': '59.1389344009547',
      volume: '7676800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-16': {
      open: '108.0',
      high: '109.69',
      low: '105.75',
      close: '109.19',
      'adjusted close': '58.7034567930931',
      volume: '7122300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-15': {
      open: '107.75',
      high: '107.94',
      low: '104.5',
      close: '107.0',
      'adjusted close': '57.5260543718378',
      volume: '9437900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-14': {
      open: '110.19',
      high: '110.19',
      low: '106.87',
      close: '109.25',
      'adjusted close': '58.7357143936755',
      volume: '7437900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-13': {
      open: '108.12',
      high: '112.75',
      low: '106.56',
      close: '109.7',
      'adjusted close': '58.977646398043',
      volume: '7135200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-10': {
      open: '111.62',
      high: '111.94',
      low: '107.56',
      close: '109.0',
      'adjusted close': '58.6013077245824',
      volume: '12588800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-09': {
      open: '120.5',
      high: '122.12',
      low: '112.25',
      close: '113.37',
      'adjusted close': '60.9507363003294',
      volume: '16643000',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-08': {
      open: '116.25',
      high: '121.0',
      low: '115.5',
      close: '118.28',
      'adjusted close': '63.5904832813175',
      volume: '8139800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-07': {
      open: '117.0',
      high: '119.19',
      low: '115.44',
      close: '116.62',
      'adjusted close': '62.6980229985395',
      volume: '11326100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-06': {
      open: '113.0',
      high: '116.5',
      low: '112.5',
      close: '116.0',
      'adjusted close': '62.3646944591886',
      volume: '9928300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-03': {
      open: '109.81',
      high: '112.87',
      low: '107.94',
      close: '111.87',
      'adjusted close': '60.1442962857709',
      volume: '14680300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-02': {
      open: '103.44',
      high: '106.31',
      low: '103.37',
      close: '105.27',
      'adjusted close': '56.5959602217137',
      volume: '6216900',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-12-01': {
      open: '102.56',
      high: '104.44',
      low: '102.25',
      close: '103.42',
      'adjusted close': '55.6013508704249',
      volume: '5336400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-30': {
      open: '103.69',
      high: '104.5',
      low: '102.12',
      close: '103.06',
      'adjusted close': '55.4078052669309',
      volume: '5758700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-29': {
      open: '104.94',
      high: '104.94',
      low: '103.37',
      close: '104.19',
      'adjusted close': '56.0153234112316',
      volume: '5677700',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-26': {
      open: '105.94',
      high: '106.0',
      low: '104.12',
      close: '105.0',
      'adjusted close': '56.4508010190931',
      volume: '3108400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-24': {
      open: '105.0',
      high: '105.0',
      low: '101.81',
      close: '104.5',
      'adjusted close': '56.181987680907',
      volume: '9710400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-23': {
      open: '108.81',
      high: '109.87',
      low: '106.0',
      close: '106.06',
      'adjusted close': '57.0206852960478',
      volume: '10665200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-22': {
      open: '105.5',
      high: '108.44',
      low: '104.44',
      close: '107.87',
      'adjusted close': '57.9937895802817',
      volume: '13681600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-19': {
      open: '99.0',
      high: '105.12',
      low: '98.37',
      close: '103.94',
      'adjusted close': '55.8809167421385',
      volume: '19678400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-18': {
      open: '94.0',
      high: '98.25',
      low: '93.56',
      close: '98.0',
      'adjusted close': '52.6874142844869',
      volume: '18720500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-17': {
      open: '94.87',
      high: '94.87',
      low: '92.62',
      close: '93.75',
      'adjusted close': '50.4025009099046',
      volume: '8355100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-16': {
      open: '94.62',
      high: '95.0',
      low: '93.0',
      close: '94.62',
      'adjusted close': '50.8702361183485',
      volume: '8537400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-15': {
      open: '96.0',
      high: '96.37',
      low: '93.87',
      close: '94.06',
      'adjusted close': '50.56916517958',
      volume: '6259400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-12': {
      open: '96.0',
      high: '96.25',
      low: '93.0',
      close: '95.87',
      'adjusted close': '51.5422694638139',
      volume: '9668400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-11': {
      open: '97.5',
      high: '97.5',
      low: '94.87',
      close: '95.0',
      'adjusted close': '51.07453425537',
      volume: '7147500',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-10': {
      open: '94.62',
      high: '97.06',
      low: '94.12',
      close: '97.0',
      'adjusted close': '52.1497876081146',
      volume: '8680300',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-09': {
      open: '95.06',
      high: '95.5',
      low: '92.12',
      close: '93.62',
      'adjusted close': '50.3326094419762',
      volume: '9551200',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-08': {
      open: '90.5',
      high: '93.94',
      low: '90.5',
      close: '93.94',
      'adjusted close': '50.5046499784153',
      volume: '8522300',
      'dividend amount': '0.1200',
      'split coefficient': '1.0',
    },
    '1999-11-05': {
      open: '92.75',
      high: '92.94',
      low: '90.19',
      close: '90.25',
      'adjusted close': '48.4589055980436',
      volume: '13737600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-04': {
      open: '94.44',
      high: '94.44',
      low: '90.0',
      close: '91.56',
      'adjusted close': '49.1622980227908',
      volume: '16697600',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-03': {
      open: '95.87',
      high: '95.94',
      low: '93.5',
      close: '94.37',
      'adjusted close': '50.6711016209128',
      volume: '10369100',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-02': {
      open: '96.75',
      high: '96.81',
      low: '93.69',
      close: '94.81',
      'adjusted close': '50.9073555651027',
      volume: '11105400',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
    '1999-11-01': {
      open: '98.5',
      high: '98.81',
      low: '96.37',
      close: '96.75',
      'adjusted close': '51.9490206826673',
      volume: '9551800',
      'dividend amount': '0.0000',
      'split coefficient': '1.0',
    },
  },
};
