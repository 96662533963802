// For simplicity, the data here is actually monthly data
export const quarterlyStockPrices = {
  'Meta Data': {
    'Company Symbol': 'IBM',
    'Last Refreshed': '2023-06-23',
    'Time Zone': 'US/Eastern',
  },
  'Time Series Quarterly': {
    '2023-06-23': {
      open: '128.4400',
      high: '139.4690',
      low: '127.7800',
      close: '129.4300',
      'adjusted close': '129.4300',
      volume: '82026166',
      'dividend amount': '0.0000',
    },
    '2023-05-31': {
      open: '126.3500',
      high: '130.0699',
      low: '120.5500',
      close: '128.5900',
      'adjusted close': '128.5900',
      volume: '95710890',
      'dividend amount': '1.6600',
    },
    '2023-04-28': {
      open: '130.9700',
      high: '132.6100',
      low: '124.5600',
      close: '126.4100',
      'adjusted close': '124.7016',
      volume: '83664114',
      'dividend amount': '0.0000',
    },
    '2023-03-31': {
      open: '128.9000',
      high: '131.4800',
      low: '121.7100',
      close: '131.0900',
      'adjusted close': '129.3184',
      volume: '138093084',
      'dividend amount': '0.0000',
    },
    '2023-02-28': {
      open: '134.4900',
      high: '137.3900',
      low: '128.8600',
      close: '129.3000',
      'adjusted close': '127.5526',
      volume: '76080679',
      'dividend amount': '1.6500',
    },
    '2023-01-31': {
      open: '141.1000',
      high: '147.1800',
      low: '132.9800',
      close: '134.7300',
      'adjusted close': '131.2895',
      volume: '105576019',
      'dividend amount': '0.0000',
    },
    '2022-12-30': {
      open: '149.9800',
      high: '153.2100',
      low: '137.1950',
      close: '140.8900',
      'adjusted close': '137.2922',
      volume: '86426226',
      'dividend amount': '0.0000',
    },
    '2022-11-30': {
      open: '138.2500',
      high: '150.4600',
      low: '133.9700',
      close: '148.9000',
      'adjusted close': '145.0977',
      volume: '93620235',
      'dividend amount': '1.6500',
    },
    '2022-10-31': {
      open: '120.1600',
      high: '138.8615',
      low: '115.5450',
      close: '138.2900',
      'adjusted close': '133.1594',
      volume: '113480787',
      'dividend amount': '0.0000',
    },
    '2022-09-30': {
      open: '128.4000',
      high: '130.9900',
      low: '118.6100',
      close: '118.8100',
      'adjusted close': '114.4021',
      volume: '87256958',
      'dividend amount': '0.0000',
    },
    '2022-08-31': {
      open: '130.7500',
      high: '139.3400',
      low: '128.4000',
      close: '128.4500',
      'adjusted close': '123.6845',
      volume: '77392342',
      'dividend amount': '1.6500',
    },
    '2022-07-29': {
      open: '141.0000',
      high: '141.8700',
      low: '125.1300',
      close: '130.7900',
      'adjusted close': '124.3529',
      volume: '129801061',
      'dividend amount': '0.0000',
    },
    '2022-06-30': {
      open: '139.6700',
      high: '144.7300',
      low: '132.8500',
      close: '141.1900',
      'adjusted close': '134.2410',
      volume: '105815743',
      'dividend amount': '0.0000',
    },
    '2022-05-31': {
      open: '133.0000',
      high: '139.8300',
      low: '125.8000',
      close: '138.8400',
      'adjusted close': '132.0067',
      volume: '113207659',
      'dividend amount': '1.6500',
    },
    '2022-04-29': {
      open: '129.6600',
      high: '141.8800',
      low: '124.9100',
      close: '132.2100',
      'adjusted close': '124.1789',
      volume: '107525264',
      'dividend amount': '0.0000',
    },
    '2022-03-31': {
      open: '122.6700',
      high: '133.0800',
      low: '120.7000',
      close: '130.0200',
      'adjusted close': '122.1220',
      volume: '96447210',
      'dividend amount': '0.0000',
    },
    '2022-02-28': {
      open: '133.7600',
      high: '138.8200',
      low: '118.8100',
      close: '122.5100',
      'adjusted close': '115.0682',
      volume: '98492968',
      'dividend amount': '1.6400',
    },
    '2022-01-31': {
      open: '134.0700',
      high: '142.2000',
      low: '124.1930',
      close: '133.5700',
      'adjusted close': '123.9341',
      volume: '147238382',
      'dividend amount': '0.0000',
    },
    '2021-12-31': {
      open: '118.2500',
      high: '134.9900',
      low: '116.5600',
      close: '133.6600',
      'adjusted close': '124.0176',
      volume: '113930079',
      'dividend amount': '0.0000',
    },
    '2021-11-30': {
      open: '125.0500',
      high: '127.2900',
      low: '114.5600',
      close: '117.1000',
      'adjusted close': '108.6522',
      volume: '119252012',
      'dividend amount': '1.6400',
    },
    '2021-10-29': {
      open: '141.0000',
      high: '146.0000',
      low: '124.6200',
      close: '125.1000',
      'adjusted close': '109.4847',
      volume: '144097758',
      'dividend amount': '0.0000',
    },
    '2021-09-30': {
      open: '139.9800',
      high: '140.5700',
      low: '132.7800',
      close: '138.9300',
      'adjusted close': '121.5884',
      volume: '76644238',
      'dividend amount': '0.0000',
    },
    '2021-08-31': {
      open: '141.4500',
      high: '144.7000',
      low: '137.2100',
      close: '140.3400',
      'adjusted close': '122.8224',
      volume: '69687056',
      'dividend amount': '1.6400',
    },
    '2021-07-30': {
      open: '146.9600',
      high: '147.5000',
      low: '136.2089',
      close: '140.9600',
      'adjusted close': '121.9491',
      volume: '110625907',
      'dividend amount': '0.0000',
    },
    '2021-06-30': {
      open: '145.0000',
      high: '152.8400',
      low: '143.0400',
      close: '146.5900',
      'adjusted close': '126.8198',
      volume: '84365220',
      'dividend amount': '0.0000',
    },
    '2021-05-28': {
      open: '143.8100',
      high: '148.5150',
      low: '140.9200',
      close: '143.7400',
      'adjusted close': '124.3542',
      volume: '98036425',
      'dividend amount': '1.6400',
    },
    '2021-04-30': {
      open: '133.7600',
      high: '148.7400',
      low: '130.3800',
      close: '141.8800',
      'adjusted close': '121.3766',
      volume: '122920494',
      'dividend amount': '0.0000',
    },
    '2021-03-31': {
      open: '120.3500',
      high: '137.0700',
      low: '118.7550',
      close: '133.2600',
      'adjusted close': '114.0023',
      volume: '127967821',
      'dividend amount': '0.0000',
    },
    '2021-02-26': {
      open: '119.9000',
      high: '124.3500',
      low: '118.1200',
      close: '118.9300',
      'adjusted close': '101.7431',
      volume: '106339228',
      'dividend amount': '1.6300',
    },
    '2021-01-29': {
      open: '125.8500',
      high: '132.2400',
      low: '117.3600',
      close: '119.1100',
      'adjusted close': '100.5547',
      volume: '176168962',
      'dividend amount': '0.0000',
    },
    '2020-12-31': {
      open: '123.9000',
      high: '127.6900',
      low: '121.7200',
      close: '125.8800',
      'adjusted close': '106.2701',
      volume: '102587006',
      'dividend amount': '0.0000',
    },
    '2020-11-30': {
      open: '112.6500',
      high: '125.3130',
      low: '111.1600',
      close: '123.5200',
      'adjusted close': '104.2777',
      volume: '103461100',
      'dividend amount': '1.6300',
    },
    '2020-10-30': {
      open: '122.3600',
      high: '135.5000',
      low: '105.9200',
      close: '111.6600',
      'adjusted close': '92.9538',
      volume: '159130911',
      'dividend amount': '0.0000',
    },
    '2020-09-30': {
      open: '122.8500',
      high: '129.9500',
      low: '116.4800',
      close: '121.6700',
      'adjusted close': '101.2869',
      volume: '84510174',
      'dividend amount': '0.0000',
    },
    '2020-08-31': {
      open: '123.5000',
      high: '130.4700',
      low: '122.1500',
      close: '123.3100',
      'adjusted close': '102.6521',
      volume: '74033007',
      'dividend amount': '1.6300',
    },
    '2020-07-31': {
      open: '120.2700',
      high: '132.1700',
      low: '115.2000',
      close: '122.9400',
      'adjusted close': '101.0263',
      volume: '113972765',
      'dividend amount': '0.0000',
    },
    '2020-06-30': {
      open: '124.6400',
      high: '135.8800',
      low: '115.8800',
      close: '120.7700',
      'adjusted close': '99.2431',
      volume: '120931250',
      'dividend amount': '0.0000',
    },
    '2020-05-29': {
      open: '123.1900',
      high: '126.9700',
      low: '111.8100',
      close: '124.9000',
      'adjusted close': '102.6370',
      volume: '92864791',
      'dividend amount': '1.6300',
    },
    '2020-04-30': {
      open: '106.3600',
      high: '129.3100',
      low: '104.5200',
      close: '125.5600',
      'adjusted close': '101.8104',
      volume: '130400316',
      'dividend amount': '0.0000',
    },
    '2020-03-31': {
      open: '130.7500',
      high: '136.1000',
      low: '90.5600',
      close: '110.9300',
      'adjusted close': '89.9477',
      volume: '189585053',
      'dividend amount': '0.0000',
    },
    '2020-02-28': {
      open: '144.2500',
      high: '158.7500',
      low: '126.3600',
      close: '130.1500',
      'adjusted close': '105.5322',
      volume: '118092333',
      'dividend amount': '1.6200',
    },
    '2020-01-31': {
      open: '135.0000',
      high: '145.7900',
      low: '133.2000',
      close: '143.7300',
      'adjusted close': '115.3257',
      volume: '112864967',
      'dividend amount': '0.0000',
    },
    '2019-12-31': {
      open: '134.4500',
      high: '136.4200',
      low: '130.6900',
      close: '134.0400',
      'adjusted close': '107.5507',
      volume: '71661951',
      'dividend amount': '0.0000',
    },
    '2019-11-29': {
      open: '134.5000',
      high: '139.1400',
      low: '132.7500',
      close: '134.4500',
      'adjusted close': '107.8797',
      volume: '64898948',
      'dividend amount': '1.6200',
    },
    '2019-10-31': {
      open: '145.5900',
      high: '147.3500',
      low: '130.9000',
      close: '133.7300',
      'adjusted close': '106.0542',
      volume: '92647927',
      'dividend amount': '0.0000',
    },
    '2019-09-30': {
      open: '134.8500',
      high: '146.5700',
      low: '133.3300',
      close: '145.4200',
      'adjusted close': '115.3249',
      volume: '62083780',
      'dividend amount': '0.0000',
    },
    '2019-08-30': {
      open: '148.9000',
      high: '152.9500',
      low: '128.8300',
      close: '135.5300',
      'adjusted close': '107.4817',
      volume: '96719129',
      'dividend amount': '1.6200',
    },
    '2019-07-31': {
      open: '139.6000',
      high: '151.9400',
      low: '139.1300',
      close: '148.2400',
      'adjusted close': '116.2175',
      volume: '80175943',
      'dividend amount': '0.0000',
    },
    '2019-06-28': {
      open: '127.1000',
      high: '140.1500',
      low: '127.0600',
      close: '137.9000',
      'adjusted close': '108.1111',
      volume: '61342985',
      'dividend amount': '0.0000',
    },
    '2019-05-31': {
      open: '140.5500',
      high: '141.8100',
      low: '126.8500',
      close: '126.9900',
      'adjusted close': '99.5578',
      volume: '75537897',
      'dividend amount': '1.6200',
    },
    '2019-04-30': {
      open: '141.5100',
      high: '145.3900',
      low: '136.2600',
      close: '140.2700',
      'adjusted close': '108.6684',
      volume: '80199229',
      'dividend amount': '0.0000',
    },
    '2019-03-29': {
      open: '139.3100',
      high: '142.1200',
      low: '133.5800',
      close: '141.1000',
      'adjusted close': '109.3114',
      volume: '74688899',
      'dividend amount': '0.0000',
    },
    '2019-02-28': {
      open: '134.9700',
      high: '140.4900',
      low: '132.1200',
      close: '138.1300',
      'adjusted close': '107.0105',
      volume: '68462024',
      'dividend amount': '1.5700',
    },
    '2019-01-31': {
      open: '112.0100',
      high: '135.4100',
      low: '111.6900',
      close: '134.4200',
      'adjusted close': '102.9231',
      volume: '120388583',
      'dividend amount': '0.0000',
    },
    '2018-12-31': {
      open: '125.6700',
      high: '126.5900',
      low: '105.9400',
      close: '113.6700',
      'adjusted close': '87.0352',
      volume: '115600988',
      'dividend amount': '0.0000',
    },
    '2018-11-30': {
      open: '115.5000',
      high: '125.0600',
      low: '114.5400',
      close: '124.2700',
      'adjusted close': '95.1514',
      volume: '141719180',
      'dividend amount': '1.5700',
    },
    '2018-10-31': {
      open: '151.7100',
      high: '154.3600',
      low: '114.0900',
      close: '115.4300',
      'adjusted close': '87.2723',
      volume: '198093801',
      'dividend amount': '0.0000',
    },
    '2018-09-28': {
      open: '145.9800',
      high: '153.3500',
      low: '144.8100',
      close: '151.2100',
      'adjusted close': '114.3242',
      volume: '95868702',
      'dividend amount': '0.0000',
    },
    '2018-08-31': {
      open: '144.7600',
      high: '147.9300',
      low: '142.0000',
      close: '146.4800',
      'adjusted close': '110.7480',
      volume: '79196876',
      'dividend amount': '1.5700',
    },
    '2018-07-31': {
      open: '138.2800',
      high: '150.5400',
      low: '138.2000',
      close: '144.9300',
      'adjusted close': '108.4005',
      volume: '95015127',
      'dividend amount': '0.0000',
    },
    '2018-06-29': {
      open: '142.4300',
      high: '147.5200',
      low: '137.4500',
      close: '139.7000',
      'adjusted close': '104.4887',
      volume: '90243111',
      'dividend amount': '0.0000',
    },
    '2018-05-31': {
      open: '144.6500',
      high: '146.2000',
      low: '139.9000',
      close: '141.3100',
      'adjusted close': '105.6929',
      volume: '88812832',
      'dividend amount': '1.5700',
    },
    '2018-04-30': {
      open: '153.3400',
      high: '162.0000',
      low: '144.1100',
      close: '144.9600',
      'adjusted close': '107.2423',
      volume: '122893135',
      'dividend amount': '0.0000',
    },
    '2018-03-29': {
      open: '155.5300',
      high: '162.1100',
      low: '148.5400',
      close: '153.4300',
      'adjusted close': '113.5085',
      volume: '92113147',
      'dividend amount': '0.0000',
    },
    '2018-02-28': {
      open: '163.1900',
      high: '164.1300',
      low: '144.4000',
      close: '155.8300',
      'adjusted close': '115.2840',
      volume: '100673819',
      'dividend amount': '1.5000',
    },
    '2018-01-31': {
      open: '154.5000',
      high: '171.1300',
      low: '153.5400',
      close: '163.7000',
      'adjusted close': '119.8878',
      volume: '140197975',
      'dividend amount': '0.0000',
    },
    '2017-12-29': {
      open: '154.4000',
      high: '157.8500',
      low: '151.4900',
      close: '153.4200',
      'adjusted close': '112.3591',
      volume: '87525752',
      'dividend amount': '0.0000',
    },
    '2017-11-30': {
      open: '154.1000',
      high: '154.9000',
      low: '146.2110',
      close: '153.9700',
      'adjusted close': '112.7619',
      volume: '89208545',
      'dividend amount': '1.5000',
    },
    '2017-10-31': {
      open: '145.3500',
      high: '162.5100',
      low: '145.2100',
      close: '154.0600',
      'adjusted close': '111.7129',
      volume: '121490046',
      'dividend amount': '0.0000',
    },
    '2017-09-29': {
      open: '142.9800',
      high: '147.4200',
      low: '141.6400',
      close: '145.0800',
      'adjusted close': '105.2013',
      volume: '77056229',
      'dividend amount': '0.0000',
    },
    '2017-08-31': {
      open: '145.0000',
      high: '145.6700',
      low: '139.1300',
      close: '143.0300',
      'adjusted close': '103.7148',
      volume: '79260314',
      'dividend amount': '1.5000',
    },
    '2017-07-31': {
      open: '153.5800',
      high: '156.0250',
      low: '143.6400',
      close: '144.6700',
      'adjusted close': '103.8083',
      volume: '91242021',
      'dividend amount': '0.0000',
    },
    '2017-06-30': {
      open: '152.8000',
      high: '157.2000',
      low: '150.8000',
      close: '153.8300',
      'adjusted close': '110.3811',
      volume: '82652760',
      'dividend amount': '0.0000',
    },
    '2017-05-31': {
      open: '160.0500',
      high: '160.4200',
      low: '149.7900',
      close: '152.6300',
      'adjusted close': '109.5200',
      volume: '102455942',
      'dividend amount': '1.5000',
    },
    '2017-04-28': {
      open: '173.8200',
      high: '176.3300',
      low: '159.6000',
      close: '160.2900',
      'adjusted close': '113.9000',
      volume: '80758989',
      'dividend amount': '0.0000',
    },
    '2017-03-31': {
      open: '180.4800',
      high: '182.5500',
      low: '172.0937',
      close: '174.1400',
      'adjusted close': '123.7416',
      volume: '79476385',
      'dividend amount': '0.0000',
    },
    '2017-02-28': {
      open: '175.0000',
      high: '182.7900',
      low: '172.8900',
      close: '179.8200',
      'adjusted close': '127.7778',
      volume: '59817600',
      'dividend amount': '1.4000',
    },
    '2017-01-31': {
      open: '167.0000',
      high: '179.2500',
      low: '165.3400',
      close: '174.5200',
      'adjusted close': '123.0339',
      volume: '88671370',
      'dividend amount': '0.0000',
    },
    '2016-12-30': {
      open: '161.9500',
      high: '169.9520',
      low: '158.3000',
      close: '165.9900',
      'adjusted close': '117.0204',
      volume: '69463793',
      'dividend amount': '0.0000',
    },
    '2016-11-30': {
      open: '153.5000',
      high: '164.6600',
      low: '151.0000',
      close: '162.2200',
      'adjusted close': '114.3626',
      volume: '76340912',
      'dividend amount': '1.4000',
    },
    '2016-10-31': {
      open: '158.0600',
      high: '158.5300',
      low: '147.7900',
      close: '153.6900',
      'adjusted close': '107.3803',
      volume: '78325840',
      'dividend amount': '0.0000',
    },
    '2016-09-30': {
      open: '158.3200',
      high: '165.0000',
      low: '153.2100',
      close: '158.8500',
      'adjusted close': '110.9855',
      volume: '69951563',
      'dividend amount': '0.0000',
    },
    '2016-08-31': {
      open: '160.6500',
      high: '164.9500',
      low: '157.8500',
      close: '158.8800',
      'adjusted close': '111.0064',
      volume: '69680582',
      'dividend amount': '1.4000',
    },
    '2016-07-29': {
      open: '152.1900',
      high: '163.6000',
      low: '149.9200',
      close: '160.6200',
      'adjusted close': '111.2609',
      volume: '69930927',
      'dividend amount': '0.0000',
    },
    '2016-06-30': {
      open: '153.0000',
      high: '155.4800',
      low: '142.5000',
      close: '151.7800',
      'adjusted close': '105.1374',
      volume: '77115961',
      'dividend amount': '0.0000',
    },
    '2016-05-31': {
      open: '146.5600',
      high: '153.8100',
      low: '142.9000',
      close: '153.7400',
      'adjusted close': '106.4951',
      volume: '75248178',
      'dividend amount': '1.4000',
    },
    '2016-04-29': {
      open: '150.5100',
      high: '153.5200',
      low: '142.6100',
      close: '145.9400',
      'adjusted close': '100.1402',
      volume: '94431134',
      'dividend amount': '0.0000',
    },
    '2016-03-31': {
      open: '132.2400',
      high: '153.1000',
      low: '132.0300',
      close: '151.4500',
      'adjusted close': '103.9211',
      volume: '105698603',
      'dividend amount': '0.0000',
    },
    '2016-02-29': {
      open: '124.4000',
      high: '134.9200',
      low: '116.9010',
      close: '131.0300',
      'adjusted close': '89.9094',
      volume: '106703104',
      'dividend amount': '1.3000',
    },
    '2016-01-29': {
      open: '135.6000',
      high: '136.8900',
      low: '118.0000',
      close: '124.7900',
      'adjusted close': '84.7599',
      volume: '126204196',
      'dividend amount': '0.0000',
    },
    '2015-12-31': {
      open: '139.5800',
      high: '141.4000',
      low: '133.9100',
      close: '137.6200',
      'adjusted close': '93.4743',
      volume: '97650594',
      'dividend amount': '0.0000',
    },
    '2015-11-30': {
      open: '140.5000',
      high: '142.8000',
      low: '131.6500',
      close: '139.4200',
      'adjusted close': '94.6969',
      volume: '85421426',
      'dividend amount': '1.3000',
    },
    '2015-10-30': {
      open: '145.3100',
      high: '153.1470',
      low: '137.3300',
      close: '140.0800',
      'adjusted close': '94.2588',
      volume: '120622363',
      'dividend amount': '0.0000',
    },
    '2015-09-30': {
      open: '144.8400',
      high: '149.6800',
      low: '141.1501',
      close: '144.9700',
      'adjusted close': '97.5493',
      volume: '81761648',
      'dividend amount': '0.0000',
    },
    '2015-08-31': {
      open: '161.7000',
      high: '161.8500',
      low: '140.6200',
      close: '147.8900',
      'adjusted close': '99.5141',
      volume: '93750967',
      'dividend amount': '1.3000',
    },
    '2015-07-31': {
      open: '163.9700',
      high: '173.7800',
      low: '158.5000',
      close: '161.9900',
      'adjusted close': '108.1029',
      volume: '93805392',
      'dividend amount': '0.0000',
    },
    '2015-06-30': {
      open: '170.2100',
      high: '171.5601',
      low: '162.1200',
      close: '162.6600',
      'adjusted close': '108.5500',
      volume: '80845501',
      'dividend amount': '0.0000',
    },
    '2015-05-29': {
      open: '173.2000',
      high: '176.3000',
      low: '168.8400',
      close: '169.6500',
      'adjusted close': '113.2148',
      volume: '57916093',
      'dividend amount': '1.3000',
    },
    '2015-04-30': {
      open: '160.2300',
      high: '175.1303',
      low: '158.3900',
      close: '171.2900',
      'adjusted close': '113.4420',
      volume: '93821213',
      'dividend amount': '0.0000',
    },
    '2015-03-31': {
      open: '161.6800',
      high: '165.3500',
      low: '153.4000',
      close: '160.5000',
      'adjusted close': '106.2960',
      volume: '104899758',
      'dividend amount': '0.0000',
    },
    '2015-02-27': {
      open: '154.0000',
      high: '164.9900',
      low: '151.5100',
      close: '161.9400',
      'adjusted close': '107.2496',
      volume: '77654705',
      'dividend amount': '1.1000',
    },
    '2015-01-30': {
      open: '161.3100',
      high: '163.3100',
      low: '149.5200',
      close: '153.3100',
      'adjusted close': '100.8265',
      volume: '117395861',
      'dividend amount': '0.0000',
    },
    '2014-12-31': {
      open: '161.6400',
      high: '164.5200',
      low: '150.5000',
      close: '160.4400',
      'adjusted close': '105.5156',
      volume: '101614529',
      'dividend amount': '0.0000',
    },
    '2014-11-28': {
      open: '164.2500',
      high: '164.9700',
      low: '159.8000',
      close: '162.1700',
      'adjusted close': '106.6534',
      volume: '80018581',
      'dividend amount': '1.1000',
    },
    '2014-10-31': {
      open: '189.9100',
      high: '190.8900',
      low: '161.1000',
      close: '164.4000',
      'adjusted close': '107.3883',
      volume: '146272610',
      'dividend amount': '0.0000',
    },
    '2014-09-30': {
      open: '192.6800',
      high: '195.0000',
      low: '188.1200',
      close: '189.8300',
      'adjusted close': '123.9996',
      volume: '64033759',
      'dividend amount': '0.0000',
    },
    '2014-08-29': {
      open: '190.5000',
      high: '194.1330',
      low: '183.5800',
      close: '192.3000',
      'adjusted close': '125.6130',
      volume: '53441600',
      'dividend amount': '1.1000',
    },
    '2014-07-31': {
      open: '181.7000',
      high: '196.4000',
      low: '181.7000',
      close: '191.6700',
      'adjusted close': '124.4653',
      volume: '97597800',
      'dividend amount': '0.0000',
    },
    '2014-06-30': {
      open: '184.7600',
      high: '187.6500',
      low: '179.2700',
      close: '181.2700',
      'adjusted close': '117.7118',
      volume: '78501700',
      'dividend amount': '0.0000',
    },
    '2014-05-30': {
      open: '196.3100',
      high: '196.7400',
      low: '182.3300',
      close: '184.3600',
      'adjusted close': '119.7184',
      volume: '70018300',
      'dividend amount': '1.1000',
    },
    '2014-04-30': {
      open: '193.1200',
      high: '199.2100',
      low: '187.0100',
      close: '196.4700',
      'adjusted close': '126.8452',
      volume: '118447100',
      'dividend amount': '0.0000',
    },
    '2014-03-31': {
      open: '183.3300',
      high: '195.6300',
      low: '182.2100',
      close: '192.4900',
      'adjusted close': '124.2756',
      volume: '118984000',
      'dividend amount': '0.0000',
    },
    '2014-02-28': {
      open: '176.0200',
      high: '186.1200',
      low: '172.1900',
      close: '185.1700',
      'adjusted close': '119.5497',
      volume: '87054800',
      'dividend amount': '0.9500',
    },
    '2014-01-31': {
      open: '187.2100',
      high: '190.8100',
      low: '175.3400',
      close: '176.6800',
      'adjusted close': '113.4513',
      volume: '123489300',
      'dividend amount': '0.0000',
    },
    '2013-12-31': {
      open: '179.4600',
      high: '187.7900',
      low: '172.7300',
      close: '187.5700',
      'adjusted close': '120.4441',
      volume: '97993100',
      'dividend amount': '0.0000',
    },
    '2013-11-29': {
      open: '179.8100',
      high: '186.2400',
      low: '177.3100',
      close: '179.6800',
      'adjusted close': '115.3777',
      volume: '100984900',
      'dividend amount': '0.9500',
    },
    '2013-10-31': {
      open: '185.3400',
      high: '186.9900',
      low: '172.5700',
      close: '179.2100',
      'adjusted close': '114.4690',
      volume: '130967100',
      'dividend amount': '0.0000',
    },
    '2013-09-30': {
      open: '183.6300',
      high: '194.8900',
      low: '182.3100',
      close: '185.1800',
      'adjusted close': '118.2823',
      volume: '71488100',
      'dividend amount': '0.0000',
    },
    '2013-08-30': {
      open: '196.6500',
      high: '197.1700',
      low: '181.1000',
      close: '182.2700',
      'adjusted close': '116.4236',
      volume: '73077400',
      'dividend amount': '0.9500',
    },
    '2013-07-31': {
      open: '192.1500',
      high: '200.9400',
      low: '190.2600',
      close: '195.0400',
      'adjusted close': '123.9558',
      volume: '88079400',
      'dividend amount': '0.0000',
    },
    '2013-06-28': {
      open: '208.2500',
      high: '210.0500',
      low: '188.4100',
      close: '191.1100',
      'adjusted close': '121.4581',
      volume: '80215000',
      'dividend amount': '0.0000',
    },
    '2013-05-31': {
      open: '201.8700',
      high: '211.9800',
      low: '199.2000',
      close: '208.0200',
      'adjusted close': '132.2051',
      volume: '90683600',
      'dividend amount': '0.9500',
    },
    '2013-04-30': {
      open: '212.8000',
      high: '214.8900',
      low: '187.6800',
      close: '202.5400',
      'adjusted close': '128.1281',
      volume: '108666600',
      'dividend amount': '0.0000',
    },
    '2013-03-28': {
      open: '200.6500',
      high: '215.9000',
      low: '199.3600',
      close: '213.3000',
      'adjusted close': '134.9349',
      volume: '76023800',
      'dividend amount': '0.0000',
    },
    '2013-02-28': {
      open: '204.6500',
      high: '205.3500',
      low: '197.5100',
      close: '200.8300',
      'adjusted close': '127.0463',
      volume: '64144100',
      'dividend amount': '0.8500',
    },
    '2013-01-31': {
      open: '194.0900',
      high: '208.5800',
      low: '190.3900',
      close: '203.0700',
      'adjusted close': '127.9224',
      volume: '87636600',
      'dividend amount': '0.0000',
    },
    '2012-12-31': {
      open: '190.7600',
      high: '196.4500',
      low: '186.9400',
      close: '191.5500',
      'adjusted close': '120.6655',
      volume: '79320200',
      'dividend amount': '0.0000',
    },
    '2012-11-30': {
      open: '194.9600',
      high: '198.0000',
      low: '184.7800',
      close: '190.0700',
      'adjusted close': '119.7332',
      volume: '81892500',
      'dividend amount': '0.8500',
    },
    '2012-10-31': {
      open: '208.0100',
      high: '211.7900',
      low: '190.5600',
      close: '194.5300',
      'adjusted close': '122.0002',
      volume: '96155000',
      'dividend amount': '0.0000',
    },
    '2012-09-28': {
      open: '196.6100',
      high: '208.3200',
      low: '193.2500',
      close: '207.4500',
      'adjusted close': '130.1031',
      volume: '77341400',
      'dividend amount': '0.0000',
    },
    '2012-08-31': {
      open: '196.9600',
      high: '202.0000',
      low: '193.0200',
      close: '194.8500',
      'adjusted close': '122.2009',
      volume: '60653700',
      'dividend amount': '0.8500',
    },
    '2012-07-31': {
      open: '196.3600',
      high: '197.8400',
      low: '181.8500',
      close: '195.9800',
      'adjusted close': '122.3869',
      volume: '91516500',
      'dividend amount': '0.0000',
    },
    '2012-06-29': {
      open: '190.1200',
      high: '199.9900',
      low: '187.0000',
      close: '195.5800',
      'adjusted close': '122.1371',
      volume: '84559100',
      'dividend amount': '0.0000',
    },
    '2012-05-31': {
      open: '207.1800',
      high: '208.9290',
      low: '192.0000',
      close: '192.9000',
      'adjusted close': '120.4635',
      volume: '86005300',
      'dividend amount': '0.8500',
    },
    '2012-04-30': {
      open: '208.9600',
      high: '210.6900',
      low: '196.7910',
      close: '207.0800',
      'adjusted close': '128.7755',
      volume: '86603000',
      'dividend amount': '0.0000',
    },
    '2012-03-30': {
      open: '197.2300',
      high: '209.1200',
      low: '196.8120',
      close: '208.6500',
      'adjusted close': '129.7518',
      volume: '88096900',
      'dividend amount': '0.0000',
    },
    '2012-02-29': {
      open: '193.2100',
      high: '199.2300',
      low: '190.8310',
      close: '196.7300',
      'adjusted close': '122.3392',
      volume: '78809900',
      'dividend amount': '0.7500',
    },
    '2012-01-31': {
      open: '186.7300',
      high: '193.1000',
      low: '177.3472',
      close: '192.6000',
      'adjusted close': '119.3071',
      volume: '110015000',
      'dividend amount': '0.0000',
    },
    '2011-12-30': {
      open: '187.0100',
      high: '194.9000',
      low: '179.0400',
      close: '183.8800',
      'adjusted close': '113.9055',
      volume: '103436500',
      'dividend amount': '0.0000',
    },
    '2011-11-30': {
      open: '181.5500',
      high: '189.9700',
      low: '177.0600',
      close: '188.0000',
      'adjusted close': '116.4576',
      volume: '97014100',
      'dividend amount': '0.7500',
    },
    '2011-10-31': {
      open: '174.3600',
      high: '190.5300',
      low: '168.8800',
      close: '184.6300',
      'adjusted close': '113.9138',
      volume: '144563700',
      'dividend amount': '0.0000',
    },
    '2011-09-30': {
      open: '172.7100',
      high: '180.9100',
      low: '158.7600',
      close: '174.8700',
      'adjusted close': '107.8920',
      volume: '137895000',
      'dividend amount': '0.0000',
    },
    '2011-08-31': {
      open: '182.6000',
      high: '183.6900',
      low: '157.1300',
      close: '171.9100',
      'adjusted close': '106.0658',
      volume: '195054100',
      'dividend amount': '0.7500',
    },
    '2011-07-29': {
      open: '171.6100',
      high: '185.6300',
      low: '171.4900',
      close: '181.8500',
      'adjusted close': '111.6946',
      volume: '113593200',
      'dividend amount': '0.0000',
    },
    '2011-06-30': {
      open: '168.9000',
      high: '172.4468',
      low: '161.5200',
      close: '171.5500',
      'adjusted close': '105.3682',
      volume: '105442400',
      'dividend amount': '0.0000',
    },
    '2011-05-31': {
      open: '172.1100',
      high: '173.5400',
      low: '165.9000',
      close: '168.9300',
      'adjusted close': '103.7590',
      volume: '110804200',
      'dividend amount': '0.7500',
    },
    '2011-04-29': {
      open: '163.7000',
      high: '173.0000',
      low: '162.1900',
      close: '170.5800',
      'adjusted close': '104.3092',
      volume: '100769500',
      'dividend amount': '0.0000',
    },
    '2011-03-31': {
      open: '163.1500',
      high: '167.7200',
      low: '151.7100',
      close: '163.0700',
      'adjusted close': '99.7169',
      volume: '129714300',
      'dividend amount': '0.0000',
    },
    '2011-02-28': {
      open: '162.1100',
      high: '166.2500',
      low: '159.0300',
      close: '161.8800',
      'adjusted close': '98.9892',
      volume: '89067500',
      'dividend amount': '0.6500',
    },
    '2011-01-31': {
      open: '147.2100',
      high: '164.3500',
      low: '146.6400',
      close: '162.0000',
      'adjusted close': '98.6763',
      volume: '118831600',
      'dividend amount': '0.0000',
    },
    '2010-12-31': {
      open: '143.6100',
      high: '147.5000',
      low: '143.5100',
      close: '146.7600',
      'adjusted close': '89.3934',
      volume: '93392800',
      'dividend amount': '0.0000',
    },
    '2010-11-30': {
      open: '143.6400',
      high: '147.5300',
      low: '141.1800',
      close: '141.4600',
      'adjusted close': '86.1651',
      volume: '104096700',
      'dividend amount': '0.6500',
    },
    '2010-10-29': {
      open: '135.5100',
      high: '144.0000',
      low: '134.3900',
      close: '143.6000',
      'adjusted close': '87.0822',
      volume: '137284700',
      'dividend amount': '0.0000',
    },
    '2010-09-30': {
      open: '125.3100',
      high: '136.1100',
      low: '124.5200',
      close: '134.1400',
      'adjusted close': '81.3454',
      volume: '116092900',
      'dividend amount': '0.0000',
    },
    '2010-08-31': {
      open: '129.2500',
      high: '132.4900',
      low: '122.2800',
      close: '123.1300',
      'adjusted close': '74.6687',
      volume: '117394000',
      'dividend amount': '0.6500',
    },
    '2010-07-30': {
      open: '123.5500',
      high: '131.6000',
      low: '120.6100',
      close: '128.4000',
      'adjusted close': '77.4776',
      volume: '144080000',
      'dividend amount': '0.0000',
    },
    '2010-06-30': {
      open: '124.6900',
      high: '131.9400',
      low: '122.8200',
      close: '123.4800',
      'adjusted close': '74.5088',
      volume: '162019100',
      'dividend amount': '0.0000',
    },
    '2010-05-28': {
      open: '129.3900',
      high: '133.1000',
      low: '116.0000',
      close: '125.2600',
      'adjusted close': '75.5829',
      volume: '188444200',
      'dividend amount': '0.6500',
    },
    '2010-04-30': {
      open: '128.9500',
      high: '132.2800',
      low: '127.1200',
      close: '129.0000',
      'adjusted close': '77.4335',
      volume: '146426400',
      'dividend amount': '0.0000',
    },
    '2010-03-31': {
      open: '127.5000',
      high: '130.7300',
      low: '125.2000',
      close: '128.2500',
      'adjusted close': '76.9833',
      volume: '139997300',
      'dividend amount': '0.0000',
    },
    '2010-02-26': {
      open: '123.2300',
      high: '128.2700',
      low: '121.6100',
      close: '127.1600',
      'adjusted close': '76.3290',
      volume: '113211200',
      'dividend amount': '0.5500',
    },
    '2010-01-29': {
      open: '131.1800',
      high: '134.2500',
      low: '121.9000',
      close: '122.3900',
      'adjusted close': '73.1357',
      volume: '156110600',
      'dividend amount': '0.0000',
    },
    '2009-12-31': {
      open: '127.2900',
      high: '132.8500',
      low: '126.0000',
      close: '130.9000',
      'adjusted close': '78.2210',
      volume: '124475900',
      'dividend amount': '0.0000',
    },
    '2009-11-30': {
      open: '120.6100',
      high: '128.9400',
      low: '119.5000',
      close: '126.3500',
      'adjusted close': '75.5021',
      volume: '110748200',
      'dividend amount': '0.5500',
    },
    '2009-10-30': {
      open: '119.3900',
      high: '128.6100',
      low: '117.2600',
      close: '120.6100',
      'adjusted close': '71.7525',
      volume: '183531100',
      'dividend amount': '0.0000',
    },
    '2009-09-30': {
      open: '117.6700',
      high: '122.8800',
      low: '115.1500',
      close: '119.6100',
      'adjusted close': '71.1576',
      volume: '131522200',
      'dividend amount': '0.0000',
    },
    '2009-08-31': {
      open: '118.8800',
      high: '120.8800',
      low: '116.1200',
      close: '118.0500',
      'adjusted close': '70.2295',
      volume: '112480200',
      'dividend amount': '0.5500',
    },
    '2009-07-31': {
      open: '105.0000',
      high: '119.3700',
      low: '99.5000',
      close: '117.9300',
      'adjusted close': '69.8309',
      volume: '176171400',
      'dividend amount': '0.0000',
    },
    '2009-06-30': {
      open: '106.9400',
      high: '110.6400',
      low: '103.5100',
      close: '104.4200',
      'adjusted close': '61.8311',
      volume: '160502100',
      'dividend amount': '0.0000',
    },
    '2009-05-29': {
      open: '103.7800',
      high: '106.8200',
      low: '99.8300',
      close: '106.2800',
      'adjusted close': '62.9325',
      volume: '153084700',
      'dividend amount': '0.5500',
    },
    '2009-04-30': {
      open: '96.1300',
      high: '106.0500',
      low: '95.7000',
      close: '103.2100',
      'adjusted close': '60.7950',
      volume: '213623100',
      'dividend amount': '0.0000',
    },
    '2009-03-31': {
      open: '91.1700',
      high: '99.8600',
      low: '83.0200',
      close: '96.8900',
      'adjusted close': '57.0723',
      volume: '286114500',
      'dividend amount': '0.0000',
    },
    '2009-02-27': {
      open: '90.6000',
      high: '97.1000',
      low: '82.8500',
      close: '92.0300',
      'adjusted close': '54.2095',
      volume: '223017600',
      'dividend amount': '0.5000',
    },
    '2009-01-30': {
      open: '83.8900',
      high: '94.9400',
      low: '81.7600',
      close: '91.6500',
      'adjusted close': '53.7064',
      volume: '208959400',
      'dividend amount': '0.0000',
    },
    '2008-12-31': {
      open: '80.9500',
      high: '87.2700',
      low: '75.3100',
      close: '84.1600',
      'adjusted close': '49.3173',
      volume: '188846100',
      'dividend amount': '0.0000',
    },
    '2008-11-28': {
      open: '92.6400',
      high: '94.7600',
      low: '69.5000',
      close: '81.6000',
      'adjusted close': '47.8171',
      volume: '218313000',
      'dividend amount': '0.5000',
    },
    '2008-10-31': {
      open: '115.5100',
      high: '116.8000',
      low: '78.8200',
      close: '92.9700',
      'adjusted close': '54.1619',
      volume: '314972000',
      'dividend amount': '0.0000',
    },
    '2008-09-30': {
      open: '122.8700',
      high: '124.0000',
      low: '109.9500',
      close: '116.9600',
      'adjusted close': '68.1378',
      volume: '218411700',
      'dividend amount': '0.0000',
    },
    '2008-08-29': {
      open: '128.5200',
      high: '129.9700',
      low: '121.5000',
      close: '121.7300',
      'adjusted close': '70.9167',
      volume: '137028800',
      'dividend amount': '0.5000',
    },
    '2008-07-31': {
      open: '117.5000',
      high: '130.9300',
      low: '116.6000',
      close: '127.9800',
      'adjusted close': '74.2702',
      volume: '200876500',
      'dividend amount': '0.0000',
    },
    '2008-06-30': {
      open: '128.6700',
      high: '129.3700',
      low: '118.1500',
      close: '118.5300',
      'adjusted close': '68.7862',
      volume: '152327500',
      'dividend amount': '0.0000',
    },
    '2008-05-30': {
      open: '121.0600',
      high: '129.9900',
      low: '120.7800',
      close: '129.4300',
      'adjusted close': '75.1117',
      volume: '159517500',
      'dividend amount': '0.5000',
    },
    '2008-04-30': {
      open: '115.1400',
      high: '125.0000',
      low: '113.8600',
      close: '120.7000',
      'adjusted close': '69.7645',
      volume: '192087200',
      'dividend amount': '0.0000',
    },
    '2008-03-31': {
      open: '114.0900',
      high: '119.7900',
      low: '111.8000',
      close: '115.1400',
      'adjusted close': '66.5508',
      volume: '179284200',
      'dividend amount': '0.0000',
    },
    '2008-02-29': {
      open: '107.2100',
      high: '116.6300',
      low: '100.6000',
      close: '113.8600',
      'adjusted close': '65.8110',
      volume: '168280800',
      'dividend amount': '0.4000',
    },
    '2008-01-31': {
      open: '108.9900',
      high: '108.9900',
      low: '97.0400',
      close: '107.1100',
      'adjusted close': '61.6713',
      volume: '247523900',
      'dividend amount': '0.0000',
    },
    '2007-12-31': {
      open: '105.4500',
      high: '112.1900',
      low: '104.0000',
      close: '108.1000',
      'adjusted close': '62.2413',
      volume: '134151600',
      'dividend amount': '0.0000',
    },
    '2007-11-30': {
      open: '115.5000',
      high: '116.0900',
      low: '99.2700',
      close: '105.1800',
      'adjusted close': '60.5601',
      volume: '200920600',
      'dividend amount': '0.4000',
    },
    '2007-10-31': {
      open: '117.6100',
      high: '121.4600',
      low: '110.9600',
      close: '116.1200',
      'adjusted close': '66.6192',
      volume: '171669000',
      'dividend amount': '0.0000',
    },
    '2007-09-28': {
      open: '116.3400',
      high: '118.8900',
      low: '114.3000',
      close: '117.8000',
      'adjusted close': '67.5830',
      volume: '132233800',
      'dividend amount': '0.0000',
    },
    '2007-08-31': {
      open: '110.3900',
      high: '117.3500',
      low: '103.7000',
      close: '116.6900',
      'adjusted close': '66.9462',
      volume: '202871500',
      'dividend amount': '0.4000',
    },
    '2007-07-31': {
      open: '105.3900',
      high: '118.8200',
      low: '104.5800',
      close: '110.6500',
      'adjusted close': '63.2570',
      volume: '212757600',
      'dividend amount': '0.0000',
    },
    '2007-06-29': {
      open: '106.6200',
      high: '107.2400',
      low: '101.5600',
      close: '105.2500',
      'adjusted close': '60.1699',
      volume: '169602000',
      'dividend amount': '0.0000',
    },
    '2007-05-31': {
      open: '102.0600',
      high: '108.0500',
      low: '101.3500',
      close: '106.6000',
      'adjusted close': '60.9417',
      volume: '146441400',
      'dividend amount': '0.4000',
    },
    '2007-04-30': {
      open: '94.5100',
      high: '103.0000',
      low: '93.9110',
      close: '102.2100',
      'adjusted close': '58.2066',
      volume: '166807200',
      'dividend amount': '0.0000',
    },
    '2007-03-30': {
      open: '90.2500',
      high: '95.8100',
      low: '88.7700',
      close: '94.2600',
      'adjusted close': '53.6792',
      volume: '163194500',
      'dividend amount': '0.0000',
    },
    '2007-02-28': {
      open: '98.9700',
      high: '100.4400',
      low: '92.4700',
      close: '92.9400',
      'adjusted close': '52.9275',
      volume: '125776200',
      'dividend amount': '0.3000',
    },
    '2007-01-31': {
      open: '97.1700',
      high: '100.9000',
      low: '94.5500',
      close: '99.1500',
      'adjusted close': '56.2943',
      volume: '192702000',
      'dividend amount': '0.0000',
    },
    '2006-12-29': {
      open: '91.9000',
      high: '97.8800',
      low: '90.5500',
      close: '97.1500',
      'adjusted close': '55.1588',
      volume: '104470200',
      'dividend amount': '0.0000',
    },
    '2006-11-30': {
      open: '92.5000',
      high: '94.0500',
      low: '90.4300',
      close: '91.9200',
      'adjusted close': '52.1893',
      volume: '104430000',
      'dividend amount': '0.3000',
    },
    '2006-10-31': {
      open: '81.7600',
      high: '92.6800',
      low: '81.5600',
      close: '92.3300',
      'adjusted close': '52.2528',
      volume: '170367300',
      'dividend amount': '0.0000',
    },
    '2006-09-29': {
      open: '81.1300',
      high: '83.7900',
      low: '79.3100',
      close: '81.9400',
      'adjusted close': '46.3728',
      volume: '102776100',
      'dividend amount': '0.0000',
    },
    '2006-08-31': {
      open: '76.8600',
      high: '81.6800',
      low: '74.9000',
      close: '80.9700',
      'adjusted close': '45.8238',
      volume: '100880300',
      'dividend amount': '0.3000',
    },
    '2006-07-31': {
      open: '77.5400',
      high: '78.5300',
      low: '72.7300',
      close: '77.4100',
      'adjusted close': '43.6353',
      volume: '137188300',
      'dividend amount': '0.0000',
    },
    '2006-06-30': {
      open: '79.8900',
      high: '80.8700',
      low: '76.0600',
      close: '76.8200',
      'adjusted close': '43.3027',
      volume: '139457800',
      'dividend amount': '0.0000',
    },
    '2006-05-31': {
      open: '82.5900',
      high: '83.6900',
      low: '79.0000',
      close: '79.9000',
      'adjusted close': '45.0389',
      volume: '113146500',
      'dividend amount': '0.3000',
    },
    '2006-04-28': {
      open: '82.7200',
      high: '84.4500',
      low: '80.6300',
      close: '82.3400',
      'adjusted close': '46.2469',
      volume: '114007000',
      'dividend amount': '0.0000',
    },
    '2006-03-31': {
      open: '80.0100',
      high: '84.9900',
      low: '79.5100',
      close: '82.4700',
      'adjusted close': '46.3199',
      volume: '111514900',
      'dividend amount': '0.0000',
    },
    '2006-02-28': {
      open: '80.9000',
      high: '82.2400',
      low: '78.9300',
      close: '80.2400',
      'adjusted close': '45.0674',
      volume: '111052200',
      'dividend amount': '0.2000',
    },
    '2006-01-31': {
      open: '82.4500',
      high: '85.0300',
      low: '80.2100',
      close: '81.3000',
      'adjusted close': '45.5500',
      volume: '146491600',
      'dividend amount': '0.0000',
    },
    '2005-12-30': {
      open: '89.1500',
      high: '89.9200',
      low: '81.5600',
      close: '82.2000',
      'adjusted close': '46.0543',
      volume: '120052400',
      'dividend amount': '0.0000',
    },
    '2005-11-30': {
      open: '81.8500',
      high: '89.9400',
      low: '80.6400',
      close: '88.9000',
      'adjusted close': '49.8081',
      volume: '110664500',
      'dividend amount': '0.2000',
    },
    '2005-10-31': {
      open: '80.2200',
      high: '84.6000',
      low: '78.7000',
      close: '81.8800',
      'adjusted close': '45.7649',
      volume: '139881800',
      'dividend amount': '0.0000',
    },
    '2005-09-30': {
      open: '80.1600',
      high: '82.1100',
      low: '76.9300',
      close: '80.2200',
      'adjusted close': '44.8371',
      volume: '115681400',
      'dividend amount': '0.0000',
    },
    '2005-08-31': {
      open: '83.0000',
      high: '84.2000',
      low: '79.8700',
      close: '80.6200',
      'adjusted close': '45.0607',
      volume: '105031100',
      'dividend amount': '0.2000',
    },
    '2005-07-29': {
      open: '74.3000',
      high: '85.1100',
      low: '74.1600',
      close: '83.4600',
      'adjusted close': '46.5364',
      volume: '156801500',
      'dividend amount': '0.0000',
    },
    '2005-06-30': {
      open: '75.5700',
      high: '77.7300',
      low: '73.4500',
      close: '74.2000',
      'adjusted close': '41.3731',
      volume: '135542800',
      'dividend amount': '0.0000',
    },
    '2005-05-31': {
      open: '76.8800',
      high: '78.1100',
      low: '72.5000',
      close: '75.5500',
      'adjusted close': '42.1258',
      volume: '138416000',
      'dividend amount': '0.2000',
    },
    '2005-04-29': {
      open: '91.4900',
      high: '91.7600',
      low: '71.8500',
      close: '76.3800',
      'adjusted close': '42.4758',
      volume: '216745500',
      'dividend amount': '0.0000',
    },
    '2005-03-31': {
      open: '92.6400',
      high: '93.7300',
      low: '89.0900',
      close: '91.3800',
      'adjusted close': '50.8174',
      volume: '106145200',
      'dividend amount': '0.0000',
    },
    '2005-02-28': {
      open: '93.6700',
      high: '94.9700',
      low: '91.5500',
      close: '92.5800',
      'adjusted close': '51.4848',
      volume: '79707700',
      'dividend amount': '0.1800',
    },
    '2005-01-31': {
      open: '98.9700',
      high: '99.1000',
      low: '91.4400',
      close: '93.4200',
      'adjusted close': '51.8527',
      volume: '114459000',
      'dividend amount': '0.0000',
    },
    '2004-12-31': {
      open: '94.5000',
      high: '99.0000',
      low: '94.4700',
      close: '98.5800',
      'adjusted close': '54.7168',
      volume: '108170400',
      'dividend amount': '0.0000',
    },
    '2004-11-30': {
      open: '89.3300',
      high: '96.6300',
      low: '89.2300',
      close: '94.2400',
      'adjusted close': '52.3079',
      volume: '114982100',
      'dividend amount': '0.1800',
    },
    '2004-10-29': {
      open: '85.9500',
      high: '90.2700',
      low: '84.2900',
      close: '89.7500',
      'adjusted close': '49.7198',
      volume: '118116100',
      'dividend amount': '0.0000',
    },
    '2004-09-30': {
      open: '84.0500',
      high: '87.2800',
      low: '83.2400',
      close: '85.7400',
      'adjusted close': '47.4984',
      volume: '93906300',
      'dividend amount': '0.0000',
    },
    '2004-08-31': {
      open: '86.8700',
      high: '87.3900',
      low: '81.9000',
      close: '84.6900',
      'adjusted close': '46.9167',
      volume: '91169700',
      'dividend amount': '0.1800',
    },
    '2004-07-30': {
      open: '88.2800',
      high: '88.4400',
      low: '83.4200',
      close: '87.0700',
      'adjusted close': '48.1314',
      volume: '121001000',
      'dividend amount': '0.0000',
    },
    '2004-06-30': {
      open: '88.0900',
      high: '91.2100',
      low: '87.3000',
      close: '88.1500',
      'adjusted close': '48.7284',
      volume: '91968600',
      'dividend amount': '0.0000',
    },
    '2004-05-28': {
      open: '88.1300',
      high: '89.7500',
      low: '85.1200',
      close: '88.5900',
      'adjusted close': '48.9716',
      volume: '104707300',
      'dividend amount': '0.1800',
    },
    '2004-04-30': {
      open: '91.6700',
      high: '94.5500',
      low: '88.0100',
      close: '88.1700',
      'adjusted close': '48.6404',
      volume: '110632000',
      'dividend amount': '0.0000',
    },
    '2004-03-31': {
      open: '96.5000',
      high: '97.6000',
      low: '90.2800',
      close: '91.8400',
      'adjusted close': '50.6650',
      volume: '124483700',
      'dividend amount': '0.0000',
    },
    '2004-02-27': {
      open: '99.1500',
      high: '100.4300',
      low: '95.2000',
      close: '96.5000',
      'adjusted close': '53.2358',
      volume: '97741900',
      'dividend amount': '0.1600',
    },
    '2004-01-30': {
      open: '92.8600',
      high: '99.8500',
      low: '89.0100',
      close: '99.2300',
      'adjusted close': '54.6534',
      volume: '139368500',
      'dividend amount': '0.0000',
    },
    '2003-12-31': {
      open: '90.9000',
      high: '94.1200',
      low: '90.0300',
      close: '92.6800',
      'adjusted close': '51.0458',
      volume: '116103600',
      'dividend amount': '0.0000',
    },
    '2003-11-28': {
      open: '89.9000',
      high: '91.4800',
      low: '87.7200',
      close: '90.5400',
      'adjusted close': '49.8672',
      volume: '97966100',
      'dividend amount': '0.1600',
    },
    '2003-10-31': {
      open: '88.7500',
      high: '94.5400',
      low: '87.5300',
      close: '89.4800',
      'adjusted close': '49.1953',
      volume: '156145400',
      'dividend amount': '0.0000',
    },
    '2003-09-30': {
      open: '82.4000',
      high: '93.4700',
      low: '82.3000',
      close: '88.3300',
      'adjusted close': '48.5630',
      volume: '171943100',
      'dividend amount': '0.0000',
    },
    '2003-08-29': {
      open: '81.1500',
      high: '84.7200',
      low: '78.7300',
      close: '82.0100',
      'adjusted close': '45.0883',
      volume: '113895200',
      'dividend amount': '0.1600',
    },
    '2003-07-31': {
      open: '81.4000',
      high: '87.0300',
      low: '80.5800',
      close: '81.2500',
      'adjusted close': '44.5810',
      volume: '153915600',
      'dividend amount': '0.0000',
    },
    '2003-06-30': {
      open: '89.0000',
      high: '89.0800',
      low: '79.8100',
      close: '82.5000',
      'adjusted close': '45.2669',
      volume: '184488300',
      'dividend amount': '0.0000',
    },
    '2003-05-30': {
      open: '85.3000',
      high: '90.4000',
      low: '84.4500',
      close: '88.0400',
      'adjusted close': '48.3066',
      volume: '149548000',
      'dividend amount': '0.1600',
    },
    '2003-04-30': {
      open: '78.5000',
      high: '86.4900',
      low: '78.1200',
      close: '84.9000',
      'adjusted close': '46.4979',
      volume: '177874300',
      'dividend amount': '0.0000',
    },
    '2003-03-31': {
      open: '78.9000',
      high: '84.9000',
      low: '73.1700',
      close: '78.4300',
      'adjusted close': '42.9544',
      volume: '191937200',
      'dividend amount': '0.0000',
    },
    '2003-02-28': {
      open: '78.2000',
      high: '80.0500',
      low: '74.3100',
      close: '77.9500',
      'adjusted close': '42.6916',
      volume: '145672700',
      'dividend amount': '0.1500',
    },
    '2003-01-31': {
      open: '78.8000',
      high: '88.9500',
      low: '77.0200',
      close: '78.2000',
      'adjusted close': '42.7458',
      volume: '193933500',
      'dividend amount': '0.0000',
    },
    '2002-12-31': {
      open: '88.6000',
      high: '89.4600',
      low: '75.6000',
      close: '77.5000',
      'adjusted close': '42.3631',
      volume: '151563500',
      'dividend amount': '0.0000',
    },
    '2002-11-29': {
      open: '78.9000',
      high: '88.1100',
      low: '76.7000',
      close: '86.9200',
      'adjusted close': '47.5123',
      volume: '184438100',
      'dividend amount': '0.1500',
    },
    '2002-10-31': {
      open: '59.2000',
      high: '79.7900',
      low: '54.0100',
      close: '78.9400',
      'adjusted close': '43.0710',
      volume: '285626300',
      'dividend amount': '0.0000',
    },
    '2002-09-30': {
      open: '74.2000',
      high: '77.5000',
      low: '57.9900',
      close: '58.3100',
      'adjusted close': '31.8149',
      volume: '185664400',
      'dividend amount': '0.0000',
    },
    '2002-08-30': {
      open: '70.4000',
      high: '82.8500',
      low: '65.8500',
      close: '75.3800',
      'adjusted close': '41.1286',
      volume: '166322300',
      'dividend amount': '0.1500',
    },
    '2002-07-31': {
      open: '72.0100',
      high: '74.2000',
      low: '65.7000',
      close: '70.4000',
      'adjusted close': '38.3283',
      volume: '246256900',
      'dividend amount': '0.0000',
    },
    '2002-06-28': {
      open: '80.7500',
      high: '80.9500',
      low: '66.1000',
      close: '72.0000',
      'adjusted close': '39.1994',
      volume: '190980700',
      'dividend amount': '0.0000',
    },
    '2002-05-31': {
      open: '83.8500',
      high: '86.4900',
      low: '75.9200',
      close: '80.4500',
      'adjusted close': '43.7999',
      volume: '172738400',
      'dividend amount': '0.1500',
    },
    '2002-04-30': {
      open: '104.0000',
      high: '104.0000',
      low: '83.3400',
      close: '83.7600',
      'adjusted close': '45.5192',
      volume: '246503300',
      'dividend amount': '0.0000',
    },
    '2002-03-28': {
      open: '98.6000',
      high: '108.8500',
      low: '98.5000',
      close: '104.0000',
      'adjusted close': '56.5186',
      volume: '151268500',
      'dividend amount': '0.0000',
    },
    '2002-02-28': {
      open: '107.6400',
      high: '109.3000',
      low: '95.7600',
      close: '98.1200',
      'adjusted close': '53.3231',
      volume: '172321000',
      'dividend amount': '0.1400',
    },
    '2002-01-31': {
      open: '120.6000',
      high: '126.3900',
      low: '101.0000',
      close: '107.8900',
      'adjusted close': '58.5557',
      volume: '193562900',
      'dividend amount': '0.0000',
    },
    '2001-12-31': {
      open: '114.8000',
      high: '124.7000',
      low: '113.2100',
      close: '120.9600',
      'adjusted close': '65.6493',
      volume: '133393800',
      'dividend amount': '0.0000',
    },
    '2001-11-30': {
      open: '107.2500',
      high: '117.0000',
      low: '106.9000',
      close: '115.5900',
      'adjusted close': '62.7348',
      volume: '135136300',
      'dividend amount': '0.1400',
    },
    '2001-10-31': {
      open: '91.7200',
      high: '112.1000',
      low: '91.3400',
      close: '108.0700',
      'adjusted close': '58.5814',
      volume: '224351500',
      'dividend amount': '0.0000',
    },
    '2001-09-28': {
      open: '100.1500',
      high: '103.1300',
      low: '87.4900',
      close: '91.7200',
      'adjusted close': '49.7185',
      volume: '189312500',
      'dividend amount': '0.0000',
    },
    '2001-08-31': {
      open: '105.3500',
      high: '110.0900',
      low: '98.8600',
      close: '99.9500',
      'adjusted close': '54.1798',
      volume: '143401500',
      'dividend amount': '0.1400',
    },
    '2001-07-31': {
      open: '113.5000',
      high: '115.4000',
      low: '101.5600',
      close: '105.2100',
      'adjusted close': '56.9545',
      volume: '161016400',
      'dividend amount': '0.0000',
    },
    '2001-06-29': {
      open: '112.5000',
      high: '119.0000',
      low: '111.1000',
      close: '113.5000',
      'adjusted close': '61.4422',
      volume: '142929700',
      'dividend amount': '0.0000',
    },
    '2001-05-31': {
      open: '115.8500',
      high: '119.9000',
      low: '110.9600',
      close: '111.8000',
      'adjusted close': '60.5220',
      volume: '159356200',
      'dividend amount': '0.1400',
    },
    '2001-04-30': {
      open: '97.4500',
      high: '118.0500',
      low: '90.0500',
      close: '115.1400',
      'adjusted close': '62.2560',
      volume: '226210900',
      'dividend amount': '0.0000',
    },
    '2001-03-30': {
      open: '99.3000',
      high: '108.4000',
      low: '87.6500',
      close: '96.1800',
      'adjusted close': '52.0044',
      volume: '254550000',
      'dividend amount': '0.0000',
    },
    '2001-02-28': {
      open: '112.4000',
      high: '118.6400',
      low: '99.1000',
      close: '99.9000',
      'adjusted close': '54.0157',
      volume: '156438900',
      'dividend amount': '0.1300',
    },
    '2001-01-31': {
      open: '84.5000',
      high: '116.7500',
      low: '83.7500',
      close: '112.0000',
      'adjusted close': '60.4909',
      volume: '198962600',
      'dividend amount': '0.0000',
    },
    '2000-12-29': {
      open: '94.4400',
      high: '104.7500',
      low: '80.0600',
      close: '85.0000',
      'adjusted close': '45.9083',
      volume: '167790700',
      'dividend amount': '0.0000',
    },
    '2000-11-30': {
      open: '98.4400',
      high: '104.3700',
      low: '91.6200',
      close: '93.5000',
      'adjusted close': '50.4991',
      volume: '130411900',
      'dividend amount': '0.1300',
    },
    '2000-10-31': {
      open: '112.6200',
      high: '119.6200',
      low: '86.9400',
      close: '98.5000',
      'adjusted close': '53.1305',
      volume: '223510000',
      'dividend amount': '0.0000',
    },
    '2000-09-29': {
      open: '133.0600',
      high: '134.9400',
      low: '110.2500',
      close: '112.6200',
      'adjusted close': '60.7468',
      volume: '107468400',
      'dividend amount': '0.0000',
    },
    '2000-08-31': {
      open: '112.0000',
      high: '134.2500',
      low: '110.2500',
      close: '132.0200',
      'adjusted close': '71.2111',
      volume: '121998000',
      'dividend amount': '0.1300',
    },
    '2000-07-31': {
      open: '108.7500',
      high: '117.8100',
      low: '100.0000',
      close: '112.2500',
      'adjusted close': '60.4811',
      volume: '150130400',
      'dividend amount': '0.0000',
    },
    '2000-06-30': {
      open: '107.5600',
      high: '122.3100',
      low: '105.5000',
      close: '109.5600',
      'adjusted close': '59.0317',
      volume: '136216300',
      'dividend amount': '0.0000',
    },
    '2000-05-31': {
      open: '112.5000',
      high: '113.6200',
      low: '102.0000',
      close: '107.3100',
      'adjusted close': '57.8194',
      volume: '123803200',
      'dividend amount': '0.1300',
    },
    '2000-04-28': {
      open: '120.0000',
      high: '128.0000',
      low: '101.2500',
      close: '111.5000',
      'adjusted close': '60.0059',
      volume: '168464800',
      'dividend amount': '0.0000',
    },
    '2000-03-31': {
      open: '102.0000',
      high: '128.2500',
      low: '99.5000',
      close: '118.3700',
      'adjusted close': '63.7031',
      volume: '194329000',
      'dividend amount': '0.0000',
    },
    '2000-02-29': {
      open: '112.3700',
      high: '119.7500',
      low: '100.9400',
      close: '102.7500',
      'adjusted close': '55.2969',
      volume: '133524400',
      'dividend amount': '0.1200',
    },
    '2000-01-31': {
      open: '112.4400',
      high: '124.7500',
      low: '109.6200',
      close: '112.2500',
      'adjusted close': '60.3486',
      volume: '175259600',
      'dividend amount': '0.0000',
    },
    '1999-12-31': {
      open: '102.5600',
      high: '122.1200',
      low: '102.2500',
      close: '107.8700',
      'adjusted close': '57.9938',
      volume: '158626300',
      'dividend amount': '0.0000',
    },
  },
};
