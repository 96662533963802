import { FilteredNewsDataModel } from '../../../../common/models';
import { blockchainNewsData } from './blockchain-news';
import { earningsNewsData } from './earnings-news';
import { dailyNews } from './daily-news';
import { hourlyNews } from './hourly-news';
import { newsData } from './news-data';
import { searchResultsData } from './search-result';

export const newsDataByType: FilteredNewsDataModel = {
  All: newsData,
  Blockchain: blockchainNewsData,
  Earnings: earningsNewsData,
};

export const newsDataByTime: FilteredNewsDataModel = {
  hourly: hourlyNews,
  daily: dailyNews,
};

export const filteredNewsDataBySearch: FilteredNewsDataModel = {
  search: searchResultsData,
};
