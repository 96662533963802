import { AppRouter } from './common/routers';
import { BACKGROUND } from './common/styles';

function App() {
  return (
    <div className="App" data-testid="app">
      <AppRouter />
    </div>
  );
}

export default App;
