import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNewsReqVersion1, getNewsByCompanyReq } from '../../api';

export const getNewsDataThunk = createAsyncThunk(
  'getNewsDataThunk',
  async ({
    tag,
    page,
    pageSize,
  }: {
    tag: string;
    page: string;
    pageSize: string;
  }) => {
    const response = await getNewsReqVersion1({
      newsTag: tag,
      page: page,
      pageSize: pageSize,
    });
    if (response && Array.isArray(response)) {
      return { news: response };
    } else {
      throw new Error('Expected the response to be an array');
    }
  },
);

export const getNewsDataByCompanyThunk = createAsyncThunk(
  'getNewsDataByCompanyThunk',
  async ({
    companySymbol,
    page,
    pageSize,
  }: {
    companySymbol: string;
    page: string;
    pageSize: string;
  }) => {
    const response = await getNewsByCompanyReq({
      company: companySymbol,
      page: page,
      pageSize: pageSize,
    });

    if (response && Array.isArray(response)) {
      return { news: response };
    } else {
      throw new Error('Expected the response to be an array');
    }
  },
);
