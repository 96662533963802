import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import { LoginButton, LogoutButton, SignUpButton } from '../../components';
import { useAuth0 } from '@auth0/auth0-react';
import CardMedia from '@mui/material/CardMedia';

const TopNavBar = () => {
  const { t } = useTranslation();
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#23232B',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* 左边的ICON和标题 */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 10,
          }}
        >
          <img
            src="/asset/icon/ymycai-logo.png"
            alt="LOGO"
            style={{ width: '50px', height: 'auto' }}
          />
          <Typography variant="h6" noWrap component="div">
            {t('common.ymycai')}
          </Typography>
        </Box>
        {/* 右边的登陆注册按钮和提示文字 */}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Typography variant="body1" sx={{ mr: 2 }}>
            {user ? `Welcome, ${user.nickname}!` : 'Please Login'}
          </Typography>
          {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          <SignUpButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
