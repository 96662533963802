import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux_features/hooks';
import { getFinReportDataThunk } from '../../redux_features/thunks';
import {
  BalanceSheetItem,
  CashflowItem,
  IncomeStatementItem,
} from '../../models';
import { FRDataTable, FRDynamicGraphTable, FRDupontChart } from '..';
import { NewsButton } from '../../widgets';
import { BACKGROUND } from '../../styles';

interface FRFetchPassDataProps {
  company: string;
}

const FRFetchPassData: React.FC<FRFetchPassDataProps> = ({ company }) => {
  const dispatch = useAppDispatch();
  const { quarterlyReport, annuallyReport } = useAppSelector(
    (state) => state.ui.finReport.FinReport,
  );
  const { quarterlyAnalysis, annuallyAnalysis } = useAppSelector(
    (state) => state.ui.finAnalysis.FinAnalysis,
  );

  const status = useAppSelector((state) => state.ui.finReport.status);

  const [currentPeriodReportData, setCurrentPeriodReportData] =
    useState(annuallyReport);
  const [currentPeriodAnalysisData, setCurrentPeriodAnalysisData] =
    useState(annuallyAnalysis);

  useEffect(() => {
    setCurrentPeriodReportData(annuallyReport);
  }, [annuallyReport]);

  const today = new Date();
  const to = `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(
    2,
    '0',
  )}/${String(today.getDate()).padStart(2, '0')}`;
  const tenYearsAgoYear = today.getFullYear() - 12;
  const from = `${tenYearsAgoYear}/01/01`;

  useEffect(() => {
    dispatch(getFinReportDataThunk({ company, from, to }));
  }, [company, dispatch, from, to]);

  const limitDataToLength = (data: any[], length: number) => {
    return data.length > length ? data.slice(0, length) : data;
  };

  const currentBalanceSheetData = limitDataToLength(
    currentPeriodReportData.flatMap((item) => item.balancesheet),
    7,
  );
  const currentCashflowData = limitDataToLength(
    currentPeriodReportData.flatMap((item) => item.cashflow),
    7,
  );
  const currentIncomeStatementData = limitDataToLength(
    currentPeriodReportData.flatMap((item) => item.income_statement),
    7,
  );

  if (!company) {
    return <div>No company is passing in</div>;
  }

  return (
    <div className="Financial Report Component">
      {quarterlyReport.length !== 0 && (
        <NewsButton
          onClick={() => {
            setCurrentPeriodReportData(quarterlyReport);
            setCurrentPeriodAnalysisData(quarterlyAnalysis);
          }}
          isSelected={currentPeriodReportData === quarterlyReport}
        >
          Quarterly
        </NewsButton>
      )}
      <NewsButton
        onClick={() => {
          setCurrentPeriodReportData(annuallyReport);
          setCurrentPeriodAnalysisData(annuallyAnalysis);
        }}
        isSelected={
          currentPeriodReportData === annuallyReport ||
          !currentPeriodReportData.length
        }
      >
        Annually
      </NewsButton>
      <FRDynamicGraphTable
        company={company}
        analysisData={currentPeriodAnalysisData}
      />
      {status === 'loading' && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            fontSize: '3em',
            color: BACKGROUND,
          }}
        >
          Loading...
        </div>
      )}

      {/* Commented out for release, will add in the future */}
      {/* <FRDupontChart /> */}
      <p style={{ width: '90%' }}></p>
      <FRDataTable<BalanceSheetItem>
        data={currentBalanceSheetData}
        title="Balance Sheet"
      />
      <FRDataTable<CashflowItem> data={currentCashflowData} title="Cash Flow" />
      <FRDataTable<IncomeStatementItem>
        data={currentIncomeStatementData}
        title="Income Statement"
      />
    </div>
  );
};

export default FRFetchPassData;
