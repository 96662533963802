import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import { TEXT_COLOR } from '../../styles';

const UserProfile: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const tableCells = [
    { label: 'Nickname', value: user?.nickname },
    { label: 'Name', value: user?.name },
    { label: 'Email', value: user?.email },
    { label: 'Email Verified', value: user?.email_verified ? 'Yes' : 'No' },
    {
      label: 'Last Updated',
      value: new Date(user?.updated_at || '').toLocaleDateString(),
    },
  ];

  return (
    <Box sx={{ margin: '20px' }}>
      <Avatar
        alt={user?.nickname}
        src={user?.picture}
        sx={{ width: '200px', height: '200px' }}
      />

      <Box sx={{ marginTop: '20px' }}></Box>

      <Table sx={{ borderTop: '1px solid #FAFEFF' }}>
        <TableBody>
          {tableCells.map((cell, index) => (
            <TableRow key={index}>
              <TableCell sx={{ color: TEXT_COLOR }}>{cell.label}</TableCell>
              <TableCell sx={{ color: TEXT_COLOR }}>{cell.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default UserProfile;
