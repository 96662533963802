import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  GetTreeMapDataResponseModel,
  TreeMapDataPointModel,
  GetNewsResponseModelVersion1,
  NewsItemModelVersion1,
  FinReportItemModel,
  GetCompanyResponseModel,
  StockListTableRowsModel,
  InsideTradingModel,
  GetInsideTradingModel,
  // GetFinAnalysisResponseModel,
  FinAnalysisItem,
  LatestQuoteModel,
  CompanyInfoModel,
  CompanyAndPeersInfoModel,
  CompanyAndPeersLatestQuotesModel,
} from '../../models';
import {
  getTreeMapDataThunk,
  getNewsDataThunk,
  getFinReportDataThunk,
  getCompanyDataThunk,
  getStockListTableRowsThunk,
  getNewsDataByCompanyThunk,
  getFinAnalysisDataThunk,
} from '../thunks';
import { getInsiderTradingByCompanyThunk } from '../thunks/Market-data-thunk';

const initialState = {
  searchInput: '',

  customizedDialog: {
    isOpen: false,
  },

  // Tree Map
  treeMap: {
    treeMapData: [] as TreeMapDataPointModel[],
  },

  insideTradings: {
    insideTradingDatas: [] as InsideTradingModel[],
    status: 'idle',
  },
  news: {
    newsData: [] as NewsItemModelVersion1[],
    status: 'idle',
  },
  selectedTag: 'all',
  finReport: {
    FinReport: {
      quarterlyReport: [] as FinReportItemModel[],
      annuallyReport: [] as FinReportItemModel[],
    },
    status: 'idle',
  },
  finAnalysis: {
    FinAnalysis: {
      quarterlyAnalysis: [] as FinAnalysisItem[],
      annuallyAnalysis: [] as FinAnalysisItem[],
    },
    status: 'idle',
  },
  selectedPeriod: 'Quarterly',

  // company data
  company: {
    companyData: {
      companyInfo: {} as CompanyInfoModel,
      peerStock: {} as CompanyAndPeersInfoModel,
      latestQuotes: [] as CompanyAndPeersLatestQuotesModel,
    } as GetCompanyResponseModel,
    status: 'idle',
  },

  // stock list
  stockList: {
    symbols: [] as string[],
    selectedSymbol: '',
    // stock list table
    stockListTable: {
      rows: [] as StockListTableRowsModel,
      expiration: Date.now() + 24 * 60 * 60 * 1000, // 24小时
      displayedSymbols: [] as string[],
    },
    // right sidebar
    stockListRightSidebar: {
      selectedCompanyLatestQuote: {} as LatestQuoteModel,
      isOpen: false,
      status: 'idle',
    },
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    clearSearchInput: (state) => {
      state.searchInput = '';
    },
    openCustomizedDialog: (state) => {
      state.customizedDialog.isOpen = true;
    },
    closeCustomizedDialog: (state) => {
      state.customizedDialog.isOpen = false;
    },

    // Tree Map
    setTreeMapData: (state, action) => {
      state.treeMap.treeMapData = action.payload;
    },

    setSelectedTag: (state, action: PayloadAction<string>) => {
      state.selectedTag = action.payload;
    },
    setSelectedPeriod: (state, action: PayloadAction<string>) => {
      state.selectedPeriod = action.payload;
    },

    // StockList
    setSymbols: (state, action) => {
      state.stockList.symbols = action.payload;
    },
    updateStockListExpiration: (state) => {
      state.stockList.stockListTable.expiration =
        Date.now() + 24 * 60 * 60 * 1000; // 24小时
    },
    setDisplayedSymbols: (state, action) => {
      state.stockList.stockListTable.displayedSymbols = action.payload;
    },
    // stock list right sidebar
    setSelectedSymbol: (state, action: PayloadAction<string>) => {
      state.stockList.selectedSymbol = action.payload;
    },
    setSelectedCompanyLatestQuote: (
      state,
      action: PayloadAction<LatestQuoteModel>,
    ) => {
      state.stockList.stockListRightSidebar.selectedCompanyLatestQuote =
        action.payload;
    },
    openStockListRightSidebar: (state) => {
      state.stockList.stockListRightSidebar.isOpen = true;
    },
    closeStockListRightSidebar: (state) => {
      state.stockList.stockListRightSidebar.isOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTreeMapDataThunk.fulfilled,
        (state, action: PayloadAction<GetTreeMapDataResponseModel>) => {
          const responseJson: GetTreeMapDataResponseModel = action.payload;
          state.treeMap.treeMapData =
            responseJson.data as TreeMapDataPointModel[];
        },
      )
      //inside trading thunk
      .addCase(
        getInsiderTradingByCompanyThunk.fulfilled,
        (state, action: PayloadAction<GetInsideTradingModel>) => {
          state.insideTradings.insideTradingDatas =
            action.payload.insideTradings;
          state.insideTradings.status = 'succeeded';
        },
      )
      .addCase(getInsiderTradingByCompanyThunk.rejected, (state, action) => {
        state.insideTradings.status = 'failed';
      })
      //news thunk
      .addCase(
        getNewsDataThunk.fulfilled,
        (state, action: PayloadAction<GetNewsResponseModelVersion1>) => {
          state.news.newsData = action.payload.news;
          state.news.status = 'succeeded';
        },
      )
      .addCase(getNewsDataThunk.rejected, (state, action) => {
        state.news.status = 'failed';
      })
      //get news data by company thunk
      .addCase(
        getNewsDataByCompanyThunk.fulfilled,
        (state, action: PayloadAction<GetNewsResponseModelVersion1>) => {
          state.news.newsData = action.payload.news;
          state.news.status = 'succeeded';
        },
      )
      .addCase(getNewsDataByCompanyThunk.rejected, (state, action) => {
        state.news.status = 'failed';
      })
      //financial report
      .addCase(getFinReportDataThunk.pending, (state) => {
        state.finReport.status = 'loading';
      })
      .addCase(getFinReportDataThunk.fulfilled, (state, action) => {
        state.finReport.status = 'succeeded';
        state.finReport.FinReport.quarterlyReport =
          action.payload.FinReport.quarterlyReport;
        state.finReport.FinReport.annuallyReport =
          action.payload.FinReport.annuallyReport;
      })

      .addCase(getFinReportDataThunk.rejected, (state, action) => {
        state.finReport.status = 'failed';
      })
      // financial analysis
      .addCase(getFinAnalysisDataThunk.pending, (state) => {
        state.finAnalysis.status = 'loading';
      })
      .addCase(getFinAnalysisDataThunk.fulfilled, (state, action) => {
        state.finAnalysis.status = 'succeeded';
        state.finAnalysis.FinAnalysis.annuallyAnalysis =
          action.payload.FinAnalysis.annually;
        state.finAnalysis.FinAnalysis.quarterlyAnalysis =
          action.payload.FinAnalysis.quarterly;
      })

      .addCase(getFinAnalysisDataThunk.rejected, (state) => {
        state.finAnalysis.status = 'failed';
      })

      // getCompanyData
      .addCase(getCompanyDataThunk.pending, (state) => {
        state.company.status = 'loading';
      })
      .addCase(
        getCompanyDataThunk.fulfilled,
        (state, action: PayloadAction<GetCompanyResponseModel>) => {
          state.company.companyData = { ...action.payload };
          state.company.status = 'succeeded';
        },
      )
      .addCase(getCompanyDataThunk.rejected, (state, action) => {
        state.company.status = 'failed';
      })

      .addCase(
        getStockListTableRowsThunk.fulfilled,
        (state, action: PayloadAction<StockListTableRowsModel>) => {
          const resp: StockListTableRowsModel = action.payload;
          state.stockList.stockListTable.rows = resp as StockListTableRowsModel;
        },
      );
  },
});

export default uiSlice.reducer;
