import { NavLink, useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import { NavButtonPropsModel } from '../models';
import { TEXT_COLOR } from '../styles';

const NavButton: React.FC<NavButtonPropsModel> = ({
  to,
  children,
  onClick,
  isSelected,
  height,
}) => {
  const location = useLocation();

  const isButtonActive = (path: string) => location.pathname === path;

  const handleClick = () => {
    if (onClick) onClick();
  };

  const styles = {
    marginRight: '20px',
    textDecoration: 'none',
    borderRadius: 0,
    height: height || '38px',
    fontSize: '16px',
    backgroundColor:
      (to && isButtonActive(to)) || isSelected ? '#6d717d' : 'transparent',
    color: TEXT_COLOR,
  };

  return (
    <Button
      component={to ? NavLink : 'button'}
      to={to}
      onClick={handleClick}
      style={styles}
    >
      {children}
    </Button>
  );
};

export default NavButton;
