import React, { useEffect, useState } from 'react';
import { getCompanyDetail } from '../../api';
import { CompanyInfoModel } from '../../models';

const formatCompanyInfo = (rawData: CompanyInfoModel): CompanyInfoModel => {
  return {
    ...rawData,
    company_name: rawData.company_name,
    company_symbol: rawData.company_symbol,
    company_information: rawData.company_information,
    industry_position: rawData.industry_position,
    // 包含 CompanyInfoModel 期望的其他属性
    tags: rawData.tags, // 这只是一个占位符，请用 CompanyInfoModel 中期望的实际标签或字段替换它
  };
};

interface AiSummaryProps {
  company_symbol: string;
}

const AiSummary: React.FC<AiSummaryProps> = ({ company_symbol }) => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoModel | null>(null);

  useEffect(() => {
    const GetCompanyInfoData = async () => {
      const companyInformationData: CompanyInfoModel = await getCompanyDetail(
        company_symbol,
      );
      setCompanyInfo(formatCompanyInfo(companyInformationData));
    };

    GetCompanyInfoData();
  }, [company_symbol]);

  if (!companyInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h3>Company Information</h3>
      <p>{companyInfo.company_information}</p>
      <h3>Industry Position</h3>
      <p>{companyInfo.industry_position}</p>
    </div>
  );
};

export default AiSummary;
