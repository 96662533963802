import { useAuth0 } from '@auth0/auth0-react';
import { RegistrationButton } from '../../widgets';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <RegistrationButton
      onClick={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Log Out
    </RegistrationButton>
  );
};

export default LogoutButton;
