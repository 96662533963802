import React from 'react';
import {
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import { TEXT_COLOR, BACKGROUND } from '../../styles';

type FRDataTableProps<T> = {
  data: T[];
  title: string;
  excludedColumns?: string[];
};

const StyledTableContainer = styled(TableContainer)({
  // maxHeight: 300,
  maxWidth: 1150,
  // overflow: 'auto',
  margin: 'auto',
  backgroundColor: BACKGROUND,
});

const StickyTableCell = styled(MuiTableCell)({
  position: 'sticky',
  left: 0,
  background: BACKGROUND,
  zIndex: 2,
  textAlign: 'center',
  fontSize: '14px',
  width: '20px',
  color: TEXT_COLOR,
});

const CenteredTableCell = styled(MuiTableCell)({
  fontSize: '12px',
  textAlign: 'center',
  width: '20px',
  color: TEXT_COLOR,
});

const FRDataTable = <T,>({
  data,
  title,
  excludedColumns = [],
}: FRDataTableProps<T>): JSX.Element => {
  const headers = Object.keys(data[0] || {});

  function formatKey(key: string): string {
    const words = key.split(/(?=[A-Z])/);
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function formatNumber(num: number): string {
    if (Math.abs(num) > 1_000_000_000) {
      return `${(num / 1_000_000_000).toFixed(2)} B`;
    }
    if (Math.abs(num) > 1_000_000) {
      return `${(num / 1_000_000).toFixed(2)} M`;
    }

    return num.toFixed(2);
  }

  const formattedHeaders = headers.map((header) => formatKey(header));

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>{title}</h2>
      <StyledTableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StickyTableCell>Attributes</StickyTableCell>
              {data.map((record, rowIndex) => (
                <CenteredTableCell key={rowIndex}>
                  {(record as any).date}
                </CenteredTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {headers.map((header, index) => {
              const excludedHeaders = [
                'date',
                'link',
                'finalLink',
                'acceptedDate',
                'fillingDate',
                'Calendar Year',
                'cik',
                'calendarYear',
                'symbol',
              ];

              return (
                !excludedHeaders.includes(header) && (
                  <TableRow key={header}>
                    <StickyTableCell>{formattedHeaders[index]}</StickyTableCell>
                    {data.map((row, rowIndex) => (
                      <CenteredTableCell key={rowIndex}>
                        {typeof row[header as keyof (typeof data)[0]] ===
                        'number'
                          ? formatNumber(
                              row[header as keyof (typeof data)[0]] as number,
                            )
                          : String(row[header as keyof (typeof data)[0]])}
                      </CenteredTableCell>
                    ))}
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
};

export default FRDataTable;
